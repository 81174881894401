import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import moment from 'moment'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

import DataChip from 'components/DataChip'

import NotificationsEvents from './NotificationsEvents'

import messages from './messages'

const styles = {
  hr: {
    borderColor: '#ddd'
  }
}

const NotificationsDetail = props => {
  const { classes, closeDialog, intl, notification } = props

  const notificationStatusToShow = notification.status === 'acknowledged' ? 'ACKD' : notification.status

  return (
    <Dialog maxWidth='md' onClose={closeDialog} open>
      <DialogTitle disableTypography>
        <Typography component='h1' variant='h4'>
          {notification.ruleInstanceName}
        </Typography>{' '}
        {notification.ruleInstanceDescription}
      </DialogTitle>
      <DialogContent style={{ marginBottom: 16 }}>
        <Grid container>
          <Grid item xs={12}>
            <hr className={classes.hr} style={{ marginTop: 0, borderWidth: 3 }} />
          </Grid>
          <Grid container item xs={12}>
            <Grid item sm={4} xs={12}>
              <strong>{intl.formatMessage(messages.deviceName)}:</strong> {notification.deviceName}
            </Grid>
            <Grid item sm={4} xs={12}>
              <strong>{intl.formatMessage(messages.deviceType)}:</strong> {notification.deviceType}
            </Grid>
            <Grid item sm={4} xs={12}>
              <strong>EID:</strong> {notification.source}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <hr className={classes.hr} />
          </Grid>
          <Grid container item style={{ marginBottom: 15 }} xs={12}>
            <Grid item sm={4} xs={12}>
              <strong>{intl.formatMessage(messages.dateDetailLabel)}:</strong>{' '}
              {moment(notification.createdAt).format('L LTS')}
            </Grid>
            <Grid item sm={4} xs={12}>
              <strong>{intl.formatMessage(messages.severityDetailLabel)}:</strong>{' '}
              <DataChip chipText={notification.criticality} inline intl={intl} />
            </Grid>
            <Grid item sm={4} xs={12}>
              <strong>{intl.formatMessage(messages.currentStatusDetailLabel)}:</strong>{' '}
              <DataChip chipText={notificationStatusToShow} inline intl={intl} />
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item sm={4} style={{ paddingRight: 20 }} xs={12}>
              <strong>{intl.formatMessage(messages.reason)}:</strong> {notification.notificationBody}
            </Grid>
            <Grid item sm={4} xs={12}>
              <strong>{intl.formatMessage(messages.archivedDetailLabel)}:</strong>{' '}
              {notification.archived ? intl.formatMessage(messages.yes) : intl.formatMessage(messages.no)}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <hr className={classes.hr} />
          </Grid>
          <Grid item style={{ marginTop: 15 }} xs={12}>
            <Typography component='h2' style={{ color: 'inherit' }} variant='h5'>
              {intl.formatMessage(messages.historyDetailLabel)}
            </Typography>
            <div>
              <NotificationsEvents intl={intl} notification={notification} />
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions disableSpacing style={{ padding: '8px 24px 16px' }}>
        <Button className='primary-action-button' onClick={closeDialog}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

NotificationsDetail.propTypes = {
  classes: PropTypes.object.isRequired,
  closeDialog: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  notification: PropTypes.object.isRequired
}

export default injectIntl(withStyles(styles)(NotificationsDetail))
