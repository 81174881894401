import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import GNSSGeofenceConditions from './GNSSGeofenceConditions'

import { actions } from 'ducks/configurationsCS500'

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      handleFieldChange: actions.changeLocalConfigurationField,
    },
    dispatch
  )

export default connect(null, mapDispatchToProps)(GNSSGeofenceConditions)