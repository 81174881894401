import { connect } from 'react-redux'

import { utils as map } from 'ducks/map'
import { utils as template } from 'ducks/template'

import AdminTemplate from './AdminTemplate'

const mapStateToProps = state => ({
  isCollapsed: template.isCollapsed(state),
  isMapMounted: map.getIsMounted(state)
})

export default connect(mapStateToProps)(AdminTemplate)
