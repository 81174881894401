import * as J1939 from 'utils/cs500/maps/J1939.json'

export const initMFIO = () => ({
  averageValue: 0,
  averageValueErrorMessage: '',
  bias: '',
  biasErrorMessage: '',
  decimals: '',
  decimalsErrorMessage: '',
  defaultPowerUp: false,
  defaultPowerUpErrorMessage: '',
  digThreshHigh: '',
  digThreshHighErrorMessage: '',
  digThreshLow: '',
  digThreshLowErrorMessage: '',
  expanded: false,
  inputRange: '',
  inputRangeErrorMessage: '',
  inputSignal: '',
  inputSignalErrorMessage: '',
  lastValue: 1,
  lastValueErrorMessage: '',
  logCycle: '',
  logCycleErrorMessage: '',
  logType: 1,
  logTypeErrorMessage: '',
  maxValue: 0,
  maxValueErrorMessage: '',
  minValue: 0,
  minValueErrorMessage: '',
  multiplier: '',
  multiplierErrorMessage: '',
  name: '',
  nameErrorMessage: '',
  offset: '',
  offsetErrorMessage: '',
  pinFunction: 0,
  pinFunctionErrorMessage: '',
  signalId: 0,
  unit: '',
  unitErrorMessage: ''
})

export const initSignal = () => ({
  address: '',
  addressErrorMessage: '',
  canId: '',
  canIdErrorMessage: '',
  canPort: '',
  canPortErrorMessage: '',
  editableId: false,
  expanded: false,
  frequency: '',
  frequencyErrorMessage: '',
  idMask: '',
  idMaskErrorMessage: '',
  lengthOfBits: '',
  lengthOfBitsErrorMessage: '',
  multiplier: '',
  multiplierErrorMessage: '',
  name: '',
  nameErrorMessage: '',
  offset: '',
  offsetErrorMessage: '',
  pgn: '',
  pgnName: '',
  priority: 0,
  priorityErrorMessage: '',
  selectedCanProtocolName: null,
  signalId: 2,
  signalIdErrorMessage: '',
  signed: false,
  sourceAddress: '0x00',
  sourceAddressErrorMessage: '',
  spn: '',
  spnName: '',
  startBit: '',
  startBitErrorMessage: '',
  unit: '',
  unitErrorMessage: ''
})

export const initialValues = () => ({
  modelName: '',
  modelNameErrorMessage: '',
  baudRate1: '',
  baudRate1ErrorMessage: '',
  baudRate2: '',
  baudRate2ErrorMessage: '',
  nodeId: '',
  nodeIdErrorMessage: '',
  dataLoggingEnable: true,
  loggingUploadPeriodEnable: true,
  loggingUploadPeriod: 300,
  loggingUploadPeriodErrorMessage: '',
  loggingBufferThresholdEnable: true,
  loggingBufferThreshold: 20,
  loggingBufferThresholdErrorMessage: '',
  gpsEnabled: false,
  gpsAltitude: false,
  gpsHeading: false,
  gpsSpeed: false,
  gpsFrequency: '',
  gpsFrequencyErrorMessage: '',
  shutdownDelay: 120,
  shutdownDelayErrorMessage: '',
  cycleTimeDelay: 0,
  cycleTimeDelayErrorMessage: '',
  powerManagementEnable: 0,
  wakeUpFlagEnable: false,
  MFIO: [],
  signals: [],
  protocolList: [
    { value: 'custom', label: '| Select a predefined protocol', type: 'custom' },
    { value: 'J1939', label: 'J1939', type: 'common' }
  ],
  isProtocolListLoading: false,
  protocols: { J1939: { type: J1939.protocol, data: J1939.signals } },
  protocolLoading: false,
  alertVisibility: 'hidden',
  alertMessageTitle: '',
  alertMessageText: '',
  machineUsagePercentage: 25,
  dataConsumptionEstimation: 0
})

export const transformValuesForAPI = values => {
  const mfioArray = values.MFIO.map(value => {
    const pinFunction = value.pinFunction
    let mfioItem

    switch (pinFunction) {
      case 0:
        mfioItem = {
          signalId: value.signalId,
          pinFunction: value.pinFunction
        }
        break

      case 1:
        mfioItem = {
          signalId: value.signalId,
          pinFunction: value.pinFunction,
          name: value.name,
          defaultPowerUp: value.defaultPowerUp,
          bias: 0
        }
        break

      case 2:
        mfioItem = {
          signalId: value.signalId,
          pinFunction: value.pinFunction,
          name: value.name,
          inputSignal: value.inputSignal,
          logCycle: value.logCycle,
          unit: value.unit,
          offset: value.offset,
          multiplier: value.multiplier ? parseFloat(value.multiplier) : 1,
          decimals: value.decimals ? value.decimals : 0,
          bias:
            value.inputSignal === 'Voltage' ||
            value.inputSignal === 'Digital Input' ||
            value.inputSignal === 'Frequency'
              ? value.bias
              : 0,
          inputRange:
            value.inputSignal === 'Voltage' ||
            value.inputSignal === 'Digital Input' ||
            value.inputSignal === 'Frequency'
              ? value.inputRange
              : 0
        }

        if (value.inputSignal === 'Digital Input') {
          mfioItem.digThreshHigh = value.digThreshHigh
          mfioItem.digThreshLow = value.digThreshLow
        }

        const logType = logTypeComposer(value.maxValue, value.minValue, value.averageValue, value.lastValue)
        if (logType !== 1) {
          mfioItem.logType = logType
        }
        break

      case 3:
        mfioItem = {
          signalId: value.signalId,
          pinFunction: value.pinFunction,
          name: value.name,
          bias: 0
        }
        break

      default:
        mfioItem = {
          signalId: value.signalId,
          pinFunction: value.pinFunction
        }
        break
    }
    return mfioItem
  })

  if (!mfioArray.find(mfio => mfio.signalId === 0)) {
    mfioArray.splice(0, 0, {
      signalId: 0,
      pinFunction: 0
    })
  }
  if (!mfioArray.find(mfio => mfio.signalId === 1)) {
    mfioArray.splice(1, 0, {
      signalId: 1,
      pinFunction: 0
    })
  }

  if (mfioArray[0].signalId === 1 && mfioArray[1].signalId === 0) {
    const firstItem = mfioArray[1]
    const secondItem = mfioArray[0]

    mfioArray[0] = firstItem
    mfioArray[1] = secondItem
  }

  return {
    name: values.modelName,
    nodeId: values.nodeId,
    baudRate1: values.baudRate1,
    baudRate2: values.baudRate2,
    dataLoggingEnable: values.dataLoggingEnable,
    loggingUploadPeriod: values.loggingUploadPeriod,
    loggingBufferThreshold: values.loggingBufferThreshold,

    powerManagementEnable: values.powerManagementEnable,
    shutdownDelay: values.shutdownDelay,
    cycleTimeDelay: values.cycleTimeDelay,

    gps: values.gpsEnabled
      ? {
        positionLogTime: values.gpsFrequency,
        altitudeLogTime: values.gpsAltitude ? values.gpsFrequency : 0,
        speedLogTime: values.gpsSpeed ? values.gpsFrequency : 0,
        headingLogTime: values.gpsHeading ? values.gpsFrequency : 0
      }
      : {
        positionLogTime: 0,
        altitudeLogTime: 0,
        speedLogTime: 0,
        headingLogTime: 0
      },
    MFIO: mfioArray,
    sensorsMap: values.signals.map(value => ({
      signalId: value.signalId,
      CANId: value.canId,
      CANPort: parseInt(value.canPort),
      address: value.address,
      frequency: value.frequency,
      idMask: value.idMask,
      lengthOfBits: value.lengthOfBits,
      multiplier: value.multiplier,
      name: value.name,
      offset: value.offset,
      signed: value.signed,
      startBit: value.startBit,
      unit: value.unit
    })),
    protocol: 'generic',
    mainFotaUpdateMode: 0,
    clamp15Frequency: 'STRING',
    clamp15Wakeup: 'STRING',
    clearLog: 0,
    flushing: 0
  }
}

export const transformValuesFromPCToAPI = values => {
  const mfioArray = Array.isArray(values.MFIO)
    ? values.MFIO.map(value => {
      const pinFunction = value.pinFunction
      let mfioItem

      switch (pinFunction) {
        case 0:
          mfioItem = {
            signalId: value.signalId,
            pinFunction: value.pinFunction
          }
          break

        case 1:
          mfioItem = {
            signalId: value.signalId,
            pinFunction: value.pinFunction,
            name: value.name,
            defaultPowerUp: value.defaultPowerUp,
            bias: 0
          }
          break
        case 2:
          mfioItem = {
            signalId: value.signalId,
            pinFunction: value.pinFunction,
            name: value.name,
            inputSignal: value.inputSignal,
            logCycle: value.logCycle,
            unit: value.unit,
            offset: value.offset,
            multiplier: parseFloat(value.multiplier) ? parseFloat(value.multiplier) : 1,
            decimals: parseInt(value.decimals) ? parseInt(value.decimals) : 0,
            bias:
                value.inputSignal === 'Voltage' ||
                value.inputSignal === 'Digital Input' ||
                value.inputSignal === 'Frequency'
                  ? value.bias
                  : 0,
            inputRange:
                value.inputSignal === 'Voltage' ||
                value.inputSignal === 'Digital Input' ||
                value.inputSignal === 'Frequency'
                  ? value.inputRange
                  : 0
          }

          if (value.inputSignal === 'Digital Input') {
            mfioItem.digThreshHigh = value.digThreshHigh
            mfioItem.digThreshLow = value.digThreshLow
          }

          const logType = value.logType
          if (logType && logType !== 1) {
            mfioItem.logType = logType
          }
          break

        case 3:
          mfioItem = {
            signalId: value.signalId,
            pinFunction: value.pinFunction,
            name: value.name,
            bias: 0
          }
          break

        default:
          mfioItem = {
            signalId: value.signalId,
            pinFunction: value.pinFunction
          }
          break
      }

      return mfioItem
    })
    : []

  if (!mfioArray.find(mfio => mfio.signalId === 0)) {
    mfioArray.splice(0, 0, {
      signalId: 0,
      pinFunction: 0
    })
  }
  if (!mfioArray.find(mfio => mfio.signalId === 1)) {
    mfioArray.splice(1, 0, {
      signalId: 1,
      pinFunction: 0
    })
  }

  if (mfioArray[0].signalId === 1 && mfioArray[1].signalId === 0) {
    const firstItem = mfioArray[1]
    const secondItem = mfioArray[0]

    mfioArray[0] = firstItem
    mfioArray[1] = secondItem
  }

  return {
    name: values.name,
    nodeId: values.nodeId,
    baudRate1: values.baudRate1,
    baudRate2: values.baudRate2,
    dataLoggingEnable: values.dataLoggingEnable,
    loggingUploadPeriod: values.loggingUploadPeriod,
    loggingBufferThreshold: values.loggingBufferThreshold,
    powerManagementEnable: values.powerManagementEnable ? values.powerManagementEnable : 0,
    shutdownDelay: values.shutdownDelay ? values.shutdownDelay : 120,
    cycleTimeDelay: values.cycleTimeDelay ? values.cycleTimeDelay : 0,
    gps: {
      positionLogTime: values.gps.positionLogTime ? values.gps.positionLogTime : 0,
      altitudeLogTime: values.gps.altitudeLogTime ? values.gps.altitudeLogTime : 0,
      speedLogTime: values.gps.speedLogTime ? values.gps.speedLogTime : 0,
      headingLogTime: values.gps.headingLogTime ? values.gps.headingLogTime : 0
    },
    MFIO: mfioArray,
    sensorsMap: values.sensorsMap.map(value => ({
      signalId: value.signalId,
      CANId: value.CANId,
      CANPort: value.CANPort,
      address: value.address,
      frequency: value.frequency,
      idMask: value.idMask,
      lengthOfBits: value.lengthOfBits,
      multiplier: value.multiplier,
      name: value.name,
      offset: value.offset,
      signed: Boolean(values.signed),
      startBit: value.startBit,
      unit: value.unit
    })),
    resetConfig: values.resetConfig ? values.resetConfig : 0,
    protocol: values.protocol ? values.protocol : 'generic',
    mainFotaUpdateMode: values.mainFotaUpdateMode ? values.mainFotaUpdateMode : 0,
    clamp15Frequency: values.clamp15Frequency ? values.clamp15Frequency : 'STRING',
    clamp15Wakeup: values.clamp15Wakeup ? values.clamp15Wakeup : 'STRING',
    clearLog: values.clearLog ? values.clearLog : 0,
    flushing: values.flushing ? values.flushing : 0
  }
}

export const transformValuesFromAPI = values => {
  const mfioArray = Array.isArray(values.MFIO)
    ? values.MFIO.map(value => {
      const pinFunction = value.pinFunction
      let mfioItem

      switch (pinFunction) {
        case 0:
          mfioItem = {
            signalId: value.signalId,
            pinFunction: value.pinFunction
          }
          break

        case 1:
          mfioItem = {
            signalId: value.signalId,
            pinFunction: value.pinFunction,
            name: value.name,
            defaultPowerUp: value.defaultPowerUp
          }
          break
        case 2:
          mfioItem = {
            signalId: value.signalId,
            pinFunction: value.pinFunction,
            name: value.name,
            inputSignal: value.inputSignal,
            logCycle: value.logCycle,
            unit: value.unit,
            offset: value.offset,
            multiplier: value.multiplier,
            decimals: value.decimals,
            bias: value.bias,
            inputRange: value.inputRange,
            digThreshHigh: value.digThreshHigh,
            digThreshLow: value.digThreshLow,
            logType: value.logType ? value.logType : 1
          }
          break

        case 3:
          mfioItem = {
            signalId: value.signalId,
            pinFunction: value.pinFunction,
            name: value.name
          }
          break

        default:
          mfioItem = {
            signalId: value.signalId,
            pinFunction: value.pinFunction
          }
          break
      }

      mfioItem.averageValue = logTypeToValues(mfioItem.logType ? mfioItem.logType : 1).averageValue
      mfioItem.averageValueErrorMessage = ''
      mfioItem.biasErrorMessage = ''
      mfioItem.decimalsErrorMessage = ''
      mfioItem.defaultPowerUpErrorMessage = ''
      mfioItem.digThreshHighErrorMessage = ''
      mfioItem.digThreshLowErrorMessage = ''
      mfioItem.expanded = false
      mfioItem.inputRangeErrorMessage = ''
      mfioItem.inputSignalErrorMessage = ''
      mfioItem.lastValue = logTypeToValues(mfioItem.logType ? mfioItem.logType : 1).lastValue
      mfioItem.lastValueErrorMessage = ''
      mfioItem.logCycleErrorMessage = ''
      mfioItem.logTypeErrorMessage = ''
      mfioItem.maxValue = logTypeToValues(mfioItem.logType ? mfioItem.logType : 1).maxValue
      mfioItem.maxValueErrorMessage = ''
      mfioItem.minValue = logTypeToValues(mfioItem.logType ? mfioItem.logType : 1).minValue
      mfioItem.minValueErrorMessage = ''
      mfioItem.multiplierErrorMessage = ''
      mfioItem.nameErrorMessage = ''
      mfioItem.offsetErrorMessage = ''
      mfioItem.pinFunctionErrorMessage = ''
      mfioItem.unitErrorMessage = ''

      return mfioItem
    })
    : []

  if (!mfioArray.find(mfio => mfio.signalId === 0)) {
    mfioArray.splice(0, 0, {
      signalId: 0,
      pinFunction: 0
    })
  }
  if (!mfioArray.find(mfio => mfio.signalId === 1)) {
    mfioArray.splice(1, 0, {
      signalId: 1,
      pinFunction: 0
    })
  }

  return {
    protocols: {},
    protocolLoading: false,
    modelName: values.name,
    modelNameErrorMessage: '',
    nodeId: values.nodeId,
    nodeIdErrorMessage: '',
    baudRate1: values.baudRate1,
    baudRate1ErrorMessage: '',
    baudRate2: values.baudRate2,
    baudRate2ErrorMessage: '',
    dataLoggingEnable: values.dataLoggingEnable,
    loggingUploadPeriod: values.loggingUploadPeriod,
    loggingUploadPeriodEnable: values.loggingUploadPeriod !== 0,
    loggingUploadPeriodErrorMessage: '',
    loggingBufferThreshold: values.loggingBufferThreshold,
    loggingBufferThresholdEnable: values.loggingBufferThreshold !== 0,
    loggingBufferThresholdErrorMessage: '',
    powerManagementEnable: values.powerManagementEnable,
    wakeUpFlagEnable: values.powerManagementEnable === 3 ? true : false,
    shutdownDelay: values.shutdownDelay,
    shutdownDelayErrorMessage: '',
    cycleTimeDelay: values.cycleTimeDelay,
    cycleTimeDelayErrorMessage: '',
    gpsEnabled:
      values.gps.headingLogTime !== 0 ||
      values.gps.altitudeLogTime !== 0 ||
      values.gps.speedLogTime !== 0 ||
      values.gps.positionLogTime !== 0,
    gpsAltitude: values.gps.altitudeLogTime !== 0,
    gpsHeading: values.gps.headingLogTime !== 0,
    gpsSpeed: values.gps.speedLogTime !== 0,
    gpsFrequency: values.gps.positionLogTime,
    gpsFrequencyErrorMessage: '',
    MFIO: mfioArray,
    signals: values.sensorsMap
      .filter(signal => signal.frequency !== 0)
      .map(signal => ({
        signalId: signal.signalId,
        signalIdErrorMessage: '',
        address: signal.address,
        addressErrorMessage: '',
        canId: signal.CANId,
        canIdErrorMessage: '',
        canPort: signal.CANPort,
        canPortErrorMessage: '',
        editableId: false,
        expanded: false,
        frequency: signal.frequency,
        frequencyErrorMessage: '',
        idMask: signal.idMask,
        idMaskErrorMessage: '',
        lengthOfBits: signal.lengthOfBits,
        lengthOfBitsErrorMessage: '',
        multiplier: signal.multiplier,
        multiplierErrorMessage: '',
        name: signal.name,
        nameErrorMessage: '',
        offset: signal.offset,
        offsetErrorMessage: '',
        pgn: '',
        pgnName: '',
        priority: 0,
        priorityErrorMessage: '',
        selectedCanProtocolName: null,
        signed: Boolean(signal.signed),
        sourceAddress: '0x00',
        sourceAddressErrorMessage: '',
        spn: '',
        spnName: '',
        startBit: signal.startBit,
        startBitErrorMessage: '',
        unit: signal.unit,
        unitErrorMessage: ''
      })),
    versionNumber: values.versionNumber,
    resetConfig: values.resetConfig ? values.resetConfig : 0,
    protocol: values.protocol ? values.protocol : 'generic',
    mainFotaUpdateMode: values.mainFotaUpdateMode ? values.mainFotaUpdateMode : 0,
    clamp15Frequency: values.clamp15Frequency ? values.clamp15Frequency : 'STRING',
    clamp15Wakeup: values.clamp15Wakeup ? values.clamp15Wakeup : 'STRING',
    clearLog: values.clearLog ? values.clearLog : 0,
    flushing: values.flushing ? values.flushing : 0
  }
}

export const logTypeComposer = (maxValue = 0, minValue = 0, averageValue = 0, lastValue = 1) => {
  let logType = maxValue.toString() + minValue.toString() + averageValue.toString() + lastValue.toString()
  logType = parseInt(logType, 2)
  return logType
}

export const logTypeToValues = logType => {
  const values = {
    maxValue: 0,
    minValue: 0,
    averageValue: 0,
    lastValue: 1
  }

  let binaryLogType = logType.toString(2)
  for (let i = binaryLogType.length; i < 4; i++) {
    binaryLogType = '0' + binaryLogType
  }

  values.maxValue = parseInt(binaryLogType[0])
  values.minValue = parseInt(binaryLogType[1])
  values.averageValue = parseInt(binaryLogType[2])
  values.lastValue = parseInt(binaryLogType[3])

  return values
}

export const canIdComposer = (priority, pgnData, sourceAddress) => {
  let pgn = pgnData.toString(2)
  for (let i = pgn.length; i < 18; i++) {
    pgn = '0' + pgn
  }

  let sa = parseInt(sourceAddress)
  sa = sa.toString(2)
  for (let i = sa.length; i < 8; i++) {
    sa = '0' + sa
  }

  let p = priority.toString(2)
  for (let i = p.length; i < 3; i++) {
    p = '0' + p
  }

  let canId = p + pgn + sa
  canId = parseInt(canId, 2).toString(16)
  for (let i = canId.length; i < 8; i++) {
    canId = '0' + canId
  }

  canId = '0x' + canId.toUpperCase()

  return canId
}

export const canIdModifier = (canId, sourceAddress) => {
  let sa = parseInt(sourceAddress).toString(16)
  for (let i = sa.length; i < 2; i++) {
    sa = '0' + sa
  }

  const canIdentifier = canId.substr(0, canId.length - 2) + sa.toUpperCase()
  return canIdentifier
}

export const decToHexCanId = (decCanId, isExtended = true) => {
  let hexCanId = decCanId.toString(16).toUpperCase()
  for (let i = hexCanId.length; i < (isExtended ? 8 : 3); i++) {
    hexCanId = '0' + hexCanId
  }
  hexCanId = '0x' + hexCanId
  return hexCanId
}
