import { capitalize } from 'lodash'

const mapMFIOToDynamicData = MFIOData => {
  let MFIOSignals = Array.isArray(MFIOData) ? MFIOData : []
  MFIOSignals = MFIOSignals.filter(signal => signal.pinFunction !== 0).map(signal => ({ ...signal, isMFIO: true }))
  return MFIOSignals
}

const mapGPSFieldToUnits = field => {
  const GPSFieldUnits = {
    altitude: 'm',
    speed: 'km/h',
    heading: '°'
  }
  return GPSFieldUnits[field]
}

const mapGPSToDynamicData = gpsData =>
  Object.entries(gpsData)
    .filter(([key, value]) => value > 0 && !(key.startsWith('position') || key.startsWith('heading')))
    .map(([key]) => {
      const signalId = key.replace('LogTime', '')
      const name = capitalize(signalId)
      const unit = mapGPSFieldToUnits(signalId)
      return {
        signalId,
        name,
        unit,
        isGPS: true
      }
    })

const generateDynamicData = (mfioAndCanBusSignals, gpsSignals) => {
  const mfioAndCanBusDynamicData = mfioAndCanBusSignals
    .filter(sensor => sensor.frequency !== 0)
    .map(sensor => {
      const sensorData = {
        signalId: sensor.signalId,
        name: sensor.name,
        unit: sensor.unit,
        ...typeof sensor.lengthOfBits !== 'undefined' && { lengthOfBits: sensor.lengthOfBits },
        multiplier: sensor.multiplier,
        offset: sensor.offset,
        ...typeof sensor.decimals !== 'undefined' && { decimals: sensor.decimals },
        ...typeof sensor.logType !== 'undefined' && { logType: sensor.logType },
        ...typeof sensor.isMFIO !== 'undefined' && { isMFIO: sensor.isMFIO },
        ...typeof sensor.divider !== 'undefined' && { divider: sensor.divider },
        ...typeof sensor.filterType !== 'undefined' && { filterType: sensor.filterType }
      }

      return sensorData
    })
  return [...gpsSignals, ...mfioAndCanBusDynamicData]
}

export const generateDeviceData = deviceData => {
  const data = Array.isArray(deviceData) ? deviceData[0] : deviceData

  const staticData = [
    {
      name: 'id',
      value: data.id
    },
    {
      name: 'EID',
      value: data.EID
    },
    {
      name: 'name',
      value: data.name
    },
    {
      name: 'description',
      value: data.description
    },
    {
      name: 'status',
      value: data.status?.name || data.status || ''
    },
    {
      name: 'lastActivityTime',
      value: data.lastActivityTime
    },
    {
      name: 'mainFirmwareVersion',
      value: data.mainFirmwareVersion
    },
    {
      name: 'mainFirmwareStatus',
      value: data.components?.mainFirmware?.status || data.components?.firmware?.status || ''
    },
    {
      name: 'mainFirmwareLastChangeTimestamp',
      value: data.components?.mainFirmware?.lastChangeTimestamp || data.components?.firmware?.lastChange || ''
    },
    {
      name: 'cellFirmwareVersion',
      value: data.cellFirmwareVersion
    },
    {
      name: 'cellFirmwareStatus',
      value: data.components?.cellFirmware?.status || data.components?.cell?.status || ''
    },
    {
      name: 'cellFirmwareLastChangeTimestamp',
      value: data.components?.cellFirmware?.lastChangeTimestamp || data.components?.cell?.lastChange || ''
    },
    {
      name: 'configurationStatus',
      value: data.components?.configuration?.status || ''
    },
    {
      name: 'configurationLastChangeTimestamp',
      value: data.components?.configuration?.lastChangeTimestamp || ''
    },
    {
      name: 'SimCardIMEI',
      value: data.simCard ? data.simCard.IMEI : data.SimCard ? data.SimCard.IMEI : ''
    },
    {
      name: 'SimCardICCID',
      value: data.simCard ? data.simCard.ICCID : data.SimCard ? data.SimCard.ICCID : ''
    },
    {
      name: 'deviceConfigurationName',
      value: data.deviceConfiguration?.name || data.Configuration?.name || ''
    },
    {
      name: 'startDateTime',
      value: data.operatingTime?.startDateTime || ''
    },
    {
      name: 'lastDateTime',
      value: data.operatingTime?.lastDateTime || ''
    },
    {
      name: 'totalActivityTime',
      value: data.operatingTime?.totalMinutes || ''
    },
    {
      name: 'deviceStatus',
      value: data.status?.name || ''
    },
    {
      name: 'deviceType',
      value: data.deviceType || data.device_type || ''
    },
    {
      name: 'hasConfiguration',
      value: Boolean(data.deviceConfiguration) || Boolean(data.Configuration)
    }
  ]

  const prop = data.deviceConfiguration ? 'deviceConfiguration' : 'Configuration'
  const deviceConfigData = data[prop]
  const dinamicDataId = deviceConfigData?.id || ''
  let dinamicData = []
  if (deviceConfigData) {
    const { MFIO = [], sensorsMap = [], gps = {} } = deviceConfigData
    const mfioSignals = mapMFIOToDynamicData(MFIO)
    const canBusSignals = sensorsMap
    const gpsSignals = mapGPSToDynamicData(gps)
    dinamicData = generateDynamicData([...mfioSignals, ...canBusSignals], gpsSignals)
  }
  return {
    staticData,
    dinamicDataId,
    dinamicData,
    advancedSignals: []
  }
}
