import PropTypes from 'prop-types'
import React from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

import SelectColumnsButton from 'components/SelectColumnsButton'
import { logError, mapDynamicDevicesFilterObject } from 'utils/http'
import reactBootstrapTable from 'utils/reactBootstrapTable'
import { utcTimeToBrowserLocal } from 'utils/timeFormat'

import ErrorAlert from '../../ErrorAlert'
import messages from '../../messages'

class AssignMachinesTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      devices: [],

      count: reactBootstrapTable.count,
      length: reactBootstrapTable.length,
      elementsPerPage: reactBootstrapTable.elementsPerPage,
      page: reactBootstrapTable.page,
      start: reactBootstrapTable.start,
      noDataText: <CircularProgress />,

      filter: {},
      sort: {},

      isErrorAlertShown: false,
      error: {},

      visibleColumns: {
        name: true,
        machineModel: true,
        machineType: true,
        machineBrand: false,
        machineSerialNumber: true,
        deviceType: true,
        eid: false,
        lastActivity: false,
        firmwareVersion: false,
        deviceConfiguration: true,
        deviceConfigurationVersion: false
      }
    }

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage
  }

  componentDidMount() {
    this.fetchDevices()
  }

  fetchDevices = () => {
    const { getGroupDevices, groupId } = this.props
    const { length, start, filter, sort } = this.state

    const deviceFields = {
      Device: [
        'id',
        'name',
        'device_type',
        'eid',
        'operating_time',
        'main_firmware_version',
        'created_at',
        'machine_model',
        'machine_type',
        'machine_brand',
        'machine_serial_number'
      ],
      Configuration: ['id', 'multi_functional_inputs', 'sensors_map', 'name', 'version_number', 'gps']
    }
    const mappedFilters = mapDynamicDevicesFilterObject(filter, sort)
    const params = [groupId, length, start, deviceFields, mappedFilters]

    getGroupDevices(...params)
      .then(response => {
        const { devices = [], total = 0 } = response.data

        this.fetchCs500Configuration(groupId, devices).then(cs500Configurations => {
          const groupDevicesWithConfigurations = devices.map(device => {
            const newDevice = { ...device }
            if (device.device_type === 'CS500' && device.eid in cs500Configurations) {
              newDevice.Configuration = cs500Configurations[device.eid].parsedConfiguration.deviceConfiguration
            }
            return newDevice
          })

          this.setState({
            count: total,
            devices: groupDevicesWithConfigurations,
            noDataText: this.formatMessage(messages.noMachinesText)
          })
        })
      })
      .catch(error => {
        this.setState({ error: { error }, isErrorAlertShown: true })
        logError(error)
      })
  }

  fetchCs500Configuration = (groupId, cs500Devices) => {
    const { fetchDeviceNonVolatileConfiguration } = this.props
    const cs500ConfigurationPromises = cs500Devices
      .map(device => {
        if (device.device_type === 'CS500') {
          return {
            EID: device.eid,
            config: fetchDeviceNonVolatileConfiguration(groupId, device.eid).catch(error => {
              // If there's an error fetching the configuration we shouldn't break the whole chain. So that's why we simply return an empty object.
              return {}
            })
          }
        } else {
          return null
        }
      })
      .filter(Boolean)

    return Promise.all(cs500ConfigurationPromises.map(item => item.config)).then(values => {
      const cs500Configurations = values.reduce((acc, value) => ({ ...acc, ...value }), {})
      return cs500Configurations
    })
  }

  getTableOptions = () => {
    const { elementsPerPage, length, page, noDataText } = this.state
    const tableOptions = {
      noDataText,

      // Page size select
      onSizePerPageList: this.handleSizePerPageList,
      sizePerPageList: elementsPerPage,
      sizePerPage: length,
      page,

      // Pagination
      onPageChange: this.handlePageChange,
      ignoreSinglePage: false,
      pageStartIndex: 1,
      paginationSize: 5,
      prePage: this.formatMessage(messages.prePage),
      nextPage: this.formatMessage(messages.nextPage),
      firstPage: this.formatMessage(messages.firstPage),
      lastPage: this.formatMessage(messages.lastPage),
      paginationShowsTotal: this.renderPaginationShowsTotal(this.formatMessage),
      paginationPosition: 'bottom',
      hideSizePerPage: false,
      alwaysShowAllBtns: false,
      withFirstAndLast: true,

      onSortChange: this.handleSortChange,
      onFilterChange: this.handleFilterChange
    }

    return tableOptions
  }

  handleClearSelectedDevicesClick = () => {
    const { onAssignedDevicesChange } = this.props

    this.setState({ error: {}, isErrorAlertShown: false })
    onAssignedDevicesChange([])
  }

  handleErrorAlertClose = () => {
    this.setState({ error: {}, isErrorAlertShown: false })
  }

  handlePageChange = (page, sizePerPage) => {
    this.setState(
      {
        start: (page - 1) * sizePerPage,
        page,
        length: sizePerPage,
        devices: [],
        noDataText: <CircularProgress />
      },
      () => {
        this.fetchDevices()
      }
    )
  }

  handleSizePerPageList = sizePerPage => {
    this.setState(
      {
        length: sizePerPage,
        devices: [],
        noDataText: <CircularProgress />
      },
      () => this.fetchDevices()
    )
  }

  handleFilterChange = filterObj => {
    this.setState(
      {
        filter: filterObj,
        devices: [],
        noDataText: <CircularProgress />
      },
      () => this.fetchDevices()
    )
  }

  handleSortChange = (sortName, sortOrder) => {
    if (sortName.includes('.')) {
      this.setState({ sort: {} })
    } else {
      this.setState(
        {
          sort: {
            sortColumnKey: sortName,
            sortColumnModel: 'Device',
            sortColumnOrder: sortOrder.toUpperCase()
          },
          devices: [],
          noDataText: <CircularProgress />
        },
        () => this.fetchDevices()
      )
    }
  }

  handleRowSelect = (row, isSelected) => {
    const { assignedDevices, onAssignedDevicesChange } = this.props

    let newAssignedDevices
    if (isSelected) {
      newAssignedDevices = [...assignedDevices, row]
    } else {
      newAssignedDevices = assignedDevices.filter(device => device.id !== row.id)
    }

    onAssignedDevicesChange(newAssignedDevices)
  }

  handleSelectAll = (isSelected, rows) => {
    const { assignedDevices, onAssignedDevicesChange } = this.props

    let newAssignedDevices
    if (isSelected) {
      newAssignedDevices = [...assignedDevices, ...rows]
    } else {
      const rowIds = rows.map(row => row.id)
      newAssignedDevices = assignedDevices.filter(device => !rowIds.includes(device.id))
    }

    onAssignedDevicesChange(newAssignedDevices)
  }

  handleChangeColumnVisibility = columnId => {
    this.setState(state => {
      const updatedColumnVisibility = !state.visibleColumns[columnId]
      return {
        visibleColumns: {
          ...state.visibleColumns,
          [columnId]: updatedColumnVisibility
        }
      }
    })
  }

  formatLastActivity = operatingTime => {
    return utcTimeToBrowserLocal(operatingTime.lastDateTime)
  }

  formatMachineField = cell => {
    return cell ? cell : '-'
  }

  formatActiveConfigurationColumn = cell => {
    return cell?.name ? cell.name : '-'
  }

  formatActiveConfigurationVersionColumn = cell => {
    return cell?.version_number ? cell.version_number : '-'
  }

  renderPaginationShowsTotal = formatMessage => (start, to, total) =>
    (
      <span>
        {`${formatMessage(messages.showingMachines)} ${start} ${formatMessage(messages.to)} ${to} ${formatMessage(
          messages.of
        )} ${total}`}
      </span>
    )

  render() {
    const { assignedDevices, assignedDevicesErrorText } = this.props
    const { count, devices, error, isErrorAlertShown, visibleColumns } = this.state

    const tableOptions = this.getTableOptions()
    const assignedDeviceIds = assignedDevices.map(device => device.id)

    const deviceTypeFilterOptions = {
      CS100: 'CS100',
      CS500: 'CS500',
      CS10: 'CS10'
    }

    const selectRowProp = {
      mode: 'checkbox',
      clickToSelect: false,
      onSelect: this.handleRowSelect,
      onSelectAll: this.handleSelectAll,
      bgColor: '#f5f5f5',
      selected: assignedDeviceIds
    }

    return (
      <Grid container item xs={12}>
        <Grid container item spacing={1} xs={12}>
          <Grid item xs={12}>
            <h3>{this.formatMessage(messages.assignMachines)}</h3>
          </Grid>
          <Grid item xs={12}>
            {this.formatMessage(messages.machineConditionText)}
          </Grid>
          <Grid item xs={12}>
            <ErrorAlert
              error={error}
              errorText={assignedDevicesErrorText}
              onClose={this.handleErrorAlertClose}
              show={isErrorAlertShown || assignedDevicesErrorText !== ''}
            />
          </Grid>
        </Grid>

        <Paper style={{ padding: 20, maxWidth: '100%' }}>
          <Grid container item spacing={1} xs={12}>
            <Grid item>
              <Button className='text-button' disabled>
                {this.formatMessage(messages.selected)} ({assignedDevices.length})
              </Button>
            </Grid>
            <Grid item>
              <Button
                className='secondary-action-button'
                disabled={assignedDevices.length === 0}
                onClick={this.handleClearSelectedDevicesClick}
              >
                {this.formatMessage(messages.clearSelection)}
              </Button>
            </Grid>
            <Grid item>
              <SelectColumnsButton
                columns={visibleColumns}
                onChangeColumnVisibility={this.handleChangeColumnVisibility}
              />
            </Grid>
          </Grid>
          <div className='table-with-pagination'>
            <BootstrapTable
              bordered={false}
              condensed={false}
              data={devices}
              exportCSV={false}
              fetchInfo={{ dataTotalSize: count }}
              hover
              keyField='id'
              multiColumnSearch={false}
              options={tableOptions}
              pagination
              remote={remoteObj => ({
                ...remoteObj,
                search: false,
                pagination: true,
                sizePerPage: false,
                filter: true
              })}
              search={false}
              searchPlaceholder={this.formatMessage(messages.searchPlaceholder)}
              selectRow={selectRowProp}
              striped={false}
            >
              <TableHeaderColumn dataField='id' hidden />
              <TableHeaderColumn
                dataField='name'
                dataSort
                filter={{
                  type: 'TextFilter',
                  delay: 400,
                  placeholder: `${this.formatMessage(messages.placeholder, {
                    column: this.formatMessage(messages.name).toLowerCase()
                  })}`
                }}
                hidden={!visibleColumns.name}
              >
                {this.formatMessage(messages.name)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField='machine_model'
                dataFormat={this.formatMachineField}
                dataSort
                filter={{
                  type: 'TextFilter',
                  delay: 400,
                  placeholder: `${this.formatMessage(messages.placeholder, {
                    column: this.formatMessage(messages.machineModel).toLowerCase()
                  })}`
                }}
                hidden={!visibleColumns.machineModel}
              >
                {this.formatMessage(messages.machineModel)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField='machine_type'
                dataFormat={this.formatMachineField}
                dataSort
                filter={{
                  type: 'TextFilter',
                  delay: 400,
                  placeholder: `${this.formatMessage(messages.placeholder, {
                    column: this.formatMessage(messages.machineType).toLowerCase()
                  })}`
                }}
                hidden={!visibleColumns.machineType}
              >
                {this.formatMessage(messages.machineType)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField='machine_brand'
                dataFormat={this.formatMachineField}
                dataSort
                filter={{
                  type: 'TextFilter',
                  delay: 400,
                  placeholder: `${this.formatMessage(messages.placeholder, {
                    column: this.formatMessage(messages.machineBrand).toLowerCase()
                  })}`
                }}
                hidden={!visibleColumns.machineBrand}
              >
                {this.formatMessage(messages.machineBrand)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField='machine_serial_number'
                dataFormat={this.formatMachineField}
                dataSort
                filter={{
                  type: 'TextFilter',
                  delay: 400,
                  placeholder: `${this.formatMessage(messages.placeholder, {
                    column: this.formatMessage(messages.machineSerialNumber).toLowerCase()
                  })}`
                }}
                hidden={!visibleColumns.machineSerialNumber}
              >
                {this.formatMessage(messages.machineSerialNumber)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField='device_type'
                dataSort
                filter={{
                  type: 'SelectFilter',
                  delay: 400,
                  options: deviceTypeFilterOptions,
                  placeholder: `${this.formatMessage(messages.placeholder, {
                    column: this.formatMessage(messages.deviceType).toLowerCase()
                  })}`
                }}
                hidden={!visibleColumns.deviceType}
              >
                {this.formatMessage(messages.deviceType)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField='eid'
                dataSort
                filter={{
                  type: 'TextFilter',
                  delay: 400,
                  placeholder: `${this.formatMessage(messages.placeholder, {
                    column: this.formatMessage(messages.eid).toLowerCase()
                  })}`
                }}
                hidden={!visibleColumns.eid}
              >
                {this.formatMessage(messages.eid)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField='operating_time'
                dataFormat={this.formatLastActivity}
                hidden={!visibleColumns.lastActivity}
              >
                {this.formatMessage(messages.lastActivity)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField='main_firmware_version'
                dataSort
                filter={{
                  type: 'TextFilter',
                  delay: 400,
                  placeholder: `${this.formatMessage(messages.placeholder, {
                    column: this.formatMessage(messages.firmwareVersion).toLowerCase()
                  })}`
                }}
                hidden={!visibleColumns.firmwareVersion}
              >
                {this.formatMessage(messages.firmwareVersion)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField='Configuration'
                dataFormat={this.formatActiveConfigurationColumn}
                filter={{
                  type: 'TextFilter',
                  delay: 400,
                  placeholder: `${this.formatMessage(messages.placeholder, {
                    column: this.formatMessage(messages.deviceConfiguration).toLowerCase()
                  })}`
                }}
                hidden={!visibleColumns.deviceConfiguration}
              >
                {this.formatMessage(messages.deviceConfiguration)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField='Configuration'
                dataFormat={this.formatActiveConfigurationVersionColumn}
                hidden={!visibleColumns.deviceConfigurationVersion}
              >
                {this.formatMessage(messages.deviceConfigurationVersion)}
              </TableHeaderColumn>
            </BootstrapTable>
          </div>
        </Paper>
      </Grid>
    )
  }
}

AssignMachinesTable.propTypes = {
  assignedDevices: PropTypes.array.isRequired,
  assignedDevicesErrorText: PropTypes.string.isRequired,
  fetchDeviceNonVolatileConfiguration: PropTypes.func.isRequired,
  getGroupDevices: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired }).isRequired,
  onAssignedDevicesChange: PropTypes.func.isRequired
}

export default injectIntl(AssignMachinesTable)
