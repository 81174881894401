import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'

import { client, logError } from 'utils/http'

import messages from './messages'

const NotificationsGlobalActions = props => {
  const {
    canViewAndEditNotifications,
    selectedNotifications,
    selectedNotificationsStatus,
    clearSelectedNotifications,
    redrawNotificationsTable,
    intl: { formatMessage }
  } = props

  const showAcknowledge =
    selectedNotificationsStatus.filter(status => {
      return status === 'open'
    }).length > 0

  const showUnacknowledge =
    selectedNotificationsStatus.filter(status => {
      return status === 'acknowledged'
    }).length > 0

  const showClose =
    selectedNotificationsStatus.filter(status => {
      return status === 'open' || status === 'acknowledged'
    }).length > 0

  const showReopen =
    selectedNotificationsStatus.filter(status => {
      return status === 'closed'
    }).length > 0

  let colorOfUnacknowledgeIcon = 'white'
  if (!showUnacknowledge || showUnacknowledge && (showAcknowledge || showReopen)) {
    colorOfUnacknowledgeIcon = 'silver'
  }

  function notificationClose() {
    if (selectedNotifications.length > -1) {
      selectedNotifications.map(notification => {
        let notificationToUpdate = {}
        notificationToUpdate = {
          hashId: notification.hashId,
          status: 'closed',
          criticality: notification.criticality,
          ruleInstanceHashId: notification.ruleInstanceHashId,
          ruleInstanceName: notification.ruleInstanceName,
          ruleInstanceDescription: notification.ruleInstanceDescription,
          source: notification.source,
          archived: notification.archived,
          version: notification.version,
          observations: 'closed',
          deviceType: notification.deviceType,
          deviceName: notification.deviceName
        }

        client
          .modifyNotificacion(notificationToUpdate)
          .then(response => {
            //Mandar mensaje al padre para que actualice la tabla de notificaciones
            redrawNotificationsTable(notificationToUpdate)
          })
          .catch(response => {
            logError(response)
          })

        return notification
      })

      clearSelectedNotifications()
    } else {
      return false
    }
  }

  function notificationReopen() {
    if (selectedNotifications.length > -1) {
      selectedNotifications.map(notification => {
        let notificationToUpdate = {}
        notificationToUpdate = {
          hashId: notification.hashId,
          status: 'open',
          criticality: notification.criticality,
          ruleInstanceHashId: notification.ruleInstanceHashId,
          ruleInstanceName: notification.ruleInstanceName,
          ruleInstanceDescription: notification.ruleInstanceDescription,
          source: notification.source,
          archived: notification.archived,
          version: notification.version,
          observations: 'reopened',
          deviceType: notification.deviceType,
          deviceName: notification.deviceName
        }
        client
          .modifyNotificacion(notificationToUpdate)
          .then(response => {
            //Mandar mensaje al padre para que actualice la tabla de notificaciones
            redrawNotificationsTable(notificationToUpdate)
          })
          .catch(response => {
            logError(response)
          })

        return notification
      })

      clearSelectedNotifications()
    } else {
      return false
    }
  }

  function notificationAcknowledge() {
    if (selectedNotifications.length > -1) {
      selectedNotifications.map(notification => {
        let notificationToUpdate = {}
        notificationToUpdate = {
          hashId: notification.hashId,
          status: 'acknowledged',
          criticality: notification.criticality,
          ruleInstanceHashId: notification.ruleInstanceHashId,
          ruleInstanceName: notification.ruleInstanceName,
          ruleInstanceDescription: notification.ruleInstanceDescription,
          source: notification.source,
          archived: notification.archived,
          version: notification.version,
          observations: 'acknowledged',
          deviceType: notification.deviceType,
          deviceName: notification.deviceName
        }

        client
          .modifyNotificacion(notificationToUpdate)
          .then(response => {
            //Mandar mensaje al padre para que actualice la tabla de notificaciones
            redrawNotificationsTable(notificationToUpdate)
          })
          .catch(response => {
            logError(response)
          })

        return notification
      })

      clearSelectedNotifications()
    } else {
      return false
    }
  }

  function notificationUnacknowledge() {
    if (selectedNotifications.length > -1) {
      selectedNotifications.map(notification => {
        let notificationToUpdate = {}
        notificationToUpdate = {
          hashId: notification.hashId,
          status: 'open',
          criticality: notification.criticality,
          ruleInstanceHashId: notification.ruleInstanceHashId,
          ruleInstanceName: notification.ruleInstanceName,
          ruleInstanceDescription: notification.ruleInstanceDescription,
          source: notification.source,
          archived: notification.archived,
          version: notification.version,
          observations: 'unacknowledged',
          deviceType: notification.deviceType,
          deviceName: notification.deviceName
        }

        client
          .modifyNotificacion(notificationToUpdate)
          .then(response => {
            //Mandar mensaje al padre para que actualice la tabla de notificaciones
            redrawNotificationsTable(notificationToUpdate)
          })
          .catch(response => {
            logError(response)
          })

        return notification
      })

      clearSelectedNotifications()
    } else {
      return false
    }
  }

  return (
    <Grid container>
      {canViewAndEditNotifications && (
        <Grid container item xs={12}>
          <Grid item xs={4}>
            <Button
              disabled
              style={{
                color: 'color: rgba(0, 0, 0, 0.87)',
                marginTop: 14,
                marginLeft: 5
              }}
            >
              {formatMessage(messages.selectedNotifications)} ({selectedNotifications.length})
            </Button>
            <Button
              className='secondary-action-button'
              onClick={clearSelectedNotifications}
              style={{
                marginLeft: 5,

                marginTop: 14
              }}
            >
              {formatMessage(messages.clearSelection)}
            </Button>
          </Grid>
          <Grid container item justify='flex-end' xs={8}>
            {/* eslint-disable react/jsx-no-bind */}
            <Button
              className='primary-action-button'
              disabled={!showUnacknowledge || showUnacknowledge && (showAcknowledge || showReopen)}
              onClick={notificationUnacknowledge}
              style={{
                marginTop: 14,
                marginLeft: 5
              }}
            >
              <span className='zmdi-hc-stack zmdi-hc'>
                <i className='zmdi zmdi-account zmdi-hc-stack-1x' style={{ color: colorOfUnacknowledgeIcon }} />
                <i className='zmdi zmdi-block zmdi-hc-stack-2x' style={{ color: colorOfUnacknowledgeIcon }} />
              </span>
              {formatMessage(messages.unacknowledge)}
            </Button>
            {/* eslint-enable */}

            {/* eslint-disable react/jsx-no-bind */}
            <Button
              className='primary-action-button'
              disabled={!showReopen || showReopen && (showClose || showAcknowledge || showUnacknowledge)}
              onClick={notificationReopen}
              style={{
                marginTop: 14,
                marginLeft: 5
              }}
            >
              <Icon className='zmdi zmdi-replay' />
              {formatMessage(messages.reopen)}
            </Button>
            {/* eslint-enable */}

            {/* eslint-disable react/jsx-no-bind */}
            <Button
              className='primary-action-button'
              disabled={!showAcknowledge || showAcknowledge && (showReopen || showUnacknowledge)}
              onClick={notificationAcknowledge}
              style={{
                marginTop: 14,
                marginLeft: 5
              }}
            >
              <Icon className='zmdi zmdi-account' />
              {formatMessage(messages.acknowledge)}
            </Button>
            {/* eslint-enable */}

            {/* eslint-disable react/jsx-no-bind */}
            <Button
              className='primary-action-button'
              disabled={!showClose || showClose && showReopen}
              onClick={notificationClose}
              style={{
                marginTop: 14,
                marginLeft: 5
              }}
            >
              <Icon className='zmdi zmdi-close-circle-o' />
              {formatMessage(messages.close)}
            </Button>
            {/* eslint-enable */}
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

NotificationsGlobalActions.propTypes = {
  canViewAndEditNotifications: PropTypes.bool.isRequired,
  clearSelectedNotifications: PropTypes.func.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired }).isRequired,
  redrawNotificationsTable: PropTypes.func.isRequired,
  selectedNotifications: PropTypes.array.isRequired,
  selectedNotificationsStatus: PropTypes.array.isRequired
}

export default injectIntl(NotificationsGlobalActions)
