import { utils as groupUtils } from 'ducks/groups'
import { actions as hactions } from 'utils/http'
import client from 'utils/http/client'

const fetchCSNodes =
  (groupId, limit = Infinity, offset = 0) =>
    dispatch =>
      (async () => {
        const numMaxOfDevice = Math.min(10, limit)
        let devices = []
        let count = 0
        let total = 0
        let internalOffset = offset
        let next = true
        while (next) {
          try {
            const devicesResponse = await dispatch(
              hactions.plus1Connect.getAllDevices(groupId, numMaxOfDevice, internalOffset)
            ).promise.then(({ payload }) => payload)
            devices = devices.concat(devicesResponse?.data.devices)
            count += devicesResponse.data.count
            internalOffset += devicesResponse.data.count
            total = devicesResponse.data.total
            next = devicesResponse.data.count === numMaxOfDevice && devices.length < Math.min(limit, total)
          } catch (error) {
            if (error.type) {
              return Promise.reject(error)
            } else {
              return Promise.resolve({ data: { count: 0, devices: [], total: 0 } })
            }
          }
        }
        return Promise.resolve({ data: { count, devices, total } })
      })()

/* eslint-disable max-params */
const fetchDynamicCSNodes =
  (groupId, limit = Infinity, offset = 0, extraQueryParameters = {}, filters = {}) =>
    dispatch =>
      (async () => {
        const parameterValues = Object.values(extraQueryParameters).reduce((acc, cur) => [...acc, ...cur], [])
        const numOfParamters = parameterValues.length
        const numMaxOfDevice = Math.min(Math.floor(2000 / numOfParamters), limit)
        let devices = []
        let count = 0
        let total = 0
        let internalOffset = offset
        let next = true
        while (next) {
          try {
            const devicesResponse = await dispatch(
              hactions.plus1Connect.getDynamicDevices(
                groupId,
                numMaxOfDevice,
                internalOffset,
                extraQueryParameters,
                filters
              )
            ).promise.then(({ payload }) => payload)
            devices = devices.concat(devicesResponse?.data.devices)
            count += devicesResponse.data.count
            internalOffset += devicesResponse.data.count
            total = devicesResponse.data.total
            next = devicesResponse.data.count === numMaxOfDevice && devices.length < Math.min(limit, total)
          } catch (error) {
            if (error.type) {
              return Promise.reject(error)
            } else {
              return Promise.resolve({ data: { count: 0, devices: [], total: 0 } })
            }
          }
        }
        return Promise.resolve({ data: { count, devices, total } })
      })()
/* eslint-enable */

const fetchCSNodesLocationsAndNotifications =
  (groupId, limit = Infinity, offset = 0) =>
    (dispatch, getState) =>
      (async () => {
        const numMaxOfDevice = Math.min(10, limit)
        let devices = []
        let count = 0
        let total = 0
        let internalOffset = offset
        let next = true
        let selectedGroupId = groupUtils.getSelectedGroup(getState()).id
        while (next && selectedGroupId === groupId) {
          try {
            const devicesResponse = await dispatch(
              hactions.plus1Connect.getAllDevicesAndLocations(groupId, numMaxOfDevice, internalOffset)
            ).promise.then(({ payload }) => payload)
            devices = devices.concat(devicesResponse ? devicesResponse.data.devices : [])
            count += devicesResponse ? devicesResponse.data.count : 0
            internalOffset += devicesResponse ? devicesResponse.data.count : 0
            total = devicesResponse ? devicesResponse.data.total : 0
            next = devicesResponse?.data.count === numMaxOfDevice && devices.length < Math.min(limit, total)
            selectedGroupId = groupUtils.getSelectedGroup(getState()).id
            // Notifications
            if (devices.length > 0) {
              const devicesEIDs = devices.map(device => device.EID)
              dispatch(client.searchNotificationsByDeviceEIDs(devicesEIDs))
            }
          } catch (e) {
            return Promise.reject(e)
          }
        }
        return Promise.resolve({ data: { count, devices, total } })
      })()

const fetchDynamicCSNodesLocationsAndNotifications =
  (groupId, limit = Infinity, offset = 0, extraQueryParameters) =>
    (dispatch, getState) =>
      (async () => {
        const numMaxOfDevice = Math.min(100, limit)
        let devices = []
        let count = 0
        let total = 0
        let internalOffset = offset
        let next = true
        let selectedGroupId = groupUtils.getSelectedGroup(getState()).id

        const deviceFields = {
          Device: ['id', 'name', 'description', 'eid', 'device_type', 'operating_time'],
          Configuration: ['name', 'version_number']
        }

        while (next && selectedGroupId === groupId) {
          try {
            const devicesResponse = await dispatch(
              hactions.plus1Connect.getDynamicDevicesLocationsNotifications(
                groupId,
                numMaxOfDevice,
                internalOffset,
                deviceFields
              )
            ).promise.then(({ payload }) => payload)
            devices = devices.concat(devicesResponse ? devicesResponse.data.devices : [])
            count += devicesResponse ? devicesResponse.data.count : 0
            internalOffset += devicesResponse ? devicesResponse.data.count : 0
            total = devicesResponse ? devicesResponse.data.total : 0
            next = devicesResponse?.data.count === numMaxOfDevice && devices.length < Math.min(limit, total)
            selectedGroupId = groupUtils.getSelectedGroup(getState()).id
            // Notifications
            if (devices.length > 0) {
              const devicesEIDs = devices.map(device => device.eid)
              dispatch(client.searchNotificationsByDeviceEIDs(devicesEIDs))
            }
          } catch (e) {
            return Promise.reject(e)
          }
        }
        return Promise.resolve({ data: { count, devices, total } })
      })()

const chunkArray = (myArray, chunk_size) => {
  var index = 0
  var arrayLength = myArray.length
  var tempArray = []

  let myChunk = []
  for (index = 0; index < arrayLength; index += chunk_size) {
    myChunk = myArray.slice(index, index + chunk_size)
    tempArray.push(myChunk)
  }

  return tempArray
}

const fetchCSNodesDetailsCall = (groupId, devicesEIDs) => dispatch => {
  const { promise } = dispatch(hactions.plus1Connect.getDevicesDetails(groupId, devicesEIDs))
  return promise.then(({ payload }) => {
    return payload
  })
}

const fetchCSNodesDetails = (groupId, devicesEIDs) => dispatch =>
  (async () => {
    //NOTE: Avoid to request more than 10 devices details in one call
    const numMaxOfDevice = 10
    let devices = []

    if (devicesEIDs.length <= numMaxOfDevice) {
      const devicesResponse = await dispatch(fetchCSNodesDetailsCall(groupId, devicesEIDs))
      devices = devicesResponse.data.devices
    } else {
      const devicesEIDsArrays = chunkArray(devicesEIDs, numMaxOfDevice)
      try {
        const devicesDetailsRequests = []
        let numOfDevicesDetailsArraysTreated = 0
        while (numOfDevicesDetailsArraysTreated < devicesEIDsArrays.length) {
          const currentArray = devicesEIDsArrays[numOfDevicesDetailsArraysTreated]
          devicesDetailsRequests.push(dispatch(fetchCSNodesDetailsCall(groupId, currentArray)))
          numOfDevicesDetailsArraysTreated = numOfDevicesDetailsArraysTreated + 1
        }
        const devicesDetailsResponses = await Promise.all(devicesDetailsRequests)
        devicesDetailsResponses.forEach(devicesDetailsResponse => {
          devices = devices.concat(devicesDetailsResponse.data.devices)
        })
      } catch (e) {
        return Promise.reject(e)
      }
    }

    return Promise.resolve({ data: { devices } })
  })()

const fetchCSNodeByEid = (eid, groupId) => (dispatch, getState) => {
  const { promise } = dispatch(hactions.plus1Connect.getDeviceByEid(eid, groupId))
  return promise.then(({ payload }) => {
    return payload
  })
}

const fetchCSNode = (id, groupId) => (dispatch, getState) => {
  const { promise } = dispatch(hactions.plus1Connect.getDeviceById(id, groupId))
  return promise.then(({ payload }) => {
    return payload
  })
}

const fetchCSNodeAndLocation = (id, groupId) => (dispatch, getState) => {
  const { promise } = dispatch(hactions.plus1Connect.getDeviceAndLocationById(id, groupId))
  return promise.then(({ payload }) => {
    return payload
  })
}

const fetchLastLocation = (id, nodeMac) => (dispatch, getState) => {
  const { promise } = dispatch(hactions.plus1Connect.getLastLocation(id, nodeMac))
  return promise.then(({ payload }) => {
    return payload
  })
}

const fetchNodeListLastLocation = (nodeMacList, page, size) => dispatch => {
  const { promise } = dispatch(hactions.plus1Connect.getLastLocationByNodeMacList(nodeMacList, page, size))
  return promise.then(({ payload }) => {
    return payload
  })
}

const fetchLastUpdate = id => (dispatch, getState) => {
  dispatch(fetchCSNode(id)).then(payload => {
    return payload ? payload.data.lastActivityTime : undefined
  })
}

const activateDevice = body => (dispatch, getState) => {
  const { promise } = dispatch(hactions.plus1Connect.activateDevice(body))
  return promise.then(({ payload }) => {
    return payload
  })
}

const unregisterCS100Device = eid => dispatch => {
  const { promise } = dispatch(hactions.plus1Connect.unregisterCS100Device(eid))
  return promise.then(({ payload }) => {
    return payload
  })
}

const updateDevice = (id, groupId, body) => (dispatch, getState) => {
  const { promise } = dispatch(hactions.plus1Connect.updateDevice(id, groupId, body))
  return promise.then(({ payload }) => {
    return payload
  })
}

const fetchDeviceSIMStatus = (groupId, deviceId) => (dispatch, getState) => {
  const { promise } = dispatch(hactions.plus1Connect.getDeviceSIMStatus(groupId, deviceId))
  return promise.then(({ payload }) => {
    return payload
  })
}

const testDeviceSIM = (groupId, deviceId) => (dispatch, getState) => {
  const { promise } = dispatch(hactions.plus1Connect.testDeviceSIM(groupId, deviceId))
  return promise.then(({ payload }) => {
    return payload
  })
}

const pauseDeviceSIM = (groupId, deviceId) => (dispatch, getState) => {
  const { promise } = dispatch(hactions.plus1Connect.pauseDeviceSIM(groupId, deviceId))
  return promise.then(({ payload }) => {
    return payload
  })
}

const fetchDeviceMetadata = (groupId, eid) => (dispatch, getState) => {
  const { promise } = dispatch(hactions.plus1Connect.getDeviceMetadata(groupId, eid))
  return promise.then(({ payload }) => {
    return payload
  })
}

const fetchDeviceNonVolatileConfiguration = (groupId, eid) => (dispatch, getState) => {
  const { promise } = dispatch(hactions.plus1Connect.getDeviceNonVolatileConfiguration(groupId, eid))
  return promise.then(({ payload }) => {
    return payload
  })
}

const setDeviceNonVolatileConfiguration = (groupId, eid, nvConfiguration) => (dispatch, getState) => {
  const { promise } = dispatch(hactions.plus1Connect.setDeviceNonVolatileConfiguration(groupId, eid, nvConfiguration))
  return promise.then(({ payload }) => {
    return payload
  })
}

const setCS500ValuePulse = (deviceEid, valuePulse, groupId, body) => dispatch => {
  const { promise } = dispatch(hactions.plus1Connect.setCS500ValuePulse(deviceEid, valuePulse, groupId, body))
  return promise.then(({ payload }) => {
    return payload
  })
}

export {
  activateDevice,
  fetchCSNode,
  fetchDynamicCSNodes,
  fetchCSNodeAndLocation,
  fetchCSNodeByEid,
  fetchCSNodes,
  fetchCSNodesDetails,
  fetchCSNodesLocationsAndNotifications,
  fetchDynamicCSNodesLocationsAndNotifications,
  fetchDeviceMetadata,
  fetchDeviceNonVolatileConfiguration,
  fetchDeviceSIMStatus,
  fetchLastLocation,
  fetchLastUpdate,
  fetchNodeListLastLocation,
  pauseDeviceSIM,
  setDeviceNonVolatileConfiguration,
  testDeviceSIM,
  unregisterCS100Device,
  updateDevice,
  setCS500ValuePulse
}
