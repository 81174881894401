import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'

import { devicesEndpointAdapter } from 'utils/adapters'

import ApplyConfigurationDialog from './ApplyConfigurationDialog'
import LoadConfigurationDialog from './LoadConfigurationDialog'
import NewConfigurationForm from './NewConfigurationForm'
import NewConfigurationFormTitle from './NewConfigurationFormTitle'
import { SELECT_DEVICES_MODE, UPDATE_CONFIG_MODE } from './constants'
import messages from './messages'
import { handleApplyConfigurationButtonClick, handleDownloadConfiguration, handleSelectDevices } from './utils'

class NewConfiguration extends React.Component {
  constructor(props) {
    super(props)
    const {
      intl: { formatMessage }
    } = props

    this.state = {
      alertMessagesText: '',
      alertMessagesTitle: '',
      alertMessagesType: 'danger',
      applyConfigurationDialogMode: SELECT_DEVICES_MODE,
      configAdapted: {},
      devices: [],
      isApplyConfigurationDialogOpen: false,
      isLoadConfigurationDialogOpen: false,
      selectedDevices: []
    }

    this.formatMessage = formatMessage
  }

  componentDidMount() {
    const { getCsNodes, groupId } = this.props

    const deviceFields = { Device: ['id', 'eid', 'device_type', 'name'] }
    const filterObject = {
      filters: {
        models: [
          {
            modelName: 'Device',
            columns: [{ device_type: 'CS500' }]
          }
        ]
      }
    }

    getCsNodes(groupId, Infinity, 0, deviceFields, filterObject)
      .then(response => {
        const { devices = [] } = response.data
        const mappedDevices = devicesEndpointAdapter(devices)
        this.setState({
          devices: mappedDevices
        })
      })
      .catch(() => {
        this.setState({
          alertMessagesText: this.formatMessage(messages.thereAreNoCS500),
          alertMessagesTitle: this.formatMessage(messages.generalError),
          alertMessagesType: 'danger',
          devices: []
        })
      })
  }

  componentWillUnmount() {
    const { history, location, resetLocalConfiguration } = this.props

    if (history.location.pathname !== location.pathname) {
      resetLocalConfiguration()
    }
  }

  setStateFunction = ({ ...newParameters }) => {
    this.setState(state => ({
      ...state,
      ...newParameters
    }))
  }

  handleApplyConfigurationDialogOpen = () => {
    this.setState({
      isApplyConfigurationDialogOpen: true,
      applyConfigurationDialogMode: SELECT_DEVICES_MODE
    })
  }

  handleApplyConfigurationDialogClose = reason => {
    const { onApplyConfiguration } = this.props
    const { applyConfigurationDialogMode } = this.state
    const redirectToConfigurationsTable = applyConfigurationDialogMode === UPDATE_CONFIG_MODE
    if (reason === 'backdropClick') {
      return false
    } else {
      this.setState(
        {
          alertMessages: false,
          alertMessagesText: '',
          alertMessagesTitle: '',
          alertMessagesType: 'danger',
          applyConfigurationDialogMode: SELECT_DEVICES_MODE,
          configAdapted: {},
          isApplyConfigurationDialogOpen: false,
          isLoadConfigurationDialogOpen: false,
          selectedDevices: []
        },
        () => redirectToConfigurationsTable && onApplyConfiguration()
      )
    }
  }

  handleLoadConfigurationDialogOpen = () => {
    this.setState({
      isLoadConfigurationDialogOpen: true
    })
  }

  handleLoadConfigurationDialogClose = reason => {
    if (reason === 'backdropClick') {
      return false
    } else {
      this.setState({
        alertMessages: false,
        alertMessagesText: '',
        alertMessagesTitle: '',
        alertMessagesType: 'danger',
        applyConfigurationDialogMode: SELECT_DEVICES_MODE,
        configAdapted: {},
        isApplyConfigurationDialogOpen: false,
        isLoadConfigurationDialogOpen: false,
        selectedDevices: []
      })
    }
  }

  render() {
    const {
      addLocalConfigurationCANMessage,
      addLocalConfigurationSignal,
      canEditDevice,
      configState,
      configurationsUrl,
      getDeviceNonVolatileConfiguration,
      groupId,
      isSidebarCollapsed,
      localConfigurationError,
      resetLocalConfiguration,
      setDeviceNonVolatileConfiguration,
      setLocalConfiguration
    } = this.props
    const {
      alertMessagesText,
      alertMessagesTitle,
      alertMessagesType,
      applyConfigurationDialogMode,
      configAdapted,
      devices,
      isApplyConfigurationDialogOpen,
      isLoadConfigurationDialogOpen,
      selectedDevices
    } = this.state

    return (
      <div
        className='content-container'
        id='newConfigurationContent'
        style={{ position: 'relative', overflowX: 'hidden', marginTop: '0px' }}
      >
        <div
          className='col-md-12'
          style={{
            backgroundColor: '#efefef',
            padding: isSidebarCollapsed ? '20px 95px 20px 35px' : '20px 255px 20px 35px',
            position: 'fixed',
            top: '50px',
            width: '100%',
            zIndex: 1000
          }}
        >
          <div className='col-md-9' style={{ height: '100%', padding: 0 }}>
            <NewConfigurationFormTitle configurationsUrl={configurationsUrl} />
          </div>
          <div className='col-md-3' style={{ textAlign: 'end', height: '100%', marginTop: 20, padding: 0 }}>
            <Button
              className='secondary-action-button'
              onClick={this.handleLoadConfigurationDialogOpen}
              style={{ minWidth: 100, marginRight: 10 }}
            >
              {this.formatMessage(messages.loadConfigFromPC)}
            </Button>
            <Button
              className='secondary-action-button'
              disabled={groupId === ''}
              onClick={() => handleDownloadConfiguration(configState, localConfigurationError, this.setStateFunction)}
              style={{ minWidth: 100, marginRight: 10 }}
            >
              {this.formatMessage(messages.download)}
            </Button>
            {canEditDevice && (
              <Button
                className='primary-action-button'
                disabled={groupId === ''}
                onClick={() =>
                  handleApplyConfigurationButtonClick(configState, localConfigurationError, this.setStateFunction)
                }
                style={{ minWidth: 100 }}
              >
                {this.formatMessage(messages.apply)}
              </Button>
            )}
          </div>
        </div>
        <div style={{ margin: '20px', paddingTop: '70px' }}>
          <NewConfigurationForm
            addLocalConfigurationCANMessage={addLocalConfigurationCANMessage}
            addLocalConfigurationSignal={addLocalConfigurationSignal}
            configState={configState}
          />
          <LoadConfigurationDialog
            dialogOpen={isLoadConfigurationDialogOpen}
            onDialogClose={this.handleLoadConfigurationDialogClose}
            resetLocalConfiguration={resetLocalConfiguration}
            setLocalConfiguration={setLocalConfiguration}
          />
          <ApplyConfigurationDialog
            alertMessagesText={alertMessagesText}
            alertMessagesTitle={alertMessagesTitle}
            alertMessagesType={alertMessagesType}
            canEditDevice={canEditDevice}
            config={configAdapted}
            devices={devices}
            dialogMode={applyConfigurationDialogMode}
            getDeviceNonVolatileConfiguration={getDeviceNonVolatileConfiguration}
            groupId={groupId}
            isDialogOpen={isApplyConfigurationDialogOpen}
            onDialogClose={this.handleApplyConfigurationDialogClose}
            selectDevices={handleSelectDevices}
            selectedDevices={selectedDevices}
            setDeviceNonVolatileConfiguration={setDeviceNonVolatileConfiguration}
            setState={this.setStateFunction}
            stateCopy={this.state}
          />
        </div>
      </div>
    )
  }
}

NewConfiguration.propTypes = {
  addLocalConfigurationCANMessage: PropTypes.func.isRequired,
  addLocalConfigurationSignal: PropTypes.func.isRequired,
  canEditDevice: PropTypes.bool.isRequired,
  configState: PropTypes.object.isRequired,
  configurationsUrl: PropTypes.string.isRequired,
  getCsNodes: PropTypes.func.isRequired,
  getDeviceNonVolatileConfiguration: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  isSidebarCollapsed: PropTypes.bool.isRequired,
  localConfigurationError: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  onApplyConfiguration: PropTypes.func.isRequired,
  resetLocalConfiguration: PropTypes.func.isRequired,
  setDeviceNonVolatileConfiguration: PropTypes.func.isRequired,
  setLocalConfiguration: PropTypes.func.isRequired
}

export default injectIntl(NewConfiguration)
