import { defineMessages } from 'react-intl'

const messages = defineMessages({
  importAdvancedSignals: {
    id: 'ImportAdvancedSignals.importAdvancedSignals',
    description: 'Import advanced signals',
    defaultMessage: 'Import advanced signals'
  },
  import: {
    id: 'ImportAdvancedSignals.import',
    description: 'Import button label',
    defaultMessage: 'Import'
  },
  dropFilesHereOrClickToUpload: {
    id: 'ImportAdvancedSignals.dropFilesHereOrClickToUpload',
    description: 'Drop files here or click to upload',
    defaultMessage: 'Drop files here or click to upload'
  },
  isNotJsonError: {
    id: 'ImportAdvancedSignals.isNotJsonError',
    description: 'Is not a json file error',
    defaultMessage: '\'\'{name}\'\' is not a json file'
  },
  exceedsMaximumError: {
    id: 'ImportAdvancedSignals.exceedsMaximumError',
    description: 'Exceed maximum file size error',
    defaultMessage: '\'\'{name}\'\' exceeds the maximum allowed file size of 10 MB'
  },
  contentNotValid: {
    id: 'ImportAdvancedSignals.contentNotValid',
    description: 'File content not valid',
    defaultMessage: '\'\'{name}\'\' file\'s content is not valid'
  },
  importError: {
    id: 'ImportAdvancedSignals.importError',
    description: 'Import error',
    defaultMessage: 'There was an error starting the importing process'
  },
  importSuccess: {
    id: 'ImportAdvancedSignals.importSuccess',
    description: 'Import success',
    defaultMessage: 'Advanced signals importing process started successfully'
  },
  warningAlertTitle: {
    id: 'ImportAdvancedSignals.warningAlertTitle',
    description: 'Warning alert title',
    defaultMessage: 'Before importing Advanced signals'
  },
  sameConfigDevices: {
    id: 'ImportAdvancedSignals.sameConfigDevices',
    description: 'Devices should have the same configuration warning',
    defaultMessage: 'Devices should have the {sameConfig}'
  },
  sameConfig: {
    id: 'ImportAdvancedSignals.sameConfig',
    description: 'Same configuration',
    defaultMessage: 'same configuration'
  },
  importWarning: {
    id: 'ImportAdvancedSignals.importWarning',
    description: 'Import warning',
    defaultMessage: 'Current Advanced signals will be {replaced} by the importing Advanced signals'
  },
  replaced: {
    id: 'ImportAdvancedSignals.replaced',
    description: 'replaced',
    defaultMessage: 'replaced'
  }
})

export default messages
