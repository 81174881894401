import React from 'react'
import PropTypes from 'prop-types'
import { bbox, lineString } from '@turf/turf'
import { injectIntl } from 'react-intl'
import WebMercatorViewport from 'viewport-mercator-project'

import QueryPanel from './QueryPanel'
import MapView from './MapView'
import { cleanEID } from '../../utils'

class Geotracking extends React.Component {
  constructor(props) {
    super(props)
    this.initialState = {
      currentPosition: { latitude: 43.295645, longitude: -1.980012 },
      currentZoom: 1.5,
      isRealtimeGpsTrackingRunning: false,
      selectedDevices: [],
      queriedDevices: [],
      step: 0,
      trail: 10
    }
    this.state = {
      ...this.initialState,
      deviceColors: []
    }

    this.mapContainerRef = React.createRef()
  }

  getChartPoints = () => {
    const { mergedAndOrderedGpsTrackings } = this.props
    const { deviceColors } = this.state

    const chartPoints = mergedAndOrderedGpsTrackings.map(gpsPoint => {
      const { color } = deviceColors.find(device => cleanEID(device.EID) === gpsPoint.node) || {}
      return {
        x: gpsPoint.x,
        color,
        deviceEID: gpsPoint.node
      }
    })

    return chartPoints
  }

  getChartPointLimits = chartPoints => {
    const { step, trail } = this.state
    const chartLastPointIndex = chartPoints.length - 1 - trail * step
    const chartFirstPointIndex = chartLastPointIndex - trail

    const from = chartPoints[chartFirstPointIndex]
      ? chartPoints[chartFirstPointIndex].x
      : chartFirstPointIndex < 0
        ? 0
        : undefined
    const to = chartPoints[chartLastPointIndex]
      ? chartPoints[chartLastPointIndex].x
      : chartLastPointIndex < 0
        ? 0
        : undefined

    return { from, to }
  }

  getFilteredAndOrderedGpsPoints = (gpsPoints, fromTimestamp, toTimestamp) => {
    const { isRealtimeGpsTrackingRunning } = this.state
    return gpsPoints
      .filter(
        (gpsPoint, index, self) =>
          isRealtimeGpsTrackingRunning ||
          self.length === 1 ||
          (fromTimestamp === 0 || gpsPoint.timestamp >= fromTimestamp) &&
            (toTimestamp === 0 || gpsPoint.timestamp <= toTimestamp)
      )
      .sort((pointA, pointB) => pointA.timestamp - pointB.timestamp)
  }

  resetInitialState = () => {
    this.setState(this.initialState)
  }

  handleDeviceColorsChange = deviceColors => {
    this.setState({ deviceColors })
  }

  handleSelectedDevicesChange = selectedDevices => {
    this.setState({
      selectedDevices
    })
  }

  handleQueriedDevicesChange = queriedDevices => {
    this.setState({
      queriedDevices
    })
  }

  handleTrailChange = value => {
    const { trail, step } = this.state

    const initialPos = trail * step
    this.setState({
      trail: value,
      step: initialPos / value
    })
  }

  handleStepChange = value => {
    this.setState({
      step: value
    })
  }

  handleFlyToMarkerClick = gpsTracking => {
    const chartPoints = this.getChartPoints()

    const height = this.mapContainerRef.current ? this.mapContainerRef.current.clientHeight : 800
    const width = this.mapContainerRef.current ? this.mapContainerRef.current.clientWidth : 1200

    const { from, to } = this.getChartPointLimits(chartPoints)

    const filteredAndOrderedGpsPoints = this.getFilteredAndOrderedGpsPoints(gpsTracking, from, to)

    let longitude
    let latitude
    let zoom

    if (filteredAndOrderedGpsPoints.length > 1) {
      const locations = filteredAndOrderedGpsPoints.map(gpsPoint => [gpsPoint.longitude, gpsPoint.latitude])

      const feature = lineString(locations)
      const [minLongitude, minLatitude, maxLongitude, maxLatitude] = bbox(feature)
      const viewport = new WebMercatorViewport({ width, height }).fitBounds(
        [
          [minLongitude, minLatitude],
          [maxLongitude, maxLatitude]
        ],
        {
          padding: {
            top: 40,
            bottom: 40,
            left: 40,
            right: 40
          }
        }
      )

      longitude = viewport.longitude
      latitude = viewport.latitude
      zoom = viewport.zoom
    } else if (filteredAndOrderedGpsPoints.length === 1) {
      longitude = filteredAndOrderedGpsPoints[0].longitude
      latitude = filteredAndOrderedGpsPoints[0].latitude
      zoom = 15
    }

    this.setState({
      currentPosition: {
        longitude,
        latitude
      },
      currentZoom: zoom + Number.EPSILON
    })
  }

  handleRealtimeGpsTrackingRunningChange = isRealtimeGpsTrackingRunning => {
    this.setState({ isRealtimeGpsTrackingRunning })
  }

  render() {
    const {
      currentPosition,
      currentZoom,
      deviceColors,
      isRealtimeGpsTrackingRunning,
      queriedDevices,
      selectedDevices,
      step,
      trail
    } = this.state

    return (
      <div className='content-container' id='content' style={{ height: 'calc(100vh - 50px)' }}>
        <div style={{ padding: 0, height: '100%', display: 'flex' }}>
          <QueryPanel
            deviceColors={deviceColors}
            isRealtimeGpsTrackingRunning={isRealtimeGpsTrackingRunning}
            onDeviceColorsChange={this.handleDeviceColorsChange}
            onFlyToMarkerClick={this.handleFlyToMarkerClick}
            onQueriedDevicesChange={this.handleQueriedDevicesChange}
            onRealtimeGpsTrackingRunningChange={this.handleRealtimeGpsTrackingRunningChange}
            onSelectedDevicesChange={this.handleSelectedDevicesChange}
            onTrailChange={this.handleTrailChange}
            queriedDevices={queriedDevices}
            resetInitialState={this.resetInitialState}
            selectedDevices={selectedDevices}
            trail={trail}
          />
          <MapView
            currentPosition={currentPosition}
            currentZoom={currentZoom}
            deviceColors={deviceColors}
            getChartPointLimits={this.getChartPointLimits}
            getChartPoints={this.getChartPoints}
            getFilteredAndOrderedGpsPoints={this.getFilteredAndOrderedGpsPoints}
            isRealtimeGpsTrackingRunning={isRealtimeGpsTrackingRunning}
            mapContainerRef={this.mapContainerRef}
            onFlyToMarkerClick={this.handleFlyToMarkerClick}
            onStepChange={this.handleStepChange}
            queriedDevices={queriedDevices}
            selectedDevices={selectedDevices}
            step={step}
            trail={trail}
          />
        </div>
      </div>
    )
  }
}

Geotracking.propTypes = {
  mergedAndOrderedGpsTrackings: PropTypes.array.isRequired
}

export default injectIntl(Geotracking)
