import React from 'react'
import PropTypes from 'prop-types'

import Logo from './Logo'
import { NotificationsBell } from 'components/notifications/components'

import LanguageDropdown from './LanguageDropdown'
import ProfileDropdown from './ProfileDropdown'
import SettingsDropdown from './SettingsDropdown'
import HelpDropdown from './HelpDropdown'

const iconSizes = { height: '44px' }

const Header = ({
  isCollapsed = false,
  showSettingsDropdown = false,
  canViewNotifications = false,
  appBarColor,
  appFontColor
}) => {
  const borderTop = appBarColor + ' solid 3px'
  const notificationsColor = appFontColor + '4d'
  return (
    <header className='header-container header-fixed bg-white'>
      <header className='top-header clearfix'>
        <Logo isCollapsed={isCollapsed} />
        <div
          className='top-nav danfoss-color'
          style={{
            backgroundColor: appBarColor,
            '--border-top': borderTop,
            '--app-font-color': appFontColor,
            '--notifications-color': notificationsColor
          }}
        >
          <ul className='nav-right pull-right list-unstyled'>
            <li>{canViewNotifications && <NotificationsBell color={appFontColor} />}</li>
            <li>{showSettingsDropdown && <SettingsDropdown style={{ ...iconSizes, color: appFontColor }} />}</li>
            <li>
              <HelpDropdown style={{ height: '44px', color: appFontColor }} />
            </li>
            <li>
              <ProfileDropdown color={appBarColor} fontColor={appFontColor} style={{ height: '44px' }} />
            </li>
            <li>
              <LanguageDropdown
                availableLanguages={JSON.parse(localStorage.getItem('availableLanguages'))}
                style={{ ...iconSizes, color: appFontColor }}
              />
            </li>
          </ul>
        </div>
      </header>
    </header>
  )
}

Header.propTypes = {
  appBarColor: PropTypes.string.isRequired,
  appFontColor: PropTypes.string.isRequired,
  canViewNotifications: PropTypes.bool.isRequired,
  isCollapsed: PropTypes.bool,
  showSettingsDropdown: PropTypes.bool.isRequired
}

Header.defaultProps = {
  isCollapsed: false
}

export default Header
