import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'

import Loading from 'components/Loading'
import ReactUploadFile from 'utils/fileHandler/ReactUploadFile'

import { PROTOCOL_TYPES } from '../constants'

import messages from './messages'

const styles = {
  dialog: {
    minWidth: 600
  },
  list: {
    maxHeight: 300,
    overflow: 'auto'
  }
}

class DbcFileImportFromPC extends React.Component {
  constructor(props) {
    super(props)
    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      addDbcFileFromPCDisabled: false,
      alertDeleteMessageText: '',
      alertDeleteMessageTitle: '',
      alertDeleteVisibility: 'hidden',
      alertMessageText: '',
      alertMessageTitle: '',
      alertMessageType: 'success',
      alertVisibility: 'hidden',
      dbcFileToImport: null,
      isValidFile: false,
      loaded: false,
      loadedDbcFileName: '',
      saveDbcFileAs: '',
      saveDbcFileAsError: '',
      saveDbcFileDisabled: true,
      showAddDbcFileFromPc: false
    }
  }

  componentDidUpdate = prevProps => {
    const { groupId, dialogOpen, getProtocols } = this.props

    if (groupId && prevProps.groupId !== groupId || !prevProps.dialogOpen && dialogOpen) {
      getProtocols(groupId)
    }
    if (prevProps.dialogOpen !== dialogOpen) {
      this.deleteData()
    }
  }

  handleAddDbcFileFromPC = () => {
    this.setState({
      showAddDbcFileFromPc: true,
      addDbcFileFromPCDisabled: true
    })
  }

  handleCloseAlert = () => {
    this.setState({
      alertVisibility: 'hidden',
      alertMessageTitle: '',
      alertMessageText: ''
    })
  }

  handleCloseDeleteAlert = () => {
    this.setState({
      alertDeleteVisibility: 'hidden',
      alertDeleteMessageTitle: '',
      alertDeleteMessageText: ''
    })
  }

  handleChangeDbcFileName = event => {
    const { isValidFile } = this.state

    const dbcFileName = event.target.value
    const saveDbcFileDisabled =
      event.target.value.trim().length === 0 ||
      event.target.value.length === 0 ||
      !event.target.value.toLowerCase().endsWith('.dbc') ||
      event.target.value.toUpperCase() === 'J1939.dbc'.toUpperCase()

    let saveDbcFileAsError = ''
    if (event.target.value.toUpperCase() === 'J1939.dbc'.toUpperCase()) {
      saveDbcFileAsError = 'J1939 name is reserved'
    }
    if (!event.target.value.toLowerCase().endsWith('.dbc')) {
      saveDbcFileAsError = 'It must end with \'.dbc\''
    }
    this.setState({
      saveDbcFileAs: dbcFileName,
      saveDbcFileDisabled: saveDbcFileDisabled || !isValidFile,
      saveDbcFileAsError
    })
  }

  deleteData = () => {
    this.setState({
      addDbcFileFromPCDisabled: false,
      alertDeleteMessageText: '',
      alertDeleteMessageTitle: '',
      alertDeleteMessageType: 'success',
      alertDeleteVisibility: 'hidden',
      alertMessageText: '',
      alertMessageTitle: '',
      alertMessageType: 'success',
      alertVisibility: 'hidden',
      dbcFileToImport: null,
      isValidFile: false,
      loaded: false,
      loadedDbcFileName: '',
      saveDbcFileAs: '',
      saveDbcFileAsError: '',
      saveDbcFileDisabled: true,
      showAddDbcFileFromPc: false
    })
  }

  handleUploadDbcFile = () => {
    const { uploadDbcFile, groupId, getProtocols } = this.props
    const { dbcFileToImport, saveDbcFileAs } = this.state

    const formData = new FormData()
    const dbcFile = new File([dbcFileToImport], saveDbcFileAs)

    formData.append('file', dbcFile)

    uploadDbcFile(groupId, formData)
      .then(() => {
        this.setState({
          isValidFile: false,
          saveDbcFileDisabled: true,
          alertVisibility: '',
          alertMessageType: 'success',
          alertMessageTitle: this.formatMessage(messages.fileSuccessfullyUploaded),
          alertMessageText: ''
        })
        getProtocols(groupId)
      })
      .catch(response => {
        const error = response.error.response

        this.setState({
          isValidFile: false,
          saveDbcFileDisabled: true,
          alertVisibility: '',
          alertMessageType: 'danger',
          alertMessageTitle: 'Error ' + error.status,
          alertMessageText: error.data.message
        })
      })
  }

  deleteProtocol = protocolName => {
    const { groupId, deleteProtocol, getProtocols } = this.props

    deleteProtocol(groupId, protocolName)
      .then(() => {
        this.setState({
          alertDeleteVisibility: '',
          alertDeleteMessageType: 'success',
          alertDeleteMessageTitle: protocolName + ' ' + this.formatMessage(messages.fileSuccessfullyDeleted),
          alertDeleteMessageText: ''
        })
        getProtocols(groupId)
      })
      .catch(response => {
        const error = response.error.response
        this.setState({
          alertDeleteVisibility: '',
          alertDeleteMessageType: 'danger',
          alertDeleteMessageTitle: 'Error ' + error.status,
          alertDeleteMessageText: error.data.message
        })
      })
  }

  render() {
    const uploadButtonOptions = {
      baseUrl: process.env.REACT_APP_HTTP_API,
      multiple: false,
      numberLimit: 1,
      accept: '.dbc',
      fileFieldName: 'file',

      beforeUpload: files => {
        if (files[0].size === 0) {
          this.setState({
            loaded: false,
            isValidFile: false,
            loadedDbcFileName: '',
            dbcFileToImport: null,
            saveDbcFileDisabled: true,
            alertVisibility: '',
            alertMessageType: 'danger',
            alertMessageTitle: this.formatMessage(messages.invalidDbcFile) + ' ' + files[0].name,
            alertMessageText: this.formatMessage(messages.emptyFile)
          })
          return false
        }
        if (!files[0].name.toLowerCase().endsWith('.dbc')) {
          this.setState({
            loaded: false,
            isValidFile: false,
            loadedDbcFileName: '',
            dbcFileToImport: null,
            saveDbcFileDisabled: true,
            alertVisibility: '',
            alertMessageType: 'danger',
            alertMessageTitle: this.formatMessage(messages.invalidDbcFile) + ' ' + files[0].name,
            alertMessageText: this.formatMessage(messages.noDbcFormat)
          })
          return false
        }
        if (files[0].size > 0) {
          return true
        }

        return false
      },
      didLoad: pcFile => {
        const reader = new FileReader()
        reader.readAsText(pcFile)
        reader.onload = () => {
          this.setState({
            loaded: true,
            isValidFile: true,
            loadedDbcFileName: pcFile.name,
            saveDbcFileAs: pcFile.name,
            dbcFileToImport: pcFile,
            saveDbcFileDisabled: false,
            alertVisibility: '',
            alertMessageType: 'success',
            alertMessageTitle: this.formatMessage(messages.fileSuccessfullyLoaded),
            alertMessageText: ''
          })
        }
      }
    }

    const { classes, dialogOpen, intl, isProtocolListLoading, onDialogClose, protocolList } = this.props
    const {
      addDbcFileFromPCDisabled,
      alertDeleteMessageText,
      alertDeleteMessageTitle,
      alertDeleteMessageType,
      alertDeleteVisibility,
      alertMessageText,
      alertMessageTitle,
      alertMessageType,
      alertVisibility,
      loaded,
      loadedDbcFileName,
      saveDbcFileAsError,
      saveDbcFileDisabled,
      showAddDbcFileFromPc,
      saveDbcFileAs
    } = this.state

    /* eslint-disable react/jsx-key */
    return (
      <Dialog classes={{ paper: classes.dialog }} onClose={onDialogClose} open={dialogOpen}>
        <DialogContent style={{ padding: '12px 10px 10px 12px' }}>
          <DialogTitle style={{ padding: 12 }}>
            <IconButton
              onClick={onDialogClose}
              style={{
                position: 'absolute',
                right: 3,
                top: 3,
                padding: 5
              }}
            >
              <CloseIcon />
            </IconButton>
            {this.formatMessage(messages.availableProtocols)}
          </DialogTitle>
          <div className='col-md-12' style={{ padding: '12px 12px 0 12px' }}>
            <div className={alertDeleteVisibility}>
              <div className={'alert alert-' + alertDeleteMessageType + ' alert-dismissible animated fadeIn'}>
                <button aria-label='Close' className='close' onClick={this.handleCloseDeleteAlert}>
                  <span aria-hidden='true'>×</span>
                </button>
                {alertDeleteMessageTitle !== '' && <h4>{alertDeleteMessageTitle}</h4>}
                <span className='h5'>{alertDeleteMessageText}</span>
              </div>
            </div>
          </div>
          {isProtocolListLoading && <Loading />}
          <div className='col-md-12' style={{ padding: 0 }}>
            <List classes={{ root: classes.list }}>
              {!isProtocolListLoading &&
                protocolList
                  .filter(protocol => protocol.type !== PROTOCOL_TYPES.CUSTOM)
                  .map(protocol => (
                    <ListItem key={protocol.value}>
                      <ListItemText primary={protocol.value} />
                      {protocol.type !== PROTOCOL_TYPES.COMMON && (
                        <ListItemSecondaryAction>
                          <IconButton
                            aria-label='Delete'
                            onClick={() => this.deleteProtocol(protocol.value)}
                            style={{ padding: 5 }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      )}
                    </ListItem>
                  ))}
            </List>
          </div>
          {!isProtocolListLoading && protocolList.length === 0 && (
            <DialogContentText color='textPrimary' style={{ padding: 12 }}>
              {this.formatMessage(messages.thereAreNoAvailableProtocols)}
            </DialogContentText>
          )}
          <div style={{ width: '100%', padding: '0 12px 12px 0', textAlign: 'left', color: '#1295d5' }}>
            <Button
              className='text-button'
              disabled={addDbcFileFromPCDisabled}
              onClick={this.handleAddDbcFileFromPC}
              style={{ padding: 12 }}
            >
              {this.formatMessage(messages.addDbcFile)}
            </Button>
          </div>
          {showAddDbcFileFromPc && (
            <div style={{ padding: 0 }}>
              <Divider />
              <DialogTitle style={{ padding: 12 }}>{this.formatMessage(messages.selectDbcFileFromPC)}</DialogTitle>
              <div className='col-md-12' style={{ padding: '0 0 0 12px' }}>
                <ReactUploadFile
                  chooseFileButton={
                    <Button className='secondary-action-button'>{this.formatMessage(messages.browse)}</Button>
                  }
                  intl={intl}
                  options={uploadButtonOptions}
                  style={{ padding: 0 }}
                />
              </div>
              <div className='col-md-12' style={{ padding: '12px 12px 0 12px' }}>
                <div className={alertVisibility}>
                  <div className={'alert alert-' + alertMessageType + ' alert-dismissible animated fadeIn'}>
                    <button aria-label='Close' className='close' onClick={this.handleCloseAlert}>
                      <span aria-hidden='true'>×</span>
                    </button>
                    {alertMessageTitle !== '' && <h4>{alertMessageTitle}</h4>}
                    <span className='h5'>{alertMessageText}</span>
                  </div>
                </div>
              </div>
              {loaded && (
                <div className='col-md-12' style={{ padding: 12 }}>
                  <DialogContentText color='textPrimary'>{this.formatMessage(messages.loadedFile)}</DialogContentText>
                  <DialogContentText color='textPrimary' style={{ paddingBottom: 12 }}>
                    {loadedDbcFileName}
                  </DialogContentText>
                  <TextField
                    error={saveDbcFileAsError !== ''}
                    helperText={saveDbcFileAsError}
                    label={this.formatMessage(messages.saveDbcFileAs)}
                    onChange={this.handleChangeDbcFileName}
                    placeholder={this.formatMessage(messages.dbcFileName)}
                    value={saveDbcFileAs}
                  />
                </div>
              )}
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button className='primary-action-button' disabled={saveDbcFileDisabled} onClick={this.handleUploadDbcFile}>
            {this.formatMessage(messages.saveDbcFile)}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

DbcFileImportFromPC.propTypes = {
  classes: PropTypes.object.isRequired,
  deleteProtocol: PropTypes.func.isRequired,
  dialogOpen: PropTypes.bool.isRequired,
  getProtocols: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  isProtocolListLoading: PropTypes.bool.isRequired,
  onDialogClose: PropTypes.func.isRequired,
  protocolList: PropTypes.array.isRequired,
  uploadDbcFile: PropTypes.func.isRequired
}

export default withStyles(styles)(injectIntl(DbcFileImportFromPC))
