import { capitalize, isObject } from 'lodash'

export const devicesEndpointAdapter = devices => {
  const snakeCaseFields = [
    'device_type',
    'creation_date',
    'version_number',
    'updated_at',
    'main_firmware_version',
    'cell_firmware_version',
    'operating_time',
    'sensors_map'
  ]

  const lowerCaseFields = ['eid', 'imei', 'iccid']

  const specialFieldsMappingObject = {
    Configuration: 'deviceConfiguration',
    multi_functional_inputs: 'MFIO',
    firmware: 'mainFirmware',
    lastChange: 'lastChangeTimestamp',
    cell: 'cellFirmware'
  }

  const mapKeys = object =>
    Object.entries(object).reduce((acc, [key, value]) => {
      let newKey = key
      let newValue = value
      if (key === 'status_id') {
        newKey = 'status'
        newValue = 'activated'
      } else if (snakeCaseFields.includes(key)) {
        const [head, ...rest] = key.split('_')
        const camelCasedRest = rest.map(strg => capitalize(strg)).join('')
        newKey = head + camelCasedRest
      } else if (lowerCaseFields.includes(key)) {
        newKey = key.toUpperCase()
      } else if (specialFieldsMappingObject[key]) {
        newKey = specialFieldsMappingObject[key]
      }

      if (isObject(value) && !Array.isArray(value)) newValue = mapKeys(value)

      return { ...acc, [newKey]: newValue }
    }, {})

  return devices.map(device => {
    return mapKeys(device)
  })
}
