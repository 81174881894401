import { defineMessages } from 'react-intl'

const messages = defineMessages({
  eid: {
    id: 'NodePanel.eid',
    description: 'EID field label',
    defaultMessage: 'EID'
  },
  activeConfiguration: {
    id: 'NodePanel.activeConfiguration',
    description: 'activeConfiguration date field label',
    defaultMessage: 'Active configuration'
  },
  description: {
    id: 'NodePanel.description',
    description: 'description field label',
    defaultMessage: 'Description'
  },
  lastMessageDate: {
    id: 'NodePanel.lastMessageDate',
    description: 'Last message date field label',
    defaultMessage: 'Last message date'
  },
  actions: {
    id: 'NodePanel.actions',
    description: 'Actions field label',
    defaultMessage: 'Actions'
  },
  never: {
    id: 'NodePanel.never',
    description: 'Never last checked date option',
    defaultMessage: 'Never'
  },
  ago: {
    id: 'NodePanel.ago',
    description: 'Some time ago last checked date option',
    defaultMessage: '{duration} ago'
  }
})

export default messages
