import { defineMessages } from 'react-intl'

const messages = defineMessages({
  deviceSettings: {
    id: 'DeviceSettingsCS500.deviceSettings',
    description: 'Device settings card title',
    defaultMessage: 'Device settings'
  },
  nodeId0: {
    id: 'DeviceSettingsCS500.nodeId0',
    description: 'CAN port 0 node ID input label',
    defaultMessage: 'CAN port 0 node ID'
  },
  nodeId1: {
    id: 'DeviceSettingsCS500.nodeId1',
    description: 'CAN port 1 node ID input label',
    defaultMessage: 'CAN port 1 node ID'
  },
  baudRate0: {
    id: 'DeviceSettingsCS500.baudRate0',
    description: 'CAN port 0 baud rate select label',
    defaultMessage: 'CAN port 0 baud rate (kbps)'
  },
  baudRate1: {
    id: 'DeviceSettingsCS500.baudRate1',
    description: 'CAN port 1 baud rate select label',
    defaultMessage: 'CAN port 1 baud rate (kbps)'
  },
  sa0: {
    id: 'DeviceSettingsCS500.sa0',
    description: 'CAN port 0 source address input label',
    defaultMessage: 'CAN port 0 source address'
  },
  sa1: {
    id: 'DeviceSettingsCS500.sa1',
    description: 'CAN port 1 source address input label',
    defaultMessage: 'CAN port 1 source address'
  },
  loggingUploadPeriod0: {
    id: 'DeviceSettingsCS500.loggingUploadPeriod0',
    description: 'Cloud buffer 0 upload period input label',
    defaultMessage: 'Cloud buffer 0 upload period (s)'
  },
  loggingUploadPeriod1: {
    id: 'DeviceSettingsCS500.loggingUploadPeriod1',
    description: 'Cloud buffer 1 upload period input label',
    defaultMessage: 'Cloud buffer 1 upload period (s)'
  },
  buffer0explanation: {
    id: 'DeviceSettingsCS500.buffer0explanation',
    description: 'Virtual signals',
    defaultMessage: 'Virtual signals'
  },
  buffer1explanation: {
    id: 'DeviceSettingsCS500.buffer1explanation',
    description: 'DM1, Geofence, GNSS & Diagnostic data',
    defaultMessage: 'DM1, GNSS & Diagnostic data'
  },
  interlinkEnabled: {
    id: 'DeviceSettingsCS500.interlinkEnabled',
    description: 'Enable PLUS+1 Interlink proxy connection',
    defaultMessage: 'Enable PLUS+1 Interlink proxy connection'
  },
  interlinkEnabledExplanation: {
    id: 'DeviceSettingsCS500.interlinkEnabledExplanation',
    description: 'Enable PLUS+1 Interlink proxy connection tooltip text',
    defaultMessage: 'Checking this option the device will be accessible via the Internet'
  },
  fotaUpdateEnabled: {
    id: 'DeviceSettingsCS500.fotaUpdateEnabled',
    description: 'Enable FOTA update',
    defaultMessage: 'Enable FOTA update'
  },
  restartCellularModule: {
    id: 'DeviceSettingsCS500.restartCellularModule',
    description: 'Restart cellular module',
    defaultMessage: 'Restart cellular module'
  },
  restartCellularModuleExplanation: {
    id: 'DeviceSettingsCS500.restartCellularModuleExplanation',
    description: 'Restart cellular module tooltip text',
    defaultMessage:
      'The cellular module gets restarted if no connection to the IoT-Hub and Diagnostic proxy is possible'
  },
  true: {
    id: 'DeviceSettingsCS500.true',
    description: 'True',
    defaultMessage: 'True'
  },
  false: {
    id: 'DeviceSettingsCS500.false',
    description: 'False',
    defaultMessage: 'False'
  }
  // cellularPriority: {
  //   id: 'DeviceSettingsCS500.cellularPriority',
  //   description: 'Behaviour at active diagnostic session',
  //   defaultMessage: 'Behaviour at active diagnostic session'
  // }
})

export default messages
