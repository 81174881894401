import React from 'react'
import LinkedLogo from './LinkedLogo'
import './styles.css'

const Logo = ({ isCollapsed = false, logo, mapUrl }) => {
//const Logo = ({ logo, mapUrl }) => {
  return (
    <div className="logo">
    	<LinkedLogo link={mapUrl} isCollapsed={isCollapsed} logo={logo} />
      {/*<LinkedLogo link={mapUrl} logo={logo} />*/}
    </div>
  )
}

export { Logo }
