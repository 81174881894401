import { defineMessages } from 'react-intl'

const messages = defineMessages({
  gnssConditions: {
    id: 'GNSSGeofenceConditionsCS500.gnssConditions',
    description: 'GNSS conditions card title',
    defaultMessage: 'GNSS conditions'
  },
  sendGNSSPosition: {
    id: 'GNSSGeofenceConditionsCS500.sendGNSSPosition',
    description: 'sendGNSSPosition checkbox label',
    defaultMessage: 'Send GNSS position'
  },
  positionLogTime: {
    id: 'GNSSGeofenceConditionsCS500.positionLogTime',
    description: 'positionLogTime input label',
    defaultMessage: 'Position log time (s)'
  },
  sendGNSSAltitude: {
    id: 'GNSSGeofenceConditionsCS500.sendGNSSAltitude',
    description: 'sendGNSSAltitude checkbox label',
    defaultMessage: 'Send GNSS altitude'
  },
  altitudeLogTime: {
    id: 'GNSSGeofenceConditionsCS500.altitudeLogTime',
    description: 'altitudeLogTime input label',
    defaultMessage: 'Altitude log time (s)'
  },
  sendGNSSSpeed: {
    id: 'GNSSGeofenceConditionsCS500.sendGNSSSpeed',
    description: 'sendGNSSSpeed checkbox label',
    defaultMessage: 'Send GNSS speed'
  },
  speedLogTime: {
    id: 'GNSSGeofenceConditionsCS500.speedLogTime',
    description: 'speedLogTime input label',
    defaultMessage: 'Speed log time (s)'
  },
  sendGNSSHeading: {
    id: 'GNSSGeofenceConditionsCS500.sendGNSSHeading',
    description: 'sendGNSSHeading checkbox label',
    defaultMessage: 'Send GNSS heading'
  },
  headingLogTime: {
    id: 'GNSSGeofenceConditionsCS500.headingLogTime',
    description: 'headingLogTime input label',
    defaultMessage: 'Heading log time (s)'
  },
  geofenceConditions: {
    id: 'GNSSGeofenceConditionsCS500.geofenceConditions',
    description: 'Geofence conditions card title',
    defaultMessage: 'Geofence conditions'
  },
  enableGeofence: {
    id: 'GNSSGeofenceConditionsCS500.enableGeofence',
    description: 'enableGeofence checkbox label',
    defaultMessage: 'Enable geofence'
  },
  geofenceEnabled: {
    id: 'GNSSGeofenceConditionsCS500.geofenceEnabled',
    description: 'Geofence enabled',
    defaultMessage: 'Geofence enabled'
  },
  latitude: {
    id: 'GNSSGeofenceConditionsCS500.latitude',
    description: 'Latitude / 0.0000001°',
    defaultMessage: 'Latitude / 0.0000001°'
  },
  longitude: {
    id: 'GNSSGeofenceConditionsCS500.longitude',
    description: 'Longitude / 0.0000001°',
    defaultMessage: 'Longitude / 0.0000001°'
  },
  radius: {
    id: 'GNSSGeofenceConditionsCS500.radius',
    description: 'radius',
    defaultMessage: 'Radius (m)'
  },
  geofenceLogToPortal: {
    id: 'GNSSGeofenceConditionsCS500.geofenceLogToPortal',
    description: 'Log to portal',
    defaultMessage: 'Log to portal'
  },
  geofenceSamplePeriod: {
    id: 'GNSSGeofenceConditionsCS500.geofenceSamplePeriod',
    description: 'Sample period',
    defaultMessage: 'Sample period (s)'
  },
  geofenceSendImmediately: {
    id: 'GNSSGeofenceConditionsCS500.geofenceSendImmediately',
    description: 'Send immediately',
    defaultMessage: 'Send immediately'
  },
  true: {
    id: 'GNSSGeofenceConditionsCS500.true',
    description: 'True',
    defaultMessage: 'True'
  },
  false: {
    id: 'GNSSGeofenceConditionsCS500.false',
    description: 'False',
    defaultMessage: 'False'   
  }
})

export default messages