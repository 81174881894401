import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import CheckIcon from '@material-ui/icons/Check'
import Checkbox from '@material-ui/core/Checkbox'
import ClearIcon from '@material-ui/icons/Clear'
import Collapse from '@material-ui/core/Collapse'
import ControlPointDuplicateOutlinedIcon from '@material-ui/icons/ControlPointDuplicateOutlined'
import Divider from '@material-ui/core/Divider'
import EditIcon from '@material-ui/icons/Edit'
import ErrorIcon from '@material-ui/icons/Error'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

import { FIELDS } from '../constants'
import { optionsForSignalItem } from '../config'

import ProtocolSelector from '../ProtocolSelector'
import messages from './messages'

const styles = theme => ({
  expand: {
    transform: 'rotate(0deg)'
  },
  expandOpen: {
    transform: 'rotate(180deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  title: {
    fontSize: 17,
    fontWeight: 'bold',
    paddingLeft: 16
  },
  root: {
    padding: 0
  },
  action: {
    margin: 0
  },
  button: {
    margin: 0
  },
  goldenButton: {
    margin: 0,
    backgroundColor: '#ffd700'
  },
  actionsRoot: {
    paddingRight: 1
  },
  contentRoot: {
    padding: 16
  },
  errorButton: {
    color: 'red'
  },
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid #dadde9',
    fontSize: 14
  },
  checkbox: {
    color: '#5d5d5d'
  },
  checked: {
    '&$checked': {
      color: '#1592E6'
    }
  }
})

const Signal = ({
  address,
  addressErrorMessage,
  canId,
  canIdErrorMessage,
  canPort,
  canPortErrorMessage,
  classes,
  copyLocalConfigurationSignal,
  dataLoggingEnable,
  editableId,
  expanded,
  frequency,
  frequencyErrorMessage,
  handleFieldChange,
  handleSignalDelete,
  handleSignalIdChange,
  handleSourceAddressChange,
  idMask,
  idMaskErrorMessage,
  intl,
  isEditable,
  lengthOfBits,
  lengthOfBitsErrorMessage,
  makeSignalIdEditable,
  multiplier,
  multiplierErrorMessage,
  name,
  nameErrorMessage,
  noErrorInSignal,
  offset,
  offsetErrorMessage,
  pgn,
  pgnName,
  priority,
  priorityErrorMessage,
  selectedCanProtocolName,
  selectedCanProtocolData,
  selectedCanProtocolType,
  selectedSignal,
  signalColor,
  signalId,
  signalIdErrorMessage,
  signed,
  sourceAddress,
  sourceAddressErrorMessage,
  spn,
  spnName,
  startBit,
  startBitErrorMessage,
  unit,
  unitErrorMessage,
  validateNewSignalId
}) => {
  const { formatMessage } = intl
  const optionsForSignalItemSelects = optionsForSignalItem()

  const getSignalTitle = () => {
    const title = !editableId ? (
      <div>
        <span>{formatMessage(messages.signalId) + ' ' + parseInt(signalId) + (name ? ' | ' + name : '')}</span>
        {signalIdErrorMessage && <span style={{ color: 'red' }}>{' | ' + signalIdErrorMessage}</span>}
      </div>
    ) : (
      <div className='col-md-12' style={{ paddingLeft: 0 }}>
        <span style={{ verticalAlign: 'middle' }}>{formatMessage(messages.signalId) + ' '}</span>
        <FormControl
          error={signalIdErrorMessage !== ''}
          style={{ width: '10%', verticalAlign: 'middle', padding: '0 0 2 0' }}
        >
          <Input
            id={FIELDS.signalId}
            inputProps={{ min: 2, max: 51 }}
            onChange={event => {
              handleSignalIdChange(signalId, parseInt(event.target.value))
            }}
            onWheel={event => event.target.blur()}
            placeholder=' [2 - 51]'
            type='number'
          />
          {signalIdErrorMessage !== '' ? <FormHelperText>{signalIdErrorMessage}</FormHelperText> : null}
        </FormControl>
        <span style={{ verticalAlign: 'middle' }}>{name ? ' | ' + name : ''}</span>
      </div>
    )
    return title
  }

  return (
    <Card
      elevation={0}
      style={{
        backgroundColor: dataLoggingEnable === false ? '#d3d3d3' : 'white',
        borderLeft: '10px solid ' + signalColor,
        borderRadius: 0
      }}
    >
      <CardHeader
        action={
          <CardActions classes={{ root: classes.actionsRoot }}>
            {!noErrorInSignal && isEditable && (
              <Tooltip classes={{ tooltip: classes.tooltip }} title={formatMessage(messages.error)}>
                <IconButton className={classes.errorButton}>
                  <ErrorIcon fontSize='small' />
                </IconButton>
              </Tooltip>
            )}
            {isEditable && expanded && selectedCanProtocolName !== 'J1939' && (
              <Tooltip
                classes={{ tooltip: classes.tooltip }}
                title={editableId ? formatMessage(messages.validateId) : formatMessage(messages.editId)}
              >
                <IconButton
                  classes={{ root: editableId ? classes.goldenButton : classes.button }}
                  onClick={() => {
                    makeSignalIdEditable(signalId, !editableId)
                    if (editableId) {
                      validateNewSignalId(signalId)
                    }
                  }}
                >
                  {editableId ? <CheckIcon fontSize='small' /> : <EditIcon fontSize='small' />}
                </IconButton>
              </Tooltip>
            )}
            {isEditable && (
              <Tooltip classes={{ tooltip: classes.tooltip }} title={formatMessage(messages.copySignal)}>
                <IconButton
                  classes={{ root: classes.button }}
                  onClick={() => {
                    copyLocalConfigurationSignal(signalId)
                  }}
                >
                  <ControlPointDuplicateOutlinedIcon fontSize='small' />
                </IconButton>
              </Tooltip>
            )}
            {isEditable && (
              <Tooltip classes={{ tooltip: classes.tooltip }} title={formatMessage(messages.deleteSignal)}>
                <IconButton
                  classes={{ root: classes.button }}
                  onClick={() => {
                    handleSignalDelete(signalId)
                  }}
                >
                  <ClearIcon fontSize='small' />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              title={expanded ? formatMessage(messages.close) : formatMessage(messages.open)}
            >
              <IconButton
                classes={{ root: classes.button }}
                onClick={() => {
                  handleFieldChange(signalId, 'expanded')
                }}
              >
                {expanded ? (
                  <ExpandMoreIcon className={(classes.expand, classes.expandOpen)} fontSize='small' />
                ) : (
                  <ExpandMoreIcon className={classes.expand} fontSize='small' />
                )}
              </IconButton>
            </Tooltip>
          </CardActions>
        }
        classes={{
          title: classes.title,
          root: classes.root,
          action: classes.action
        }}
        title={getSignalTitle()}
      />
      <Collapse in={expanded}>
        <CardContent
          classes={{
            root: classes.contentRoot
          }}
          style={{ paddingBottom: '16px' }}
        >
          {isEditable && <Typography style={{ fontWeight: 'bold' }}>{formatMessage(messages.settings)}</Typography>}
          {isEditable && <Divider style={{ backgroundColor: 'black', margin: '0 0 20px 0' }} />}
          {isEditable ? (
            <ProtocolSelector
              selectedCanProtocolData={selectedCanProtocolData}
              selectedCanProtocolName={selectedCanProtocolName}
              selectedCanProtocolType={selectedCanProtocolType}
              selectedSignal={selectedSignal}
              signalId={signalId}
            />
          ) : 
            []
          }
          {isEditable && selectedSignal !== null ? (
            <Grid container spacing={2}>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <FormControl error={nameErrorMessage !== ''} fullWidth>
                  <InputLabel>{formatMessage(messages.spnName)}</InputLabel>
                  <Input disabled id={FIELDS.spnName} value={spnName} />
                  <FormHelperText>{nameErrorMessage}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <FormControl fullWidth>
                  <InputLabel>{formatMessage(messages.spn)}</InputLabel>
                  <Input disabled id={FIELDS.spn} value={spn} />
                </FormControl>
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <FormControl error={canIdErrorMessage !== ''} fullWidth>
                  <InputLabel>{formatMessage(messages.canId)}</InputLabel>
                  <Input disabled id={FIELDS.canId} value={canId} />
                  <FormHelperText>{canIdErrorMessage}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <FormControl error={idMaskErrorMessage !== ''} fullWidth>
                  <InputLabel>{formatMessage(messages.idMask)}</InputLabel>
                  <Input
                    id={FIELDS.idMask}
                    inputProps={{ maxLength: 10 }}
                    onChange={event => {
                      handleFieldChange(signalId, FIELDS.idMask, event.target.value)
                    }}
                    placeholder={address === '11' ? '0x1FF' : '0x1FFFFFFF'}
                    value={idMask}
                  />
                  <FormHelperText>{idMaskErrorMessage}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <FormControl fullWidth>
                  <InputLabel>{formatMessage(messages.pgnName)}</InputLabel>
                  <Input disabled id={FIELDS.pgnName} value={pgnName} />
                </FormControl>
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <FormControl fullWidth>
                  <InputLabel>{formatMessage(messages.pgn)}</InputLabel>
                  <Input disabled id={FIELDS.pgn} value={pgn} />
                </FormControl>
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <FormControl error={addressErrorMessage !== ''} fullWidth>
                  <InputLabel>{formatMessage(messages.address)}</InputLabel>
                  <Select disabled name={FIELDS.address} value={address}>
                    {optionsForSignalItemSelects.address.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{addressErrorMessage}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <FormControl error={sourceAddressErrorMessage !== ''} fullWidth>
                  <InputLabel>{formatMessage(messages.sourceAddress)}</InputLabel>
                  <Input
                    id={FIELDS.sourceAddress}
                    onChange={event => {
                      handleSourceAddressChange(signalId, event.target.value)
                    }}
                    placeholder='[0x00 - 0xFF]'
                    value={sourceAddress}
                  />
                  <FormHelperText>{sourceAddressErrorMessage}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <FormControl error={startBitErrorMessage !== ''} fullWidth>
                  <InputLabel>{formatMessage(messages.startBit)}</InputLabel>
                  <Input disabled id={FIELDS.startBit} value={startBit} />
                  <FormHelperText>{startBitErrorMessage}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <FormControl error={lengthOfBitsErrorMessage !== ''} fullWidth>
                  <InputLabel>{formatMessage(messages.lengthOfBits)}</InputLabel>
                  <Input disabled id={FIELDS.lengthOfBits} value={lengthOfBits} />
                  <FormHelperText>{lengthOfBitsErrorMessage}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <FormControl error={offsetErrorMessage !== ''} fullWidth>
                  <InputLabel>{formatMessage(messages.offset)}</InputLabel>
                  <Input disabled id={FIELDS.offset} value={offset} />
                  <FormHelperText>{offsetErrorMessage}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <FormControl error={canPortErrorMessage !== ''} fullWidth>
                  <InputLabel>{formatMessage(messages.canPort)}</InputLabel>
                  <Select
                    name={FIELDS.canPort}
                    onChange={event => {
                      handleFieldChange(signalId, FIELDS.canPort, event.target.value)
                    }}
                    value={canPort}
                  >
                    {optionsForSignalItemSelects.canPort.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{canPortErrorMessage}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <FormControl error={priorityErrorMessage !== ''} fullWidth>
                  <InputLabel>{formatMessage(messages.priority)}</InputLabel>
                  <Input disabled id={FIELDS.priority} value={priority} />
                  <FormHelperText>{priorityErrorMessage}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <FormControl error={multiplierErrorMessage !== ''} fullWidth>
                  <InputLabel>{formatMessage(messages.multiplier)}</InputLabel>
                  <Input disabled id={FIELDS.multiplier} value={multiplier} />
                  <FormHelperText>{multiplierErrorMessage}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <FormControl error={unitErrorMessage !== ''} fullWidth>
                  <InputLabel>{formatMessage(messages.unit)}</InputLabel>
                  <Input
                    id={FIELDS.unit}
                    inputProps={{ maxLength: 50 }}
                    onChange={event => {
                      handleFieldChange(signalId, FIELDS.unit, event.target.value)
                    }}
                    value={unit}
                  />
                  <FormHelperText>{unitErrorMessage}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <FormControl error={frequencyErrorMessage !== ''} fullWidth>
                  <InputLabel>{formatMessage(messages.timeInterval)}</InputLabel>
                  <Input
                    id={FIELDS.frequency}
                    inputProps={{ min: 10, max: 65535 }}
                    onChange={event => {
                      handleFieldChange(signalId, FIELDS.frequency, event.target.value)
                    }}
                    onWheel={event => event.target.blur()}
                    placeholder='(s)'
                    type='number'
                    value={frequency}
                  />
                  <FormHelperText>{frequencyErrorMessage}</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={2}>
              {isEditable ? (
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <FormControl error={nameErrorMessage !== ''} fullWidth>
                    <InputLabel>{formatMessage(messages.name)}</InputLabel>
                    <Input
                      disabled={selectedSignal !== null}
                      id={FIELDS.name}
                      inputProps={{ maxLength: 300 }}
                      onChange={event => {
                        handleFieldChange(signalId, FIELDS.name, event.target.value)
                      }}
                      value={name}
                    />
                    <FormHelperText>{nameErrorMessage}</FormHelperText>
                  </FormControl>
                </Grid>
              ) : (
                <Grid item lg={1} md={2} sm={3} xs={4}>
                  <Typography>{formatMessage(messages.name)}</Typography>
                  <label>{name}</label>
                </Grid>
              )}
              {isEditable ? (
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <FormControl error={canIdErrorMessage !== ''} fullWidth>
                    <InputLabel>{formatMessage(messages.canId)}</InputLabel>
                    <Input
                      disabled={selectedSignal !== null}
                      id={FIELDS.canId}
                      inputProps={{ maxLength: 10 }}
                      onChange={event => {
                        handleFieldChange(signalId, FIELDS.canId, event.target.value)
                      }}
                      placeholder={address === '11' ? '0x1FF' : '0x1FFFFFFF'}
                      value={canId}
                    />
                    <FormHelperText>{canIdErrorMessage}</FormHelperText>
                  </FormControl>
                </Grid>
              ) : (
                <Grid item lg={1} md={2} sm={3} xs={4}>
                  <Typography>{formatMessage(messages.canId)}</Typography>
                  <label>{canId}</label>
                </Grid>
              )}
              {isEditable ? (
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <FormControl error={idMaskErrorMessage !== ''} fullWidth>
                    <InputLabel>{formatMessage(messages.idMask)}</InputLabel>
                    <Input
                      id={FIELDS.idMask}
                      inputProps={{ maxLength: 10 }}
                      onChange={event => {
                        handleFieldChange(signalId, FIELDS.idMask, event.target.value)
                      }}
                      placeholder={address === '11' ? '0x1FF' : '0x1FFFFFFF'}
                      value={idMask}
                    />
                    <FormHelperText>{idMaskErrorMessage}</FormHelperText>
                  </FormControl>
                </Grid>
              ) : (
                <Grid item lg={1} md={2} sm={3} xs={4}>
                  <Typography>{formatMessage(messages.idMask)}</Typography>
                  <label>{idMask}</label>
                </Grid>
              )}
              {isEditable ? (
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <FormControl error={frequencyErrorMessage !== ''} fullWidth>
                    {/*<InputLabel>{formatMessage(messages.frequency)}</InputLabel>*/}
                    <InputLabel>{formatMessage(messages.timeInterval)}</InputLabel>
                    <Input
                      id={FIELDS.frequency}
                      inputProps={{ min: 1, max: 65535 }}
                      onChange={event => {
                        handleFieldChange(signalId, FIELDS.frequency, event.target.value)
                      }}
                      onWheel={event => event.target.blur()}
                      placeholder='(s)'
                      type='number'
                      value={frequency}
                    />
                    <FormHelperText>{frequencyErrorMessage}</FormHelperText>
                  </FormControl>
                </Grid>
              ) : (
                <Grid item lg={1} md={2} sm={3} xs={4}>
                  <Typography>{formatMessage(messages.timeInterval)}</Typography>
                  <label>{frequency}</label>
                </Grid>
              )}
              {isEditable ? (
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <FormControl error={addressErrorMessage !== ''} fullWidth>
                    <InputLabel>{formatMessage(messages.address)}</InputLabel>
                    <Select
                      disabled={selectedSignal !== null}
                      name={FIELDS.address}
                      onChange={event => {
                        handleFieldChange(signalId, FIELDS.address, event.target.value)
                      }}
                      value={address}
                    >
                      {optionsForSignalItemSelects.address.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{addressErrorMessage}</FormHelperText>
                  </FormControl>
                </Grid>
              ) : (
                <Grid item lg={1} md={2} sm={3} xs={4}>
                  <Typography>{formatMessage(messages.address)}</Typography>
                  <label>{address}</label>
                </Grid>
              )}
              {isEditable ? (
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <FormControl error={canPortErrorMessage !== ''} fullWidth>
                    <InputLabel>{formatMessage(messages.canPort)}</InputLabel>
                    <Select
                      name={FIELDS.canPort}
                      onChange={event => {
                        handleFieldChange(signalId, FIELDS.canPort, event.target.value)
                      }}
                      value={canPort}
                    >
                      {optionsForSignalItemSelects.canPort.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{canPortErrorMessage}</FormHelperText>
                  </FormControl>
                </Grid>
              ) : (
                <Grid item lg={1} md={2} sm={3} xs={4}>
                  <Typography>{formatMessage(messages.canPort)}</Typography>
                  <label>{canPort}</label>
                </Grid>
              )}
              {isEditable ? (
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <FormControl error={startBitErrorMessage !== ''} fullWidth>
                    <InputLabel>{formatMessage(messages.startBit)}</InputLabel>
                    <Input
                      disabled={selectedSignal !== null}
                      id={FIELDS.startBit}
                      inputProps={{ min: 0, max: 63 }}
                      onChange={event => {
                        handleFieldChange(signalId, FIELDS.startBit, event.target.value)
                      }}
                      onWheel={event => event.target.blur()}
                      placeholder='[0 - 63]'
                      type='number'
                      value={startBit}
                    />
                    <FormHelperText>{startBitErrorMessage}</FormHelperText>
                  </FormControl>
                </Grid>
              ) : (
                <Grid item lg={1} md={2} sm={3} xs={4}>
                  <Typography>{formatMessage(messages.startBit)}</Typography>
                  <label>{startBit}</label>
                </Grid>
              )}
              {isEditable ? (
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <FormControl error={lengthOfBitsErrorMessage !== ''} fullWidth>
                    <InputLabel>{formatMessage(messages.lengthOfBits)}</InputLabel>
                    <Input
                      disabled={selectedSignal !== null}
                      id={FIELDS.lengthOfBits}
                      inputProps={{ min: 1, max: 64 }}
                      onChange={event => {
                        handleFieldChange(signalId, FIELDS.lengthOfBits, event.target.value)
                      }}
                      onWheel={event => event.target.blur()}
                      placeholder='[1 - 64]'
                      type='number'
                      value={lengthOfBits}
                    />
                    <FormHelperText>{lengthOfBitsErrorMessage}</FormHelperText>
                  </FormControl>
                </Grid>
              ) : (
                <Grid item lg={1} md={2} sm={3} xs={4}>
                  <Typography>{formatMessage(messages.lengthOfBits)}</Typography>
                  <label>{lengthOfBits}</label>
                </Grid>
              )}
              {isEditable ? (
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <FormControl error={offsetErrorMessage !== ''} fullWidth>
                    <InputLabel>{formatMessage(messages.offset)}</InputLabel>
                    <Input
                      disabled={selectedSignal !== null}
                      id={FIELDS.offset}
                      onChange={event => {
                        handleFieldChange(signalId, FIELDS.offset, event.target.value)
                      }}
                      onWheel={event => event.target.blur()}
                      type='number'
                      value={offset}
                    />
                    <FormHelperText>{offsetErrorMessage}</FormHelperText>
                  </FormControl>
                </Grid>
              ) : (
                <Grid item lg={1} md={2} sm={3} xs={4}>
                  <Typography>{formatMessage(messages.offset)}</Typography>
                  <label>{offset}</label>
                </Grid>
              )}
              {isEditable ? (
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <FormControl error={multiplierErrorMessage !== ''} fullWidth>
                    <InputLabel>{formatMessage(messages.multiplier)}</InputLabel>
                    <Input
                      disabled={selectedSignal !== null}
                      id={FIELDS.multiplier}
                      onChange={event => {
                        handleFieldChange(signalId, FIELDS.multiplier, event.target.value)
                      }}
                      onWheel={event => event.target.blur()}
                      type='number'
                      value={multiplier}
                    />
                    <FormHelperText>{multiplierErrorMessage}</FormHelperText>
                  </FormControl>
                </Grid>
              ) : (
                <Grid item lg={1} md={2} sm={3} xs={4}>
                  <Typography>{formatMessage(messages.multiplier)}</Typography>
                  <label>{multiplier}</label>
                </Grid>
              )}
              {isEditable ? (
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <FormControl error={unitErrorMessage !== ''} fullWidth>
                    <InputLabel>{formatMessage(messages.unit)}</InputLabel>
                    <Input
                      disabled={selectedSignal !== null}
                      id={FIELDS.unit}
                      inputProps={{ maxLength: 50 }}
                      onChange={event => {
                        handleFieldChange(signalId, FIELDS.unit, event.target.value)
                      }}
                      value={unit}
                    />
                    <FormHelperText>{unitErrorMessage}</FormHelperText>
                  </FormControl>
                </Grid>
              ) : (
                <Grid item lg={1} md={2} sm={3} xs={4}>
                  <Typography>{formatMessage(messages.unit)}</Typography>
                  <label>{unit}</label>
                </Grid>
              )}
              {isEditable ? (
                <Grid item lg={3} md={3} style={{ padding: '25px 8px 8px 8px' }} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={signed}
                        classes={{ root: classes.checkbox, checked: classes.checked }}
                        onClick={event => {
                          handleFieldChange(signalId, FIELDS.signed, event.target.checked)
                        }}
                      />
                    }
                    label={formatMessage(messages.signed)}
                  />
                </Grid>
              ) : (
                <Grid item lg={1} md={2} sm={3} xs={4}>
                  <Typography>{formatMessage(messages.signed)}</Typography>
                  <label>{signed ? 'true' : 'false'}</label>
                </Grid>
              )}
            </Grid>
          )}
        </CardContent>
      </Collapse>
    </Card>
  )
}

Signal.propTypes = {
  address: PropTypes.string.isRequired,
  addressErrorMessage: PropTypes.string.isRequired,
  canId: PropTypes.string.isRequired,
  canIdErrorMessage: PropTypes.string.isRequired,
  canPort: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  canPortErrorMessage: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  copyLocalConfigurationSignal: PropTypes.func,
  dataLoggingEnable: PropTypes.bool.isRequired,
  editableId: PropTypes.bool,
  expanded: PropTypes.bool.isRequired,
  frequency: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  frequencyErrorMessage: PropTypes.string.isRequired,
  handleFieldChange: PropTypes.func.isRequired,
  handleSignalDelete: PropTypes.func.isRequired,
  handleSignalIdChange: PropTypes.func.isRequired,
  handleSourceAddressChange: PropTypes.func.isRequired,
  idMask: PropTypes.string.isRequired,
  idMaskErrorMessage: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  isEditable: PropTypes.bool.isRequired,
  lengthOfBits: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  lengthOfBitsErrorMessage: PropTypes.string.isRequired,
  makeSignalIdEditable: PropTypes.func.isRequired,
  multiplier: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  multiplierErrorMessage: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  nameErrorMessage: PropTypes.string.isRequired,
  noErrorInSignal: PropTypes.bool.isRequired,
  offset: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  offsetErrorMessage: PropTypes.string.isRequired,
  pgn: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pgnName: PropTypes.string,
  priority: PropTypes.number,
  priorityErrorMessage: PropTypes.string,
  selectedCanProtocolData: PropTypes.object,
  selectedCanProtocolName: PropTypes.string,
  selectedCanProtocolType: PropTypes.string,
  selectedSignal: PropTypes.object,
  signalColor: PropTypes.string,
  signalId: PropTypes.number.isRequired,
  signalIdErrorMessage: PropTypes.string,
  signed: PropTypes.bool.isRequired,
  sourceAddress: PropTypes.string,
  sourceAddressErrorMessage: PropTypes.string,
  spn: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  spnName: PropTypes.string,
  startBit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  startBitErrorMessage: PropTypes.string.isRequired,
  unit: PropTypes.string.isRequired,
  unitErrorMessage: PropTypes.string.isRequired,
  validateNewSignalId: PropTypes.func.isRequired
}

Signal.defaultProps = {
  copyLocalConfigurationSignal: () => {},
  editableId: false,
  pgn: '',
  pgnName: '',
  priority: 0,
  priorityErrorMessage: '',
  selectedCanProtocolData: null,
  selectedCanProtocolName: '',
  selectedCanProtocolType: '',
  selectedSignal: null,
  signalColor: '',
  signalIdErrorMessage: '',
  sourceAddress: '0x00',
  sourceAddressErrorMessage: '',
  spn: '',
  spnName: ''
}

export default withStyles(styles)(injectIntl(React.memo(Signal)))
