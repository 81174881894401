import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

import { MachineActions } from 'components/machines'

import messages from './messages'

const styles = {
  transitionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'relative'
  },
  mapPanelNode: {
    backgroundColor: 'rgb(255, 255, 255)',
    borderRadius: '6px',
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 10px, rgba(0, 0, 0, 0.23) 0px 3px 10px',
    boxSizing: 'border-box',
    color: 'rgba(0, 0, 0, 0.87)',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '16px',
    marginRight: '2vh',
    marginTop: '10vh',
    maxWidth: '600px',
    transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',

    '&.fadein-appear': {
      opacity: '0.01'
    },
    '&.fadein-appear.fadein-appear-active': {
      opacity: '1',
      transition: 'opacity 500ms ease-in'
    }
  },

  container: {
    marginTop: '10px',
    borderTop: '5px solid #5D5D5D',
    padding: '10px 25px 20px 25px'
  },
  title: {
    marginBottom: '20px'
  },
  dataLine: {
    marginBottom: '10px'
  },
  actionsContainer: {
    marginTop: '20px'
  }
}

const Panel = props => {
  const {
    classes,
    intl: { formatMessage },
    name = '',
    description,
    eid,
    activeConfiguration,
    nodeId,
    operatingLastDateTime,
    unselectNode
  } = props

  return (
    <TransitionGroup className={classes.transitionContainer}>
      <CSSTransition appear classNames='fadein' in timeout={500}>
        <Grid className={classes.mapPanelNode} container item xs={12}>
          <Grid className={classes.container} container item xs={12}>
            <Grid className={classes.title} container item xs={12}>
              <Typography style={{ flexGrow: 1 }} variant='h5'>
                {name}
              </Typography>
              <IconButton onClick={unselectNode} style={{ padding: '3px' }}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid container>
              <Grid className={classes.dataLine} item xs={12}>
                <strong>{formatMessage(messages.eid)}:</strong> {eid}
              </Grid>
              <Grid className={classes.dataLine} item xs={12}>
                <strong>{formatMessage(messages.description)}:</strong> {description}
              </Grid>
              <Grid className={classes.dataLine} item xs={12}>
                <strong>{formatMessage(messages.activeConfiguration)}:</strong> {activeConfiguration}
              </Grid>
              <Grid className={classes.dataLine} item xs={12}>
                <strong>{formatMessage(messages.lastMessageDate)}:</strong> {operatingLastDateTime}
              </Grid>
            </Grid>
            <Grid className={classes.actionsContainer} container>
              <Grid item xs={12}>
                <Typography variant='h6'>{formatMessage(messages.actions)}</Typography>
              </Grid>
              <Grid item xs={12}>
                <MachineActions areAdvancedSignalsHidden nodeId={nodeId} style={{ justifyContent: 'flex-start' }} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CSSTransition>
    </TransitionGroup>
  )
}

Panel.propTypes = {
  activeConfiguration: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  description: PropTypes.string.isRequired,
  eid: PropTypes.string.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired }).isRequired,
  name: PropTypes.string.isRequired,
  nodeId: PropTypes.string.isRequired,
  operatingLastDateTime: PropTypes.string.isRequired,
  unselectNode: PropTypes.func.isRequired
}

export default injectIntl(withStyles(styles)(Panel))
