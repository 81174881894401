import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { utils, actions } from 'ducks/map'
import csNode from 'modules/csNode'
import { utcTimeToBrowserLocal } from 'utils/timeFormat'

import Panel from './Panel'

const mapStateToProps = state => {
  const { nd } = utils.getSelectedNode(state)
  if (nd) {
    return {
      nodeId: nd.id,
      name: nd.name,
      description: nd.description ? nd.description : '-',
      eid: nd.EID,
      activeConfiguration:
        nd.deviceConfiguration && nd.deviceConfiguration.name
          ? nd.deviceConfiguration.name + ' v.' + nd.deviceConfiguration.version
          : '-',
      deviceType: nd.deviceType,
      operatingLastDateTime: utcTimeToBrowserLocal(csNode.utils.getOperatingLastDateTime(state, nd.id))
    }
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      unselectNode: actions.unselectNode
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Panel)
