import React from 'react'

import BasicMap from './BasicMap'
import CustomizableMap from './CustomizableMap'

import { ExtendedMapProps } from '../../../props'
import { toLines, toImages, toCircles, toCircleOutlines, toPolygons, toPolygonLines } from './geojson'
import { injectIntl } from 'react-intl'

const ExtendedMap = injectIntl(
  ({ sat, polygons, polygonLines, circles, circleOutlines, images, lines, children, intl, ...other }) => {
    if (!sat && !polygons && !polygonLines && !circles && !circleOutlines && !images && !lines) {
      return (
        <BasicMap selectedLanguage={intl.locale} {...other}>
          {children}
        </BasicMap>
      )
    }
    const ln = toLines(lines)
    const img = toImages(images)
    const poly = toPolygons(polygons)
    const circle = toCircles(circles)
    const polyLines = toPolygonLines(polygonLines)
    const cirOutines = toCircleOutlines(circleOutlines)

    return (
      <CustomizableMap
        selectedLanguage={intl.locale}
        {...other}
        layers={[
          ...poly.layers,
          ...polyLines.layers,
          ...circle.layers,
          ...cirOutines.layers,
          ...img.layers,
          ...ln.layers
        ]}
        sources={{
          ...poly.sources,
          ...polyLines.sources,
          ...circle.sources,
          ...cirOutines.sources,
          ...img.sources,
          ...ln.sources
        }}
      >
        {children}
      </CustomizableMap>
    )
  }
)

ExtendedMap.propTypes = ExtendedMapProps

export default ExtendedMap
