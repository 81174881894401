import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {
  isEditing,
  getMaxZIndex,
  getRandom,
  getWidgets,
  getStaticData,
  setupDashboard,
  setWidgetZIndexPlus,
  setWidgetZIndexMinus,
  setWidgetSize,
  setWidgetPosition,
  setWidgetSettings,
  deleteWidget,
  addWidget,
  getDashboardSettings,
  getDinamicData,
  widgetUpdateRealTimeValues,
  newGpsPoints
} from 'ducks/dashboards'

import { actions as websocket } from 'ducks/websocket'

import csNode from 'modules/csNode'

import Dashboard from './Dashboard'

import { actions as hactions } from 'utils/http'

import { actions as cs500Actions } from 'ducks/configurationsCS500'

import { utils } from 'ducks/groups'

import { actions as geoActions } from 'ducks/geotracking'

const mapStateToProps = state => {
  return {
    editing: isEditing(state),
    maxZIndex: getMaxZIndex(state),
    random: getRandom(state),
    widgets: getWidgets(state),
    eid: getStaticData(state)[1]?.value,
    nodeCredentials: csNode.utils.getRealTimeCredentials(state),
    settings: getDashboardSettings(state),
    staticData: getStaticData(state),
    dinamicData: getDinamicData(state),
    groupId: utils.getSelectedGroup(state).id
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getCSDevice: (nodeId, groupId) => dispatch(csNode.actions.fetchCSNodeAndLocation(nodeId, groupId)),
    // eslint-disable-next-line max-params
    setupDashboard: (nodeFamily, maxZIndex, widgets, deviceData, settings) =>
      dispatch(setupDashboard(nodeFamily, maxZIndex, widgets, deviceData, settings)),
    setWidgetZIndexPlus: widgetId => dispatch(setWidgetZIndexPlus(widgetId)),
    setWidgetZIndexMinus: widgetId => dispatch(setWidgetZIndexMinus(widgetId)),
    setWidgetSize: (widgetId, height, width) => dispatch(setWidgetSize(widgetId, height, width)),
    setWidgetPosition: (widgetId, x, y) => dispatch(setWidgetPosition(widgetId, x, y)),
    setWidgetSettings: (widgetId, data) => dispatch(setWidgetSettings(widgetId, data)),
    deleteWidget: widgetId => dispatch(deleteWidget(widgetId)),
    subscribeToWS: (topics, eid, credentials) => dispatch(websocket.subscribeGeneric(topics, eid, credentials)),
    unsuscribeFromWS: (topic, eid) => dispatch(websocket.unsubscribe(topic, eid)),
    addWidget: template => dispatch(addWidget(template)),
    widgetUpdateRealTimeValues: (signalNumber, data) => dispatch(widgetUpdateRealTimeValues(signalNumber, data)),
    fetchDeviceNonVolatileConfiguration: (groupId, eid) =>
      dispatch(cs500Actions.fetchDeviceNonVolatileConfiguration(groupId, eid)),
    getNodeLastGPSLocation: eid =>
      dispatch(geoActions.getNodeLastGPSLocation(eid)).promise.then(({ payload }) => payload),
    newGpsPoints: (meta, payload) => dispatch(newGpsPoints(meta, payload)),
    ...bindActionCreators(
      {
        // eslint-disable-next-line no-shadow
        getAzureToken: (id, eid) => dispatch => {
          const { promise } = dispatch(hactions.plus1Connect.getAzureNodeToken(id, eid))
          return promise
        }
      },
      dispatch
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
