import {
  NOTIFICATION_CRITICALITY_LOW,
  NOTIFICATION_CRITICALITY_MEDIUM,
  NOTIFICATION_CRITICALITY_HIGH,
  NOTIFICATION_CRITICALITY_NORMAL,
  NOTIFICATION_CRITICALITY_NORMAL_LASTCHECK_HIGH,
  getColorsByNotificationCriticality
} from 'utils/notificationCriticality'

import { CS_NODE_PREFIX, GROUP_PREFIX, LASTCHECK_LIMIT_MILISECONDS } from '../../../constants'
import { toMarkerId } from '../../../utils'
import csNode from 'modules/csNode'
import moment from 'moment'
import { store } from 'index'

const getZIndex = (criticality, isSelected = false, isGroup = false) => {
  let zIndex = 1

  if (isSelected) {
    zIndex = 12000
  }

  switch (criticality) {
    case NOTIFICATION_CRITICALITY_LOW:
      zIndex = 100
      break

    case NOTIFICATION_CRITICALITY_MEDIUM:
      zIndex = 1000
      break

    case NOTIFICATION_CRITICALITY_HIGH:
      zIndex = 10000
      break

    default:
  }

  if (isGroup) zIndex += 1

  return zIndex
}

const getNodeWithoutNotificationsColors = criticality => {
  const { primary, secondary } = getColorsByNotificationCriticality(criticality)

  return [primary, secondary]
}

const getColors = criticality => {
  const { primary, secondary } = criticality !== '' ? getColorsByNotificationCriticality(criticality) : {}

  return [primary, secondary]
}

const getLastCheckElapsedTime = lastCheckedDate => {
  let elapsedTime

  if (lastCheckedDate !== 0) {
    elapsedTime = moment.duration(moment() - lastCheckedDate).valueOf()
  }

  return elapsedTime
}

const csNodeToMarker = (node, selectedMarkerId = null) => {
  const { notifications = [] } = node
  const state = store.getState()
  const markerId = toMarkerId(node.id, CS_NODE_PREFIX)
  const isSelected = markerId === selectedMarkerId

  let colors = []
  let nodeNotificationsCriticality
  let nodeNotificationsStatus

  if (notifications?.[0]?.criticality) {
    nodeNotificationsCriticality = notifications[0].criticality
    nodeNotificationsStatus = notifications[0].status
    colors = getColors(nodeNotificationsCriticality)
  } else {
    const lastCheckTime = moment(csNode.utils.getOperatingLastDateTime(state, node.id))
    const lastCheckElapsedTime = getLastCheckElapsedTime(lastCheckTime)

    if (lastCheckElapsedTime <= LASTCHECK_LIMIT_MILISECONDS) {
      colors = getNodeWithoutNotificationsColors(NOTIFICATION_CRITICALITY_NORMAL)
    } else {
      colors = getNodeWithoutNotificationsColors(NOTIFICATION_CRITICALITY_NORMAL_LASTCHECK_HIGH)
    }
  }

  const [color, hoverColor] = colors

  let url = ''
  switch (node.deviceType) {
    case 'CS100':
      url = '/images/devices/cs100.png'
      break
    case 'CS500':
      url = '/images/devices/cs500.png'
      break
    default:
      url = '/images/devices/cs100.png'
      break
  }

  return {
    id: markerId,
    deviceEID: node.EID,
    color,
    hoverColor,
    isSelected,
    position: node.location,
    title: node.name,
    url,
    size: isSelected ? 65 : 35,
    zIndex: getZIndex(nodeNotificationsCriticality, isSelected),
    acknowledged: nodeNotificationsStatus === 'acknowledged'
  }
}

const groupToMarker = ({ hashId, description, location, markerURL, criticality }) => {
  const id = toMarkerId(hashId, GROUP_PREFIX)
  const [color, hoverColor] = criticality ? getColors(criticality) : []

  return {
    id,
    color,
    hoverColor,
    title: description,
    position: location,
    url: markerURL,
    zIndex: getZIndex(criticality, false, true)
  }
}

export { csNodeToMarker, groupToMarker }
