import PropTypes from 'prop-types'
import React from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import 'core-js/proposals/promise-all-settled'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import Paper from '@material-ui/core/Paper'

import Alert from 'components/Alert'
import Loading from 'components/Loading'
import SelectColumnsButton from 'components/SelectColumnsButton'
import { client, logError } from 'utils/http'
import reactBootstrapTable from 'utils/reactBootstrapTable'
import { utcTimeToBrowserLocal } from 'utils/timeFormat'

import messages from './messages'
import { composeLocalQuery } from './utils'

class DevicesAssignedToNotification extends React.Component {
  constructor(props) {
    super(props)
    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    const alertMessages = false
    const alertMessagesType = ''
    const alertMessagesTitle = ''
    const alertMessagesText = ['']

    let alertSuccessMessagesClass = 'hidden'
    let successMessageTitle = ''
    let successMessage = ''
    if (
      props.history !== undefined &&
      props.history.location.state !== undefined &&
      props.history.location.state !== '' &&
      props.history.location.state === 'devicesAssigned'
    ) {
      alertSuccessMessagesClass = ''
      successMessageTitle = ''
      successMessage = this.formatMessage(messages.newMachinesAssigned)
    }

    this.state = {
      notificationAssignedDevices: [],

      loading: true,

      dialogOpen: false,
      dialogSelectedDeviceToDeleteTitle: '',
      dialogSelectedDeviceToDeleteEid: '',

      dialogOpenBulk: false,
      dialogSelectedDeviceToDeleteBulkTitle: '',

      alertMessages,
      alertMessagesType,
      alertMessagesTitle,
      alertMessagesText,

      count: reactBootstrapTable.count,
      length: reactBootstrapTable.length,
      elementsPerPage: reactBootstrapTable.elementsPerPage,
      page: reactBootstrapTable.page,

      selectedDevices: [],
      selectedDevicesEIDs: [],

      alertMessagesUnassigningDevices: false,
      alertMessagesUnassigningDevicesType: '',
      alertMessagesUnassigningDevicesTitle: '',
      alertMessagesUnassigningDevicesText: [''],

      alertSuccessMessagesClass,
      successMessageTitle,
      successMessage,

      visibleColumns: {
        name: true,
        machineModel: true,
        machineType: true,
        machineBrand: false,
        machineSerialNumber: true,
        deviceType: true,
        eid: false,
        lastActivity: false,
        firmwareVersion: false,
        deviceConfiguration: true,
        deviceConfigurationVersion: false
      }
    }
  }

  componentDidMount() {
    this.getAssignedDevices()
  }

  getAssignedDevices = () => {
    const { notification } = this.props
    const { length, page } = this.state

    const query = composeLocalQuery(page, length)
    client
      .getRuleInstanceDevicesAssigned(notification.hashId, query)
      .then(response => {
        const assignedDevicesCount = parseInt(response.headers['x-total-count'], 10)

        if (response.data !== undefined) {
          //Get more information about each device
          const deviceEIDs = response.data.map(deviceData => deviceData.eid)
          this.getAssignedDevicesDetails(deviceEIDs, assignedDevicesCount)
        } else {
          this.setState({
            loading: false,
            notificationAssignedDevices: [],
            count: 0,
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: this.formatMessage(messages.errorText),
            alertMessagesText: [this.formatMessage(messages.errorRequestingMachines)]
          })
        }
      })
      .catch(response => {
        const error = { ...response }
        if (error.response !== undefined && error.response.status !== undefined) {
          switch (error.response.status) {
            case 400: // Bad request
              this.setState({
                loading: false,
                notificationAssignedDevices: [],
                count: 0,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, { message: 400 }),
                alertMessagesText: [this.formatMessage(messages.error400Text)]
              })
              break
            case 401: // Invalid credentials
              this.setState({
                loading: false,
                notificationAssignedDevices: [],
                count: 0,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, { message: 401 }),
                alertMessagesText: [error.response.message]
              })
              break
            case 403: // Access denied
              this.setState({
                loading: false,
                notificationAssignedDevices: [],
                count: 0,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, { message: 403 }),
                alertMessagesText: [this.formatMessage(messages.error403Text)]
              })
              break
            case 404: // API url not found
              this.setState({
                loading: false,
                notificationAssignedDevices: [],
                count: 0,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, { message: 404 }),
                alertMessagesText: [this.formatMessage(messages.error404Text)]
              })
              break
            case 406: // Not acceptable
              this.setState({
                loading: false,
                notificationAssignedDevices: [],
                count: 0,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, { message: 406 }),
                alertMessagesText: [this.formatMessage(messages.error406Text)]
              })
              break
            case 500: // Server errors
              this.setState({
                loading: false,
                notificationAssignedDevices: [],
                count: 0,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, { message: 500 }),
                alertMessagesText: [error.response.data.error_description]
              })
              break
            default:
              this.setState({
                loading: false,
                notificationAssignedDevices: [],
                count: 0,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
                alertMessagesText: [this.formatMessage(messages.errorUndefinedText)]
              })
              logError(response)
          }
        } else {
          this.setState({
            loading: false,
            notificationAssignedDevices: [],
            count: 0,
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
            alertMessagesText: [this.formatMessage(messages.errorUndefinedText)]
          })
        }
      })
  }

  getAssignedDevicesDetails = (asignedDevicesToShow, assignedDevicesCount) => {
    const { getDevicesDetail, notification } = this.props

    const groupId = notification.groupId
    const devicesEIDsToGetDetail = asignedDevicesToShow

    try {
      getDevicesDetail(groupId, devicesEIDsToGetDetail)
        .then(response => {
          let assignedMachines = []
          if (response.data.devices !== undefined && response.data.devices.length > 0) {
            assignedMachines = response.data.devices
          }
          this.setState({
            notificationAssignedDevices: assignedMachines,
            count: assignedDevicesCount,
            loading: false
          })
        })
        .catch(response => {
          const error = { ...response }
          if (error.response !== undefined && error.response.status !== undefined) {
            switch (error.response.status) {
              case 400: // Bad request
                this.setState({
                  loading: false,
                  notificationAssignedDevices: [],
                  count: 0,
                  alertMessages: true,
                  alertMessagesType: 'danger',
                  alertMessagesTitle: this.formatMessage(messages.error, { message: 400 }),
                  alertMessagesText: [this.formatMessage(messages.error400Text)]
                })
                break
              case 401: // Invalid credentials
                this.setState({
                  loading: false,
                  notificationAssignedDevices: [],
                  count: 0,
                  alertMessages: true,
                  alertMessagesType: 'danger',
                  alertMessagesTitle: this.formatMessage(messages.error, { message: 401 }),
                  alertMessagesText: [error.response.message]
                })
                break
              case 403: // Access denied
                this.setState({
                  loading: false,
                  notificationAssignedDevices: [],
                  count: 0,
                  alertMessages: true,
                  alertMessagesType: 'danger',
                  alertMessagesTitle: this.formatMessage(messages.error, { message: 403 }),
                  alertMessagesText: [this.formatMessage(messages.error403Text)]
                })
                break
              case 404: // API url not found
                this.setState({
                  loading: false,
                  notificationAssignedDevices: [],
                  count: 0,
                  alertMessages: true,
                  alertMessagesType: 'danger',
                  alertMessagesTitle: this.formatMessage(messages.error, { message: 404 }),
                  alertMessagesText: [this.formatMessage(messages.error404Text)]
                })
                break
              case 500: // Server errors
                this.setState({
                  loading: false,
                  notificationAssignedDevices: [],
                  count: 0,
                  alertMessages: true,
                  alertMessagesType: 'danger',
                  alertMessagesTitle: this.formatMessage(messages.error, { message: 500 }),
                  alertMessagesText: [error.response.data.error_description]
                })
                break
              default:
                this.setState({
                  loading: false,
                  notificationAssignedDevices: [],
                  count: 0,
                  alertMessages: true,
                  alertMessagesType: 'danger',
                  alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
                  alertMessagesText: [this.formatMessage(messages.errorUndefinedText)]
                })
                logError(response)
            }
          } else {
            this.setState({
              loading: false,
              notificationAssignedDevices: [],
              count: 0,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
              alertMessagesText: [this.formatMessage(messages.errorUndefinedText)]
            })
          }
        })
    } catch (error) {
      this.setState({
        loading: false,
        notificationAssignedDevices: [],
        count: 0,
        alertMessages: true,
        alertMessagesType: 'danger',
        alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
        alertMessagesText: [this.formatMessage(messages.errorUndefinedText)]
      })
    }
  }

  closeAlert = () => {
    this.setState({
      alertMessages: false,
      alertMessagesType: '',
      alertMessagesTitle: '',
      alertMessagesText: ['']
    })
  }

  closeAlertUnassigningDevices = () => {
    this.setState({
      alertMessagesUnassigningDevices: false,
      alertMessagesUnassigningDevicesType: '',
      alertMessagesUnassigningDevicesTitle: '',
      alertMessagesUnassigningDevicesText: ['']
    })
  }

  deleteDevice = device => {
    const deviceName = device.name
    const deviceTitle = deviceName

    this.setState({
      dialogOpen: true,
      dialogSelectedDeviceToDeleteTitle: deviceTitle,
      dialogSelectedDeviceToDeleteEid: device.EID
    })
  }

  handleDeleteDeviceBulkClick = () => {
    const { selectedDevicesEIDs } = this.state
    const deviceTitle = this.formatMessage(messages.unassignMachines, { number: selectedDevicesEIDs.length })

    this.setState({
      dialogOpenBulk: true,
      dialogSelectedDeviceToDeleteBulkTitle: deviceTitle
    })
  }

  handleClose = () => {
    this.setState({
      dialogOpen: false,
      dialogSelectedDeviceToDeleteTitle: '',
      dialogSelectedDeviceToDeleteEid: '',
      dialogOpenBulk: false,
      dialogSelectedDeviceToDeleteBulkTitle: '',
      alertSuccessMessagesClass: 'hidden',
      successMessageTitle: '',
      successMessage: ''
    })
  }

  dialogBulkActions = () => {
    return [
      <Button key='cancel-button' className='cancel-button' onClick={this.handleClose} style={{ marginRight: 10 }}>
        {this.formatMessage(messages.cancel)}
      </Button>,
      <Button key='delete-button' className='delete-button' onClick={this.handleBulkDelete}>
        {this.formatMessage(messages.unassign)}
      </Button>
    ]
  }

  handleDelete = () => {
    const { dialogSelectedDeviceToDeleteEid } = this.state
    this.deleteDevices([dialogSelectedDeviceToDeleteEid])
  }

  handleBulkDelete = () => {
    const { selectedDevicesEIDs } = this.state
    this.deleteDevices(selectedDevicesEIDs)
  }

  deleteDevices = devicesToUnassign => {
    const { notification } = this.props
    const { notificationAssignedDevices } = this.state
    //Delete from notificacion assigned devices the selected devices
    //Check at least one device is left
    const newDevicesAssignement = notificationAssignedDevices.filter(function (item) {
      const obj = devicesToUnassign.find(deviceEid => deviceEid === item.EID)
      if (obj === undefined) {
        return true
      } else {
        return false
      }
    })

    if (newDevicesAssignement.length === 0) {
      this.setState({
        alertMessagesUnassigningDevices: true,
        alertMessagesUnassigningDevicesType: 'danger',
        alertMessagesUnassigningDevicesTitle: this.formatMessage(messages.errorText),
        alertMessagesUnassigningDevicesText: [
          this.formatMessage(messages.unassignMachinesError),
          this.formatMessage(messages.assignAtLeastAMachine)
        ],
        dialogOpen: false,
        dialogSelectedDeviceToDeleteTitle: '',
        dialogOpenBulk: false,
        dialogSelectedDeviceToDeleteBulkTitle: '',
        alertSuccessMessagesClass: 'hidden',
        successMessageTitle: '',
        successMessage: '',
        selectedDevices: [],
        selectedDevicesEIDs: []
      })
    } else {
      const notificationRuleHashId = notification.hashId
      client
        .desassignDevicesToRuleInstance(notificationRuleHashId, devicesToUnassign)
        .then(response => {
          if (response.data !== undefined) {
            this.setState(
              {
                dialogOpen: false,
                dialogSelectedDeviceToDeleteTitle: '',
                dialogOpenBulk: false,
                dialogSelectedDeviceToDeleteBulkTitle: '',
                selectedDevices: [],
                selectedDevicesEIDs: [],
                alertSuccessMessagesClass: '',
                successMessageTitle: '',
                successMessage: this.formatMessage(messages.machinesUnassignedCorrectly),
                alertMessagesUnassigningDevices: false,
                alertMessagesUnassigningDevicesType: '',
                alertMessagesUnassigningDevicesTitle: '',
                alertMessagesUnassigningDevicesText: ['']
              },
              () => this.getAssignedDevices()
            )
          }
        })
        .catch(response => {
          const error = { ...response }
          switch (error.response.status) {
            case 400: // Bad request
              this.setState({
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, { message: 400 }),
                alertMessagesText: [this.formatMessage(messages.error400Text)]
              })
              break
            case 401: // Invalid credentials
              this.setState({
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, { message: 401 }),
                alertMessagesText: [error.response.message]
              })
              break
            case 403: // Access denied
              this.setState({
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, { message: 403 }),
                alertMessagesText: [this.formatMessage(messages.error403Text)]
              })
              break
            case 404: // API url not found
              this.setState({
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, { message: 404 }),
                alertMessagesText: [this.formatMessage(messages.error404Text)]
              })
              break
            case 406: // Not acceptable
              this.setState({
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, { message: 406 }),
                alertMessagesText: [this.formatMessage(messages.error406Text)]
              })
              break
            case 409: // Data integrity violation
              this.setState({
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, { message: 409 }),
                alertMessagesText: [this.formatMessage(messages.error409Text)]
              })
              break
            case 415: // Unsupported media type
              this.setState({
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, { message: 415 }),
                alertMessagesText: [this.formatMessage(messages.error415Text)]
              })
              break
            case 422: // Validation failed
              this.setState({
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, { message: 422 }),
                alertMessagesText: [this.formatMessage(messages.error422Text)]
              })
              break
            case 500: // Server errors
              this.setState({
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, { message: 500 }),
                alertMessagesText: [error.response.data.error_description]
              })
              break
            default:
              this.setState({
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
                alertMessagesText: [this.formatMessage(messages.errorUndefinedText)]
              })
              logError(response)
          }
        })
    }
  }

  handleClearSelectedDevicesClick = () => {
    this.setState({
      selectedDevices: [],
      selectedDevicesEIDs: []
    })
  }

  onRowSelect = (row, isSelected) => {
    const { selectedDevices, selectedDevicesEIDs } = this.state
    const element = row
    const elementId = row.id

    const newSelectedDevices = selectedDevices.map(deviceId => deviceId)
    const newSelectedDevicesEIDs = selectedDevicesEIDs.map(deviceEid => deviceEid)
    const indexOfDevice = newSelectedDevices.indexOf(elementId)

    if (isSelected) {
      if (indexOfDevice < 0) {
        newSelectedDevices.push(elementId)
        newSelectedDevicesEIDs.push(element.EID)
      }
    } else {
      if (indexOfDevice > -1) {
        newSelectedDevices.splice(indexOfDevice, 1)
        newSelectedDevicesEIDs.splice(indexOfDevice, 1)
      }
    }

    this.setState({
      selectedDevices: newSelectedDevices,
      selectedDevicesEIDs: newSelectedDevicesEIDs
    })
  }

  onSelectAll = (isSelected, rows) => {
    const { selectedDevices, selectedDevicesEIDs } = this.state

    const newSelectedDevices = selectedDevices.map(deviceId => deviceId)
    const newSelectedDevicesEIDs = selectedDevicesEIDs.map(deviceEid => deviceEid)

    const devicesToProcess = rows.map(device => {
      return device
    })

    devicesToProcess.forEach(device => {
      const indexOfDevice = newSelectedDevices.indexOf(device.id)

      if (isSelected) {
        if (indexOfDevice < 0) {
          newSelectedDevices.push(device.id)
          newSelectedDevicesEIDs.push(device.EID)
        }
      } else {
        if (indexOfDevice > -1) {
          newSelectedDevices.splice(indexOfDevice, 1)
          newSelectedDevicesEIDs.splice(indexOfDevice, 1)
        }
      }

      return device
    })

    this.setState({
      selectedDevices: newSelectedDevices,
      selectedDevicesEIDs: newSelectedDevicesEIDs
    })
  }

  onPageChange = (page, sizePerPage) => {
    this.setState(
      {
        page,
        length: sizePerPage,
        notificationAssignedDevices: []
      },
      () => {
        this.getAssignedDevices()
      }
    )
  }

  onSizePerPageList = sizePerPage => {
    this.setState(
      {
        length: sizePerPage,
        notificationAssignedDevices: []
      },
      () => this.getAssignedDevices()
    )
  }

  handleCloseAlertResultClick = () => {
    this.setState({
      alertSuccessMessagesClass: 'hidden',
      successMessageTitle: '',
      successMessage: ''
    })
  }

  handleChangeColumnVisibility = columnId => {
    this.setState(state => {
      const updatedColumnVisibility = !state.visibleColumns[columnId]
      return {
        visibleColumns: {
          ...state.visibleColumns,
          [columnId]: updatedColumnVisibility
        }
      }
    })
  }

  formatLastActivity = operatingTime => {
    return utcTimeToBrowserLocal(operatingTime.lastDateTime)
  }

  formatMachineField = cell => {
    return cell ? cell : '-'
  }

  formatActiveConfigurationColumn = cell => {
    return cell?.name ? cell.name : '-'
  }

  formatActiveConfigurationVersionColumn = cell => {
    return cell?.versionNumber ? cell.versionNumber : '-'
  }

  renderTableLoadingAndError = () => {
    const { alertMessages, alertMessagesText, alertMessagesTitle, alertMessagesType, loading } = this.state

    if (loading) {
      return <Loading />
    } else if (alertMessages) {
      return (
        <Alert
          alertType={alertMessagesType}
          closeFunction={this.closeAlert}
          messageText={alertMessagesText}
          messageTitle={alertMessagesTitle}
          showAlert={alertMessages}
        />
      )
    }
  }

  render() {
    const {
      alertMessages,
      alertMessagesUnassigningDevices,
      alertMessagesUnassigningDevicesText,
      alertMessagesUnassigningDevicesTitle,
      alertMessagesUnassigningDevicesType,
      alertSuccessMessagesClass,
      count,
      dialogOpen,
      dialogOpenBulk,
      dialogSelectedDeviceToDeleteBulkTitle,
      dialogSelectedDeviceToDeleteTitle,
      elementsPerPage,
      length,
      loading,
      notificationAssignedDevices,
      page,
      selectedDevices,
      successMessage,
      successMessageTitle,
      visibleColumns
    } = this.state

    const renderPaginationShowsTotal = formatMessage => (start, to, total) =>
      (
        <span>
          {`${formatMessage(messages.showingMachines)} ${start} ${formatMessage(messages.to)} ${to} ${formatMessage(
            messages.of
          )} ${total}`}
        </span>
      )

    if (loading || alertMessages) {
      return <div>{this.renderTableLoadingAndError()}</div>
    } else {
      const tableOptions = {
        // No data
        noDataText: this.formatMessage(messages.noMachinesAssigned),

        // Page size select
        onSizePerPageList: this.onSizePerPageList,
        sizePerPageList: elementsPerPage, // you can change the dropdown list for size per page
        sizePerPage: length, // which size per page you want to locate as default
        page, // which page you want to show as default

        // Pagination
        onPageChange: this.onPageChange,
        ignoreSinglePage: false, // Give true will ignore the pagination if only one page, default is false.
        pageStartIndex: 1, // where to start counting the pages
        paginationSize: 5, // the pagination bar size.
        prePage: this.formatMessage(messages.prePage), // Previous page button text
        nextPage: this.formatMessage(messages.nextPage), // Next page button text
        firstPage: this.formatMessage(messages.firstPage), // First page button text
        lastPage: this.formatMessage(messages.lastPage), // Last page button text
        paginationShowsTotal: renderPaginationShowsTotal(this.formatMessage), // Accept bool or function
        paginationPosition: 'bottom', // default is bottom, top and both is all available
        hideSizePerPage: false, // You can hide the dropdown for sizePerPage
        alwaysShowAllBtns: false, // Always show next and previous button
        withFirstAndLast: true // Hide the going to First and Last page button

        // Filter options
        //onFilterChange: this.onFilterChange

        // Search options
        // onSearchChange: this.onSearchChange,
        // clearSearch: false,
        // searchDelayTime: 400 // A delay for triggering search after a keyup event
      }

      const selectRowProp = {
        mode: 'checkbox',
        clickToSelect: false,
        onSelect: this.onRowSelect,
        onSelectAll: this.onSelectAll,
        bgColor: '#f5f5f5',
        selected: selectedDevices
      }

      const remote = remoteObj => {
        return { ...remoteObj, filter: false, pagination: true, search: false, sizePerPage: true, sort: false }
      }

      console.log('notificationAssignedDevices: ', notificationAssignedDevices)

      return (
        <div>
          <div className={alertSuccessMessagesClass}>
            <div className='alert alert-success alert-dismissible animated fadeIn'>
              <button aria-label='Close' className='close' onClick={this.handleCloseAlertResultClick}>
                <span aria-hidden='true'>×</span>
              </button>
              <h4>{successMessageTitle}</h4>
              <p className='h5'>{successMessage}</p>
            </div>
          </div>
          {alertMessagesUnassigningDevices ? (
            <Alert
              alertType={alertMessagesUnassigningDevicesType}
              closeFunction={this.closeAlertUnassigningDevices}
              messageText={alertMessagesUnassigningDevicesText}
              messageTitle={alertMessagesUnassigningDevicesTitle}
              showAlert={alertMessagesUnassigningDevices}
            />
          ) : 
            ''
          }
          <Paper>
            <div className='container-fluid'>
              <Grid container item spacing={1} xs={12}>
                <Grid container item spacing={1} style={{ marginTop: 14 }} xs={7}>
                  <Grid item>
                    <Button className='text-button' disabled>
                      {this.formatMessage(messages.selectedMachines)} ({selectedDevices.length})
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      className='secondary-action-button'
                      disabled={selectedDevices.length === 0}
                      onClick={this.handleClearSelectedDevicesClick}
                    >
                      {this.formatMessage(messages.clearSelection)}
                    </Button>
                  </Grid>
                  <Grid item>
                    <SelectColumnsButton
                      columns={visibleColumns}
                      onChangeColumnVisibility={this.handleChangeColumnVisibility}
                    />
                  </Grid>
                </Grid>
                <Grid container item justifyContent='flex-end' xs={5}>
                  <Grid item>
                    <Button
                      className='delete-button'
                      disabled={selectedDevices.length === 0}
                      onClick={this.handleDeleteDeviceBulkClick}
                      style={{ marginTop: 14 }}
                    >
                      <Icon className='zmdi zmdi-close-circle-o' />
                      {this.formatMessage(messages.unassign)}
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <div className='table-with-pagination'>
                    <BootstrapTable
                      bordered={false}
                      condensed={false}
                      data={notificationAssignedDevices}
                      exportCSV={false}
                      fetchInfo={{ dataTotalSize: count }}
                      hover
                      keyField='id'
                      multiColumnSearch={false}
                      options={tableOptions}
                      pagination
                      remote={remote}
                      search={false}
                      searchPlaceholder={this.formatMessage(messages.searchPlaceholder)}
                      selectRow={selectRowProp}
                      striped={false}
                    >
                      <TableHeaderColumn dataField='id' hidden />
                      <TableHeaderColumn dataField='name' hidden={!visibleColumns.name}>
                        {this.formatMessage(messages.name)}
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        dataField='machineModel'
                        dataFormat={this.formatMachineField}
                        hidden={!visibleColumns.machineModel}
                      >
                        {this.formatMessage(messages.machineModel)}
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        dataField='machineType'
                        dataFormat={this.formatMachineField}
                        hidden={!visibleColumns.machineType}
                      >
                        {this.formatMessage(messages.machineType)}
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        dataField='machineBrand'
                        dataFormat={this.formatMachineField}
                        hidden={!visibleColumns.machineBrand}
                      >
                        {this.formatMessage(messages.machineBrand)}
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        dataField='machineSerialNumber'
                        dataFormat={this.formatMachineField}
                        hidden={!visibleColumns.machineSerialNumber}
                      >
                        {this.formatMessage(messages.machineSerialNumber)}
                      </TableHeaderColumn>
                      <TableHeaderColumn dataField='device_type' hidden={!visibleColumns.deviceType}>
                        {this.formatMessage(messages.deviceType)}
                      </TableHeaderColumn>
                      <TableHeaderColumn dataField='EID' hidden={!visibleColumns.eid}>
                        {this.formatMessage(messages.eid)}
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        dataField='operatingTime'
                        dataFormat={this.formatLastActivity}
                        hidden={!visibleColumns.lastActivity}
                      >
                        {this.formatMessage(messages.lastActivity)}
                      </TableHeaderColumn>
                      <TableHeaderColumn dataField='mainFirmwareVersion' hidden={!visibleColumns.firmwareVersion}>
                        {this.formatMessage(messages.firmwareVersion)}
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        dataField='Configuration'
                        dataFormat={this.formatActiveConfigurationColumn}
                        hidden={!visibleColumns.deviceConfiguration}
                      >
                        {this.formatMessage(messages.deviceConfiguration)}
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        dataField='Configuration'
                        dataFormat={this.formatActiveConfigurationVersionColumn}
                        hidden={!visibleColumns.deviceConfigurationVersion}
                      >
                        {this.formatMessage(messages.deviceConfigurationVersion)}
                      </TableHeaderColumn>
                    </BootstrapTable>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Paper>

          <Dialog fullWidth maxWidth='md' onClose={this.handleClose} open={dialogOpen}>
            <DialogTitle>{this.formatMessage(messages.confirmMachineUnassignment)}</DialogTitle>
            <DialogContent>{dialogSelectedDeviceToDeleteTitle}</DialogContent>
            <DialogActions>
              <Button className='cancel-button' color='primary' onClick={this.handleClose} style={{ marginRight: 10 }}>
                {this.formatMessage(messages.cancel)}
              </Button>
              <Button className='delete-button' onClick={this.handleDelete}>
                {this.formatMessage(messages.unassign)}
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog fullWidth maxWidth='md' onClose={this.handleClose} open={dialogOpenBulk}>
            <DialogTitle>{this.formatMessage(messages.confirmMachineUnassignment)}</DialogTitle>
            <DialogContent>{dialogSelectedDeviceToDeleteBulkTitle}</DialogContent>
            <DialogActions>
              <Button className='cancel-button' onClick={this.handleClose} style={{ marginRight: 10 }}>
                {this.formatMessage(messages.cancel)}
              </Button>
              <Button className='delete-button' onClick={this.handleBulkDelete}>
                {this.formatMessage(messages.unassign)}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )
    }
  }
}

DevicesAssignedToNotification.propTypes = {
  getDevicesDetail: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired }).isRequired,
  notification: PropTypes.object.isRequired
}

export default withRouter(injectIntl(DevicesAssignedToNotification))
