import { capitalize } from 'lodash'
import PropTypes from 'prop-types' // eslint-disable-line no-unused-vars
import React from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

import DateLabel from 'components/DateLabel'
import SelectColumnsButton from 'components/SelectColumnsButton'
import { devicesEndpointAdapter } from 'utils/adapters'
import { logError, mapDynamicDevicesFilterObject } from 'utils/http'
import { utcTimeToBrowserLocal } from 'utils/timeFormat'

import messages from './messages'

class SelectMachine extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      groupNodes: [],

      filter: {},
      sort: {},

      count: 0,
      sizePerPage: 10,
      page: 1,
      start: 0,
      noDataText: <CircularProgress />,

      visibleColumns: {
        name: true,
        machineModel: true,
        machineType: true,
        machineBrand: false,
        machineSerialNumber: true,
        deviceType: true,
        eid: false,
        lastActivity: false,
        firmwareVersion: false,
        deviceConfiguration: true,
        deviceConfigurationVersion: false
      }
    }
  }

  componentDidMount() {
    const { groupId } = this.props
    if (groupId) this.getNodes()
  }

  getNodes = () => {
    this.setState(
      {
        noDataText: <CircularProgress />
      },
      () => {
        const { getGroupDevices, groupId } = this.props
        const { sizePerPage, start, filter, sort } = this.state
        const deviceFields = {
          Device: [
            'id',
            'name',
            'device_type',
            'eid',
            'operating_time',
            'main_firmware_version',
            'created_at',
            'machine_model',
            'machine_type',
            'machine_brand',
            'machine_serial_number'
          ],
          Configuration: ['name', 'version_number', 'id']
        }
        const mappedFilters = mapDynamicDevicesFilterObject(filter, sort)

        getGroupDevices(groupId, sizePerPage, start, deviceFields, mappedFilters)
          .then(response => {
            const devices = response ? response.data.devices : []
            const count = response ? response.data.total : 0

            this.setState({
              count,
              groupNodes: devices,
              noDataText: this.formatMessage(messages.noMachinesText)
            })
          })
          .catch(response => {
            let noDataText = this.formatMessage(messages.errorRequestingMachines)
            if (response && response.error && response.error.response) {
              const { status, data } = response.error.response
              const errorMessage = typeof data === 'string' ? data : data ? data.message : ''
              if (status === 404 && errorMessage.includes('NO DEVICE'))
                noDataText = this.formatMessage(messages.noMachinesText)
            }
            this.setState({
              noDataText
            })
            logError(response)
          })
      }
    )
  }

  getTableOptions = () => {
    const { noDataText, sizePerPage, page } = this.state
    return {
      noDataText,
      onSizePerPageList: this.handleSizePerPageList,
      sizePerPageList: [
        {
          text: '10',
          value: 10
        },
        {
          text: '20',
          value: 20
        },
        {
          text: '50',
          value: 50
        },
        {
          text: '100',
          value: 100
        }
      ],
      sizePerPage,
      page,

      onPageChange: this.handlePageChange,
      ignoreSinglePage: false,
      pageStartIndex: 1,
      paginationSize: 5,
      prePage: this.formatMessage(messages.prePage),
      nextPage: this.formatMessage(messages.nextPage),
      firstPage: this.formatMessage(messages.firstPage),
      lastPage: this.formatMessage(messages.lastPage),
      paginationShowsTotal: this.renderPaginationShowsTotal(this.formatMessage),
      paginationPosition: 'bottom',
      hideSizePerPage: false,
      alwaysShowAllBtns: false,
      withFirstAndLast: true,

      onFilterChange: this.handleFilterChange,
      onSortChange: this.handleSortChange
    }
  }

  handleSelectedDevice = async row => {
    const [device] = devicesEndpointAdapter([{ ...row }])
    const { changeSelectedDevice, fetchDeviceNonVolatileConfiguration, groupId, getCSNodeByEID } = this.props

    changeSelectedDevice(device)
    let configuration = null
    switch (device.deviceType) {
      case 'CS100':
        const deviceResponse = await getCSNodeByEID(device.EID, groupId)
        configuration = deviceResponse.data.deviceConfiguration
        break
      case 'CS500':
        const configResponse = await fetchDeviceNonVolatileConfiguration(groupId, device.EID)
        configuration = configResponse[device.EID].parsedConfiguration.deviceConfiguration
        break
    }
    const { selectedDeviceEid, updateDeviceConfiguration } = this.props
    if (device.EID === selectedDeviceEid && configuration) updateDeviceConfiguration(configuration)
  }

  renderDataDetail = (cell, row) => <DateLabel date={cell} />

  renderActionsDetail = (cell, row) => {
    const { selectedDeviceEid } = this.props
    return (
      <Button
        disabled={row.eid === selectedDeviceEid}
        onClick={() => {
          this.handleSelectedDevice(row)
        }}
      >
        {this.formatMessage(messages.select)}
      </Button>
    )
  }

  handlePageChange = (page, sizePerPage) => {
    this.setState(
      {
        start: (page - 1) * sizePerPage,
        page,
        sizePerPage,
        groupNodes: []
      },
      this.getNodes
    )
  }

  handleSizePerPageList = sizePerPage => {
    this.setState(
      {
        sizePerPage,
        groupNodes: []
      },
      this.getNodes
    )
  }

  handleFilterChange = filterObj => {
    this.setState(
      {
        page: 1,
        start: 0,
        groupNodes: [],
        filter: filterObj
      },
      this.getNodes
    )
  }

  handleSortChange = (sortName, sortOrder) => {
    this.setState(
      {
        page: 1,
        start: 0,
        groupNodes: [],
        sort: {
          sortColumnKey: sortName,
          sortColumnModel: 'Device',
          sortColumnOrder: sortOrder.toUpperCase()
        }
      },
      this.getNodes
    )
  }

  handleChangeColumnVisibility = columnId => {
    this.setState(state => {
      const updatedColumnVisibility = !state.visibleColumns[columnId]
      return {
        visibleColumns: {
          ...state.visibleColumns,
          [columnId]: updatedColumnVisibility
        }
      }
    })
  }

  remote(remoteObj) {
    return {
      ...remoteObj,
      search: false,
      pagination: true,
      sizePerPage: true,
      filter: true
    }
  }

  formatLastActivity = operatingTime => {
    return utcTimeToBrowserLocal(operatingTime.lastDateTime)
  }

  formatMachineField = cell => {
    return cell ? cell : '-'
  }

  formatActiveConfigurationColumn = cell => {
    return cell?.name ? cell.name : '-'
  }

  formatActiveConfigurationVersionColumn = cell => {
    return cell?.version_number ? cell.version_number : '-'
  }

  renderPaginationShowsTotal = formatMessage => (start, to, total) =>
    (
      <span>
        {`${formatMessage(messages.showingMachines)} ${start} ${formatMessage(messages.to)} ${to} ${formatMessage(
          messages.of
        )} ${total}`}
      </span>
    )

  render() {
    const { groupNodes, count, visibleColumns } = this.state
    const { onlyCS500 } = this.props

    const deviceTypeFilterOptions = { CS500: 'CS500' }
    if (!onlyCS500) {
      deviceTypeFilterOptions['CS100'] = 'CS100'
      deviceTypeFilterOptions['CS10'] = 'CS10'
    }

    return (
      <Paper style={{ marginBottom: 10 }}>
        <div className='table-with-pagination'>
          <Grid container item spacing={2} xs={12}>
            <Grid item xs={12}>
              <SelectColumnsButton
                columns={visibleColumns}
                onChangeColumnVisibility={this.handleChangeColumnVisibility}
              />
            </Grid>
            <Grid item xs={12}>
              <BootstrapTable
                bordered={false}
                condensed={false}
                csvFileName=''
                data={groupNodes}
                exportCSV={false}
                fetchInfo={{ dataTotalSize: count }}
                hover
                keyField='eid'
                multiColumnSearch={false}
                options={this.getTableOptions()}
                pagination
                remote={this.remote}
                search={false}
                searchPlaceholder=''
                striped={false}
              >
                <TableHeaderColumn
                  dataField='name'
                  dataSort
                  filter={{
                    type: 'TextFilter',
                    delay: 400,
                    placeholder: `${this.formatMessage(messages.placeholder, {
                      column: this.formatMessage(messages.name).toLowerCase()
                    })}`
                  }}
                  hidden={!visibleColumns.name}
                >
                  {this.formatMessage(messages.name)}
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField='machine_model'
                  dataFormat={this.formatMachineField}
                  dataSort
                  filter={{
                    type: 'TextFilter',
                    delay: 400,
                    placeholder: `${this.formatMessage(messages.placeholder, {
                      column: this.formatMessage(messages.machineModel).toLowerCase()
                    })}`
                  }}
                  hidden={!visibleColumns.machineModel}
                >
                  {this.formatMessage(messages.machineModel)}
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField='machine_type'
                  dataFormat={this.formatMachineField}
                  dataSort
                  filter={{
                    type: 'TextFilter',
                    delay: 400,
                    placeholder: `${this.formatMessage(messages.placeholder, {
                      column: this.formatMessage(messages.machineType).toLowerCase()
                    })}`
                  }}
                  hidden={!visibleColumns.machineType}
                >
                  {this.formatMessage(messages.machineType)}
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField='machine_brand'
                  dataFormat={this.formatMachineField}
                  dataSort
                  filter={{
                    type: 'TextFilter',
                    delay: 400,
                    placeholder: `${this.formatMessage(messages.placeholder, {
                      column: this.formatMessage(messages.machineBrand).toLowerCase()
                    })}`
                  }}
                  hidden={!visibleColumns.machineBrand}
                >
                  {this.formatMessage(messages.machineBrand)}
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField='machine_serial_number'
                  dataFormat={this.formatMachineField}
                  dataSort
                  filter={{
                    type: 'TextFilter',
                    delay: 400,
                    placeholder: `${this.formatMessage(messages.placeholder, {
                      column: this.formatMessage(messages.machineSerialNumber).toLowerCase()
                    })}`
                  }}
                  hidden={!visibleColumns.machineSerialNumber}
                >
                  {this.formatMessage(messages.machineSerialNumber)}
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField='device_type'
                  dataSort
                  filter={{
                    type: 'SelectFilter',
                    delay: 400,
                    options: deviceTypeFilterOptions,
                    placeholder: `${this.formatMessage(messages.placeholder, {
                      column: this.formatMessage(messages.deviceType).toLowerCase()
                    })}`
                  }}
                  hidden={!visibleColumns.deviceType}
                >
                  {this.formatMessage(messages.deviceType)}
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField='eid'
                  dataSort
                  filter={{
                    type: 'TextFilter',
                    delay: 400,
                    placeholder: `${this.formatMessage(messages.placeholder, {
                      column: this.formatMessage(messages.EID).toLowerCase()
                    })}`
                  }}
                  hidden={!visibleColumns.eid}
                >
                  {capitalize(this.formatMessage(messages.EID))}
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField='operating_time'
                  dataFormat={this.formatLastActivityColumn}
                  hidden={!visibleColumns.lastActivity}
                >
                  {this.formatMessage(messages.lastActivity)}
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField='main_firmware_version'
                  dataSort
                  filter={{
                    type: 'TextFilter',
                    delay: 400,
                    placeholder: `${this.formatMessage(messages.placeholder, {
                      column: this.formatMessage(messages.firmwareVersion).toLowerCase()
                    })}`
                  }}
                  hidden={!visibleColumns.firmwareVersion}
                >
                  {this.formatMessage(messages.firmwareVersion)}
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField='Configuration'
                  dataFormat={this.formatActiveConfigurationColumn}
                  filter={{
                    type: 'TextFilter',
                    delay: 400,
                    placeholder: `${this.formatMessage(messages.placeholder, {
                      column: this.formatMessage(messages.deviceConfiguration).toLowerCase()
                    })}`
                  }}
                  hidden={!visibleColumns.deviceConfiguration}
                >
                  {this.formatMessage(messages.deviceConfiguration)}
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField='configurationVersion'
                  dataFormat={this.formatActiveConfigurationVersionColumn}
                  hidden={!visibleColumns.deviceConfigurationVersion}
                >
                  {this.formatMessage(messages.deviceConfigurationVersion)}
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataAlign='center'
                  dataField='id'
                  dataFormat={this.renderActionsDetail}
                  headerAlign='center'
                  width='100'
                >
                  {this.formatMessage(messages.actions)}
                </TableHeaderColumn>
              </BootstrapTable>
            </Grid>
          </Grid>
        </div>
      </Paper>
    )
  }
}

SelectMachine.propTypes = {
  changeSelectedDevice: PropTypes.func.isRequired,
  fetchDeviceNonVolatileConfiguration: PropTypes.func.isRequired,
  getCSNodeByEID: PropTypes.func.isRequired,
  getGroupDevices: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  onlyCS500: PropTypes.bool,
  selectedDeviceEid: PropTypes.string.isRequired,
  updateDeviceConfiguration: PropTypes.func.isRequired
}

SelectMachine.defaultProps = {
  onlyCS500: false
}

export default injectIntl(SelectMachine)
