import { saveAs } from 'file-saver'
import { uniq } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'

import Alert from 'components/Alert'
import SelectColumnsButton from 'components/SelectColumnsButton'
import { mapDynamicDevicesFilterObject, client, logError } from 'utils/http'
import { utcTimeToBrowserLocal, utcTimeToBrowserLocalMilliseconds } from 'utils/timeFormat'

import FleetActions from '../../FleetActions'
import MachineActions from '../../MachineActions'
import { getCSVAdaptedDevicesData } from '../../adapters'
import messages from '../../messages'

const styles = {
  root: {
    width: 340
  }
}

class MachinesTable extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    const defaultTableProps = { page: 1, sizePerPage: 10 }
    const { page, sizePerPage } = { ...defaultTableProps, ...props.location.state }

    this.state = {
      csNodes: [],

      count: 0,
      start: (page - 1) * sizePerPage,
      selectedDevices: [],

      visibleColumns: {
        name: true,
        machineModel: true,
        machineType: true,
        machineBrand: false,
        machineSerialNumber: false,
        deviceType: true,
        eid: true,
        lastMessageDate: true,
        firmwareVersion: true,
        deviceConfiguration: false,
        deviceConfigurationVersion: false
      },

      filter: {},
      sort: {},
      tableOptions: {
        noDataText: <CircularProgress />,
        page,
        sizePerPage
      },
      apiFleetActions: []
    }

    this.tableRef = React.createRef()
  }

  componentDidMount() {
    const { groupId, history, location } = this.props

    history.replace(location.pathname, undefined)
    this.getActions()
    if (groupId) this.getCSNodes()
  }

  componentDidUpdate(prevProps) {
    const { groupId } = this.props

    if (groupId && prevProps.groupId !== groupId) {
      this.getActions()

      this.setState(
        state => ({
          tableOptions: {
            ...state.tableOptions,
            noDataText: <CircularProgress />,
            page: 1
          },
          count: 0,
          start: 0,
          csNodes: [],
          filter: {},
          sort: {},
          selectedDevices: []
        }),
        this.getCSNodes
      )
    }
  }

  getActions = () => {
    client
      .getActions('CS')
      .then(response => {
        this.setState({ apiFleetActions: response.data })
      })
      .catch(error => {
        logError(error)
        this.setState({ apiFleetActions: [] })
      })
  }

  getCSNodes = () => {
    const { getDynamicCSNodes, groupId } = this.props
    const {
      start,
      tableOptions: { sizePerPage }
    } = this.state

    this.setState(
      state => ({
        csNodes: [],
        tableOptions: {
          ...state.tableOptions,
          noDataText: <CircularProgress />
        }
      }),
      () => {
        const { filter, sort } = this.state
        const mappedFilters = mapDynamicDevicesFilterObject(filter, sort)
        const deviceFields = {
          Device: [
            'id',
            'name',
            'device_type',
            'eid',
            'operating_time',
            'main_firmware_version',
            'created_at',
            'machine_model',
            'machine_type',
            'machine_brand',
            'machine_serial_number'
          ],
          Configuration: ['name', 'version_number', 'id']
        }
        getDynamicCSNodes(groupId, sizePerPage, start, deviceFields, mappedFilters)
          .then(response => {
            const data = response.data
            if (data.count === 0) {
              this.setState(state => ({
                csNodes: [],
                count: 0,
                tableOptions: {
                  ...state.tableOptions,
                  noDataText: this.formatMessage(messages.noConfigurationsText)
                }
              }))
            } else {
              this.setState({
                csNodes: data.devices,
                count: data.total
              })
            }
          })
          .catch(response => {
            const { error } = { ...response }
            let noDataText = this.formatMessage(messages.errorRequestingData)
            if (error && error.response) {
              switch (error.response.status) {
                case 404:
                  const errorReceived =
                    typeof error.response.data === 'string' ? error.response.data : error.response.data.message
                  if (
                    errorReceived.includes('NO DEVICE IS ASSOCIATED TO THIS USER IN THIS GROUP') ||
                    errorReceived.includes('NO DEVICE EXISTS')
                  ) {
                    noDataText = this.formatMessage(messages.thereAreCurrentlyNoMachines)
                  }
                  break
                default:
              }
            }
            this.setState(state => ({
              csNodes: [],
              tableOptions: {
                ...state.tableOptions,
                noDataText
              }
            }))
          })
      }
    )
  }

  getTableOptions = () => {
    const { tableOptions } = this.state
    return {
      onSizePerPageList: this.handleSizePerPageList,
      sizePerPageList: [
        {
          text: '10',
          value: 10
        },
        {
          text: '50',
          value: 50
        },
        {
          text: '100',
          value: 100
        },
        {
          text: '200',
          value: 200
        }
      ],

      onPageChange: this.handlePageChange,
      ignoreSinglePage: false,
      pageStartIndex: 1,
      paginationSize: 5,
      prePage: this.formatMessage(messages.prePage),
      nextPage: this.formatMessage(messages.nextPage),
      firstPage: this.formatMessage(messages.firstPage),
      lastPage: this.formatMessage(messages.lastPage),
      paginationShowsTotal: this.renderPaginationShowsTotal(this.formatMessage),
      paginationPosition: 'bottom',
      hideSizePerPage: false,
      alwaysShowAllBtns: false,
      withFirstAndLast: true,
      onFilterChange: this.handleFilterChange,
      onSortChange: this.handleSortChange,
      exportCSVBtn: () => <button style={{ display: 'none' }} />,
      ...tableOptions
    }
  }

  getCSVFormattedData = () => {
    const { visibleColumns: columns, csNodes } = this.state

    const columnKeys = Object.keys(columns)
    const csvHeaders = columnKeys.map(key => this.formatMessage(messages[key])).join(',')
    const csvAdaptedDevices = getCSVAdaptedDevicesData(csNodes)

    const csvData = csvAdaptedDevices.reduce((acc, deviceData) => {
      const csvRowValues = columnKeys.map(column => deviceData[column] || '-')
      return `${acc}\n${csvRowValues.join(',')}`
    }, csvHeaders)
    return csvData
  }

  handlePageChange = (page, sizePerPage) => {
    this.setState(
      state => ({
        start: (page - 1) * sizePerPage,
        tableOptions: {
          ...state.tableOptions,
          page,
          sizePerPage
        }
      }),
      this.getCSNodes
    )
  }

  handleSizePerPageList = sizePerPage => {
    this.setState(
      state => ({
        tableOptions: { ...state.tableOptions, sizePerPage }
      }),
      this.getCSNodes
    )
  }

  handleRowSelect = (row, isSelected) => {
    const { selectedDevices } = this.state

    const { id, device_type, name, main_firmware_version, eid, Configuration } = row

    let newSelectedNodes

    if (isSelected) {
      newSelectedNodes = [
        ...selectedDevices,
        ...!selectedDevices.map(device => device.id).includes(id)
          ? [
            {
              id,
              device_type,
              name,
              main_firmware_version,
              EID: eid,
              Configuration
            }
          ]
          : []
      ]
    } else {
      newSelectedNodes = selectedDevices.filter(device => device.id !== id)
    }

    this.setState({ selectedDevices: newSelectedNodes })
  }

  handleSelectAll = (isSelected, rows) => {
    const { selectedDevices } = this.state

    const newSelectedNodes = rows.reduce((acc, row) => {
      const { id, device_type, name, main_firmware_version, eid, Configuration } = row
      if (isSelected) {
        return [
          ...acc,
          ...!acc.map(device => device.id).includes(id)
            ? [
              {
                id,
                device_type,
                name,
                main_firmware_version,
                EID: eid,
                Configuration
              }
            ]
            : []
        ]
      } else {
        return acc.filter(device => device.id !== id)
      }
    }, selectedDevices)

    this.setState({
      selectedDevices: newSelectedNodes
    })
  }

  handleFilterChange = filterObj => {
    this.setState(
      state => ({
        filter: filterObj,
        start: 0,
        count: 0,
        tableOptions: {
          ...state.tableOptions,
          page: 1
        }
      }),
      this.getCSNodes
    )
  }

  handleSortChange = (sortName, sortOrder) => {
    if (sortName.includes('.') || sortName === 'operating_time') {
      this.setState({
        sort: {}
      })
    } else {
      this.setState(
        {
          sort: {
            sortColumnKey: sortName,
            sortColumnModel: 'Device',
            sortColumnOrder: sortOrder.toUpperCase()
          }
        },
        this.getCSNodes
      )
    }
  }

  handleClearSelectedNodesClick = () => {
    this.setState({
      selectedDevices: []
    })
  }

  handleChangeColumnVisibility = columnId => {
    this.setState(state => {
      const updatedColumnVisibility = !state.visibleColumns[columnId]
      return {
        visibleColumns: {
          ...state.visibleColumns,
          [columnId]: updatedColumnVisibility
        }
      }
    })
  }

  handleCsvDownloadClick = () => {
    const csvData = this.getCSVFormattedData()
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' })
    saveAs(blob, 'machines.csv')
  }

  renderDeviceActions = (cell, row) => {
    const { privileges } = this.props
    const { tableOptions, apiFleetActions } = this.state

    if (privileges.canViewActions) {
      const { page, sizePerPage } = tableOptions
      const { id, device_type, name, eid } = row

      return (
        <MachineActions
          deviceName={name}
          deviceType={device_type}
          eid={eid}
          locationState={{ page, sizePerPage }}
          narrow
          nodeId={id}
        >
          {privileges.canViewActionsSelector && (
            <FleetActions apiActions={apiFleetActions} isMachineActions machines={[row]} />
          )}
        </MachineActions>
      )
    }
  }

  renderLastActivity = operatingTime => {
    return utcTimeToBrowserLocal(operatingTime.lastDateTime)
  }

  renderDeviceConfiguration = (cell, row) => {
    return row.Configuration?.name || '-'
  }

  renderDeviceConfigurationVersion = (cell, row) => {
    return typeof row.Configuration?.version_number === 'number' ? 'v.' + row.Configuration?.version_number : '-'
  }

  renderMainFirmwareVersion = cell => {
    return cell || '-'
  }

  renderMachineField = cell => {
    return cell || '-'
  }

  sortLastActivityTime = (a, b, order) => {
    if (order === 'asc') {
      return (
        utcTimeToBrowserLocalMilliseconds(a.operating_time.lastDateTime) -
        utcTimeToBrowserLocalMilliseconds(b.operating_time.lastDateTime)
      )
    } else {
      return (
        utcTimeToBrowserLocalMilliseconds(b.operating_time.lastDateTime) -
        utcTimeToBrowserLocalMilliseconds(a.operating_time.lastDateTime)
      )
    }
  }

  sortActiveConfiguration = (a, b, order) => {
    const configurationNameA = a.Configuration?.name || ''
    const configurationNameB = b.Configuration?.name || ''
    if (order === 'asc') {
      return configurationNameA.localeCompare(configurationNameB)
    } else {
      return configurationNameB.localeCompare(configurationNameA)
    }
  }

  renderPaginationShowsTotal = formatMessage => (start, to, total) =>
    (
      <span>
        {`${formatMessage(messages.showingMachines)} ${start} ${formatMessage(messages.to)} ${to} ${formatMessage(
          messages.of
        )} ${total}`}
      </span>
    )

  render() {
    const {
      classes,
      privileges: { canViewActionsSelector, canViewActions }
    } = this.props
    const { count, csNodes, selectedDevices, visibleColumns, apiFleetActions } = this.state

    const selectRowProp = {
      mode: 'checkbox',
      clickToSelect: false,
      onSelect: this.handleRowSelect,
      onSelectAll: this.handleSelectAll,
      bgColor: '#f5f5f5',
      selected: selectedDevices.map(device => device.id)
    }

    const selectedNodesDeviceTypes = uniq(selectedDevices.map(device => device.device_type))
    const multipleDeviceTypesSelected = selectedNodesDeviceTypes.length > 1
    const fleetActionsStyle = multipleDeviceTypesSelected ? { marginBottom: 25 } : {}

    const deviceTypeFilterOptions = {
      CS100: 'CS100',
      CS500: 'CS500',
      CS10: 'CS10'
    }

    const TableHeaderColumns = [
      <TableHeaderColumn key='id' dataField='id' hidden isKey width='0'>
        {this.formatMessage(messages.idColumn)}
      </TableHeaderColumn>,
      <TableHeaderColumn
        key='name'
        csvHeader={this.formatMessage(messages.name)}
        dataField='name'
        dataSort
        filter={{
          type: 'TextFilter',
          delay: 400,
          placeholder: this.formatMessage(messages.placeholder, { column: this.formatMessage(messages.name) })
        }}
        hidden={!visibleColumns.name}
        tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word', verticalAlign: 'middle' }}
        width='100'
      >
        {this.formatMessage(messages.name)}
      </TableHeaderColumn>,
      <TableHeaderColumn
        key='machineModel'
        csvFormat={this.renderMachineField}
        csvHeader={this.formatMessage(messages.machineModel)}
        dataField='machine_model'
        dataFormat={this.renderMachineField}
        dataSort
        filter={{
          type: 'TextFilter',
          delay: 400,
          placeholder: this.formatMessage(messages.placeholder, { column: this.formatMessage(messages.name) })
        }}
        hidden={!visibleColumns.machineModel}
        tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word', verticalAlign: 'middle' }}
        width='100'
      >
        {this.formatMessage(messages.machineModel)}
      </TableHeaderColumn>,
      <TableHeaderColumn
        key='machineType'
        csvFormat={this.renderMachineField}
        csvHeader={this.formatMessage(messages.machineType)}
        dataField='machine_type'
        dataFormat={this.renderMachineField}
        dataSort
        filter={{
          type: 'TextFilter',
          delay: 400,
          placeholder: this.formatMessage(messages.placeholder, {
            column: this.formatMessage(messages.machine)
          })
        }}
        hidden={!visibleColumns.machineType}
        tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word', verticalAlign: 'middle' }}
        width='100'
      >
        {this.formatMessage(messages.machineType)}
      </TableHeaderColumn>,
      <TableHeaderColumn
        key='machineBrand'
        csvFormat={this.renderMachineField}
        csvHeader={this.formatMessage(messages.machineBrand)}
        dataField='machine_brand'
        dataFormat={this.renderMachineField}
        dataSort
        filter={{
          type: 'TextFilter',
          delay: 400,
          placeholder: this.formatMessage(messages.selectPlaceholder, {
            column: this.formatMessage(messages.machineBrand)
          })
        }}
        hidden={!visibleColumns.machineBrand}
        tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word', verticalAlign: 'middle' }}
        width='100'
      >
        {this.formatMessage(messages.machineBrand)}
      </TableHeaderColumn>,
      <TableHeaderColumn
        key='machineSerialNumber'
        csvFormat={this.renderMachineField}
        csvHeader={this.formatMessage(messages.machineSerialNumber)}
        dataField='machine_serial_number'
        dataFormat={this.renderMachineField}
        dataSort
        filter={{
          type: 'TextFilter',
          delay: 400,
          placeholder: this.formatMessage(messages.selectPlaceholder, {
            column: this.formatMessage(messages.machineSerialNumber)
          })
        }}
        hidden={!visibleColumns.machineSerialNumber}
        tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word', verticalAlign: 'middle' }}
        width='100'
      >
        {this.formatMessage(messages.machineSerialNumber)}
      </TableHeaderColumn>,
      <TableHeaderColumn
        key='deviceType'
        csvHeader={this.formatMessage(messages.deviceType)}
        dataField='device_type'
        dataSort
        filter={{
          type: 'SelectFilter',
          delay: 400,
          options: deviceTypeFilterOptions,
          placeholder: this.formatMessage(messages.selectPlaceholder, {
            column: this.formatMessage(messages.deviceType)
          })
        }}
        hidden={!visibleColumns.deviceType}
        tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word', verticalAlign: 'middle' }}
        width='100'
      >
        {this.formatMessage(messages.deviceType)}
      </TableHeaderColumn>,
      <TableHeaderColumn
        key='eid'
        csvHeader={this.formatMessage(messages.eid)}
        dataField='eid'
        dataSort
        filter={{
          type: 'TextFilter',
          delay: 400,
          placeholder: this.formatMessage(messages.placeholder, { column: this.formatMessage(messages.eid) })
        }}
        hidden={!visibleColumns.eid}
        tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word', verticalAlign: 'middle' }}
        width='100'
      >
        {this.formatMessage(messages.eid)}
      </TableHeaderColumn>,
      <TableHeaderColumn
        key='operating_time'
        csvFormat={this.formatLastActivityTime}
        csvHeader={this.formatMessage(messages.lastMessageDate)}
        dataField='operating_time'
        dataFormat={this.renderLastActivity}
        dataSort
        hidden={!visibleColumns.lastMessageDate}
        sortFunc={this.sortLastActivityTime}
        tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word', verticalAlign: 'middle' }}
        width='100'
      >
        {this.formatMessage(messages.lastMessageDate)}
      </TableHeaderColumn>,
      <TableHeaderColumn
        key='deviceConfiguration'
        csvFormat={this.renderDeviceConfiguration}
        csvHeader={this.formatMessage(messages.deviceConfiguration)}
        dataField='Configuration.name'
        dataFormat={this.renderDeviceConfiguration}
        dataSort
        filter={{
          type: 'TextFilter',
          delay: 400,
          placeholder: this.formatMessage(messages.placeholder, {
            column: this.formatMessage(messages.deviceConfiguration)
          })
        }}
        hidden={!visibleColumns.deviceConfiguration}
        sortFunc={this.sortActiveConfiguration}
        tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word', verticalAlign: 'middle' }}
        width='100'
      >
        {this.formatMessage(messages.deviceConfiguration)}
      </TableHeaderColumn>,
      <TableHeaderColumn
        key='deviceConfigurationVersion'
        csvFormat={this.renderDeviceConfigurationVersion}
        csvHeader={this.formatMessage(messages.deviceConfigurationVersion)}
        dataField='Configuration.version_number'
        dataFormat={this.renderDeviceConfigurationVersion}
        hidden={!visibleColumns.deviceConfigurationVersion}
        tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word', verticalAlign: 'middle' }}
        width='100'
      >
        {this.formatMessage(messages.deviceConfigurationVersion)}
      </TableHeaderColumn>,
      <TableHeaderColumn
        key='firmwareVersion'
        csvFormat={this.renderMainFirmwareVersion}
        csvHeader={this.formatMessage(messages.firmwareVersion)}
        dataField='main_firmware_version'
        dataFormat={this.renderMainFirmwareVersion}
        dataSort
        filter={{
          type: 'TextFilter',
          delay: 400,
          placeholder: this.formatMessage(messages.placeholder, {
            column: this.formatMessage(messages.firmwareVersion)
          })
        }}
        hidden={!visibleColumns.firmwareVersion}
        tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word', verticalAlign: 'middle' }}
        width='100'
      >
        {this.formatMessage(messages.firmwareVersion)}
      </TableHeaderColumn>
    ]

    if (canViewActions) {
      TableHeaderColumns.push(
        <TableHeaderColumn
          key='actions'
          dataAlign='center'
          dataField='id'
          dataFormat={this.renderDeviceActions}
          export={false}
          headerAlign='center'
          tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word', verticalAlign: 'middle' }}
          width='100'
        >
          {this.formatMessage(messages.actions)}
        </TableHeaderColumn>
      )
    }

    return (
      <Paper style={{ borderRadius: 0 }}>
        <Grid container>
          <Grid container item style={{ ...fleetActionsStyle, padding: '20px 20px 0px' }}>
            <Grid container item spacing={1} xs={7}>
              {canViewActionsSelector ? (
                <Grid item>
                  <Button
                    disabled
                    style={{
                      color: 'color: rgba(0, 0, 0, 0.87)'
                    }}
                  >
                    {this.formatMessage(messages.selected)} ({selectedDevices.length})
                  </Button>
                </Grid>
              ) : null}

              {canViewActionsSelector ? (
                <Grid item>
                  <Button
                    className='secondary-action-button'
                    disabled={selectedDevices.length < 1}
                    onClick={this.handleClearSelectedNodesClick}
                  >
                    {this.formatMessage(messages.clearSelection)}
                  </Button>
                </Grid>
              ) : null}
              <Grid item>
                <SelectColumnsButton
                  columns={visibleColumns}
                  onChangeColumnVisibility={this.handleChangeColumnVisibility}
                />
              </Grid>
              {canViewActionsSelector ? (
                <Grid item>
                  <FleetActions apiActions={apiFleetActions} isMachineActions={false} machines={selectedDevices} />
                </Grid>
              ) : null}
            </Grid>
            <Grid alignItems='flex-start' container item justifyContent='flex-end' xs={5}>
              {canViewActionsSelector ? (
                <Button
                  className='primary-action-button'
                  onClick={this.handleCsvDownloadClick}
                  startIcon={<i className='zmdi zmdi-download' style={{ fontSize: 16, paddingLeft: 4 }} />}
                  style={{ marginRight: 8 }}
                >
                  {this.formatMessage(messages.downloadCSV)}
                </Button>
              ) : null}
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            {multipleDeviceTypesSelected && (
              <div className='col-md-12' style={{ marginBottom: -20 }}>
                <Alert
                  alertType='warning'
                  messageText={[this.formatMessage(messages.firmwareUpdateWarning)]}
                  showAlert={multipleDeviceTypesSelected}
                />
              </div>
            )}
          </Grid>
        </Grid>
        <div className='table-with-pagination' style={{ paddingBottom: 110 }}>
          <BootstrapTable
            ref={this.tableRef}
            bordered={false}
            condensed={false}
            csvFileName='Machines.csv'
            data={csNodes}
            //exportCSV
            fetchInfo={{ dataTotalSize: count }}
            hover
            multiColumnSearch={false}
            options={this.getTableOptions()}
            pagination
            remote={remoteObj => ({
              ...remoteObj,
              search: false,
              pagination: true,
              sizePerPage: true,
              filter: true
            })}
            search={false}
            searchPlaceholder={this.formatMessage(messages.searchPlaceholder)}
            selectRow={selectRowProp}
            striped={false}
          >
            {TableHeaderColumns}
          </BootstrapTable>
        </div>
      </Paper>
    )
  }
}

MachinesTable.propTypes = {
  allPrivileges: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  getDynamicCSNodes: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    state: PropTypes.shape({ page: PropTypes.number })
  }).isRequired,
  privileges: PropTypes.object.isRequired
}

export default injectIntl(withStyles(styles)(MachinesTable))
