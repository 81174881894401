import { defineMessages } from 'react-intl'

const messages = defineMessages({
  title: {
    id: 'Notifications.title',
    description: 'Notifications title',
    defaultMessage: 'Notifications'
  },
  actions: {
    id: 'Notifications.actions',
    description: 'Actions table header column',
    defaultMessage: 'Actions'
  },
  viewActionDetail: {
    id: 'Notifications.viewActionDetail',
    description: 'View action detail button label',
    defaultMessage: 'View action detail'
  },
  closeNotification: {
    id: 'Notifications.closeNotification',
    description: 'Close notification button label',
    defaultMessage: 'Close notification'
  },
  reopenNotification: {
    id: 'Notifications.reopenNotification',
    description: 'Reopen notification button label',
    defaultMessage: 'Reopen notification'
  },
  acknowledgeNotification: {
    id: 'Notifications.acknowledgeNotification',
    description: 'Acknowledge notification button label',
    defaultMessage: 'Acknowledge notification'
  },
  unacknowledgeNotification: {
    id: 'Notifications.unacknowledgeNotification',
    description: 'Unacknowledge notification button label',
    defaultMessage: 'Unacknowledge notification'
  },
  showingRows: {
    id: 'Notifications.showingRows',
    description: 'Notifications table total text showing rows',
    defaultMessage: 'Showing notifications'
  },
  toTable: {
    id: 'Notifications.toTable',
    description: 'Notifications table total text to',
    defaultMessage: 'to'
  },
  of: {
    id: 'Notifications.of',
    description: 'Notifications table total text of',
    defaultMessage: 'of'
  },
  noNotificationsAvailable: {
    id: 'Notifications.noNotificationsAvailable',
    description: 'No notifications available warning text',
    defaultMessage: 'No notifications available'
  },
  prePage: {
    id: 'Notifications.prePage',
    description: 'Notifications previous page button text',
    defaultMessage: 'Prev'
  },
  nextPage: {
    id: 'Notifications.nextPage',
    description: 'Notifications next page button text',
    defaultMessage: 'Next'
  },
  firstPage: {
    id: 'Notifications.firstPage',
    description: 'Notifications first page button text',
    defaultMessage: 'First'
  },
  lastPage: {
    id: 'Notifications.lastPage',
    description: 'Notifications last page button text',
    defaultMessage: 'Last'
  },
  filterByOrderableColumns: {
    id: 'Notifications.filterByOrderableColumns',
    description: 'Filter by orderable columns placeholder',
    defaultMessage: 'Filter by orderable columns'
  },
  date: {
    id: 'Notifications.date',
    description: 'Date table header column label',
    defaultMessage: 'Date'
  },
  placeholder: {
    id: 'Notifications.placeholder',
    description: 'Table header column filter placeholder',
    defaultMessage: 'Enter'
  },
  source: {
    id: 'Notifications.source',
    description: 'Source table header column label',
    defaultMessage: 'Source'
  },
  name: {
    id: 'Notifications.name',
    description: 'Name table header column label',
    defaultMessage: 'Name'
  },
  description: {
    id: 'Notifications.description',
    description: 'Description table header column label',
    defaultMessage: 'Description'
  },
  severity: {
    id: 'Notifications.severity',
    description: 'Severity table header column label',
    defaultMessage: 'Severity'
  },
  status: {
    id: 'Notifications.status',
    description: 'Status table header column label',
    defaultMessage: 'Status'
  },
  fromAndToDatesAreTheSame: {
    id: 'Notifications.fromAndToDatesAreTheSame',
    description: 'From and To dates are the same warning',
    defaultMessage: '"From" and "To" dates are the same.'
  },
  toDateIsBeforeFromDate: {
    id: 'Notifications.toDateIsBeforeFromDate',
    description: 'To date is before from date warning',
    defaultMessage: '"To" date is before "From" date.'
  },
  dates: {
    id: 'Notifications.dates',
    description: 'Dates filter title',
    defaultMessage: 'DATES'
  },
  from: {
    id: 'Notifications.from',
    description: 'From floating label text',
    defaultMessage: 'From'
  },
  to: {
    id: 'Notifications.to',
    description: 'To floating label text',
    defaultMessage: 'To'
  },
  severityFilterTitle: {
    id: 'Notifications.severityFilterTitle',
    description: 'Severity filter title',
    defaultMessage: 'SEVERITY'
  },
  high: {
    id: 'Notifications.high',
    description: 'Severity high checkbox',
    defaultMessage: 'high'
  },
  medium: {
    id: 'Notifications.medium',
    description: 'Severity medium checkbox',
    defaultMessage: 'medium'
  },
  low: {
    id: 'Notifications.low',
    description: 'Severity low checkbox',
    defaultMessage: 'low'
  },
  statusFilterTitle: {
    id: 'Notifications.statusFilterTitle',
    description: 'Status filter title',
    defaultMessage: 'STATUS'
  },
  open: {
    id: 'Notifications.open',
    description: 'Status open checkbox',
    defaultMessage: 'open'
  },
  acknowledged: {
    id: 'Notifications.acknowledged',
    description: 'Status acknowledged checkbox',
    defaultMessage: 'acknowledged'
  },
  closed: {
    id: 'Notifications.closed',
    description: 'Status closed checkbox',
    defaultMessage: 'closed'
  },
  archived: {
    id: 'Notifications.archived',
    description: 'Archived option title',
    defaultMessage: 'ARCHIVED'
  },
  viewArchived: {
    id: 'Notifications.viewArchived',
    description: 'View archived option label',
    defaultMessage: 'View archived'
  },
  selectedNotifications: {
    id: 'Notifications.selectedNotifications',
    description: 'Selected notifications button label',
    defaultMessage: 'Selected notifications'
  },
  clearSelection: {
    id: 'Notifications.clearSelection',
    description: 'Clear selection button label',
    defaultMessage: 'Clear selection'
  },
  acknowledge: {
    id: 'Notifications.acknowledge',
    description: 'Acknowledge button label',
    defaultMessage: 'Acknowledge'
  },
  unacknowledge: {
    id: 'Notifications.unacknowledge',
    description: 'Unacknowledge button label',
    defaultMessage: 'Unacknowledge'
  },
  reopen: {
    id: 'Notifications.reopen',
    description: 'Reopen button label',
    defaultMessage: 'Reopen'
  },
  close: {
    id: 'Notifications.close',
    description: 'Close button label',
    defaultMessage: 'Close'
  },
  dateDetailLabel: {
    id: 'Notifications.dateDetailLabel',
    description: 'Date detail label',
    defaultMessage: 'Date'
  },
  severityDetailLabel: {
    id: 'Notifications.severityDateDetailLabel',
    description: 'Date detail label',
    defaultMessage: 'Severity'
  },
  currentStatusDetailLabel: {
    id: 'Notifications.currentStatusDetailLabel',
    description: 'Current status detail label',
    defaultMessage: 'Current status'
  },
  yes: {
    id: 'Notifications.yes',
    description: 'Yes',
    defaultMessage: 'Yes'
  },
  no: {
    id: 'Notifications.no',
    description: 'No',
    defaultMessage: 'No'
  },
  archivedDetailLabel: {
    id: 'Notifications.archivedDetailLabel',
    description: 'Archived detail label',
    defaultMessage: 'Archived'
  },
  descriptionDetailLabel: {
    id: 'Notifications.descriptionDetailLabel',
    description: 'Description detail label',
    defaultMessage: 'Description:'
  },
  historyDetailLabel: {
    id: 'Notifications.historyDetailLabel',
    description: 'History detail label',
    defaultMessage: 'History'
  },
  noEventsAvailable: {
    id: 'Notifications.noEventsAvailable',
    description: 'No events available warning text',
    defaultMessage: 'No events available'
  },
  user: {
    id: 'Notifications.user',
    description: 'User table header column label',
    defaultMessage: 'User'
  },
  observations: {
    id: 'Notifications.observations',
    description: 'Observations table header column label',
    defaultMessage: 'Observations'
  },
  error400Message: {
    id: 'Notifications.error400Message',
    description: 'Error 400 message',
    defaultMessage: 'Bad request.'
  },
  error404Message: {
    id: 'Notifications.error404Message',
    description: 'Error 404 message',
    defaultMessage: 'API not available at the moment. Try it later.'
  },
  errorUndefinedTitle: {
    id: 'Notifications.errorUndefinedTitle',
    description: 'Undefined error title',
    defaultMessage: 'Error undefined'
  },
  errorUndefinedMessage: {
    id: 'Notifications.errorUndefinedMessage',
    description: 'Undefined error message',
    defaultMessage: 'No description avaliable.'
  },
  cancel: {
    id: 'Notifications.cancel',
    description: 'Cancel button label',
    defaultMessage: 'Cancel'
  },
  yesClose: {
    id: 'Notifications.yesClose',
    description: 'Yes close button label',
    defaultMessage: 'Yes, close'
  },
  closeNotificationWarning: {
    id: 'Notifications.closeNotificationWarning',
    description: 'Notification closing warning text',
    defaultMessage: 'Are you sure you want to "close" this notification?'
  },
  observation: {
    id: 'Notifications.observation',
    description: 'Observation floating label text',
    defaultMessage: 'Observation'
  },
  yesReopen: {
    id: 'Notifications.yesReopen',
    description: 'Yes reopen button label',
    defaultMessage: 'Yes, reopen'
  },
  reopenNotificationWarning: {
    id: 'Notifications.reopenNotificationWarning',
    description: 'Notification reopening warning text',
    defaultMessage: 'Are you sure you want to "reopen" this notification?'
  },
  yesAcknowledge: {
    id: 'Notifications.yesAcknowledge',
    description: 'Yes acknowledge button label',
    defaultMessage: 'Yes, acknowledge'
  },
  acknowledgeNotificationWarning: {
    id: 'Notifications.acknowledgeNotificationWarning',
    description: 'Notification acknowledging warning text',
    defaultMessage: 'Are you sure you want to "acknowledge" this notification?'
  },
  yesUnacknowledge: {
    id: 'Notifications.yesUnacknowledge',
    description: 'Yes unacknowledge button label',
    defaultMessage: 'Yes, unacknowledge'
  },
  unacknowledgeNotificationWarning: {
    id: 'Notifications.unacknowledgeNotificationWarning',
    description: 'Notification unacknowledging warning text',
    defaultMessage: 'Are you sure you want to "unacknowledge" this notification?'
  },
  error: {
    id: 'Notifications.error',
    description: 'error text',
    defaultMessage: 'Error {number}'
  },
  ok: {
    id: 'Notifications.ok',
    description: 'Ok button label',
    defaultMessage: 'Ok'
  },
  clear: {
    id: 'Notifications.clear',
    description: 'Clear button label',
    defaultMessage: 'Clear'
  },
  deviceName: {
    id: 'Notifications.deviceName',
    defaultMessage: 'Device name'
  },
  deviceType: {
    id: 'Notifications.deviceType',
    defaultMessage: 'Device type'
  },
  reason: {
    id: 'Notifications.reason',
    defaultMessage: 'Reason'
  }
})

export default messages
