import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'

import Chip from '@material-ui/core/Chip'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import SearchIcon from '@material-ui/icons/Search'

import PageTitle from 'components/PageTitle'
import client from 'utils/http/client'

import { getAdaptedMaintenancesData } from '../apiAdapters'
import ServerErrorAlert from '../components/alerts/ServerErrorAlert'
import MaintenanceDeletionModal from '../components/modals/MaintenanceDeletionModal'
import { getFormattedFrequency } from '../helpers'
import messages from '../messages'
import { chipStyles, iconButtonStyles, paperStyles, tooltipStyles } from '../styles'
import { getTableOptions } from '../tableHelpers'
import { getNewMaintenanceUrl, getEditMaintenanceUrl, getMaintenanceDetailUrl } from '../urls'

const styles = {
  ...chipStyles,
  ...iconButtonStyles,
  ...paperStyles,
  ...tooltipStyles
}

class Maintenances extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMaintenancesLoading: false,
      maintenancesData: [],

      isDeleteMaintenanceLoading: false,
      maintenanceDeletionModalData: undefined,

      isApiError: false,

      currentPage: 1,
      currentPageSize: 10,
      totalSize: 0,
      sort: {},
      filter: {}
    }
  }

  componentDidMount() {
    this.fetchMaintenancesData()
  }

  componentDidUpdate(prevProps) {
    const { groupId } = this.props
    if (prevProps.groupId !== groupId) {
      this.fetchMaintenancesData()
    }
  }

  fetchMaintenancesData = () => {
    const { groupId } = this.props
    const { currentPage, currentPageSize, sort, filter } = this.state

    const mappedFilter = Object.entries(filter).reduce((acc, [key, value]) => {
      let mappedKey = ''
      let mappedValue = ''
      switch (key) {
        case 'name':
          mappedKey = 'name'
          mappedValue = value?.value
          break
        case 'isEnabled':
          mappedKey = 'notificationEnabled'
          mappedValue = value?.value === 'true' ? true : false
          break

        default:
          break
      }
      return { ...acc, [mappedKey]: mappedValue }
    }, {})

    this.setState({ isMaintenancesLoading: true, isApiError: false })
    client
      .getMaintenances({
        groupId,
        page: currentPage,
        size: currentPageSize,
        sort: sort.columnKey,
        order: sort.order,
        filter: mappedFilter
      })
      .then(response => {
        const maintenancesData = getAdaptedMaintenancesData(response.data)
        const totalSize = Number(response?.headers?.['x-total-count'] || 0)
        this.setState({ maintenancesData, totalSize })
      })
      .catch(() => {
        this.setState({ isApiError: true })
      })
      .finally(() => {
        this.setState({ isMaintenancesLoading: false })
      })
  }

  getTableOptions = () => {
    const { intl } = this.props
    const { isMaintenancesLoading, currentPage, currentPageSize, filter, sort } = this.state

    return getTableOptions({
      intl,
      isLoading: isMaintenancesLoading,
      page: currentPage,
      pageSize: currentPageSize,
      sort: {
        sortName: sort.columnKey,
        sortOrder: sort.order
      },
      filter,
      onPageChange: this.handlePageChange,
      onFilterChange: this.handleFilterChange,
      onSortChange: this.handleSortChange
    })
  }

  handleDeleteClick = maintenanceId => {
    const { maintenancesData } = this.state

    const maintenanceData = maintenancesData.find(item => item.hashId === maintenanceId) || {}
    this.setState({ maintenanceDeletionModalData: maintenanceData })
  }

  handleModalCloseClick = () => {
    this.setState({ maintenanceDeletionModalData: undefined })
  }

  handleModalDeleteClick = maintenanceId => {
    const { groupId } = this.props

    this.setState({ isDeleteMaintenanceLoading: true, isApiError: false })
    client
      .deleteMaintenance(groupId, maintenanceId)
      .then(() => {
        this.setState({ maintenanceDeletionModalData: undefined })
        this.fetchMaintenancesData()
      })
      .catch(() => {
        this.setState({ isApiError: true })
      })
      .finally(() => {
        this.setState({ isDeleteMaintenanceLoading: false })
      })
  }

  handlePageChange = (page, pageSize) => {
    this.setState({ currentPage: page, currentPageSize: pageSize }, this.fetchMaintenancesData)
  }

  handleSortChange = (columnKey, order) => {
    this.setState({ sort: { columnKey, order } }, this.fetchMaintenancesData)
  }

  handleFilterChange = filter => {
    this.setState({ filter }, this.fetchMaintenancesData)
  }

  formatFrequencyValue = (frequencyValue, row) => {
    const { intl } = this.props

    return getFormattedFrequency({
      intl,
      frequencyType: row.frequencyType,
      frequencyValue,
      frequencyUnit: row.frequencyUnit
    })
  }

  formatStateColumn = isEnabled => {
    const { classes, intl } = this.props

    return (
      <Chip
        className={classes.chipRoot + ' ' + (isEnabled ? classes.chipSuccess : classes.chipDanger)}
        label={isEnabled ? intl.formatMessage(messages.enabled) : intl.formatMessage(messages.disabled)}
      />
    )
  }

  formatActionsColumn = (cell, row) => {
    const { canDeleteNotifications, canEditNotifications, classes, intl } = this.props

    return (
      <div>
        <Link className='button-link' to={getMaintenanceDetailUrl(row.hashId)}>
          <Tooltip classes={{ tooltip: classes.tooltip }} title={intl.formatMessage(messages.details)}>
            <IconButton classes={{ root: classes.iconButtonRoot }}>
              <SearchIcon />
            </IconButton>
          </Tooltip>
        </Link>
        {canEditNotifications && (
          <Link className='button-link' to={getEditMaintenanceUrl(row.hashId)}>
            <Tooltip classes={{ tooltip: classes.tooltip }} title={intl.formatMessage(messages.edit)}>
              <IconButton classes={{ root: classes.iconButtonRoot }}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          </Link>
        )}
        {canDeleteNotifications && (
          <Tooltip classes={{ tooltip: classes.tooltip }} title={intl.formatMessage(messages.delete)}>
            <div style={{ padding: 0, display: '-webkit-inline-box' }}>
              <IconButton classes={{ root: classes.iconButtonRoot }} onClick={() => this.handleDeleteClick(row.hashId)}>
                <DeleteIcon />
              </IconButton>
            </div>
          </Tooltip>
        )}
      </div>
    )
  }

  render() {
    const { canCreateNotifications, classes, intl } = this.props
    const {
      isDeleteMaintenanceLoading,
      isApiError,
      isMaintenancesLoading,
      maintenancesData,
      maintenanceDeletionModalData,
      totalSize
    } = this.state

    const tableOptions = this.getTableOptions()
    const isMaintenanceDeletionModalOpen = Boolean(maintenanceDeletionModalData)

    const newMaintenanceButton = canCreateNotifications
      ? {
        icon: 'zmdi-plus',
        label: 'New Maintenance',
        path: getNewMaintenanceUrl(),
        primary: true
      }
      : null

    return (
      <div style={{ margin: '20px 25px 20px 20px' }}>
        <div className='container-fluid'>
          <PageTitle button={newMaintenanceButton} title={intl.formatMessage(messages.maintenances)} />
        </div>
        <div className='row' style={{ margin: '20px 0 0 0' }}>
          <div className='container-fluid'>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper classes={{ root: classes.paperRoot }}>
                  <Grid container spacing={3}>
                    {isApiError && (
                      <Grid item xs={12}>
                        <ServerErrorAlert />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <div className='table-with-pagination' style={{ padding: 0 }}>
                        <BootstrapTable
                          bordered={false}
                          data={isMaintenancesLoading ? [] : maintenancesData}
                          fetchInfo={{ dataTotalSize: totalSize }}
                          hover
                          keyField='hashId'
                          options={tableOptions}
                          pagination
                          remote={remoteObj => ({
                            ...remoteObj,
                            search: false,
                            pagination: true,
                            sizePerPage: true,
                            sort: false,
                            filter: true
                          })}
                        >
                          <TableHeaderColumn
                            dataField='name'
                            dataSort
                            filter={{
                              type: 'TextFilter',
                              delay: 400,
                              placeholder: `${intl.formatMessage(messages.filterBy)} ${intl
                                .formatMessage(messages.name)
                                .toLowerCase()}`
                            }}
                          >
                            {intl.formatMessage(messages.name)}
                          </TableHeaderColumn>
                          <TableHeaderColumn dataField='frequencyValue' dataFormat={this.formatFrequencyValue}>
                            {intl.formatMessage(messages.frequency)}
                          </TableHeaderColumn>
                          <TableHeaderColumn dataField='numberOfPendingInspections' dataSort>
                            {intl.formatMessage(messages.pendingInspections)}
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataField='isEnabled'
                            dataFormat={this.formatStateColumn}
                            filter={{
                              type: 'SelectFilter',
                              delay: 400,
                              options: {
                                true: intl.formatMessage(messages.enabled),
                                false: intl.formatMessage(messages.disabled)
                              },
                              placeholder: `${intl.formatMessage(messages.filterBy)} ${intl
                                .formatMessage(messages.state)
                                .toLowerCase()}`
                            }}
                          >
                            {intl.formatMessage(messages.state)}
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataAlign='center'
                            dataField='actions'
                            dataFormat={this.formatActionsColumn}
                            headerAlign='center'
                            width='180'
                          >
                            {intl.formatMessage(messages.actions)}
                          </TableHeaderColumn>
                        </BootstrapTable>
                      </div>
                    </Grid>
                  </Grid>
                </Paper>
                <MaintenanceDeletionModal
                  data={maintenanceDeletionModalData}
                  isDeleteMaintenanceLoading={isDeleteMaintenanceLoading}
                  isOpen={isMaintenanceDeletionModalOpen}
                  onCloseClick={this.handleModalCloseClick}
                  onDeleteClick={this.handleModalDeleteClick}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    )
  }
}

Maintenances.propTypes = {
  canCreateNotifications: PropTypes.bool.isRequired,
  canDeleteNotifications: PropTypes.bool.isRequired,
  canEditNotifications: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  groupId: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired
}

export default withStyles(styles)(injectIntl(Maintenances))
