const kbpsInMbps = mbps => mbps * 1000

const createBaudRateOption = mbps => {
  const kbps = kbpsInMbps(mbps)
  const bps = kbps * 1000 // cambio de especificaciones
  return { value: bps, label: `${kbps} kbps` }
}

export const optionsForGeneralSettings = {
  baudRate: [
    createBaudRateOption(0.05),
    createBaudRateOption(0.1),
    createBaudRateOption(0.125),
    createBaudRateOption(0.25),
    createBaudRateOption(0.5),
    createBaudRateOption(1)
  ]
}

export const optionsForSignalItem = () => {
  return {
    address: [
      { value: '11', label: '11 bit CAN ID' },
      { value: '29', label: '29 bit CAN ID' }
    ],
    canPort: [
      { value: 0, label: 'Port 0' },
      { value: 1, label: 'Port 1' }
    ]
  }
}

export const optionsForMFIOItem = () => {
  return {
    pinFunction0: [
      { value: 0, label: 'No MFIO', disabled: false },
      { value: 1, label: 'Digital output', disabled: false },
      { value: 2, label: 'Multi-function input', disabled: false },
      { value: 3, label: 'CLAMP 15', disabled: false }
    ],
    pinFunction1: [
      { value: 0, label: 'No MFIO', disabled: false },
      { value: 1, label: 'Digital output', disabled: false },
      { value: 2, label: 'Multi-function input', disabled: false }
    ],
    inputRange: [
      { value: 0, label: '0 - 5,25 V' },
      { value: 1, label: '0 - 35,3 V' }
    ],
    bias: [
      { value: 0, label: 'No Pull-Down, No Pull-Up' },
      { value: 1, label: 'No Pull-Down, Pull-Up to internal +5 V' },
      { value: 2, label: 'Pull-Down to internal GND, No Pull-Up' },
      { value: 3, label: 'Pull-Down to internal GND, Pull-Up to internal +5 V' }
    ],
    inputSignal: [
      { value: 'Voltage', label: 'Voltage (Analog) (mV)' },
      { value: 'Digital Input', label: 'Voltage (Digital) (0/1)' },
      { value: 'Frequency', label: 'Frequency (Hz)' },
      { value: 'Resistance', label: 'Resistance (Ohm)' },
      { value: 'Current', label: 'Current (µA)' }
    ],
    defaultPowerUp: [
      { value: true, label: 'High' },
      { value: false, label: 'Low' }
    ]
  }
}
