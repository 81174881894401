import { defineMessages } from 'react-intl'

const namespace = 'PieChart'

const messages = defineMessages({
  importantNotification: {
    id: `${namespace}.importantNotification`,
    description: 'Title',
    defaultMessage: 'IMPORTANT NOTIFICATION'
  },
  CS100AndCS500FirmwareUpdateMessage: {
    id: `${namespace}.CS100AndCS500FirmwareUpdateMessage`,
    description: 'Warning message',
    defaultMessage:
      'Please update via Service Tool all your CS500 firmware to v114 or later and your CS100 to firmware v170 or ' +
      'later to ensure connectivity after the November update – exact deadline will be communicated shorty.'
  },
  closeButton: {
    id: `${namespace}.closeButton`,
    description: 'Close button label',
    defaultMessage: 'Close'
  },
  CS100AndCS500FirmwareUpdateTitle: {
    id: `${namespace}.CS100AndCS500FirmwareUpdateTitle`,
    description: 'CS100 and CS500 Firmware Update',
    defaultMessage: 'CS100 and CS500 Firmware Update'
  }
})

export default messages
