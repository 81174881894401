import { connect } from 'react-redux'
import { utils } from 'ducks/theme'

import PortalLoading from './PortalLoading'

const mapStateToProps = state => ({
  logo: utils.getLogo(state)
})

export default connect(mapStateToProps)(PortalLoading)
