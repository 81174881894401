import PropTypes from 'prop-types'
import React from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

import SelectColumnsButton from 'components/SelectColumnsButton'
import { client, logError } from 'utils/http'
import { utcTimeToBrowserLocal } from 'utils/timeFormat'

import messages from './messages'
import { composeLocalQuery } from './utils'

const styles = {
  dialog: {
    minWidth: 800
  }
}
class AssignedMachinesTable extends React.Component {
  constructor(props) {
    super(props)
    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      assignedDevices: [],
      count: 0,
      dialogOpen: false,
      dialogOpenBulk: false,
      dialogSelectedDeviceToDeleteBulkTitle: '',
      dialogSelectedDeviceToDeleteEid: '',
      dialogSelectedDeviceToDeleteTitle: '',
      isAssignedDevicesLoading: false,
      selectedDevices: [],
      selectedDevicesEIDs: [],
      tableOptions: {
        noDataText: <CircularProgress />,
        sizePerPage: 5,
        page: 1
      },

      visibleColumns: {
        name: true,
        machineModel: true,
        machineType: true,
        machineBrand: false,
        machineSerialNumber: true,
        deviceType: true,
        eid: false,
        lastActivity: false,
        firmwareVersion: false,
        deviceConfiguration: true,
        deviceConfigurationVersion: false
      }
    }
  }

  componentDidMount() {
    this.getAssignedDevices()
  }

  getAssignedDevices = () => {
    const { ruleInstance, setAlert } = this.props
    const {
      tableOptions: { page, sizePerPage }
    } = this.state

    if (!ruleInstance) {
      this.setState(state => ({
        tableOptions: {
          ...state.tableOptions,
          noDataText: this.formatMessage(messages.noMachinesAssigned)
        }
      }))
    } else {
      this.setState(
        state => ({
          assignedDevices: [],
          isAssignedDevicesLoading: true,
          tableOptions: {
            ...state.tableOptions,
            noDataText: <CircularProgress />
          }
        }),
        () => {
          const query = composeLocalQuery(page, sizePerPage)
          client
            .getRuleInstanceDevicesAssigned(ruleInstance.hashId, query)
            .then(response => {
              const assignedDevicesCount = parseInt(response.headers['x-total-count'], 10)
              const assignedDevicesEIDs = response.data.map(device => device.eid)

              if (assignedDevicesCount === 0) {
                this.setState(state => {
                  const { noDataText, ...otherTableOptions } = state.tableOptions
                  return {
                    assignedDevices: [],
                    isAssignedDevicesLoading: false,
                    count: 0,
                    tableOptions: {
                      noDataText: this.formatMessage(messages.noMachinesAssigned),
                      ...otherTableOptions
                    }
                  }
                })
              } else if (response.data !== undefined) {
                this.getSelectedDevices(assignedDevicesEIDs, assignedDevicesCount)
              } else {
                const alertMessages = true
                const alertMessagesType = 'danger'
                const alertMessagesTitle = this.formatMessage(messages.errorText)
                const alertMessagesText = [this.formatMessage(messages.errorRequestingMachines)]

                setAlert(alertMessages, alertMessagesType, alertMessagesTitle, alertMessagesText)

                this.setState({
                  isAssignedDevicesLoading: false,
                  assignedDevices: [],
                  count: 0
                })
              }
            })
            .catch(response => {
              const error = { ...response }

              const alertMessages = true
              const alertMessagesType = 'danger'
              let alertMessagesTitle = ''
              let alertMessagesText = ['']

              if (error.response !== undefined && error.response.status !== undefined) {
                switch (error.response.status) {
                  case 400:
                    alertMessagesTitle = this.formatMessage(messages.error, { number: 400 })
                    alertMessagesText = [this.formatMessage(messages.error400Text)]
                    break
                  case 401:
                    alertMessagesTitle = this.formatMessage(messages.error, { number: 400 })
                    alertMessagesText = [error.response.message]
                    break
                  case 403:
                    alertMessagesTitle = this.formatMessage(messages.error, { number: 403 })
                    alertMessagesText = [this.formatMessage(messages.error403Text)]
                    break
                  case 404:
                    alertMessagesTitle = this.formatMessage(messages.error, { number: 404 })
                    alertMessagesText = [this.formatMessage(messages.error404Text)]
                    break
                  case 406:
                    alertMessagesTitle = this.formatMessage(messages.error, { number: 406 })
                    alertMessagesText = [this.formatMessage(messages.error406Text)]
                    break
                  case 500:
                    alertMessagesTitle = this.formatMessage(messages.error, { number: 500 })
                    alertMessagesText = [error.response.data.error_description]
                    break
                  default:
                    alertMessagesTitle = this.formatMessage(messages.errorUndefinedTitle)
                    alertMessagesText = [this.formatMessage(messages.errorUndefinedText)]
                    logError(response)
                }

                this.setState(state => {
                  const { noDataText, ...otherTableOptions } = state.tableOptions
                  return {
                    assignedDevices: [],
                    isAssignedDevicesLoading: false,
                    count: 0,
                    tableOptions: {
                      noDataText: this.formatMessage(messages.noMachines),
                      ...otherTableOptions
                    }
                  }
                })
                setAlert(alertMessages, alertMessagesType, alertMessagesTitle, alertMessagesText)
              } else {
                this.setState(state => {
                  const { noDataText, ...otherTableOptions } = state.tableOptions
                  return {
                    assignedDevices: [],
                    isAssignedDevicesLoading: false,
                    count: 0,
                    tableOptions: {
                      noDataText: this.formatMessage(messages.noMachines),
                      ...otherTableOptions
                    }
                  }
                })
                alertMessagesTitle = this.formatMessage(messages.errorUndefinedTitle)
                alertMessagesText = [this.formatMessage(messages.errorUndefinedText)]

                setAlert(alertMessages, alertMessagesType, alertMessagesTitle, alertMessagesText)
              }
            })
        }
      )
    }
  }

  getSelectedDevices = (assignedDevicesEIDs, assignedDevicesCount) => {
    const { fetchCSNodesDetails, ruleInstance, setAlert } = this.props

    fetchCSNodesDetails(ruleInstance.groupId, assignedDevicesEIDs)
      .then(resp => {
        this.setState({
          assignedDevices: resp.data.devices.map(device => ({
            ...device,
            configurationName: device.Configuration?.name,
            configurationVersionNumber: device.Configuration?.versionNumber
          })),
          isAssignedDevicesLoading: false,
          count: assignedDevicesCount
        })
      })
      .catch(errorResponse => {
        const error = errorResponse.error

        const alertMessages = true
        const alertMessagesType = 'danger'
        let alertMessagesTitle = ''
        let alertMessagesText = ['']

        if (error.response !== undefined && error.response.status !== undefined) {
          switch (error.response.status) {
            case 400:
              alertMessagesTitle = this.formatMessage(messages.error, { number: 400 })
              alertMessagesText = [this.formatMessage(messages.error400Text)]
              break
            case 401:
              alertMessagesTitle = this.formatMessage(messages.error, { number: 401 })
              alertMessagesText = [error.response.message]
              break
            case 403:
              alertMessagesTitle = this.formatMessage(messages.error, { number: 403 })
              alertMessagesText = [this.formatMessage(messages.error403Text)]
              break
            case 404:
              alertMessagesTitle = this.formatMessage(messages.error, { number: 404 })
              alertMessagesText = [this.formatMessage(messages.error404Text)]
              break
            case 500:
              alertMessagesTitle = this.formatMessage(messages.error, { number: 500 })
              alertMessagesText = [error.response.data.error_description]
              break
            default:
              alertMessagesTitle = this.formatMessage(messages.errorUndefinedTitle)
              alertMessagesText = [this.formatMessage(messages.errorUndefinedText)]
          }

          this.setState(state => {
            const { noDataText, ...otherTableOptions } = state.tableOptions
            return {
              assignedDevices: [],
              isAssignedDevicesLoading: false,
              count: 0,
              tableOptions: {
                noDataText: this.formatMessage(messages.noMachines),
                ...otherTableOptions
              }
            }
          })
        } else {
          this.setState(state => {
            const { noDataText, ...otherTableOptions } = state.tableOptions
            return {
              assignedDevices: [],
              isAssignedDevicesLoading: false,
              count: 0,
              tableOptions: {
                noDataText: this.formatMessage(messages.noMachines),
                ...otherTableOptions
              }
            }
          })
          alertMessagesTitle = this.formatMessage(messages.errorUndefinedTitle)
          alertMessagesText = [this.formatMessage(messages.errorUndefinedText)]
        }
        setAlert(alertMessages, alertMessagesType, alertMessagesTitle, alertMessagesText)
      })
  }

  deleteDevice = device => {
    const { name: deviceTitle, EID: deviceEID } = device

    this.setState({
      dialogOpen: true,
      dialogSelectedDeviceToDeleteTitle: deviceTitle,
      dialogSelectedDeviceToDeleteEid: deviceEID
    })
  }

  handleDeleteDeviceBulkClick = () => {
    const { selectedDevicesEIDs } = this.state
    const deviceTitle = this.formatMessage(messages.unassignMachines, { number: selectedDevicesEIDs.length })

    this.setState({
      dialogOpenBulk: true,
      dialogSelectedDeviceToDeleteBulkTitle: deviceTitle
    })
  }

  handleClose = () => {
    this.setState({
      dialogOpen: false,
      dialogSelectedDeviceToDeleteTitle: '',
      dialogSelectedDeviceToDeleteEid: '',
      dialogOpenBulk: false,
      dialogSelectedDeviceToDeleteBulkTitle: ''
    })
  }

  handleDelete = () => {
    const { dialogSelectedDeviceToDeleteEid } = this.state
    this.deleteDevices([dialogSelectedDeviceToDeleteEid])
  }

  handleBulkDelete = () => {
    const { selectedDevicesEIDs } = this.state
    this.deleteDevices(selectedDevicesEIDs)
  }

  deleteDevices = devicesToUnassign => {
    const { ruleInstance, setAlert, fetchRuleInstance } = this.props
    const { assignedDevices, count } = this.state

    let alertMessages = false
    let alertMessagesType = ''
    let alertMessagesTitle = ''
    let alertMessagesText = ['']

    //Delete from notificacion assigned devices the selected devices
    //Check at least one device is left
    const newDevicesAssignement = assignedDevices.filter(function (item) {
      const obj = devicesToUnassign.find(deviceEid => deviceEid === item.EID)
      if (obj === undefined) {
        return true
      } else {
        return false
      }
    })

    if (newDevicesAssignement.length === 0 && assignedDevices.length === count) {
      this.setState({
        dialogOpen: false,
        dialogSelectedDeviceToDeleteTitle: '',
        dialogOpenBulk: false,
        dialogSelectedDeviceToDeleteBulkTitle: '',
        selectedDevices: [],
        selectedDevicesEIDs: []
      })

      alertMessages = true
      alertMessagesType = 'danger'
      alertMessagesTitle = this.formatMessage(messages.errorText)
      alertMessagesText = [
        this.formatMessage(messages.unassignMachinesError),
        this.formatMessage(messages.assignAtLeastAMachine)
      ]
      setAlert(alertMessages, alertMessagesType, alertMessagesTitle, alertMessagesText)
    } else {
      client
        .desassignDevicesToRuleInstance(ruleInstance.hashId, devicesToUnassign)
        .then(response => {
          if (response.data !== undefined) {
            alertMessages = true
            alertMessagesType = 'success'
            alertMessagesTitle = ''
            alertMessagesText = [this.formatMessage(messages.machinesUnassignedCorrectly)]

            this.setState(
              {
                dialogOpen: false,
                dialogSelectedDeviceToDeleteTitle: '',
                dialogOpenBulk: false,
                dialogSelectedDeviceToDeleteBulkTitle: '',
                selectedDevices: [],
                selectedDevicesEIDs: []
              },
              () => {
                setAlert(alertMessages, alertMessagesType, alertMessagesTitle, alertMessagesText)
                fetchRuleInstance()
                this.getAssignedDevices()
              }
            )
          }
        })
        .catch(response => {
          const error = { ...response }

          alertMessages = true
          alertMessagesType = 'danger'

          switch (error.response.status) {
            case 400:
              alertMessagesTitle = this.formatMessage(messages.error, { number: 400 })
              alertMessagesText = [this.formatMessage(messages.error400Text)]
              break
            case 401:
              alertMessagesTitle = this.formatMessage(messages.error, { number: 401 })
              alertMessagesText = [error.response.message]
              break
            case 403:
              alertMessagesTitle = this.formatMessage(messages.error, { number: 403 })
              alertMessagesText = [this.formatMessage(messages.error403Text)]
              break
            case 404:
              alertMessagesTitle = this.formatMessage(messages.error, { number: 404 })
              alertMessagesText = [this.formatMessage(messages.error404Text)]
              break
            case 406:
              alertMessagesTitle = this.formatMessage(messages.error, { number: 406 })
              alertMessagesText = [this.formatMessage(messages.error406Text)]
              break
            case 409:
              alertMessagesTitle = this.formatMessage(messages.error, { number: 409 })
              alertMessagesText = [this.formatMessage(messages.error409Text)]
              break
            case 415:
              alertMessagesTitle = this.formatMessage(messages.error, { number: 415 })
              alertMessagesText = [this.formatMessage(messages.error415Text)]
              break
            case 422:
              alertMessagesTitle = this.formatMessage(messages.error, { number: 422 })
              alertMessagesText = [this.formatMessage(messages.error422Text)]
              break
            case 500:
              alertMessagesTitle = this.formatMessage(messages.error, { number: 500 })
              alertMessagesText = [error.response.data.error_description]
              break
            default:
              alertMessagesTitle = this.formatMessage(messages.errorUndefinedTitle)
              alertMessagesText = [this.formatMessage(messages.errorUndefinedText)]

              logError(response)
          }
          setAlert(alertMessages, alertMessagesType, alertMessagesTitle, alertMessagesText)
        })
    }
  }

  formatActionsDetail = (cell, row) => (
    <Button
      onClick={() => {
        this.deleteDevice(row)
      }}
      style={{ paddingRight: 0, paddingLeft: 0 }}
      title={this.formatMessage(messages.unassignMachine)}
    >
      <Icon className='zmdi zmdi-close-circle-o' style={{ color: '#C00000', margin: 0 }} />
    </Button>
  )

  handleClearSelectedDevicesClick = () => {
    this.setState({
      selectedDevices: [],
      selectedDevicesEIDs: []
    })
  }

  handleChangeColumnVisibility = columnId => {
    this.setState(state => {
      const updatedColumnVisibility = !state.visibleColumns[columnId]
      return {
        visibleColumns: {
          ...state.visibleColumns,
          [columnId]: updatedColumnVisibility
        }
      }
    })
  }

  formatLastActivity = operatingTime => {
    return utcTimeToBrowserLocal(operatingTime.lastDateTime)
  }

  formatMachineField = cell => {
    return cell ? cell : '-'
  }

  formatActiveConfigurationColumn = cell => {
    return cell?.name ? cell.name : '-'
  }

  formatActiveConfigurationVersionColumn = cell => {
    return cell?.versionNumber ? cell.versionNumber : '-'
  }

  onRowSelect = (row, isSelected) => {
    const { selectedDevices, selectedDevicesEIDs } = this.state
    const element = row
    const elementId = row.id

    const newSelectedDevices = selectedDevices.map(deviceId => deviceId)
    const newSelectedDevicesEIDs = selectedDevicesEIDs.map(deviceEid => deviceEid)
    const indexOfDevice = newSelectedDevices.indexOf(elementId)

    if (isSelected) {
      if (indexOfDevice < 0) {
        newSelectedDevices.push(elementId)
        newSelectedDevicesEIDs.push(element.EID)
      }
    } else {
      if (indexOfDevice > -1) {
        newSelectedDevices.splice(indexOfDevice, 1)
        newSelectedDevicesEIDs.splice(indexOfDevice, 1)
      }
    }

    this.setState({
      selectedDevices: newSelectedDevices,
      selectedDevicesEIDs: newSelectedDevicesEIDs
    })
  }

  onSelectAll = (isSelected, rows) => {
    const { selectedDevices, selectedDevicesEIDs } = this.state

    const newSelectedDevices = selectedDevices.map(deviceId => deviceId)
    const newSelectedDevicesEIDs = selectedDevicesEIDs.map(deviceEid => deviceEid)

    const devicesToProcess = rows.map(device => {
      return device
    })

    devicesToProcess.forEach(device => {
      const indexOfDevice = newSelectedDevices.indexOf(device.id)

      if (isSelected) {
        if (indexOfDevice < 0) {
          newSelectedDevices.push(device.id)
          newSelectedDevicesEIDs.push(device.EID)
        }
      } else {
        if (indexOfDevice > -1) {
          newSelectedDevices.splice(indexOfDevice, 1)
          newSelectedDevicesEIDs.splice(indexOfDevice, 1)
        }
      }

      return device
    })

    this.setState({
      selectedDevices: newSelectedDevices,
      selectedDevicesEIDs: newSelectedDevicesEIDs
    })
  }

  onPageChange = (page, sizePerPage) => {
    this.setState(
      state => {
        const { page: previousPage, sizePerPage: previousSizePage, ...otherTableOptions } = state.tableOptions
        return {
          assignedDevices: [],
          tableOptions: { page, sizePerPage, ...otherTableOptions }
        }
      },
      () => {
        this.getAssignedDevices()
      }
    )
  }

  onSizePerPageList = sizePerPage => {
    this.setState(
      state => {
        const { sizePerPage: previousSizePage, ...otherTableOptions } = state.tableOptions
        return {
          tableOptions: { sizePerPage, ...otherTableOptions }
        }
      },
      () => this.getAssignedDevices()
    )
  }

  getTableOptions = () => {
    const { tableOptions } = this.state
    const completeTableOptions = {
      ...tableOptions,
      onSizePerPageList: this.onSizePerPageList,
      sizePerPageList: [
        {
          text: '5',
          value: 5
        },
        {
          text: '50',
          value: 50
        },
        {
          text: '100',
          value: 100
        },
        {
          text: '200',
          value: 200
        }
      ],
      onPageChange: this.onPageChange,
      ignoreSinglePage: false,
      pageStartIndex: 1,
      paginationSize: 5,
      prePage: this.formatMessage(messages.prePage),
      nextPage: this.formatMessage(messages.nextPage),
      firstPage: this.formatMessage(messages.firstPage),
      lastPage: this.formatMessage(messages.lastPage),
      paginationShowsTotal: this.renderPaginationShowsTotal(this.formatMessage),
      paginationPosition: 'bottom',
      hideSizePerPage: false,
      alwaysShowAllBtns: false,
      withFirstAndLast: true,
      onFilterChange: this.onFilterChange,
      onSortChange: this.onSortChange
    }

    return completeTableOptions
  }

  renderPaginationShowsTotal = formatMessage => (start, to, total) =>
    (
      <span>
        {`${formatMessage(messages.showingRows)} ${start} ${formatMessage(messages.to)} ${to} ${formatMessage(
          messages.of
        )} ${total}`}
      </span>
    )

  render() {
    const { classes, tableHeight } = this.props
    const {
      assignedDevices,
      count,
      dialogOpen,
      dialogOpenBulk,
      dialogSelectedDeviceToDeleteBulkTitle,
      dialogSelectedDeviceToDeleteTitle,
      isAssignedDevicesLoading,
      selectedDevices,
      visibleColumns
    } = this.state

    const selectRowProp = {
      mode: 'checkbox',
      clickToSelect: false,
      onSelect: this.onRowSelect,
      onSelectAll: this.onSelectAll,
      bgColor: '#f5f5f5',
      selected: selectedDevices
    }

    const tableOptions = this.getTableOptions()

    const data = isAssignedDevicesLoading ? [] : assignedDevices

    return (
      <div className='col-md-12' style={{ height: tableHeight, padding: 0 }}>
        <Paper style={{ height: '100%', borderRadius: 0 }}>
          <Grid container style={{ height: '100%' }}>
            <Grid container item spacing={2} style={{ paddingTop: 12, paddingLeft: 10 }} xs={12}>
              <Grid container item spacing={1} xs={9}>
                <Grid item>
                  <Button disabled>
                    {this.formatMessage(messages.selectedMachines)} ({selectedDevices.length})
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    className='secondary-action-button'
                    disabled={selectedDevices.length === 0}
                    onClick={this.handleClearSelectedDevicesClick}
                  >
                    {this.formatMessage(messages.clearSelection)}
                  </Button>
                </Grid>
                <Grid item>
                  <SelectColumnsButton
                    columns={visibleColumns}
                    onChangeColumnVisibility={this.handleChangeColumnVisibility}
                  />
                </Grid>
              </Grid>
              <Grid alignContent='flex-start' container item justifyContent='flex-end' xs={3}>
                <Button
                  className='delete-button'
                  disabled={selectedDevices.length === 0}
                  onClick={this.handleDeleteDeviceBulkClick}
                  style={{ marginTop: 14, marginLeft: 5 }}
                >
                  <Icon className='zmdi zmdi-close-circle-o' />
                  {this.formatMessage(messages.unassign)}
                </Button>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <div className='table-with-pagination' style={{ height: '85%', width: '100%' }}>
                <BootstrapTable
                  bodyStyle={{ height: '65%' }}
                  bordered={false}
                  condensed={false}
                  containerStyle={{ height: '100%' }}
                  data={data}
                  exportCSV={false}
                  fetchInfo={{ dataTotalSize: count }}
                  hover
                  keyField='id'
                  multiColumnSearch={false}
                  options={tableOptions}
                  pagination
                  remote={remoteObj => ({
                    ...remoteObj,
                    search: false,
                    pagination: true,
                    sizePerPage: true,
                    sort: false,
                    filter: false
                  })}
                  search={false}
                  searchPlaceholder={this.formatMessage(messages.searchPlaceholder)}
                  selectRow={selectRowProp}
                  striped={false}
                  tableStyle={{ height: '80%' }}
                >
                  <TableHeaderColumn dataField='id' hidden />
                  <TableHeaderColumn dataField='name' hidden={!visibleColumns.name}>
                    {this.formatMessage(messages.name)}
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField='machineModel'
                    dataFormat={this.formatMachineField}
                    hidden={!visibleColumns.machineModel}
                  >
                    {this.formatMessage(messages.machineModel)}
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField='machineType'
                    dataFormat={this.formatMachineField}
                    hidden={!visibleColumns.machineType}
                  >
                    {this.formatMessage(messages.machineType)}
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField='machineBrand'
                    dataFormat={this.formatMachineField}
                    hidden={!visibleColumns.machineBrand}
                  >
                    {this.formatMessage(messages.machineBrand)}
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField='machineSerialNumber'
                    dataFormat={this.formatMachineField}
                    hidden={!visibleColumns.machineSerialNumber}
                  >
                    {this.formatMessage(messages.machineSerialNumber)}
                  </TableHeaderColumn>
                  <TableHeaderColumn dataField='device_type' hidden={!visibleColumns.deviceType}>
                    {this.formatMessage(messages.deviceType)}
                  </TableHeaderColumn>
                  <TableHeaderColumn dataField='EID' hidden={!visibleColumns.eid}>
                    {this.formatMessage(messages.eid)}
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField='operatingTime'
                    dataFormat={this.formatLastActivity}
                    hidden={!visibleColumns.lastActivity}
                  >
                    {this.formatMessage(messages.lastActivity)}
                  </TableHeaderColumn>
                  <TableHeaderColumn dataField='mainFirmwareVersion' hidden={!visibleColumns.firmwareVersion}>
                    {this.formatMessage(messages.firmwareVersion)}
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField='Configuration'
                    dataFormat={this.formatActiveConfigurationColumn}
                    hidden={!visibleColumns.deviceConfiguration}
                  >
                    {this.formatMessage(messages.deviceConfiguration)}
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField='Configuration'
                    dataFormat={this.formatActiveConfigurationVersionColumn}
                    hidden={!visibleColumns.deviceConfigurationVersion}
                  >
                    {this.formatMessage(messages.deviceConfigurationVersion)}
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            </Grid>
          </Grid>
        </Paper>
        <Dialog classes={{ paper: classes.dialog }} onClose={this.handleClose} open={dialogOpen}>
          <DialogContent style={{ padding: '12px 10px 10px 12px' }}>
            <DialogTitle style={{ padding: 12 }}>
              <IconButton
                onClick={this.handleClose}
                style={{
                  position: 'absolute',
                  right: 3,
                  top: 3,
                  padding: 5
                }}
              >
                <CloseIcon />
              </IconButton>
              {this.formatMessage(messages.confirmMachineUnassignament)}
            </DialogTitle>
            <DialogContentText style={{ padding: 12 }}>{dialogSelectedDeviceToDeleteTitle}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button key='delete-button' className='delete-button' onClick={this.handleDelete}>
              {this.formatMessage(messages.unassign)}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog classes={{ paper: classes.dialog }} onClose={this.handleClose} open={dialogOpenBulk}>
          <DialogContent style={{ padding: '12px 10px 10px 12px' }}>
            <DialogTitle style={{ padding: 12 }}>
              <IconButton
                onClick={this.handleClose}
                style={{
                  position: 'absolute',
                  right: 3,
                  top: 3,
                  padding: 5
                }}
              >
                <CloseIcon />
              </IconButton>
              {this.formatMessage(messages.confirmMachineUnassignament)}
            </DialogTitle>
            <DialogContentText style={{ padding: 12 }}>{dialogSelectedDeviceToDeleteBulkTitle}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button key='delete-button' className='delete-button' onClick={this.handleBulkDelete}>
              {this.formatMessage(messages.unassign)}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

AssignedMachinesTable.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchCSNodesDetails: PropTypes.func.isRequired,
  fetchRuleInstance: PropTypes.func.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired }).isRequired,
  ruleInstance: PropTypes.object,
  setAlert: PropTypes.func.isRequired,
  tableHeight: PropTypes.string.isRequired
}

AssignedMachinesTable.defaultProps = {
  ruleInstance: null
}

export default withStyles(styles)(injectIntl(AssignedMachinesTable))
