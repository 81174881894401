import { defineMessages } from 'react-intl'

const messages = defineMessages({
  download: {
    id: 'ViewConfiguration.download',
    description: 'Download button label',
    defaultMessage: 'Download'
  },
  cancel: {
    id: 'ViewConfiguration.cancel',
    description: 'Cancel button label',
    defaultMessage: 'Cancel'
  },
  createdDate: {
    id: 'ViewConfiguration.createdDate',
    description: 'Created date',
    defaultMessage: 'Created date'
  },
  generalSettings: {
    id: 'ViewConfiguration.generalSettings',
    description: 'General settings card title',
    defaultMessage: 'Device settings'
  },
  gpsConditions: {
    id: 'ViewConfiguration.gpsConditions',
    description: 'GPS conditions card title',
    defaultMessage: 'GPS Conditions'
  },
  modelName: {
    id: 'ViewConfiguration.modelName',
    description: 'Model name input label',
    defaultMessage: 'Configuration name'
  },
  nodeId: {
    id: 'ViewConfiguration.nodeId',
    description: 'Node id input label',
    defaultMessage: 'PLUS+1 Node ID'
  },
  baudRate1: {
    id: 'ViewConfiguration.baudRate1',
    description: 'Baud rate 1 select label',
    defaultMessage: 'CAN port 0 baud rate (bps)'
  },
  baudRate2: {
    id: 'ViewConfiguration.baudRate2',
    description: 'Baud rate 2 select label',
    defaultMessage: 'CAN port 1 baud rate (bps)'
  },
  loggingUploadPeriod: {
    id: 'ViewConfiguration.loggingUploadPeriod',
    description: 'LoggingUploadPeriod input label',
    defaultMessage: 'Logging upload period (s)'
  },
  loggingBufferThreshold: {
    id: 'ViewConfiguration.loggingBufferThreshold',
    description: 'LoggingBufferThreshold input label',
    defaultMessage: 'Logging buffer threshold (No. of messages)'
  },
  dataLogging: {
    id: 'ViewConfiguration.dataLogging',
    description: 'Data logging checkbox label',
    defaultMessage: 'Data logging'
  },
  sendGPSCoordinates: {
    id: 'ViewConfiguration.sendGPSCoordinates',
    description: 'sendGPSCoordinates checkbox label',
    defaultMessage: 'Send GPS coordinates'
  },
  frequency: {
    id: 'ViewConfiguration.frequency',
    description: 'Frequency texfield label',
    defaultMessage: 'Frequency'
  },
  timeInterval: {
    id: 'Signal.timeInterval',
    description: 'Time interval texfield label',
    defaultMessage: 'Time interval (s)'
  },
  canbus: {
    id: 'ViewConfiguration.canbus',
    description: 'CAN bus paper title',
    defaultMessage: 'CAN bus'
  },
  mfio: {
    id: 'ViewConfiguration.mfio',
    description: 'MFIO paper title',
    defaultMessage: 'Multi-Function I/O'
  },
  history: {
    id: 'ViewConfiguration.history',
    description: 'History card title',
    defaultMessage: 'History'
  },
  version: {
    id: 'ViewConfiguration.version',
    description: 'version',
    defaultMessage: 'Version'
  },
  published: {
    id: 'ViewConfiguration.published',
    description: 'published',
    defaultMessage: 'Published'
  },
  parentRoot: {
    id: 'ViewConfiguration.parentRoot',
    description: 'Parent root message',
    defaultMessage: 'This is a root parent configuration'
  },
  speed: {
    id: 'ViewConfiguration.speed',
    description: 'speed',
    defaultMessage: 'Speed'
  },
  altitude: {
    id: 'ViewConfiguration.altitude',
    description: 'altitude',
    defaultMessage: 'Altitude'
  },
  heading: {
    id: 'ViewConfiguration.heading',
    description: 'heading',
    defaultMessage: 'Heading'
  },
  error400Message: {
    id: 'ViewConfiguration.error400Message',
    description: 'Error 400 message',
    defaultMessage: 'Bad request'
  },
  error403Message: {
    id: 'ViewConfiguration.error403Message',
    description: 'Error 403 message',
    defaultMessage: 'Access denied'
  },
  error404Message: {
    id: 'ViewConfiguration.error404Message',
    description: 'Error 404 message',
    defaultMessage: 'API url not found.'
  },
  error406Message: {
    id: 'ViewConfiguration.error406Message',
    description: 'Error 406 message',
    defaultMessage: 'Not acceptable'
  },
  errorUndefinedTitle: {
    id: 'ViewConfiguration.errorUndefinedTitle',
    description: 'Error undefined title',
    defaultMessage: 'Error undefined'
  },
  errorUndefinedMessage: {
    id: 'ViewConfiguration.errorUndefinedMessage',
    description: 'Error undefined message',
    defaultMessage: 'No description available.'
  },
  error409Message: {
    id: 'ViewConfiguration.error409Message',
    description: 'Error 409 message',
    defaultMessage: 'Data integrity violation: Configuration name it\'s already in use'
  },
  error415Message: {
    id: 'ViewConfiguration.error415Message',
    description: 'Error 415 message',
    defaultMessage: 'Unsupported media type'
  },
  error422Message: {
    id: 'ViewConfiguration.error422Message',
    description: 'Error 422 message',
    defaultMessage: 'Validation failed'
  },
  error: {
    id: 'ViewConfiguration.error',
    description: 'error text',
    defaultMessage: 'Error {number}'
  },
  error401Message: {
    id: 'ViewConfiguration.error401Message',
    description: 'Error 401 message',
    defaultMessage: 'Invalid credentials'
  }
})

export default messages
