import PropTypes from 'prop-types'
import React from 'react'

import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import IconButton from '@material-ui/core/IconButton'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

import Alert from 'components/Alert'
import Loading from 'components/Loading'
import { client, logError } from 'utils/http'

import messages from './messages'

const styles = {
  radioButton: {
    color: '#5d5d5d',
    '&$checked': {
      color: '#1592E6'
    }
  },
  checked: {},
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid #dadde9',
    fontSize: 14
  }
}

class UpdateFirmware extends React.Component {
  constructor(props) {
    super(props)
    const {
      intl: { formatMessage },
      classes
    } = props
    this.formatMessage = formatMessage
    this.classes = classes
    this.state = {
      alertMessages: false,
      alertMessagesText: [''],
      alertMessagesTitle: '',
      alertMessagesType: '',
      firmwareApplyed: false,
      firmwareApplying: false,
      firmwares: {},
      loading: false,
      selectedFW: null,
      showMoreDevices: false,
      readMoreReadLessButtonLabel: this.formatMessage(messages.readMore)
    }
  }

  componentDidMount() {
    const {
      csNodes: [{ deviceType }]
    } = this.props

    this.setState(
      {
        loading: true
      },
      () => {
        this.getFirmwares(deviceType)
      }
    )
  }

  getFirmwares = deviceType => {
    const { getAvailableFOTA, groupId } = this.props

    getAvailableFOTA(groupId)
      .then(response => {
        const { data } = response
        let firmwares = {}
        if (Object.keys(data).length > 0) {
          const { beta = [], generic = [], interlink = [], mine = [] } = data
          switch (deviceType) {
            case 'CS100':
              firmwares = { generic, mine, beta }
              break
            case 'CS500':
              firmwares = { interlink, mine }
              break
            default:
              break
          }
        }
        this.setState({
          firmwares,
          loading: false
        })
      })
      .catch(response => {
        const { intl } = this.props
        const error = { ...response }
        if (error.response) {
          switch (error.response.status) {
            case 400: // Bad request
              this.setState({
                loading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, {
                  number: '400'
                }),
                alertMessagesText: [this.formatMessage(messages.error400Message)]
              })
              break
            case 401: // Invalid credentials
              let message
              if (intl.locale === 'en') message = error.response.message
              else message = this.formatMessage(messages.error401Message)
              this.setState({
                loading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, {
                  number: '401'
                }),
                alertMessagesText: [message]
              })
              break
            case 403: // Access denied
              this.setState({
                loading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, {
                  number: '403'
                }),
                alertMessagesText: [this.formatMessage(messages.error403Message)]
              })
              break
            case 404: // API url not found
              this.setState({
                loading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, {
                  number: '404'
                }),
                alertMessagesText: [this.formatMessage(messages.error404Message)]
              })
              break
            case 406: // Not acceptable
              this.setState({
                loading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, {
                  number: '406'
                }),
                alertMessagesText: [this.formatMessage(messages.error406Message)]
              })
              break
            case 409: // Data integrity violation
              this.setState({
                loading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, {
                  number: '409'
                }),
                alertMessagesText: [this.formatMessage(messages.error409Message)]
              })
              break
            case 415: // Unsupported media type
              this.setState({
                loading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, {
                  number: '415'
                }),
                alertMessagesText: [this.formatMessage(messages.error415Message)]
              })
              break
            case 422: // Validation failed
              this.setState({
                loading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, {
                  number: '422'
                }),
                alertMessagesText: [this.formatMessage(messages.error422Message)]
              })
              break
            case 500: // Server errors
              this.setState({
                loading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, {
                  number: '500'
                }),
                alertMessagesText: [error.response.data.error_description]
              })
              break
            default:
              this.setState({
                loading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
                alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
              })
              logError(response)
          }
        } else {
          this.setState({
            loading: false,
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
            alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
          })
          logError(response)
        }
      })
  }

  handleReadMoreReadLessButtonClick = () => {
    this.setState(state => {
      return {
        showMoreDevices: !state.showMoreDevices,
        readMoreReadLessButtonLabel: !state.showMoreDevices
          ? this.formatMessage(messages.readLess)
          : this.formatMessage(messages.readMore)
      }
    })
  }

  closeAlert = () => {
    this.setState({
      alertMessages: false,
      alertMessagesText: [''],
      alertMessagesTitle: '',
      alertMessagesType: ''
    })
  }

  handleSelectionChange = event => {
    this.setState({
      selectedFW: {
        type: 'main',
        file: event.target.value,
        fileName: event.target.value
      }
    })
  }

  handleFirmwareUpdate = () => {
    const { action, csNodes, groupId } = this.props
    const { selectedFW: firmwareFile } = this.state

    this.setState(
      {
        firmwareApplying: true,
        firmwareApplyed: false
      },
      () => {
        this.createTasks(groupId, action, csNodes, firmwareFile)
      }
    )
  }

  createTasks = (groupId, action, nodes, firmware) => {
    const actionId = action.hashId
    const actionName = action.name
    const nodesToTasks = nodes.map(node => {
      return {
        nodeHashId: node.id,
        nodeName: node.name
      }
    })
    const firmwareFile = firmware.file
    const firmwareFileName = firmware.fileName
    const infoForTasks = {
      actionHashId: actionId,
      actionName,
      actionNodeType: 'CS',
      tasks: nodesToTasks,
      groupId,
      variables: [
        {
          name: 'file',
          type: 0,
          value: firmwareFile
        },
        {
          name: 'fileName',
          type: 0,
          value: firmwareFileName
        }
      ]
    }

    client
      .newTasks(actionId, infoForTasks)
      .then(response => {
        this.setState({
          firmwareApplying: false,
          firmwareApplyed: true,
          alertMessages: true,
          alertMessagesType: 'success',
          alertMessagesTitle: '',
          alertMessagesText: [this.formatMessage(messages.successfulFirmwareMessage)]
        })
      })
      .catch(response => {
        const error = { ...response }

        switch (error.response.status) {
          case 501: // Unexpected error
            this.setState({
              firmwareApplying: false,
              firmwareApplyed: true,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, {
                number: '501'
              }),
              alertMessagesText: [error.response.data.message]
            })
            break
          default:
            this.setState({
              firmwareApplying: false,
              firmwareApplyed: true,
              selectedFW: null,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
              alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
            })

            logError(response)
        }
      })
  }

  renderUpdateFirmwareLoadingAndError = () => {
    const { alertMessages, alertMessagesText, alertMessagesTitle, alertMessagesType, firmwareApplying, loading } =
      this.state
    if (loading || firmwareApplying) {
      return (
        <DialogContent>
          <Loading />
        </DialogContent>
      )
    } else if (alertMessages) {
      return (
        <DialogContent style={{ padding: 0 }}>
          <Alert
            alertType={alertMessagesType}
            messageText={alertMessagesText}
            messageTitle={alertMessagesTitle}
            showAlert={alertMessages}
          />
        </DialogContent>
      )
    }
  }

  renderUpdateFirmwareContent = () => {
    const { loading, firmwareApplying, alertMessages } = this.state

    if (loading || firmwareApplying || alertMessages) {
      return this.renderUpdateFirmwareLoadingAndError()
    } else {
      const {
        csNodes: [{ deviceType }]
      } = this.props

      const {
        firmwares,
        firmwares: { beta = [], generic = [], interlink = [], mine = [] },
        selectedFW
      } = this.state

      const numOfCS100Firmwares =
        process.env.REACT_APP_ENV === 'production'
          ? generic.length + mine.length
          : generic.length + mine.length + beta.length
      const displayCS100Firmwares = deviceType === 'CS100' && numOfCS100Firmwares > 0
      const displayCS500Firmwares = deviceType === 'CS500' && interlink.length + mine.length > 0
      const displayFirmwares = displayCS100Firmwares || displayCS500Firmwares

      return (
        <DialogContent>
          {displayFirmwares ? (
            <FormControl style={{ width: '100%' }}>
              <RadioGroup onChange={this.handleSelectionChange}>
                {Object.keys(firmwares).map((folder, index) => {
                  // Hide files in "beta" folder in production. Show in staging and sandbox
                  switch (process.env.REACT_APP_ENV) {
                    case 'staging':
                    case 'sandbox':
                      if (firmwares[folder].length > 0) {
                        return (
                          <div key={folder + '-' + index}>
                            <Divider style={{ marginTop: 15 }} />
                            <Typography style={{ paddingTop: 15 }} variant='h6'>
                              {folder}
                            </Typography>
                            {firmwares[folder].map((firmware, i) => (
                              <FormControlLabel
                                key={firmware + '-' + i}
                                checked={selectedFW && selectedFW.file === folder + '/' + firmware ? true : false}
                                control={
                                  <Radio
                                    classes={{ root: this.classes.radioButton, checked: this.classes.checked }}
                                    color='primary'
                                    style={{ padding: '10px 10px 10px 25px' }}
                                  />
                                }
                                label={firmware}
                                style={{ display: 'flex' }}
                                value={folder + '/' + firmware}
                              />
                            ))}
                          </div>
                        )
                      } else {
                        return []
                      }

                    case 'production':
                      if (firmwares[folder].length > 0 && folder.toString().toLowerCase() !== 'beta') {
                        return (
                          <div key={index}>
                            <Divider style={{ marginTop: 15 }} />
                            <Typography style={{ paddingTop: 15 }} variant='h6'>
                              {folder}
                            </Typography>
                            {firmwares[folder].map((firmware, i) => (
                              <FormControlLabel
                                key={i}
                                checked={selectedFW && selectedFW.file === folder + '/' + firmware ? true : false}
                                control={
                                  <Radio
                                    classes={{ root: this.classes.radioButton, checked: this.classes.checked }}
                                    color='primary'
                                    style={{ padding: '10px 10px 10px 25px' }}
                                  />
                                }
                                label={firmware}
                                style={{ display: 'flex' }}
                                value={folder + '/' + firmware}
                              />
                            ))}
                          </div>
                        )
                      } else {
                        return []
                      }

                    default:
                      return []
                  }
                })}
              </RadioGroup>
            </FormControl>
          ) : 
            !alertMessages && (
              <DialogContentText>{this.formatMessage(messages.thereAreNoAvailableFirmwares)}</DialogContentText>
            )
          }
        </DialogContent>
      )
    }
  }

  render() {
    const {
      action: { description },
      canApplyFirmware,
      classes,
      handleClose,
      csNodes
    } = this.props
    const { firmwareApplyed, firmwareApplying, selectedFW, readMoreReadLessButtonLabel, showMoreDevices } = this.state

    const csNodesLength = csNodes.length

    return (
      <React.Fragment>
        <DialogTitle>
          {description}
          <IconButton
            onClick={handleClose}
            style={{
              position: 'absolute',
              right: 3,
              top: 3,
              padding: 5
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className='alert alert-dismissible animated fadeIn alert-danger'>
            <span className='h5'>
              {this.formatMessage(messages.warningDowngrade)}
              <br />
              {csNodes.map(
                (device, index) =>
                  (csNodesLength <= 2 || index < 2 || showMoreDevices) && (
                    <span key={device.name + '-' + index}>
                      <br />
                      {this.formatMessage(messages.selectedDevice)} {device.name}
                      <br />
                      {this.formatMessage(messages.activeFirmware)} <b>{device.mainFirmwareVersion}</b>
                      <br />
                    </span>
                  )
              )}
              {csNodesLength > 2 && (
                <span>
                  <br />
                  <button
                    onClick={this.handleReadMoreReadLessButtonClick}
                    style={{ border: 'none', padding: 0, background: 'none' }}
                  >
                    <u>{readMoreReadLessButtonLabel}</u>
                  </button>
                </span>
              )}
            </span>
          </div>
          {this.renderUpdateFirmwareContent()}
        </DialogContent>
        <DialogActions>
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            placement='top'
            title={!canApplyFirmware ? this.formatMessage(messages.notAllowedToApplyFirmware) : ''}
          >
            <div style={{ padding: 0, display: 'inline-flex' }}>
              <Button
                className='primary-action-button'
                disabled={
                  selectedFW === null || selectedFW === '' || firmwareApplyed || firmwareApplying || !canApplyFirmware
                }
                onClick={this.handleFirmwareUpdate}
                style={{ display: firmwareApplyed ? 'none' : 'inherit' }}
              >
                {this.formatMessage(messages.apply)}
              </Button>
            </div>
          </Tooltip>
        </DialogActions>
      </React.Fragment>
    )
  }
}

UpdateFirmware.propTypes = {
  action: PropTypes.object.isRequired,
  canApplyFirmware: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  csNodes: PropTypes.array.isRequired,
  getAvailableFOTA: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired
}

export default withStyles(styles)(UpdateFirmware)
