export const VIRTUAL_SIGNAL_MAX = 80

export const SETTINGS_FIELDS = {
  baudRate0: 'baudRate0',
  baudRate1: 'baudRate1',
  // cellularPriority: 'cellularPriority',
  fotaUpdateEnabled: 'fotaUpdateEnabled',
  interlinkEnabled: 'interlinkEnabled',
  loggingUploadPeriod0: 'loggingUploadPeriod0',
  loggingUploadPeriod1: 'loggingUploadPeriod1',
  nodeId0: 'nodeId0',
  nodeId1: 'nodeId1',
  restartCellularModule: 'restartCellularModule',
  sa0: 'sa0',
  sa1: 'sa1'
}

export const GNSS_GEOFENCE_FIELDS = {
  altitudeLogTime: 'altitudeLogTime',
  geofenceEnabled: 'geofenceEnabled',
  geofenceLatitude: 'geofenceLatitude',
  geofenceLogToPortal: 'geofenceLogToPortal',
  geofenceLongitude: 'geofenceLongitude',
  geofenceRadius: 'geofenceRadius',
  geofenceSamplePeriod: 'geofenceSamplePeriod',
  geofenceSendImmediately: 'geofenceSendImmediately',
  gnssAltitudeEnabled: 'gnssAltitudeEnabled',
  gnssHeadingEnabled: 'gnssHeadingEnabled',
  gnssPositionEnabled: 'gnssPositionEnabled',
  gnssSpeedEnabled: 'gnssSpeedEnabled',
  headingLogTime: 'headingLogTime',
  positionLogTime: 'positionLogTime',
  speedLogTime: 'speedLogTime'
}

export const MFIO_FIELDS = {
  bias0: 'bias0',
  bias1: 'bias1',
  bias2: 'bias2',
  bias3: 'bias3',
  digThreshHigh0: 'digThreshHigh0',
  digThreshHigh1: 'digThreshHigh1',
  digThreshHigh2: 'digThreshHigh2',
  digThreshHigh3: 'digThreshHigh3',
  digThreshLow0: 'digThreshLow0',
  digThreshLow1: 'digThreshLow1',
  digThreshLow2: 'digThreshLow2',
  digThreshLow3: 'digThreshLow3',
  inputRange0: 'inputRange0',
  inputRange1: 'inputRange1',
  inputRange2: 'inputRange2',
  inputRange3: 'inputRange3',
  inputSignal0: 'inputSignal0',
  inputSignal1: 'inputSignal1',
  inputSignal2: 'inputSignal2',
  inputSignal3: 'inputSignal3'
}

export const DM1_FIELDS = {
  dm1Expanded: 'dm1Expanded',
  dm1LogToPortal: 'dm1LogToPortal',
  dm1Port: 'dm1Port',
  dm1SA: 'dm1SA',
  dm1UseSA: 'dm1UseSA'
}

export const MACHINE_STATE_DETERMINATION_FIELDS = {
  byte0: 'byte0',
  byte1: 'byte1',
  byte2: 'byte2',
  byte3: 'byte3',
  byte4: 'byte4',
  byte5: 'byte5',
  byte6: 'byte6',
  byte7: 'byte7',
  canId: 'canId',
  canPort: 'canPort',
  digitalInputPin: 'digitalInputPin',
  enableCan: 'enableCan',
  enableDigitalInput: 'enableDigitalInput',
  enableServiceToolButton: 'enableServiceToolButton',
  forceFullAccessMode: 'forceFullAccessMode',
  machineStateDeterminationExpanded: 'machineStateDeterminationExpanded'
}

export const SEND_CAN_MESSAGES_FIELDS = {
  byte0: 'byte0',
  byte1: 'byte1',
  byte2: 'byte2',
  byte3: 'byte3',
  byte4: 'byte4',
  byte5: 'byte5',
  byte6: 'byte6',
  byte7: 'byte7',
  canId: 'canId',
  canPort: 'canPort',
  dataSource: 'dataSource',
  expanded: 'expanded',
  isExtendedCanId: 'isExtendedCanId',
  lowerThreshold: 'lowerThreshold',
  periodType: 'periodType',
  periodTypeTriggered: 'periodTypeTriggered',
  thresholdDivider: 'thresholdDivider',
  sendPeriod: 'sendPeriod',
  sendPeriodTriggered: 'sendPeriodTriggered',
  triggerSource: 'triggerSource',
  triggerType: 'triggerType',
  upperThreshold: 'upperThreshold',
  virtualSignalNumber: 'virtualSignalNumber'
}

export const SIGNAL_FIELDS = {
  cch: 'cch',
  cornerFrequency: 'cornerFrequency',
  customUnit: 'customUnit',
  divider: 'divider',
  expanded: 'expanded',
  filterType: 'filterType',
  gain: 'gain',
  isExtendedCanId: 'isExtendedCanId',
  lengthOfBits: 'lengthOfBits',
  logToPortal: 'logToPortal',
  logType: 'logType',
  multiplier: 'multiplier',
  name: 'name',
  offset: 'offset',
  piu: 'piu',
  quotient: 'quotient',
  samplePeriod: 'samplePeriod',
  samplePeriodTriggered: 'samplePeriodTriggered',
  sendImmediately: 'sendImmediately',
  signalId: 'signalId',
  signalSource: 'signalSource',
  sml: 'sml',
  ss: 'ss',
  triggerLogType: 'triggerLogType',
  triggerLowerThreshold: 'triggerLowerThreshold',
  triggerSendImmediately: 'triggerSendImmediately',
  triggerSource: 'triggerSource',
  triggerThresholdDivider: 'triggerThresholdDivider',
  triggerType: 'triggerType',
  triggerUpperThreshold: 'triggerUpperThreshold',
  unit: 'unit',
  unitType: 'unitType'
}
