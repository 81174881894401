import { defineMessages } from 'react-intl'

const namespace = 'Maintenances'

export default defineMessages({
  actions: {
    id: `${namespace}.actions`,
    defaultMessage: 'Actions'
  },
  active: {
    id: `${namespace}.active`,
    defaultMessage: 'Active'
  },
  deviceConfiguration: {
    id: `${namespace}.deviceConfiguration`,
    defaultMessage: 'Device configuration'
  },
  deviceConfigurationVersion: {
    id: `${namespace}.deviceConfigurationVersion`,
    defaultMessage: 'Device configuration version'
  },
  advancedSignal: {
    id: `${namespace}.advancedSignal`,
    defaultMessage: 'Advanced signal'
  },
  allMachinesMustBeCompatible: {
    id: `${namespace}.allMachinesMustBeCompatible`,
    defaultMessage: 'All the assigned machines must be compatible with the currently configured advanced signal.'
  },
  assignMachines: {
    id: `${namespace}.assignMachines`,
    defaultMessage: 'Assign machines'
  },
  assignedMachines: {
    id: `${namespace}.assignedMachines`,
    defaultMessage: 'Assigned machines'
  },
  assignedUsers: {
    id: `${namespace}.assignedUsers`,
    defaultMessage: 'Assigned users'
  },
  assignedUsersDetail: {
    id: `${namespace}.assignedUsersDetail`,
    defaultMessage: 'Assigned users detail'
  },
  assignUsers: {
    id: `${namespace}.assignUsers`,
    defaultMessage: 'Assign users'
  },
  automaticInspectionScheduling: {
    id: `${namespace}.automaticInspectionScheduling`,
    defaultMessage: 'Automatic inspection scheduling'
  },
  available: {
    id: `${namespace}.available`,
    defaultMessage: 'Available'
  },
  availableAdvancedSignals: {
    id: `${namespace}.availableAdvancedSignals`,
    defaultMessage: 'Available advanced signals'
  },
  back: {
    id: `${namespace}.back`,
    defaultMessage: 'Back'
  },
  bellNotifications: {
    id: `${namespace}.bellNotifications`,
    defaultMessage: 'Bell notifications'
  },
  cancel: {
    id: `${namespace}.cancel`,
    defaultMessage: 'Cancel'
  },
  checked: {
    id: `${namespace}.checked`,
    defaultMessage: 'Checked'
  },
  clearSelection: {
    id: `${namespace}.clearSelection`,
    defaultMessage: 'Clear selection'
  },
  compatibleMachinesClarification: {
    id: `${namespace}.compatibleMachinesClarification`,
    defaultMessage: 'For multiple machines to be compatible, they must share at least one advanced signal.'
  },
  configuredAdvancedSignal: {
    id: `${namespace}.configuredAdvancedSignal`,
    defaultMessage: 'Configured advanced signal'
  },
  currentValue: {
    id: `${namespace}.currentValue`,
    defaultMessage: 'Current value'
  },
  currentValues: {
    id: `${namespace}.currentValues`,
    defaultMessage: 'Current values'
  },
  currentValueAssignedMachines: {
    id: `${namespace}.currentValueAssignedMachines`,
    defaultMessage: 'Current value for all the assigned machines'
  },
  days: {
    id: `${namespace}.days`,
    defaultMessage: 'Days'
  },
  delete: {
    id: `${namespace}.delete`,
    defaultMessage: 'Delete'
  },
  deleteMaintenance: {
    id: `${namespace}.deleteMaintenance`,
    defaultMessage: 'Delete a maintenance'
  },
  deleteMaintenanceConfirmation: {
    id: `${namespace}.deleteMaintenanceConfirmation`,
    defaultMessage:
      'Are you sure you want to delete <b>{maintenanceName}</b>? {br} The inspection history will be deleted.'
  },
  description: {
    id: `${namespace}.description`,
    defaultMessage: 'Description'
  },
  details: {
    id: `${namespace}.details`,
    defaultMessage: 'Details'
  },
  deviceName: {
    id: `${namespace}.deviceName`,
    defaultMessage: 'Device name'
  },
  machines: {
    id: `${namespace}.machines`,
    defaultMessage: 'Machines'
  },
  machinesDontShareAdvancedSignal: {
    id: `${namespace}.machinesDontShareAdvancedSignal`,
    defaultMessage: 'The selected machines don\'t share any advanced signal.'
  },
  machinesDontShareConfiguredAdvancedSignal: {
    id: `${namespace}.machinesDontShareConfiguredAdvancedSignal`,
    defaultMessage: 'Some of the selected machines are not compatible with the currently configured advanced signal'
  },
  machinesHaveGreaterValue: {
    id: `${namespace}.machinesHaveGreaterValue`,
    defaultMessage:
      'One or more machines have a greater value for the selected advanced signal than the initial value. \
      The next compatible inspection will be scheduled instead.'
  },
  machineSettings: {
    id: `${namespace}.machineSettings`,
    defaultMessage: 'Machine settings'
  },
  deviceType: {
    id: `${namespace}.deviceType`,
    defaultMessage: 'Device type'
  },
  disabled: {
    id: `${namespace}.disabled`,
    defaultMessage: 'Disabled'
  },
  disableBellNotifications: {
    id: `${namespace}.disableBellNotifications`,
    defaultMessage: 'Disable bell notifications'
  },
  disableEmailNotifications: {
    id: `${namespace}.disableEmailNotifications`,
    defaultMessage: 'Disable email notifications'
  },
  disableSmsNotifications: {
    id: `${namespace}.disableSmsNotifications`,
    defaultMessage: 'Disable SMS notifications'
  },
  disabledMaintenance: {
    id: `${namespace}.disabledMaintenance`,
    defaultMessage: 'When disabled, no notification is generated.'
  },
  edit: {
    id: `${namespace}.edit`,
    defaultMessage: 'Edit'
  },
  editMaintenance: {
    id: `${namespace}.editMaintenance`,
    defaultMessage: 'Edit maintenance'
  },
  eid: {
    id: `${namespace}.eid`,
    defaultMessage: 'EID'
  },
  email: {
    id: `${namespace}.email`,
    defaultMessage: 'Email'
  },
  emailNotifications: {
    id: `${namespace}.emailNotifications`,
    defaultMessage: 'Email notifications'
  },
  enabled: {
    id: `${namespace}.enabled`,
    defaultMessage: 'Enabled'
  },
  enableBellNotifications: {
    id: `${namespace}.enableBellNotifications`,
    defaultMessage: 'Enable bell notifications'
  },
  enableEmailNotifications: {
    id: `${namespace}.enableEmailNotifications`,
    defaultMessage: 'Enable email notifications'
  },
  enableSmsNotifications: {
    id: `${namespace}.enableSmsNotifications`,
    defaultMessage: 'Enable SMS notifications'
  },
  equivalentAdvancedSignalsClarification: {
    id: `${namespace}.equivalentAdvancedSignalsClarification`,
    defaultMessage:
      'Two advanced signals are considered to be equivalent if their configuration parameters are exactly the same.'
  },
  exit: {
    id: `${namespace}.exit`,
    defaultMessage: 'Exit'
  },
  exportToCsv: {
    id: `${namespace}.exportToCsv`,
    defaultMessage: 'Export to CSV'
  },
  filterBy: {
    id: `${namespace}.filterBy`,
    defaultMessage: 'Filter by'
  },
  firstPage: {
    id: `${namespace}.firstPage`,
    defaultMessage: 'First'
  },
  formattedInitialDate: {
    id: `${namespace}.formattedInitialDate`,
    defaultMessage: ' on {hasWeekdayPrefix, select, \
      true {the {initialDate}}\
      false {{initialDate}}\
    }'
  },
  frequency: {
    id: `${namespace}.frequency`,
    defaultMessage: 'Frequency'
  },
  frequencyClarificationTimeBased: {
    id: `${namespace}.frequencyClarificationTimeBased`,
    defaultMessage:
      'Inspections will be scheduled <b>every {frequencyValue, plural, \
        one {{formattedTimeUnit}}\
        other {# {formattedTimeUnit}}\
      }</b>{hasDatePrecision, select, \
      true {{formattedInitialDate}}\
      false {}\
    }.'
  },
  frequencyClarificationValueBased: {
    id: `${namespace}.frequencyClarificationValueBased`,
    defaultMessage:
      'Inspections will be scheduled <b>every {frequencyValue} {formattedUnit}</b>, \
      starting at {initialValue} {formattedUnit}.'
  },
  frequencyParameters: {
    id: `${namespace}.frequencyParameters`,
    defaultMessage: 'Frequency parameters'
  },
  frequencyType: {
    id: `${namespace}.frequencyType`,
    defaultMessage: 'Frequency type'
  },
  general: {
    id: `${namespace}.general`,
    defaultMessage: 'General'
  },
  generalSettings: {
    id: `${namespace}.generalSettings`,
    defaultMessage: 'General settings'
  },
  group: {
    id: `${namespace}.group`,
    defaultMessage: 'Group'
  },
  high: {
    id: `${namespace}.high`,
    defaultMessage: 'High'
  },
  incompatibleMachines: {
    id: `${namespace}.incompatibleMachines`,
    defaultMessage: 'Incompatible machines'
  },
  initialDate: {
    id: `${namespace}.initialDate`,
    defaultMessage: 'Initial date'
  },
  initialValue: {
    id: `${namespace}.initialValue`,
    defaultMessage: 'Initial value'
  },
  inspectedBy: {
    id: `${namespace}.inspectedBy`,
    defaultMessage: 'Inspected by'
  },
  inspectionDate: {
    id: `${namespace}.inspectionDate`,
    defaultMessage: 'Inspection date'
  },
  inspectionDetail: {
    id: `${namespace}.inspectionDetail`,
    defaultMessage: 'Inspection detail'
  },
  inspectionHistory: {
    id: `${namespace}.inspectionHistory`,
    defaultMessage: 'Inspection history'
  },
  inspections: {
    id: `${namespace}.inspections`,
    defaultMessage: 'Inspections'
  },
  inspectionSuccessfullyResolved: {
    id: `${namespace}.inspectionSuccessfullyResolved`,
    defaultMessage: 'The inspection was successfully resolved.'
  },
  inspectionValue: {
    id: `${namespace}.inspectionValue`,
    defaultMessage: 'Inspection value'
  },
  lastInspection: {
    id: `${namespace}.lastInspection`,
    defaultMessage: 'Last inspection'
  },
  lastName: {
    id: `${namespace}.lastName`,
    defaultMessage: 'Last name'
  },
  lastPage: {
    id: `${namespace}.lastPage`,
    defaultMessage: 'Last'
  },
  low: {
    id: `${namespace}.low`,
    defaultMessage: 'Low'
  },
  maintenanceDetail: {
    id: `${namespace}.maintenanceDetail`,
    defaultMessage: 'Maintenance detail'
  },
  maintenanceName: {
    id: `${namespace}.maintenanceName`,
    defaultMessage: 'Maintenance name'
  },
  maintenances: {
    id: `${namespace}.maintenances`,
    defaultMessage: 'Maintenances'
  },
  maintenanceSuccesfullySaved: {
    id: `${namespace}.maintenanceSuccesfullySaved`,
    defaultMessage: 'The maintenance was successfully saved.'
  },
  medium: {
    id: `${namespace}.medium`,
    defaultMessage: 'Medium'
  },
  months: {
    id: `${namespace}.months`,
    defaultMessage: 'Months'
  },
  name: {
    id: `${namespace}.name`,
    defaultMessage: 'Name'
  },
  nDays: {
    id: `${namespace}.nDays`,
    defaultMessage: '{days, plural, one {day} other {days}}'
  },
  newMaintenance: {
    id: `${namespace}.newMaintenance`,
    defaultMessage: 'New maintenance'
  },
  next: {
    id: `${namespace}.next`,
    defaultMessage: 'Next'
  },
  nextInspection: {
    id: `${namespace}.nextInspection`,
    defaultMessage: 'Next inspection'
  },
  nextInspectionClarification: {
    id: `${namespace}.nextInspectionClarification`,
    defaultMessage:
      'The next inspection is calculated based on the inspection {frequencyType, select, \
    TIME_BASED {date} \
    other {value} \
    } and the frequency.'
  },
  nextPage: {
    id: `${namespace}.nextPage`,
    defaultMessage: 'Next'
  },
  nMonths: {
    id: `${namespace}.nMonths`,
    defaultMessage: '{months, plural, one {month} other {months}}'
  },
  noDataToDisplay: {
    id: `${namespace}.noDataToDisplay`,
    defaultMessage: 'There is no data to display.'
  },
  noMachineSelected: {
    id: `${namespace}.noMachineSelected`,
    defaultMessage: 'No machine selected'
  },
  noInspectionAvailable: {
    id: `${namespace}.noInspectionAvailable`,
    defaultMessage: 'No inspection available'
  },
  notAvailable: {
    id: `${namespace}.notAvailable`,
    defaultMessage: 'Not available'
  },
  notes: {
    id: `${namespace}.notes`,
    defaultMessage: 'Notes'
  },
  notifications: {
    id: `${namespace}.notifications`,
    defaultMessage: 'Notifications'
  },
  notificationSettings: {
    id: `${namespace}.notificationSettings`,
    defaultMessage: 'Notification settings'
  },
  notificationSeverity: {
    id: `${namespace}.notificationSeverity`,
    defaultMessage: 'Notification severity'
  },
  numberOfMachines: {
    id: `${namespace}.numberOfMachines`,
    defaultMessage: 'Number of machines'
  },
  numberOfUsers: {
    id: `${namespace}.numberOfUsers`,
    defaultMessage: 'Number of users'
  },
  nUsers: {
    id: `${namespace}.nUsers`,
    defaultMessage: '{users, plural, one {user} other {users}}'
  },
  nWeeks: {
    id: `${namespace}.nWeeks`,
    defaultMessage: '{weeks, plural, one {week} other {weeks}}'
  },
  nYears: {
    id: `${namespace}.nYears`,
    defaultMessage: '{years, plural, one {year} other {years}}'
  },
  of: {
    id: `${namespace}.of`,
    defaultMessage: 'of'
  },
  ok: {
    id: `${namespace}.ok`,
    defaultMessage: 'Ok'
  },
  pending: {
    id: `${namespace}.pending`,
    defaultMessage: 'Pending'
  },
  pendingInspections: {
    id: `${namespace}.pendingInspections`,
    defaultMessage: 'Pending inspections'
  },
  prePage: {
    id: `${namespace}.prePage`,
    defaultMessage: 'Prev'
  },
  rescheduleActiveInspections: {
    id: `${namespace}.rescheduleActiveInspections`,
    defaultMessage: 'Reschedule active inspections'
  },
  rescheduleActiveInspectionsClarification: {
    id: `${namespace}.rescheduleActiveInspectionsClarification`,
    defaultMessage: 'If checked, the inspections in "Active" state will be rescheduled according to the new frequency.'
  },
  resolve: {
    id: `${namespace}.resolve`,
    defaultMessage: 'Resolve'
  },
  resolved: {
    id: `${namespace}.resolved`,
    defaultMessage: 'Resolved'
  },
  resolveInspection: {
    id: `${namespace}.resolveInspection`,
    defaultMessage: 'Resolve inspection'
  },
  resolveInspectionNextValueError: {
    id: `${namespace}.resolveInspectionNextValueError`,
    defaultMessage: 'Next inspection value cannot be smaller that current value.'
  },
  resolveInspectionValueError: {
    id: `${namespace}.resolveInspectionValueError`,
    defaultMessage: 'Inspection value cannot be bigger than current value.'
  },
  save: {
    id: `${namespace}.save`,
    defaultMessage: 'Save'
  },
  scheduledInspection: {
    id: `${namespace}.scheduledInspection`,
    defaultMessage: 'Scheduled inspection'
  },
  seeLess: {
    id: `${namespace}.seeLess`,
    defaultMessage: 'See less'
  },
  seeMore: {
    id: `${namespace}.seeMore`,
    defaultMessage: 'See {number} more'
  },
  selectAtLeastOneMachine: {
    id: `${namespace}.selectAtLeastOneMachine`,
    defaultMessage: 'Please select at least one machine.'
  },
  selected: {
    id: `${namespace}.selected`,
    defaultMessage: 'Selected'
  },
  serverError: {
    id: `${namespace}.serverError`,
    defaultMessage: 'Server error'
  },
  serverErrorClarification: {
    id: `${namespace}.serverErrorClarification`,
    defaultMessage:
      'Please try again or refresh the page. \
      If the error persists, contact <a>Plus+1 Connect Support</a>.'
  },
  severity: {
    id: `${namespace}.severity`,
    defaultMessage: 'Severity'
  },
  severityHighClarification: {
    id: `${namespace}.severityHighClarification`,
    defaultMessage: 'The bell will show a warning in red and the node will appear in red on the map.'
  },
  severityMediumClarification: {
    id: `${namespace}.severityMediumClarification`,
    defaultMessage: 'The bell will show a warning in orange and the node will appear in orange on the map.'
  },
  severityLowClarification: {
    id: `${namespace}.severityLowClarification`,
    defaultMessage: 'The bell will show a warning in blue and the node will appear in blue on the map.'
  },
  show: {
    id: `${namespace}.show`,
    defaultMessage: 'Show'
  },
  showingRows: {
    id: `${namespace}.showingRows`,
    defaultMessage: 'Showing rows {start} to {end} of {total}'
  },
  signal: {
    id: `${namespace}.signal`,
    defaultMessage: 'Signal'
  },
  signalSettings: {
    id: `${namespace}.signalSettings`,
    defaultMessage: 'Signal settings'
  },
  smsNotifications: {
    id: `${namespace}.smsNotifications`,
    defaultMessage: 'SMS notifications'
  },
  smsService: {
    id: `${namespace}.smsService`,
    defaultMessage: 'SMS service'
  },
  state: {
    id: `${namespace}.state`,
    defaultMessage: 'State'
  },
  success: {
    id: `${namespace}.success`,
    defaultMessage: 'Success'
  },
  summary: {
    id: `${namespace}.summary`,
    defaultMessage: 'Summary'
  },
  timeBased: {
    id: `${namespace}.timeBased`,
    defaultMessage: 'Time-based'
  },
  to: {
    id: `${namespace}.to`,
    defaultMessage: 'to'
  },
  unassign: {
    id: `${namespace}.unassign`,
    defaultMessage: 'Unassign'
  },
  value: {
    id: `${namespace}.value`,
    defaultMessage: 'Value'
  },
  valueBased: {
    id: `${namespace}.valueBased`,
    defaultMessage: 'Value-based'
  },
  weeks: {
    id: `${namespace}.weeks`,
    defaultMessage: 'Weeks'
  },
  years: {
    id: `${namespace}.years`,
    defaultMessage: 'Years'
  },
  selectColumns: {
    id: `${namespace}.selectColumns`,
    defaultMessage: 'Select columns'
  },
  machineModel: {
    id: `${namespace}.machineModel`,
    defaultMessage: 'Model'
  },
  machineType: {
    id: `${namespace}.machineType`,
    defaultMessage: 'Machine type'
  },
  machineBrand: {
    id: `${namespace}.machineBrand`,
    defaultMessage: 'Brand'
  },
  machineSerialNumber: {
    id: `${namespace}.machineSerialNumber`,
    defaultMessage: 'Machine serial number'
  },
  lastActivity: {
    id: `${namespace}.lastActivity`,
    defaultMessage: 'Last activity'
  },
  firmwareVersion: {
    id: `${namespace}.firmwareVersion`,
    defaultMessage: 'Firmware version'
  },
  showHideColumns: {
    id: `${namespace}.showHideColumns`,
    defaultMessage: 'Show/hide columns'
  }
})
