import * as yup from 'yup'
import translations from 'i18n/locales'
import { numberTransform } from 'utils/yupHelpers'

const isHex = str => {
  return /^0x[A-Fa-f0-9]/g.test(str)
}

export const validationSchema = () => {
  const i18n = translations[localStorage.getItem('user_language')]
  return yup.object().shape({
    modelName: yup.string().required(i18n['Configs.modelNameIsRequired']).nullable(),
    nodeId: yup
      .string()
      .required(i18n['Configs.nodeIdIsRequired'])
      .nullable()
      .test('isValid', i18n['Configs.invalidNodeId'], function (value) {
        const invalid = !isHex(value) || value < 0 || value > 255
        return !invalid
      }),
    baudRate1: yup
      .number()
      .transform(numberTransform)
      .required(i18n['Configs.baudRate1IsRequired'])
      .nullable()
      .test('isValid', i18n['Configs.invalidBaudRate1'], function (value) {
        const invalid =
          value !== 50000 &&
          value !== 100000 &&
          value !== 125000 &&
          value !== 250000 &&
          value !== 500000 &&
          value !== 1000000
        return !invalid
      }),
    baudRate2: yup
      .number()
      .transform(numberTransform)
      .required(i18n['Configs.baudRate2IsRequired'])
      .nullable()
      .test('isValid', i18n['Configs.invalidBaudRate2'], function (value) {
        const invalid =
          value !== 50000 &&
          value !== 100000 &&
          value !== 125000 &&
          value !== 250000 &&
          value !== 500000 &&
          value !== 1000000
        return !invalid
      }),
    dataLoggingEnable: yup.boolean().required(i18n['Configs.dataLoggingEnableIsRequired']).nullable(),
    loggingUploadPeriod: yup
      .number()
      .transform(numberTransform)
      .required(i18n['Configs.loggingUploadPeriodIsRequired'])
      .nullable()
      .test('isValid', i18n['Configs.invalidLoggingUploadPeriod'], function (value) {
        const loggingBufferThreshold = this.parent.loggingBufferThreshold

        const loggingUploadPeriodIsInvalid = value < 0 || value > 0 && value < 60 || value > 65535
        const theyAreInvalid = loggingBufferThreshold === 0 && value === 0

        const invalid = loggingUploadPeriodIsInvalid || theyAreInvalid
        return !invalid
      }),
    loggingBufferThreshold: yup
      .number()
      .transform(numberTransform)
      .required(i18n['Configs.loggingBufferThresholdIsRequired'])
      .nullable()
      .test('isValid', i18n['Configs.invalidLoggingBufferThreshold'], function (value) {
        const loggingUploadPeriod = this.parent.loggingUploadPeriod

        const loggingBufferThresholdIsInvalid = value < 0 || value > 65535
        const theyAreInvalid = loggingUploadPeriod === 0 && value === 0

        const invalid = loggingBufferThresholdIsInvalid || theyAreInvalid
        return !invalid
      }),
    gpsEnabled: yup.boolean().required(i18n['Configs.enabledIsRequired']).nullable(),
    gpsAltitude: yup.boolean().required(i18n['Configs.gpsAltitudeIsRequired']).nullable(),
    gpsHeading: yup.boolean().required(i18n['Configs.gpsHeadingIsRequired']).nullable(),
    gpsSpeed: yup.boolean().required(i18n['Configs.gpsSpeedIsRequired']).nullable(),
    gpsFrequency: yup
      .number()
      .transform(numberTransform)
      .test('isValid', i18n['Configs.invalidGpsFrequency'], function (value) {
        const gpsConditionsEnabled = this.parent.gpsEnabled
        const frequencyIsInvalid =
          value === null || value === undefined || value < 0 || value > 0 && value < 60 || value > 4294967295 // unsigned long int

        const invalid = gpsConditionsEnabled && frequencyIsInvalid
        return !invalid
      }),
    powerManagementEnable: yup
      .number()
      .transform(numberTransform)
      .required(i18n['Configs.powerManagementEnableIsRequired'])
      .nullable()
      .test('isValid', i18n['Configs.invalidPowerManagementEnable'], function (value) {
        const invalid = value !== 0 && value !== 1 && value !== 3
        return !invalid
      }),
    wakeUpFlagEnable: yup.boolean().required(i18n['Configs.wakeUpFlagEnableIsRequired']).nullable(),
    shutdownDelay: yup
      .number()
      .transform(numberTransform)
      .required(i18n['Configs.shutdownDelayIsRequired'])
      .nullable()
      .test('isValid', i18n['Configs.invalidShutdownDelay'], function (value) {
        const invalid = value < 120 || value > 65535 // unsigned short int
        return !invalid
      }),
    cycleTimeDelay: yup
      .number()
      .transform(numberTransform)
      .required(i18n['Configs.cycleTimeDelayIsRequired'])
      .nullable()
      .test('isValid', i18n['Configs.invalidCycleTimeDelay'], function (value) {
        const invalid = value < 0 || value > 3600 // 1 month
        return !invalid
      }),
    MFIO: yup.array().of(
      yup.object().shape({
        signalId: yup
          .number()
          .transform(numberTransform)
          .required(i18n['Configs.mfioSignalIdIsRequired'])
          .nullable()
          .test('isValid', i18n['Configs.invalidMfioSignalId'], function (value) {
            const invalid = value !== 0 && value !== 1
            return !invalid
          }),
        pinFunction: yup
          .number()
          .transform(numberTransform)
          .required(i18n['Configs.pinFunctionIsRequired'])
          .nullable()
          .test('isValid', i18n['Configs.invalidPinFunction'], function (value) {
            const invalid = value < 0 || value > 3
            return !invalid
          }),
        name: yup.string().test('isRequired', i18n['Configs.mfioNameIsRequired'], function (value) {
          const pinFunction = this.parent.pinFunction
          const invalidValue = value === null || value === undefined || value === ''

          const invalid = pinFunction !== 0 && invalidValue
          return !invalid
        }),
        defaultPowerUp: yup.boolean().test('isRequired', i18n['Configs.defaultPowerUpIsRequired'], function (value) {
          const pinFunction = this.parent.pinFunction
          const invalidValue = typeof value !== 'boolean'

          const invalid = pinFunction === 1 && invalidValue
          return !invalid
        }),
        logCycle: yup
          .number()
          .transform(numberTransform)
          .test('isValid', i18n['Configs.invalidLogCycle'], function (value) {
            const pinFunction = this.parent.pinFunction
            const invalidValue =
              value === null || value === undefined || value < 0 || value > 0 && value < 10 || value > 65535

            const invalid = pinFunction === 2 && invalidValue
            return !invalid
          }),
        lastValue: yup
          .number()
          .transform(numberTransform)
          .test('isValid', i18n['Configs.invalidLastValue'], function (value) {
            const pinFunction = this.parent.pinFunction
            const invalidValue = value === null || value === undefined || value !== 0 && value !== 1

            const invalid = pinFunction === 2 && invalidValue
            return !invalid
          }),
        averageValue: yup
          .number()
          .transform(numberTransform)
          .test('isValid', i18n['Configs.invalidAverageValue'], function (value) {
            const pinFunction = this.parent.pinFunction
            const invalidValue = value === null || value === undefined || value !== 0 && value !== 1

            const invalid = pinFunction === 2 && invalidValue
            return !invalid
          }),
        minValue: yup
          .number()
          .transform(numberTransform)
          .test('isValid', i18n['Configs.invalidMinValue'], function (value) {
            const pinFunction = this.parent.pinFunction
            const invalidValue = value === null || value === undefined || value !== 0 && value !== 1

            const invalid = pinFunction === 2 && invalidValue
            return !invalid
          }),
        maxValue: yup
          .number()
          .transform(numberTransform)
          .test('isValid', i18n['Configs.invalidMaxValue'], function (value) {
            const pinFunction = this.parent.pinFunction
            const invalidValue = value === null || value === undefined || value !== 0 && value !== 1

            const invalid = pinFunction === 2 && invalidValue
            return !invalid
          }),
        inputSignal: yup.string().test('isRequired', i18n['Configs.inputSignalIsRequired'], function (value) {
          const pinFunction = this.parent.pinFunction
          const invalidValue =
            value !== 'Voltage' &&
            value !== 'Digital Input' &&
            value !== 'Frequency' &&
            value !== 'Resistance' &&
            value !== 'Current'

          const invalid = pinFunction === 2 && invalidValue
          return !invalid
        }),
        inputRange: yup
          .number()
          .transform(numberTransform)
          .test('isRequired', i18n['Configs.inputRangeIsRequired'], function (value) {
            const pinFunction = this.parent.pinFunction
            const inputSignal = this.parent.inputSignal
            const invalidValue = value !== 0 && value !== 1

            const invalid =
              pinFunction === 2 &&
              (inputSignal === 'Voltage' || inputSignal === 'Digital Input' || inputSignal === 'Frequency') &&
              invalidValue
            return !invalid
          }),
        bias: yup
          .number()
          .transform(numberTransform)
          .test('isValid', i18n['Configs.invalidBias'], function (value) {
            const pinFunction = this.parent.pinFunction
            const inputSignal = this.parent.inputSignal
            const invalidValue = value === null || value === undefined || value < 0 || value > 3

            const invalid =
              pinFunction === 2 &&
              (inputSignal === 'Voltage' || inputSignal === 'Digital Input' || inputSignal === 'Frequency') &&
              invalidValue
            return !invalid
          }),
        digThreshHigh: yup
          .number()
          .transform(numberTransform)
          .test('isValid', i18n['Configs.invalidDigThreshHigh'], function (value) {
            const pinFunction = this.parent.pinFunction
            const inputSignal = this.parent.inputSignal
            const digThreshLow = this.parent.digThreshLow
            const invalidValue =
              value === null || value === undefined || value < 0 || value > 65535 || value < digThreshLow

            const invalid = pinFunction === 2 && inputSignal === 'Digital Input' && invalidValue
            return !invalid
          }),
        digThreshLow: yup
          .number()
          .transform(numberTransform)
          .test('isValid', i18n['Configs.invalidDigThreshLow'], function (value) {
            const pinFunction = this.parent.pinFunction
            const inputSignal = this.parent.inputSignal
            const digThreshHigh = this.parent.digThreshHigh
            const invalidValue =
              value === null || value === undefined || value < 0 || value > 65535 || value > digThreshHigh

            const invalid = pinFunction === 2 && inputSignal === 'Digital Input' && invalidValue
            return !invalid
          }),
        decimals: yup
          .number()
          .transform(numberTransform)
          .test('isValid', i18n['Configs.invalidDecimals'], function (value) {
            const pinFunction = this.parent.pinFunction
            const invalidValue = value === null || value === undefined || value < 0

            const invalid = pinFunction === 2 && invalidValue
            return !invalid
          }),
        multiplier: yup
          .number()
          .transform(numberTransform)
          .test('isValid', i18n['Configs.invalidMultiplier'], function (value) {
            const pinFunction = this.parent.pinFunction
            const invalidValue = value === null || value === undefined || value === 0

            const invalid = pinFunction === 2 && invalidValue
            return !invalid
          }),
        offset: yup
          .number()
          .transform(numberTransform)
          .test('isRequired', i18n['Configs.offsetIsRequired'], function (value) {
            const pinFunction = this.parent.pinFunction
            const invalidValue = value === null || value === undefined

            const invalid = pinFunction === 2 && invalidValue
            return !invalid
          }),
        unit: yup.string()
      })
    ),
    signals: yup.array().of(
      yup.object().shape({
        signalId: yup
          .number()
          .transform(numberTransform)
          .required(i18n['Configs.signalIdIsRequired'])
          .nullable()
          .test('isValid', i18n['Configs.invalidSignalId'], function (value) {
            const editableId = this.parent.editableId

            const invalid = value === null || value < 2 || value > 65535 || editableId === true
            return !invalid
          }),
        name: yup.string().required(i18n['Configs.signalNameIsRequired']).nullable(),
        address: yup
          .string()
          .required(i18n['Configs.addressIsRequired'])
          .nullable()
          .test('isValid', i18n['Configs.invalidAddress'], function (value) {
            const invalid = value !== '11' && value !== '29'
            return !invalid
          }),
        canId: yup
          .string()
          .required(i18n['Configs.canIdIsRequired'])
          .nullable()
          .test('isValidCanId', function (value) {
            const address = this.parent.address

            if (address === '11') {
              if (isHex(value) && value <= 0x7ff) {
                return true
              } else {
                return this.createError({ message: i18n['Configs.invalid11BitCanId'], path: this.path })
              }
            } else if (address === '29') {
              if (isHex(value) && value <= 0x1fffffff) {
                return true
              } else {
                return this.createError({ message: i18n['Configs.invalid29BitCanId'], path: this.path })
              }
            } else {
              return this.createError({ message: i18n['Configs.canIdIsRequired'], path: this.path })
            }
          }),
        idMask: yup
          .string()
          .required(i18n['Configs.idMaskIsRequired'])
          .nullable()
          .test('isValidIdMask', function (value) {
            const address = this.parent.address

            if (address === '11') {
              if (isHex(value) && value <= 0x7ff) {
                return true
              } else {
                return this.createError({ message: i18n['Configs.invalid11BitIdMask'], path: this.path })
              }
            } else if (address === '29') {
              if (isHex(value) && value <= 0x3fffffff) {
                return true
              } else {
                return this.createError({ message: i18n['Configs.invalid29BitIdMask'], path: this.path })
              }
            } else {
              return this.createError({ message: i18n['Configs.idMaskIsRequired'], path: this.path })
            }
          }),
        canPort: yup
          .number()
          .transform(numberTransform)
          .required(i18n['Configs.canPortIsRequired'])
          .nullable()
          .test('isValidCanPort', i18n['Configs.invalidCanPort'], function (value) {
            const invalid = value !== 0 && value !== 1
            return !invalid
          }),
        frequency: yup
          .number()
          .transform(numberTransform)
          .required(i18n['Configs.frequencyIsRequired'])
          .nullable()
          .test('isValid', i18n['Configs.invalidFrequency'], function (value) {
            const invalid = value < 0 || value > 0 && value < 10 || value > 65535
            return !invalid
          }),
        lengthOfBits: yup
          .number()
          .transform(numberTransform)
          .required(i18n['Configs.lengthOfBitsIsRequired'])
          .nullable()
          .test('isValid', i18n['Configs.invalidLengthOfBits'], function (value) {
            const startBit = this.parent.startBit

            const invalidValue = value <= 0 || value > 64
            const invalidSum = value + startBit > 64
            const invalid = invalidSum || invalidValue
            return !invalid
          }),
        startBit: yup
          .number()
          .transform(numberTransform)
          .required(i18n['Configs.startBitIsRequired'])
          .nullable()
          .test('isValid', i18n['Configs.invalidStartBit'], function (value) {
            const lengthOfBits = this.parent.lengthOfBits

            const invalidValue = value < 0 || value >= 64
            const invalidSum = value + lengthOfBits > 64
            const invalid = invalidSum || invalidValue
            return !invalid
          }),
        offset: yup.number().transform(numberTransform).required(i18n['Configs.offsetIsRequired']).nullable(),
        multiplier: yup
          .number()
          .transform(numberTransform)
          .required(i18n['Configs.multiplierIsRequired'])
          .nullable()
          .test('isValid', i18n['Configs.invalidMultiplier'], function (value) {
            const invalid = value === 0
            return !invalid
          }),
        unit: yup.string()
      })
    )
  })
}

export const validationSchemaFromPC = () => {
  const i18n = translations[localStorage.getItem('user_language')]
  return yup.object().shape({
    name: yup.string().required(i18n['Configs.modelNameIsRequired']).nullable(),
    nodeId: yup
      .string()
      .required(i18n['Configs.nodeIdIsRequired'])
      .nullable()
      .test('isValid', i18n['Configs.invalidImportedNodeId'], function (value) {
        const invalid = !isHex(value) || value < 0 || value > 255
        return !invalid
      }),
    baudRate1: yup
      .number()
      .transform(numberTransform)
      .required(i18n['Configs.baudRate1IsRequired'])
      .nullable()
      .test('isValid', i18n['Configs.invalidBaudRate1'], function (value) {
        const invalid =
          value !== 50000 &&
          value !== 100000 &&
          value !== 125000 &&
          value !== 250000 &&
          value !== 500000 &&
          value !== 1000000
        return !invalid
      }),
    baudRate2: yup
      .number()
      .transform(numberTransform)
      .required(i18n['Configs.baudRate2IsRequired'])
      .nullable()
      .test('isValid', i18n['Configs.invalidBaudRate2'], function (value) {
        const invalid =
          value !== 50000 &&
          value !== 100000 &&
          value !== 125000 &&
          value !== 250000 &&
          value !== 500000 &&
          value !== 1000000
        return !invalid
      }),
    dataLoggingEnable: yup.boolean().required(i18n['Configs.dataLoggingEnableIsRequired']).nullable(),
    loggingUploadPeriod: yup
      .number()
      .transform(numberTransform)
      .required(i18n['Configs.loggingUploadPeriodIsRequired'])
      .nullable()
      .test('isValid', i18n['Configs.invalidLoggingUploadPeriod'], function (value) {
        const loggingBufferThreshold = this.parent.loggingBufferThreshold

        const loggingUploadPeriodIsInvalid = value < 0 || value > 0 && value < 60 || value > 65535
        const theyAreInvalid = loggingBufferThreshold === 0 && value === 0

        const invalid = loggingUploadPeriodIsInvalid || theyAreInvalid
        return !invalid
      }),
    loggingBufferThreshold: yup
      .number()
      .transform(numberTransform)
      .required(i18n['Configs.loggingBufferThresholdIsRequired'])
      .nullable()
      .test('isValid', i18n['Configs.invalidLoggingBufferThreshold'], function (value) {
        const loggingUploadPeriod = this.parent.loggingUploadPeriod

        const loggingBufferThresholdIsInvalid = value < 0 || value > 65535
        const theyAreInvalid = loggingUploadPeriod === 0 && value === 0

        const invalid = loggingBufferThresholdIsInvalid || theyAreInvalid
        return !invalid
      }),
    gps: yup.object().shape({
      positionLogTime: yup
        .number()
        .transform(numberTransform)
        .required(i18n['Configs.positionLogTimeIsRequired'])
        .nullable()
        .test('isRequired', i18n['Configs.invalidGpsFrequency'], function (value) {
          const invalid = value < 0 || value > 0 && value < 60 || value > 4294967295

          return !invalid
        }),
      altitudeLogTime: yup
        .number()
        .transform(numberTransform)
        .required(i18n['Configs.altitudeLogTimeIsRequired'])
        .nullable()
        .test('isRequired', i18n['Configs.invalidGpsFrequency'], function (value) {
          const invalid = value < 0 || value > 0 && value < 60 || value > 4294967295

          return !invalid
        }),
      speedLogTime: yup
        .number()
        .transform(numberTransform)
        .required(i18n['Configs.speedLogTimeIsRequired'])
        .nullable()
        .test('isRequired', i18n['Configs.invalidGpsFrequency'], function (value) {
          const invalid = value < 0 || value > 0 && value < 60 || value > 4294967295

          return !invalid
        }),
      headingLogTime: yup
        .number()
        .transform(numberTransform)
        .required(i18n['Configs.headingLogTimeIsRequired'])
        .nullable()
        .test('isRequired', i18n['Configs.invalidGpsFrequency'], function (value) {
          const invalid = value < 0 || value > 0 && value < 60 || value > 4294967295

          return !invalid
        })
    }),
    powerManagementEnable: yup
      .number()
      .transform(numberTransform)
      .test('isValid', i18n['Configs.invalidPowerManagementEnable'], function (value) {
        const invalid = value !== 0 && value !== 1 && value !== 3
        return !invalid
      }),
    shutdownDelay: yup
      .number()
      .transform(numberTransform)
      .test('isValid', i18n['Configs.invalidShutdownDelay'], function (value) {
        const invalid = value < 120 || value > 65535
        return !invalid
      }),
    cycleTimeDelay: yup
      .number()
      .transform(numberTransform)
      .test('isValid', i18n['Configs.invalidCycleTimeDelay'], function (value) {
        const invalid = value < 0 || value > 3600 // 1 month
        return !invalid
      }),
    MFIO: yup.array().of(
      yup.object().shape({
        signalId: yup
          .number()
          .transform(numberTransform)
          .required(i18n['Configs.mfioSignalIdIsRequired'])
          .nullable()
          .test('isValid', i18n['Configs.invalidMfioSignalId'], function (value) {
            const invalid = value !== 0 && value !== 1
            return !invalid
          }),
        pinFunction: yup
          .number()
          .transform(numberTransform)
          .required(i18n['Configs.pinFunctionIsRequired'])
          .nullable()
          .test('isValid', i18n['Configs.invalidPinFunction'], function (value) {
            const invalid = value < 0 || value > 3
            return !invalid
          }),
        name: yup.string().test('isRequired', i18n['Configs.mfioNameIsRequired'], function (value) {
          const pinFunction = this.parent.pinFunction
          const invalidValue = value === null || value === undefined || value === ''

          const invalid = pinFunction !== 0 && invalidValue
          return !invalid
        }),
        defaultPowerUp: yup.boolean().test('isRequired', i18n['Configs.defaultPowerUpIsRequired'], function (value) {
          const pinFunction = this.parent.pinFunction
          const invalidValue = typeof value !== 'boolean'

          const invalid = pinFunction === 1 && invalidValue
          return !invalid
        }),
        logCycle: yup
          .number()
          .transform(numberTransform)
          .test('isValid', i18n['Configs.invalidLogCycle'], function (value) {
            const pinFunction = this.parent.pinFunction
            const invalidValue =
              value === null || value === undefined || value < 0 || value > 0 && value < 10 || value > 65535

            const invalid = pinFunction === 2 && invalidValue
            return !invalid
          }),
        logType: yup
          .number()
          .transform(numberTransform)
          .test('isValid', i18n['Configs.invalidLogType'], function (value) {
            const invalid = value < 0 || value > 15
            return !invalid
          }),
        inputSignal: yup.string().test('isRequired', i18n['Configs.inputSignalIsRequired'], function (value) {
          const pinFunction = this.parent.pinFunction
          const invalidValue =
            value !== 'Voltage' &&
            value !== 'Digital Input' &&
            value !== 'Frequency' &&
            value !== 'Resistance' &&
            value !== 'Current'

          const invalid = pinFunction === 2 && invalidValue
          return !invalid
        }),
        inputRange: yup
          .number()
          .transform(numberTransform)
          .test('isRequired', i18n['Configs.inputRangeIsRequired'], function (value) {
            const pinFunction = this.parent.pinFunction
            const inputSignal = this.parent.inputSignal
            const invalidValue = value !== 0 && value !== 1

            const invalid =
              pinFunction === 2 &&
              (inputSignal === 'Voltage' || inputSignal === 'Digital Input' || inputSignal === 'Frequency') &&
              invalidValue
            return !invalid
          }),
        bias: yup
          .number()
          .transform(numberTransform)
          .test('isValid', i18n['Configs.invalidBias'], function (value) {
            const pinFunction = this.parent.pinFunction
            const inputSignal = this.parent.inputSignal
            const invalidValue = value === null || value === undefined || value < 0 || value > 3

            const invalid =
              pinFunction === 2 &&
              (inputSignal === 'Voltage' || inputSignal === 'Digital Input' || inputSignal === 'Frequency') &&
              invalidValue
            return !invalid
          }),
        digThreshHigh: yup
          .number()
          .transform(numberTransform)
          .test('isValid', i18n['Configs.invalidDigThreshHigh'], function (value) {
            const pinFunction = this.parent.pinFunction
            const inputSignal = this.parent.inputSignal
            const digThreshLow = this.parent.digThreshLow
            const invalidValue =
              value === null || value === undefined || value < 0 || value > 65535 || value < digThreshLow

            const invalid = pinFunction === 2 && inputSignal === 'Digital Input' && invalidValue
            return !invalid
          }),
        digThreshLow: yup
          .number()
          .transform(numberTransform)
          .test('isValid', i18n['Configs.invalidDigThreshLow'], function (value) {
            const pinFunction = this.parent.pinFunction
            const inputSignal = this.parent.inputSignal
            const digThreshHigh = this.parent.digThreshHigh
            const invalidValue =
              value === null || value === undefined || value < 0 || value > 65535 || value > digThreshHigh

            const invalid = pinFunction === 2 && inputSignal === 'Digital Input' && invalidValue
            return !invalid
          }),
        decimals: yup
          .number()
          .transform(numberTransform)
          .test('isValid', i18n['Configs.invalidDecimals'], function (value) {
            const pinFunction = this.parent.pinFunction
            const invalidValue = value === null || value === undefined || value < 0

            const invalid = pinFunction === 2 && invalidValue
            return !invalid
          }),
        multiplier: yup
          .number()
          .transform(numberTransform)
          .test('isValid', i18n['Configs.invalidMultiplier'], function (value) {
            const pinFunction = this.parent.pinFunction
            const invalidValue = value === null || value === undefined || value === 0

            const invalid = pinFunction === 2 && invalidValue
            return !invalid
          }),
        offset: yup
          .number()
          .transform(numberTransform)
          .test('isRequired', i18n['Configs.offsetIsRequired'], function (value) {
            const pinFunction = this.parent.pinFunction
            const invalidValue = value === null || value === undefined

            const invalid = pinFunction === 2 && invalidValue
            return !invalid
          }),
        unit: yup.string()
      })
    ),
    sensorsMap: yup.array().of(
      yup.object().shape({
        signalId: yup
          .number()
          .transform(numberTransform)
          .required(i18n['Configs.signalIdIsRequired'])
          .nullable()
          .test('isValid', i18n['Configs.invalidSignalId'], function (value) {
            const invalid = value < 2 || value > 65535
            return !invalid
          }),
        name: yup.string().required(i18n['Configs.signalNameIsRequired']).nullable(),
        address: yup
          .string()
          .required(i18n['Configs.addressIsRequired'])
          .nullable()
          .test('isValid', i18n['Configs.invalidImportedAddress'], function (value) {
            const invalid = value !== '11' && value !== '29'
            return !invalid
          }),
        CANId: yup
          .string()
          .required(i18n['Configs.canIdIsRequired'])
          .nullable()
          .test('isValidCanId', function (value) {
            const address = this.parent.address

            if (address === '11') {
              if (isHex(value) && value <= 0x7ff) {
                return true
              } else {
                return this.createError({ message: i18n['Configs.invalidImported11BitCanId'], path: this.path })
              }
            } else if (address === '29') {
              if (isHex(value) && value <= 0x1fffffff) {
                return true
              } else {
                return this.createError({ message: i18n['Configs.invalidImported29BitCanId'], path: this.path })
              }
            } else {
              return this.createError({ message: i18n['Configs.canIdIsRequired'], path: this.path })
            }
          }),
        idMask: yup
          .string()
          .required(i18n['Configs.idMaskIsRequired'])
          .nullable()
          .test('isValidIdMask', function (value) {
            const address = this.parent.address

            if (address === '11') {
              if (isHex(value) && value <= 0x7ff) {
                return true
              } else {
                return this.createError({ message: i18n['Configs.invalidImported11BitIdMask'], path: this.path })
              }
            } else if (address === '29') {
              if (isHex(value) && value <= 0x3fffffff) {
                return true
              } else {
                return this.createError({ message: i18n['Configs.invalidImported29BitIdMask'], path: this.path })
              }
            } else {
              return this.createError({ message: i18n['Configs.idMaskIsRequired'], path: this.path })
            }
          }),
        CANPort: yup
          .number()
          .transform(numberTransform)
          .required(i18n['Configs.canPortIsRequired'])
          .nullable()
          .test('isValid', i18n['Configs.invalidCanPort'], function (value) {
            const invalid = value !== 0 && value !== 1
            return !invalid
          }),
        frequency: yup
          .number()
          .transform(numberTransform)
          .required(i18n['Configs.frequencyIsRequired'])
          .nullable()
          .test('isValid', i18n['Configs.invalidFrequency'], function (value) {
            const invalid = value < 0 || value > 0 && value < 10 || value > 65535
            return !invalid
          }),
        lengthOfBits: yup
          .number()
          .transform(numberTransform)
          .required(i18n['Configs.lengthOfBitsIsRequired'])
          .nullable()
          .test('isValid', i18n['Configs.invalidLengthOfBits'], function (value) {
            const startBit = this.parent.startBit

            const invalidValue = value <= 0 || value > 64
            const invalidSum = value + startBit > 64
            const invalid = invalidSum || invalidValue
            return !invalid
          }),
        startBit: yup
          .number()
          .transform(numberTransform)
          .required(i18n['Configs.startBitIsRequired'])
          .nullable()
          .test('isValid', i18n['Configs.invalidStartBit'], function (value) {
            const lengthOfBits = this.parent.lengthOfBits

            const invalidValue = value < 0 || value >= 64
            const invalidSum = value + lengthOfBits > 64
            const invalid = invalidSum || invalidValue
            return !invalid
          }),
        offset: yup.number().transform(numberTransform).required(i18n['Configs.offsetIsRequired']).nullable(),
        multiplier: yup
          .number()
          .transform(numberTransform)
          .required(i18n['Configs.multiplierIsRequired'])
          .nullable()
          .test('isValid', i18n['Configs.invalidMultiplier'], function (value) {
            const invalid = value === 0
            return !invalid
          }),
        unit: yup.string()
      })
    )
  })
}
