import { defineMessages } from 'react-intl'

const messages = defineMessages({
  title: {
    id: 'EditConfiguration.title',
    description: 'Title text',
    defaultMessage: 'Edit configuration'
  },
  cancel: {
    id: 'EditConfiguration.cancel',
    description: 'Cancel button label',
    defaultMessage: 'Cancel'
  },
  save: {
    id: 'EditConfiguration.save',
    description: 'Save button label',
    defaultMessage: 'Save'
  },
  configurationDeniedTitle: {
    id: 'EditConfiguration.configurationDeniedTitle',
    description: 'Configuration denied dialog title',
    defaultMessage: 'Configuration denied'
  },
  configurationDeniedText: {
    id: 'EditConfiguration.configurationDeniedText',
    description: 'Configuration denied dialog text',
    defaultMessage:
      'The data consumption estimated for this configuration is too large. Please, try again with different parameters to get an estimated data consumption below 25 MB per month.'
  },
  mbPerMonth: {
    id: 'EditConfiguration.mbPerMonth',
    description: 'Megabytes per month label',
    defaultMessage: 'Megabytes per month: '
  },
  usagePercentage: {
    id: 'EditConfiguration.usagePercentage',
    description: 'Machine usage percentage label',
    defaultMessage: 'Machine usage percentage: '
  },
  generalSettings: {
    id: 'EditConfiguration.generalSettings',
    description: 'General settings card title',
    defaultMessage: 'Device settings'
  },
  gpsConditions: {
    id: 'EditConfiguration.gpsConditions',
    description: 'GPS conditions card title',
    defaultMessage: 'GPS Conditions'
  },
  modelName: {
    id: 'EditConfiguration.modelName',
    description: 'Model name input label',
    defaultMessage: 'Configuration name'
  },
  nodeId: {
    id: 'EditConfiguration.nodeId',
    description: 'Node id input label',
    defaultMessage: 'PLUS+1 Node ID'
  },
  baudRate1: {
    id: 'EditConfiguration.baudRate1',
    description: 'Baud rate 1 select label',
    defaultMessage: 'CAN port 0 baud rate (kbps)'
  },
  baudRate2: {
    id: 'EditConfiguration.baudRate2',
    description: 'Baud rate 2 select label',
    defaultMessage: 'CAN port 1 baud rate (kbps)'
  },
  loggingUploadPeriod: {
    id: 'EditConfiguration.loggingUploadPeriod',
    description: 'LoggingUploadPeriod input label',
    defaultMessage: 'Logging upload period (s)'
  },
  loggingBufferThreshold: {
    id: 'EditConfiguration.loggingBufferThreshold',
    description: 'LoggingBufferThreshold input label',
    defaultMessage: 'Logging buffer threshold (No. of messages)'
  },
  dataLogging: {
    id: 'EditConfiguration.dataLogging',
    description: 'Data logging checkbox label',
    defaultMessage: 'Data Logging'
  },
  sendGPSCoordinates: {
    id: 'EditConfiguration.sendGPSCoordinates',
    description: 'sendGPSCoordinates checkbox label',
    defaultMessage: 'Send GPS coordinates'
  },
  frequency: {
    id: 'EditConfiguration.frequency',
    description: 'Frequency texfield label',
    defaultMessage: 'Frequency'
  },
  timeInterval: {
    id: 'Signal.timeInterval',
    description: 'Time interval texfield label',
    defaultMessage: 'Time interval (s)'
  },
  canbus: {
    id: 'EditConfiguration.canbus',
    description: 'CAN bus paper title',
    defaultMessage: 'CAN bus'
  },
  mfio: {
    id: 'EditConfiguration.mfio',
    description: 'MFIO paper title',
    defaultMessage: 'Multi-Function I/O'
  },
  error400Message: {
    id: 'EditConfiguration.error400Message',
    description: 'Error 400 message',
    defaultMessage: 'Bad request'
  },
  error403Message: {
    id: 'EditConfiguration.error403Message',
    description: 'Error 403 message',
    defaultMessage: 'Access denied'
  },
  error404Message: {
    id: 'EditConfiguration.error404Message',
    description: 'Error 404 message',
    defaultMessage: 'API url not found.'
  },
  error406Message: {
    id: 'EditConfiguration.error406Message',
    description: 'Error 406 message',
    defaultMessage: 'Not acceptable'
  },
  errorUndefinedTitle: {
    id: 'EditConfiguration.errorUndefinedTitle',
    description: 'Error undefined title',
    defaultMessage: 'Error undefined'
  },
  errorUndefinedMessage: {
    id: 'EditConfiguration.errorUndefinedMessage',
    description: 'Error undefined message',
    defaultMessage: 'No description available.'
  },
  error409Message: {
    id: 'EditConfiguration.error409Message',
    description: 'Error 409 message',
    defaultMessage: 'Data integrity violation: Configuration name it\'s already in use'
  },
  error415Message: {
    id: 'EditConfiguration.error415Message',
    description: 'Error 415 message',
    defaultMessage: 'Unsupported media type'
  },
  error422Message: {
    id: 'EditConfiguration.error422Message',
    description: 'Error 422 message',
    defaultMessage: 'Validation failed'
  },
  error500Message: {
    id: 'EditConfiguration.error500Message',
    description: 'Error 500 message',
    defaultMessage: 'Unexpected error.'
  },
  error: {
    id: 'EditConfiguration.error',
    description: 'error text',
    defaultMessage: 'Error {number}'
  },
  error401Message: {
    id: 'EditConfiguration.error401Message',
    description: 'Error 401 message',
    defaultMessage: 'Invalid credentials'
  },
  speed: {
    id: 'EditConfiguration.speed',
    description: 'speed',
    defaultMessage: 'Speed'
  },
  altitude: {
    id: 'EditConfiguration.altitude',
    description: 'altitude',
    defaultMessage: 'Altitude'
  },
  heading: {
    id: 'EditConfiguration.heading',
    description: 'heading',
    defaultMessage: 'Heading'
  },
  numberOfMessages: {
    id: 'EditConfiguration.numberOfMessages',
    description: 'numberOfMessages',
    defaultMessage: '(No. of messages)'
  },
  loggingUploadPeriodDescription: {
    id: 'EditConfiguration.loggingUploadPeriodDescription',
    description: 'loggingUploadPeriod description',
    defaultMessage:
      'The periodicity at which the logged data are transmitted to the cloud. Disabling it, only the logging buffer threshold will be used to determine when to transmit. When this period has expired or the logging buffer threshold limit is reached, the logged data are transmitted to the cloud.'
  },
  loggingBufferThresholdDescription: {
    id: 'EditConfiguration.loggingBufferThresholdDescription',
    description: 'loggingBufferThresholdDescription description',
    defaultMessage:
      'The number of logged messages before being transmitted to the cloud. Disabling it, only the logging upload period will be used to determine when to transmit. When this limit is reached or the logging upload period has expired, the logged data are transmitted to the cloud.'
  },
  dataLoggingDisabled: {
    id: 'EditConfiguration.dataLoggingDisabled',
    description: 'dataLogging disabled alert message',
    defaultMessage: 'Disabling it, configured GPS data and CAN messages will no longer be registered and sent.'
  },
  loggingPeriodBufferWarning: {
    id: 'EditConfiguration.loggingPeriodBufferWarning',
    description: 'loggingUploadPeriod and loggingBufferThreshold warning',
    defaultMessage: 'Warning: At least "Logging upload period" or "Logging buffer threshold" must be enabled.'
  },
  addSignal: {
    id: 'EditConfiguration.addSignal',
    description: 'Add signal tooltip',
    defaultMessage: 'Add signal'
  },
  addMfio: {
    id: 'EditConfiguration.addMfio',
    description: 'Add MFIO tooltip',
    defaultMessage: 'Add MFIO'
  },
  addMfio0: {
    id: 'EditConfiguration.addMfio0',
    description: 'Add MFIO 0 option',
    defaultMessage: '+ Add MFIO 0'
  },
  addMfio1: {
    id: 'EditConfiguration.addMfio1',
    description: 'Add MFIO 1 option',
    defaultMessage: '+ Add MFIO 1'
  },
  download: {
    id: 'EditConfiguration.download',
    description: 'Download button label',
    defaultMessage: 'Download'
  }
})

export default messages
