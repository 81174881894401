import { defineMessages } from 'react-intl'

const messages = defineMessages({
  notifications: {
    id: 'NotificationsBell.notifications',
    description: 'PARTM notifications bell',
    defaultMessage: 'Notifications'
  },
  myProfile: {
    id: 'NotificationsBell.myProfile',
    description: 'My profile',
    defaultMessage: 'My Profile'
  },
  notificationsDisabled: {
    id: 'NotificationsBell.notificationsDisabled',
    description: 'Notifications disabled',
    defaultMessage: 'Notifications disabled'
  },
  notificationsNoReceived: {
    id: 'NotificationsBell.notificationsNoReceived',
    description: 'Notifications are not being received',
    defaultMessage: 'Notifications are not being received'
  }
})

export default messages
