import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'

import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'

import DM1 from '../DM1'
import DeviceSettings from '../DeviceSettings'
import GNSSGeofenceConditions from '../GNSSGeofenceConditions'
import MFIO from '../MFIO'
import MachineStateDetermination from '../MachineStateDetermination'
import SendCANMessages from '../SendCANMessages'
import Signal from '../Signal'
import messages from './messages'

const styles = {
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid #dadde9',
    fontSize: 14
  }
}

class EditConfigurationForm extends React.Component {
  constructor(props) {
    super(props)
    const {
      intl: { formatMessage },
      classes
    } = props

    this.formatMessage = formatMessage
    this.classes = classes
  }

  render() {
    const { addLocalConfigurationCANMessage, addLocalConfigurationSignal, configState } = this.props

    return (
      <div className='container-fluid' style={{ marginTop: 20 }}>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <DeviceSettings
              baudRate0={configState.baudRate0}
              baudRate0ErrorMessage={configState.baudRate0ErrorMessage}
              baudRate1={configState.baudRate1}
              baudRate1ErrorMessage={configState.baudRate1ErrorMessage}
              // cellularPriority={configState.cellularPriority}
              // cellularPriorityErrorMessage={configState.cellularPriorityErrorMessage}
              fotaUpdateEnabled={configState.fotaUpdateEnabled}
              interlinkEnabled={configState.interlinkEnabled}
              isEditable
              loggingUploadPeriod0={configState.loggingUploadPeriod0}
              loggingUploadPeriod0ErrorMessage={configState.loggingUploadPeriod0ErrorMessage}
              loggingUploadPeriod1={configState.loggingUploadPeriod1}
              loggingUploadPeriod1ErrorMessage={configState.loggingUploadPeriod1ErrorMessage}
              nodeId0={configState.nodeId0}
              nodeId0ErrorMessage={configState.nodeId0ErrorMessage}
              nodeId1={configState.nodeId1}
              nodeId1ErrorMessage={configState.nodeId1ErrorMessage}
              restartCellularModule={configState.restartCellularModule}
              sa0={configState.sa0}
              sa0ErrorMessage={configState.sa0ErrorMessage}
              sa1={configState.sa1}
              sa1ErrorMessage={configState.sa1ErrorMessage}
              // view='editConfig'
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <GNSSGeofenceConditions
              altitudeLogTime={configState.altitudeLogTime}
              altitudeLogTimeErrorMessage={configState.altitudeLogTimeErrorMessage}
              geofenceEnabled={configState.geofenceEnabled}
              geofenceLatitude={configState.geofenceLatitude}
              geofenceLatitudeErrorMessage={configState.geofenceLatitudeErrorMessage}
              geofenceLogToPortal={configState.geofenceLogToPortal}
              geofenceLongitude={configState.geofenceLongitude}
              geofenceLongitudeErrorMessage={configState.geofenceLongitudeErrorMessage}
              geofenceRadius={configState.geofenceRadius}
              geofenceRadiusErrorMessage={configState.geofenceRadiusErrorMessage}
              geofenceSamplePeriod={configState.geofenceSamplePeriod}
              geofenceSamplePeriodErrorMessage={configState.geofenceSamplePeriodErrorMessage}
              geofenceSendImmediately={configState.geofenceSendImmediately}
              gnssAltitudeEnabled={configState.gnssAltitudeEnabled}
              gnssHeadingEnabled={configState.gnssHeadingEnabled}
              gnssPositionEnabled={configState.gnssPositionEnabled}
              gnssSpeedEnabled={configState.gnssSpeedEnabled}
              headingLogTime={configState.headingLogTime}
              headingLogTimeErrorMessage={configState.headingLogTimeErrorMessage}
              isEditable
              positionLogTime={configState.positionLogTime}
              positionLogTimeErrorMessage={configState.positionLogTimeErrorMessage}
              speedLogTime={configState.speedLogTime}
              speedLogTimeErrorMessage={configState.speedLogTimeErrorMessage}
            />
          </Grid>
          <Grid item xs={12}>
            <MFIO
              bias0={configState.bias0}
              bias0ErrorMessage={configState.bias0ErrorMessage}
              bias1={configState.bias1}
              bias1ErrorMessage={configState.bias1ErrorMessage}
              bias2={configState.bias2}
              bias2ErrorMessage={configState.bias2ErrorMessage}
              bias3={configState.bias3}
              bias3ErrorMessage={configState.bias3ErrorMessage}
              digThreshHigh0={configState.digThreshHigh0}
              digThreshHigh0ErrorMessage={configState.digThreshHigh0ErrorMessage}
              digThreshHigh1={configState.digThreshHigh1}
              digThreshHigh1ErrorMessage={configState.digThreshHigh1ErrorMessage}
              digThreshHigh2={configState.digThreshHigh2}
              digThreshHigh2ErrorMessage={configState.digThreshHigh2ErrorMessage}
              digThreshHigh3={configState.digThreshHigh3}
              digThreshHigh3ErrorMessage={configState.digThreshHigh3ErrorMessage}
              digThreshLow0={configState.digThreshLow0}
              digThreshLow0ErrorMessage={configState.digThreshLow0ErrorMessage}
              digThreshLow1={configState.digThreshLow1}
              digThreshLow1ErrorMessage={configState.digThreshLow1ErrorMessage}
              digThreshLow2={configState.digThreshLow2}
              digThreshLow2ErrorMessage={configState.digThreshLow2ErrorMessage}
              digThreshLow3={configState.digThreshLow3}
              digThreshLow3ErrorMessage={configState.digThreshLow3ErrorMessage}
              inputRange0={configState.inputRange0}
              inputRange0ErrorMessage={configState.inputRange0ErrorMessage}
              inputRange1={configState.inputRange1}
              inputRange1ErrorMessage={configState.inputRange1ErrorMessage}
              inputRange2={configState.inputRange2}
              inputRange2ErrorMessage={configState.inputRange2ErrorMessage}
              inputRange3={configState.inputRange3}
              inputRange3ErrorMessage={configState.inputRange3ErrorMessage}
              inputSignal0={configState.inputSignal0}
              inputSignal0ErrorMessage={configState.inputSignal0ErrorMessage}
              inputSignal1={configState.inputSignal1}
              inputSignal1ErrorMessage={configState.inputSignal1ErrorMessage}
              inputSignal2={configState.inputSignal2}
              inputSignal2ErrorMessage={configState.inputSignal2ErrorMessage}
              inputSignal3={configState.inputSignal3}
              inputSignal3ErrorMessage={configState.inputSignal3ErrorMessage}
              isEditable
            />
          </Grid>
          <Grid item xs={12}>
            <DM1
              dm1LogToPortal={configState.dm1LogToPortal}
              dm1Port={configState.dm1Port}
              dm1PortErrorMessage={configState.dm1PortErrorMessage}
              dm1SA={configState.dm1SA}
              dm1SAErrorMessage={configState.dm1SAErrorMessage}
              dm1UseSA={configState.dm1UseSA}
              expanded={configState.dm1Expanded}
              isEditable
            />
          </Grid>
          <Grid item xs={12}>
            <MachineStateDetermination
              byte0={configState.byte0}
              byte0ErrorMessage={configState.byte0ErrorMessage}
              byte1={configState.byte1}
              byte1ErrorMessage={configState.byte1ErrorMessage}
              byte2={configState.byte2}
              byte2ErrorMessage={configState.byte2ErrorMessage}
              byte3={configState.byte3}
              byte3ErrorMessage={configState.byte3ErrorMessage}
              byte4={configState.byte4}
              byte4ErrorMessage={configState.byte4ErrorMessage}
              byte5={configState.byte5}
              byte5ErrorMessage={configState.byte5ErrorMessage}
              byte6={configState.byte6}
              byte6ErrorMessage={configState.byte6ErrorMessage}
              byte7={configState.byte7}
              byte7ErrorMessage={configState.byte7ErrorMessage}
              canId={configState.canId}
              canIdErrorMessage={configState.canIdErrorMessage}
              canPort={configState.canPort}
              canPortErrorMessage={configState.canPortErrorMessage}
              digitalInputPin={configState.digitalInputPin}
              digitalInputPinErrorMessage={configState.digitalInputPinErrorMessage}
              enableCan={configState.enableCan}
              enableDigitalInput={configState.enableDigitalInput}
              enableServiceToolButton={configState.enableServiceToolButton}
              expanded={configState.machineStateDeterminationExpanded}
              forceFullAccessMode={configState.forceFullAccessMode}
              isEditable
            />
          </Grid>
          <Grid item xs={12}>
            <Paper style={{ borderRadius: 0 }}>
              <Grid container style={{ justifyContent: 'space-between' }}>
                <Grid item>
                  <Typography component='h3' style={{ margin: '16px' }} variant='h5'>
                    {this.formatMessage(messages.sendCANMessages)}
                  </Typography>
                </Grid>
                <Grid item style={{ alignSelf: 'center', paddingRight: '1px' }}>
                  <Tooltip
                    classes={{ tooltip: this.classes.tooltip }}
                    placement='top'
                    title={this.formatMessage(messages.addCANMessage)}
                  >
                    <div style={{ padding: 0, display: 'inline-flex' }}>
                      <IconButton
                        disabled={configState.sendCANMessages.length >= 4}
                        onClick={addLocalConfigurationCANMessage}
                      >
                        <AddIcon />
                      </IconButton>
                    </div>
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid>
                {configState.sendCANMessages.map(sendCANMessage => {
                  const noErrorInSendCANMessage =
                    sendCANMessage.byte0ErrorMessage === '' &&
                    sendCANMessage.byte1ErrorMessage === '' &&
                    sendCANMessage.byte2ErrorMessage === '' &&
                    sendCANMessage.byte3ErrorMessage === '' &&
                    sendCANMessage.byte4ErrorMessage === '' &&
                    sendCANMessage.byte5ErrorMessage === '' &&
                    sendCANMessage.byte6ErrorMessage === '' &&
                    sendCANMessage.byte7ErrorMessage === '' &&
                    sendCANMessage.canIdErrorMessage === '' &&
                    sendCANMessage.canPortErrorMessage === '' &&
                    sendCANMessage.dataSourceErrorMessage === '' &&
                    sendCANMessage.lowerThresholdErrorMessage === '' &&
                    sendCANMessage.periodTypeErrorMessage === '' &&
                    sendCANMessage.periodTypeTriggeredErrorMessage === '' &&
                    sendCANMessage.sendPeriodErrorMessage === '' &&
                    sendCANMessage.sendPeriodTriggeredErrorMessage === '' &&
                    sendCANMessage.thresholdDividerErrorMessage === '' &&
                    sendCANMessage.triggerSourceErrorMessage === '' &&
                    sendCANMessage.triggerTypeErrorMessage === '' &&
                    sendCANMessage.upperThresholdErrorMessage === '' &&
                    sendCANMessage.virtualSignalNumberErrorMessage === ''
                  return (
                    <React.Fragment key={sendCANMessage.canMessageId}>
                      <Divider light />
                      <SendCANMessages
                        {...sendCANMessage}
                        isEditable
                        noErrorInSendCANMessage={noErrorInSendCANMessage}
                      />
                    </React.Fragment>
                  )
                })}
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper style={{ borderRadius: 0 }}>
              <Grid container style={{ justifyContent: 'space-between' }}>
                <Grid item>
                  <Typography component='h3' style={{ margin: '16px' }} variant='h5'>
                    {this.formatMessage(messages.virtualSignals)}
                  </Typography>
                </Grid>
                <Grid item style={{ alignSelf: 'center', paddingRight: '1px' }}>
                  <Tooltip
                    classes={{ tooltip: this.classes.tooltip }}
                    placement='top'
                    title={this.formatMessage(messages.addSignal)}
                  >
                    <div style={{ padding: 0, display: 'inline-flex' }}>
                      <IconButton disabled={configState.signals.length >= 80} onClick={addLocalConfigurationSignal}>
                        <AddIcon />
                      </IconButton>
                    </div>
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid>
                {configState.signals.map(signal => {
                  const noErrorInSignal =
                    signal.signalIdErrorMessage === '' &&
                    signal.nameErrorMessage === '' &&
                    signal.signalSourceErrorMessage === '' &&
                    signal.cchErrorMessage === '' &&
                    signal.piuErrorMessage === '' &&
                    signal.smlErrorMessage === '' &&
                    signal.ssErrorMessage === '' &&
                    signal.lengthOfBitsErrorMessage === '' &&
                    signal.multiplierErrorMessage === '' &&
                    signal.dividerErrorMessage === '' &&
                    signal.offsetErrorMessage === '' &&
                    signal.unitErrorMessage === '' &&
                    signal.logTypeErrorMessage === '' &&
                    signal.samplePeriodErrorMessage === '' &&
                    signal.triggerTypeErrorMessage === '' &&
                    signal.triggerSourceErrorMessage === '' &&
                    signal.triggerLogTypeErrorMessage === '' &&
                    signal.samplePeriodTriggeredErrorMessage === '' &&
                    signal.triggerLowerThresholdErrorMessage === '' &&
                    signal.triggerUpperThresholdErrorMessage === '' &&
                    signal.triggerThresholdDividerErrorMessage === '' &&
                    signal.triggerSendImmediatelyErrorMessage === ''
                  return (
                    <React.Fragment key={signal.signalId}>
                      <Divider light />
                      <Signal
                        {...signal}
                        inputSignal0={configState.inputSignal0}
                        inputSignal1={configState.inputSignal1}
                        inputSignal2={configState.inputSignal2}
                        inputSignal3={configState.inputSignal3}
                        isEditable
                        noErrorInSignal={noErrorInSignal}
                      />
                    </React.Fragment>
                  )
                })}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    )
  }
}

EditConfigurationForm.propTypes = {
  addLocalConfigurationCANMessage: PropTypes.func.isRequired,
  addLocalConfigurationSignal: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  configState: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired
}

export default withStyles(styles)(withRouter(injectIntl(EditConfigurationForm)))
