import { defineMessages } from 'react-intl'

const namespace = 'SelectColumnsButton'

const messages = defineMessages({
  name: {
    id: `${namespace}.name`,
    description: 'Name title',
    defaultMessage: 'Name'
  },
  deviceType: {
    id: `${namespace}.deviceType`,
    description: 'Device type',
    defaultMessage: 'Device type'
  },
  eid: {
    id: `${namespace}.eid`,
    description: 'eid',
    defaultMessage: 'EID'
  },
  firmwareVersion: {
    id: `${namespace}.firmwareVersion`,
    description: 'Firmware version',
    defaultMessage: 'Firmware version'
  },
  machineModel: {
    id: `${namespace}.machineModel`,
    description: 'Model column',
    defaultMessage: 'Model'
  },
  machineType: {
    id: `${namespace}.machineType`,
    description: 'Machine type',
    defaultMessage: 'Machine type'
  },
  machine: {
    id: `${namespace}.machine`,
    description: 'Machine',
    defaultMessage: 'Machine'
  },
  selectColumns: {
    id: `${namespace}.selectColumns`,
    description: 'Select columns',
    defaultMessage: 'Select columns'
  },
  machineBrand: {
    id: `${namespace}.machineBrand`,
    description: 'Brand',
    defaultMessage: 'Brand'
  },
  machineSerialNumber: {
    id: `${namespace}.machineSerialNumber`,
    description: 'Machine serial number',
    defaultMessage: 'Machine serial number'
  },
  showHideColumns: {
    id: `${namespace}.showHideColumns`,
    description: 'Show/hide columns',
    defaultMessage: 'Show/hide columns'
  },
  deviceConfiguration: {
    id: `${namespace}.deviceConfiguration`,
    description: 'Device configuration text',
    defaultMessage: 'Device configuration'
  },
  deviceConfigurationVersion: {
    id: `${namespace}.deviceConfigurationVersion`,
    description: 'Device configuration version text',
    defaultMessage: 'Device configuration version'
  },
  lastActivity: {
    id: `${namespace}.lastActivity`,
    description: 'Last activity',
    defaultMessage: 'Last activity'
  },
  lastOnline: {
    id: `${namespace}.lastOnline`,
    description: 'Last online',
    defaultMessage: 'Last online'
  },
  deviceSerialNumber: {
    id: `${namespace}.deviceSerialNumber`,
    description: 'Device serial number',
    defaultMessage: 'Device serial number'
  },
  simStatus: {
    id: `${namespace}.simStatus`,
    description: 'SIM status',
    defaultMessage: 'SIM status'
  },
  planID: {
    id: `${namespace}.planID`,
    description: 'Plan ID header title',
    defaultMessage: 'Plan ID'
  },
  accessPassStatus: {
    id: `${namespace}.accessPassStatus`,
    description: 'Access Pass status column header',
    defaultMessage: 'Access Pass status'
  },
  lastMessageDate: {
    id: `${namespace}.lastMessageDate`,
    description: 'Last message date',
    defaultMessage: 'Last message date'
  }
})

export default messages
