import { defineMessages } from 'react-intl'

const namespace = 'AssignMachinesToGroup'

const messages = defineMessages({
  error400Message: {
    id: `${namespace}.error400Message`,
    description: 'Error 400 message',
    defaultMessage: 'Bad request'
  },
  error403Message: {
    id: `${namespace}.error403Message`,
    description: 'Error 403 message',
    defaultMessage: 'Access denied'
  },
  error404Message: {
    id: `${namespace}.error404Message`,
    description: 'Error 404 message',
    defaultMessage: 'API url not found.'
  },
  error406Message: {
    id: `${namespace}.error406Message`,
    description: 'Error 406 message',
    defaultMessage: 'Not acceptable'
  },
  error500Message: {
    id: `${namespace}.error500Message`,
    description: 'Error 500 message',
    defaultMessage: 'Unexpected error'
  },
  errorUndefinedTitle: {
    id: `${namespace}.errorUndefinedTitle`,
    description: 'Error undefined title',
    defaultMessage: 'Error undefined'
  },
  errorUndefinedMessage: {
    id: `${namespace}.errorUndefinedMessage`,
    description: 'Error undefined message',
    defaultMessage: 'No description available.'
  },
  errorNoParentGroupTitle: {
    id: `${namespace}.errorNoParentGroupTitle`,
    description: 'It is not possible to assign machines to this group title',
    defaultMessage: 'It is not possible to assign machines to this group'
  },
  errorNoParentGroupMessage: {
    id: `${namespace}.errorNoParentGroupMessage`,
    description: 'No parent group available title',
    defaultMessage: 'No parent group available'
  },
  return: {
    id: `${namespace}.return`,
    description: 'Return button label',
    defaultMessage: 'Return'
  },
  cancel: {
    id: `${namespace}.cancel`,
    description: 'Cancel button label',
    defaultMessage: 'Cancel'
  },
  selected: {
    id: `${namespace}.selected`,
    description: 'Selected machines button label',
    defaultMessage: 'SELECTED'
  },
  clearSelection: {
    id: `${namespace}.clearSelection`,
    description: 'Clear selection button label',
    defaultMessage: 'Clear selection'
  },
  assign: {
    id: `${namespace}.assign`,
    description: 'Assign button label',
    defaultMessage: 'Assign'
  },
  filterByOrderableColumns: {
    id: `${namespace}.filterByOrderableColumns`,
    description: 'Filter by orderable columns text',
    defaultMessage: 'Filter by orderable columns'
  },
  name: {
    id: `${namespace}.name`,
    description: 'Name table header column label',
    defaultMessage: 'Name'
  },
  groupName: {
    id: `${namespace}.groupName`,
    description: 'Group name table header column label',
    defaultMessage: 'Group name'
  },
  deviceType: {
    id: 'EditGroup.deviceType',
    description: 'Device type table header column label',
    defaultMessage: 'Device type'
  },
  EID: {
    id: 'EditGroup.EID',
    description: 'EID table header column label',
    defaultMessage: 'EID'
  },
  noMachines: {
    id: `${namespace}.noMachines`,
    description: 'No machines for the above search criteria message',
    defaultMessage: 'No machines for the above search criteria.'
  },
  showingRows: {
    id: `${namespace}.showingRows`,
    description: 'Assign machines to group table total text showing rows',
    defaultMessage: 'Showing machines'
  },
  to: {
    id: `${namespace}.to`,
    description: 'Assign machines to group table total text to',
    defaultMessage: 'to'
  },
  of: {
    id: `${namespace}.of`,
    description: 'Assign machines to group table total text of',
    defaultMessage: 'of'
  },
  noDataText: {
    id: `${namespace}.noDataText`,
    description: 'No machines assigned message',
    defaultMessage: 'No machines assigned'
  },
  prePage: {
    id: `${namespace}.prePage`,
    description: 'Previous page button text',
    defaultMessage: 'Prev'
  },
  nextPage: {
    id: `${namespace}.nextPage`,
    description: 'Next page button text',
    defaultMessage: 'Next'
  },
  firstPage: {
    id: `${namespace}.firstPage`,
    description: 'First page button text',
    defaultMessage: 'First'
  },
  lastPage: {
    id: `${namespace}.lastPage`,
    description: 'Last page button text',
    defaultMessage: 'Last'
  },
  error: {
    id: `${namespace}.error`,
    description: 'error text',
    defaultMessage: 'Error {number}'
  },
  error401Message: {
    id: `${namespace}.error401Message`,
    description: 'Error 401 message',
    defaultMessage: 'Invalid credentials'
  },
  assignMachinesToGroup: {
    id: `${namespace}.assignMachinesToGroup`,
    description: 'Assign machines to group',
    defaultMessage: 'Assign machines to group'
  },
  eid: {
    id: `${namespace}.eid`,
    description: 'EID',
    defaultMessage: 'EID'
  },
  originGroupHasNoMachines: {
    id: `${namespace}.originGroupHasNoMachines`,
    description: 'Origin group has no machines',
    defaultMessage: 'Origin group has no machines'
  },
  installDate: {
    id: `${namespace}.installDate`,
    description: 'Install date table header column label',
    defaultMessage: 'Install date'
  },
  manufacturingTime: {
    id: `${namespace}.manufacturingTime`,
    description: 'Manufacturing time label text',
    defaultMessage: 'Manufacturing time'
  },
  lastActivityTime: {
    id: `${namespace}.lastActivityTime`,
    description: 'Last activity time label text',
    defaultMessage: 'Last activity time'
  },
  placeholder: {
    id: `${namespace}.placeholder`,
    description: 'Placeholder text',
    defaultMessage: 'Enter {column}'
  },
  selectPlaceholder: {
    id: `${namespace}.selectPlaceholder`,
    description: 'Select placeholder text',
    defaultMessage: 'Select {column}'
  },
  parentGroup: {
    id: `${namespace}.parentGroup`,
    description: 'Parent group text',
    defaultMessage: 'Parent group:'
  },
  machineModel: {
    id: `${namespace}.machineModel`,
    description: 'Machine model',
    defaultMessage: 'Model'
  },
  machineType: {
    id: `${namespace}.machineType`,
    description: 'Machine type',
    defaultMessage: 'Machine type'
  },
  machineBrand: {
    id: `${namespace}.machineBrand`,
    description: 'Machine brand',
    defaultMessage: 'Brand'
  },
  machineSerialNumber: {
    id: `${namespace}.machineSerialNumber`,
    description: 'Machine serial number',
    defaultMessage: 'Machine serial number'
  },
  lastActivity: {
    id: `${namespace}.lastActivity`,
    description: 'Last activity',
    defaultMessage: 'Last activity'
  },
  firmwareVersion: {
    id: `${namespace}.firmwareVersion`,
    description: 'Firmware version',
    defaultMessage: 'Firmware version'
  },
  deviceConfiguration: {
    id: `${namespace}.deviceConfiguration`,
    description: 'Device configuration',
    defaultMessage: 'Device configuration'
  },
  deviceConfigurationVersion: {
    id: `${namespace}.deviceConfigurationVersion`,
    description: 'Device configuration version',
    defaultMessage: 'Device configuration version'
  },
  selectColumns: {
    id: `${namespace}.selectColumns`,
    description: 'Select columns',
    defaultMessage: 'Select columns'
  },
  showHideColumns: {
    id: `${namespace}.showHideColumns`,
    description: 'Show/hide columns',
    defaultMessage: 'Show/hide columns'
  }
})

export default messages
