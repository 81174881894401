import PropTypes from 'prop-types'
import React from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Paper from '@material-ui/core/Paper'

import { getMappedConsentState } from 'components/SmsServiceConsentDialog/apiMappings'
import SmsServiceStateChip from 'components/SmsServiceStateChip'
import { getSmsServiceState } from 'components/SmsServiceStateChip/helpers'
import { client, logError } from 'utils/http'

import * as c from './constants'
import messages from './messages'

class AssignableUsersTable extends React.Component {
  constructor(props) {
    super(props)
    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      assignedUsers: [],
      count: 0,
      isAssignedUsersLoading: false,
      isUnassignedUsersLoading: false,
      selectedUsers: [],
      selectedUsersForNotificationRule: [],
      start: 0,
      unassignedUsers: [],
      tableOptions: {
        noDataText: <CircularProgress />,
        sizePerPage: 5,
        page: 1
      }
    }
  }

  componentDidMount() {
    this.getUnassignedUsers()
    this.getAssignedUsers()
  }

  getUnassignedUsers = () => {
    const { getGroupUsers, ruleInstance, setAlert } = this.props
    const {
      start,
      tableOptions: { sizePerPage }
    } = this.state

    if (ruleInstance) {
      const { groupId } = ruleInstance

      this.setState(
        state => ({
          unassignedUsers: [],
          isUnassignedUsersLoading: true,
          tableOptions: {
            ...state.tableOptions,
            noDataText: <CircularProgress />
          }
        }),
        () => {
          getGroupUsers(groupId, sizePerPage, start)
            .then(response => {
              const unassignedUsers =
                response?.data?.groupUsers?.users?.map(user => {
                  const consentState = getMappedConsentState(user?.consent)
                  const isPhoneNumberPresent = user?.isPhoneNumberPresent

                  return {
                    ...user,
                    smsService: getSmsServiceState(consentState, isPhoneNumberPresent)
                  }
                }) || []

              this.setState({
                unassignedUsers,
                count: response.data.groupUsers.total,
                isUnassignedUsersLoading: false
              })
            })
            .catch(response => {
              const errorResponse = { ...response }

              const alertMessages = true
              const alertMessagesType = 'danger'
              let alertMessagesTitle = ''
              let alertMessagesText = ['']

              if (errorResponse && errorResponse.error && errorResponse.error.response) {
                const errorDetail = errorResponse.error.response
                switch (errorDetail.status) {
                  case 400:
                    alertMessagesTitle = this.formatMessage(messages.error, { number: 400 })
                    alertMessagesText = [this.formatMessage(messages.error400Text)]
                    break
                  case 401:
                    alertMessagesTitle = this.formatMessage(messages.error, { number: 401 })
                    alertMessagesText = [errorResponse.error.response.message]
                    break
                  case 403:
                    alertMessagesTitle = this.formatMessage(messages.error, { number: 403 })
                    alertMessagesText = [this.formatMessage(messages.error403Text)]
                    break
                  case 404:
                    alertMessagesTitle = this.formatMessage(messages.error, { number: 404 })
                    alertMessagesText = [this.formatMessage(messages.error404Text)]
                    break
                  case 406:
                    alertMessagesTitle = this.formatMessage(messages.error, { number: 406 })
                    alertMessagesText = [this.formatMessage(messages.error406Text)]
                    break
                  case 409:
                    alertMessagesTitle = this.formatMessage(messages.error, { number: 409 })
                    alertMessagesText = [this.formatMessage(messages.error409Text)]
                    break
                  case 415:
                    alertMessagesTitle = this.formatMessage(messages.error, { number: 415 })
                    alertMessagesText = [this.formatMessage(messages.error415Text)]
                    break
                  case 422:
                    alertMessagesTitle = this.formatMessage(messages.error, { number: 422 })
                    alertMessagesText = [this.formatMessage(messages.error422Text)]
                    break
                  case 500:
                    alertMessagesTitle = this.formatMessage(messages.error, { number: 500 })
                    alertMessagesText = [errorResponse.error.response.data.error_description]
                    break
                  default:
                    alertMessagesTitle = this.formatMessage(messages.errorUndefinedTitle)
                    alertMessagesText = [this.formatMessage(messages.errorUndefinedText)]
                }
                this.setState({
                  isUnassignedUsersLoading: false,
                  unassignedUsers: [],
                  count: 0
                })
                setAlert(alertMessages, alertMessagesType, alertMessagesTitle, alertMessagesText)
              } else {
                this.setState({
                  isUnassignedUsersLoading: false,
                  unassignedUsers: [],
                  count: 0
                })

                alertMessagesTitle = this.formatMessage(messages.errorUndefinedTitle)
                alertMessagesText = [this.formatMessage(messages.errorUndefinedText)]

                setAlert(alertMessages, alertMessagesType, alertMessagesTitle, alertMessagesText)
              }
            })
        }
      )
    }
  }

  getAssignedUsers = () => {
    const { ruleInstance, setAlert, getUsersOfGroupDetail } = this.props

    if (!ruleInstance) {
      this.setState(state => ({
        tableOptions: {
          ...state.tableOptions,
          noDataText: this.formatMessage(messages.noUsers)
        }
      }))
    } else {
      this.setState(
        state => ({
          assignedUsers: [],
          isAssignedUsersLoading: true,
          tableOptions: {
            ...state.tableOptions,
            noDataText: <CircularProgress />
          }
        }),
        () => {
          const { groupId, users: usersAssigned } = ruleInstance

          const usersAssignedEmails = usersAssigned.map(user => user.email)

          if (usersAssignedEmails.length > 0) {
            getUsersOfGroupDetail(groupId, usersAssignedEmails)
              .then(response => {
                const assignedUsers =
                  response?.data?.users?.map(user => {
                    const consentState = getMappedConsentState(user?.consent)
                    const isPhoneNumberPresent = user?.isPhoneNumberPresent

                    return {
                      ...user,
                      smsService: getSmsServiceState(consentState, isPhoneNumberPresent)
                    }
                  }) || []

                this.setState({
                  assignedUsers,
                  isAssignedUsersLoading: false
                })
              })
              .catch(response => {
                const error = { ...response }

                const alertMessages = true
                const alertMessagesType = 'danger'
                let alertMessagesTitle = ''
                let alertMessagesText = ''

                if (error.response !== undefined && error.response.status !== undefined) {
                  switch (error.response.status) {
                    case 400:
                      alertMessagesTitle = this.formatMessage(messages.error, { number: 400 })
                      alertMessagesText = [this.formatMessage(messages.error400Text)]
                      break
                    case 401:
                      alertMessagesTitle = this.formatMessage(messages.error, { number: 401 })
                      alertMessagesText = [error.response.message]
                      break
                    case 403:
                      alertMessagesTitle = this.formatMessage(messages.error, { number: 403 })
                      alertMessagesText = [this.formatMessage(messages.error403Text)]
                      break
                    case 404:
                      alertMessagesTitle = this.formatMessage(messages.error, { number: 404 })
                      alertMessagesText = [this.formatMessage(messages.error404Text)]
                      break
                    case 500:
                      alertMessagesTitle = this.formatMessage(messages.error, { number: 500 })
                      alertMessagesText = [error.response.data.error_description]
                      break
                    default:
                      alertMessagesTitle = this.formatMessage(messages.errorUndefinedTitle)
                      alertMessagesText = [this.formatMessage(messages.errorUndefinedText)]
                  }

                  this.setState(state => {
                    const { noDataText, ...otherTableOptions } = state.tableOptions
                    return {
                      assignedUsers: [],
                      isAssignedUsersLoading: false,
                      tableOptions: {
                        noDataText: this.formatMessage(messages.noUsersAssigned),
                        ...otherTableOptions
                      }
                    }
                  })
                  setAlert(alertMessages, alertMessagesType, alertMessagesTitle, alertMessagesText)
                } else {
                  this.setState(state => {
                    const { noDataText, ...otherTableOptions } = state.tableOptions
                    return {
                      assignedUsers: [],
                      isAssignedUsersLoading: false,
                      tableOptions: {
                        noDataText: this.formatMessage(messages.noUsersAssigned),
                        ...otherTableOptions
                      }
                    }
                  })
                  alertMessagesTitle = this.formatMessage(messages.errorUndefinedTitle)
                  alertMessagesText = [this.formatMessage(messages.errorUndefinedText)]
                  setAlert(alertMessages, alertMessagesType, alertMessagesTitle, alertMessagesText)
                }
              })
          } else {
            this.setState({
              assignedUsers: [],
              isAssignedUsersLoading: false
            })
          }
        }
      )
    }
  }

  handleClearSelectedUsers = () => {
    this.setState({
      selectedUsers: [],
      selectedUsersForNotificationRule: []
    })
  }

  formatFirstName = (cell, row) => {
    if (row.firstName !== undefined) {
      return row.firstName
    } else {
      return ''
    }
  }

  formatSmsService = cell => {
    return <SmsServiceStateChip state={cell} />
  }

  onPageChange = (page, sizePerPage) => {
    this.setState(state => {
      const { page: previousPage, sizePerPage: previousSizePage, ...otherTableOptions } = state.tableOptions
      return {
        start: (page - 1) * sizePerPage,
        tableOptions: {
          page,
          sizePerPage,
          ...otherTableOptions
        }
      }
    }, this.getUnassignedUsers)
  }

  onSizePerPageList = sizePerPage => {
    this.setState(state => {
      const { sizePerPage: previousSizePerPage, ...otherTableOptions } = state.tableOptions
      return {
        tableOptions: { sizePerPage, ...otherTableOptions }
      }
    }, this.getUnassignedUsers)
  }

  getTableOptions = () => {
    const { tableOptions } = this.state
    const completeTableOptions = {
      ...tableOptions,
      onSizePerPageList: this.onSizePerPageList,
      sizePerPageList: [
        {
          text: '5',
          value: 5
        },
        {
          text: '50',
          value: 50
        },
        {
          text: '100',
          value: 100
        },
        {
          text: '200',
          value: 200
        }
      ],
      onPageChange: this.onPageChange,
      ignoreSinglePage: false,
      pageStartIndex: 1,
      paginationSize: 5,
      prePage: this.formatMessage(messages.prePage),
      nextPage: this.formatMessage(messages.nextPage),
      firstPage: this.formatMessage(messages.firstPage),
      lastPage: this.formatMessage(messages.lastPage),
      paginationShowsTotal: this.renderPaginationShowsTotal(this.formatMessage),
      paginationPosition: 'bottom',
      hideSizePerPage: false,
      alwaysShowAllBtns: false,
      withFirstAndLast: true,
      onFilterChange: this.onFilterChange,
      onSortChange: this.onSortChange
    }

    return completeTableOptions
  }

  onRowSelect = (row, isSelected, event, rowNumber) => {
    const { selectedUsers, selectedUsersForNotificationRule } = this.state

    const element = row
    const elementId = row.userId

    const currentSelectedUsers = selectedUsers.map(user => user)
    const currentSelectedUsersForNotificationRule = selectedUsersForNotificationRule.map(user => user)
    const indexOfUser = selectedUsers.indexOf(elementId)

    if (isSelected) {
      if (indexOfUser < 0) {
        currentSelectedUsers.push(elementId)
        currentSelectedUsersForNotificationRule.push(element)
      }
    } else {
      if (indexOfUser > -1) {
        currentSelectedUsers.splice(indexOfUser, 1)
        currentSelectedUsersForNotificationRule.splice(indexOfUser, 1)
      }
    }

    this.setState({
      selectedUsers: currentSelectedUsers,
      selectedUsersForNotificationRule: currentSelectedUsersForNotificationRule
    })
  }

  onSelectAll = (isSelected, rows) => {
    const { selectedUsers, selectedUsersForNotificationRule } = this.state
    let indexOfUser

    const currentSelectedUsers = selectedUsers.map(user => user)
    const currentSelectedUsersForNotificationRule = selectedUsersForNotificationRule.map(user => user)

    const usersToProcess = rows.map(user => {
      return user
    })

    usersToProcess.map(user => {
      indexOfUser = currentSelectedUsers.indexOf(user.userId)

      if (isSelected) {
        if (indexOfUser < 0) {
          currentSelectedUsers.push(user.userId)
          currentSelectedUsersForNotificationRule.push(user)
        }
      } else {
        if (indexOfUser > -1) {
          currentSelectedUsers.splice(indexOfUser, 1)
          currentSelectedUsersForNotificationRule.splice(indexOfUser, 1)
        }
      }

      return user
    })

    this.setState({
      selectedUsers: currentSelectedUsers,
      selectedUsersForNotificationRule: currentSelectedUsersForNotificationRule
    })
  }

  handleAssignUsers = () => {
    const { ruleInstance, changeTableMode, fetchRuleInstance, setAlert } = this.props
    const { selectedUsersForNotificationRule } = this.state

    const usersToAssign = selectedUsersForNotificationRule.map(user => ({ email: user.email, userId: user.userId }))

    let alertMessages = true
    let alertMessagesType = ''
    let alertMessagesTitle = ''
    let alertMessagesText = ''

    client
      .assignUsersToRuleInstance(ruleInstance.hashId, usersToAssign)
      .then(response => {
        alertMessages = true
        alertMessagesType = 'success'
        alertMessagesTitle = ''
        alertMessagesText = [this.formatMessage(messages.usersAssignedCorrectly)]
        setAlert(alertMessages, alertMessagesType, alertMessagesTitle, alertMessagesText)

        fetchRuleInstance()
        changeTableMode(c.VIEW_ASSIGNED_USERS_MODE)
      })
      .catch(response => {
        const error = { ...response }

        alertMessages = true
        alertMessagesType = 'danger'

        switch (error.response.status) {
          case 400:
            alertMessagesTitle = this.formatMessage(messages.error, { number: 400 })
            alertMessagesText = [this.formatMessage(messages.error400Text)]
            break
          case 401:
            alertMessagesTitle = this.formatMessage(messages.error, { number: 401 })
            alertMessagesText = [error.response.message]
            break
          case 403:
            alertMessagesTitle = this.formatMessage(messages.error, { number: 403 })
            alertMessagesText = [this.formatMessage(messages.error403Text)]
            break
          case 404:
            alertMessagesTitle = this.formatMessage(messages.error, { number: 404 })
            alertMessagesText = [this.formatMessage(messages.error404Text)]
            break
          case 406:
            alertMessagesTitle = this.formatMessage(messages.error, { number: 406 })
            alertMessagesText = [this.formatMessage(messages.error406Text)]
            break
          case 409:
            alertMessagesTitle = this.formatMessage(messages.error, { number: 409 })
            alertMessagesText = [this.formatMessage(messages.error409Text)]
            break
          case 415:
            alertMessagesTitle = this.formatMessage(messages.error, { number: 415 })
            alertMessagesText = [this.formatMessage(messages.error415Text)]
            break
          case 422:
            alertMessagesTitle = this.formatMessage(messages.error, { number: 422 })
            alertMessagesText = [this.formatMessage(messages.error422Text)]
            break
          case 500:
            alertMessagesTitle = this.formatMessage(messages.error, { number: 500 })
            alertMessagesText = [error.response.data.error_description]
            break
          default:
            alertMessagesTitle = this.formatMessage(messages.errorUndefinedTitle)
            alertMessagesText = [this.formatMessage(messages.errorUndefinedText)]

            logError(response)
        }
        setAlert(alertMessages, alertMessagesType, alertMessagesTitle, alertMessagesText)
      })
  }

  renderPaginationShowsTotal = formatMessage => (start, to, total) =>
    (
      <span>
        {`${formatMessage(messages.showingRows)} ${start} ${formatMessage(messages.to)} ${to} ${formatMessage(
          messages.of
        )} ${total}`}
      </span>
    )

  render() {
    const { tableHeight } = this.props
    const { assignedUsers, count, isAssignedUsersLoading, isUnassignedUsersLoading, selectedUsers, unassignedUsers } =
      this.state

    const isLoading = isAssignedUsersLoading || isUnassignedUsersLoading

    const currentAssignedUsersIds = assignedUsers.map(user => user.id)

    const tableOptions = this.getTableOptions()

    const data = isLoading ? [] : unassignedUsers

    const selectRowProp = {
      mode: 'checkbox',
      clickToSelect: false,
      onSelect: this.onRowSelect,
      onSelectAll: this.onSelectAll,
      bgColor: '#f5f5f5',
      selected: selectedUsers,
      unselectable: currentAssignedUsersIds
    }

    return (
      <div className='col-md-12' style={{ height: tableHeight, padding: 0 }}>
        <Paper style={{ height: '100%', borderRadius: 0 }}>
          <div className='col-md-12' style={{ height: '15%' }}>
            <div className='col-md-9' style={{ padding: 0 }}>
              <Button disabled style={{ marginTop: 14, marginLeft: 5 }}>
                {this.formatMessage(messages.selectedUsers)} ({selectedUsers.length})
              </Button>
              <Button
                className='secondary-action-button'
                disabled={selectedUsers.length === 0}
                onClick={this.handleClearSelectedUsers}
                style={{
                  marginTop: 14,
                  marginLeft: 5
                }}
              >
                {this.formatMessage(messages.clearSelection)}
              </Button>
              <span style={{ marginLeft: '15px', marginTop: '10px', marginBottom: '10px' }}>
                {this.formatMessage(messages.userConditionTextNoSelectableUsers)}
              </span>
            </div>
            <div className='col-md-3 text-right' style={{ height: 52, padding: 0 }}>
              <Button
                className='primary-action-button'
                disabled={selectedUsers.length === 0}
                onClick={this.handleAssignUsers}
                style={{
                  marginTop: 14,
                  marginLeft: 5
                }}
              >
                {this.formatMessage(messages.assignUsers)}
              </Button>
            </div>
          </div>
          <div className='col-md-12' style={{ padding: '0px', height: '85%' }}>
            <div className='table-with-pagination' style={{ height: '100%' }}>
              <BootstrapTable
                bodyStyle={{ height: '80%', overflowY: 'scroll' }}
                bordered={false}
                condensed={false}
                containerStyle={{ height: '100%' }}
                data={data}
                exportCSV={false}
                fetchInfo={{ dataTotalSize: count }}
                hover
                multiColumnSearch={false}
                options={tableOptions}
                pagination
                remote={remoteObj => ({
                  ...remoteObj,
                  search: false,
                  pagination: true,
                  sizePerPage: true,
                  sort: false,
                  filter: true
                })}
                search={false}
                searchPlaceholder={this.formatMessage(messages.searchPlaceholder)}
                selectRow={selectRowProp}
                striped={false}
                tableStyle={{ height: '80%' }}
              >
                <TableHeaderColumn
                  dataField='userId'
                  dataFormat={this.formatFirstName}
                  isKey
                  tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word', verticalAlign: 'middle' }}
                  width='32%'
                >
                  {this.formatMessage(messages.firstName)}
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField='lastName'
                  tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word', verticalAlign: 'middle' }}
                  width='32%'
                >
                  {this.formatMessage(messages.lastName)}
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField='email'
                  tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word', verticalAlign: 'middle' }}
                  width='32%'
                >
                  {this.formatMessage(messages.email)}
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField='smsService'
                  dataFormat={this.formatSmsService}
                  tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word', verticalAlign: 'middle' }}
                  width='32%'
                >
                  {this.formatMessage(messages.smsService)}
                </TableHeaderColumn>
              </BootstrapTable>
            </div>
          </div>
        </Paper>
      </div>
    )
  }
}

AssignableUsersTable.propTypes = {
  changeTableMode: PropTypes.func.isRequired,
  fetchRuleInstance: PropTypes.func.isRequired,
  getGroupUsers: PropTypes.func.isRequired,
  getUsersOfGroupDetail: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  ruleInstance: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
  tableHeight: PropTypes.string.isRequired
}

export default injectIntl(AssignableUsersTable)
