import PropTypes from 'prop-types'
import React from 'react'

import MuiThemeProvider from 'components/Theme'

import Header from './Header'
import ObsoleteFirmwareWarningPopUp from './ObsoleteFirmwareWarningPopUp'
import Sidebar from './Sidebar'

const AdminTemplate = ({ children, isCollapsed = false, isMapMounted = false }) => {
  // on-canvas is used on small screens, but it is harmless in bigger screens.
  // Since I don't know how to add it in a clean way (without checking sizes here),
  // I added it for all cases.
  const className = isCollapsed ? 'app nav-collapsed-min' : 'app on-canvas'
  const containerClassName = isMapMounted ? 'content-container map-container' : 'content-container'

  return (
    <MuiThemeProvider>
      <div className={className}>
        {/*<Header isCollapsed={isCollapsed} />*/}
        <Header />
        <div className='main-container'>
          <Sidebar isCollapsed={isCollapsed} />
          <div className={containerClassName}>{children}</div>
          <ObsoleteFirmwareWarningPopUp />
        </div>
      </div>
    </MuiThemeProvider>
  )
}

AdminTemplate.propTypes = {
  children: PropTypes.node,
  isCollapsed: PropTypes.bool,
  isMapMounted: PropTypes.bool
}

export default AdminTemplate
