import { createSelector } from 'reselect'

import { actions } from 'utils/http'
import { actionTypes as aT } from 'ducks/groups'
import * as c from './constants'
import * as csNodeActionTypes from './actionTypes'
import { devicesEndpointAdapter } from 'utils/adapters'

const { types: ht } = actions

export default (state = {}, action) => {
  switch (action.type) {
    case ht.success(ht.CS_NODE):
    case ht.success(ht.CS_NODE_EID):
    case ht.success(ht.CS_NODE_AND_LOCATION):
    case ht.success(ht.CS_NODES_AND_LOCATIONS): {
      const response = action.payload.data.devices ? action.payload.data.devices : action.payload.data

      if (Array.isArray(response)) {
        const csNodes = response.reduce((ret, csn) => {
          return {
            ...ret,
            [csn.id]: {
              ...state[csn.id],
              ...csn
            }
          }
        }, {})
        //return csNodes
        return { ...state, ...csNodes }
      }

      return {
        ...state,
        [response.id]: {
          ...state[response.id],
          ...response
        }
      }
    }

    case ht.success(ht.CS_NODES_DYNAMIC): {
      const response = action.payload.data.devices
        ? devicesEndpointAdapter(action.payload.data.devices)
        : action.payload.data

      if (Array.isArray(response)) {
        const csNodes = response.reduce((ret, csn) => {
          return {
            ...ret,
            [csn.id]: {
              ...state[csn.id],
              ...csn
            }
          }
        }, {})
        //return csNodes
        return { ...state, ...csNodes }
      }

      return {
        ...state,
        [response.id]: {
          ...state[response.id],
          ...response
        }
      }
    }

    case ht.success(ht.GROUP_ADD_DEVICES):
      debugger
      const url = action?.meta?.previousAction?.payload?.request?.url
      if (url.includes('/groups/userResources/remove/')) {
        return {}
      }
      break

    case ht.success(ht.CS_NODES_LOCATIONS_NOTIFICATIONS_DYNAMIC):
      const resp = action.payload.data.devices ? action.payload.data.devices : action.payload.data

      if (Array.isArray(resp)) {
        const csNodes = resp.reduce((ret, csn) => {
          // eslint-disable-next-line no-param-reassign
          ret[csn.id] = {
            ...state[csn.id],
            ...{
              id: csn.id,
              name: csn.name,
              description: csn.description,
              EID: csn.eid,
              deviceType: csn.device_type,
              operatingTime: csn.operating_time,
              deviceConfiguration: {
                name: csn.Configuration?.name,
                version: csn.Configuration?.version_number
              }
            }
          }

          return ret
        }, {})
        //return csNodes
        return { ...state, ...csNodes }
      }

      return {
        ...state,
        [resp.id]: {
          ...state[resp.id],
          ...resp
        }
      }

    case aT.GROUP_CHANGE:
    case ht.fail(ht.CS_NODE):
    case ht.fail(ht.CS_NODE_AND_LOCATION):
    case ht.fail(ht.CS_NODES_AND_LOCATIONS):
      return {}

    case ht.success(ht.CS_NODE_LOCATION):
      const deviceId = action.meta.previousAction.payload.request.data
      const data = action.payload.data

      if (data && data.location) {
        const lat = data.location.latitude
        const lng = data.location.longitude
        if (lat >= -90 && lat <= 90 && lng >= -180 && lng <= 180) {
          const location = {
            longitude: lng,
            latitude: lat
          }
          return {
            ...state,
            [deviceId]: {
              ...state[deviceId],
              location
            }
          }
        }
      }
      break

    case ht.success(ht.CS_NODES_LOCATION):
      const newState = { ...state }
      const locationResponse = action.payload.data && action.payload.data.content ? action.payload.data.content : []

      const nodeEIDs = locationResponse.map(n => n.eid)
      const nodeIds = Object.keys(newState)

      nodeEIDs.forEach(eid => {
        nodeIds.forEach(id => {
          if (newState[id].EID !== undefined) {
            if ('m' + newState[id].EID.replaceAll(':', '') === eid) {
              const l = locationResponse.find(r => r.eid === eid).location

              const lat = l.latitude
              const lng = l.longitude
              if (lat >= -90 && lat <= 90 && lng >= -180 && lng <= 180) {
                const location = {
                  longitude: lng,
                  latitude: lat
                }
                Object.assign(newState, {
                  [id]: {
                    ...newState[id],
                    location
                  }
                })
              }
            }
          }
        })
      })
      return { ...state, ...newState }

    case ht.success(ht.CS_NODE_TOKEN):
      if (action.payload) {
        // eslint-disable-next-line no-shadow
        const deviceId = action.meta.previousAction.payload.request.data
        // eslint-disable-next-line no-shadow
        const data = action.payload.data

        if (data && data.accessKeyId && data.secretAccessKey && data.sessionToken) {
          const wscredentials = {
            accessKeyId: data.accessKeyId ? data.accessKeyId : null,
            secretAccessKey: data.secretAccessKey ? data.secretAccessKey : null,
            sessionToken: data.sessionToken ? data.sessionToken : null,
            expiration: data.expiration ? data.expiration : null
          }
          return {
            ...state,
            [deviceId]: {
              ...state[deviceId],
              wscredentials
            }
          }
        }
      }
      break

    case ht.success(ht.CS_NODE_ACTIVATE):
      const activationData = action.payload.data
      return activationData

    case ht.success(ht.CS_NODES_DETAIL):
      const { devices = [] } = action.payload.data
      return devices.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), state)
    case ht.fail(ht.CS_NODES_DETAIL):
      return {}

    case ht.success(ht.CS_NODE_METADATA):
      const metadata = action.payload.data
      const deviceEid = action.meta.previousAction.payload.request.url.replace('/devices/', '').replace('/meta', '')

      const nodes = Object.entries(state)
      const node = nodes.filter(eachNode => {
        return eachNode[1].EID === deviceEid
      })
      if (node[0]) {
        const id = node[0][1].id
        return {
          ...state,
          [id]: {
            ...state[id],
            metadata
          }
        }
      }
      break

    case ht.success(csNodeActionTypes.CS_NODES_NOTIFICATIONS):
      const notificationsData = action.payload.data

      const newNodes = {}
      Object.entries(state).forEach(([nodeId, nodeData]) => {
        const notificationData = notificationsData.find(notification => notification.deviceId === nodeData.EID) || {}
        const notifications =
          notificationData?.notifications?.map(notification => {
            return {
              ...notification,
              criticality: notification.criticality.toLowerCase(),
              status: notification.status.toLowerCase()
            }
          }) || []

        newNodes[nodeId] = {
          ...nodeData,
          notifications
        }
      })

      return {
        ...state,
        ...newNodes
      }

    default:
  }
  return state
}
/* eslint-enable */

const getCSNodes = state => (state[c.NAME] === null ? null : state[c.NAME])
const getCSNodeById = (state, id) => (state[c.NAME] === null ? null : state[c.NAME][id])
const getCSNodeByEID = (state, eid) => {
  const nodes = Object.entries(state[c.NAME])
  const node = nodes.filter(eachNode => {
    return eachNode[1].EID === eid
  })

  return node[0] ? node[0][1] : null
}
const getLastActivityTime = (state, id) => (state[c.NAME] === null ? null : state[c.NAME][id].lastActivityTime)
const getOperatingLastDateTime = (state, id) =>
  state[c.NAME] === null ? null : state[c.NAME][id]?.operatingTime?.lastDateTime
const getLastLocation = (state, id) =>
  state[c.NAME] === null ? null : state[c.NAME][id] ? state[c.NAME][id].location : null
//const getRealTimeCredentials = (state, id) => (state[c.NAME] === null ? null : state[c.NAME][id].wscredentials)

const getRealTimeCredentials = createSelector(getCSNodes, csNodes =>
  Object.keys(csNodes).reduce((ret, nodeId) => {
    const credentials = { ...csNodes[nodeId].wscredentials }
    ret[nodeId] = { ...credentials } // eslint-disable-line no-param-reassign
    return ret
  }, {})
)

const getAllCSNodes = createSelector(getCSNodes, csNodes => Object.values(csNodes))

export {
  getCSNodes,
  getCSNodeById,
  getCSNodeByEID,
  getLastActivityTime,
  getOperatingLastDateTime,
  getAllCSNodes,
  getLastLocation,
  getRealTimeCredentials
}
