import React from 'react'
import { PropTypes } from 'prop-types'
import { Link } from 'react-router-dom'

import { injectIntl } from 'react-intl'
import messages from './messages'

const LinkedLogo = ({ isCollapsed = false, logo, link, intl }) => {
  //const LinkedLogo = ({ logo, link, intl }) => {
  const { formatMessage } = intl
  return (
    <Link to={link}>
      {logo && (
        <img alt={formatMessage(messages.logo)} src={logo} className={'logo-' + (isCollapsed ? 'small' : 'normal')} />
      )}
    </Link>
  )
}

LinkedLogo.propTypes = {
  isCollapsed: PropTypes.bool,
  link: PropTypes.string.isRequired,
  logo: PropTypes.string
}

export default injectIntl(LinkedLogo)
