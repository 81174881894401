import moment from 'moment'
import { createAction } from 'redux-actions'

import { actions as notificationsActions } from 'ducks/notifications'
import * as c from 'utils/constants'
import { client, actions } from 'utils/http'
import { resolvePermissions } from 'utils/permissions'

import * as t from './actionTypes'
import {
  cacheCredentials,
  clearCredentials,
  clearRegisterPath,
  getAccessTokenExpiresIn,
  getAccessTokendDateExpiresIn
} from './cache'
import { hasBeenInitialized, getLoggedUserEmail, isPlus1ConnectAuthorized } from './reducer'

// ------------------------------------
// Simple action creators
// ------------------------------------

const authorize = createAction(t.AUTHORIZE, ({ email, token }) => ({
  user: email,
  token
}))

const showError = createAction(t.SHOW_ERROR, errorMessage => errorMessage)

const clearError = createAction(t.CLEAR_ERROR)

const loadingUser = createAction(t.LOADING_USER, userState => userState)

const updateProfileOnly = profile => {
  return {
    type: t.UPDATE_PROFILE,
    profile
  }
}

// ------------------------------------
// Composed action creators
// ------------------------------------
//Method that disconnects the user when the token expires
const logoutUser = secondsExpireToken => dispatch => {
  if (secondsExpireToken !== undefined && secondsExpireToken > 0) {
    const currentTimeInSeconds = moment().valueOf() / 1000
    const secondsTokenExpiresIn = Math.round(secondsExpireToken - currentTimeInSeconds)

    let millisecondsExpireToken = secondsTokenExpiresIn * 1000
    //Check if the number of milliseconds is greater than the maximum allowed value for setTimeout
    if (millisecondsExpireToken > 2147483647) {
      millisecondsExpireToken = 2147483647
    }

    setTimeout(function () {
      dispatch(loadingUser('toBeExpired'))
    }, millisecondsExpireToken - 120000)

    setTimeout(function () {
      dispatch(unauthorize())
      dispatch(loadingUser('expired'))
    }, millisecondsExpireToken)
  }
}

const plus1ConnectLogin = () => (dispatch, getState) => {
  const state = getState()
  // Check if token exists
  if (hasBeenInitialized(state)) {
    //Check if token has expired
    let tokenIsExpired = false
    const currentDate = moment()
    const currentTokenDateExpirenIn = moment(getAccessTokendDateExpiresIn())
    const diffDates = currentTokenDateExpirenIn.diff(currentDate)
    if (diffDates < 0) {
      tokenIsExpired = true
    }

    if (tokenIsExpired) {
      //Log out user
      dispatch(loadingUser('expired'))
      dispatch(unauthorize())
    } else {
      const { promise } = dispatch(actions.plus1Connect.plus1ConnectLogin())
      promise
        .then(response => {
          //const userId = response.payload.data.id
          //cacheUserId(userId)
        })
        .catch(error => {
          if (
            error.error &&
            error.error.response &&
            error.error.response.data &&
            error.error.response.data.includes('email is not verified')
          ) {
            dispatch(loadingUser('not verified'))
            dispatch(unauthorize())
          } else {
            dispatch(loadingUser('failed'))
            dispatch(unauthorize())
          }
        })
    }
  }
}

// SSO SIGN IN
const SSOLogin = (token, dateTokenExpiresInSeconds, email, userId) => dispatch => {
  dispatch(clearError())
  clearCredentials()

  const currentTimeInSeconds = moment().valueOf() / 1000
  const secondsTokenExpiresIn = Math.round(dateTokenExpiresInSeconds - currentTimeInSeconds)

  const credentials = {
    email,
    token,
    userId,
    tokenExpiresIn: dateTokenExpiresInSeconds,
    dateTokenExpiresIn: moment().add(secondsTokenExpiresIn, 'seconds')
  }
  cacheCredentials(
    credentials.email,
    credentials.token,
    credentials.userId,
    credentials.tokenExpiresIn,
    credentials.dateTokenExpiresIn
  )
  dispatch(authorize(credentials))

  // PUNE v2
  const { promise } = dispatch(actions.plus1Connect.plus1ConnectLogin())
  promise
    .then(response => {
      if (response !== undefined && response.payload !== undefined) {
        //const userId = response.payload.data.id
        //cacheUserId(userId)
      }
    })
    .catch(error => {
      dispatch(loadingUser('failed'))
      dispatch(unauthorize())
    })
}

const loadProfile = () => (dispatch, getState) => {
  let state = getState()
  // Check if token exists
  if (hasBeenInitialized(state)) {
    //Check if token has expired
    let tokenIsExpired = false
    const currentDate = moment()
    const currentTokenDateExpirenIn = moment(getAccessTokendDateExpiresIn())
    const diffDates = currentTokenDateExpirenIn.diff(currentDate)
    if (diffDates < 0) {
      tokenIsExpired = true
    }

    if (tokenIsExpired) {
      //Log out user
      dispatch(loadingUser('expired'))
      dispatch(unauthorize())
    } else {
      //set language in app and store it in redux based on user preferences
      dispatch(actions.setLanguage(localStorage.getItem('user_language')))

      // MOVED
      //Open connection wiht WebSocket to receive notifications changes
      //dispatch(notificationsActions.connectToWebSocketForNotifications())

      //Launch method that disconnects the user when the token expires
      const secondsTokenExpiresIn = getAccessTokenExpiresIn()
      dispatch(logoutUser(secondsTokenExpiresIn))

      const isPlus1User = isPlus1ConnectAuthorized(state)
      if (isPlus1User) {
        const limit = 10
        let offset = 0

        const loadPermissions = async () => {
          let next = true
          while (next) {
            const { promise } = dispatch(actions.getUserDetails(limit, offset))
            const { payload } = await promise
            if (payload.data.data.result.UserGroups.length < limit) {
              next = false
            } else {
              offset += limit
              next = true
            }
          }
        }
        loadPermissions()
          .then(() => {
            dispatch(loadingUser('loaded'))
            state = getState()
            const canViewNotifications = resolvePermissions(state, c.NOTIFICATION_READ)

            if (canViewNotifications) {
              //Open connection wiht WebSocket to receive notifications changes
              dispatch(notificationsActions.connectToWebSocketForNotifications())
            }
          })
          .catch(response => {
            dispatch(loadingUser('failed'))
            dispatch(unauthorize())
          })
      } else {
        const attempt = () => {
          const { promise } = dispatch(actions.getProfile(getLoggedUserEmail(state)))
          return promise
            .then(response => {
              return
            })
            .catch(response => {
              const { error } = { ...response }
              if (error) {
                throw error
              }
            })
        }

        const rejectDelay = reason => {
          return new Promise(function (resolve, reject) {
            setTimeout(reject.bind(null, reason), 3000)
          })
        }

        const processResult = () => {
          dispatch(loadingUser('loaded'))
        }
        const errorHandler = () => {
          dispatch(loadingUser('failed'))
          dispatch(unauthorize())
        }

        let p = Promise.reject()
        for (let i = 0; i < 5; i++) {
          p = p.catch(attempt).catch(rejectDelay)
        }
        p = p.then(processResult).catch(errorHandler)
      }
    }
  }
}

const updateProfile = profile => dispatch => {
  dispatch(updateProfileOnly(profile))
  dispatch(actions.setLanguage(localStorage.getItem('user_language')))
}

const unauthorize = () => dispatch => {
  clearCredentials()
  clearRegisterPath()
  //dispatch(actions.logout())
}

export { SSOLogin, loadProfile, unauthorize, clearError, updateProfile, plus1ConnectLogin }
