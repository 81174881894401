import { getValueTypesFromLogType } from 'utils/valueTypes'
import { merge } from 'lodash'

/* eslint-disable max-params */
const manageWSSubscriptions = async (
  state,
  data,
  devicesData,
  subscribeWidgetToWS,
  unsubscribeWidgetFromWS,
  getNodeCredentials,
  getAzureToken
) => {
  const previousEid = state.previousEid[0]
  const eid = state.deviceEid[0]

  const wasWSConnected = state.wsSubscribedData !== ''
  const isWSConnected = data.data !== ''
  const wasDeviceConnected = state.previousEid.length > 0
  const hasChangedDevice = wasDeviceConnected && previousEid !== eid
  const hasChangedSignalType = typeof state.wsSubscribedData !== typeof data.data

  if (wasWSConnected && (!isWSConnected || isWSConnected && (hasChangedDevice || hasChangedSignalType))) {
    const previousTopic = getTopic(state.wsSubscribedData, previousEid)
    unsubscribeWidgetFromWS(previousTopic, previousEid)
  }

  if (isWSConnected && (!wasWSConnected || hasChangedDevice || hasChangedSignalType)) {
    const topic = getTopic(data.data, eid)
    const { value } = state.node.staticData.find(({ name }) => name === 'id')
    let credentials = getNodeCredentials(value)
    if (!credentials || Object.keys(credentials).length === 0) {
      const credentialsResponse = await getAzureToken(value, eid)
      credentials = credentialsResponse.payload.data
    }
    subscribeWidgetToWS(topic, eid, credentials)
  }
}
/* eslint-enable */

const getTopic = (data, eid) => {
  const topicEnding = typeof data === 'string' ? '/geo' : '/u/ds'
  return process.env.REACT_APP_TOPIC + 'm' + eid.replaceAll(':', '') + topicEnding
}

const createZoomConfig = chartId => {
  sessionStorage.setItem(chartId, JSON.stringify({ loaded: false, xMin: '', xMax: '' }))
}

const setValue = (chartId, field, value) => {
  let zoomConfig = JSON.parse(sessionStorage.getItem(chartId))
  if (zoomConfig) zoomConfig[field] = value
  else {
    zoomConfig = { loaded: false, xMin: '', xMax: '', [field]: value }
  }
  sessionStorage.setItem(chartId, JSON.stringify(zoomConfig))
}

const getValue = (chartId, field) => {
  const zoomConfig = JSON.parse(sessionStorage.getItem(chartId))
  if (zoomConfig) return zoomConfig[field]
  else return ''
}

const deleteZoomConfig = chartId => {
  sessionStorage.removeItem(chartId)
}

const valueMeetsTheCondition = data => {
  let meetsTheCondition = false
  if (data.conditionalParams && data.conditionalParams.value) {
    const conditionValue = parseFloat(data.conditionalParams.value)
    const value = data.value.value
    switch (data.conditionalParams.operator) {
      case '=':
        meetsTheCondition = value === conditionValue
        break
      case '>':
        meetsTheCondition = value > conditionValue
        break
      case '>=':
        meetsTheCondition = value >= conditionValue
        break
      case '<':
        meetsTheCondition = value < conditionValue
        break
      case '<=':
        meetsTheCondition = value <= conditionValue
        break
      case '!=':
        meetsTheCondition = value !== conditionValue
        break
      default:
        break
    }
  }
  return meetsTheCondition
}

const getValueTypes = (deviceData, signalId) => {
  let valueTypes = []
  const { staticData = [], dinamicData = [] } = deviceData
  const data = staticData.find(dataItem => dataItem.name === 'deviceType')
  const signal = dinamicData.find(dynamicSignal => dynamicSignal.signalId === signalId)
  if (data?.value && signal) {
    switch (data.value) {
      case 'CS100':
        if (signal.isMFIO && typeof signal.logType === 'number') {
          valueTypes = getValueTypesFromLogType(signal.logType)
        }
        break
      case 'CS500':
        if (signal.filterType === 6) valueTypes = getValueTypesFromLogType(15)
        break
      default:
    }
  }

  return valueTypes
}

const generatePartialStateFromProps = props => {
  let node = {
    staticData: [],
    dinamicDataId: '',
    dinamicData: []
  }
  let dinamicData = ''
  let previousEid = []
  let { valueType } = props.data
  const { data } = props.data

  if (typeof props.devicesData[props.eid] !== 'undefined') {
    node = props.devicesData[props.eid]
    previousEid = [props.eid]
    const dinamicDataSignalIds = node.dinamicData.map(signal => signal.signalId)
    const isValidData = dinamicDataSignalIds.includes(data)
    if (isValidData) dinamicData = data
  }

  const valueTypes = getValueTypes(node, dinamicData)
  if (!valueTypes.includes(valueType)) valueType = valueTypes[0] || ''

  return {
    node,
    deviceEid: [props.eid],
    deviceEidError: '',
    dinamicData,
    wsSubscribedData: dinamicData,
    devicesData: props.devicesData,
    previousEid,
    devicesTableDisplayed: false,
    devicesButtonTextKey: 'changeDevice',
    originalDevice: '',
    valueTypes,
    valueType,
    configurationLoading: false
  }
}

const mapToConditionalProperties = conditionalParams =>
  Object.entries(conditionalParams).reduce((acc, current) => {
    let key = current[0]
    if (key !== 'operator') {
      const charSplittedKey = key.split('')
      key = 'conditional' + charSplittedKey[0].toUpperCase() + charSplittedKey.slice(1).join('')
    }
    return { ...acc, [key]: current[1] }
  }, {})

const getDecimals = (deviceData, signalId) => {
  let decimals = null
  if (Array.isArray(deviceData?.dinamicData) && typeof signalId === 'number') {
    const signal = deviceData.dinamicData.find(dynamicSignal => dynamicSignal.signalId === signalId)
    if (typeof signal.decimals === 'number') decimals = signal.decimals
  }
  return decimals
}

const withDefaultValues = (widgetData, templateParams) => merge({}, templateParams, widgetData)

export {
  manageWSSubscriptions,
  getTopic,
  createZoomConfig,
  setValue,
  getValue,
  deleteZoomConfig,
  valueMeetsTheCondition,
  getValueTypes,
  generatePartialStateFromProps,
  mapToConditionalProperties,
  getDecimals,
  withDefaultValues
}
