import { defineMessages } from 'react-intl'

const messages = defineMessages({
  changeGroupAlert: {
    id: 'ProfileDropdown.changeGroupAlert',
    defaultMessage: 'It is not allowed to switch to a new group on this page.'
  },
  cookies: {
    id: 'ProfileDropdown.cookies',
    defaultMessage: 'Cookies'
  },
  hello: {
    id: 'ProfileDropdown.hello',
    defaultMessage: 'Hello '
  },
  groupSelection: {
    id: 'ProfileDropdown.groupSelection',
    defaultMessage: 'Group selection'
  },
  loadingInfo: {
    id: 'ProfileDropdown.loadingInfo',
    defaultMessage: 'Loading profile info...'
  },
  logout: {
    id: 'ProfileDropdown.logout',
    defaultMessage: 'Logout'
  },
  marketPlace: {
    id: 'ProfileDropdown.marketPlace',
    defaultMessage: 'PLUS+1 Marketplace'
  },
  myProfile: {
    id: 'ProfileDropdown.myProfile',
    defaultMessage: 'My profile'
  },
  smsServiceConsent: {
    id: 'ProfileDropdown.smsServiceConsent',
    defaultMessage: 'SMS Service Consent'
  },
  statusPage: {
    id: 'ProfileDropdown.statusPage',
    defaultMessage: 'Operations Status'
  },
  support: {
    id: 'ProfileDropdown.support',
    defaultMessage: 'Support'
  }
})

export default messages
