import { defineMessages } from 'react-intl'

const namespace = 'EditNotificationGeneralProperties'

const messages = defineMessages({
  error400Text: {
    id: `${namespace}.error400Text`,
    description: 'Error 400 text',
    defaultMessage: 'Bad request'
  },
  error404Text: {
    id: `${namespace}.error404Text`,
    description: 'Error 404 text',
    defaultMessage: 'API url not found'
  },
  error406Text: {
    id: `${namespace}.error406Text`,
    description: 'Error 406 text',
    defaultMessage: 'Not acceptable'
  },
  errorUndefinedTitle: {
    id: `${namespace}.errorUndefinedTitle`,
    description: 'Error undefined title',
    defaultMessage: 'Error undefined'
  },
  errorUndefinedText: {
    id: `${namespace}.errorUndefinedText`,
    description: 'Error undefined text',
    defaultMessage: 'No description avaliable.'
  },
  errorText: {
    id: `${namespace}.errorText`,
    description: 'Error title',
    defaultMessage: 'Error'
  },
  errorLoadingNotification: {
    id: `${namespace}.errorLoadingNotification`,
    description: 'Error loading notification description',
    defaultMessage: 'It was not possible to load the requested notification.'
  },
  errorSavingNotification: {
    id: `${namespace}.errorSavingNotification`,
    description: 'Error saving notification description',
    defaultMessage: 'An error occurred while saving the notification. Try again in a few minutes.'
  },
  title: {
    id: `${namespace}.title`,
    description: 'Edit notification title',
    defaultMessage: 'Notification'
  },
  next: {
    id: `${namespace}.next`,
    description: 'Next button label',
    defaultMessage: 'Next'
  },
  list: {
    id: `${namespace}.list`,
    description: 'List button label',
    defaultMessage: 'List'
  },
  notificationCreated: {
    id: `${namespace}.notificationCreated`,
    description: 'Notification created message',
    defaultMessage: 'Notification created'
  },
  notificationCreatedAssignUsers: {
    id: `${namespace}.notificationCreatedAssignUsers`,
    description: 'Notification created now you can assign users message',
    defaultMessage: 'The notification was successfully created. To assign users, press the button "Assign users"'
  },
  thisFieldIsRequired: {
    id: `${namespace}.thisFieldIsRequired`,
    description: 'This field is required text',
    defaultMessage: 'This field is required.'
  },
  youMustEnterAnInteger: {
    id: `${namespace}.youMustEnterAnInteger`,
    description: 'You must enter an integer in this field text',
    defaultMessage: 'You must enter an integer in this field.'
  },
  notificationUpdatedTitle: {
    id: `${namespace}.notificationUpdatedTitle`,
    description: 'Notification updated title',
    defaultMessage: 'Notification updated'
  },
  notificationUpdatedMessage: {
    id: `${namespace}.notificationUpdatedMessage`,
    description: 'Notification updated message',
    defaultMessage: 'Data have been saved correctly.'
  },
  error403Text: {
    id: `${namespace}.error403Text`,
    description: 'Error 403 text',
    defaultMessage: 'Access denied'
  },
  error409Text: {
    id: `${namespace}.error409Text`,
    description: 'Error 409 text',
    defaultMessage: 'Data integrity violation: Group name it`s already in use'
  },
  error415Text: {
    id: `${namespace}.error415Text`,
    description: 'Error 415 text',
    defaultMessage: 'Unsupported media type'
  },
  error422Text: {
    id: `${namespace}.error422Text`,
    description: 'Error 422 text',
    defaultMessage: 'Validation failed'
  },
  assignUsers: {
    id: `${namespace}.assignUsers`,
    description: 'Assign users button label',
    defaultMessage: 'Assign users'
  },
  assignMachines: {
    id: `${namespace}.assignMachines`,
    description: 'Assign machines button label',
    defaultMessage: 'Assign machines'
  },
  assignedMachines: {
    id: `${namespace}.assignedMachines`,
    description: 'Assigned machines title',
    defaultMessage: 'Assigned machines'
  },
  generalSettings: {
    id: `${namespace}.generalSettings`,
    description: 'General settings title',
    defaultMessage: 'General settings'
  },
  nameOfNotification: {
    id: `${namespace}.nameOfNotification`,
    description: 'Name of the notification label',
    defaultMessage: 'Name of the notification'
  },
  description: {
    id: `${namespace}.description`,
    description: 'Description label',
    defaultMessage: 'Description'
  },
  enabled: {
    id: `${namespace}.enabled`,
    description: 'Enabled option label',
    defaultMessage: 'Enabled*'
  },
  ifEnabledIsntChecked: {
    id: `${namespace}.ifEnabledIsntChecked`,
    description: 'If "Enabled" is not checked... message',
    defaultMessage: 'If "Enabled" isn`t checked, the rule is disabled. A disabled rule doesn`t generate notifications.'
  },
  createdAt: {
    id: `${namespace}.createdAt`,
    description: 'Created at floating label text',
    defaultMessage: 'Created at:'
  },
  modifiedAt: {
    id: `${namespace}.modifiedAt`,
    description: 'Modified at floating label text',
    defaultMessage: 'Modified at:'
  },
  group: {
    id: `${namespace}.group`,
    description: 'Group floating label text',
    defaultMessage: 'Group'
  },
  save: {
    id: `${namespace}.save`,
    description: 'Save button label',
    defaultMessage: 'Save'
  },
  newMachinesAssigned: {
    id: `${namespace}.newMachinesAssigned`,
    description: 'New machines assigned to this notification message',
    defaultMessage: 'New machines assigned to this notification.'
  },
  unassign: {
    id: `${namespace}.unassign`,
    description: 'Unassign button label',
    defaultMessage: 'Unassign'
  },
  unassignMachines: {
    id: `${namespace}.unassignMachines`,
    description: 'Unassign machines machine title',
    defaultMessage: 'Unassign {number} machines'
  },
  cancel: {
    id: `${namespace}.cancel`,
    description: 'Cancel button label',
    defaultMessage: 'Cancel'
  },
  machinesUnassignedCorrectly: {
    id: `${namespace}.machinesUnassignedCorrectly`,
    description: 'Machines unassigned correctly message',
    defaultMessage: 'Machines unassigned correctly.'
  },
  showingMachines: {
    id: `${namespace}.showingMachines`,
    description: 'Machines table total text showing rows',
    defaultMessage: 'Showing machines'
  },
  to: {
    id: `${namespace}.to`,
    description: 'Machines table total text to',
    defaultMessage: 'to'
  },
  of: {
    id: `${namespace}.of`,
    description: 'Machines table total text of',
    defaultMessage: 'of'
  },
  noMachinesAssigned: {
    id: `${namespace}.noMachinesAssigned`,
    description: 'No machines assigned message',
    defaultMessage: 'No machines assigned'
  },
  prePage: {
    id: `${namespace}.prePage`,
    description: 'Notifications previous page button text',
    defaultMessage: 'Prev'
  },
  nextPage: {
    id: `${namespace}.nextPage`,
    description: 'Notifications next page button text',
    defaultMessage: 'Next'
  },
  firstPage: {
    id: `${namespace}.firstPage`,
    description: 'Notifications first page button text',
    defaultMessage: 'First'
  },
  lastPage: {
    id: `${namespace}.lastPage`,
    description: 'Notifications last page button text',
    defaultMessage: 'Last'
  },
  clearSelection: {
    id: `${namespace}.clearSelection`,
    description: 'Clear selection button label',
    defaultMessage: 'Clear selection'
  },
  filterByOrderableColumns: {
    id: `${namespace}.filterByOrderableColumns`,
    description: 'Filter by orderable columns placeholder',
    defaultMessage: 'Filter by orderable columns'
  },
  locationID: {
    id: `${namespace}.locationID`,
    description: 'Location ID table header column label',
    defaultMessage: 'Location ID'
  },
  placeholder: {
    id: `${namespace}.placeholder`,
    description: 'Table header column filter placeholder',
    defaultMessage: 'Enter'
  },
  name: {
    id: `${namespace}.name`,
    description: 'Name table header column label',
    defaultMessage: 'Name'
  },
  groupName: {
    id: `${namespace}.groupName`,
    description: 'Group name table header column label',
    defaultMessage: 'Group name'
  },
  status: {
    id: `${namespace}.status`,
    description: 'Status table header column label || Status floating label text',
    defaultMessage: 'Status'
  },
  eid: {
    id: `${namespace}.eid`,
    description: 'EID  table header column label',
    defaultMessage: 'EID'
  },
  creationDate: {
    id: `${namespace}.creationDate`,
    description: 'Creation date table header column label',
    defaultMessage: 'Creation date'
  },
  actions: {
    id: `${namespace}.actions`,
    description: 'Actions table header column label',
    defaultMessage: 'Actions'
  },
  confirmMachineUnassignment: {
    id: `${namespace}.confirmMachineUnassignment`,
    description: 'Confirm machine unassignment message',
    defaultMessage: 'Confirm machine unassignment'
  },
  error: {
    id: `${namespace}.error`,
    description: 'error text',
    defaultMessage: 'Error {number}'
  },
  errorRequestingMachines: {
    id: `${namespace}.errorRequestingMachines`,
    description: 'Error requesting machines',
    defaultMessage: 'Error requesting machines'
  },
  error401Message: {
    id: `${namespace}.error401Message`,
    description: 'Error 401 message',
    defaultMessage: 'Invalid credentials'
  },
  searchPlaceholder: {
    id: `${namespace}.searchPlaceholder`,
    description: 'Search placeholder',
    defaultMessage: 'Filter by orderable columns'
  },
  assignAtLeastAMachine: {
    id: `${namespace}.assignAtLeastAMachine`,
    description: 'Assign a machine text',
    defaultMessage: 'At least one machine must be assigned to the notification.'
  },
  unassignMachinesError: {
    id: `${namespace}.unassignMachinesError`,
    description: 'Unassign machines error text',
    defaultMessage: 'An error occurred while trying to unassign the selected machines.'
  },
  selectedMachines: {
    id: `${namespace}.selectedMachines`,
    description: 'Selected machines button label',
    defaultMessage: 'SELECTED'
  },
  machineModel: {
    id: `${namespace}.machineModel`,
    description: 'Machine model',
    defaultMessage: 'Model'
  },
  machineType: {
    id: `${namespace}.machineType`,
    description: 'Machine type',
    defaultMessage: 'Machine type'
  },
  machineBrand: {
    id: `${namespace}.machineBrand`,
    description: 'Machine brand',
    defaultMessage: 'Brand'
  },
  machineSerialNumber: {
    id: `${namespace}.machineSerialNumber`,
    description: 'Machine serial number',
    defaultMessage: 'Machine serial number'
  },
  lastActivity: {
    id: `${namespace}.lastActivity`,
    description: 'Last activity',
    defaultMessage: 'Last activity'
  },
  firmwareVersion: {
    id: `${namespace}.firmwareVersion`,
    description: 'Firmware version',
    defaultMessage: 'Firmware version'
  },
  deviceConfiguration: {
    id: `${namespace}.deviceConfiguration`,
    description: 'Device configuration',
    defaultMessage: 'Device configuration'
  },
  deviceConfigurationVersion: {
    id: `${namespace}.deviceConfigurationVersion`,
    description: 'Device configuration version',
    defaultMessage: 'Device configuration version'
  },
  deviceType: {
    id: `${namespace}.deviceType`,
    description: 'Device type',
    defaultMessage: 'Device type'
  }
})

export default messages
