import React from 'react'
import PropTypes from 'prop-types'

import { injectIntl } from 'react-intl'

import Icon from '@material-ui/core/Icon'

import {
  getDanfossWebUrl,
  getDanfossPrivacyPolicyUrl,
  getDanfossTermsOfUseUrl,
  getDanfossGeneralInformationUrl,
  getDanfossProductOverviewUrl
} from './url'

import SSOSignInButton from './SSOSignInButton'
import SSOSignUpButton from './SSOSingUpButton'
import messages from './messages'

import PortalLoading from 'components/PortalLoading'

import { client, logError } from 'utils/http'

class HomeView extends React.Component {
  constructor(props) {
    super(props)
    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      isThemeLoading: true,
      theme: {}
    }
  }

  componentDidMount() {
    const { defaultTheme, location, history, setTheme } = this.props
    const [, groupPath] = location.pathname.split('/')
    let theme = {}
    client
      .getGroupThemeByLoginPath(groupPath)
      .then(({ data }) =>
        data.file ? client.getGroupThemeConfigUrl(data.groupId, data.hashId) : Promise.reject('No theme config file')
      )
      .then(({ data: url }) => client.getGroupThemeConfig(url))
      .then(response => {
        theme = { ...defaultTheme, ...response.data }
        setTheme(theme)
        const { groupId } = response.data
        sessionStorage.setItem('group_id', groupId)
        localStorage.setItem('group_id', groupId)
      })
      .catch(() => {
        if (groupPath === 'default') {
          theme = { ...defaultTheme }
          setTheme(theme)
        } else {
          history.push('/default')
        }
      })
      .finally(() => {
        this.setState({ isThemeLoading: false, theme })
      })
  }

  reopenCookies = event => {
    event.preventDefault()
    window.CookieConsent.renew()
  }

  renderThemedHome = () => {
    const { theme } = this.state
    const isGroupThemed = theme.loginPath && theme.loginPath !== 'default'
    return (
      <div
        style={{
          '--home-border-top': theme.appBarColor,
          '--app-font-color': theme.fontColor || '#FFFFFF',
          '--login-image': `url(${theme.loginImage})`,
          '--footer-height-lg': isGroupThemed ? '0px' : '61px',
          '--login-background-color': isGroupThemed ? 'transparent' : '#B6070F'
        }}
      >
        <div className='partm-home-header'>
          <img alt='Danfoss' className='partm-home-header-logo' src={theme.logoBig} />
          <SSOSignInButton
            buttonText={this.formatMessage(messages.logIn)}
            classValue='partm-home-btn partm-home-btn--outline partm-home-header-sign-in-btn'
          />
          <div className='partm-home-header-mobile-menu'>
            <label htmlFor='partm-home-header-mobile-menu-toggler'>
              <Icon className='zmdi zmdi-menu partm-home-header-mobile-menu-icon' style={{ fontSize: 30 }} />
            </label>
            <input id='partm-home-header-mobile-menu-toggler' type='checkbox' />
            <ul>
              <li>
                <a
                  className='partm-home-header-mobile-menu-item'
                  href={getDanfossWebUrl()}
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  {this.formatMessage(messages.learnMore)}
                </a>
              </li>
              <li>
                <SSOSignInButton
                  buttonText={this.formatMessage(messages.logIn)}
                  classValue='partm-home-header-mobile-menu-item'
                />
              </li>
            </ul>
          </div>
        </div>
        <div className='partm-home-diapo partm-home-diapo--only-content'>
          <div className='partm-home-diapo-text-block'>
            <h1 className='partm-home-diapo-title' style={{ paddingTop: '150px' }}>
              {theme.title}
            </h1>
            <p className='partm-home-lead'>{theme.loginText}</p>
            <SSOSignUpButton
              buttonText={this.formatMessage(messages.signUp)}
              classValue='partm-home-btn partm-home-btn--outline partm-home-diapo-btn'
            />
          </div>
        </div>
        {!isGroupThemed && (
          <div className='partm-home-footer'>
            <ul className='partm-home-footer-menu'>
              <li>
                <a
                  className='partm-home-footer-anchor'
                  href={getDanfossPrivacyPolicyUrl()}
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  {this.formatMessage(messages.privacyPolicy)}
                </a>
              </li>
              <li>
                <a
                  className='partm-home-footer-anchor'
                  href={getDanfossTermsOfUseUrl()}
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  {this.formatMessage(messages.termsOfUse)}
                </a>
              </li>
              <li>
                <a
                  className='partm-home-footer-anchor'
                  href={getDanfossGeneralInformationUrl()}
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  {this.formatMessage(messages.generalInformation)}
                </a>
              </li>
              <li>
                <a className='partm-home-footer-anchor' href='#' onClick={this.reopenCookies}>
                  {this.formatMessage(messages.cookies)}
                </a>
              </li>
              <li>
                <a
                  className='partm-home-footer-anchor'
                  href={getDanfossProductOverviewUrl()}
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  {this.formatMessage(messages.productOverview)}
                </a>
              </li>
            </ul>
          </div>
        )}
        {!isGroupThemed && process.env.REACT_APP_ENV === 'staging' && (
          <div className='container-fluid' style={{ background: 'white' }}>
            <div className='col-md-12' style={{ textAlign: 'center', paddingTop: '5px' }}>
              <span style={{ fontSize: 16, fontWeight: 'bold' }}>Sponsored by:</span>
            </div>
            <div className='col-md-12' style={{ textAlign: 'center', paddingTop: '10px' }}>
              <div className='col-md-6' style={{ textAlign: 'right', paddingRight: '20px' }}>
                <img
                  alt=''
                  src='/images/Hazitek.png'
                  style={{ display: 'inline-flex', height: '60px', textAlign: 'center' }}
                />
                <span style={{ fontSize: 14, display: 'block' }}>HAZITEK</span>
              </div>
              <div className='col-md-6' style={{ textAlign: 'left', paddingLeft: '20px' }}>
                <img
                  alt=''
                  src='/images/Feder.png'
                  style={{ display: 'inline-flex', height: '60px', textAlign: 'center' }}
                />
                <span style={{ fontSize: 14, display: 'block' }}>FEDER</span>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }

  render() {
    const { isThemeLoading } = this.state
    return isThemeLoading ? <PortalLoading /> : this.renderThemedHome()
  }
}

HomeView.propTypes = {
  defaultTheme: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  setTheme: PropTypes.func.isRequired
}

export default injectIntl(HomeView)
