import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { getMachinesUrl } from 'components/machines'
import { utils as groupUtils } from 'ducks/groups'
import { actions as template } from 'ducks/template'
import { utils as themeUtils } from 'ducks/theme'
import { getChartsUrl } from 'modules/charts'
import { getConfigurationsUrl } from 'modules/configurationsCS100'
import { getCS500ConfigurationsUrl } from 'modules/configurationsCS500'
import { getDevicesUrl } from 'modules/devices'
import { getGeofencesUrl } from 'modules/geofences'
import { getMaintenancesUrl } from 'modules/maintenances/urls'
import { getReportsUrl } from 'modules/reports'
import { getActionsStateRoute, getGeotrackingRoute, getMapUrl, getNotificationsUrl } from 'routes/helper'
import * as c from 'utils/constants'
import { resolvePermissions } from 'utils/permissions'

import Sidebar from './Sidebar'

const mapStateToProps = state => ({
  canReadCharts: groupUtils.hasPermission(state, c.CHARTS_READ),
  canReadConfigurations: resolvePermissions(state, c.DEVICECONFIGURATION_READ),
  canReadGeofenceOrGeotracking: groupUtils.hasSomePermission(state, [c.GEOFENCE_READ, c.GEOTRACKING_VIEW]),
  canReadGeotracking: groupUtils.hasPermission(state, c.GEOTRACKING_VIEW),
  canReadNotifications: resolvePermissions(state, c.NOTIFICATION_READ),
  canReadReports: groupUtils.hasPermission(state, c.SCHEDULE_READ),
  canViewActions: resolvePermissions(state, c.EVENTSHISTORY_VIEW),
  canViewGroupDashboards: groupUtils.hasPermission(state, c.GROUPDASHBOARD_READ),
  canViewMap: resolvePermissions(state, c.MAP_READ),
  canViewNodes: resolvePermissions(state, c.DEVICE_READ),

  actionsStateUrl: getActionsStateRoute(),
  chartsUrl: getChartsUrl(),
  configurationsUrl: getConfigurationsUrl(),
  cs500ConfigurationsUrl: getCS500ConfigurationsUrl(),
  devicesUrl: getDevicesUrl(),
  geofencesUrl: getGeofencesUrl(),
  geotrackingUrl: getGeotrackingRoute(),
  groupId: groupUtils.getSelectedGroup(state).id,
  maintenancesUrl: getMaintenancesUrl(),
  mapUrl: getMapUrl(),
  machinesUrl: getMachinesUrl(),
  notificationsUrl: getNotificationsUrl(),
  reportsUrl: getReportsUrl(),
  appFontColor: themeUtils.getFontColor(state),
  sideBarColor: themeUtils.getSideBarColor(state)
})

const mapDispatchToProps = {
  toggleCollapse: template.toggleCollapse
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidebar))
