import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Dialog from '@material-ui/core/Dialog'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import { getDeviceEditUrl } from 'modules/devices'
import {
  UPDATE_FIRMWARE_CS,
  WRITE_CONFIGURATION_CS,
  IMPORT_DASHBOARDS,
  DELETE_DASHBOARDS,
  IMPORT_ADVANCED_SIGNALS
} from 'utils/constants'

import messages from '../messages'
import { getMachinesUrl } from '../urls'
import { mapMachinesToCSNodes } from '../utils'
import DeleteDashboards from './Actions/DeleteDashboards'
import ImportAdvancedSignals from './Actions/ImportAdvancedSignals'
import ImportDashboards from './Actions/ImportDashboards'
import UpdateConfiguration from './Actions/UpdateConfiguration'
import UpdateFirmware from './Actions/UpdateFirmware'

const styles = {
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid #dadde9',
    fontSize: 14
  }
}

class FleetActions extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      selectedAction: '',
      anchorEl: null
    }
  }

  handleActionsButtonClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleActionClick = actionName => {
    this.setState({ selectedAction: actionName })
  }

  handleActionDialogClose = () => {
    this.setState({ selectedAction: '' })
  }

  handleMenuClose = () => {
    this.setState({ anchorEl: null })
  }

  renderActionsMenu = () => {
    const {
      apiActions,
      isMachineActions,
      canUpdateFirmware,
      canUpdateConfiguration,
      canImportDashboards,
      canDeleteDashboards,
      canImportAdvancedSignals,
      canEditDevice,
      machines
    } = this.props

    const { anchorEl } = this.state

    const deviceTypes = machines.map(machine => machine.device_type)

    const apiActionsNames = apiActions.map(action => action.name)
    const isUpdateFirmwareActionShown =
      canUpdateFirmware &&
      apiActionsNames.includes(UPDATE_FIRMWARE_CS) &&
      deviceTypes.length === 1 &&
      !deviceTypes.includes('CS10')
    const isUpdateConfigurationActionShown =
      canUpdateConfiguration &&
      apiActionsNames.includes(WRITE_CONFIGURATION_CS) &&
      deviceTypes.length === 1 &&
      !deviceTypes.includes('CS10')
    const isAdvancedSignalsImportActionShown =
      canImportAdvancedSignals && deviceTypes.length === 1 && !deviceTypes.includes('CS10')
    const isEditDeviceActionShown = canEditDevice && isMachineActions && machines.length === 1

    return (
      <Popper anchorEl={anchorEl} open={Boolean(anchorEl)} placement={isMachineActions ? 'bottom-end' : 'bottom-start'}>
        <Paper style={{ zIndex: 2100 }}>
          <ClickAwayListener onClickAway={this.handleMenuClose}>
            <MenuList>
              {isUpdateFirmwareActionShown && (
                <MenuItem
                  dense={isMachineActions}
                  onClick={() => {
                    this.handleActionClick(UPDATE_FIRMWARE_CS)
                  }}
                  style={isMachineActions ? { padding: '8px 50px 8px 25px' } : {}}
                >
                  <ListItemText
                    primary={this.formatMessage(messages.updateFirmware)}
                    {...(isMachineActions && { style: { fontSize: 15 }, disableTypography: true })}
                  />
                </MenuItem>
              )}
              {isUpdateConfigurationActionShown && (
                <MenuItem
                  dense={isMachineActions}
                  onClick={() => {
                    this.handleActionClick(WRITE_CONFIGURATION_CS)
                  }}
                  style={isMachineActions ? { padding: '8px 50px 8px 25px' } : {}}
                >
                  <ListItemText
                    primary={this.formatMessage(messages.updateConfiguration)}
                    {...(isMachineActions && { style: { fontSize: 15 }, disableTypography: true })}
                  />
                </MenuItem>
              )}
              {canImportDashboards && (
                <MenuItem
                  dense={isMachineActions}
                  onClick={() => {
                    this.handleActionClick(IMPORT_DASHBOARDS)
                  }}
                  style={isMachineActions ? { padding: '8px 50px 8px 25px' } : {}}
                >
                  <ListItemText
                    primary={this.formatMessage(messages.importDashboards)}
                    {...(isMachineActions && { style: { fontSize: 15 }, disableTypography: true })}
                  />
                </MenuItem>
              )}
              {canDeleteDashboards && (
                <MenuItem
                  dense={isMachineActions}
                  onClick={() => {
                    this.handleActionClick(DELETE_DASHBOARDS)
                  }}
                  style={isMachineActions ? { padding: '8px 50px 8px 25px' } : {}}
                >
                  <ListItemText
                    primary={this.formatMessage(messages.deleteDashboards)}
                    {...(isMachineActions && { style: { fontSize: 15 }, disableTypography: true })}
                  />
                </MenuItem>
              )}
              {isAdvancedSignalsImportActionShown && (
                <MenuItem
                  dense={isMachineActions}
                  onClick={() => {
                    this.handleActionClick(IMPORT_ADVANCED_SIGNALS)
                  }}
                  style={isMachineActions ? { padding: '8px 50px 8px 25px' } : {}}
                >
                  <ListItemText
                    primary={this.formatMessage(messages.importAdvancedSignals)}
                    {...(isMachineActions && { style: { fontSize: 15 }, disableTypography: true })}
                  />
                </MenuItem>
              )}
              {isEditDeviceActionShown && (
                <Link
                  className='button-link'
                  to={{ pathname: getDeviceEditUrl(machines[0].id), state: { from: getMachinesUrl() } }}
                >
                  <MenuItem dense style={{ padding: '8px 50px 8px 25px' }}>
                    <ListItemText
                      disableTypography
                      primary={this.formatMessage(messages.editMachine)}
                      style={{ fontSize: 15 }}
                    />
                  </MenuItem>
                </Link>
              )}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    )
  }

  renderSelectedAction = () => {
    const { apiActions, machines, intl } = this.props
    const { selectedAction } = this.state
    const csNodes = mapMachinesToCSNodes(machines)
    const deviceTypes = [...new Set(csNodes.map(csNode => csNode.deviceType))]
    let action = null

    const commonProps = {
      handleClose: this.handleActionDialogClose,
      csNodes,
      intl
    }

    switch (selectedAction) {
      case UPDATE_FIRMWARE_CS:
        action = apiActions.find(apiAction => apiAction.name === UPDATE_FIRMWARE_CS)
        return <UpdateFirmware action={action} {...commonProps} />
      case WRITE_CONFIGURATION_CS:
        action = apiActions.find(apiAction => apiAction.name === WRITE_CONFIGURATION_CS)
        return <UpdateConfiguration action={action} deviceTypes={deviceTypes} {...commonProps} />
      case IMPORT_DASHBOARDS:
        return <ImportDashboards {...commonProps} />
      case DELETE_DASHBOARDS:
        return <DeleteDashboards {...commonProps} />
      case IMPORT_ADVANCED_SIGNALS:
        return <ImportAdvancedSignals {...commonProps} />
      default:
        return null
    }
  }

  render() {
    const { isMachineActions, classes, machines } = this.props
    const { selectedAction } = this.state

    return (
      <React.Fragment>
        {isMachineActions ? (
          <Tooltip key='actions' classes={{ tooltip: classes.tooltip }} title={this.formatMessage(messages.actions)}>
            <IconButton onClick={this.handleActionsButtonClick} style={{ padding: 4, paddingLeft: 0 }}>
              <MoreVertIcon style={{ fontSize: 26 }} />
              {this.renderActionsMenu()}
            </IconButton>
          </Tooltip>
        ) : (
          <Button
            className='secondary-action-button'
            disabled={machines.length === 0}
            onClick={this.handleActionsButtonClick}
          >
            <FormatListBulletedIcon fontSize='small' style={{ marginRight: 3 }} />
            {this.formatMessage(messages.actions)}
            <Icon className='zmdi zmdi-caret-down' style={{ marginRight: 0, marginTop: -3 }} />
            {this.renderActionsMenu()}
          </Button>
        )}
        <Dialog fullWidth maxWidth='sm' open={Boolean(selectedAction)}>
          {this.renderSelectedAction()}
        </Dialog>
      </React.Fragment>
    )
  }
}

FleetActions.propTypes = {
  apiActions: PropTypes.array.isRequired,
  canDeleteDashboards: PropTypes.bool.isRequired,
  canEditDevice: PropTypes.bool.isRequired,
  canImportAdvancedSignals: PropTypes.bool.isRequired,
  canImportDashboards: PropTypes.bool.isRequired,
  canUpdateConfiguration: PropTypes.bool.isRequired,
  canUpdateFirmware: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired }).isRequired,
  isMachineActions: PropTypes.bool.isRequired,
  machines: PropTypes.array.isRequired
}

export default withStyles(styles)(injectIntl(FleetActions))
