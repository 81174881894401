import React from 'react'
import PropTypes from 'prop-types'
// eslint-disable-next-line
import ReactMapGL from 'react-map-gl'

import { MapProps } from '../../../props'
import EditionPanel from '../../EditionPanel'
import LayerSelectionPanel from '../../LayerSelectionPanel'
import { mapContainer, messageContainer, message } from './style.module.css'
import { MAPBOX_STYLE, MAPBOX_STYLE_SAT } from './constants'

import { isMobile } from 'react-device-detect'
import MobileMapControls from './MobileMapControls'

//const equalLocation = (loc1, loc2) =>
//  loc1.latitude === loc2.latitude && loc1.longitude === loc2.longitude

class Map extends React.Component {
  constructor(props) {
    super(props)
    this.ref = null
    this.ReactMapGL = React.createRef()
    this.state = {
      loaded: false,
      showMessage: false,
      viewport: {
        ...props.defaultCenter || props.center,
        zoom: props.zoom,
        transitionDuration: 0
        // transitionInterpolator: new FlyToInterpolator()
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { center, zoom } = this.props
    let newViewport = this.state.viewport

    if (zoom && zoom !== prevProps.zoom) {
      newViewport = { ...newViewport, zoom }
    }

    if (center && center !== prevProps.center) {
      newViewport = { ...newViewport, ...center }
    }

    if (newViewport !== this.state.viewport) {
      this.setState({
        ...this.state,
        viewport: {
          ...this.state.viewport,
          ...newViewport,
          transitionDuration: 0
          // transitionInterpolator: new LinearInterpolator()
        }
      })
    }
  }

  changeStyle = map => () => {
    var layers = map.getStyle().layers
    //console.log(layers)
    layers.forEach(layer => {
      if (layer.layout && layer.layout['text-field'] && !layer.id.startsWith('road')) {
        map.setLayoutProperty(layer.id, 'text-field', ['get', 'name_' + this.props.selectedLanguage])
      }
    })
  }

  onPanStart = () => {
    this.setState({ showMessage: true })
    setTimeout(
      function () {
        this.setState({ showMessage: false })
      }.bind(this),
      1500
    ) // wait 3 seconds, then reset
  }

  setLoaded = () => {
    if (this.props.onLoad) {
      this.props.onLoad(true)
    }
    this.setState({ loaded: true })
  }

  render() {
    const {
      center,
      controls,
      width = '100%',
      height = '100%',
      containerElement,
      onZoomChanged,
      onCenterChanged,
      children,
      ...other
    } = this.props
    const { viewport, showMessage } = this.state

    const newChildren = React.Children.map(children, child => {
      if (child !== null && child.props.onClick) {
        return React.cloneElement(child, {
          onClick: markerId => child.props.onClick(markerId, viewport.height, viewport.width)
        })
      }
      return child
    })

    const mobileMapControl = new MobileMapControls()
    mobileMapControl.setOptions({ onPanStart: this.onPanStart })

    const wrappable = (
      <div ref={ref => this.ref = ref} className={mapContainer}>
        {showMessage ? (
          <div className={messageContainer}>
            <p className={message}>Use two fingers to move the map</p>
          </div>
        ) : 
          ''
        }

        <ReactMapGL
          ref={this.ReactMapGL}
          controller={mobileMapControl}
          dragPan={!isMobile}
          refreshExpiredTiles={false}
          renderWorldCopies={false}
          touchAction={isMobile ? 'pan-x pan-y' : 'none'}
          {...center}
          {...other}
          {...viewport}
          height={height}
          onLoad={() => this.setLoaded(true)}
          onViewportChange={newViewport => {
            this.setState(
              {
                viewport: {
                  ...this.state.viewport,
                  ...newViewport
                }
              }
              //() => {
              //if (onZoomChanged && viewport.zoom !== newViewport.zoom) {
              //onZoomChanged(newViewport.zoom)
              //}

              //if (onCenterChanged && !equalLocation(viewport, newViewport)) {
              //onCenterChanged(newViewport)
              //}
              //}
            )
          }}
          width={width}
        >
          {this.state.loaded && newChildren}
        </ReactMapGL>
        {
          // Moved outside ReactMapGL because otherwise click events handled by
          // the menu controls is received in ReactMapGL too.
          this.ref !== null && <EditionPanel containerRef={this.ref} controls={controls} />
        }
        {
          // Moved outside ReactMapGL because otherwise click events handled by
          // the menu controls is received in ReactMapGL too.
          this.ref !== null && this.props.onClickSat && (
            <LayerSelectionPanel
              containerRef={this.ref}
              onClickMap={this.props.onClickMap}
              onClickSat={this.props.onClickSat}
            />
          )
        }
      </div>
    )

    if (containerElement) {
      return React.Children.map(containerElement, child =>
        React.cloneElement(child, {
          children: wrappable
        })
      )
    }

    return wrappable
  }
}

Map.defaultProps = {
  mapStyle: MAPBOX_STYLE,
  mapStyleSat: MAPBOX_STYLE_SAT,
  mapboxApiAccessToken: process.env.REACT_APP_MAPBOX_TOKEN
}

Map.propTypes = {
  controls: PropTypes.node,
  ...MapProps
}

export default Map
