import { defineMessages } from 'react-intl'
/* eslint-disable max-len */
const messages = defineMessages({
  machineStateDetermination: {
    id: 'MachineStateDeterminationCS500.machineStateDetermination',
    description: 'Machine state determination card title',
    defaultMessage: 'Machine state determination'
  },
  machineStateDeterminationExplanation: {
    id: 'MachineStateDeterminationCS500.machineStateDeterminationExplanation',
    description: 'Machine state determination explanation',
    defaultMessage: `The CS500 provides two operation modes where remote access rights can be limited.
      Full access: All the diagnostic service requests are allowed.
      Read-only: The non-safe service requests are blocked.
      Non-safe service requests: ECU reset, Start programming session, Write data (except history records).
      
      By default the device is set to "Read-only" mode and can be changed to "Full access" using one of the options:
      · Force "Full access" mode
      · CAN message
      · Digital input
      · Service Tool screen button`
  },
  forceFullAccessMode: {
    id: 'MachineStateDeterminationCS500.forceFullAccessMode',
    description: 'Force full access mode',
    defaultMessage: 'Force "Full access" mode'
  },
  enable: {
    id: 'MachineStateDeterminationCS500.enable',
    description: 'Enable',
    defaultMessage: 'Enable'
  },
  enabled: {
    id: 'MachineStateDeterminationCS500.enabled',
    description: 'Enabled',
    defaultMessage: 'Enabled'
  },
  can: {
    id: 'MachineStateDeterminationCS500.can',
    description: 'CAN',
    defaultMessage: 'CAN'
  },
  canExplanation: {
    id: 'MachineStateDeterminationCS500.canExplanation',
    description: 'CAN explanation',
    defaultMessage:
      'An extended CAN ID message is able to change operation mode where the message ID and bytes 0 to 7 must match a configurable pattern. Once received the device remains in "Normal mode" for 1 second.'
  },
  canPort: {
    id: 'MachineStateDeterminationCS500.canPort',
    description: 'Port',
    defaultMessage: 'Port'
  },
  canId: {
    id: 'MachineStateDeterminationCS500.canId',
    description: 'CAN ID',
    defaultMessage: 'CAN ID'
  },
  byte: {
    id: 'MachineStateDeterminationCS500.byte',
    description: 'Byte',
    defaultMessage: 'Byte {number}'
  },
  digitalInput: {
    id: 'MachineStateDeterminationCS500.digitalInput',
    description: 'Digital input pin',
    defaultMessage: 'Digital input pin'
  },
  digitalInputExplanation: {
    id: 'MachineStateDeterminationCS500.digitalInputExplanation',
    description: 'Digital input explanation',
    defaultMessage: '"Normal mode" is active as long as the selected input pin provides a high level.'
  },
  serviceToolButton: {
    id: 'MachineStateDeterminationCS500.serviceToolButton',
    description: 'Service Tool button',
    defaultMessage: 'Service Tool button'
  },
  serviceToolButtonExplanation: {
    id: 'MachineStateDeterminationCS500.serviceToolButtonExplanation',
    description: 'Service tool button explanation',
    defaultMessage: '"Normal mode" is active for one hour or until it is manually terminated.'
  },
  true: {
    id: 'MachineStateDeterminationCS500.true',
    description: 'True',
    defaultMessage: 'True'
  },
  false: {
    id: 'MachineStateDeterminationCS500.false',
    description: 'False',
    defaultMessage: 'False'
  },
  expand: {
    id: 'MachineStateDeterminationCS500.expand',
    description: 'Expand',
    defaultMessage: 'Expand'
  },
  collapse: {
    id: 'MachineStateDeterminationCS500.collapse',
    description: 'Collapse',
    defaultMessage: 'Collapse'
  }
})

export default messages
