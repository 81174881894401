import { defineMessages } from 'react-intl'

const namespace = 'EditGroup'

const messages = defineMessages({
  error400Message: {
    id: `${namespace}.error400Message`,
    description: 'Error 400 message',
    defaultMessage: 'Bad request'
  },
  error403Message: {
    id: `${namespace}.error403Message`,
    description: 'Error 403 message',
    defaultMessage: 'Access denied'
  },
  error404Message: {
    id: `${namespace}.error404Message`,
    description: 'Error 404 message',
    defaultMessage: 'API url not found.'
  },
  error406Message: {
    id: `${namespace}.error406Message`,
    description: 'Error 406 message',
    defaultMessage: 'Not acceptable'
  },
  error500Message: {
    id: `${namespace}.error500Message`,
    description: 'Error 500 message',
    defaultMessage: 'Unexpected error'
  },
  errorUndefinedTitle: {
    id: `${namespace}.errorUndefinedTitle`,
    description: 'Error undefined title',
    defaultMessage: 'Error undefined'
  },
  errorUndefinedMessage: {
    id: `${namespace}.errorUndefinedMessage`,
    description: 'Error undefined message',
    defaultMessage: 'No description available.'
  },
  title: {
    id: `${namespace}.title`,
    description: 'Edit group title',
    defaultMessage: 'Group:'
  },
  return: {
    id: `${namespace}.return`,
    description: 'Return button label',
    defaultMessage: 'Return'
  },
  thisFieldIsRequired: {
    id: `${namespace}.thisFieldIsRequired`,
    description: 'This field is required text',
    defaultMessage: 'This field is required.'
  },
  error412Message: {
    id: `${namespace}.error412Message`,
    description: 'Error 412 message',
    defaultMessage: 'Out of date'
  },
  error415Message: {
    id: `${namespace}.error415Message`,
    description: 'Error 415 message',
    defaultMessage: 'Unsupported media type'
  },
  error422Message: {
    id: `${namespace}.error422Message`,
    description: 'Error 422 message',
    defaultMessage: 'Validation failed'
  },
  error428Message: {
    id: `${namespace}.error428Message`,
    description: 'Error 428 message',
    defaultMessage: 'If-Match header required'
  },
  name: {
    id: `${namespace}.name`,
    description: 'Name floating label text || Name table header column label',
    defaultMessage: 'Name'
  },
  parentGroup: {
    id: `${namespace}.parentGroup`,
    description: 'Parent group floating label text',
    defaultMessage: 'Parent group'
  },
  creationDate: {
    id: `${namespace}.creationDate`,
    description: 'Creation date label text',
    defaultMessage: 'Creation date'
  },
  manufacturingTime: {
    id: `${namespace}.manufacturingTime`,
    description: 'Manufacturing time label text',
    defaultMessage: 'Manufacturing time'
  },
  modificationDate: {
    id: `${namespace}.modificationDate`,
    description: 'Modification date label text',
    defaultMessage: 'Modification date'
  },
  lastActivityTime: {
    id: `${namespace}.lastActivityTime`,
    description: 'Last activity time label text',
    defaultMessage: 'Last activity time'
  },
  save: {
    id: `${namespace}.save`,
    description: 'Save button label',
    defaultMessage: 'Save'
  },
  newMachinesAssigned: {
    id: `${namespace}.newMachinesAssigned`,
    description: 'New machines assigned to this group message',
    defaultMessage: 'New machines assigned to this group.'
  },
  unassign: {
    id: `${namespace}.unassign`,
    description: 'Unassign text',
    defaultMessage: 'Unassign'
  },
  machines: {
    id: `${namespace}.machines`,
    description: 'Machines text',
    defaultMessage: 'machines'
  },
  cancel: {
    id: `${namespace}.cancel`,
    description: 'Cancel button label',
    defaultMessage: 'Cancel'
  },
  machinesUnassignedCorrectly: {
    id: `${namespace}.machinesUnassignedCorrectly`,
    description: 'Machines unassigned correctly text',
    defaultMessage: 'Machines unassigned correctly.'
  },
  showingMachines: {
    id: `${namespace}.showingMachines`,
    description: 'Edit group table total text showing rows',
    defaultMessage: 'Showing machines'
  },
  to: {
    id: `${namespace}.to`,
    description: 'Edit group table total text to',
    defaultMessage: 'to'
  },
  of: {
    id: `${namespace}.of`,
    description: 'Edit group table total text of',
    defaultMessage: 'of'
  },
  noDataText: {
    id: `${namespace}.noDataText`,
    description: 'No data text',
    defaultMessage: 'No machines assigned'
  },
  prePage: {
    id: `${namespace}.prePage`,
    description: 'Previous page button text',
    defaultMessage: 'Prev'
  },
  nextPage: {
    id: `${namespace}.nextPage`,
    description: 'Next page button text',
    defaultMessage: 'Next'
  },
  firstPage: {
    id: `${namespace}.firstPage`,
    description: 'First page button text',
    defaultMessage: 'First'
  },
  lastPage: {
    id: `${namespace}.lastPage`,
    description: 'Last page button text',
    defaultMessage: 'Last'
  },
  assignedMachines: {
    id: `${namespace}.assignedMachines`,
    description: 'Assigned machines title',
    defaultMessage: 'Assigned machines'
  },
  clearSelection: {
    id: `${namespace}.clearSelection`,
    description: 'Clear selection button label',
    defaultMessage: 'Clear selection'
  },
  filterByOrderableColumns: {
    id: `${namespace}.filterByOrderableColumns`,
    description: 'Filter by orderable columns placeholder',
    defaultMessage: 'Filter by orderable columns'
  },
  locationID: {
    id: `${namespace}.locationID`,
    description: 'Location ID table header column label',
    defaultMessage: 'Location ID'
  },
  groupName: {
    id: `${namespace}.groupName`,
    description: 'Group name table header column label',
    defaultMessage: 'Group name'
  },
  deviceType: {
    id: `${namespace}.deviceType`,
    description: 'Device type table header column label',
    defaultMessage: 'Device type'
  },
  EID: {
    id: `${namespace}.EID`,
    description: 'EID table header column label',
    defaultMessage: 'EID'
  },
  installDate: {
    id: `${namespace}.installDate`,
    description: 'Install date table header column label',
    defaultMessage: 'Install date'
  },
  actions: {
    id: `${namespace}.actions`,
    description: 'Actions table header column label',
    defaultMessage: 'Actions'
  },
  confirmMachineUnassignament: {
    id: `${namespace}.confirmMachineUnassignament`,
    description: 'Confirm machine unassignment message',
    defaultMessage: 'Confirm machine unassignment'
  },
  error: {
    id: `${namespace}.error`,
    description: 'error text',
    defaultMessage: 'Error {number}'
  },
  error401Message: {
    id: `${namespace}.error401Message`,
    description: 'Error 401 message',
    defaultMessage: 'Invalid credentials'
  },
  assignMachines: {
    id: `${namespace}.assignMachines`,
    description: 'Assign machines button label',
    defaultMessage: 'Assign machines'
  },
  selected: {
    id: `${namespace}.selected`,
    description: 'Selected machines button label',
    defaultMessage: 'SELECTED'
  },
  placeholder: {
    id: `${namespace}.placeholder`,
    description: 'Placeholder text',
    defaultMessage: 'Enter {column}'
  },
  errorRequestingMachines: {
    id: `${namespace}.errorRequestingMachines`,
    description: 'Error requesting machines',
    defaultMessage: 'Error requesting machines'
  },
  selectPlaceholder: {
    id: `${namespace}.selectPlaceholder`,
    description: 'Select placeholder text',
    defaultMessage: 'Select {column}'
  },
  errorSaving: {
    id: `${namespace}.errorSaving`,
    description: 'There was an error when saving the edited group',
    defaultMessage: 'There was an error when saving the edited group'
  },
  errorObtaining: {
    id: `${namespace}.errorObtaining`,
    description: 'There was an error when obtaining the theme of the group',
    defaultMessage: 'There was an error when obtaining the theme of the group'
  },
  editGroupTitle: {
    id: `${namespace}.editGroupTitle`,
    description: 'Edit group title text',
    defaultMessage: 'Edit "{name}" group'
  },
  machineModel: {
    id: `${namespace}.machineModel`,
    description: 'Machine model',
    defaultMessage: 'Model'
  },
  machineType: {
    id: `${namespace}.machineType`,
    description: 'Machine type',
    defaultMessage: 'Machine type'
  },
  machineBrand: {
    id: `${namespace}.machineBrand`,
    description: 'Machine brand',
    defaultMessage: 'Brand'
  },
  machineSerialNumber: {
    id: `${namespace}.machineSerialNumber`,
    description: 'Machine serial number',
    defaultMessage: 'Machine serial number'
  },
  lastActivity: {
    id: `${namespace}.lastActivity`,
    description: 'Last activity',
    defaultMessage: 'Last activity'
  },
  firmwareVersion: {
    id: `${namespace}.firmwareVersion`,
    description: 'Firmware version',
    defaultMessage: 'Firmware version'
  },
  deviceConfiguration: {
    id: `${namespace}.deviceConfiguration`,
    description: 'Device configuration',
    defaultMessage: 'Device configuration'
  },
  deviceConfigurationVersion: {
    id: `${namespace}.deviceConfigurationVersion`,
    description: 'Device configuration version',
    defaultMessage: 'Device configuration version'
  },
  selectColumns: {
    id: `${namespace}.selectColumns`,
    description: 'Select columns',
    defaultMessage: 'Select columns'
  },
  showHideColumns: {
    id: `${namespace}.showHideColumns`,
    description: 'Show/hide columns',
    defaultMessage: 'Show/hide columns'
  },
  eid: {
    id: `${namespace}.eid`,
    description: 'Eid',
    defaultMessage: 'Eid'
  }
})

export default messages
