import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'

import Alert from 'components/Alert'

import { getRoleEditUrl } from '../url'
import { momentDate } from '../utils'

import messages from './messages'

const styles = {
  dialog: {
    minWidth: 600
  },
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid #dadde9',
    fontSize: 14
  }
}

class TreeListRoles extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage },
      classes
    } = props
    this.formatMessage = formatMessage
    this.classes = classes

    this.state = {
      dialogOpened: false,
      loading: false,
      alertMessages: false,
      alertMessagesType: '',
      alertMessagesTitle: '',
      alertMessagesText: ['']
    }
  }

  handleClick = event => {
    event.preventDefault()
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()

    this.setState({
      dialogOpened: true
    })
  }

  handleDeleteClick = () => {
    const { deleteGroupRole, groupId, hashId, deleteRole } = this.props
    this.setState(
      {
        loading: true
      },
      () => {
        deleteGroupRole(groupId, hashId)
          .then(() => {
            this.setState(
              {
                dialogOpened: false
              },
              deleteRole()
            )
          })
          .catch(response => {
            const error = response.error
            if (error.response) {
              switch (error.response.status) {
                case 400:
                  let errorMessage = ''
                  if (typeof error.response.data === 'string') errorMessage = error.response.data
                  else if (error.response.data) errorMessage = error.response.data.message
                  if (errorMessage && errorMessage.includes('THE ROLE IS CURRENTLY ASSIGNED TO USERS IN THIS GROUP')) {
                    this.setState({
                      loading: false,
                      alertMessages: true,
                      alertMessagesType: 'danger',
                      alertMessagesTitle: '',
                      alertMessagesText: [this.formatMessage(messages.cannotDeleteRole)]
                    })
                  } else {
                    this.setState({
                      loading: false,
                      alertMessages: true,
                      alertMessagesType: 'danger',
                      alertMessagesTitle: this.formatMessage(messages.error, { number: error.response.status }),
                      alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
                    })
                  }
                  break
                default:
                  this.setState({
                    loading: false,
                    alertMessages: true,
                    alertMessagesType: 'danger',
                    alertMessagesTitle: this.formatMessage(messages.error, { number: error.response.status }),
                    alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
                  })
              }
            } else {
              this.setState({
                loading: false,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, { number: error.response.status }),
                alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
              })
            }
          })
      }
    )
  }

  handleResetDialogState = () => {
    this.setState({
      loading: false,
      alertMessages: false,
      alertMessagesType: '',
      alertMessagesTitle: '',
      alertMessagesText: ['']
    })
  }

  render() {
    const {
      actionsTitle,
      canDeleteRoles,
      canEditRoles,
      class: className,
      createdDate,
      description,
      hashId,
      lastModifiedDate,
      name,
      userRole,
      usersCount
    } = this.props
    const { alertMessages, alertMessagesText, alertMessagesTitle, alertMessagesType, dialogOpened, loading } =
      this.state

    let createdDateData
    let lastModifiedDateData

    if (typeof createdDate === 'string') {
      createdDateData = createdDate
    } else {
      createdDateData = momentDate(createdDate)
    }
    if (typeof lastModifiedDate === 'string') {
      lastModifiedDateData = lastModifiedDate
    } else {
      lastModifiedDateData = momentDate(lastModifiedDate)
    }

    if (name === userRole) {
      return (
        <div className={className}>
          <div className='row'>
            <div className='col-xs-8'>
              {description} <Chip className='miChip' label={usersCount} style={{ height: 'fit-content' }} />
            </div>
            <div className='col-xs-3'>
              <div className='row'>
                <div className='col-xs-6 text-right'>{createdDateData}</div>
                <div className='col-xs-6 text-right'>{lastModifiedDateData}</div>
              </div>
            </div>
          </div>
        </div>
      )
    }

    const deletingButtonDisabled = loading || alertMessages

    return (
      <div className={className}>
        <div className='row'>
          <div className='col-xs-8'>{description}</div>
          <div className='col-xs-3'>
            <div className='row'>
              <div className='col-xs-6 text-right'>{createdDate}</div>
              <div className='col-xs-6 text-right'>{lastModifiedDate}</div>
            </div>
          </div>
          {actionsTitle === '' ? (
            <div className='col-xs-1 text-center'>
              <span>
                {canEditRoles && (
                  <Link className='button-link' to={getRoleEditUrl(hashId)}>
                    <Tooltip classes={{ tooltip: this.classes.tooltip }} title={this.formatMessage(messages.editRole)}>
                      <div style={{ padding: 0, display: '-webkit-inline-box' }}>
                        <IconButton style={{ padding: '5px' }}>
                          <EditIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  </Link>
                )}
                {canDeleteRoles && (
                  <Tooltip classes={{ tooltip: this.classes.tooltip }} title={this.formatMessage(messages.deleteRole)}>
                    <div style={{ padding: 0, display: '-webkit-inline-box' }}>
                      <IconButton onClick={this.handleClick} style={{ padding: '5px' }}>
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </Tooltip>
                )}
                {canDeleteRoles && (
                  <Dialog
                    disableBackdropClick
                    fullWidth
                    maxWidth='md'
                    onExited={this.handleResetDialogState}
                    open={dialogOpened}
                  >
                    <DialogTitle>
                      {this.formatMessage(messages.dialogTitle, { roleDescription: description })}
                    </DialogTitle>
                    <DialogContent>
                      {alertMessages && (
                        <Alert
                          alertType={alertMessagesType}
                          messageText={alertMessagesText}
                          messageTitle={alertMessagesTitle}
                          showAlert={alertMessages}
                        />
                      )}
                    </DialogContent>
                    <DialogActions>
                      <Button
                        className='cancel-button'
                        onClick={() => {
                          this.setState({ dialogOpened: false })
                        }}
                      >
                        {this.formatMessage(messages.cancel)}
                      </Button>
                      <Button
                        className='delete-button'
                        disabled={deletingButtonDisabled}
                        onClick={this.handleDeleteClick}
                      >
                        <span>{this.formatMessage(messages.delete)}</span>
                        {loading && <CircularProgress size={24} style={{ position: 'absolute' }} />}
                      </Button>
                    </DialogActions>
                  </Dialog>
                )}
              </span>
            </div>
          ) : (
            <div className='col-xs-1 text-center'>
              <span>{actionsTitle}</span>
            </div>
          )}
        </div>
      </div>
    )
  }
}

TreeListRoles.propTypes = {
  actionsTitle: PropTypes.string,
  canDeleteRoles: PropTypes.bool,
  canEditRoles: PropTypes.bool,
  class: PropTypes.string,
  classes: PropTypes.object.isRequired,
  createdDate: PropTypes.node,
  deleteGroupRole: PropTypes.func.isRequired,
  deleteRole: PropTypes.func.isRequired,
  description: PropTypes.string,
  groupId: PropTypes.string,
  hashId: PropTypes.string,
  intl: PropTypes.object.isRequired,
  lastModifiedDate: PropTypes.node,
  name: PropTypes.string,
  userRole: PropTypes.string.isRequired,
  usersCount: PropTypes.node
}

TreeListRoles.defaultProps = {
  actionsTitle: '',
  canDeleteRoles: false,
  canEditRoles: false,
  class: '',
  createdDate: '',
  description: '',
  groupId: '',
  hashId: '',
  lastModifiedDate: '',
  name: '',
  usersCount: 0
}

export default withStyles(styles)(injectIntl(TreeListRoles))
