import { defineMessages } from 'react-intl'

const messages = defineMessages({
  metadata: {
    id: 'ViewConfigurationCS500.metadata',
    description: 'Metadata text',
    defaultMessage: 'Metadata'
  },
  nvConfiguration: {
    id: 'ViewConfigurationCS500.nvConfiguration',
    description: 'Configuration text',
    defaultMessage: 'Configuration'
  },
  canMessages: {
    id: 'ViewConfigurationCS500.canMessages',
    description: 'CAN messages',
    defaultMessage: 'Send CAN messages'
  },
  downloadMetadata: {
    id: 'ViewConfigurationCS500.downloadMetadata',
    description: 'Download metadata button label',
    defaultMessage: 'Download metadata'
  },
  downloadNVConfiguration: {
    id: 'ViewConfigurationCS500.downloadNVConfiguration',
    description: 'Download configuration button label',
    defaultMessage: 'Download configuration'
  },
  download: {
    id: 'ViewConfigurationCS500.download',
    description: 'Download button label',
    defaultMessage: 'Download'
  },
  formatted: {
    id: 'ViewConfigurationCS500.formatted',
    description: 'Formatted switch label',
    defaultMessage: 'Formatted'
  },
  raw: {
    id: 'ViewConfigurationCS500.raw',
    description: 'Raw switch label',
    defaultMessage: 'Raw'
  },
  cancel: {
    id: 'ViewConfigurationCS500.cancel',
    description: 'Cancel button label',
    defaultMessage: 'Cancel'
  },
  generalSettings: {
    id: 'ViewConfigurationCS500.generalSettings',
    description: 'General settings card title',
    defaultMessage: 'Device settings'
  },
  gpsConditions: {
    id: 'ViewConfigurationCS500.gpsConditions',
    description: 'GNSS conditions card title',
    defaultMessage: 'GNSS Conditions'
  },
  geofenceConditions: {
    id: 'ViewConfigurationCS500.geofenceConditions',
    description: 'Geofence conditions card title',
    defaultMessage: 'Geofence Conditions'
  },
  enableGeofence: {
    id: 'ViewConfigurationCS500.enableGeofence',
    description: 'Geofence enabled',
    defaultMessage: 'Geofence enabled'
  },
  latitude: {
    id: 'ViewConfigurationCS500.latitude',
    description: 'Latitude / 0.0000001°',
    defaultMessage: 'Latitude / 0.0000001°'
  },
  longitude: {
    id: 'ViewConfigurationCS500.longitude',
    description: 'Longitude / 0.0000001°',
    defaultMessage: 'Longitude / 0.0000001°'
  },
  radius: {
    id: 'ViewConfigurationCS500.radius',
    description: 'radius',
    defaultMessage: 'Radius (m)'
  },
  geofenceLogToPortal: {
    id: 'ViewConfigurationCS500.geofenceLogToPortal',
    description: 'Log to portal',
    defaultMessage: 'Log to portal'
  },
  geofenceSamplePeriod: {
    id: 'ViewConfigurationCS500.geofenceSamplePeriod',
    description: 'Sample period',
    defaultMessage: 'Sample period (s)'
  },
  geofenceSendImmediately: {
    id: 'ViewConfigurationCS500.geofenceSendImmediately',
    description: 'Send immediately',
    defaultMessage: 'Send immediately'
  },
  loopTime: {
    id: 'ViewConfigurationCS500.loopTime',
    description: 'Loop time (ms)',
    defaultMessage: 'Loop time (ms)'
  },
  nodeId0: {
    id: 'ViewConfigurationCS500.nodeId0',
    description: 'CAN port 0 node ID input label',
    defaultMessage: 'CAN port 0 node ID'
  },
  nodeId1: {
    id: 'ViewConfigurationCS500.nodeId1',
    description: 'CAN port 1 node ID input label',
    defaultMessage: 'CAN port 1 node ID'
  },
  baudRate0: {
    id: 'ViewConfigurationCS500.baudRate0',
    description: 'CAN port 0 baud rate select label',
    defaultMessage: 'CAN port 0 baud rate (kbps)'
  },
  baudRate1: {
    id: 'ViewConfigurationCS500.baudRate1',
    description: 'CAN port 1 baud rate select label',
    defaultMessage: 'CAN port 1 baud rate (kbps)'
  },
  sa0: {
    id: 'ViewConfigurationCS500.sa0',
    description: 'CAN port 0 source address input label',
    defaultMessage: 'CAN port 0 source address'
  },
  sa1: {
    id: 'ViewConfigurationCS500.sa1',
    description: 'CAN port 1 source address input label',
    defaultMessage: 'CAN port 1 source address'
  },
  loggingUploadPeriod0: {
    id: 'ViewConfigurationCS500.loggingUploadPeriod0',
    description: 'Cloud buffer 0 upload period input label',
    defaultMessage: 'Cloud buffer 0 upload period (s)'
  },
  loggingUploadPeriod1: {
    id: 'ViewConfigurationCS500.loggingUploadPeriod1',
    description: 'Cloud buffer 1 upload period input label',
    defaultMessage: 'Cloud buffer 1 upload period (s)'
  },
  interlinkEnabled: {
    id: 'ViewConfigurationCS500.interlinkEnabled',
    description: 'Enable PLUS+1 Interlink proxy connection',
    defaultMessage: 'Enable PLUS+1 Interlink proxy connection'
  },
  fotaUpdateEnabled: {
    id: 'ViewConfigurationCS500.fotaUpdateEnabled',
    description: 'Enable FOTA update',
    defaultMessage: 'Enable FOTA update'
  },
  dm1Settings: {
    id: 'ViewConfigurationCS500.dm1Settings',
    description: 'DM1 settings paper title',
    defaultMessage: 'DM1 settings'
  },
  dm1LogToPortal: {
    id: 'ViewConfigurationCS500.dm1LogToPortal',
    description: 'DM1 log to portal',
    defaultMessage: 'DM1 log to portal'
  },
  dm1Port: {
    id: 'ViewConfigurationCS500.dm1Port',
    description: 'DM1 port',
    defaultMessage: 'DM1 port'
  },
  dm1UseSA: {
    id: 'ViewConfigurationCS500.dm1UseSA',
    description: 'Use SA',
    defaultMessage: 'Use source address'
  },
  dm1SA: {
    id: 'ViewConfigurationCS500.dm1SA',
    description: 'Source address',
    defaultMessage: 'Source address'
  },
  virtualSignals: {
    id: 'ViewConfigurationCS500.virtualSignals',
    description: 'Virtual signals paper title',
    defaultMessage: 'Virtual signals'
  },
  sendGPSCoordinates: {
    id: 'ViewConfigurationCS500.sendGPSCoordinates',
    description: 'sendGPSCoordinates checkbox label',
    defaultMessage: 'Send GNSS coordinates'
  },
  error400Message: {
    id: 'ViewConfigurationCS500.error400Message',
    description: 'Error 400 message',
    defaultMessage: 'Bad request'
  },
  error403Message: {
    id: 'ViewConfigurationCS500.error403Message',
    description: 'Error 403 message',
    defaultMessage: 'Access denied'
  },
  error404Message: {
    id: 'ViewConfigurationCS500.error404Message',
    description: 'Error 404 message',
    defaultMessage: 'API url not found.'
  },
  error406Message: {
    id: 'ViewConfigurationCS500.error406Message',
    description: 'Error 406 message',
    defaultMessage: 'Not acceptable'
  },
  errorUndefinedTitle: {
    id: 'ViewConfigurationCS500.errorUndefinedTitle',
    description: 'Error undefined title',
    defaultMessage: 'Error undefined'
  },
  errorUndefinedMessage: {
    id: 'ViewConfigurationCS500.errorUndefinedMessage',
    description: 'Error undefined message',
    defaultMessage: 'No description available.'
  },
  error409Message: {
    id: 'ViewConfigurationCS500.error409Message',
    description: 'Error 409 message',
    defaultMessage: 'Data integrity violation: Configuration name it\'s already in use'
  },
  error415Message: {
    id: 'ViewConfigurationCS500.error415Message',
    description: 'Error 415 message',
    defaultMessage: 'Unsupported media type'
  },
  error422Message: {
    id: 'ViewConfigurationCS500.error422Message',
    description: 'Error 422 message',
    defaultMessage: 'Validation failed'
  },
  error: {
    id: 'ViewConfigurationCS500.error',
    description: 'error text',
    defaultMessage: 'Error {number}'
  },
  error401Message: {
    id: 'ViewConfigurationCS500.error401Message',
    description: 'Error 401 message',
    defaultMessage: 'Invalid credentials'
  },
  positionLogTime: {
    id: 'ViewConfigurationCS500.positionLogTime',
    description: 'positionLogTime input label',
    defaultMessage: 'Position log time (s)'
  },
  altitudeLogTime: {
    id: 'ViewConfigurationCS500.altitudeLogTime',
    description: 'altitudeLogTime input label',
    defaultMessage: 'Altitude log time (s)'
  },
  speedLogTime: {
    id: 'ViewConfigurationCS500.speedLogTime',
    description: 'speedLogTime input label',
    defaultMessage: 'Speed log time (s)'
  },
  headingLogTime: {
    id: 'ViewConfigurationCS500.headingLogTime',
    description: 'headingLogTime input label',
    defaultMessage: 'Heading log time (s)'
  },
  true: {
    id: 'ViewConfigurationCS500.true',
    description: 'True',
    defaultMessage: 'True'
  },
  false: {
    id: 'ViewConfigurationCS500.false',
    description: 'False',
    defaultMessage: 'False'
  },
  localTimezone: {
    id: 'ViewConfigurationCS500.localTimezone',
    description: 'Local timezone',
    defaultMessage: 'Local timezone'
  }
})

export default messages
