import React, { Component } from 'react'
import PropTypes from 'prop-types'

import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { withStyles } from '@material-ui/core/styles'

import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'

import * as yup from 'yup'

import { client, logError } from 'utils/http'

import { IMPORT_STATUS, MAX_FILE_SIZE } from './constants'
import messages from './messages'

const styles = {
  filesDropzone: {
    width: '100%',
    border: '3px dashed #cccccc',
    padding: 20
  },
  filesDropzoneActive: {
    backgroundColor: '#cccccc',
    color: '#ffffff'
  },
  warningAlert: {
    marginBottom: 20
  },
  action: {
    alignItems: 'flex-start'
  }
}

class ImportAdvancedSignals extends Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      alertSeverity: '',
      alertMessage: '',
      fileName: '',
      fileContent: [],
      importStatus: IMPORT_STATUS.PENDING,
      isWarningAlertVisible: true
    }

    this.dropzoneRef = React.createRef()
    this.inputRef = React.createRef()
  }

  handleFileChange = event => {
    event.preventDefault()
    const [file] = event.dataTransfer ? event.dataTransfer.files : event.target.files
    document.getElementById('advanced-signals-config-input').value = null
    let alertMessage = ''
    if (file.type !== 'application/json' || !file.name.endsWith('.json')) {
      alertMessage = this.formatMessage(messages.isNotJsonError, { name: file.name })
    } else if (file.size > MAX_FILE_SIZE) {
      alertMessage = this.formatMessage(messages.exceedsMaximumError, { name: file.name })
    }

    if (alertMessage === '') {
      const reader = new FileReader()
      reader.readAsText(file)
      reader.onload = () => {
        const fileContent = JSON.parse(reader.result)
        if (Array.isArray(fileContent)) {
          this.setState({
            fileName: file.name,
            fileContent
          })
        } else {
          alertMessage = this.formatMessage(messages.contentNotValid, { name: file.name })
          this.setState({
            alertSeverity: 'error',
            alertMessage,
            fileName: '',
            fileContent: []
          })
        }
      }
    } else {
      this.setState({
        alertSeverity: 'error',
        alertMessage,
        fileName: '',
        fileContent: []
      })
    }
  }

  handleDropzoneClick = () => {
    this.inputRef.current.click()
  }

  handleDropzoneDrop = event => {
    this.handleFilesChange(event)
  }

  handleDragEnter = () => {
    const { classes } = this.props
    const dropZone = this.dropzoneRef.current
    dropZone.classList.add(classes.filesDropzoneActive)
  }

  handleDragLeave = () => {
    const { classes } = this.props
    const dropZone = this.dropzoneRef.current
    dropZone.classList.remove(classes.filesDropzoneActive)
  }

  handleDragOver = event => {
    event.preventDefault()
    event.stopPropagation()
  }

  handleRemoveFile = () => {
    this.setState({
      fileName: '',
      fileContent: []
    })
  }

  handleImport = () => {
    this.setState(
      {
        importStatus: IMPORT_STATUS.IMPORTING
      },
      async () => {
        const { csNodes, groupId } = this.props
        const { fileContent } = this.state

        const validAdvancedSignals = await this.getValidAdvancedSignals(fileContent)
        const importingSignals = csNodes.reduce((acc, device) => {
          const { deviceConfigurationId, EID: deviceEid, deviceType, name: deviceName } = device
          const measurementDevices = [{ deviceEid, deviceType, deviceName }]
          const advancedSignalsWithDeviceData = validAdvancedSignals.map(advancedSignal => ({
            ...advancedSignal,
            deviceConfigurationId,
            measurementDevices
          }))
          return [...acc, ...advancedSignalsWithDeviceData]
        }, [])

        let alertSeverity = ''
        let alertMessage = ''

        try {
          await client.importMeasurements(groupId, importingSignals)
          alertSeverity = 'success'
          alertMessage = this.formatMessage(messages.importSuccess)
        } catch (error) {
          alertSeverity = 'error'
          alertMessage = this.formatMessage(messages.importError)
          logError(error)
        }

        this.setState({
          alertSeverity,
          alertMessage,
          fileName: '',
          fileContent: [],
          importStatus: IMPORT_STATUS.DONE
        })
      }
    )
  }

  getValidAdvancedSignals = async fileContent => {
    const schema = yup.object().shape({
      measurementName: yup.string().required(),
      measurementUnit: yup.string().required(),
      operationType: yup.string().test('Digits only', 'Digits only', value => !isNaN(value)),
      signalGaps: yup.number().required(),
      formula: yup.string().required(),
      measurementSignals: yup.array(
        yup.object().shape({
          targetSignal: yup.string().required(),
          placeholder: yup.string().required()
        })
      )
    })

    const advancedSignalResults = await Promise.allSettled(
      fileContent.map(advancedSignal => schema.validate(advancedSignal, { stripUnknown: true }))
    )
    return advancedSignalResults.filter(result => result.status === 'fulfilled').map(result => result.value)
  }

  closeAlert = () => {
    this.setState({
      alertSeverity: '',
      alertMessage: ''
    })
  }

  render() {
    const { handleClose, classes } = this.props
    const { alertSeverity, alertMessage, importStatus, fileName, isWarningAlertVisible } = this.state

    const disabled = alertSeverity !== '' || importStatus !== IMPORT_STATUS.PENDING

    return (
      <React.Fragment>
        <DialogTitle id='alert-dialog-slide-title'>
          {this.formatMessage(messages.importAdvancedSignals)}
          <IconButton
            onClick={handleClose}
            style={{
              position: 'absolute',
              right: 3,
              top: 3,
              padding: 5
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {isWarningAlertVisible && (
            <Alert
              classes={{ root: classes.warningAlert, action: classes.action }}
              onClose={() => {
                this.setState({ isWarningAlertVisible: false })
              }}
              severity='warning'
            >
              <AlertTitle>{this.formatMessage(messages.warningAlertTitle)}</AlertTitle>
              <ul>
                <li>
                  {this.formatMessage(messages.sameConfigDevices, {
                    sameConfig: <strong>{this.formatMessage(messages.sameConfig)}</strong>
                  })}
                </li>
                <li>
                  {this.formatMessage(messages.importWarning, {
                    replaced: <strong>{this.formatMessage(messages.replaced)}</strong>
                  })}
                </li>
              </ul>
            </Alert>
          )}
          {alertSeverity !== '' ? (
            <Alert
              onClose={
                alertSeverity === 'error' && importStatus === IMPORT_STATUS.PENDING ? this.closeAlert : undefined
              }
              severity={alertSeverity}
            >
              {alertMessage}
            </Alert>
          ) : fileName === '' ? (
            <React.Fragment>
              <input
                ref={this.inputRef}
                accept='.json'
                id='advanced-signals-config-input'
                multiple
                onChange={this.handleFileChange}
                style={{ display: 'none' }}
                type='file'
              />
              <div
                ref={this.dropzoneRef}
                className={classes.filesDropzone}
                id='dropzone'
                onClick={this.handleDropzoneClick}
                onDragEnter={this.handleDragEnter}
                onDragLeave={this.handleDragLeave}
                onDragOver={this.handleDragOver}
                onDrop={this.handleFileChange}
              >
                {this.formatMessage(messages.dropFilesHereOrClickToUpload)}
              </div>
            </React.Fragment>
          ) : (
            <div style={{ padding: 9 }}>
              <span>{fileName}</span>
              <IconButton onClick={this.handleRemoveFile}>
                <CloseIcon />
              </IconButton>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button className='primary-action-button' disabled={disabled} onClick={this.handleImport}>
            {this.formatMessage(messages.import)}
          </Button>
        </DialogActions>
      </React.Fragment>
    )
  }
}

ImportAdvancedSignals.propTypes = {
  classes: PropTypes.object.isRequired,
  csNodes: PropTypes.array.isRequired,
  groupId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired
}

export default withStyles(styles)(ImportAdvancedSignals)
