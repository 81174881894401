import PropTypes from 'prop-types'
import React from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import Alert from 'components/Alert'
import DateLabel from 'components/DateLabel'
import SelectColumnsButton from 'components/SelectColumnsButton'
import { logError, mapDynamicDevicesFilterObject, getFiltersMatchingDevices } from 'utils/http'
import { utcTimeToBrowserLocal } from 'utils/timeFormat'

import { mapDevicesToTypeGroupedDevices } from '../../utils'
import messages from '../messages'

class EditGroupMachines extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage
    let alertMessages = false
    let alertMessagesType = ''
    let alertMessagesTitle = ''
    let alertMessagesText = ['']

    switch (props.urlAction) {
      case 'update':
        alertMessages = true
        alertMessagesType = 'success'
        alertMessagesTitle = ''
        alertMessagesText = [this.formatMessage(messages.newMachinesAssigned)]
        break
      default:
        alertMessages = false
        alertMessagesType = ''
        alertMessagesTitle = ''
        alertMessagesText = ['']
    }

    this.state = {
      groupNodes: [],

      dialogOpen: false,
      dialogSelectedNodeToDeleteTitle: '',
      dialogSelectedNodeToDeleteId: '',

      dialogOpenBulk: false,
      dialogSelectedNodeToDeleteBulkTitle: '',

      alertMessages,
      alertMessagesType,
      alertMessagesTitle,
      alertMessagesText,

      filter: {},
      sort: {},

      count: 0,
      length: 10,
      elementsPerPage: [
        {
          text: '10',
          value: 10
        },
        {
          text: '20',
          value: 20
        },
        {
          text: '50',
          value: 50
        },
        {
          text: '100',
          value: 100
        }
      ],
      page: 1,
      start: 0,
      selectedNodes: [],
      accumulatedNodes: [],
      noDataText: <CircularProgress />,

      visibleColumns: {
        name: true,
        machineModel: true,
        machineType: true,
        machineBrand: false,
        machineSerialNumber: true,
        deviceType: true,
        eid: false,
        lastActivity: false,
        firmwareVersion: false,
        deviceConfiguration: true,
        deviceConfigurationVersion: false
      }
    }
  }

  componentDidMount() {
    const { group } = this.props
    if (group) this.getNodes()
  }

  getNodes = () => {
    this.setState({
      noDataText: <CircularProgress />
    })
    const { getGroupDevices, group } = this.props
    const { length, start, accumulatedNodes, filter, sort, selectedNodes } = this.state
    let selectedNodeIds = [...selectedNodes]

    const getGroupNodesPaginated = getGroupDevices
    const deviceFields = {
      Device: [
        'id',
        'name',
        'device_type',
        'eid',
        'operating_time',
        'main_firmware_version',
        'created_at',
        'machine_model',
        'machine_type',
        'machine_brand',
        'machine_serial_number'
      ],
      Configuration: ['name', 'version_number', 'id']
    }
    const mappedFilters = mapDynamicDevicesFilterObject(filter, sort)
    const params = [group, length, start, deviceFields, mappedFilters]

    getGroupNodesPaginated(...params)
      .then(response => {
        const accumulatedNodeIds = accumulatedNodes.map(node => node.hashId)
        const data = response.data.devices.map(device => {
          const { id, ...deviceProperties } = device
          return { hashId: id, lastActivityTime: deviceProperties?.operating_time?.lastDateTime, ...deviceProperties }
        })
        const newData = data.filter(node => !accumulatedNodeIds.includes(node.hashId))

        let selectedNodesData = selectedNodes.map(nodeId => accumulatedNodes.find(node => node.eid === nodeId))
        selectedNodesData = selectedNodesData.map(node => {
          const { hashId, ...rest } = node
          return { ...rest, id: hashId }
        })
        const filterMatchingDevices = getFiltersMatchingDevices(filter, selectedNodesData)
        selectedNodeIds = filterMatchingDevices.map(device => device.eid)

        this.setState(({ accumulatedNodes: accNodes }) => ({
          count: response.data.total,
          groupNodes: data,
          accumulatedNodes: accNodes.concat(newData),
          noDataText: this.formatMessage(messages.noDataText),
          selectedNodes: selectedNodeIds
        }))
      })
      .catch(response => {
        const error = response.error

        switch (error.response.status) {
          case 400: // Bad request
          case 401: // Invalid credentials
          case 403: // Access denied
            this.setState({
              noDataText: this.formatMessage(messages.errorRequestingMachines)
            })
            break
          case 404: // API url not found
            const errorReceived =
              typeof error.response.data === 'string' ? error.response.data : error.response.data.message
            if (
              errorReceived.includes('NO DEVICE IS ASSOCIATED TO THIS USER IN THIS GROUP') ||
              errorReceived.includes('NO DEVICE EXISTS')
            ) {
              this.setState({
                count: 0,
                groupNodes: [],
                noDataText: this.formatMessage(messages.noDataText)
              })
            }
            break
          case 406: // Not acceptable
          case 500: // Unexpected error
            this.setState({
              noDataText: this.formatMessage(messages.errorRequestingMachines)
            })
            break
          default:
            this.setState({
              noDataText: this.formatMessage(messages.errorRequestingMachines)
            })
            logError(response)
        }
      })
  }

  getTableOptions = () => {
    const { elementsPerPage, length, noDataText, page } = this.state

    return {
      noDataText,
      onSizePerPageList: this.handleSizePerPageList,
      sizePerPageList: elementsPerPage,
      sizePerPage: length,
      page,

      onPageChange: this.handlePageChange,
      ignoreSinglePage: false,
      pageStartIndex: 1,
      paginationSize: 5,
      prePage: this.formatMessage(messages.prePage),
      nextPage: this.formatMessage(messages.nextPage),
      firstPage: this.formatMessage(messages.firstPage),
      lastPage: this.formatMessage(messages.lastPage),
      paginationShowsTotal: this.renderPaginationShowsTotal(this.formatMessage),
      paginationPosition: 'bottom',
      hideSizePerPage: false,
      alwaysShowAllBtns: false,
      withFirstAndLast: true,

      onSortChange: this.handleSortChange,
      onFilterChange: this.handleFilterChange
    }
  }

  closeAlert = () => {
    this.setState({
      alertMessages: false,
      alertMessagesType: '',
      alertMessagesTitle: '',
      alertMessagesText: ['']
    })
  }

  deleteNode = hashId => {
    const { groupNodes } = this.state

    const { name: nodeName, eid } = groupNodes.find(groupNode => groupNode.hashId === hashId) || {}
    const nodeTitle = nodeName + ' - ' + eid

    this.setState({
      dialogOpen: true,
      dialogSelectedNodeToDeleteTitle: nodeTitle,
      dialogSelectedNodeToDeleteId: hashId
    })
  }

  handleDeleteNodeBulkClick = () => {
    const { selectedNodes, groupNodes } = this.state
    let nodeTitle

    if (selectedNodes.length > 1) {
      nodeTitle =
        this.formatMessage(messages.unassign) + ' ' + selectedNodes.length + ' ' + this.formatMessage(messages.machines)
    } else {
      const { name: nodeName, eid } = groupNodes.find(groupNode => groupNode.eid === selectedNodes[0]) || {}
      nodeTitle = nodeName + ' - ' + eid
    }

    this.setState({
      dialogOpenBulk: true,
      dialogSelectedNodeToDeleteBulkTitle: nodeTitle
    })
  }

  handleClose = () => {
    this.setState({
      dialogOpen: false,
      dialogSelectedNodeToDeleteTitle: '',
      dialogSelectedNodeToDeleteId: '',
      dialogOpenBulk: false,
      dialogSelectedNodeToDeleteBulkTitle: ''
    })
  }

  dialogBulkActions = () => {
    return [
      <Button key={0} className='cancel-button' onClick={this.handleClose}>
        {this.formatMessage(messages.cancel)}
      </Button>,
      <Button key={1} className='delete-button' onClick={this.handleBulkUnassignClick} style={{ marginLeft: 5 }}>
        {this.formatMessage(messages.unassign)}
      </Button>
    ]
  }

  handleUnassignClick = () => {
    const { dialogSelectedNodeToDeleteId, groupNodes } = this.state

    const nodeToUnassign = groupNodes.find(node => node.hashId === dialogSelectedNodeToDeleteId)

    this.deleteNodes([nodeToUnassign])
  }

  handleBulkUnassignClick = () => {
    const { accumulatedNodes, selectedNodes } = this.state

    const nodesToUnassign = accumulatedNodes.filter(node => selectedNodes.includes(node.eid))

    this.deleteNodes(nodesToUnassign)
  }

  deleteNodes = async nodes => {
    const { group, removeDevicesFromGroup } = this.props

    try {
      const typeGroupedDevices = mapDevicesToTypeGroupedDevices(nodes)
      const typeGroupedDevicesEntries = Object.entries(typeGroupedDevices)
      for (let i = 0; i < typeGroupedDevicesEntries.length; i++) {
        const deviceGroup = typeGroupedDevicesEntries[i]
        await removeDevicesFromGroup(group, deviceGroup[1], deviceGroup[0])
      }

      this.setState(
        {
          dialogOpen: false,
          dialogOpenBulk: false,
          dialogSelectedNodeToDeleteBulkTitle: '',
          selectedNodes: [],
          alertMessages: true,
          alertMessagesType: 'success',
          alertMessagesTitle: '',
          alertMessagesText: [this.formatMessage(messages.machinesUnassignedCorrectly)],
          start: 0,
          page: 1
        },
        () => this.getNodes()
      )
    } catch (response) {
      const error = response.error
      if (error.response) {
        switch (error.response.status) {
          case 400: // Bad request
          case 401: // Invalid credentials
          case 403: // Access denied
          case 404: // API url not found
          case 406: // Not acceptable
          case 500: // Unexpected error
            this.setState({
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: error.response.status }),
              alertMessagesText: [this.formatMessage(messages['error' + error.response.status + 'Message'])]
            })
            break
          default:
            this.setState({
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
              alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
            })
            logError(response)
        }
      } else {
        this.setState({
          alertMessages: true,
          alertMessagesType: 'danger',
          alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
          alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
        })
        logError(response)
      }
    }
  }

  formatDataDetail = cell => {
    return <DateLabel date={cell} />
  }

  formatActionsDetail = cell => {
    return (
      <Button
        className='users-action-buttons'
        onClick={() => {
          this.deleteNode(cell)
        }}
      >
        <Icon className='zmdi zmdi-close-circle-o' style={{ color: '#C00000' }} />
      </Button>
    )
  }

  handleClearSelectedNodesClick = () => {
    this.setState({
      selectedNodes: []
    })
  }

  handlePageChange = (page, sizePerPage) => {
    this.setState(
      {
        start: (page - 1) * sizePerPage,
        page,
        length: sizePerPage,
        groupNodes: [],
        noDataText: <CircularProgress />
      },
      () => {
        this.getNodes()
      }
    )
  }

  handleSizePerPageList = sizePerPage => {
    this.setState(
      {
        length: sizePerPage,
        groupNodes: [],
        noDataText: <CircularProgress />
      },
      () => this.getNodes()
    )
  }

  handleRowSelect = (row, isSelected) => {
    const { selectedNodes } = this.state

    const newSelectedNodes = isSelected ? [...selectedNodes, row.eid] : selectedNodes.filter(item => item !== row.eid)

    this.setState({
      selectedNodes: newSelectedNodes
    })
  }

  handleSelectAll = (isSelected, rows) => {
    const newSelectedNodes = isSelected ? rows.map(item => item.eid) : []

    this.setState({
      selectedNodes: newSelectedNodes
    })
  }

  handleFilterChange = filterObj => {
    this.setState(
      {
        filter: filterObj,
        groupNodes: [],
        noDataText: <CircularProgress />
      },
      () => this.getNodes()
    )
  }

  handleSortChange = (sortName, sortOrder) => {
    if (sortName.includes('.') || sortName === 'lastActivityTime') {
      this.setState({ sort: {} })
    } else {
      this.setState(
        {
          sort: {
            sortColumnKey: sortName,
            sortColumnModel: 'Device',
            sortColumnOrder: sortOrder.toUpperCase()
          },
          noDataText: <CircularProgress />,
          groupNodes: []
        },
        this.getNodes
      )
    }
  }

  handleChangeColumnVisibility = columnId => {
    this.setState(state => {
      const updatedColumnVisibility = !state.visibleColumns[columnId]
      return {
        visibleColumns: {
          ...state.visibleColumns,
          [columnId]: updatedColumnVisibility
        }
      }
    })
  }

  formatLastActivity = operatingTime => {
    return utcTimeToBrowserLocal(operatingTime.lastDateTime)
  }

  formatMachineField = cell => {
    return cell ? cell : '-'
  }

  formatActiveConfigurationColumn = cell => {
    return cell?.name ? cell.name : '-'
  }

  formatActiveConfigurationVersionColumn = cell => {
    return cell?.version_number ? cell.version_number : '-'
  }

  renderPaginationShowsTotal = formatMessage => (start, to, total) =>
    (
      <span>
        {`${formatMessage(messages.showingMachines)} ${start} ${formatMessage(messages.to)} ${to} ${formatMessage(
          messages.of
        )} ${total}`}
      </span>
    )

  render() {
    const { canShareDevices, getAssignMachinesUrl } = this.props
    const {
      alertMessages,
      alertMessagesText,
      alertMessagesTitle,
      alertMessagesType,
      count,
      dialogOpen,
      dialogOpenBulk,
      dialogSelectedNodeToDeleteBulkTitle,
      dialogSelectedNodeToDeleteTitle,
      groupNodes,
      selectedNodes,
      visibleColumns
    } = this.state

    const tableOptions = this.getTableOptions()

    const selectRowProp = {
      mode: 'checkbox',
      clickToSelect: false,
      onSelect: this.handleRowSelect,
      onSelectAll: this.handleSelectAll,
      bgColor: '#f5f5f5',
      selected: selectedNodes
    }

    const deviceTypeFilterOptions = {
      CS100: 'CS100',
      CS500: 'CS500',
      CS10: 'CS10'
    }

    return (
      <div className='container-fluid users'>
        <Grid container spacing={3}>
          {alertMessages && (
            <Grid item xs={12}>
              <Alert
                alertType={alertMessagesType}
                closeFunction={this.closeAlert}
                messageText={alertMessagesText}
                messageTitle={alertMessagesTitle}
                showAlert={alertMessages}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Paper style={{ borderRadius: 0, padding: '20px' }}>
              <Grid container>
                <Grid container item style={{ marginBottom: 35 }} xs={12}>
                  <Grid item xs={6}>
                    <Typography variant='h5'>{this.formatMessage(messages.assignedMachines)}</Typography>
                  </Grid>
                  <Grid container item style={{ justifyContent: 'flex-end' }} xs={6}>
                    <Link
                      className='button-link'
                      style={{ ...!canShareDevices && { cursor: 'default', pointerEvents: 'none' } }}
                      to={getAssignMachinesUrl}
                    >
                      <Button className='primary-action-button' disabled={!canShareDevices} variant='contained'>
                        <Icon className='zmdi zmdi-plus' />
                        {this.formatMessage(messages.assignMachines)}
                      </Button>
                    </Link>
                  </Grid>
                </Grid>
                <Grid container item xs={12}>
                  <Grid container item spacing={1} xs={6}>
                    <Grid item>
                      <Button className='text-button' disabled style={{ padding: 0 }}>
                        {this.formatMessage(messages.selected)} ({selectedNodes.length})
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        className='secondary-action-button'
                        disabled={selectedNodes.length === 0}
                        onClick={this.handleClearSelectedNodesClick}
                      >
                        {this.formatMessage(messages.clearSelection)}
                      </Button>
                    </Grid>
                    <Grid item>
                      <SelectColumnsButton
                        columns={visibleColumns}
                        onChangeColumnVisibility={this.handleChangeColumnVisibility}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item justifyContent='flex-end' xs={6}>
                    <Grid item>
                      <Button
                        className='delete-button'
                        disabled={selectedNodes.length === 0}
                        onClick={this.handleDeleteNodeBulkClick}
                        style={{
                          marginTop: 14,
                          marginLeft: 5
                        }}
                      >
                        <Icon className='zmdi zmdi-close-circle-o' />
                        {this.formatMessage(messages.unassign)}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <div className='table-with-pagination' style={{ paddingBottom: 110 }}>
                    <BootstrapTable
                      bordered={false}
                      condensed={false}
                      data={groupNodes}
                      exportCSV={false}
                      fetchInfo={{ dataTotalSize: count }}
                      hover
                      keyField='eid'
                      multiColumnSearch={false}
                      options={tableOptions}
                      pagination
                      remote={remoteObj => ({
                        ...remoteObj,
                        pagination: true,
                        search: false,
                        sizePerPage: true,
                        sort: false,
                        filter: true
                      })}
                      search={false}
                      searchPlaceholder={this.formatMessage(messages.filterByOrderableColumns)}
                      selectRow={selectRowProp}
                      striped={false}
                    >
                      <TableHeaderColumn
                        dataField='name'
                        dataSort
                        filter={{
                          type: 'TextFilter',
                          delay: 400,
                          placeholder: `${this.formatMessage(messages.placeholder, {
                            column: this.formatMessage(messages.name).toLowerCase()
                          })}`
                        }}
                        hidden={!visibleColumns.name}
                      >
                        {this.formatMessage(messages.name)}
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        dataField='machine_model'
                        dataFormat={this.formatMachineField}
                        dataSort
                        filter={{
                          type: 'TextFilter',
                          delay: 400,
                          placeholder: `${this.formatMessage(messages.placeholder, {
                            column: this.formatMessage(messages.machineModel).toLowerCase()
                          })}`
                        }}
                        hidden={!visibleColumns.machineModel}
                      >
                        {this.formatMessage(messages.machineModel)}
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        dataField='machine_type'
                        dataFormat={this.formatMachineField}
                        dataSort
                        filter={{
                          type: 'TextFilter',
                          delay: 400,
                          placeholder: `${this.formatMessage(messages.placeholder, {
                            column: this.formatMessage(messages.machineType).toLowerCase()
                          })}`
                        }}
                        hidden={!visibleColumns.machineType}
                      >
                        {this.formatMessage(messages.machineType)}
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        dataField='machine_brand'
                        dataFormat={this.formatMachineField}
                        dataSort
                        filter={{
                          type: 'TextFilter',
                          delay: 400,
                          placeholder: `${this.formatMessage(messages.placeholder, {
                            column: this.formatMessage(messages.machineBrand).toLowerCase()
                          })}`
                        }}
                        hidden={!visibleColumns.machineBrand}
                      >
                        {this.formatMessage(messages.machineBrand)}
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        dataField='machine_serial_number'
                        dataFormat={this.formatMachineField}
                        dataSort
                        filter={{
                          type: 'TextFilter',
                          delay: 400,
                          placeholder: `${this.formatMessage(messages.placeholder, {
                            column: this.formatMessage(messages.machineSerialNumber).toLowerCase()
                          })}`
                        }}
                        hidden={!visibleColumns.machineSerialNumber}
                      >
                        {this.formatMessage(messages.machineSerialNumber)}
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        dataField='device_type'
                        dataSort
                        filter={{
                          type: 'SelectFilter',
                          delay: 400,
                          options: deviceTypeFilterOptions,
                          placeholder: `${this.formatMessage(messages.placeholder, {
                            column: this.formatMessage(messages.deviceType).toLowerCase()
                          })}`
                        }}
                        hidden={!visibleColumns.deviceType}
                      >
                        {this.formatMessage(messages.deviceType)}
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        dataField='eid'
                        dataSort
                        filter={{
                          type: 'TextFilter',
                          delay: 400,
                          placeholder: `${this.formatMessage(messages.placeholder, {
                            column: this.formatMessage(messages.EID).toLowerCase()
                          })}`
                        }}
                        hidden={!visibleColumns.eid}
                      >
                        {this.formatMessage(messages.EID).toLowerCase()}
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        dataField='operating_time'
                        dataFormat={this.formatLastActivity}
                        hidden={!visibleColumns.lastActivity}
                      >
                        {this.formatMessage(messages.lastActivity)}
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        dataField='main_firmware_version'
                        dataSort
                        filter={{
                          type: 'TextFilter',
                          delay: 400,
                          placeholder: `${this.formatMessage(messages.placeholder, {
                            column: this.formatMessage(messages.firmwareVersion).toLowerCase()
                          })}`
                        }}
                        hidden={!visibleColumns.firmwareVersion}
                      >
                        {this.formatMessage(messages.firmwareVersion)}
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        dataField='Configuration'
                        dataFormat={this.formatActiveConfigurationColumn}
                        filter={{
                          type: 'TextFilter',
                          delay: 400,
                          placeholder: `${this.formatMessage(messages.placeholder, {
                            column: this.formatMessage(messages.deviceConfiguration).toLowerCase()
                          })}`
                        }}
                        hidden={!visibleColumns.deviceConfiguration}
                      >
                        {this.formatMessage(messages.deviceConfiguration)}
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        dataField='Configuration'
                        dataFormat={this.formatActiveConfigurationVersionColumn}
                        hidden={!visibleColumns.deviceConfigurationVersion}
                      >
                        {this.formatMessage(messages.deviceConfigurationVersion)}
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        dataAlign='center'
                        dataField='hashId'
                        dataFormat={this.formatActionsDetail}
                        headerAlign='center'
                        width='150'
                      >
                        {this.formatMessage(messages.actions)}
                      </TableHeaderColumn>
                    </BootstrapTable>
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <Dialog fullWidth maxWidth='md' onClose={this.handleClose} open={dialogOpen}>
          <DialogTitle>{this.formatMessage(messages.confirmMachineUnassignament)}</DialogTitle>
          <DialogContent>{dialogSelectedNodeToDeleteTitle}</DialogContent>
          <DialogActions>
            <Button className='cancel-button' onClick={this.handleClose}>
              {this.formatMessage(messages.cancel)}
            </Button>
            <Button className='delete-button' onClick={this.handleUnassignClick} style={{ marginLeft: 5 }}>
              {this.formatMessage(messages.unassign)}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog fullWidth maxWidth='md' onClose={this.handleClose} open={dialogOpenBulk}>
          <DialogTitle>{this.formatMessage(messages.confirmMachineUnassignament)}</DialogTitle>
          <DialogContent>{dialogSelectedNodeToDeleteBulkTitle}</DialogContent>
          <DialogActions>
            <Button className='cancel-button' onClick={this.handleClose}>
              {this.formatMessage(messages.cancel)}
            </Button>
            <Button className='delete-button' onClick={this.handleBulkUnassignClick} style={{ marginLeft: 5 }}>
              {this.formatMessage(messages.unassign)}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

EditGroupMachines.propTypes = {
  canShareDevices: PropTypes.bool.isRequired,
  getAssignMachinesUrl: PropTypes.string.isRequired,
  getGroupDevices: PropTypes.func.isRequired,
  group: PropTypes.string.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired, locale: PropTypes.string.isRequired }).isRequired,
  removeDevicesFromGroup: PropTypes.func.isRequired,
  urlAction: PropTypes.string
}

EditGroupMachines.defaultProps = {
  urlAction: ''
}

export default injectIntl(EditGroupMachines)
