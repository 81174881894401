export const getCSVAdaptedDevicesData = rawDevicesData =>
  rawDevicesData.map(device => ({
    id: device.id,
    name: device.name,
    machineModel: device.machine_model,
    machineType: device.machine_type,
    machineBrand: device.machine_brand,
    machineSerialNumber: device.machine_serial_number,
    deviceType: device.device_type,
    eid: device.eid,
    lastMessageDate: device?.operating_time?.lastDateTime || '',
    firmwareVersion: device.main_firmware_version,
    deviceConfiguration: device?.Configuration?.name || '',
    deviceConfigurationVersion: device?.Configuration?.versionNumber
  }))
