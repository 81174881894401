import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'
import DescriptionIcon from '@material-ui/icons/Description'

import PageTitle from 'components/PageTitle'
import client from 'utils/http/client'

import { getAdaptedInspectionsData, getAdaptedOneMaintenanceData } from '../apiAdapters'
import { getMappedInspectionState } from '../apiMappings'
import ResolveInspectionSuccessAlert from '../components/alerts/ResolveInspectionSuccessAlert'
import ServerErrorAlert from '../components/alerts/ServerErrorAlert'
import InspectionStateChip from '../components/chips/InspectionStateChip'
import NotificationSeverityChip from '../components/chips/NotificationSeverityChip'
import AssignedUsersDetailModal from '../components/modals/AssignedUsersDetailModal'
import ResolveInspectionModal from '../components/modals/ResolveInspectionModal'
import { FREQUENCY_TYPES, INSPECTION_STATES, MAINTENANCE_CONFIGURATION_STEPS } from '../constants'
import { getFormattedFrequency, getFormattedInspection, getFormattedInspectionValue } from '../helpers'
import messages from '../messages'
import { tooltipStyles, iconButtonStyles, linkButtonStyles, paperStyles } from '../styles'
import { getTableOptions } from '../tableHelpers'
import { getMaintenancesUrl, getInspectionHistoryUrl, getEditMaintenanceUrl } from '../urls'

const styles = {
  ...iconButtonStyles,
  ...linkButtonStyles,
  ...paperStyles,
  ...tooltipStyles
}

class MaintenanceDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      maintenanceData: {},
      isMaintenanceLoading: false,
      inspectionsData: [],
      isInspectionsLoading: false,
      isResolveInspectionSuccessAlertShwon: false,

      isApiError: false,

      resolveInspectionModalData: undefined,
      assignedUsersDetailModalData: [],

      currentPage: 1,
      currentPageSize: 10,
      totalSize: 0,
      sort: {},
      filter: {}
    }
  }

  componentDidMount() {
    this.fetchMaintenanceData().then(this.fetchInspectionsData)
  }

  fetchMaintenanceData = () => {
    const {
      groupId,
      match: {
        params: { maintenanceId }
      }
    } = this.props

    this.setState({ isMaintenanceLoading: true, isApiError: false })
    return client
      .getOneMaintenance(groupId, maintenanceId)
      .then(response => {
        const maintenanceData = getAdaptedOneMaintenanceData(response.data)

        this.setState({ maintenanceData })
      })
      .catch(() => {
        this.setState({ isApiError: true })
        return true
      })
      .finally((isApiError = false) => {
        this.setState({ isMaintenanceLoading: false })
        return isApiError
      })
  }

  fetchInspectionsData = (isApiError = false) => {
    const {
      groupId,
      match: {
        params: { maintenanceId }
      }
    } = this.props
    const { currentPage, currentPageSize, maintenanceData, sort, filter } = this.state

    if (isApiError) return

    let sortKey = ''
    switch (sort.columnKey) {
      case 'deviceName':
        sortKey = 'device.deviceName'
        break
      case 'currentValue':
        sortKey = 'calculatedValue'
        break
      case 'nextInspection':
        sortKey =
          maintenanceData.frequencyType === FREQUENCY_TYPES.TIME_BASED ? 'serviceThresholdDate' : 'serviceThreshold'
        break
      case 'lastInspection':
        sortKey =
          maintenanceData.frequencyType === FREQUENCY_TYPES.TIME_BASED
            ? 'lastInspectionResolutionDate'
            : 'lastInspectionResolutionValue'
        break
      case 'state':
        sortKey = 'status'
        break
    }

    const mappedFilter = Object.entries(filter).reduce((acc, [key, value]) => {
      let mappedKey = ''
      let mappedValue = ''
      switch (key) {
        case 'deviceName':
          mappedKey = 'device.deviceName'
          mappedValue = value?.value
          break

        case 'state':
          mappedKey = 'status'
          mappedValue = getMappedInspectionState(value?.value, true)
          break

        default:
          break
      }
      return { ...acc, [mappedKey]: mappedValue }
    }, {})

    this.setState({ isInspectionsLoading: true, isApiError: false })
    client
      .getOneMaintenanceInspectionsLimited({
        groupId,
        maintenanceId,
        page: currentPage,
        size: currentPageSize,
        sort: sortKey,
        order: sort.order,
        filter: mappedFilter
      })
      .then(response => {
        const latestInspectionsData = getAdaptedInspectionsData(response.data, {
          frequencyType: maintenanceData.frequencyType
        })
        const totalSize = Number(response?.headers?.['x-total-count'] || 0)

        const activeAndPendingInspections = latestInspectionsData.filter(
          item => item.state === INSPECTION_STATES.ACTIVE || item.state === INSPECTION_STATES.PENDING
        )

        this.setState({ inspectionsData: activeAndPendingInspections, totalSize })
      })
      .catch(() => {
        this.setState({ isApiError: true })
      })
      .finally(() => {
        this.setState({ isInspectionsLoading: false })
      })
  }

  getTableOptions = () => {
    const { intl } = this.props
    const { isMaintenanceLoading, isInspectionsLoading, currentPage, currentPageSize, sort, filter } = this.state

    return getTableOptions({
      intl,
      isLoading: isMaintenanceLoading || isInspectionsLoading,
      page: currentPage,
      pageSize: currentPageSize,
      sort: {
        sortName: sort.columnKey,
        sortOrder: sort.order
      },
      filter,
      onPageChange: this.handlePageChange,
      onFilterChange: this.handleFilterChange,
      onSortChange: this.handleSortChange
    })
  }

  handleAssignedUsersDetailClick = () => {
    const { maintenanceData } = this.state

    this.setState({ assignedUsersDetailModalData: maintenanceData.assignedUsers })
  }

  handleAssignedUsersDetailModalCloseClick = () => {
    this.setState({ assignedUsersDetailModalData: [] })
  }

  handleAssignedUsersDetailModalEditClick = () => {
    const {
      history,
      match: {
        params: { maintenanceId }
      }
    } = this.props

    this.setState({ assignedUsersDetailModalData: [] })
    history.push(getEditMaintenanceUrl(maintenanceId), { step: MAINTENANCE_CONFIGURATION_STEPS.NOTIFICATIONS_SETTINGS })
  }

  handleResolveInspectionClick = row => {
    const { maintenanceData } = this.state

    const modalData = {
      hashId: row.hashId,
      maintenanceId: maintenanceData.hashId,
      deviceId: row.deviceId,
      deviceName: row.deviceName,
      advancedSignalName: maintenanceData.advancedSignalName,
      advancedSignalUnit: maintenanceData.advancedSignalUnit,
      frequencyValue: maintenanceData.frequencyValue,
      frequencyUnit: maintenanceData.frequencyUnit,
      frequencyType: maintenanceData.frequencyType,
      currentValue: row.currentValue
    }

    this.setState({ resolveInspectionModalData: modalData, isResolveInspectionSuccessAlertShwon: false })
  }

  handleResolveInspectionModalCloseClick = () => {
    this.setState({ resolveInspectionModalData: undefined })
  }

  handleResolveInspectionModalResolveClick = () => {
    this.setState(
      { resolveInspectionModalData: undefined, isResolveInspectionSuccessAlertShwon: true },
      this.fetchInspectionsData
    )
  }

  handleResolveInspectionSuccessAlertCloseClick = () => {
    this.setState({ isResolveInspectionSuccessAlertShwon: false })
  }

  handlePageChange = (page, pageSize) => {
    this.setState({ currentPage: page, currentPageSize: pageSize }, this.fetchInspectionsData)
  }

  handleSortChange = (columnKey, order) => {
    this.setState({ sort: { columnKey, order } }, this.fetchInspectionsData)
  }

  handleFilterChange = filter => {
    this.setState({ filter }, this.fetchInspectionsData)
  }

  formatCurrentValueColumn = cell => {
    const { maintenanceData } = this.state

    return getFormattedInspectionValue({
      inspectionValue: cell,
      advancedSignalUnit: maintenanceData.advancedSignalUnit
    })
  }

  formatInspectionColumn = cell => {
    const { maintenanceData } = this.state

    return getFormattedInspection({
      frequencyType: maintenanceData.frequencyType,
      inspection: cell,
      advancedSignalUnit: maintenanceData.advancedSignalUnit
    })
  }

  formatStateColumn = cell => {
    return <InspectionStateChip state={cell} />
  }

  formatActionsColumn = (cell, row) => {
    const {
      classes,
      intl,
      match: {
        params: { maintenanceId }
      }
    } = this.props
    const { deviceId, state } = row

    return (
      <div>
        {(state === INSPECTION_STATES.ACTIVE || state === INSPECTION_STATES.PENDING) && (
          <Tooltip classes={{ tooltip: classes.tooltip }} title={intl.formatMessage(messages.resolveInspection)}>
            <IconButton
              classes={{ root: classes.iconButtonRoot }}
              onClick={() => this.handleResolveInspectionClick(row)}
            >
              <AssignmentTurnedInIcon />
            </IconButton>
          </Tooltip>
        )}
        <Link className='button-link' to={getInspectionHistoryUrl(maintenanceId, deviceId)}>
          <Tooltip classes={{ tooltip: classes.tooltip }} title={intl.formatMessage(messages.inspectionHistory)}>
            <IconButton classes={{ root: classes.iconButtonRoot }}>
              <DescriptionIcon />
            </IconButton>
          </Tooltip>
        </Link>
      </div>
    )
  }

  render() {
    const {
      classes,
      intl,
      match: {
        params: { maintenanceId }
      }
    } = this.props
    const {
      assignedUsersDetailModalData,
      inspectionsData,
      isApiError,
      isInspectionsLoading,
      isMaintenanceLoading,
      isResolveInspectionSuccessAlertShwon,
      maintenanceData,
      resolveInspectionModalData,
      totalSize
    } = this.state

    const tableOptions = this.getTableOptions()
    const isResolveInspectionModalOpen = Boolean(resolveInspectionModalData)
    const isAssignedUsersDetailModalOpen = Boolean(assignedUsersDetailModalData.length > 0)
    const isLoading = isInspectionsLoading || isMaintenanceLoading

    return (
      <div style={{ margin: '20px 25px 20px 20px' }}>
        <div className='container-fluid'>
          <PageTitle back={getMaintenancesUrl()} title={intl.formatMessage(messages.maintenanceDetail)} />
        </div>
        <div className='row' style={{ margin: '20px 0 100px 0' }}>
          <div className='container-fluid'>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Paper classes={{ root: classes.paperRoot }}>
                  <Grid container spacing={3}>
                    {isApiError && (
                      <Grid item xs={12}>
                        <ServerErrorAlert />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Typography component='h2' variant='h5'>
                        {maintenanceData.name}
                      </Typography>
                    </Grid>
                    <Grid container item spacing={1} xs={6}>
                      <Grid container item spacing={3} xs={12}>
                        <Grid item lg={3} xs={5}>
                          <b>{intl.formatMessage(messages.description)}</b>
                        </Grid>
                        <Grid item lg={9} xs={7}>
                          {maintenanceData.description}
                        </Grid>
                      </Grid>
                      <Grid container item spacing={3} xs={12}>
                        <Grid item lg={3} xs={5}>
                          <b>{intl.formatMessage(messages.frequency)}</b>
                        </Grid>
                        <Grid item lg={9} xs={7}>
                          {typeof maintenanceData.frequencyValue !== 'undefined' &&
                            getFormattedFrequency({
                              intl,
                              frequencyType: maintenanceData.frequencyType,
                              frequencyValue: maintenanceData.frequencyValue,
                              frequencyUnit: maintenanceData.frequencyUnit
                            })}
                        </Grid>
                      </Grid>
                      <Grid container item spacing={3} xs={12}>
                        <Grid item lg={3} xs={5}>
                          <b>{intl.formatMessage(messages.advancedSignal)}</b>
                        </Grid>
                        <Grid item lg={9} xs={7}>
                          {maintenanceData.advancedSignalName}
                        </Grid>
                      </Grid>
                      <Grid container item spacing={3} xs={12}>
                        <Grid item lg={3} xs={5}>
                          <b>{intl.formatMessage(messages.notificationSeverity)}</b>
                        </Grid>
                        <Grid item lg={9} xs={7}>
                          <NotificationSeverityChip severity={maintenanceData.severity || ''} />
                        </Grid>
                      </Grid>
                      <Grid container item spacing={3} xs={12}>
                        <Grid item lg={3} xs={5}>
                          <b>{intl.formatMessage(messages.assignedUsers)}</b>
                        </Grid>
                        <Grid item lg={9} xs={7}>
                          {typeof maintenanceData?.assignedUsers !== 'undefined' && (
                            <span className={classes.linkButton} onClick={this.handleAssignedUsersDetailClick}>
                              {maintenanceData?.assignedUsers?.length}{' '}
                              {intl.formatMessage(messages.nUsers, {
                                users: maintenanceData?.assignedUsers?.length || 0
                              })}
                            </span>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container item justifyContent='flex-end' xs={6}>
                      <Grid item>
                        <Link className='button-link' to={getInspectionHistoryUrl(maintenanceId)}>
                          <Button className='primary-action-button' startIcon={<DescriptionIcon />}>
                            {intl.formatMessage(messages.inspectionHistory)}
                          </Button>
                        </Link>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper classes={{ root: classes.paperRoot }}>
                  <Grid container spacing={3}>
                    {isResolveInspectionSuccessAlertShwon && (
                      <Grid item xs={12}>
                        <ResolveInspectionSuccessAlert
                          onCloseClick={this.handleResolveInspectionSuccessAlertCloseClick}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <div className='table-with-pagination' style={{ padding: 0 }}>
                        <BootstrapTable
                          bordered={false}
                          data={isLoading ? [] : inspectionsData}
                          fetchInfo={{ dataTotalSize: totalSize }}
                          hover
                          keyField='hashId'
                          options={tableOptions}
                          pagination
                          remote={remoteObj => ({
                            ...remoteObj,
                            search: false,
                            pagination: true,
                            sizePerPage: true,
                            sort: false,
                            filter: true
                          })}
                        >
                          <TableHeaderColumn
                            dataField='deviceName'
                            dataSort
                            filter={{
                              type: 'TextFilter',
                              delay: 400,
                              placeholder: `${intl.formatMessage(messages.filterBy)} ${intl
                                .formatMessage(messages.deviceName)
                                .toLowerCase()}`
                            }}
                          >
                            {intl.formatMessage(messages.deviceName)}
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataField='currentValue'
                            dataFormat={this.formatCurrentValueColumn}
                            dataSort
                          >
                            {intl.formatMessage(messages.currentValue)}
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataField='nextInspection'
                            dataFormat={this.formatInspectionColumn}
                            dataSort
                          >
                            {intl.formatMessage(messages.nextInspection)}
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataField='lastInspection'
                            dataFormat={this.formatInspectionColumn}
                            dataSort
                          >
                            {intl.formatMessage(messages.lastInspection)}
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataField='state'
                            dataFormat={this.formatStateColumn}
                            dataSort
                            filter={{
                              type: 'SelectFilter',
                              delay: 400,
                              options: {
                                [INSPECTION_STATES.ACTIVE]: intl.formatMessage(messages.active),
                                [INSPECTION_STATES.PENDING]: intl.formatMessage(messages.pending)
                              },
                              placeholder: `${intl.formatMessage(messages.filterBy)} ${intl
                                .formatMessage(messages.state)
                                .toLowerCase()}`
                            }}
                          >
                            {intl.formatMessage(messages.state)}
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataAlign='center'
                            dataField='actions'
                            dataFormat={this.formatActionsColumn}
                            headerAlign='center'
                            width='180'
                          >
                            {intl.formatMessage(messages.actions)}
                          </TableHeaderColumn>
                        </BootstrapTable>
                      </div>
                    </Grid>
                  </Grid>
                </Paper>
                <AssignedUsersDetailModal
                  assignedUsers={assignedUsersDetailModalData}
                  isOpen={isAssignedUsersDetailModalOpen}
                  onCloseClick={this.handleAssignedUsersDetailModalCloseClick}
                  onEditClick={this.handleAssignedUsersDetailModalEditClick}
                />
                <ResolveInspectionModal
                  data={resolveInspectionModalData}
                  isOpen={isResolveInspectionModalOpen}
                  onCloseClick={this.handleResolveInspectionModalCloseClick}
                  onResolveClick={this.handleResolveInspectionModalResolveClick}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    )
  }
}

MaintenanceDetail.propTypes = {
  classes: PropTypes.object.isRequired,
  groupId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
}

export default withStyles(styles)(injectIntl(MaintenanceDetail))
