import { defineMessages } from 'react-intl'
/* eslint-disable max-len */
const messages = defineMessages({
  byte: {
    id: 'SendCANMessages.byte',
    description: 'Byte',
    defaultMessage: 'Byte {number}'
  },
  canId: {
    id: 'SendCANMessages.canId',
    description: 'CAN ID',
    defaultMessage: 'CAN ID'
  },
  canPort: {
    id: 'SendCANMessages.canPort',
    description: 'CAN port',
    defaultMessage: 'CAN port'
  },
  collapse: {
    id: 'SendCANMessages.collapse',
    description: 'Collapse',
    defaultMessage: 'Collapse'
  },
  copyMessage: {
    id: 'SendCANMessages.copyMessage',
    description: 'Copy message',
    defaultMessage: 'Copy message'
  },
  dataSource: {
    id: 'SendCANMessages.dataSource',
    description: 'Data source',
    defaultMessage: 'Data source'
  },
  dataSourceVirtualSignalMessage: {
    id: 'SendCANMessages.dataSourceVirtualSignalMessage',
    description: 'Data source == GNSS positio message',
    defaultMessage:
      'The physical value of the selected virtual signal gets applied to the data-bytes in the following form: Physical value: Byte3: MB ... Byte0: LB. Divider: Byte7: MB ... Byte4: LB.'
  },
  dataSourceGNSSMessage: {
    id: 'SendCANMessages.dataSourceGNSSMessage',
    description: 'Data source == Select virtual signal message',
    defaultMessage:
      'Position data format: Latitude = Canval * 0.0000001 - 210. Format Canval: Byte3: MB ... Byte0: LB. Longitude = Canval * 0.0000001 - 210. Format Canval: Byte7: MB ... Byte4: LB.'
  },
  deleteMessage: {
    id: 'SendCANMessages.deleteMessage',
    description: 'Delete message',
    defaultMessage: 'Delete message'
  },
  expand: {
    id: 'SendCANMessages.expand',
    description: 'Expand',
    defaultMessage: 'Expand'
  },
  isExtendedCanId: {
    id: 'SendCANMessages.isExtendedCanId',
    description: 'Extended CAN ID',
    defaultMessage: 'Extended CAN ID'
  },
  lowerThreshold: {
    id: 'SendCANMessages.triggerLowerThreshold',
    description: 'Trigger lower threshold',
    defaultMessage: 'Trigger lower threshold'
  },
  message: {
    id: 'SendCANMessages.message',
    description: 'Message',
    defaultMessage: 'Message'
  },
  messageSettings: {
    id: 'SendCANMessages.messageSettings',
    description: 'Message settings',
    defaultMessage: 'Message settings'
  },
  periodType: {
    id: 'SendCANMessages.periodType',
    description: 'Period type',
    defaultMessage: 'Period type'
  },
  periodTypeTriggered: {
    id: 'SendCANMessages.periodTypeTriggered',
    description: 'Trigger period type',
    defaultMessage: 'Trigger period type'
  },
  sendCANMessagesTitle: {
    id: 'SendCANMessages.sendCANMessagesTitle',
    description: 'Send CAN messages title',
    defaultMessage: 'Send CAN messages'
  },
  sendMessage: {
    id: 'SendCANMessages.sendMessage',
    description: 'Send message',
    defaultMessage: 'Send message'
  },
  sendPeriod: {
    id: 'SendCANMessages.sendPeriod',
    description: 'Send period',
    defaultMessage: 'Send period (ms)'
  },
  sendPeriodTriggered: {
    id: 'sendPeriodTriggered.sendPeriod',
    description: 'Trigger send period',
    defaultMessage: 'Trigger send period (ms)'
  },
  transmitTimings: {
    id: 'SendCANMessages.transmitTimings',
    description: 'Transmit timings',
    defaultMessage: 'Transmit timings'
  },
  trigger: {
    id: 'SendCANMessages.trigger',
    description: 'Trigger',
    defaultMessage: 'Trigger'
  },
  triggerSource: {
    id: 'SendCANMessages.triggerSource',
    description: 'Trigger source',
    defaultMessage: 'Trigger source'
  },
  thresholdDivider: {
    id: 'SendCANMessages.triggerThresholdDivider',
    description: 'Trigger threshold divider',
    defaultMessage: 'Trigger threshold divider'
  },
  triggerType: {
    id: 'SendCANMessages.triggerType',
    description: 'Trigger type',
    defaultMessage: 'Trigger type'
  },
  upperThreshold: {
    id: 'SendCANMessages.triggerUpperThreshold',
    description: 'Trigger upper threshold',
    defaultMessage: 'Trigger upper threshold'
  },
  virtualSignalNumber: {
    id: 'SendCANMessages.virtualSignalNumber',
    description: 'Virtual signal number',
    defaultMessage: 'Virtual signal number'
  },
  virtualSignalNumberPlaceholder: {
    id: 'SendCANMessages.virtualSignalNumberPlaceholder',
    description: 'Virtual signal number placeholder',
    defaultMessage: 'Number of the virtual signal [0 - 79]'
  },
  messageSentSuccessfully: {
    id: 'SendCANMessages.messageSentSuccessfully',
    description: 'CAN message sent successfully',
    defaultMessage: 'CAN message sent successfully'
  },
  messageSsendingFailed: {
    id: 'SendCANMessages.messageSsendingFailed',
    description: 'CAN message sending failed',
    defaultMessage: 'CAN message sending failed'
  },
  error: {
    id: 'SendCANMessages.error',
    description: 'Error text',
    defaultMessage: 'Error'
  }
})

export default messages
