import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import AdvancedSignalsConfigDialog from 'components/AdvancedSignalsConfigDialog'
import Alert from 'components/Alert'
import Loading from 'components/Loading'
import PageTitle from 'components/PageTitle'
import { logError } from 'utils/http'

import MachineDetails from './MachineDetails'
import messages from './messages'

class MachineDetailsLoader extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage
    ;this.state = {
      loading: true,
      node: {},
      alertMessagesNode: false,
      alertMessagesTypeNode: 'danger',
      alertMessagesTitleNode: '',
      alertMessagesTextNode: ['']
    },
    this.interval = 0
  }

  componentDidMount() {
    const { groupId } = this.props
    if (groupId) this.getDevice()
    this.interval = setInterval(this.getDevice, 30000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  getDevice = () => {
    const { getDevice, nodeId, groupId, intl } = this.props
    getDevice(nodeId, groupId)
      .then(response => {
        this.setState({
          loading: false,
          node: response.data
        })
      })
      .catch(response => {
        const { error } = { ...response }
        if (error && error.response) {
          switch (error.response.status) {
            case 400: // Bad request
              this.setState({
                loading: false,
                alertMessagesNode: true,
                alertMessagesTypeNode: 'danger',
                alertMessagesTitleNode: this.formatMessage(messages.error, {
                  number: '400'
                }),
                alertMessagesTextNode: [this.formatMessage(messages.error400Message)]
              })
              break
            case 401: // Invalid credentials
              let message
              if (intl.locale === 'en') message = error.response.message
              else message = this.formatMessage(messages.error401Message)
              this.setState({
                loading: false,
                alertMessagesNode: true,
                alertMessagesTypeNode: 'danger',
                alertMessagesTitleNode: this.formatMessage(messages.error, {
                  number: '401'
                }),
                alertMessagesTextNode: [message]
              })
              break
            case 403: // Access denied
              this.setState({
                loading: false,
                alertMessagesNode: true,
                alertMessagesTypeNode: 'danger',
                alertMessagesTitleNode: this.formatMessage(messages.error, {
                  number: '403'
                }),
                alertMessagesTextNode: [this.formatMessage(messages.error403Message)]
              })
              break
            case 404: // API url not found
              this.setState({
                loading: false,
                alertMessagesNode: true,
                alertMessagesTypeNode: 'danger',
                alertMessagesTitleNode: this.formatMessage(messages.error, {
                  number: '404'
                }),
                alertMessagesTextNode: [this.formatMessage(messages.error404Message)]
              })
              break
            case 406: // Not acceptable
              this.setState({
                loading: false,
                alertMessagesNode: true,
                alertMessagesTypeNode: 'danger',
                alertMessagesTitleNode: this.formatMessage(messages.error, {
                  number: '406'
                }),
                alertMessagesTextNode: [this.formatMessage(messages.error406Message)]
              })
              break
            case 422: // Not acceptable
              this.setState({
                loading: false,
                alertMessagesNode: true,
                alertMessagesTypeNode: 'danger',
                alertMessagesTitleNode: this.formatMessage(messages.error, {
                  number: '422'
                }),
                alertMessagesTextNode: [this.formatMessage(messages.error422Message)]
              })
              break
            case 500: // Unexpected error
              this.setState({
                loading: false,
                alertMessagesNode: true,
                alertMessagesTypeNode: 'danger',
                alertMessagesTitleNode: this.formatMessage(messages.error, {
                  number: '500'
                }),
                alertMessagesTextNode: [this.formatMessage(messages.error500Message)]
              })
              break
            default:
              this.setState({
                loading: false,
                alertMessagesNode: true,
                alertMessagesTypeNode: 'danger',
                alertMessagesTitleNode: this.formatMessage(messages.errorUndefinedTitle),
                alertMessagesTextNode: [this.formatMessage(messages.errorUndefinedMessage)]
              })
              logError(error)
          }
        } else {
          this.setState({
            loading: false,
            alertMessagesNode: true,
            alertMessagesTypeNode: 'danger',
            alertMessagesTitleNode: this.formatMessage(messages.errorUndefinedTitle),
            alertMessagesTextNode: [this.formatMessage(messages.errorUndefinedMessage)]
          })
          logError(error)
        }
      })
  }

  renderNodeDetailsLoadingAndError = () => {
    const { loading, alertMessagesNode, alertMessagesTypeNode, alertMessagesTextNode, alertMessagesTitleNode } =
      this.state

    if (loading) {
      return <Loading />
    } else if (alertMessagesNode) {
      return (
        <Alert
          alertType={alertMessagesTypeNode}
          messageText={alertMessagesTextNode}
          messageTitle={alertMessagesTitleNode}
          showAlert={alertMessagesNode}
        />
      )
    }
  }

  renderNodeDetailsContent = () => {
    const { loading, alertMessagesNode, node } = this.state
    const { nodeLastLocation, onOpenDialog, canConfigureAdvancedSignals } = this.props

    if (loading || alertMessagesNode) {
      return (
        <div className='container-fluid' style={{ marginTop: '20px' }}>
          {this.renderNodeDetailsLoadingAndError()}
        </div>
      )
    } else {
      return (
        <MachineDetails
          canConfigureAdvancedSignals={canConfigureAdvancedSignals}
          node={node}
          nodeLastLocation={nodeLastLocation}
          onOpenDialog={onOpenDialog}
        />
      )
    }
  }

  render() {
    const { nodesUrl } = this.props

    return (
      <div className='content-container' id='content'>
        <div style={{ margin: '20px' }}>
          <div className='container-fluid'>
            <PageTitle back={nodesUrl} title={this.formatMessage(messages.title)} />
          </div>
          {this.renderNodeDetailsContent()}
        </div>
        <AdvancedSignalsConfigDialog />
      </div>
    )
  }
}

MachineDetailsLoader.propTypes = {
  canConfigureAdvancedSignals: PropTypes.bool.isRequired,
  getDevice: PropTypes.func.isRequired,
  groupId: PropTypes.string,
  intl: PropTypes.object.isRequired,
  nodeId: PropTypes.string.isRequired,
  nodeLastLocation: PropTypes.object,
  nodesUrl: PropTypes.string.isRequired,
  onOpenDialog: PropTypes.func.isRequired
}

MachineDetailsLoader.defaultProps = {
  groupId: '',
  nodeLastLocation: {}
}

export default injectIntl(MachineDetailsLoader)
