import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import Fab from '@material-ui/core/Fab'
import Grid from '@material-ui/core/Grid'
import SearchIcon from '@material-ui/icons/Search'
import { DateTimePicker } from '@material-ui/pickers'
import { withStyles } from '@material-ui/core/styles'

import messages from './messages'

import { utcTimeToBrowserLocalNoSeconds } from 'utils/timeFormat'

const styles = {
  gridContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 14,
    marginBottom: 5
  }
}

class DateTimeRangeSelector extends React.Component {
  constructor(props) {
    super(props)
    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage
    this.state = {
      min: props.minDate,
      max: props.maxDate
    }
  }

  componentDidUpdate(prevProps) {
    const { minDate, maxDate } = this.props
    if (prevProps.minDate !== minDate || prevProps.maxDate !== maxDate) {
      this.setState({
        min: minDate,
        max: maxDate
      })
    }
  }

  isMinValid(newMin) {
    const { max } = this.state
    return newMin < max
  }

  isMaxValid(newMax) {
    const { min } = this.state
    return min < newMax
  }

  // Changes are only propagated if the new range is valid!
  // TODO show error message otherwise
  handleMinChangeValidityCheck = newMinObj => {
    const newMin = newMinObj.valueOf()
    if (this.isMinValid(newMin)) {
      this.setState({ min: newMin }, () => {
        const { onMinChange } = this.props
        if (onMinChange) onMinChange(newMin)
      })
    }
  }

  handleMaxChangeValidityCheck = newMaxObj => {
    const newMax = newMaxObj.valueOf()
    if (this.isMaxValid(newMax)) {
      this.setState({ max: newMax }, () => {
        const { onMaxChange } = this.props
        if (onMaxChange) onMaxChange(newMax)
      })
    }
  }

  handleSearch = () => {
    const { onSearch } = this.props
    const { min, max } = this.state
    onSearch(min, max)
  }

  render() {
    const { onSearch, classes, loading } = this.props
    const { min, max } = this.state
    return (
      <Grid classes={{ root: classes.gridContainer }} container spacing={3}>
        <Grid item xs={5}>
          <DateTimePicker
            ampm={false}
            cancelLabel={this.formatMessage(messages.cancel)}
            disableFuture
            helperText=''
            label={this.formatMessage(messages.from)}
            labelFunc={() => utcTimeToBrowserLocalNoSeconds(min)}
            maxDate={max}
            okLabel={this.formatMessage(messages.ok)}
            onChange={this.handleMinChangeValidityCheck}
            style={{ width: '90%' }}
            value={min}
          />
        </Grid>
        <Grid item xs={5}>
          <DateTimePicker
            ampm={false}
            cancelLabel={this.formatMessage(messages.cancel)}
            disableFuture
            helperText=''
            label={this.formatMessage(messages.to)}
            labelFunc={() => utcTimeToBrowserLocalNoSeconds(max)}
            minDate={min}
            okLabel={this.formatMessage(messages.ok)}
            onChange={this.handleMaxChangeValidityCheck}
            style={{ width: '90%' }}
            value={max}
          />
        </Grid>
        {onSearch && (
          <Grid item style={{ textAlign: 'center' }} xs={2}>
            <Fab
              className='primary-action-button'
              disabled={loading}
              onClick={this.handleSearch}
              size='small'
              style={{ width: '45px' }}
            >
              <SearchIcon />
            </Fab>
          </Grid>
        )}
      </Grid>
    )
  }
}

DateTimeRangeSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  maxDate: PropTypes.number.isRequired,
  minDate: PropTypes.number.isRequired,
  onMaxChange: PropTypes.func,
  onMinChange: PropTypes.func,
  onSearch: PropTypes.func
}

DateTimeRangeSelector.defaultProps = {
  loading: false,
  onMaxChange: i => i,
  onMinChange: i => i,
  onSearch: i => i
}

export default withStyles(styles)(injectIntl(DateTimeRangeSelector))
