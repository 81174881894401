import PropTypes from 'prop-types'
import React from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { injectIntl } from 'react-intl'

import { Menu } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

import DropDownMenu from 'material-ui/DropDownMenu'
import MenuItem from 'material-ui/MenuItem'

import DataChip from 'components/DataChip'
import { getMappedConsentState } from 'components/SmsServiceConsentDialog/apiMappings'
import SmsServiceStateChip from 'components/SmsServiceStateChip'
import { getSmsServiceState } from 'components/SmsServiceStateChip/helpers'
import { client, logError } from 'utils/http'

import AssignedUsersGroupActions from './AssignedUsersGroupActions'
import messages from './messages'
import { composeLocalQuery, showActionMenuItems } from './utils'

const styles = {
  dialog: {
    minWidth: 800
  }
}

class AssignedUsersTable extends React.Component {
  constructor(props) {
    super(props)
    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      assignedUsers: [],
      assignedUsersGroupActions: [],
      count: 0,
      dialogOpen: false,
      dialogOpenBulk: false,
      dialogOpenForSelectedAssignUser: false,
      dialogSelectedAssignUser: '',
      dialogSelectedComponent: <div />,
      dialogSelectedUserToDeleteBulkTitle: '',
      dialogSelectedUserToDeleteEmail: '',
      dialogSelectedUserToDeleteTitle: '',
      groupActionsButtonAnchorElement: null,
      isAssignedUsersLoading: false,
      selectedUsers: [],
      selectedUsersEmails: [],
      tableOptions: {
        noDataText: <CircularProgress />,
        sizePerPage: 5,
        page: 1
      }
    }
  }

  componentDidMount() {
    const { intl } = this.props

    this.getAssignedUsers()

    AssignedUsersGroupActions(intl).then(actions => {
      this.setState({
        assignedUsersGroupActions: actions
      })
    })
  }

  getAssignedUsers = () => {
    const { ruleInstance, setAlert } = this.props
    const {
      tableOptions: { page, sizePerPage }
    } = this.state

    if (!ruleInstance) {
      this.setState(state => ({
        tableOptions: {
          ...state.tableOptions,
          noDataText: this.formatMessage(messages.noUsers)
        }
      }))
    } else {
      this.setState(
        state => ({
          assignedUsers: [],
          isAssignedUsersLoading: true,
          tableOptions: {
            ...state.tableOptions,
            noDataText: <CircularProgress />
          }
        }),
        () => {
          const query = composeLocalQuery(page, sizePerPage)
          client
            .getRuleInstanceUsersAssigned(ruleInstance.hashId, query)
            .then(response => {
              const assignedUsersCount = parseInt(response.headers['x-total-count'], 10)
              const assignedUsers = response.data

              if (assignedUsersCount === 0) {
                this.setState(state => {
                  const { noDataText, ...otherTableOptions } = state.tableOptions
                  return {
                    assignedUsers: [],
                    isAssignedUsersLoading: false,
                    count: 0,
                    tableOptions: {
                      noDataText: this.formatMessage(messages.noUsersAssigned),
                      ...otherTableOptions
                    }
                  }
                })
              } else if (response.data !== undefined) {
                //Get more information about each user
                this.getAssignedUsersDetails(assignedUsers, assignedUsersCount)
              } else {
                const alertMessages = true
                const alertMessagesType = 'danger'
                const alertMessagesTitle = this.formatMessage(messages.errorText)
                const alertMessagesText = [this.formatMessage(messages.errorRequestingUsers)]

                setAlert(alertMessages, alertMessagesType, alertMessagesTitle, alertMessagesText)
                this.setState({
                  assignedUsers: [],
                  isAssignedUsersLoading: false,
                  count: 0
                })
              }
            })
            .catch(response => {
              const error = { ...response }

              const alertMessages = true
              const alertMessagesType = 'danger'
              let alertMessagesTitle = ''
              let alertMessagesText = ['']

              if (error.response !== undefined && error.response.status !== undefined) {
                switch (error.response.status) {
                  case 400:
                    alertMessagesTitle = this.formatMessage(messages.error, { number: 400 })
                    alertMessagesText = [this.formatMessage(messages.error400Text)]
                    break
                  case 401:
                    alertMessagesTitle = this.formatMessage(messages.error, { number: 401 })
                    alertMessagesText = [error.response.message]
                    break
                  case 403:
                    alertMessagesTitle = this.formatMessage(messages.error, { number: 403 })
                    alertMessagesText = [this.formatMessage(messages.error403Text)]
                    break
                  case 404:
                    alertMessagesTitle = this.formatMessage(messages.error, { number: 404 })
                    alertMessagesText = [this.formatMessage(messages.error404Text)]
                    break
                  case 406:
                    alertMessagesTitle = this.formatMessage(messages.error, { number: 406 })
                    alertMessagesText = [this.formatMessage(messages.error406Text)]
                    break
                  case 500:
                    alertMessagesTitle = this.formatMessage(messages.error, { number: 500 })
                    alertMessagesText = [error.response.data.error_description]
                    break
                  default:
                    alertMessagesTitle = this.formatMessage(messages.errorUndefinedTitle)
                    alertMessagesText = [this.formatMessage(messages.errorUndefinedText)]
                    logError(response)
                }

                this.setState(state => {
                  const { noDataText, ...otherTableOptions } = state.tableOptions
                  return {
                    assignedUsers: [],
                    isAssignedUsersLoading: false,
                    count: 0,
                    tableOptions: {
                      noDataText: this.formatMessage(messages.noUsersAssigned),
                      ...otherTableOptions
                    }
                  }
                })
                setAlert(alertMessages, alertMessagesType, alertMessagesTitle, alertMessagesText)
              } else {
                this.setState(state => {
                  const { noDataText, ...otherTableOptions } = state.tableOptions
                  return {
                    assignedUsers: [],
                    isAssignedUsersLoading: false,
                    count: 0,
                    tableOptions: {
                      noDataText: this.formatMessage(messages.noUsersAssigned),
                      ...otherTableOptions
                    }
                  }
                })

                alertMessagesTitle = this.formatMessage(messages.errorUndefinedTitle)
                alertMessagesText = [this.formatMessage(messages.errorUndefinedText)]

                setAlert(alertMessages, alertMessagesType, alertMessagesTitle, alertMessagesText)
              }
            })
        }
      )
    }
  }

  getAssignedUsersDetails = (asignedUsersToShow, assignedUsersCount) => {
    const { getUsersOfGroupDetail, ruleInstance, setAlert } = this.props

    const { groupId } = ruleInstance
    const usersEmailsToGetDetail = asignedUsersToShow.map(user => user.email)

    if (asignedUsersToShow.length > 0) {
      getUsersOfGroupDetail(groupId, usersEmailsToGetDetail)
        .then(response => {
          const assignedUsers = []
          if (response.data.users !== undefined && response.data.users.length > 0) {
            const assignedUsersWithDetail = response.data.users
            assignedUsersWithDetail.map(user => {
              const currentUserEmail = user.email
              const usersWithActionsFiltered = asignedUsersToShow.filter(u => {
                return u.email === currentUserEmail
              })
              const userWithActions = usersWithActionsFiltered[0]

              const consentState = getMappedConsentState(user?.consent)
              const isPhoneNumberPresent = user?.isPhoneNumberPresent

              const userObject = {
                id: user.id,
                email: user.email,
                firstName: user.firstName,
                lastName: user.lastName,
                notificationActions: userWithActions.actions,
                ruleInstanceHashId: userWithActions.ruleInstanceHashId,
                smsService: getSmsServiceState(consentState, isPhoneNumberPresent)
              }
              assignedUsers.push(userObject)
              return user
            })
          }
          this.setState({
            assignedUsers,
            count: assignedUsersCount,
            isAssignedUsersLoading: false
          })
        })
        .catch(response => {
          const error = { ...response }

          const alertMessages = true
          const alertMessagesType = 'danger'
          let alertMessagesTitle = ''
          let alertMessagesText = ['']

          if (error.response !== undefined && error.response.status !== undefined) {
            switch (error.response.status) {
              case 400:
                alertMessagesTitle = this.formatMessage(messages.error, { number: 400 })
                alertMessagesText = [this.formatMessage(messages.error400Text)]
                break
              case 401:
                alertMessagesTitle = this.formatMessage(messages.error, { number: 401 })
                alertMessagesText = [error.response.message]
                break
              case 403:
                alertMessagesTitle = this.formatMessage(messages.error, { number: 403 })
                alertMessagesText = [this.formatMessage(messages.error403Text)]
                break
              case 404:
                alertMessagesTitle = this.formatMessage(messages.error, { number: 404 })
                alertMessagesText = [this.formatMessage(messages.error404Text)]
                break
              case 500:
                alertMessagesTitle = this.formatMessage(messages.error, { number: 500 })
                alertMessagesText = [error.response.data.error_description]
                break
              default:
                alertMessagesTitle = this.formatMessage(messages.errorUndefinedTitle)
                alertMessagesText = [this.formatMessage(messages.errorUndefinedText)]
                logError(response)
            }
            this.setState(state => {
              const { noDataText, ...otherTableOptions } = state.tableOptions
              return {
                assignedUsers: [],
                isAssignedUsersLoading: false,
                count: 0,
                tableOptions: {
                  noDataText: this.formatMessage(messages.noUsers),
                  ...otherTableOptions
                }
              }
            })
            setAlert(alertMessages, alertMessagesType, alertMessagesTitle, alertMessagesText)
          } else {
            this.setState(state => {
              const { noDataText, ...otherTableOptions } = state.tableOptions
              return {
                assignedUsers: [],
                isAssignedUsersLoading: false,
                count: 0,
                tableOptions: {
                  noDataText: this.formatMessage(messages.noUsers),
                  ...otherTableOptions
                }
              }
            })

            alertMessagesTitle = this.formatMessage(messages.errorUndefinedTitle)
            alertMessagesText = [this.formatMessage(messages.errorUndefinedText)]

            setAlert(alertMessages, alertMessagesType, alertMessagesTitle, alertMessagesText)
          }
        })
    } else {
      this.setState(state => {
        const { noDataText, ...otherTableOptions } = state.tableOptions
        return {
          assignedUsers: [],
          isAssignedUsersLoading: false,
          count: 0,
          tableOptions: {
            noDataText: this.formatMessage(messages.noUsers),
            ...otherTableOptions
          }
        }
      })
    }
  }

  redrawAssignedUsersTable = () => {
    this.getAssignedUsers()
  }

  deleteUser = user => {
    const userName = user.firstName + ' ' + user.lastName
    const userEmail = user.email
    const userTitle = userName + ' (' + userEmail + ')'

    this.setState({
      dialogOpen: true,
      dialogSelectedUserToDeleteTitle: userTitle,
      dialogSelectedUserToDeleteEmail: userEmail
    })
  }

  handleDeleteUserBulk = () => {
    const { selectedUsers } = this.state
    const userTitle = this.formatMessage(messages.unassignUsers, { number: selectedUsers.length })

    this.setState({
      dialogOpenBulk: true,
      dialogSelectedUserToDeleteBulkTitle: userTitle
    })
  }

  handleClose = () => {
    this.setState({
      dialogOpen: false,
      dialogOpenBulk: false,
      dialogOpenForSelectedAssignUser: false,
      dialogSelectedAssignUser: '',
      dialogSelectedUserToDeleteBulkTitle: '',
      dialogSelectedUserToDeleteEmail: '',
      dialogSelectedUserToDeleteTitle: ''
    })
  }

  handleDelete = () => {
    const { dialogSelectedUserToDeleteEmail } = this.state
    this.deleteUsers([dialogSelectedUserToDeleteEmail])
  }

  handleBulkDelete = () => {
    const { selectedUsersEmails } = this.state
    this.deleteUsers(selectedUsersEmails)
  }

  deleteUsers = usersToUnassign => {
    const { ruleInstance, setAlert, fetchRuleInstance } = this.props
    const { assignedUsers } = this.state

    const users = assignedUsers
      .filter(user => usersToUnassign.includes(user.email))
      .map(user => ({ email: user.email, userId: user.id }))

    let alertMessages = false
    let alertMessagesType = ''
    let alertMessagesTitle = ''
    let alertMessagesText = ['']

    client
      .desassignUsersToRuleInstance(ruleInstance.hashId, users)
      .then(response => {
        if (response.data !== undefined) {
          alertMessages = true
          alertMessagesType = 'success'
          alertMessagesTitle = ''
          alertMessagesText = [this.formatMessage(messages.usersUnassignedCorrectly)]

          this.setState(
            {
              dialogOpen: false,
              dialogOpenBulk: false,
              dialogSelectedUserToDeleteBulkTitle: '',
              dialogSelectedUserToDeleteEmail: '',
              dialogSelectedUserToDeleteTitle: '',
              selectedUsers: []
            },
            () => {
              setAlert(alertMessages, alertMessagesType, alertMessagesTitle, alertMessagesText)
              fetchRuleInstance()
              this.getAssignedUsers()
            }
          )
        }
      })
      .catch(response => {
        const error = { ...response }

        alertMessages = true
        alertMessagesType = 'danger'

        switch (error.response.status) {
          case 400:
            alertMessagesTitle = this.formatMessage(messages.error, { number: 400 })
            alertMessagesText = [this.formatMessage(messages.error400Text)]
            break
          case 401:
            alertMessagesTitle = this.formatMessage(messages.error, { number: 401 })
            alertMessagesText = [error.response.message]
            break
          case 403:
            alertMessagesTitle = this.formatMessage(messages.error, { number: 403 })
            alertMessagesText = [this.formatMessage(messages.error403Text)]
            break
          case 404:
            alertMessagesTitle = this.formatMessage(messages.error, { number: 404 })
            alertMessagesText = [this.formatMessage(messages.error404Text)]
            break
          case 406:
            alertMessagesTitle = this.formatMessage(messages.error, { number: 406 })
            alertMessagesText = [this.formatMessage(messages.error406Text)]
            break
          case 409:
            alertMessagesTitle = this.formatMessage(messages.error, { number: 409 })
            alertMessagesText = [this.formatMessage(messages.error409Text)]
            break
          case 415:
            alertMessagesTitle = this.formatMessage(messages.error, { number: 415 })
            alertMessagesText = [this.formatMessage(messages.error415Text)]
            break
          case 422:
            alertMessagesTitle = this.formatMessage(messages.error, { number: 422 })
            alertMessagesText = [this.formatMessage(messages.error422Text)]
            break
          case 500:
            alertMessagesTitle = this.formatMessage(messages.error, { number: 500 })
            alertMessagesText = [error.response.data.error_description]
            break
          default:
            alertMessagesTitle = this.formatMessage(messages.errorUndefinedTitle)
            alertMessagesText = [this.formatMessage(messages.errorUndefinedText)]

            logError(response)
        }
        this.setState(
          {
            dialogOpen: false,
            dialogOpenBulk: false,
            dialogSelectedUserToDeleteBulkTitle: '',
            dialogSelectedUserToDeleteEmail: '',
            dialogSelectedUserToDeleteTitle: '',
            selectedUsers: []
          },
          () => this.getAssignedUsers()
        )
        setAlert(alertMessages, alertMessagesType, alertMessagesTitle, alertMessagesText)
      })
  }

  handleClearSelectedUsers = () => {
    this.setState({
      selectedUsers: [],
      selectedUsersEmails: []
    })
  }

  onRowSelect = (row, isSelected, event, rowNumber) => {
    const { selectedUsers, selectedUsersEmails } = this.state
    const element = row
    const elementId = row.id

    const newSelectedUsers = selectedUsers.map(userId => userId)
    const newSelectedUsersEmails = selectedUsersEmails.map(userEmail => userEmail)
    const indexOfUser = selectedUsers.indexOf(elementId)

    if (isSelected) {
      if (indexOfUser < 0) {
        newSelectedUsers.push(elementId)
        newSelectedUsersEmails.push(element.email)
      }
    } else {
      if (indexOfUser > -1) {
        newSelectedUsers.splice(indexOfUser, 1)
        newSelectedUsersEmails.splice(indexOfUser, 1)
      }
    }

    this.setState({
      selectedUsers: newSelectedUsers,
      selectedUsersEmails: newSelectedUsersEmails
    })
  }

  onSelectAll = (isSelected, rows) => {
    const { selectedUsers, selectedUsersEmails } = this.state

    const newSelectedUsers = selectedUsers.map(userId => userId)
    const newSelectedUsersEmails = selectedUsersEmails.map(userEmail => userEmail)

    const usersToProcess = rows.map(user => {
      return user
    })

    usersToProcess.map(user => {
      const indexOfUser = selectedUsers.indexOf(user.id)

      if (isSelected) {
        if (indexOfUser < 0) {
          newSelectedUsers.push(user.id)
          newSelectedUsersEmails.push(user.email)
        }
      } else {
        if (indexOfUser > -1) {
          newSelectedUsers.splice(indexOfUser, 1)
          newSelectedUsersEmails.splice(indexOfUser, 1)
        }
      }

      return user
    })

    this.setState({
      selectedUsers: newSelectedUsers,
      selectedUsersEmails: newSelectedUsersEmails
    })
  }

  onPageChange = (page, sizePerPage) => {
    this.setState(
      state => {
        const { page: previousPage, sizePerPage: previousSizePage, ...otherTableOptions } = state.tableOptions
        return {
          assignedDevices: [],
          tableOptions: { page, sizePerPage, ...otherTableOptions }
        }
      },
      () => {
        this.getAssignedUsers()
      }
    )
  }

  onSizePerPageList = sizePerPage => {
    this.setState(
      state => {
        const { sizePerPage: previousSizePage, ...otherTableOptions } = state.tableOptions
        return {
          tableOptions: { sizePerPage, ...otherTableOptions }
        }
      },
      () => this.getAssignedUsers()
    )
  }

  getTableOptions = () => {
    const { tableOptions } = this.state
    const completeTableOptions = {
      ...tableOptions,
      onSizePerPageList: this.onSizePerPageList,
      sizePerPageList: [
        {
          text: '5',
          value: 5
        },
        {
          text: '50',
          value: 50
        },
        {
          text: '100',
          value: 100
        },
        {
          text: '200',
          value: 200
        }
      ],
      onPageChange: this.onPageChange,
      ignoreSinglePage: false,
      pageStartIndex: 1,
      paginationSize: 5,
      prePage: this.formatMessage(messages.prePage),
      nextPage: this.formatMessage(messages.nextPage),
      firstPage: this.formatMessage(messages.firstPage),
      lastPage: this.formatMessage(messages.lastPage),
      paginationShowsTotal: this.renderPaginationShowsTotal(this.formatMessage),
      paginationPosition: 'bottom',
      hideSizePerPage: false,
      alwaysShowAllBtns: false,
      withFirstAndLast: true,
      onFilterChange: this.onFilterChange,
      onSortChange: this.onSortChange
    }

    return completeTableOptions
  }

  formatSmsService = cell => {
    return <SmsServiceStateChip state={cell} />
  }

  formatNotifyByBell = cell => {
    const typeOfNotification = 1
    return this.handleNotifyBy(cell, typeOfNotification)
  }

  formatNotifyByEmail = cell => {
    const typeOfNotification = 2
    return this.handleNotifyBy(cell, typeOfNotification)
  }

  formatNotifyBySms = cell => {
    const typeOfNotification = 4
    return this.handleNotifyBy(cell, typeOfNotification)
  }

  /*
  formatNotifyByPush = cell => {      
    let typeOfNotification = 3
    return this.handleNotifyBy(cell, typeOfNotification)   
  }
  */

  //Possible types of notifications:
  // 1 - Bell (Live)
  // 2 - Email
  // 3 - Push
  handleNotifyBy = (notificationAction, typeOfNotification) => {
    const { intl } = this.props
    let notify = 'EnabledNo'

    if (notificationAction !== undefined) {
      const numActions = notificationAction.length
      if (numActions > 0) {
        notificationAction.map(theNotificationAction => {
          if (typeOfNotification === 1) {
            if (theNotificationAction.name === 'LIVE_NOTIFICATION') {
              notify = 'EnabledYes'
            }
          } else if (typeOfNotification === 2) {
            if (theNotificationAction.name === 'EMAIL_NOTIFICATION') {
              notify = 'EnabledYes'
            }
          } else if (typeOfNotification === 3) {
            if (theNotificationAction.name === 'PUSH_NOTIFICATION') {
              notify = 'EnabledYes'
            }
          } else if (typeOfNotification === 4) {
            if (theNotificationAction.name === 'SMS_NOTIFICATION') {
              notify = 'EnabledYes'
            }
          }
          return theNotificationAction
        })
      }
    }

    return <DataChip chipText={notify} inline intl={intl} />
  }

  formatActionsDetail = (cell, row) => {
    const { assignedUsersGroupActions } = this.state
    return (
      <div>
        <Button
          onClick={() => {
            this.deleteUser(row)
          }}
          style={{ top: '-10px' }}
          title={this.formatMessage(messages.unassignUser)}
        >
          <Icon className='zmdi zmdi-close-circle-o' style={{ color: '#C00000' }} />
        </Button>
        <DropDownMenu
          className='privilegesDropdown'
          labelStyle={{
            height: '100%',
            lineHeight: 'initial',
            padding: '2px'
          }}
          onChange={this.handleSingleAssignedUserChange}
          onClick={() => this.handleSelectedAssignedUser(row.email)}
          style={{ height: '28px' }}
          targetOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          title={this.formatMessage(messages.groupActions)}
          value={null}
        >
          <MenuItem
            label={<Icon className='zmdi zmdi-notifications' />}
            primaryText={this.formatMessage(messages.selectAction)}
            value={null}
          />
          {showActionMenuItems(assignedUsersGroupActions)}
        </DropDownMenu>
      </div>
    )
  }

  handleSelectedAssignedUser = assignedUserEmail => {
    this.setState({
      dialogSelectedAssignUser: assignedUserEmail
    })
  }

  handleMultipleAssignedUsersChange = value => {
    const { ruleInstance } = this.props
    const { assignedUsers, selectedUsersEmails } = this.state

    if (value !== null) {
      const users = assignedUsers
        .filter(user => selectedUsersEmails.includes(user.email))
        .map(user => ({ email: user.email, userId: user.id }))
      const ruleInstanceHashId = ruleInstance.hashId
      this.setState({
        dialogOpenForSelectedAssignUser: true,
        dialogSelectedAssignUser: '',
        dialogSelectedComponent: value.component(value, users, ruleInstanceHashId, this.redrawAssignedUsersTable)
      })
    }
  }

  handleSingleAssignedUserChange = (event, index, value) => {
    const { ruleInstance } = this.props
    const { assignedUsers, dialogSelectedAssignUser } = this.state

    if (value !== null) {
      const users = assignedUsers
        .filter(user => user.email === dialogSelectedAssignUser)
        .map(user => ({ email: user.email, userId: user.id }))
      const ruleInstanceHashId = ruleInstance.hashId
      this.setState({
        dialogOpenForSelectedAssignUser: true,
        dialogSelectedAssignUser: '',
        dialogSelectedComponent: value.component(value, users, ruleInstanceHashId, this.redrawAssignedUsersTable)
      })
    }
  }

  handleGroupActionsMenuButtonClick = event => {
    this.setState({ groupActionsButtonAnchorElement: event.currentTarget })
  }

  handleGroupActionsMenuClose = () => {
    this.setState({ groupActionsButtonAnchorElement: null })
  }

  renderPaginationShowsTotal = formatMessage => (start, to, total) =>
    (
      <span>
        {`${formatMessage(messages.showingRows)} ${start} ${formatMessage(messages.to)} ${to} ${formatMessage(
          messages.of
        )} ${total}`}
      </span>
    )

  renderGroupActionsMenuItems = () => {
    const { assignedUsersGroupActions } = this.state

    if (assignedUsersGroupActions !== undefined) {
      return assignedUsersGroupActions.map(action => (
        <MenuItem
          key={'actionFor' + action.hashId}
          onClick={() => {
            this.handleMultipleAssignedUsersChange(action)
            this.handleGroupActionsMenuClose()
          }}
          primaryText={action.description}
          value={action}
        />
      ))
    } else {
      return <div />
    }
  }

  render() {
    const { classes, tableHeight } = this.props
    const {
      assignedUsers,
      count,
      dialogOpen,
      dialogOpenBulk,
      dialogOpenForSelectedAssignUser,
      dialogSelectedComponent,
      dialogSelectedUserToDeleteBulkTitle,
      dialogSelectedUserToDeleteTitle,
      groupActionsButtonAnchorElement,
      isAssignedUsersLoading,
      selectedUsers
    } = this.state

    const selectRowProp = {
      mode: 'checkbox',
      clickToSelect: false,
      onSelect: this.onRowSelect,
      onSelectAll: this.onSelectAll,
      bgColor: '#f5f5f5',
      selected: selectedUsers
    }

    const tableOptions = this.getTableOptions()

    const data = isAssignedUsersLoading ? [] : assignedUsers

    return (
      <div className='col-md-12' style={{ height: tableHeight, padding: 0 }}>
        <Paper style={{ height: '100%', borderRadius: 0 }}>
          <div className='col-md-12' style={{ height: '15%' }}>
            <div className='col-md-9' style={{ padding: 0 }}>
              <Button disabled style={{ marginTop: 14, marginLeft: 5 }}>
                {this.formatMessage(messages.selectedUsers)} ({selectedUsers.length})
              </Button>
              <Button
                className='secondary-action-button'
                disabled={selectedUsers.length === 0}
                onClick={this.handleClearSelectedUsers}
                style={{
                  marginTop: 14,
                  marginLeft: 5
                }}
              >
                {this.formatMessage(messages.clearSelection)}
              </Button>
            </div>
            <div className='col-md-3 text-right' style={{ height: 52, padding: 0 }}>
              <Button
                className='delete-button'
                disabled={selectedUsers.length === 0}
                onClick={this.handleDeleteUserBulk}
                style={{ marginTop: 14, marginLeft: 5 }}
              >
                <Icon className='zmdi zmdi-close-circle-o' />
                {this.formatMessage(messages.unassign)}
              </Button>
              <Button
                className='primary-action-button'
                disabled={selectedUsers.length === 0}
                onClick={this.handleGroupActionsMenuButtonClick}
                style={{ marginTop: 14, marginLeft: 5 }}
              >
                {this.formatMessage(messages.groupActions)}
                <Icon className='zmdi zmdi-caret-down' style={{ marginLeft: 6, marginRight: 0 }} />
              </Button>
              <Menu
                anchorEl={groupActionsButtonAnchorElement}
                keepMounted
                onClose={this.handleGroupActionsMenuClose}
                open={Boolean(groupActionsButtonAnchorElement)}
              >
                {this.renderGroupActionsMenuItems()}
              </Menu>
            </div>
          </div>
          <div className='col-md-12' style={{ padding: '0px', height: '85%' }}>
            <div className='table-with-pagination' style={{ height: '100%' }}>
              <BootstrapTable
                bodyStyle={{ height: '65%', overflowY: 'scroll' }}
                bordered={false}
                condensed={false}
                containerStyle={{ height: '100%' }}
                data={data}
                exportCSV={false}
                fetchInfo={{ dataTotalSize: count }}
                hover
                multiColumnSearch={false}
                options={tableOptions}
                pagination
                remote={remoteObj => ({
                  ...remoteObj,
                  search: false,
                  pagination: true,
                  sizePerPage: true,
                  sort: false,
                  filter: false
                })}
                search={false}
                searchPlaceholder={this.formatMessage(messages.searchPlaceholder)}
                selectRow={selectRowProp}
                striped={false}
                tableStyle={{ height: '80%' }}
              >
                <TableHeaderColumn dataField='id' hidden isKey width='0'>
                  id
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField='firstName'
                  row='0'
                  rowSpan='2'
                  tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word', verticalAlign: 'middle' }}
                  width='19%'
                >
                  {this.formatMessage(messages.firstName)}
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField='lastName'
                  row='0'
                  rowSpan='2'
                  tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word', verticalAlign: 'middle' }}
                  width='19%'
                >
                  {this.formatMessage(messages.lastName)}
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField='email'
                  row='0'
                  rowSpan='2'
                  tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word', verticalAlign: 'middle' }}
                  width='19%'
                >
                  {this.formatMessage(messages.email)}
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField='smsService'
                  dataFormat={this.formatSmsService}
                  row='0'
                  rowSpan='2'
                  tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word', verticalAlign: 'middle' }}
                  width='19%'
                >
                  {this.formatMessage(messages.smsService)}
                </TableHeaderColumn>
                <TableHeaderColumn
                  colSpan='3'
                  headerAlign='center'
                  row='0'
                  tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word', verticalAlign: 'middle' }}
                  width='20%'
                >
                  {this.formatMessage(messages.notifyBy)}
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField='notificationActions'
                  dataFormat={this.formatNotifyByBell}
                  row='1'
                  tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word', verticalAlign: 'middle' }}
                  width='10%'
                >
                  {this.formatMessage(messages.bell)}
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField='notificationActions'
                  dataFormat={this.formatNotifyByEmail}
                  row='1'
                  tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word', verticalAlign: 'middle' }}
                  width='10%'
                >
                  {this.formatMessage(messages.email)}
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField='notificationActions'
                  dataFormat={this.formatNotifyBySms}
                  row='1'
                  tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word', verticalAlign: 'middle' }}
                  width='10%'
                >
                  {this.formatMessage(messages.sms)}
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataAlign='center'
                  dataField='id'
                  dataFormat={this.formatActionsDetail}
                  row='0'
                  rowSpan='2'
                  width='18%'
                >
                  {this.formatMessage(messages.actions)}
                </TableHeaderColumn>
              </BootstrapTable>
            </div>
          </div>
        </Paper>
        <Dialog classes={{ paper: classes.dialog }} onClose={this.handleClose} open={dialogOpen}>
          <DialogContent style={{ padding: '12px 10px 10px 12px' }}>
            <DialogTitle style={{ padding: 12 }}>
              <IconButton
                onClick={this.handleClose}
                style={{
                  position: 'absolute',
                  right: 3,
                  top: 3,
                  padding: 5
                }}
              >
                <CloseIcon />
              </IconButton>
              {this.formatMessage(messages.confirmUserUnassignament)}
            </DialogTitle>
            <DialogContentText style={{ padding: 12 }}>{dialogSelectedUserToDeleteTitle}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button className='delete-button' onClick={this.handleDelete}>
              {this.formatMessage(messages.unassign)}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog classes={{ paper: classes.dialog }} onClose={this.handleClose} open={dialogOpenBulk}>
          <DialogContent style={{ padding: '12px 10px 10px 12px' }}>
            <DialogTitle style={{ padding: 12 }}>
              <IconButton
                onClick={this.handleClose}
                style={{
                  position: 'absolute',
                  right: 3,
                  top: 3,
                  padding: 5
                }}
              >
                <CloseIcon />
              </IconButton>
              {this.formatMessage(messages.confirmUserUnassignament)}
            </DialogTitle>
            <DialogContentText style={{ padding: 12 }}>{dialogSelectedUserToDeleteBulkTitle}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button className='delete-button' onClick={this.handleBulkDelete}>
              {this.formatMessage(messages.unassign)}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog classes={{ paper: classes.dialog }} onClose={this.handleClose} open={dialogOpenForSelectedAssignUser}>
          <DialogContent style={{ padding: '12px 10px 10px 12px' }}>
            <DialogTitle style={{ padding: 12 }}>
              <IconButton
                onClick={this.handleClose}
                style={{
                  position: 'absolute',
                  right: 3,
                  top: 3,
                  padding: 5
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContentText style={{ padding: '0 12px 12px 12px' }}>{dialogSelectedComponent}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button className='primary-action-button' onClick={this.handleClose}>
              {this.formatMessage(messages.ok)}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

AssignedUsersTable.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchRuleInstance: PropTypes.func.isRequired,
  getUsersOfGroupDetail: PropTypes.func.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired }).isRequired,
  ruleInstance: PropTypes.object,
  setAlert: PropTypes.func.isRequired,
  tableHeight: PropTypes.string.isRequired
}

AssignedUsersTable.defaultProps = {
  ruleInstance: null
}

export default withStyles(styles)(injectIntl(AssignedUsersTable))
