import * as yup from 'yup'

import translations from 'i18n/locales'
import { numberTransform } from 'utils/yupHelpers'

const isHex = str => {
  return /^0x[A-Fa-f0-9]/g.test(str)
}

const MAX_VALUE_32_BIT = 4294967295
const REDUCED_MAX_VALUE_32_BIT = 4294967

export const validationSchema = () => {
  const i18n = translations[localStorage.getItem('user_language')]
  return yup.object().shape({
    nodeId0: yup
      .number()
      .transform(numberTransform)
      .required(i18n['ConfigsCS500.nodeId0IsRequired'])
      .nullable()
      .test('isValid', i18n['ConfigsCS500.invalidNodeId0'], function (value) {
        const invalid = value < 0 || value > 253
        return !invalid
      }),
    nodeId1: yup
      .number()
      .transform(numberTransform)
      .required(i18n['ConfigsCS500.nodeId1IsRequired'])
      .nullable()
      .test('isValid', i18n['ConfigsCS500.invalidNodeId1'], function (value) {
        const invalid = value < 0 || value > 253
        return !invalid
      }),
    baudRate0: yup
      .number()
      .transform(numberTransform)
      .required(i18n['ConfigsCS500.baudRate0IsRequired'])
      .nullable()
      .test('isValid', i18n['ConfigsCS500.invalidBaudRate0'], function (value) {
        const invalid = value < 0 || value > 5
        return !invalid
      }),
    baudRate1: yup
      .number()
      .transform(numberTransform)
      .required(i18n['ConfigsCS500.baudRate1IsRequired'])
      .nullable()
      .test('isValid', i18n['ConfigsCS500.invalidBaudRate1'], function (value) {
        const invalid = value < 0 || value > 5
        return !invalid
      }),
    sa0: yup
      .number()
      .transform(numberTransform)
      .required(i18n['ConfigsCS500.sa0IsRequired'])
      .nullable()
      .test('isValid', i18n['ConfigsCS500.invalidSa0'], function (value) {
        const invalid = value < 0 || value > 255
        return !invalid
      }),
    sa1: yup
      .number()
      .transform(numberTransform)
      .required(i18n['ConfigsCS500.sa1IsRequired'])
      .nullable()
      .test('isValid', i18n['ConfigsCS500.invalidSa1'], function (value) {
        const invalid = value < 0 || value > 255
        return !invalid
      }),
    loggingUploadPeriod0: yup
      .number()
      .transform(numberTransform)
      .required(i18n['ConfigsCS500.loggingUploadPeriod0IsRequired'])
      .nullable()
      .test('isValid', i18n['ConfigsCS500.invalidLoggingUploadPeriod0'], function (value) {
        const invalid = value < 0 || value > 0 && value < 30 || value > 65535
        return !invalid
      }),
    loggingUploadPeriod1: yup
      .number()
      .transform(numberTransform)
      .required(i18n['ConfigsCS500.loggingUploadPeriod1IsRequired'])
      .nullable()
      .test('isValid', i18n['ConfigsCS500.invalidLoggingUploadPeriod1'], function (value) {
        const invalid = value < 0 || value > 0 && value < 30 || value > 65535
        return !invalid
      }),
    interlinkEnabled: yup.boolean().required(i18n['ConfigsCS500.interlinkEnabledIsRequired']).nullable(),
    fotaUpdateEnabled: yup.boolean().required(i18n['ConfigsCS500.fotaUpdateEnabledIsRequired']).nullable(),
    restartCellularModule: yup.boolean(),
    //.required(i18n['ConfigsCS500.restartCellularModuleIsRequired'])
    //.nullable(),
    gnssPositionEnabled: yup.boolean().required(i18n['ConfigsCS500.enabledGnssPositionIsRequired']).nullable(),
    positionLogTime: yup
      .number()
      .transform(numberTransform)
      .test('isValid', i18n['ConfigsCS500.invalidPositionLogTimeErrorMessage'], function (value) {
        const invalid =
          this.parent.gnssPositionEnabled &&
          (value === null || value === undefined || value < 10 || value > REDUCED_MAX_VALUE_32_BIT)
        return !invalid
      }),
    gnssAltitudeEnabled: yup.boolean().required(i18n['ConfigsCS500.enabledGnssAltitudeIsRequired']).nullable(),
    altitudeLogTime: yup
      .number()
      .transform(numberTransform)
      .test('isValid', i18n['ConfigsCS500.invalidAltitudeLogTimeErrorMessage'], function (value) {
        const invalid =
          this.parent.gnssAltitudeEnabled &&
          (value === null || value === undefined || value < 10 || value > REDUCED_MAX_VALUE_32_BIT)
        return !invalid
      }),
    gnssSpeedEnabled: yup.boolean().required(i18n['ConfigsCS500.enabledGnssSpeedIsRequired']).nullable(),
    speedLogTime: yup
      .number()
      .transform(numberTransform)
      .test('isValid', i18n['ConfigsCS500.invalidSpeedLogTimeErrorMessage'], function (value) {
        const invalid =
          this.parent.gnssSpeedEnabled &&
          (value === null || value === undefined || value < 10 || value > REDUCED_MAX_VALUE_32_BIT)
        return !invalid
      }),
    gnssHeadingEnabled: yup.boolean().required(i18n['ConfigsCS500.enabledGnssHeadingIsRequired']).nullable(),
    headingLogTime: yup
      .number()
      .transform(numberTransform)
      .test('isValid', i18n['ConfigsCS500.invalidHeadingLogTimeErrorMessage'], function (value) {
        const invalid =
          this.parent.gnssHeadingEnabled &&
          (value === null || value === undefined || value < 10 || value > REDUCED_MAX_VALUE_32_BIT)
        return !invalid
      }),
    geofenceEnabled: yup.boolean().nullable(), //.required(i18n['ConfigsCS500.enabledGeofenceIsRequired']).nullable(),
    geofenceLogToPortal: yup.boolean(),
    geofenceSendImmediately: yup.boolean(),
    geofenceSamplePeriod: yup
      .number()
      .transform(numberTransform)
      .test('isValid', i18n['ConfigsCS500.invalidGeofenceSamplePeriodErrorMessage'], function (value) {
        const invalid =
          this.parent.geofenceEnabled &&
          this.parent.geofenceLogToPortal &&
          (value === null || value === undefined || value < 10 || value > REDUCED_MAX_VALUE_32_BIT)
        return !invalid
      }),
    geofenceLatitude: yup
      .number()
      .transform(numberTransform)
      .test('isValid', i18n['ConfigsCS500.invalidGeofenceLatitude'], function (value) {
        const invalid =
          this.parent.geofenceEnabled &&
          (value === null || value === undefined || value < -900000000 || value > 900000000)
        return !invalid
      }),
    geofenceLongitude: yup
      .number()
      .transform(numberTransform)
      .test('isValid', i18n['ConfigsCS500.invalidGeofenceLongitude'], function (value) {
        const invalid =
          this.parent.geofenceEnabled &&
          (value === null || value === undefined || value < -1800000000 || value > 1800000000)
        return !invalid
      }),
    geofenceRadius: yup
      .number()
      .transform(numberTransform)
      .test('isValid', i18n['ConfigsCS500.invalidGeofenceRadius'], function (value) {
        const invalid =
          this.parent.geofenceEnabled &&
          (value === null || value === undefined || value < 10 || value > MAX_VALUE_32_BIT)
        return !invalid
      }),
    dm1LogToPortal: yup.boolean().required(i18n['ConfigsCS500.dm1LogToPortalIsRequired']).nullable(),
    dm1Port: yup
      .number()
      .transform(numberTransform)
      .required(i18n['ConfigsCS500.dm1LogToPortalIsRequired'])
      .test('isValid', i18n['ConfigsCS500.invalidDM1Port'], function (value) {
        const invalid = value < 1 || value > 3
        return !invalid
      }),
    dm1UseSA: yup.boolean().required(i18n['ConfigsCS500.dm1UseSAIsRequired']).nullable(),
    dm1SA: yup.string().test('isValid', i18n['ConfigsCS500.invalidDM1SA'], function (value) {
      const valueToTest = '0x' + value
      const invalid =
        this.parent.dm1UseSA &&
        (value === undefined ||
          value === null ||
          value === '' ||
          !isHex(valueToTest) ||
          valueToTest < 0 ||
          valueToTest > 253)
      return !invalid
    }),
    forceFullAccessMode: yup.boolean().required(i18n['ConfigsCS500.forceFullAccessModeIsRequired']).nullable(),
    enableCan: yup.boolean(),
    canPort: yup
      .number()
      .transform(numberTransform)
      .test('isValid', i18n['ConfigsCS500.invalidMsdCanPort'], function (value) {
        const invalid =
          !this.parent.forceFullAccessMode &&
          this.parent.enableCan &&
          (value === undefined || value === null || value === '' || value < 0 || value > 1)
        return !invalid
      }),
    canId: yup.string().test('isValid', i18n['ConfigsCS500.invalidMsdCanId'], function (value) {
      const valueToTest = '0x' + value
      const invalid =
        !this.parent.forceFullAccessMode &&
        this.parent.enableCan &&
        (value === undefined || value === null || value === '' || valueToTest < 0 || valueToTest > 0x1fffffff)
      return !invalid
    }),
    byte0: yup.string().test('isValid', i18n['ConfigsCS500.invalidMsdByte'], function (value) {
      const valueToTest = '0x' + value
      const invalid =
        !this.parent.forceFullAccessMode &&
        this.parent.enableCan &&
        (value === undefined || value === null || value === '' || valueToTest < 0 || valueToTest > 255)
      return !invalid
    }),
    byte1: yup.string().test('isValid', i18n['ConfigsCS500.invalidMsdByte'], function (value) {
      const valueToTest = '0x' + value
      const invalid =
        !this.parent.forceFullAccessMode &&
        this.parent.enableCan &&
        (value === undefined || value === null || value === '' || valueToTest < 0 || valueToTest > 255)
      return !invalid
    }),
    byte2: yup.string().test('isValid', i18n['ConfigsCS500.invalidMsdByte'], function (value) {
      const valueToTest = '0x' + value
      const invalid =
        !this.parent.forceFullAccessMode &&
        this.parent.enableCan &&
        (value === undefined || value === null || value === '' || valueToTest < 0 || valueToTest > 255)
      return !invalid
    }),
    byte3: yup.string().test('isValid', i18n['ConfigsCS500.invalidMsdByte'], function (value) {
      const valueToTest = '0x' + value
      const invalid =
        !this.parent.forceFullAccessMode &&
        this.parent.enableCan &&
        (value === undefined || value === null || value === '' || valueToTest < 0 || valueToTest > 255)
      return !invalid
    }),
    byte4: yup.string().test('isValid', i18n['ConfigsCS500.invalidMsdByte'], function (value) {
      const valueToTest = '0x' + value
      const invalid =
        !this.parent.forceFullAccessMode &&
        this.parent.enableCan &&
        (value === undefined || value === null || value === '' || valueToTest < 0 || valueToTest > 255)
      return !invalid
    }),
    byte5: yup.string().test('isValid', i18n['ConfigsCS500.invalidMsdByte'], function (value) {
      const valueToTest = '0x' + value
      const invalid =
        !this.parent.forceFullAccessMode &&
        this.parent.enableCan &&
        (value === undefined || value === null || value === '' || valueToTest < 0 || valueToTest > 255)
      return !invalid
    }),
    byte6: yup.string().test('isValid', i18n['ConfigsCS500.invalidMsdByte'], function (value) {
      const valueToTest = '0x' + value
      const invalid =
        !this.parent.forceFullAccessMode &&
        this.parent.enableCan &&
        (value === undefined || value === null || value === '' || valueToTest < 0 || valueToTest > 255)
      return !invalid
    }),
    byte7: yup.string().test('isValid', i18n['ConfigsCS500.invalidMsdByte'], function (value) {
      const valueToTest = '0x' + value
      const invalid =
        !this.parent.forceFullAccessMode &&
        this.parent.enableCan &&
        (value === undefined || value === null || value === '' || valueToTest < 0 || valueToTest > 255)
      return !invalid
    }),
    enableDigitalInput: yup.boolean(),
    digitalInputPin: yup
      .number()
      .transform(numberTransform)
      .test('isValid', i18n['ConfigsCS500.invalidMsdDigitalInputPin'], function (value) {
        const invalid =
          !this.parent.forceFullAccessMode &&
          this.parent.enableDigitalInput &&
          (value === undefined || value === null || value === '' || value < 0 || value > 1)
        return !invalid
      }),
    enableServiceToolButton: yup.boolean(),
    sendCANMessages: yup.array().of(
      yup.object().shape({
        canPort: yup
          .number()
          .transform(numberTransform)
          .test('isValid', i18n['ConfigsCS500.invalidSendCANMessagesCanPort'], function (value) {
            const invalid = value === undefined || value === null || value === '' || value < 0 || value > 1
            return !invalid
          }),
        canId: yup.string().test('isValid', function (value) {
          const valueToTest = '0x' + value

          if (this.parent.isExtendedCanId) {
            if (value === undefined || value === null || value === '' || valueToTest < 0 || valueToTest > 0x1fffffff) {
              return this.createError({ message: i18n['ConfigsCS500.invalidExtendedCanId'], path: this.path })
            } else {
              return true
            }
          } else {
            if (value === undefined || value === null || value === '' || valueToTest < 0 || valueToTest > 0x7ff) {
              return this.createError({ message: i18n['ConfigsCS500.invalidCanId'], path: this.path })
            } else {
              return true
            }
          }
        }),
        dataSource: yup
          .number()
          .transform(numberTransform)
          .test('isValid', i18n['ConfigsCS500.invalidSendCANMessagesDataSource'], function (value) {
            const invalid = value !== 0 && value !== 240 && value !== 255
            return !invalid
          }),
        virtualSignalNumber: yup
          .number()
          .transform(numberTransform)
          .test('isValid', i18n['ConfigsCS500.invalidSendCANMessagesVirtualSignalNumber'], function (value) {
            const configuredSignals = this.options.context.configuredSignals
            const invalid = this.parent.dataSource === 0 && !configuredSignals.includes(value)
            return !invalid
          }),
        byte0: yup.string().test('isValid', i18n['ConfigsCS500.invalidSendCANMessagesByte'], function (value) {
          const valueToTest = '0x' + value
          const invalid =
            this.parent.dataSource === 255 &&
            (value === undefined || value === null || value === '' || valueToTest < 0 || valueToTest > 255)
          return !invalid
        }),
        byte1: yup.string().test('isValid', i18n['ConfigsCS500.invalidSendCANMessagesByte'], function (value) {
          const valueToTest = '0x' + value
          const invalid =
            this.parent.dataSource === 255 &&
            (value === undefined || value === null || value === '' || valueToTest < 0 || valueToTest > 255)
          return !invalid
        }),
        byte2: yup.string().test('isValid', i18n['ConfigsCS500.invalidSendCANMessagesByte'], function (value) {
          const valueToTest = '0x' + value
          const invalid =
            this.parent.dataSource === 255 &&
            (value === undefined || value === null || value === '' || valueToTest < 0 || valueToTest > 255)
          return !invalid
        }),
        byte3: yup.string().test('isValid', i18n['ConfigsCS500.invalidSendCANMessagesByte'], function (value) {
          const valueToTest = '0x' + value
          const invalid =
            this.parent.dataSource === 255 &&
            (value === undefined || value === null || value === '' || valueToTest < 0 || valueToTest > 255)
          return !invalid
        }),
        byte4: yup.string().test('isValid', i18n['ConfigsCS500.invalidSendCANMessagesByte'], function (value) {
          const valueToTest = '0x' + value
          const invalid =
            this.parent.dataSource === 255 &&
            (value === undefined || value === null || value === '' || valueToTest < 0 || valueToTest > 255)
          return !invalid
        }),
        byte5: yup.string().test('isValid', i18n['ConfigsCS500.invalidSendCANMessagesByte'], function (value) {
          const valueToTest = '0x' + value
          const invalid =
            this.parent.dataSource === 255 &&
            (value === undefined || value === null || value === '' || valueToTest < 0 || valueToTest > 255)
          return !invalid
        }),
        byte6: yup.string().test('isValid', i18n['ConfigsCS500.invalidSendCANMessagesByte'], function (value) {
          const valueToTest = '0x' + value
          const invalid =
            this.parent.dataSource === 255 &&
            (value === undefined || value === null || value === '' || valueToTest < 0 || valueToTest > 255)
          return !invalid
        }),
        byte7: yup.string().test('isValid', i18n['ConfigsCS500.invalidSendCANMessagesByte'], function (value) {
          const valueToTest = '0x' + value
          const invalid =
            this.parent.dataSource === 255 &&
            (value === undefined || value === null || value === '' || valueToTest < 0 || valueToTest > 255)
          return !invalid
        }),
        sendPeriod: yup
          .number()
          .transform(numberTransform)
          .test('isValid', function (value) {
            if (
              this.parent.periodType !== 0 &&
              this.parent.triggerType !== 3 &&
              this.parent.triggerType !== 4 &&
              this.parent.triggerType !== 8
            ) {
              const minValue = 20
              const loopTime = this.options.context.loopTime

              if (value === undefined || value === null) {
                return this.createError({
                  message: i18n['ConfigsCS500.sendCANMessagesSendPeriodIsRequired'],
                  path: this.path
                })
              } else if (value < minValue || value > MAX_VALUE_32_BIT || value % loopTime !== 0) {
                return this.createError({
                  message: i18n['ConfigsCS500.invalidSendCANMessagesSendPeriod'],
                  path: this.path
                })
              } else {
                return true
              }
            } else {
              return true
            }
          }),
        triggerType: yup
          .number()
          .transform(numberTransform)
          .test('isValid', function (value) {
            //if (this.parent.signalId < 40) {
            if (value === undefined || value === null) {
              return this.createError({ message: i18n['ConfigsCS500.triggerTypeIsRequired'], path: this.path })
            } else if (value < 0 || value > 8) {
              return this.createError({ message: i18n['ConfigsCS500.invalidTriggerType'], path: this.path })
            } else {
              return true
            }
            //} else {
            //return true
            //}
          }),
        triggerSource: yup
          .number()
          .transform(numberTransform)
          .test('isValid', function (value) {
            const configuredSignals = this.options.context.configuredSignals

            if (this.parent.triggerType !== 0 /* && this.parent.signalId < 40 */) {
              if (value === undefined || value === null) {
                return this.createError({ message: i18n['ConfigsCS500.triggerSourceIsRequired'], path: this.path })
              } else if (!configuredSignals.includes(value)) {
                return this.createError({
                  message: i18n['ConfigsCS500.invalidSendCANMessagesTriggerSource'],
                  path: this.path
                })
              } else {
                return true
              }
            } else {
              return true
            }
          }),
        sendPeriodTriggered: yup
          .number()
          .transform(numberTransform)
          .test('isValid', function (value) {
            if (
              this.parent.periodTypeTriggered !== 0 &&
              this.parent.triggerType !== 0 &&
              this.parent.triggerType !== 3 &&
              this.parent.triggerType !== 4 &&
              this.parent.triggerType !== 8
            ) {
              const minValue = 20
              const loopTime = this.options.context.loopTime

              if (value === undefined || value === null) {
                return this.createError({
                  message: i18n['ConfigsCS500.sendCANMessagesSendPeriodTriggeredIsRequired'],
                  path: this.path
                })
              } else if (value < minValue || value > MAX_VALUE_32_BIT || value % loopTime !== 0) {
                return this.createError({
                  message: i18n['ConfigsCS500.invalidSendCANMessagesSendPeriodTriggered'],
                  path: this.path
                })
              } else {
                return true
              }
            } else {
              return true
            }
          }),
        upperThreshold: yup
          .number()
          .transform(numberTransform)
          .test('isValid', function (value) {
            if (
              /*this.parent.signalId < 40 &&*/
              this.parent.triggerType !== 0 &&
              this.parent.triggerType !== 8
            ) {
              if (value === undefined || value === null) {
                return this.createError({
                  message: i18n['ConfigsCS500.triggerUpperThresholdIsRequired'],
                  path: this.path
                })
              } else if (value < 2147483648 * -1 || value > 2147483647) {
                return this.createError({ message: i18n['ConfigsCS500.invalidTriggerUpperThreshold'], path: this.path })
              } else {
                return true
              }
            } else {
              return true
            }
          }),
        lowerThreshold: yup
          .number()
          .transform(numberTransform)
          .test('isValid', function (value) {
            if (
              /*this.parent.signalId < 40 &&*/
              this.parent.triggerType === 5 ||
              this.parent.triggerType === 6
            ) {
              if (value === undefined || value === null) {
                return this.createError({
                  message: i18n['ConfigsCS500.triggerLowerThresholdIsRequired'],
                  path: this.path
                })
              } else if (value < 2147483648 * -1 || value > this.parent.upperThreshold) {
                return this.createError({ message: i18n['ConfigsCS500.invalidTriggerLowerThreshold'], path: this.path })
              } else {
                return true
              }
            } else {
              return true
            }
          }),
        thresholdDivider: yup
          .number()
          .transform(numberTransform)
          .test('isValid', function (value) {
            if (
              /*this.parent.signalId < 40 &&*/
              this.parent.triggerType !== 0 &&
              this.parent.triggerType !== 8
            ) {
              if (value === undefined || value === null) {
                return this.createError({
                  message: i18n['ConfigsCS500.triggerThresholdDividerIsRequired'],
                  path: this.path
                })
              } else if (value <= 0 || value > MAX_VALUE_32_BIT) {
                return this.createError({
                  message: i18n['ConfigsCS500.invalidTriggerThresholdDivider'],
                  path: this.path
                })
              } else {
                return true
              }
            } else {
              return true
            }
          })
      })
    ),
    inputSignal0: yup
      .number()
      .transform(numberTransform)
      .test('isValid', function (value) {
        if (value === undefined || value === null) {
          return this.createError({ message: i18n['ConfigsCS500.inputSignalIsRequired'], path: this.path })
        } else if (value < 0 || value > 4) {
          return this.createError({ message: i18n['ConfigsCS500.invalidInputSignal01'], path: this.path })
        } else {
          return true
        }
      }),
    inputSignal1: yup
      .number()
      .transform(numberTransform)
      .test('isValid', function (value) {
        if (value === undefined || value === null) {
          return this.createError({ message: i18n['ConfigsCS500.inputSignalIsRequired'], path: this.path })
        } else if (value < 0 || value > 4) {
          return this.createError({ message: i18n['ConfigsCS500.invalidInputSignal01'], path: this.path })
        } else {
          return true
        }
      }),
    inputSignal2: yup
      .number()
      .transform(numberTransform)
      .test('isValid', function (value) {
        if (value === undefined || value === null) {
          return this.createError({ message: i18n['ConfigsCS500.inputSignalIsRequired'], path: this.path })
        } else if (value < 0 || value > 2) {
          return this.createError({ message: i18n['ConfigsCS500.invalidInputSignal23'], path: this.path })
        } else {
          return true
        }
      }),
    inputSignal3: yup
      .number()
      .transform(numberTransform)
      .test('isValid', function (value) {
        if (value === undefined || value === null) {
          return this.createError({ message: i18n['ConfigsCS500.inputSignalIsRequired'], path: this.path })
        } else if (value < 0 || value > 2) {
          return this.createError({ message: i18n['ConfigsCS500.invalidInputSignal23'], path: this.path })
        } else {
          return true
        }
      }),
    inputRange0: yup
      .number()
      .transform(numberTransform)
      .test('isValid', function (value) {
        if (value === undefined || value === null) {
          return this.createError({ message: i18n['ConfigsCS500.inputRangeIsRequired'], path: this.path })
        } else if (value < 0 || value > 3) {
          return this.createError({ message: i18n['ConfigsCS500.invalidInputRange'], path: this.path })
        } else {
          return true
        }
      }),
    inputRange1: yup
      .number()
      .transform(numberTransform)
      .test('isValid', function (value) {
        if (value === undefined || value === null) {
          return this.createError({ message: i18n['ConfigsCS500.inputRangeIsRequired'], path: this.path })
        } else if (value < 0 || value > 3) {
          return this.createError({ message: i18n['ConfigsCS500.invalidInputRange'], path: this.path })
        } else {
          return true
        }
      }),
    inputRange2: yup
      .number()
      .transform(numberTransform)
      .test('isValid', function (value) {
        if (value === undefined || value === null) {
          return this.createError({ message: i18n['ConfigsCS500.inputRangeIsRequired'], path: this.path })
        } else if (value < 0 || value > 3) {
          return this.createError({ message: i18n['ConfigsCS500.invalidInputRange'], path: this.path })
        } else {
          return true
        }
      }),
    inputRange3: yup
      .number()
      .transform(numberTransform)
      .test('isValid', function (value) {
        if (value === undefined || value === null) {
          return this.createError({ message: i18n['ConfigsCS500.inputRangeIsRequired'], path: this.path })
        } else if (value < 0 || value > 3) {
          return this.createError({ message: i18n['ConfigsCS500.invalidInputRange'], path: this.path })
        } else {
          return true
        }
      }),
    bias0: yup
      .number()
      .transform(numberTransform)
      .test('isValid', function (value) {
        if (value === undefined || value === null) {
          return this.createError({ message: i18n['ConfigsCS500.biasIsRequired'], path: this.path })
        } else if (value < 0 || value > 3) {
          return this.createError({ message: i18n['ConfigsCS500.invalidBias'], path: this.path })
        } else {
          return true
        }
      }),
    bias1: yup
      .number()
      .transform(numberTransform)
      .test('isValid', function (value) {
        if (value === undefined || value === null) {
          return this.createError({ message: i18n['ConfigsCS500.biasIsRequired'], path: this.path })
        } else if (value < 0 || value > 3) {
          return this.createError({ message: i18n['ConfigsCS500.invalidBias'], path: this.path })
        } else {
          return true
        }
      }),
    bias2: yup
      .number()
      .transform(numberTransform)
      .test('isValid', function (value) {
        if (value === undefined || value === null) {
          return this.createError({ message: i18n['ConfigsCS500.biasIsRequired'], path: this.path })
        } else if (value < 0 || value > 3) {
          return this.createError({ message: i18n['ConfigsCS500.invalidBias'], path: this.path })
        } else {
          return true
        }
      }),
    bias3: yup
      .number()
      .transform(numberTransform)
      .test('isValid', function (value) {
        if (value === undefined || value === null) {
          return this.createError({ message: i18n['ConfigsCS500.biasIsRequired'], path: this.path })
        } else if (value < 0 || value > 3) {
          return this.createError({ message: i18n['ConfigsCS500.invalidBias'], path: this.path })
        } else {
          return true
        }
      }),
    digThreshHigh0: yup
      .number()
      .transform(numberTransform)
      .test('isValid', function (value) {
        const pinMode = this.parent.inputSignal0

        if (pinMode === 1) {
          if (value === undefined || value === null) {
            return this.createError({ message: i18n['ConfigsCS500.digThreshHighIsRequired'], path: this.path })
          } else if (value < 0 || value > 65535) {
            return this.createError({ message: i18n['ConfigsCS500.invalidDigThreshHigh'], path: this.path })
          } else {
            return true
          }
        } else {
          return true
        }
      }),
    digThreshLow0: yup
      .number()
      .transform(numberTransform)
      .test('isValid', function (value) {
        const pinMode = this.parent.inputSignal0

        if (pinMode === 1) {
          if (value === undefined || value === null) {
            return this.createError({ message: i18n['ConfigsCS500.digThreshLowIsRequired'], path: this.path })
          } else if (value < 0 || value >= this.parent.digThreshHigh) {
            return this.createError({ message: i18n['ConfigsCS500.invalidDigThreshLow'], path: this.path })
          } else {
            return true
          }
        } else {
          return true
        }
      }),
    digThreshHigh1: yup
      .number()
      .transform(numberTransform)
      .test('isValid', function (value) {
        const pinMode = this.parent.inputSignal1

        if (pinMode === 1) {
          if (value === undefined || value === null) {
            return this.createError({ message: i18n['ConfigsCS500.digThreshHighIsRequired'], path: this.path })
          } else if (value < 0 || value > 65535) {
            return this.createError({ message: i18n['ConfigsCS500.invalidDigThreshHigh'], path: this.path })
          } else {
            return true
          }
        } else {
          return true
        }
      }),
    digThreshLow1: yup
      .number()
      .transform(numberTransform)
      .test('isValid', function (value) {
        const pinMode = this.parent.inputSignal1

        if (pinMode === 1) {
          if (value === undefined || value === null) {
            return this.createError({ message: i18n['ConfigsCS500.digThreshLowIsRequired'], path: this.path })
          } else if (value < 0 || value >= this.parent.digThreshHigh) {
            return this.createError({ message: i18n['ConfigsCS500.invalidDigThreshLow'], path: this.path })
          } else {
            return true
          }
        } else {
          return true
        }
      }),
    digThreshHigh2: yup
      .number()
      .transform(numberTransform)
      .test('isValid', function (value) {
        const pinMode = this.parent.inputSignal2

        if (pinMode === 1) {
          if (value === undefined || value === null) {
            return this.createError({ message: i18n['ConfigsCS500.digThreshHighIsRequired'], path: this.path })
          } else if (value < 0 || value > 65535) {
            return this.createError({ message: i18n['ConfigsCS500.invalidDigThreshHigh'], path: this.path })
          } else {
            return true
          }
        } else {
          return true
        }
      }),
    digThreshLow2: yup
      .number()
      .transform(numberTransform)
      .test('isValid', function (value) {
        const pinMode = this.parent.inputSignal2

        if (pinMode === 1) {
          if (value === undefined || value === null) {
            return this.createError({ message: i18n['ConfigsCS500.digThreshLowIsRequired'], path: this.path })
          } else if (value < 0 || value >= this.parent.digThreshHigh) {
            return this.createError({ message: i18n['ConfigsCS500.invalidDigThreshLow'], path: this.path })
          } else {
            return true
          }
        } else {
          return true
        }
      }),
    digThreshHigh3: yup
      .number()
      .transform(numberTransform)
      .test('isValid', function (value) {
        const pinMode = this.parent.inputSignal0

        if (pinMode === 1) {
          if (value === undefined || value === null) {
            return this.createError({ message: i18n['ConfigsCS500.digThreshHighIsRequired'], path: this.path })
          } else if (value < 0 || value > 65535) {
            return this.createError({ message: i18n['ConfigsCS500.invalidDigThreshHigh'], path: this.path })
          } else {
            return true
          }
        } else {
          return true
        }
      }),
    digThreshLow3: yup
      .number()
      .transform(numberTransform)
      .test('isValid', function (value) {
        const pinMode = this.parent.inputSignal0

        if (pinMode === 1) {
          if (value === undefined || value === null) {
            return this.createError({ message: i18n['ConfigsCS500.digThreshLowIsRequired'], path: this.path })
          } else if (value < 0 || value >= this.parent.digThreshHigh) {
            return this.createError({ message: i18n['ConfigsCS500.invalidDigThreshLow'], path: this.path })
          } else {
            return true
          }
        } else {
          return true
        }
      }),
    signals: yup.array().of(
      yup.object().shape({
        signalId: yup
          .number()
          .transform(numberTransform)
          .required(i18n['ConfigsCS500.signalIdIsRequired'])
          .nullable()
          .test('isValid', i18n['ConfigsCS500.invalidSignalId'], function (value) {
            const editableId = this.parent.editableId
            const invalid = value < 0 || value > 79 || editableId === true
            return !invalid
          }),
        name: yup.string().required(i18n['ConfigsCS500.nameIsRequired']),
        logToPortal: yup.boolean().required(i18n['ConfigsCS500.logToPortalIsRequired']).nullable(),
        signalSource: yup
          .number()
          .transform(numberTransform)
          .required(i18n['ConfigsCS500.signalSourceIsRequired'])
          .nullable()
          .test('isValid', i18n['ConfigsCS500.invalidSignalSource'], function (value) {
            const invalid = value < 1 || value > 3
            return !invalid
          }),
        cch: yup
          .number()
          .transform(numberTransform)
          .test('isValid', function (value) {
            if (this.parent.signalSource === 1 || this.parent.signalSource === 2) {
              if (value === undefined || value === null) {
                return this.createError({ message: i18n['ConfigsCS500.canPortIsRequired'], path: this.path })
              } else if (value < 0 || value > 2) {
                return this.createError({ message: i18n['ConfigsCS500.invalidCanPort'], path: this.path })
              } else {
                return true
              }
            } else if (this.parent.signalSource === 3) {
              if (value === undefined || value === null) {
                return this.createError({ message: i18n['ConfigsCS500.mfioPinIsRequired'], path: this.path })
              } else if (value < 0 || value > 3) {
                return this.createError({ message: i18n['ConfigsCS500.invalidMfioPin'], path: this.path })
              } else {
                return true
              }
            }
          }),
        piu: yup.string().test('isValid', function (value) {
          if (this.parent.signalSource === 1) {
            if (value === undefined || value === null || value === '') {
              return this.createError({ message: i18n['ConfigsCS500.pgnIsRequired'], path: this.path })
            } else if (value < 0 || value > 262143) {
              return this.createError({ message: i18n['ConfigsCS500.invalidPgn'], path: this.path })
            } else {
              return true
            }
          } else if (this.parent.signalSource === 2) {
            if (value === undefined || value === null || value === '') {
              return this.createError({ message: i18n['ConfigsCS500.canIdIsRequired'], path: this.path })
            } else if (this.parent.isExtendedCanId) {
              if (isHex(value) && value <= 0x1fffffff) {
                return true
              } else {
                return this.createError({ message: i18n['ConfigsCS500.invalidExtendedCanId'], path: this.path })
              }
            } else if (!this.parent.isExtendedCanId) {
              if (isHex(value) && value <= 0x7ff) {
                return true
              } else {
                return this.createError({ message: i18n['ConfigsCS500.invalidCanId'], path: this.path })
              }
            }
          } else if (this.parent.signalSource === 3) {
            if (value === undefined || value === null || value === '') {
              return this.createError({ message: i18n['ConfigsCS500.upperLimitIsRequired'], path: this.path })
            } else if (parseInt(value) < 0 || parseInt(value) > MAX_VALUE_32_BIT) {
              return this.createError({ message: i18n['ConfigsCS500.invalidUpperLimit'], path: this.path })
            } else {
              return true
            }
          } else {
            return true
          }
        }),
        sml: yup.string().test('isValid', function (value) {
          if (this.parent.signalSource === 1) {
            if (value === undefined || value === null || value === '') {
              return this.createError({ message: i18n['ConfigsCS500.spnIsRequired'], path: this.path })
            } else if (value < 0 || value > MAX_VALUE_32_BIT) {
              return this.createError({ message: i18n['ConfigsCS500.invalidSpn'], path: this.path })
            } else {
              return true
            }
          } else if (this.parent.signalSource === 2) {
            if (value === undefined || value === null || value === '') {
              return this.createError({ message: i18n['ConfigsCS500.maskIsRequired'], path: this.path })
            } else if (this.parent.isExtendedCanId) {
              if (isHex(value) && value <= 0x1fffffff) {
                return true
              } else {
                return this.createError({ message: i18n['ConfigsCS500.invalidExtendedMask'], path: this.path })
              }
            } else if (!this.parent.isExtendedCanId) {
              if (isHex(value) && value <= 0x7ff) {
                return true
              } else {
                return this.createError({ message: i18n['ConfigsCS500.invalidMask'], path: this.path })
              }
            }
          } else if (this.parent.signalSource === 3) {
            if (value === undefined || value === null || value === '') {
              return this.createError({ message: i18n['ConfigsCS500.lowerLimitIsRequired'], path: this.path })
            } else if (parseInt(value) < 0 || parseInt(value) >= this.parent.piu) {
              return this.createError({ message: i18n['ConfigsCS500.invalidLowerLimit'], path: this.path })
            } else {
              return true
            }
          } else {
            return true
          }
        }),
        ss: yup
          .number()
          .transform(numberTransform)
          .test('isValid', function (value) {
            if (this.parent.signalSource === 1) {
              if (value === undefined || value === null) {
                return this.createError({ message: i18n['ConfigsCS500.sourceAddressIsRequired'], path: this.path })
              } else if (value < 0 || value > 255) {
                return this.createError({ message: i18n['ConfigsCS500.invalidSourceAddress'], path: this.path })
              } else {
                return true
              }
            } else if (this.parent.signalSource === 2) {
              if (value === undefined || value === null) {
                return this.createError({ message: i18n['ConfigsCS500.startBitIsRequired'], path: this.path })
              } else {
                const lengthOfBits = this.parent.lengthOfBits

                const invalidValue = value < 0 || value >= 64
                const invalidSum = value + lengthOfBits > 64
                const invalid = invalidSum || invalidValue

                if (invalid) {
                  return this.createError({ message: i18n['ConfigsCS500.invalidStartBit'], path: this.path })
                } else {
                  return true
                }
              }
            } else {
              return true
            }
          }),
        lengthOfBits: yup
          .number()
          .transform(numberTransform)
          .test('isValid', function (value) {
            if (this.parent.signalSource === 2) {
              if (value === undefined || value === null) {
                return this.createError({ message: i18n['ConfigsCS500.lengthIsRequired'], path: this.path })
              } else {
                const startBit = this.parent.startBit
                const offset = this.parent.offset
                const valueToTestCondition = Math.pow(2, value) - 1 + offset

                const invalidValue = value <= 0 || value > 64
                const invalidSum = value + startBit > 64
                const invalidCondition = valueToTestCondition < 2147483648 * -1 || valueToTestCondition > 2147483647
                const invalid = invalidSum || invalidValue || invalidCondition
                if (invalid) {
                  return this.createError({ message: i18n['ConfigsCS500.invalidLength'], path: this.path })
                } else {
                  return true
                }
              }
            } else {
              return true
            }
          }),
        multiplier: yup
          .number()
          .transform(numberTransform)
          .test('isValid', function (value) {
            if (this.parent.signalSource === 2 || this.parent.signalSource === 3) {
              if (value === undefined || value === null) {
                return this.createError({ message: i18n['ConfigsCS500.multiplierIsRequired'], path: this.path })
              } else if (value <= 0 || value > MAX_VALUE_32_BIT || !Number.isInteger(value)) {
                return this.createError({ message: i18n['ConfigsCS500.invalidMultiplier'], path: this.path })
              } else {
                return true
              }
            } else {
              return true
            }
          }),
        divider: yup
          .number()
          .transform(numberTransform)
          .test('isValid', function (value) {
            if (this.parent.signalSource === 2 || this.parent.signalSource === 3) {
              if (value === undefined || value === null) {
                return this.createError({ message: i18n['ConfigsCS500.dividerIsRequired'], path: this.path })
              } else if (value <= 0 || value > MAX_VALUE_32_BIT || !Number.isInteger(value)) {
                return this.createError({ message: i18n['ConfigsCS500.invalidDivider'], path: this.path })
              } else {
                return true
              }
            } else {
              return true
            }
          }),
        offset: yup
          .number()
          .transform(numberTransform)
          .test('isValid', function (value) {
            if (this.parent.signalSource === 2 || this.parent.signalSource === 3) {
              if (value === undefined || value === null) {
                return this.createError({ message: i18n['ConfigsCS500.offsetIsRequired'], path: this.path })
              } else {
                const offset = value
                const lengthOfBits = this.parent.lengthOfBits
                const valueToTestCondition = Math.pow(2, lengthOfBits) - 1 + offset

                const invalidValue = offset < 2147483648 * -1 || offset > 2147483647
                const invalidCondition = valueToTestCondition < 2147483648 * -1 || valueToTestCondition > 2147483647
                const invalid = invalidValue || invalidCondition

                if (invalid) {
                  return this.createError({ message: i18n['ConfigsCS500.invalidOffset'], path: this.path })
                } else {
                  return true
                }
              }
            } else {
              return true
            }
          }),
        unit: yup
          .number()
          .transform(numberTransform)
          .test('isValid', function (value) {
            if (
              this.parent.unitType === 0 && this.parent.signalSource === 2 ||
              this.parent.signalSource === 3 && this.parent.inputSignal !== 1
            ) {
              if (
                (this.parent.customUnit === undefined || this.parent.customUnit == null) && value === undefined ||
                value === null
              ) {
                return this.createError({ message: i18n['ConfigsCS500.unitIsRequired'], path: this.path })
              } else if (value < 0 || value > 32) {
                return this.createError({ message: i18n['ConfigsCS500.invalidUnit'], path: this.path })
              } else {
                return true
              }
            } else {
              return true
            }
          }),
        customUnit: yup.string().nullable(),
        filterType: yup
          .number()
          .transform(numberTransform)
          .test('isValid', i18n['ConfigsCS500.invalidFilterType'], function (value) {
            const invalid =
              this.parent.signalId < 40 && (value === undefined || value === null || value < 0 || value > 7)
            return !invalid
          }),
        cornerFrequency: yup
          .number()
          .transform(numberTransform)
          .test('isValid', function (value) {
            if (this.parent.signalId < 40 && this.parent.filterType > 0 && this.parent.filterType < 6) {
              if (value === undefined || value === null) {
                return this.createError({ message: i18n['ConfigsCS500.cornerFrequencyIsRequired'], path: this.path })
              } else if (value < 0 || value > 1000 / (this.options.context.loopTime * 2) * 100) {
                return this.createError({ message: i18n['ConfigsCS500.invalidCornerFrequency'], path: this.path })
              } else {
                return true
              }
            } else {
              return true
            }
          }),
        quotient: yup
          .number()
          .transform(numberTransform)
          .test('isValid', function (value) {
            if (this.parent.signalId < 40 && this.parent.filterType > 0 && this.parent.filterType < 6) {
              if (value === undefined || value === null) {
                return this.createError({ message: i18n['ConfigsCS500.quotientIsRequired'], path: this.path })
              } else if (value < 0 || value > 10000) {
                return this.createError({ message: i18n['ConfigsCS500.invalidQuotient'], path: this.path })
              } else {
                return true
              }
            } else {
              return true
            }
          }),
        gain: yup
          .number()
          .transform(numberTransform)
          .test('isValid', function (value) {
            if (this.parent.signalId < 40 && this.parent.filterType === 5) {
              if (value === undefined || value === null) {
                return this.createError({ message: i18n['ConfigsCS500.gainIsRequired'], path: this.path })
              } else if (value < 0 || value > 1000) {
                return this.createError({ message: i18n['ConfigsCS500.invalidGain'], path: this.path })
              } else {
                return true
              }
            } else {
              return true
            }
          }),
        sendImmediately: yup.boolean().test('isRequired', function (value) {
          if (this.parent.logToPortal) {
            if (this.parent.signalId < 40 && typeof value !== 'boolean') {
              return this.createError({ message: i18n['ConfigsCS500.sendImmediatelyIsRequired'], path: this.path })
            } else {
              return true
            }
          } else {
            return true
          }
        }),
        logType: yup
          .number()
          .transform(numberTransform)
          .test('isValid', function (value) {
            if (
              this.parent.logToPortal &&
              !this.parent.sendImmediately &&
              this.parent.triggerType !== 3 &&
              this.parent.triggerType !== 4 &&
              this.parent.triggerType !== 8
            ) {
              if (value === undefined || value === null) {
                return this.createError({ message: i18n['ConfigsCS500.logTypeIsRequired'], path: this.path })
              } else if (value < 1 || value > 2) {
                return this.createError({ message: i18n['ConfigsCS500.invalidLogType'], path: this.path })
              } else {
                return true
              }
            } else {
              return true
            }
          }),
        samplePeriod: yup
          .number()
          .transform(numberTransform)
          .test('isValid', function (value) {
            if (
              this.parent.logToPortal &&
              !this.parent.sendImmediately &&
              this.parent.logType === 2 &&
              this.parent.triggerType !== 3 &&
              this.parent.triggerType !== 4 &&
              this.parent.triggerType !== 8
            ) {
              const minValue = this.options.context.signalsToLog * 12.5
              // const MAX_AMOUNT_LOGS = 80
              // const logPeriods = this.options.context.logPeriods
              // const triggerLogPeriods = this.options.context.triggerLogPeriods
              // const minLogPeriods = logPeriods.map((logPeriod, i) => {
              //   if (Number.isNaN(triggerLogPeriods[i]) || logPeriod < triggerLogPeriods[i]) {
              //     return logPeriod
              //   } else {
              //     return triggerLogPeriods[i]
              //   }
              // })

              // const initialValue = 0
              // const amountOfLogs = minLogPeriods.reduce(
              //   (previousValue, currentValue) => previousValue + 1 / (currentValue / 1000),
              //   initialValue
              // )
              const loopTime = this.options.context.loopTime

              if (value === undefined || value === null) {
                return this.createError({ message: i18n['ConfigsCS500.samplePeriodIsRequired'], path: this.path })
              } else if (value < minValue || value > MAX_VALUE_32_BIT || value % loopTime !== 0) {
                // } else if (amountOfLogs > MAX_AMOUNT_LOGS || value > MAX_VALUE_32_BIT || value % loopTime !== 0) {
                return this.createError({ message: i18n['ConfigsCS500.invalidSamplePeriod'], path: this.path })
              } else {
                return true
              }
            } else {
              return true
            }
          }),
        triggerType: yup
          .number()
          .transform(numberTransform)
          .test('isValid', function (value) {
            if (this.parent.signalId < 40 && this.parent.logToPortal && !this.parent.sendImmediately) {
              if (value === undefined || value === null) {
                return this.createError({ message: i18n['ConfigsCS500.triggerTypeIsRequired'], path: this.path })
              } else if (value < 0 || value > 8) {
                return this.createError({ message: i18n['ConfigsCS500.invalidTriggerType'], path: this.path })
              } else {
                return true
              }
            } else {
              return true
            }
          }),
        triggerSource: yup
          .number()
          .transform(numberTransform)
          .test('isValid', function (value) {
            if (
              this.parent.signalId < 40 &&
              this.parent.logToPortal &&
              !this.parent.sendImmediately &&
              this.parent.triggerType !== 0
            ) {
              if (value === undefined || value === null) {
                return this.createError({ message: i18n['ConfigsCS500.triggerSourceIsRequired'], path: this.path })
              } else if (value < 0 || value > 79) {
                return this.createError({ message: i18n['ConfigsCS500.invalidTriggerSource'], path: this.path })
              } else {
                return true
              }
            } else {
              return true
            }
          }),
        triggerLogType: yup
          .number()
          .transform(numberTransform)
          .test('isValid', function (value) {
            if (
              this.parent.signalId < 40 &&
              this.parent.logToPortal &&
              !this.parent.sendImmediately &&
              this.parent.triggerType !== 0 &&
              this.parent.triggerType !== 3 &&
              this.parent.triggerType !== 4 &&
              this.parent.triggerType !== 8
            ) {
              if (value === undefined || value === null) {
                return this.createError({ message: i18n['ConfigsCS500.triggerLogTypeIsRequired'], path: this.path })
              } else if (value < 1 || value > 2) {
                return this.createError({ message: i18n['ConfigsCS500.invalidTriggerLogType'], path: this.path })
              } else {
                return true
              }
            } else {
              return true
            }
          }),
        samplePeriodTriggered: yup
          .number()
          .transform(numberTransform)
          .test('isValid', function (value) {
            if (
              this.parent.signalId < 40 &&
              this.parent.logToPortal &&
              !this.parent.sendImmediately &&
              this.parent.triggerType !== 0 &&
              this.parent.triggerType !== 3 &&
              this.parent.triggerType !== 4 &&
              this.parent.triggerType !== 8 &&
              this.parent.triggerLogType === 2
            ) {
              const minValue = this.options.context.signalsToLog * 12.5

              // const MAX_AMOUNT_LOGS = 80
              // const logPeriods = this.options.context.logPeriods
              // const triggerLogPeriods = this.options.context.triggerLogPeriod
              // const minLogPeriods = logPeriods.map((logPeriod, i) => {
              //   if (Number.isNaN(triggerLogPeriods[i]) || logPeriod < triggerLogPeriods[i]) {
              //     return logPeriod
              //   } else {
              //     return triggerLogPeriods[i]
              //   }
              // })

              // const initialValue = 0
              // const amountOfLogs = minLogPeriods.reduce(
              //   (previousValue, currentValue) => previousValue + 1 / (currentValue / 1000),
              //   initialValue
              // )
              const loopTime = this.options.context.loopTime

              if (value === undefined || value === null) {
                return this.createError({
                  message: i18n['ConfigsCS500.samplePeriodTriggeredIsRequired'],
                  path: this.path
                })
              } else if (value < minValue || value > MAX_VALUE_32_BIT || value % loopTime !== 0) {
                //} else if (amountOfLogs > MAX_AMOUNT_LOGS || value > MAX_VALUE_32_BIT || value % loopTime !== 0) {
                return this.createError({
                  message: i18n['ConfigsCS500.invalidSamplePeriodTriggered'],
                  path: this.path
                })
              } else {
                return true
              }
            } else {
              return true
            }
          }),
        triggerSendImmediately: yup.boolean().test('isValid', function (value) {
          if (
            this.parent.signalId < 40 &&
            this.parent.logToPortal &&
            !this.parent.sendImmediately &&
            (this.parent.triggerType === 3 || this.parent.triggerType === 4 || this.parent.triggerType === 8)
          ) {
            if (typeof value !== 'boolean') {
              return this.createError({
                message: i18n['ConfigsCS500.triggerSendImmediatelyIsRequired'],
                path: this.path
              })
            } else {
              return true
            }
          } else {
            return true
          }
        }),
        triggerUpperThreshold: yup
          .number()
          .transform(numberTransform)
          .test('isValid', function (value) {
            if (
              this.parent.signalId < 40 &&
              this.parent.logToPortal &&
              !this.parent.sendImmediately &&
              this.parent.triggerType !== 0 &&
              this.parent.triggerType !== 8
            ) {
              if (value === undefined || value === null) {
                return this.createError({
                  message: i18n['ConfigsCS500.triggerUpperThresholdIsRequired'],
                  path: this.path
                })
              } else if (value < 2147483648 * -1 || value > 2147483647) {
                return this.createError({ message: i18n['ConfigsCS500.invalidTriggerUpperThreshold'], path: this.path })
              } else {
                return true
              }
            } else {
              return true
            }
          }),
        triggerLowerThreshold: yup
          .number()
          .transform(numberTransform)
          .test('isValid', function (value) {
            if (
              this.parent.signalId < 40 &&
              this.parent.logToPortal &&
              !this.parent.sendImmediately &&
              (this.parent.triggerType === 5 || this.parent.triggerType === 6)
            ) {
              if (value === undefined || value === null) {
                return this.createError({
                  message: i18n['ConfigsCS500.triggerLowerThresholdIsRequired'],
                  path: this.path
                })
              } else if (value < 2147483648 * -1 || value >= this.parent.triggerUpperThreshold) {
                return this.createError({ message: i18n['ConfigsCS500.invalidTriggerLowerThreshold'], path: this.path })
              } else {
                return true
              }
            } else {
              return true
            }
          }),
        triggerThresholdDivider: yup
          .number()
          .transform(numberTransform)
          .test('isValid', function (value) {
            if (
              this.parent.signalId < 40 &&
              this.parent.logToPortal &&
              !this.parent.sendImmediately &&
              this.parent.triggerType !== 0 &&
              this.parent.triggerType !== 8
            ) {
              if (value === undefined || value === null) {
                return this.createError({
                  message: i18n['ConfigsCS500.triggerThresholdDividerIsRequired'],
                  path: this.path
                })
              } else if (value <= 0 || value > MAX_VALUE_32_BIT) {
                return this.createError({
                  message: i18n['ConfigsCS500.invalidTriggerThresholdDivider'],
                  path: this.path
                })
              } else {
                return true
              }
            } else {
              return true
            }
          })
      })
    )
  })
}
