import { createTheme } from '@material-ui/core/styles'
import getMuiTheme from 'material-ui/styles/getMuiTheme'

import palette, { palleteV0 } from './pallete'

const customTheme = {
  palette,
  typography: {
    htmlFontSize: 10,
    body1: {
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: 'inherit'
    },
    h5: {
      color: 'rgba(0, 0, 0, 0.87)'
    },
    h6: {
      color: 'rgba(0, 0, 0, 0.87)'
    }
  }
}

const v0Theme = {
  palette: palleteV0,
  spacing: {
    iconSize: 24,
    desktopGutter: 24,
    desktopGutterMore: 32,
    desktopGutterLess: 16,
    desktopGutterMini: 8,
    desktopKeylineIncrement: 64,
    desktopDropDownMenuItemHeight: 32,
    desktopDropDownMenuFontSize: 15,
    desktopDrawerMenuItemHeight: 48,
    desktopSubheaderHeight: 48,
    desktopToolbarHeight: 56
  },
  fontFamily: 'Roboto, sans-serif',
  tabs: {
    backgroundColor: palleteV0.primary1Color,
    secondary: {
      backgroundColor: palleteV0.primary2Color
    }
  },
  inkBar: {
    // Other props like height are not overrided unless a inkBarStyle prop
    // is set on the Tab ¬¬
    backgroundColor: palleteV0.primary6color
  },
  toolbar: {
    titleFontSize: '18px',
    secondary: {
      backgroundColor: palleteV0.primary1Color,
      // I did not found a better way to define color for the ToolbarTitle than
      // overriding its style with the following property wherever it is used :-(
      color: palleteV0.secondaryTextColor
    }
  },
  datePicker: {
    // Necessary for date picker, time picker takes it automatically.
    selectColor: palleteV0.pickerHeaderColor
  }
}

const defaultTheme = createTheme(customTheme)
const themeV0 = getMuiTheme(v0Theme)

const {
  breakpoints,
  typography: { pxToRem }
} = defaultTheme

const theme = {
  ...defaultTheme,
  overrides: {
    MuiDialogContentText: {
      root: {
        fontSize: '1.6rem',
        marginBottom: 0
      }
    },
    MuiFormLabel: {
      root: {
        fontSize: '1.6rem',
        fontWeight: 'bold',
        color: 'rgba(0, 0, 0, 0.54)'
      },
      focused: {
        color: 'rgba(0, 0, 0, 0.87)'
      }
    },
    MuiInputBase: {
      root: {
        fontSize: '1.6rem'
      }
    },
    MuiListItem: {
      root: {
        paddingTop: 11,
        paddingBottom: 11
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 'inherit',
        marginRight: 16
      }
    },
    MuiListItemText: {
      inset: {
        paddingLeft: 16,
        paddingRight: 16
      },
      root: {
        marginTop: 0,
        marginBottom: 0
      }
    },
    MuiMenuItem: {
      root: {
        paddingTop: 11,
        paddingBottom: 11,
        fontSize: '1.6rem'
      }
    },
    MuiPaper: {
      root: {
        color: 'inherit'
      }
    },
    MuiTab: {
      root: {
        [breakpoints.up('sm')]: {
          minWidth: 72
        },
        [breakpoints.up('md')]: {
          fontSize: pxToRem(13),
          minWidth: 160
        }
      }
    },
    MuiTypography: {
      root: {
        display: 'block'
      }
    }
  }
}

export default theme
export { themeV0 }
