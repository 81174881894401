import PropTypes from 'prop-types'
import React from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { injectIntl } from 'react-intl'
import 'core-js/proposals/promise-all-settled'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import Loading from 'components/Loading'
import SelectColumnsButton from 'components/SelectColumnsButton'
import { logError, mapDynamicDevicesFilterObject, getFiltersMatchingDevices } from 'utils/http'
import { utcTimeToBrowserLocal } from 'utils/timeFormat'

import messages from './messages'
import { mapDevicesToTypeGroupedDevices } from '../utils'

class AssignNodesToGroupTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      nodes: [],

      currentGroupDeviceIds: [],
      isCurrentDevicesLoading: true,

      isDevicesLoading: true,

      filter: {},
      sort: {},

      count: null,
      length: 10,
      page: 1,
      start: 0,
      selectedNodes: [],
      accumulatedNodes: [],
      noDataText: <CircularProgress />,

      visibleColumns: {
        name: true,
        machineModel: true,
        machineType: true,
        machineBrand: false,
        machineSerialNumber: true,
        deviceType: true,
        eid: false,
        lastActivity: false,
        firmwareVersion: false,
        deviceConfiguration: true,
        deviceConfigurationVersion: false
      }
    }

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage
  }

  componentDidMount() {
    this.getCurrentGroupDevices()
    this.getNodes()
  }

  getCurrentGroupDevices = async () => {
    const { getGroupDevices, groupId, setAlert } = this.props

    if (!groupId) return

    const limit = Infinity
    const offset = 0
    try {
      const deviceFields = { Device: ['eid'] }
      const devicesResponse = await getGroupDevices(groupId, limit, offset, deviceFields)
      const { devices } = devicesResponse.data
      const currentGroupDeviceIds = devices.map(device => device.eid)
      this.setState({
        currentGroupDeviceIds,
        isCurrentDevicesLoading: false
      })
    } catch (response) {
      const error = response.error
      if (error.response) {
        switch (error.response.status) {
          case 400: // Bad request
          case 401: // Invalid credentials
          case 403: // Access denied
          case 406: // Not acceptable
          case 500: // Unexpected error
            this.setState(
              {
                isCurrentDevicesLoading: false
              },
              () => {
                setAlert({
                  alertMessages: true,
                  alertMessagesType: 'danger',
                  alertMessagesTitle: this.formatMessage(messages.error, { number: error.response.status }),
                  alertMessagesText: [this.formatMessage(messages['error' + error.response.status + 'Message'])]
                })
              }
            )
            break
          case 404: // API url not found
            const errorMessage =
              typeof error.response.data === 'string' ? error.response.data : error.response.data.message
            if (typeof errorMessage === 'string' && errorMessage.toUpperCase().includes('NO DEVICE')) {
              this.setState({
                currentGroupDeviceIds: [],
                isCurrentDevicesLoading: false
              })
            } else {
              this.setState(
                {
                  isCurrentDevicesLoading: false
                },
                () => {
                  setAlert({
                    alertMessages: true,
                    alertMessagesType: 'danger',
                    alertMessagesTitle: this.formatMessage(messages.error, { number: error.response.status }),
                    alertMessagesText: [this.formatMessage(messages['error' + error.response.status + 'Message'])]
                  })
                }
              )
            }
            break
          default:
            this.setState(
              {
                isCurrentDevicesLoading: false
              },
              () => {
                setAlert({
                  alertMessages: true,
                  alertMessagesType: 'danger',
                  alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
                  alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
                })
              }
            )
            logError(response)
        }
      } else {
        this.setState(
          {
            isCurrentDevicesLoading: false
          },
          () => {
            setAlert({
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
              alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
            })
          }
        )
        logError(response)
      }
    }
  }

  getNodes = async () => {
    const { getGroupDevices, groupsHierarchy, setAlert } = this.props
    const { length, start, accumulatedNodes, selectedNodes } = this.state
    let selectedNodeIds = [...selectedNodes]

    const [{ hashId: hashIdValue }] = groupsHierarchy

    try {
      let count = 0
      let nodes = []
      let newNodes = []
      const noDataText = this.formatMessage(messages.noMachines)

      if (hashIdValue) {
        const { filter, sort } = this.state
        const mappedFilters = mapDynamicDevicesFilterObject(filter, sort)
        const accumulatedNodeIds = accumulatedNodes.map(node => node.hashId)
        const deviceFields = {
          Device: [
            'id',
            'name',
            'device_type',
            'eid',
            'operating_time',
            'main_firmware_version',
            'created_at',
            'machine_model',
            'machine_type',
            'machine_brand',
            'machine_serial_number'
          ],
          Configuration: ['name', 'version_number', 'id']
        }
        this.setState({ isDevicesLoading: true })
        const parentGroupDevicesResponse = await getGroupDevices(
          hashIdValue,
          length,
          start,
          deviceFields,
          mappedFilters
        )
        const { total, devices } = parentGroupDevicesResponse.data
        nodes = devices.map(device => {
          const { id, ...deviceProperties } = device
          return {
            hashId: id,
            lastActivityTime: deviceProperties?.operating_time?.lastDateTime,
            ...deviceProperties
          }
        })
        count = total
        newNodes = nodes.filter(node => !accumulatedNodeIds.includes(node.hashId))

        let selectedNodesData = selectedNodes.map(nodeId => accumulatedNodes.find(node => node.eid === nodeId))
        selectedNodesData = selectedNodesData.map(node => {
          const { hashId, ...rest } = node
          return { ...rest, id: hashId }
        })
        const filterMatchingDevices = getFiltersMatchingDevices(filter, selectedNodesData)
        selectedNodeIds = filterMatchingDevices.map(device => device.eid)
      }

      this.setState(({ accumulatedNodes: accNodes }) => ({
        accumulatedNodes: accNodes.concat(newNodes),
        count,
        isDevicesLoading: false,
        noDataText,
        nodes,
        selectedNodes: selectedNodeIds
      }))
    } catch (response) {
      this.setState({ isDevicesLoading: false })
      const error = response.error
      if (error?.response) {
        switch (error.response.status) {
          case 400: // Bad request
          case 401: // Invalid credentials
          case 403: // Access denied
          case 406: // Not acceptable
          case 500: // Unexpected error
            setAlert({
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: error.response.status }),
              alertMessagesText: [this.formatMessage(messages['error' + error.response.status + 'Message'])]
            })

            break
          case 404: // API url not found
            const errorMessage =
              typeof error.response.data === 'string' ? error.response.data : error.response.data.message
            if (typeof errorMessage === 'string' && errorMessage.toUpperCase().includes('NO DEVICE')) {
              this.setState({
                count: 0,
                nodes: [],
                noDataText: this.formatMessage(messages.originGroupHasNoMachines)
              })
            } else {
              setAlert({
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, { number: error.response.status }),
                alertMessagesText: [this.formatMessage(messages['error' + error.response.status + 'Message'])]
              })
            }
            break
          default:
            setAlert({
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
              alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
            })

            logError(response)
        }
      } else {
        setAlert({
          alertMessages: true,
          alertMessagesType: 'danger',
          alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
          alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
        })

        logError(response)
      }
    }
  }

  getTableOptions = () => {
    const { length, noDataText, page } = this.state

    return {
      noDataText,
      onSizePerPageList: this.handleSizePerPageList,
      sizePerPageList: [
        {
          text: '10',
          value: 10
        },
        {
          text: '20',
          value: 20
        },
        {
          text: '50',
          value: 50
        },
        {
          text: '100',
          value: 100
        }
      ],
      sizePerPage: length,
      page,

      onPageChange: this.handlePageChange,
      ignoreSinglePage: false,
      pageStartIndex: 1,
      paginationSize: 5,
      prePage: this.formatMessage(messages.prePage),
      nextPage: this.formatMessage(messages.nextPage),
      firstPage: this.formatMessage(messages.firstPage),
      lastPage: this.formatMessage(messages.lastPage),
      paginationShowsTotal: this.renderPaginationShowsTotal(this.formatMessage),
      paginationPosition: 'bottom',
      hideSizePerPage: false,
      alwaysShowAllBtns: false,
      withFirstAndLast: true,

      onFilterChange: this.handleFilterChange,
      onSortChange: this.handleSortChange
    }
  }

  getDeviceTypeFilterOptions = () => {
    return {
      CS100: 'CS100',
      CS500: 'CS500',
      CS10: 'CS10'
    }
  }

  handleAssignNodesClick = async () => {
    const { addDevicesToGroup, groupId, groupsHierarchy, onGroupEdited, setAlert } = this.props
    const { accumulatedNodes, selectedNodes } = this.state

    const [{ hashId: hashIdValue }] = groupsHierarchy

    const nodesToAssign = accumulatedNodes.filter(node => selectedNodes.includes(node.eid))

    try {
      const typeGroupedDevices = mapDevicesToTypeGroupedDevices(nodesToAssign)
      const typeGroupedDevicesEntries = Object.entries(typeGroupedDevices)
      for (let i = 0; i < typeGroupedDevicesEntries.length; i++) {
        const deviceGroup = typeGroupedDevicesEntries[i]
        await addDevicesToGroup(groupId, deviceGroup[1], deviceGroup[0], hashIdValue)
      }

      onGroupEdited()
    } catch (response) {
      const error = response.error
      if (error.response) {
        switch (error.response.status) {
          case 400: // Bad request
          case 401: // Invalid credentials
          case 403: // Access denied
          case 404: // API url not found
          case 406: // Not acceptable
          case 500: // Unexpected error
            setAlert({
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: error.response.status }),
              alertMessagesText: [this.formatMessage(messages['error' + error.response.status + 'Message'])]
            })

            break
          default:
            setAlert({
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
              alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
            })

            logError(response)
        }
      } else {
        setAlert({
          alertMessages: true,
          alertMessagesType: 'danger',
          alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
          alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
        })

        logError(response)
      }
    }
  }

  handleClearSelectedNodesClick = () => {
    this.setState({
      selectedNodes: []
    })
  }

  handlePageChange = (page, sizePerPage) => {
    this.setState(
      {
        start: (page - 1) * sizePerPage,
        page,
        length: sizePerPage,
        count: 0,
        nodes: [],
        noDataText: <CircularProgress />
      },
      () => {
        this.getNodes()
      }
    )
  }

  handleSizePerPageList = sizePerPage => {
    this.setState(
      {
        length: sizePerPage,
        start: 0,
        page: 1,
        count: 0,
        nodes: [],
        noDataText: <CircularProgress />
      },
      () => this.getNodes()
    )
  }

  handleRowSelect = (row, isSelected) => {
    const { selectedNodes } = this.state

    const newSelectedNodes = isSelected ? [...selectedNodes, row.eid] : selectedNodes.filter(item => item !== row.eid)

    this.setState({
      selectedNodes: newSelectedNodes
    })
  }

  handleSelectAll = (isSelected, rows) => {
    const newSelectedNodes = isSelected ? rows.map(item => item.eid) : []

    this.setState({
      selectedNodes: newSelectedNodes
    })
  }

  handleFilterChange = filterObj => {
    this.setState(
      {
        filter: filterObj,
        start: 0,
        page: 1,
        count: 0,
        nodes: [],
        noDataText: <CircularProgress />
      },
      () => this.getNodes()
    )
  }

  handleSortChange = (sortName, sortOrder) => {
    if (sortName.includes('.') || sortName === 'lastActivityTime') {
      this.setState({ sort: {} })
    } else {
      this.setState(
        {
          sort: {
            sortColumnKey: sortName,
            sortColumnModel: 'Device',
            sortColumnOrder: sortOrder.toUpperCase()
          },
          nodes: [],
          count: 0,
          start: 0,
          page: 1,
          noDataText: <CircularProgress />
        },
        this.getNodes
      )
    }
  }

  handleChangeColumnVisibility = columnId => {
    this.setState(state => {
      const updatedColumnVisibility = !state.visibleColumns[columnId]
      return {
        visibleColumns: {
          ...state.visibleColumns,
          [columnId]: updatedColumnVisibility
        }
      }
    })
  }

  formatLastActivity = operatingTime => {
    return utcTimeToBrowserLocal(operatingTime.lastDateTime)
  }

  formatMachineField = cell => {
    return cell ? cell : '-'
  }

  formatActiveConfigurationColumn = cell => {
    return cell?.name ? cell.name : '-'
  }

  formatActiveConfigurationVersionColumn = cell => {
    return cell?.version_number ? cell.version_number : '-'
  }

  renderPaginationShowsTotal = formatMessage => (start, to, total) =>
    (
      <span>
        {`${formatMessage(messages.showingRows)} ${start} ${formatMessage(messages.to)} ${to} ${formatMessage(
          messages.of
        )} ${total}`}
      </span>
    )

  render() {
    const { groupsHierarchy } = this.props
    const {
      count,
      currentGroupDeviceIds,
      isCurrentDevicesLoading,
      isDevicesLoading,
      nodes,
      selectedNodes,
      visibleColumns
    } = this.state

    const isLoading = isCurrentDevicesLoading || isDevicesLoading
    const [{ name: parentGroupName = '' }] = groupsHierarchy

    const tableOptions = this.getTableOptions()
    const selectRowProp = {
      mode: 'checkbox',
      clickToSelect: false,
      onSelect: this.handleRowSelect,
      onSelectAll: this.handleSelectAll,
      bgColor: '#f5f5f5',
      selected: selectedNodes,
      unselectable: currentGroupDeviceIds
    }
    const deviceTypeFilterOptions = this.getDeviceTypeFilterOptions()

    return isLoading ? (
      <Loading />
    ) : (
      <Paper style={{ borderRadius: 0, margin: 20, padding: 20 }}>
        <Grid container>
          <Grid container item style={{ marginBottom: 50 }} xs={12}>
            <Grid container item xs={3}>
              <FormControl style={{ flexGrow: 1 }}>
                <Typography>
                  <label>{this.formatMessage(messages.parentGroup)}</label>
                </Typography>
                <Typography>{parentGroupName}</Typography>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid container item spacing={1} xs={6}>
              <Grid item>
                <Button className='text-button' disabled>
                  {this.formatMessage(messages.selected)} ({selectedNodes.length})
                </Button>
              </Grid>
              <Grid item>
                <Button
                  className='secondary-action-button'
                  disabled={selectedNodes.length === 0}
                  onClick={this.handleClearSelectedNodesClick}
                >
                  {this.formatMessage(messages.clearSelection)}
                </Button>
              </Grid>
              <Grid item>
                <SelectColumnsButton
                  columns={visibleColumns}
                  onChangeColumnVisibility={this.handleChangeColumnVisibility}
                />
              </Grid>
            </Grid>
            <Grid alignContent='center' container item justifyContent='flex-end' xs={6}>
              <Button
                className='primary-action-button'
                disabled={selectedNodes.length === 0}
                onClick={this.handleAssignNodesClick}
              >
                {this.formatMessage(messages.assign)}
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div className='table-with-pagination' style={{ paddingBottom: 110 }}>
              <BootstrapTable
                bordered={false}
                data={nodes}
                fetchInfo={{ dataTotalSize: count }}
                hover
                keyField='eid'
                options={tableOptions}
                pagination
                remote={remoteObj => ({
                  ...remoteObj,
                  search: false,
                  pagination: true,
                  sizePerPage: true,
                  sort: false,
                  filter: true
                })}
                searchPlaceholder={this.formatMessage(messages.filterByOrderableColumns)}
                selectRow={selectRowProp}
              >
                <TableHeaderColumn
                  dataField='name'
                  dataSort
                  filter={{
                    type: 'TextFilter',
                    delay: 400,
                    placeholder: `${this.formatMessage(messages.placeholder, {
                      column: this.formatMessage(messages.name).toLowerCase()
                    })}`
                  }}
                  hidden={!visibleColumns.name}
                >
                  {this.formatMessage(messages.name)}
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField='machine_model'
                  dataFormat={this.formatMachineField}
                  dataSort
                  filter={{
                    type: 'TextFilter',
                    delay: 400,
                    placeholder: `${this.formatMessage(messages.placeholder, {
                      column: this.formatMessage(messages.machineModel).toLowerCase()
                    })}`
                  }}
                  hidden={!visibleColumns.machineModel}
                >
                  {this.formatMessage(messages.machineModel)}
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField='machine_type'
                  dataFormat={this.formatMachineField}
                  dataSort
                  filter={{
                    type: 'TextFilter',
                    delay: 400,
                    placeholder: `${this.formatMessage(messages.placeholder, {
                      column: this.formatMessage(messages.machineType).toLowerCase()
                    })}`
                  }}
                  hidden={!visibleColumns.machineType}
                >
                  {this.formatMessage(messages.machineType)}
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField='machine_brand'
                  dataFormat={this.formatMachineField}
                  dataSort
                  filter={{
                    type: 'TextFilter',
                    delay: 400,
                    placeholder: `${this.formatMessage(messages.placeholder, {
                      column: this.formatMessage(messages.machineBrand).toLowerCase()
                    })}`
                  }}
                  hidden={!visibleColumns.machineBrand}
                >
                  {this.formatMessage(messages.machineBrand)}
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField='machine_serial_number'
                  dataFormat={this.formatMachineField}
                  dataSort
                  filter={{
                    type: 'TextFilter',
                    delay: 400,
                    placeholder: `${this.formatMessage(messages.placeholder, {
                      column: this.formatMessage(messages.machineSerialNumber).toLowerCase()
                    })}`
                  }}
                  hidden={!visibleColumns.machineSerialNumber}
                >
                  {this.formatMessage(messages.machineSerialNumber)}
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField='device_type'
                  dataSort
                  filter={{
                    type: 'SelectFilter',
                    delay: 400,
                    options: deviceTypeFilterOptions,
                    placeholder: `${this.formatMessage(messages.placeholder, {
                      column: this.formatMessage(messages.deviceType).toLowerCase()
                    })}`
                  }}
                  hidden={!visibleColumns.deviceType}
                >
                  {this.formatMessage(messages.deviceType)}
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField='eid'
                  dataSort
                  filter={{
                    type: 'TextFilter',
                    delay: 400,
                    placeholder: `${this.formatMessage(messages.placeholder, {
                      column: this.formatMessage(messages.EID).toLowerCase()
                    })}`
                  }}
                  hidden={!visibleColumns.eid}
                >
                  {this.formatMessage(messages.eid)}
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField='operating_time'
                  dataFormat={this.formatLastActivity}
                  hidden={!visibleColumns.lastActivity}
                >
                  {this.formatMessage(messages.lastActivity)}
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField='main_firmware_version'
                  dataSort
                  filter={{
                    type: 'TextFilter',
                    delay: 400,
                    placeholder: `${this.formatMessage(messages.placeholder, {
                      column: this.formatMessage(messages.firmwareVersion).toLowerCase()
                    })}`
                  }}
                  hidden={!visibleColumns.firmwareVersion}
                >
                  {this.formatMessage(messages.firmwareVersion)}
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField='Configuration'
                  dataFormat={this.formatActiveConfigurationColumn}
                  filter={{
                    type: 'TextFilter',
                    delay: 400,
                    placeholder: `${this.formatMessage(messages.placeholder, {
                      column: this.formatMessage(messages.deviceConfiguration).toLowerCase()
                    })}`
                  }}
                  hidden={!visibleColumns.deviceConfiguration}
                >
                  {this.formatMessage(messages.deviceConfiguration)}
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField='Configuration'
                  dataFormat={this.formatActiveConfigurationVersionColumn}
                  hidden={!visibleColumns.deviceConfigurationVersion}
                >
                  {this.formatMessage(messages.deviceConfigurationVersion)}
                </TableHeaderColumn>
              </BootstrapTable>
            </div>
          </Grid>
        </Grid>
      </Paper>
    )
  }
}

AssignNodesToGroupTable.propTypes = {
  addDevicesToGroup: PropTypes.func.isRequired,
  getGroupDevices: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  groupsHierarchy: PropTypes.array.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired }).isRequired,
  onGroupEdited: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired
}

export default injectIntl(AssignNodesToGroupTable)
