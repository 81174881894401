import React from 'react'
import PropTypes from 'prop-types'

import moment from 'moment'

import { client } from 'utils/http'

import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import reactBootstrapTable from 'utils/reactBootstrapTable'

import DataChip from 'components/DataChip'

import messages from './messages'

class NotificationsEvents extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      notification: props.notification,
      notificationsEvents: [],

      count: reactBootstrapTable.count
    }
  }

  componentDidMount() {
    this.getNotificationsEvents()
  }

  getNotificationsEvents = () => {
    client
      .getNotificationsEvents(this.state.notification.hashId)
      .then(response => {
        const eventsReceived = response.data
        // Al crearse una notificación el motor de reglas la pone a OPEN y no se asigna a ningún usuario,
        // se lo asignamos al sistema
        const eventsToShow = eventsReceived.map(el => {
          if (el.userEmail !== undefined) {
            return el
          } else {
            const newElement = el
            newElement.userEmail = 'System generated'
            return newElement
          }
        })

        const eventsToShowOrdered = eventsToShow.sort((actual, before) => {
          return before.timestamp - actual.timestamp
        })

        this.setState({
          notificationsEvents: eventsToShowOrdered
        })
      })
      .catch(response => {
        this.setState({
          notificationsEvents: []
        })
      })
  }

  handleDate = (cell, row) => {
    if (row.timestamp !== undefined) {
      return <div>{moment(row.timestamp).format('L LTS')}</div>
    } else {
      return '-'
    }
  }

  handleStatus = cell => {
    let cellText

    if (cell.trim() === 'acknowledged') {
      cellText = 'ACKD'
    } else {
      cellText = cell
    }

    return <DataChip chipText={cellText} intl={this.props.intl} />
  }

  handleObservations = cell => {
    let cellWithBr = cell

    if (cell !== undefined) {
      cellWithBr = cell.replace(/(?:\r\n|\r|\n)/g, '<br />')
    }

    return <div dangerouslySetInnerHTML={{ __html: cellWithBr }} />
  }

  renderPaginationShowsTotal = formatMessage => (start, to, total) =>
    (
      <span>
        {`${formatMessage(messages.showingRows)} ${start} ${formatMessage(messages.to)} ${to} ${formatMessage(
          messages.of
        )} ${total}`}
      </span>
    )

  render() {
    const tableOptions = {
      // No data
      noDataText: this.formatMessage(messages.noEventsAvailable),
      paginationShowsTotal: this.renderPaginationShowsTotal(this.formatMessage)
    }

    const remote = remoteObj => {
      remoteObj.search = false // eslint-disable-line no-param-reassign
      remoteObj.pagination = false // eslint-disable-line no-param-reassign
      remoteObj.sizePerPage = false // eslint-disable-line no-param-reassign
      remoteObj.sort = false // eslint-disable-line no-param-reassign
      remoteObj.filter = false // eslint-disable-line no-param-reassign

      return remoteObj
    }

    return (
      <div className="notifications-history">
        <BootstrapTable
          ref="table"
          data={this.state.notificationsEvents}
          remote={remote}
          pagination={false}
          fetchInfo={{ dataTotalSize: this.state.count }}
          search={false}
          multiColumnSearch={false}
          searchPlaceholder={this.formatMessage(messages.filterByOrderableColumns)}
          exportCSV={false}
          options={tableOptions}
          bordered={false}
          condensed={false}
          hover={true}
          striped={false}
          className="noEllipsis"
        >
          <TableHeaderColumn isKey={true} dataField="hashId" dataFormat={this.handleDate} width="180">
            {this.formatMessage(messages.date)}
          </TableHeaderColumn>
          <TableHeaderColumn dataField="status" dataFormat={this.handleStatus} width="80">
            {this.formatMessage(messages.status)}
          </TableHeaderColumn>
          <TableHeaderColumn dataField="userEmail" width="180">
            {this.formatMessage(messages.user)}
          </TableHeaderColumn>
          <TableHeaderColumn dataField="observations" dataFormat={this.handleObservations}>
            {this.formatMessage(messages.observations)}
          </TableHeaderColumn>
        </BootstrapTable>
      </div>
    )
  }
}

NotificationsEvents.propTypes = {
  notification: PropTypes.object.isRequired
}

export default NotificationsEvents
