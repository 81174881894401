import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import csNode from 'modules/csNode'

import { utils, actions } from 'ducks/map'
import { utils as login } from 'ducks/login'
import { utils as groupUtils } from 'ducks/groups'
import { utils as template } from 'ducks/template'
import { utils as notificationsUtils } from 'ducks/notifications'

import { createNodesLoader } from 'components/LoadNodesOnMount'
import MapView from './MapView'
import client from 'utils/http/client'

const mapStateToProps = state => ({
  currentMapCenter: utils.getCurrentMapCenter(state),
  currentZoom: utils.getCurrentZoom(state),
  groupId: groupUtils.getSelectedGroup(state).id,
  isCollapsed: template.isCollapsed(state),
  isNodeClicked: utils.isNodeMarkerClicked(state),
  markers: utils.getFilteredMarkers(state),
  notificationsTotalsReceivedData: notificationsUtils.getNotificationsTotalObject(state),
  overlayImages: utils.getOverlayImages(state)
})

const searchNotificationsByDeviceEIDs = devicesEIDs => dispatch => {
  if (devicesEIDs.length > 0) {
    dispatch(client.searchNotificationsByDeviceEIDs(devicesEIDs))
  }
}

const loadData = groupId => dispatch => {
  dispatch(actions.show())
  if (login.getCachedUserId() !== '0') {
    // CS user
    dispatch(csNode.actions.fetchDynamicCSNodesLocationsAndNotifications(groupId))
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      centerNodesView: actions.centerNodesView,
      load: loadData,
      onMarkerClick: actions.onMarkerClick,
      searchNotificationsByDeviceEIDs,
      setCenter: actions.setCenter,
      setZoomLevel: actions.setZoomLevel,
      unload: actions.hide,
      unselectNode: actions.unselectNode
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(createNodesLoader(MapView))
