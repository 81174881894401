import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import moment from 'moment'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

import DataChip from 'components/DataChip'
import { client, logError } from 'utils/http'
import NotificationsEvents from './NotificationsEvents'
import messages from './messages'

const styles = {
  hr: {
    borderColor: '#ddd'
  }
}

class NotificationsAcknowledge extends React.Component {
  constructor(props) {
    super(props)
    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage
    this.state = {
      observationValue: '',
      errorMessageTitle: '',
      errorMessage: ''
    }
  }

  handleCloseAlertClick = () => {
    this.setState({
      errorMessageTitle: '',
      errorMessage: ''
    })
  }

  handleObservationChange = event => {
    const { value } = event.target
    this.setState({ observationValue: value })
  }

  handleAcknowledgeNotificationClick = () => {
    const { closeDialog, notification, redrawNotificationsTable } = this.props
    const { observationValue } = this.state

    const notificationToUpdate = {
      hashId: notification.hashId,
      status: 'acknowledged',
      criticality: notification.criticality,
      ruleInstanceHashId: notification.ruleInstanceHashId,
      ruleInstanceName: notification.ruleInstanceName,
      ruleInstanceDescription: notification.ruleInstanceDescription,
      source: notification.source,
      archived: notification.archived,
      version: notification.version,
      observations: observationValue,
      deviceType: notification.deviceType,
      deviceName: notification.deviceName
    }

    client
      .modifyNotificacion(notificationToUpdate)
      .then(() => {
        this.setState({
          errorMessageTitle: '',
          errorMessage: ''
        })
        redrawNotificationsTable(notificationToUpdate)
        closeDialog()
      })
      .catch(response => {
        const error = { ...response }
        switch (error.response.status) {
          case 400: // Bad request
            this.setState({
              errorMessageTitle: this.formatMessage(messages.error, { number: '400' }),
              errorMessage: this.formatMessage(messages.error400Message)
            })
            break
          case 404: // API url not found
            this.setState({
              errorMessageTitle: this.formatMessage(messages.error, { number: '404' }),
              errorMessage: this.formatMessage(messages.error404Message)
            })
            break
          case 500: // Server errors
            this.setState({
              errorMessageTitle: this.formatMessage(messages.error, { number: '500' }),
              errorMessage: error.response.data.error_description
            })
            break
          default:
            this.setState({
              errorMessageTitle: this.formatMessage(messages.errorUndefinedTitle),
              errorMessage: this.formatMessage(messages.errorUndefinedMessage)
            })
        }
        logError(response)
      })
  }

  render() {
    const { classes, closeDialog, notification, intl } = this.props
    const { errorMessageTitle, errorMessage, observationValue } = this.state

    const notificationStatusToShow = notification.status === 'acknowledged' ? 'ACKD' : notification.status

    return (
      <Dialog maxWidth='md' onClose={closeDialog} open>
        <DialogTitle disableTypography>
          <Typography component='h1' variant='h4'>
            {notification.ruleInstanceName}
          </Typography>{' '}
          {notification.ruleInstanceDescription}
        </DialogTitle>
        <DialogContent style={{ marginBottom: 16 }}>
          <Grid container>
            <Grid item xs={12}>
              <hr className={classes.hr} style={{ marginTop: 0, borderWidth: 3 }} />
            </Grid>
            <Grid container item xs={12}>
              <Grid item sm={4} xs={12}>
                <strong>{this.formatMessage(messages.deviceName)}:</strong> {notification.deviceName}
              </Grid>
              <Grid item sm={4} xs={12}>
                <strong>{this.formatMessage(messages.deviceType)}:</strong> {notification.deviceType}
              </Grid>
              <Grid item sm={4} xs={12}>
                <strong>EID:</strong> {notification.source}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <hr className={classes.hr} />
            </Grid>
            <Grid container item style={{ marginBottom: 15 }} xs={12}>
              <Grid item sm={4} xs={12}>
                <strong>{this.formatMessage(messages.dateDetailLabel)}:</strong>{' '}
                {moment(notification.createdAt).format('L LTS')}
              </Grid>
              <Grid item sm={4} xs={12}>
                <strong>{this.formatMessage(messages.severityDetailLabel)}:</strong>{' '}
                <DataChip chipText={notification.criticality} inline intl={intl} />
              </Grid>
              <Grid item sm={4} xs={12}>
                <strong>{this.formatMessage(messages.currentStatusDetailLabel)}:</strong>{' '}
                <DataChip chipText={notificationStatusToShow} inline intl={intl} />
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item sm={4} style={{ paddingRight: 20 }} xs={12}>
                <strong>{intl.formatMessage(messages.reason)}:</strong> {notification.notificationBody}
              </Grid>
              <Grid item sm={4} xs={12}>
                <strong>{intl.formatMessage(messages.archivedDetailLabel)}:</strong>{' '}
                {notification.archived ? intl.formatMessage(messages.yes) : intl.formatMessage(messages.no)}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <hr className={classes.hr} />
            </Grid>
            <Grid item style={{ marginTop: 15 }} xs={12}>
              <Typography component='h2' style={{ color: 'inherit' }} variant='h5'>
                {intl.formatMessage(messages.historyDetailLabel)}
              </Typography>
              <div>
                <NotificationsEvents intl={intl} notification={notification} />
              </div>
            </Grid>
            <Grid item style={{ marginTop: 15 }} xs={12}>
              <Typography component='h2' style={{ color: 'inherit' }} variant='h5'>
                {this.formatMessage(messages.acknowledgeNotificationWarning)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <div className={errorMessageTitle ? 'block' : 'hidden'}>
                <div className='alert alert-danger alert-dismissible animated fadeIn'>
                  <button aria-label='Close' className='close' onClick={this.handleCloseAlertClick}>
                    <span aria-hidden='true'>x</span>
                  </button>
                  <h4>{errorMessageTitle}</h4>
                  <p className='h5'>{errorMessage}</p>
                </div>
              </div>
            </Grid>
            <TextField
              fullWidth
              hintText='Hint'
              label={this.formatMessage(messages.observation)}
              multiLine
              onChange={this.handleObservationChange}
              rows={2}
              value={observationValue}
            />
          </Grid>
        </DialogContent>
        <DialogActions disableSpacing style={{ padding: '8px 24px 16px' }}>
          <Button className='cancel-button' onClick={closeDialog}>
            {this.formatMessage(messages.cancel)}
          </Button>
          <Button
            className='primary-action-button'
            onClick={this.handleAcknowledgeNotificationClick}
            style={{ marginLeft: 10 }}
          >
            {this.formatMessage(messages.yesAcknowledge)}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

NotificationsAcknowledge.propTypes = {
  classes: PropTypes.object.isRequired,
  closeDialog: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  notification: PropTypes.object.isRequired,
  redrawNotificationsTable: PropTypes.func.isRequired
}

export default injectIntl(withStyles(styles)(NotificationsAcknowledge))
