import React from 'react'
import PropTypes from 'prop-types'

import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'

const styles = {
  colorPrimary: {
    color: '#5d5d5d'
  }
}
const PortalLoading = ({ logo, classes }) => (
  <div className='partm-loading'>
    <div className='partm-loading-danfoss-logo'>
      <img height='100%' src={logo} />
    </div>
    <CircularProgress classes={{ colorPrimary: classes.colorPrimary }} />
  </div>
)

PortalLoading.propTypes = {
  classes: PropTypes.object.isRequired,
  logo: PropTypes.string.isRequired
}

export default withStyles(styles)(PortalLoading)
