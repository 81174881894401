import React from 'react'
import PropTypes from 'prop-types'

import Alert from 'components/Alert'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import { MuiTreeList } from 'components/react-treeview-mui'

import { groupsHeaderToListitems, processGroupsHierarchyToListitems } from '../utils'
import { logError } from 'utils/http'

import { injectIntl } from 'react-intl'
import messages from './messages'

class GroupsGrid extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage },
      urlAction
    } = props
    this.formatMessage = formatMessage

    let alertMessages = false
    let alertMessagesType = ''
    let alertMessagesTitle = ''
    let alertMessagesText = ['']

    switch (urlAction) {
      case 'update':
        alertMessages = true
        alertMessagesType = 'success'
        alertMessagesTitle = ''
        alertMessagesText = [this.formatMessage(messages.groupUpdatedSuccessfully)]
        break
      case 'create':
        alertMessages = true
        alertMessagesType = 'success'
        alertMessagesTitle = ''
        alertMessagesText = [this.formatMessage(messages.groupCreatedSuccessfully)]
        break
      case 'delete':
        alertMessages = true
        alertMessagesType = 'success'
        alertMessagesTitle = ''
        alertMessagesText = [this.formatMessage(messages.groupDeletedSuccessfully)]
        break
      default:
        alertMessages = false
        alertMessagesType = ''
        alertMessagesTitle = ''
        alertMessagesText = ['']
    }

    this.state = {
      dialogOpen: false,
      dialogSelectedGroupToDeleteTitle: '',
      dialogSelectedGroupToDeleteText: '',
      selectedGroup: '',
      alertMessages,
      alertMessagesType,
      alertMessagesTitle,
      alertMessagesText
    }
  }

  handleDialogClose = () => {
    this.setState({
      dialogOpen: false,
      dialogSelectedGroupToDeleteTitle: '',
      dialogSelectedGroupToDeleteText: '',
      selectedGroup: ''
    })
  }

  deleteGroup = group => {
    const { userGroup } = this.props

    let groupText
    if (group.name === userGroup) {
      groupText = this.formatMessage(messages.youCantDeleteYourOwnGroup)

      this.setState({
        dialogOpen: true,
        dialogActions: this.dialogActionsError(this.formatMessage),
        dialogSelectedGroupToDeleteTitle: this.formatMessage(messages.errorDeletingGroup),
        dialogSelectedGroupToDeleteText: groupText,
        selectedGroup: group
      })
    } else if (group.children.length > 0) {
      groupText = this.formatMessage(messages.cantDeleteGroupWithChildrenNodes)

      this.setState({
        dialogOpen: true,
        dialogActions: this.dialogActionsError(this.formatMessage),
        dialogSelectedGroupToDeleteTitle: this.formatMessage(messages.errorDeletingGroup),
        dialogSelectedGroupToDeleteText: groupText,
        selectedGroup: group
      })
    } else {
      groupText = group.description + ' (' + group.hashId + ')'

      this.setState({
        dialogOpen: true,
        dialogActions: this.dialogActionsDelete(this.formatMessage),
        dialogSelectedGroupToDeleteTitle: this.formatMessage(messages.confirmGroupToDelete),
        dialogSelectedGroupToDeleteText: groupText,
        selectedGroup: group
      })
    }
  }

  handleDelete = () => {
    const { deleteGroup, getGroups, onDeleteGroup } = this.props
    const {
      selectedGroup: { hashId }
    } = this.state

    deleteGroup(hashId)
      .then(() => {
        this.setState(
          {
            dialogOpen: false,
            dialogSelectedGroupToDeleteTitle: '',
            dialogSelectedGroupToDeleteText: '',
            selectedGroup: '',
            alertMessages: true,
            alertMessagesType: 'success',
            alertMessagesTitle: '',
            alertMessagesText: [this.formatMessage(messages.groupDeletedSuccessfully)]
          },
          () => {
            onDeleteGroup()
            getGroups()
          }
        )
      })
      .catch(response => {
        const error = response.error
        if (error.response) {
          switch (error.response.status) {
            case 400: // Bad request
            case 401: // Invalid credentials
            case 403: // Access denied
            case 404: // API url not found
            case 409: // API url not found
            case 412: // Out of date
            case 428: // If-Match header required
              this.setState({
                dialogOpen: false,
                dialogSelectedGroupToDeleteTitle: '',
                dialogSelectedGroupToDeleteText: '',
                selectedGroup: '',
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, { number: error.response.status }),
                alertMessagesText: [this.formatMessage(messages['error' + error.response.status + 'Message'])]
              })
              break
            case 500: // Server errors
              this.setState({
                dialogOpen: false,
                dialogSelectedGroupToDeleteTitle: '',
                dialogSelectedGroupToDeleteText: '',
                selectedGroup: '',
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, { number: '500' }),
                alertMessagesText: [error.response.data.error_description + error.response.data.message]
              })
              break
            default:
              this.setState({
                dialogOpen: false,
                dialogSelectedGroupToDeleteTitle: '',
                dialogSelectedGroupToDeleteText: '',
                selectedGroup: '',
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
                alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
              })
              logError(response)
          }
        } else {
          this.setState({
            dialogOpen: false,
            dialogSelectedGroupToDeleteTitle: '',
            dialogSelectedGroupToDeleteText: '',
            selectedGroup: '',
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
            alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
          })
          logError(response)
        }
      })
  }

  closeAlert = () => {
    this.setState({
      alertMessages: false,
      alertMessagesType: '',
      alertMessagesTitle: '',
      alertMessagesText: ['']
    })
  }

  dialogActionsError = formatMessage => [
    <Button key='cancel-button' className='cancel-button' onClick={this.handleDialogClose}>
      {formatMessage(messages.cancel)}
    </Button>
  ]

  dialogActionsDelete = formatMessage => [
    <Button key='cancel-button' className='cancel-button' onClick={this.handleDialogClose}>
      {formatMessage(messages.cancel)}
    </Button>,
    <Button key='delete-button' className='delete-button' onClick={this.handleDelete} style={{ marginLeft: 5 }}>
      {formatMessage(messages.delete)}
    </Button>
  ]

  render() {
    const { groupsHierarchy, userGroup } = this.props
    const {
      alertMessages,
      alertMessagesText,
      alertMessagesTitle,
      alertMessagesType,
      dialogActions,
      dialogOpen,
      dialogSelectedGroupToDeleteText,
      dialogSelectedGroupToDeleteTitle
    } = this.state

    return (
      <div className='container-fluid roles' style={{ margin: '20px 0 0 0' }}>
        {alertMessages ? (
          <Alert
            alertType={alertMessagesType}
            closeFunction={this.closeAlert}
            messageText={alertMessagesText}
            messageTitle={alertMessagesTitle}
            showAlert={alertMessages}
          />
        ) : null}

        <Paper style={{ borderRadius: 0 }}>
          <div className='rolesList'>
            <MuiTreeList
              activeListItem={-1}
              contentKey='title'
              haveSearchbar={false}
              listItems={groupsHeaderToListitems(this.formatMessage)}
            />
            <MuiTreeList
              activeListItem={-1}
              contentKey='title'
              haveSearchbar={false}
              listItems={processGroupsHierarchyToListitems(userGroup, groupsHierarchy, this.deleteGroup)}
            />
          </div>
        </Paper>

        <Dialog fullWidth maxWidth='md' onClose={this.handleDialogClose} open={dialogOpen}>
          <DialogTitle>{dialogSelectedGroupToDeleteTitle}</DialogTitle>
          <DialogContent>{dialogSelectedGroupToDeleteText}</DialogContent>
          <DialogActions>{dialogActions}</DialogActions>
        </Dialog>
      </div>
    )
  }
}

GroupsGrid.propTypes = {
  deleteGroup: PropTypes.func.isRequired,
  getGroups: PropTypes.func.isRequired,
  groupsHierarchy: PropTypes.array.isRequired,
  intl: PropTypes.object.isRequired,
  onDeleteGroup: PropTypes.func.isRequired,
  urlAction: PropTypes.string,
  userGroup: PropTypes.string.isRequired
}

GroupsGrid.defaultProps = {
  urlAction: ''
}

export default injectIntl(GroupsGrid)
