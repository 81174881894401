import { getMappedConsentState } from 'components/SmsServiceConsentDialog/apiMappings'
import { getSmsServiceState } from 'components/SmsServiceStateChip/helpers'

import {
  getMappedFrequencyType,
  getMappedInspectionState,
  getMappedNotificationActionType,
  getMappedSeverity
} from './apiMappings'
import { FREQUENCY_TYPES } from './constants'

export function getAdaptedAdvancedSignalsData(rawAdvancedSignalsData) {
  return rawAdvancedSignalsData.map(advancedSignal => getAdaptedOneAdvancedSignalData(advancedSignal))
}

export function getAdaptedOneAdvancedSignalData(rawAdvancedSignalData) {
  return {
    hashId: rawAdvancedSignalData.hashId,
    configHashId: rawAdvancedSignalData.configHashId,
    name: rawAdvancedSignalData.measurementName,
    devicesEids: rawAdvancedSignalData?.measurementDevices?.map(device => device.deviceEid) || [],
    unit: rawAdvancedSignalData.measurementUnit
  }
}

export function getAdaptedCurrentValuesData(rawCurrentValuesData) {
  return rawCurrentValuesData.map(value => {
    return {
      deviceEid: value.deviceEid,
      currentValue: value.resultsSum
    }
  })
}

export function getAdaptedDetailedDevicesData(rawDetailedDevicesData) {
  return rawDetailedDevicesData.map(device => {
    return {
      id: device.id,
      eid: device.EID,
      name: device.name,
      deviceType: device.device_type,
      configurationId: device?.Configuration?.id || '',
      configurationName: device?.Configuration?.name || '',
      configurationVersion: device?.Configuration?.versionNumber,
      lastActivity: device?.operating_time?.lastDateTime,
      machineModel: device.machineModel,
      machineType: device.machineType,
      machineBrand: device.machineBrand,
      machineSerialNumber: device.machineSerialNumber,
      firmwareVersion: device.mainFirmwareVersion
    }
  })
}

export function getAdaptedDetailedUsers(rawDetailedUsersData) {
  return rawDetailedUsersData.map(user => {
    const consentState = getMappedConsentState(user?.consent)
    const isPhoneNumberPresent = user?.isPhoneNumberPresent

    return {
      id: user.userId ?? user.id,
      name: user.firstName,
      lastName: user.lastName,
      email: user.email,
      smsService: getSmsServiceState(consentState, isPhoneNumberPresent)
    }
  })
}

export function getAdaptedDynamicDevicesData(rawDynamicDevicesData) {
  return rawDynamicDevicesData.map(device => {
    return {
      id: device.id,
      eid: device.eid,
      name: device.name,
      deviceType: device.device_type,
      configurationId: device?.Configuration?.id || '',
      configurationName: device?.Configuration?.name || '',
      configurationVersion: device?.Configuration?.version_number || '',
      lastActivity: device?.operating_time?.lastDateTime,
      machineModel: device.machine_model,
      machineType: device.machine_type,
      machineBrand: device.machine_brand,
      machineSerialNumber: device.machine_serial_number,
      firmwareVersion: device.main_firmware_version
    }
  })
}

export function getAdaptedMaintenancesData(rawMaintenancesData) {
  return rawMaintenancesData?.map(item => getAdaptedOneMaintenanceData(item))
}

export function getAdaptedOneMaintenanceData(rawMaintenanceData) {
  const advancedSignal = getAdaptedOneAdvancedSignalData(rawMaintenanceData?.measurementConfig)

  return {
    hashId: rawMaintenanceData.hashId,
    name: rawMaintenanceData.name,
    description: rawMaintenanceData.description,
    isEnabled: rawMaintenanceData.notificationEnabled,
    frequencyType: getMappedFrequencyType(rawMaintenanceData.serviceThresholdType),
    frequencyValue: rawMaintenanceData.recurringThresholdIncrement,
    frequencyUnit: rawMaintenanceData.recurringThresholdIncrementUnit || '',
    initialValue: rawMaintenanceData.serviceThreshold || 0,
    initialDate: rawMaintenanceData.serviceThresholdDate || 0,
    numberOfPendingInspections: rawMaintenanceData.numberOfPendingInspections,
    advancedSignalHashId: advancedSignal.hashId,
    advancedSignalConfigHashId: advancedSignal.configHashId,
    advancedSignalName: advancedSignal.name,
    advancedSignalUnit: advancedSignal.unit,
    assignedUsers:
      rawMaintenanceData?.notificationUsersAlarms?.map(user => ({
        id: user.notificationUser.userId,
        email: user.notificationUser.email,
        notifications: user.notificationUserActions
      })) || [],
    severity: getMappedSeverity(rawMaintenanceData.severity)
  }
}

export function getAdaptedInspectionsData(rawInspectionsData, { frequencyType } = {}) {
  const { nextInspectionParameter, lastInspectionParameter } =
    frequencyType === FREQUENCY_TYPES.TIME_BASED
      ? { nextInspectionParameter: 'serviceThresholdDate', lastInspectionParameter: 'lastInspectionResolutionDate' }
      : { nextInspectionParameter: 'serviceThreshold', lastInspectionParameter: 'lastInspectionResolutionValue' }

  return rawInspectionsData?.map(item => {
    return {
      hashId: item.hashId,
      deviceId: item?.device?.hashId || '',
      deviceName: item?.device?.deviceName || '',
      deviceEid: item?.device?.deviceEid || '',
      state: getMappedInspectionState(item.status),
      currentValue: item.calculatedValue,
      nextInspection: item[nextInspectionParameter],
      lastInspection: item[lastInspectionParameter],
      notes: item.notes,
      inspectionValue: item.resolutionValue,
      inspectionDate: item.inspectionResolutionDate,
      inspectedBy: item.resolvedBy
    }
  })
}

export function getAdaptedNotificationActions(rawNotificationActionsData) {
  return rawNotificationActionsData.map(notificationAction => {
    return {
      hashId: notificationAction.hashId,
      type: getMappedNotificationActionType(notificationAction.name)
    }
  })
}
