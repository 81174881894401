import qs from 'qs'

import { AUTH_CLIENT } from './constants'

const token = () => '/oauth/token'

const api = '/api/v2'

const logout = () => token() + '?client_id=' + AUTH_CLIENT
const profile = email => api + '/users?email=' + email

const requestPasswordReset = () => api + '/users/password'

// The name might be a little bit confusing to distinguish that this is a URL getter...
// eslint-disable-next-line no-shadow
const setNewPassword = (hashId, token) => api + '/users/' + hashId + '/password?token=' + token

const getThemeByPath = () => '/theme'
const getTheme = () => api + '/theme'

const getActivityTypes = () => api + '/events/types'

const activitiesQuery = ({ fromDate, toDate, previous, next, page = 0, filters = {}, size = 1000 }) => {
  let req = api + `/events/dated?min=${fromDate}&max=${toDate}&size=${size}`

  if (filters && filters.user) {
    req += '&user=' + filters.user
  }

  if (filters && filters.eventTypes && filters.eventTypes.length > 0) {
    req += filters.eventTypes.reduce((ret, type, i) => ret + (i === 0 ? type : ',' + type), '&events=')
  }

  req += '&page=' + page

  return req
}

const historicQuery = ({ nodeId, fromDate, toDate, previous, next, filterBy, size = 1000 }) => {
  let req = api + `/logs/dated/${nodeId}?min=${fromDate}&max=${toDate}&size=${size}`

  if (filterBy !== null && filterBy.length > 0) {
    req += '&filters='
    filterBy.forEach(filterStr => {
      req += filterStr + ','
    })
    req = req.slice(0, -1)
  }

  if (previous) {
    req += '&previous=' + previous
  } else if (next) {
    req += '&next=' + next
  }

  return req
}

const historicRawQuery = ({ nodeId, fromDate, toDate, previous, next, size = 50 }) => {
  let req = api + `/raw/dated/${nodeId}?min=${fromDate}&max=${toDate}&size=${size}`
  if (previous) {
    req += '&previous=' + previous
  } else if (next) {
    req += '&next=' + next
  }
  return req
}

const crcErrorsQuery = ({ nodeId, fromDate, toDate, previous, next, size }) => {
  let req = api + `/crcerrors/dated/${nodeId}?`

  if (fromDate) {
    req += `&min=${fromDate}&`
  }
  if (toDate) {
    req += `&max=${toDate}&`
  }
  if (size) {
    req += `&size=${size}&`
  }

  if (previous) {
    req += '&previous=' + previous
  } else if (next) {
    req += '&next=' + next
  }
  return req
}

const dm1Query = ({ nodeId, fromDate, toDate, previous, next, size }) => {
  let req = api + `/dm1/dated/${nodeId}?`

  if (fromDate) {
    req += `&min=${fromDate}&`
  }
  if (toDate) {
    req += `&max=${toDate}&`
  }
  if (size) {
    req += `&size=${size}&`
  }

  if (previous) {
    req += '&previous=' + previous
  } else if (next) {
    req += '&next=' + next
  }
  return req
}

const archivedRawQuery = ({ nodeId, fromDate, toDate, isMonthly = false }) => {
  const params = {
    min: fromDate,
    max: toDate
  }

  if (isMonthly) {
    params.isMonthly = true
  }

  return Object.entries(params).reduce(
    (ret, param, i) => ret + (i === 0 ? '?' : '&') + `${param[0]}=${param[1]}`,
    `${api}/raw/files/${nodeId}`
  )
}

const nodes = query => {
  const theURL = api + '/nodes'
  const theQuery = query ? query : ''

  return theURL + theQuery
}

const node = nodeId => api + '/nodes/' + nodeId

const nodeToken = nodeId => node(nodeId) + '/token'

const nodeDetails = nodeId => node(nodeId) + '/data'

const nodeLastUpdate = nodeId => node(nodeId) + '/lastUpdate'

const nodeLocations = nodeId => node(nodeId) + '/locations?size=200'

const nodeCounters = nodeId => node(nodeId) + '/counts/aggregate'

const nodeTimeCounters = nodeId => node(nodeId) + '/maneuverTime/timeAggregate'

const nodeSignalTime = nodeId => node(nodeId) + '/runningTime/runningTimeAggregate'

const nodeMicroKeystrokes = nodeId => node(nodeId) + '/microKeystrokes/microKeystrokesAggregate'

const nodeMixedKeystrokes = nodeId => node(nodeId) + '/combinedKeystrokes/combinedKeystrokesAggregate'

const nodeTimeCountersByTimestamp = (nodeId, minRequestDate, maxRequestDate) =>
  node(nodeId) + '/maneuverTime/dated/timeAggregate?min=' + minRequestDate + '&max=' + maxRequestDate

const nodeCountersByTimestamp = (nodeId, minRequestDate, maxRequestDate) =>
  node(nodeId) + '/counts/dated/aggregate?min=' + minRequestDate + '&max=' + maxRequestDate

const nodeSignalTimeByTimestamp = (nodeId, minRequestDate, maxRequestDate) =>
  node(nodeId) + '/runningTime/dated/runningTimeAggregate?min=' + minRequestDate + '&max=' + maxRequestDate

const nodeMicroKeystrokesByTimestamp = (nodeId, minRequestDate, maxRequestDate) =>
  node(nodeId) + '/microKeystrokes/dated/microKeystrokesAggregate?min=' + minRequestDate + '&max=' + maxRequestDate

const nodeMixedKeystrokesByTimestamp = (nodeId, minRequestDate, maxRequestDate) =>
  node(nodeId) +
  '/combinedKeystrokes/dated/combinedKeystrokesAggregate?min=' +
  minRequestDate +
  '&max=' +
  maxRequestDate

const nodeUid = nodeId => api + '/uid/' + nodeId

const loadS3Bucket = folderName => api + '/s3/list?key=' + folderName

const downloadFromBucket = fileName => api + '/s3/download?fileName=' + fileName

const uploadToBucket = () => api + '/s3/upload'

const deleteFromBucket = fileName => api + '/s3/delete?fileName=' + fileName

const configurationUpload = nodeId => node(nodeId) + '/config'

// Why is it repeated? Couldn't we call it in a more general way?
const configurationDownload = configurationUpload

//const softwareUpload = nodeId => node(nodeId) + '/software'
const softwareUpload = nodeId => node(nodeId) + '/firmware'
const comModuleSoftwareUpload = nodeId => node(nodeId) + '/comModuleFirmware'

const softwareUploadS3 = (nodeId, fileName) => node(nodeId) + '/firmwareS3?fileName=' + fileName

const comModuleSoftwareUploadS3 = (nodeId, fileName) => node(nodeId) + '/comModuleFirmwareS3?fileName=' + fileName

const downloadEepromXml = nodeId => api + '/eepromRecovery?hashId=' + nodeId
//const writeEeprom = () => api + '/eepromWriter'
const writeEeprom = nodeId => node(nodeId) + '/eepromWriter'

const componentLocation = (nodeId, componentType) => {
  let component

  if (componentType === 'Transmitter') {
    component = 'emitters'
  } else if (componentType === 'Receiver') {
    component = 'receivers'
  } else {
    component = ''
  }

  return node(nodeId) + '/' + component
}

const componentToActLocation = (nodeId, componentId, componentType) => {
  let component

  if (componentType === 'Transmitter') {
    component = 'emitters'
  } else if (componentType === 'Receiver') {
    component = 'receivers'
  } else {
    component = ''
  }

  return node(nodeId) + '/' + component + '/' + componentId
}

const roles = () => api + '/roles'
const rolesHierarchy = () => api + '/roles/hierarchy'
const role = roleId => roles() + '/' + roleId

const privileges = () => api + '/privileges'

const groups = () => api + '/groups'
const groupsHierarchy = () => api + '/groups/hierarchy'
const groupsHierarchyWithNotifications = () => api + '/groups/hierarchy?include=notifications'
const group = groupId => groups() + '/' + groupId
const groupNodes = groupId => groups() + '/' + groupId + '/nodes'
const groupNodesPaginated = (groupId, page, size) => groups() + '/' + groupId + '/nodes?page=' + page + '&size=' + size
const groupNodesWithQuery = (groupId, query) => groups() + '/' + groupId + '/nodes' + query
const groupUnassignedNodes = (groupId, query) => groupNodes(groupId) + query
const deleteGroupNodes = groupId => groupNodes(groupId) + '?delete=detete'

// eslint-disable-next-line max-params
const gpsTrackings = (deviceEid, start, end, page, sortOrder) =>
  `${api}/nodes/m${deviceEid}/locationsAzure?min=${start}&max=${end}&size=4000${page ? '&page=' + page : ''}${
    sortOrder ? '&sortOrder=' + sortOrder : ''
  }`
const nodeLastGPSLocation = nodeMac => `${api}/nodes/m${nodeMac}/locationsAzureLast`

// eslint-disable-next-line max-params
const nodeAggregatedGPSLocations = (deviceEid, groupId, start, end, size, page, sortOrder, aggLevel) =>
  `${api}/nodes/locationsAzureAgg?eids=m${deviceEid}&groupId=${groupId}&min=${start}&max=${end}&size=${size}${
    page || page === 0 ? '&page=' + page : ''
  }${sortOrder ? '&sortOrder=' + sortOrder : ''}${aggLevel ? '&aggLevel=' + aggLevel : ''}`

const geofences = (groupId, query) => {
  const theURL = groupId ? api + '/geofences?groupId=' + groupId : api + '/geofences'
  const theQuery = query ? query : ''

  return theURL + theQuery
}

const geofence = geofenceId => geofences() + '/' + geofenceId

const users = query => {
  const theURL = api + '/users'
  const theQuery = query ? query : ''

  return theURL + theQuery
}

const user = userId => users() + '/' + userId

// FIXME most likely, none of the urls below work currently.

const plants = () => api + '/plants'

const plantVariables = plantId => api + '/plantVariables/' + plantId

const manufacturers = () => api + '/manufacturers/'

const manufacturer = manufacturerId => manufacturers + manufacturerId

const actions = nodeType => api + '/actions?search=nodeType==' + nodeType

const tasks = actionId => api + '/actions/' + actionId + '/instances/'
const tasksUpload = (actionInstanceHashId, actionHashId) =>
  process.env.REACT_APP_HTTP_API +
  api +
  '/actions/instances/' +
  actionInstanceHashId +
  '/variables/' +
  actionHashId +
  '/upload/'

const getTasks = query => api + '/tasks/' + query

const downloadTask = tasksHashid => api + '/tasks/' + tasksHashid + '/output'

const downloadTaskFile = task => api + '/tasks/' + task.hashId + '/variables/' + task.variables[0].hashId + '/value'

const cancelTask = tasksHashid => api + '/tasks/' + tasksHashid

const relaunchTask = tasksHashid => api + '/tasks/' + tasksHashid

//NOTIFICATIONS
const notifications = () => api + '/notifications'
const getNotifications = (groupId, query) => notifications() + '?groupId=' + groupId + query
const getNotificationsTotals = groupId => notifications() + '/totals?groupId=' + groupId
const getNotificationsTotalsUrlSessionIdExtractorRegEx = () =>
  /wss:\/\/.+\/api\/v2\/notifications\/totals\/[0-9]+\/(?<sessionId>[A-Za-z0-9]+)\/websocket.*/i
const getNotificationsTotalsMethodToSuscribe = sessionId => '/user/' + sessionId + '/queue/updates'

const notificationsEvents = notificacionId => notifications() + '/' + notificacionId + '/events'
const notification = notificacionId => notifications() + '/' + notificacionId
const notificationsSearch = () => notifications() + '/search'
//FCM
const sendToken = hashId => api + '/users/' + hashId + '/token'

const getNotificationsActions = groupId =>
  groupId ? notifications() + '/actions?groupId=' + groupId : notifications() + '/actions'

//Notifications Settings
const rules = groupId => api + '/rules?groupId=' + groupId
const rule = ruleHashId => api + '/rules/' + ruleHashId
const rulesInstances = groupId => (groupId ? api + '/rules/instances?groupId=' + groupId : api + '/rules/instances')
const getRuleInstances = (groupId, query) => rulesInstances(groupId) + query

const ruleInstance = ruleInstanceId => api + '/rules/instances/' + ruleInstanceId
const ruleInstanceAssignDevices = ruleInstanceId => rulesInstances() + '/' + ruleInstanceId + '/devices'
const ruleInstanceAssignUsers = ruleInstanceId => rulesInstances() + '/' + ruleInstanceId + '/users'
const ruleInstanceDesassignDevices = ruleInstanceId => rulesInstances() + '/' + ruleInstanceId + '/devices?delete'
const ruleInstanceDesassignUsers = ruleInstanceId => rulesInstances() + '/' + ruleInstanceId + '/users?delete'

const ruleInstanceDevicesAssigned = (ruleInstanceId, query) =>
  rulesInstances() + '/' + ruleInstanceId + '/devices/' + query
const ruleInstanceUsersAssigned = (ruleInstanceId, query) => rulesInstances() + '/' + ruleInstanceId + '/users/' + query
const ruleInstanceProductsAssigned = (ruleInstanceId, query) =>
  rulesInstances() + '/' + ruleInstanceId + '/products/' + query
const ruleInstanceAssignNotificationsActionsToAssignedUsers = ruleInstanceId =>
  rulesInstances() + '/' + ruleInstanceId + '/users/actions'
const ruleInstanceUnassignNotificationsActionsToAssignedUsers = ruleInstanceId =>
  rulesInstances() + '/' + ruleInstanceId + '/users/actions?delete'

// eslint-disable-next-line max-params
const getDatedAzureLogs = (deviceEid, start, end, sortOrder, size, filters) =>
  api +
  '/logs/datedAzure/m' +
  deviceEid +
  '?min=' +
  start +
  '&max=' +
  end +
  '&sortOrder=' +
  sortOrder +
  '&size=' +
  size +
  '&filters=' +
  filters

// eslint-disable-next-line max-params
const getAggregatedAzureLogs = (deviceEid, start, end, sortOrder, page, filters) =>
  api +
  '/logs/aggAzure/m' +
  deviceEid +
  '?min=' +
  start +
  '&max=' +
  end +
  '&sortOrder=' +
  sortOrder +
  '&page=' +
  page +
  '&size=50000&filters=' +
  filters

// CAN
const getProtocols = groupId => api + '/protocols/' + groupId
const getProtocol = (groupId, protocol) => api + '/protocols/' + groupId + '/' + protocol
const deleteProtocol = (groupId, protocol) => api + '/protocols/' + groupId + '/' + protocol
const uploadDbcFile = groupId => api + '/protocols/' + groupId

// DEVICE DASHBOARDS
const nodeDashboards = deviceId => api + '/dashboards?deviceId=' + deviceId
const newDashboard = () => api + '/dashboards'
const newDashboardSetup = dashboard => api + '/dashboards/' + dashboard + '/file'
const editDashboard = dashboard => api + '/dashboards/' + dashboard
const deleteDashboard = dashboard => api + '/dashboards/' + dashboard
const updateDashboard = dashboard => api + '/dashboards/' + dashboard + '/file'
const getDashboardWidgets = dashboard => api + '/dashboards/' + dashboard + '/file'

// GROUP DASHBOARDS
const groupDashboards = selectedGroup => api + '/dashboards?groupId=' + selectedGroup
const newGroupDashboard = () => api + '/dashboards'
const newGroupDashboardSetup = dashboardHashId => api + '/dashboards/' + dashboardHashId + '/file'
const editGroupDashboard = dashboard => api + '/dashboards/' + dashboard
const deleteGroupDashboard = dashboard => api + '/dashboards/' + dashboard
const updateGroupDashboard = dashboard => api + '/dashboards/' + dashboard + '/file'
const getGroupDashboardWidgets = dashboardHashId => api + '/dashboards/' + dashboardHashId + '/file'
const uploadDashboards = () => api + '/dashboards/uploadDashboards'
const deleteDashboards = groupId => api + `/dashboards/deleteDashboards?groupId=${groupId}`

const theme = (groupId, themeId) => api + `/theme${themeId ? '/' + themeId : ''}?groupId=${groupId}`
const loginPathTheme = loginPath => api + `/theme/loginPath/${loginPath}`
const themeConfig = (groupId, themeId) => api + `/theme/${themeId}/file?groupId=${groupId}`
const getLastKnownSignals = (groupId, eids, signals) =>
  api + `/signals/last?groupId=${groupId}&eids=${eids}&signals=${signals}`

const getLastDM1s = (groupId, eids) => `${api}/dm1/last?groupId=${groupId}&eids=${eids}`

const getMeasurements = (groupId, deviceEids) => {
  const urlQueryParams = {
    groupId,
    deviceEids
  }
  const urlQuery = qs.stringify(urlQueryParams, { arrayFormat: 'repeat' })
  return `${api}/measurements?${urlQuery}`
}
const getOneMeasurement = (groupId, measurementId) => `${api}/measurements/${measurementId}?groupId=${groupId}`
const getMeasurementsDevices = (groupId, measurementConfigHashId) =>
  `${api}/measurements/devices?groupId=${groupId}&configHashId=${measurementConfigHashId}`

const measurmentsResults = (hashId, groupId, min, max, page, size) =>
  `${api}/measurements/${hashId}/measurements?groupId=${groupId}&min=${min}&max=${max}${page ? '&page=' + page : ''}${
    size ? '&size=' + size : ''
  }`
const newMeasurements = groupId => `${api}/measurements?groupId=${groupId}`
const measurement = (hashId, groupId) => `${api}/measurements/${hashId}?groupId=${groupId}`
const importMeasurements = groupId => `${api}/measurements/import?groupId=${groupId}`

// MAINTENANCES
const getMaintenances = ({ groupId, page, size, sort, order, filter = {} }) => {
  const search =
    Object.entries(filter)
      .map(([key, value]) => {
        return typeof value === 'boolean' ? `(${key}==${value})` : `(${key}=="*${value}*")`
      })
      .join(';') || undefined

  const urlQueryParams = {
    groupId,
    page: page ? page - 1 : undefined,
    size,
    sort,
    order,
    search
  }
  const urlQuery = qs.stringify(urlQueryParams, { arrayFormat: 'repeat' })
  return `${api}/maintenances?${urlQuery}`
}
const getOneMaintenance = ({ groupId, maintenanceId, rescheduleInspections }) => {
  const urlQueryParams = {
    groupId,
    rescheduleInspections
  }
  const urlQuery = qs.stringify(urlQueryParams)
  return `${api}/maintenances/${maintenanceId}?${urlQuery}`
}
const getOneMaintenanceInspections = ({ groupId, maintenanceId, deviceId, page, size, sort, order, filter = {} }) => {
  const search =
    Object.entries(filter)
      .map(([key, value]) => {
        return typeof value === 'boolean' ? `(${key}==${value})` : `(${key}=="*${value}*")`
      })
      .join(';') || undefined
  const urlQueryParams = {
    groupId,
    deviceHashId: deviceId || undefined,
    page: page ? page - 1 : undefined,
    size,
    sort,
    order,
    search
  }
  const urlQuery = qs.stringify(urlQueryParams)
  return `${api}/maintenances/${maintenanceId}/inspections?${urlQuery}`
}
const getOneMaintenanceInspectionsLimited = ({ groupId, maintenanceId, page, size, sort, order, filter = {} }) => {
  const search =
    Object.entries(filter)
      .map(([key, value]) => {
        return typeof value === 'boolean' ? `(${key}==${value})` : `(${key}=="*${value}*")`
      })
      .join(';') || undefined
  const urlQueryParams = {
    groupId,
    page: page ? page - 1 : undefined,
    size,
    sort,
    order,
    search
  }
  const urlQuery = qs.stringify(urlQueryParams)
  return `${api}/maintenances/${maintenanceId}/inspections/limited?${urlQuery}`
}

export {
  logout,
  profile,
  token,
  requestPasswordReset,
  setNewPassword,
  getThemeByPath,
  getTheme,
  getActivityTypes,
  activitiesQuery,
  historicQuery,
  historicRawQuery,
  crcErrorsQuery,
  dm1Query,
  archivedRawQuery,
  loadS3Bucket,
  downloadFromBucket,
  uploadToBucket,
  deleteFromBucket,
  configurationUpload,
  configurationDownload,
  softwareUpload,
  softwareUploadS3,
  comModuleSoftwareUpload,
  comModuleSoftwareUploadS3,
  nodes,
  node,
  nodeToken,
  nodeDetails,
  nodeLastUpdate,
  nodeLocations,
  nodeCounters,
  nodeTimeCounters,
  nodeTimeCountersByTimestamp,
  nodeCountersByTimestamp,
  nodeSignalTime,
  nodeSignalTimeByTimestamp,
  nodeMicroKeystrokes,
  nodeMicroKeystrokesByTimestamp,
  nodeMixedKeystrokes,
  nodeMixedKeystrokesByTimestamp,
  nodeUid,
  downloadEepromXml,
  writeEeprom,
  componentLocation,
  componentToActLocation,
  roles,
  rolesHierarchy,
  role,
  privileges,
  groups,
  groupsHierarchy,
  groupsHierarchyWithNotifications,
  group,
  groupNodes,
  groupNodesPaginated,
  groupNodesWithQuery,
  groupUnassignedNodes,
  deleteGroupNodes,
  gpsTrackings,
  nodeLastGPSLocation,
  nodeAggregatedGPSLocations,
  geofences,
  geofence,
  users,
  user,
  plants,
  plantVariables,
  manufacturers,
  manufacturer,
  actions,
  tasks,
  tasksUpload,
  getTasks,
  downloadTask,
  downloadTaskFile,
  cancelTask,
  relaunchTask,
  getNotifications,
  getNotificationsTotals,
  getNotificationsTotalsUrlSessionIdExtractorRegEx,
  getNotificationsTotalsMethodToSuscribe,
  getNotificationsActions,
  notificationsEvents,
  notification,
  notificationsSearch,
  rules,
  rule,
  rulesInstances,
  getRuleInstances,
  ruleInstance,
  ruleInstanceAssignDevices,
  ruleInstanceAssignUsers,
  ruleInstanceDesassignDevices,
  ruleInstanceDesassignUsers,
  ruleInstanceDevicesAssigned,
  ruleInstanceUsersAssigned,
  ruleInstanceProductsAssigned,
  sendToken,
  ruleInstanceAssignNotificationsActionsToAssignedUsers,
  ruleInstanceUnassignNotificationsActionsToAssignedUsers,
  getDatedAzureLogs,
  getAggregatedAzureLogs,
  getProtocols,
  getProtocol,
  deleteProtocol,
  uploadDbcFile,
  nodeDashboards,
  newDashboard,
  newDashboardSetup,
  editDashboard,
  deleteDashboard,
  updateDashboard,
  getDashboardWidgets,
  groupDashboards,
  newGroupDashboard,
  newGroupDashboardSetup,
  editGroupDashboard,
  deleteGroupDashboard,
  updateGroupDashboard,
  getGroupDashboardWidgets,
  uploadDashboards,
  deleteDashboards,
  theme,
  loginPathTheme,
  themeConfig,
  getLastKnownSignals,
  getLastDM1s,
  getMeasurements,
  measurmentsResults,
  getOneMeasurement,
  getMeasurementsDevices,
  newMeasurements,
  measurement,
  importMeasurements,
  getMaintenances,
  getOneMaintenance,
  getOneMaintenanceInspections,
  getOneMaintenanceInspectionsLimited
}
