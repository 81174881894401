import { defineMessages } from 'react-intl'

const namespace = 'NewNotification'

const messages = defineMessages({
  title: {
    id: `${namespace}.title`,
    description: 'New notification title',
    defaultMessage: 'New notification'
  },
  return: {
    id: `${namespace}.return`,
    description: 'Return button label',
    defaultMessage: 'Return'
  },
  thisFieldIsRequired: {
    id: `${namespace}.thisFieldIsRequired`,
    description: 'This field is required text',
    defaultMessage: 'This field is required.'
  },
  group: {
    id: `${namespace}.group`,
    description: 'Group floating label text',
    defaultMessage: 'Group'
  },
  selectAGroup: {
    id: `${namespace}.selectAGroup`,
    description: 'Select a group text',
    defaultMessage: 'Select a group.'
  },
  assignAtLeastAMachine: {
    id: `${namespace}.assignAtLeastAMachine`,
    description: 'Assign a machine text',
    defaultMessage: 'At least one machine must be assigned to the notification.'
  },
  machineConditionText: {
    id: `${namespace}.machineConditionText`,
    description: 'Machine condition explanation',
    defaultMessage:
      'IMPORTANT: To assign several machines to notification, the machines must have the same configuration file.'
  },
  machineSelectedWithDifferentConfigurationFile: {
    id: `${namespace}.machineSelectedWithDifferentConfigurationFile`,
    description: 'Machine selected with different configuration file',
    defaultMessage:
      'The selected machines have different Configuration files. To assign several machcines, they must have the same configuration file.'
  },
  machineSelectedWithoutConfigurationFile: {
    id: `${namespace}.machineSelectedWithoutConfigurationFile`,
    description: 'Machine selected without configuration file',
    defaultMessage:
      'The selected machines have no associated Configuration file. Choose at least one machine with configuraction file.'
  },
  machineSelectedWithConfigurationFileWithoutSignals: {
    id: `${namespace}.machineSelectedWithConfigurationFileWithoutSignals`,
    description: 'Machine selected with configuration file but without signals',
    defaultMessage: 'The configuration file of the selected machines has no signal available. Choose another machine.'
  },
  generalSettings: {
    id: `${namespace}.generalSettings`,
    description: 'General settings title',
    defaultMessage: 'General Settings'
  },
  nameOfNotification: {
    id: `${namespace}.nameOfNotification`,
    description: 'Name of the notification label',
    defaultMessage: 'Name of the notification'
  },
  description: {
    id: `${namespace}.description`,
    description: 'Description label',
    defaultMessage: 'Description'
  },
  enabled: {
    id: `${namespace}.enabled`,
    description: 'Enabled option label',
    defaultMessage: 'Enabled*'
  },
  ifEnabledIsntChecked: {
    id: `${namespace}.ifEnabledIsntChecked`,
    description: 'If "Enabled" is not checked... message',
    defaultMessage: 'If "Enabled" isn`t checked, the rule is disabled. A disabled rule doesn`t generate notifications.'
  },
  selectGroup: {
    id: `${namespace}.selectGroup`,
    description: 'Select group label',
    defaultMessage: 'Select group'
  },
  usersAssignedToTheRule: {
    id: `${namespace}.usersAssignedToTheRule`,
    description: 'Users assigned to the rule... message',
    defaultMessage:
      'Users assigned to the rule will receive notifications associated with the chosen group and its subgroups.'
  },
  save: {
    id: `${namespace}.save`,
    description: 'Save button label',
    defaultMessage: 'Save'
  },
  next: {
    id: `${namespace}.next`,
    description: 'Next button label',
    defaultMessage: 'Next'
  },
  previous: {
    id: `${namespace}.previous`,
    description: 'Previous button label',
    defaultMessage: 'Previous'
  },
  cancel: {
    id: `${namespace}.cancel`,
    description: 'Cancel button label',
    defaultMessage: 'Cancel'
  },

  assignMachines: {
    id: `${namespace}.assignMachines`,
    description: 'Assign machines title',
    defaultMessage: 'Assign machines'
  },
  selected: {
    id: `${namespace}.selected`,
    description: 'Selected machines button label',
    defaultMessage: 'SELECTED'
  },
  clearSelection: {
    id: `${namespace}.clearSelection`,
    description: 'Clear selection button label',
    defaultMessage: 'Clear selection'
  },

  showingMachines: {
    id: `${namespace}.showingMachines`,
    description: 'Assign machines table total text showing rows',
    defaultMessage: 'Showing machines'
  },
  to: {
    id: `${namespace}.to`,
    description: 'Assign machines table total text to',
    defaultMessage: 'to'
  },
  of: {
    id: `${namespace}.of`,
    description: 'Assign machines table total text of',
    defaultMessage: 'of'
  },
  prePage: {
    id: `${namespace}.prePage`,
    description: 'Assign machines previous page button text',
    defaultMessage: 'Prev'
  },
  nextPage: {
    id: `${namespace}.nextPage`,
    description: 'Assign machine next page button text',
    defaultMessage: 'Next'
  },
  firstPage: {
    id: `${namespace}.firstPage`,
    description: 'Assign machine first page button text',
    defaultMessage: 'First'
  },
  lastPage: {
    id: `${namespace}.lastPage`,
    description: 'Assign machine last page button text',
    defaultMessage: 'Last'
  },
  deviceID: {
    id: `${namespace}.deviceID`,
    description: 'Device ID',
    defaultMessage: 'Device ID'
  },
  name: {
    id: `${namespace}.name`,
    description: 'Name table header column label',
    defaultMessage: 'Name'
  },
  eid: {
    id: `${namespace}.eid`,
    description: 'EID  table header column label',
    defaultMessage: 'EID'
  },
  creationDate: {
    id: `${namespace}.creationDate`,
    description: 'Creation date table header column label',
    defaultMessage: 'Creation date'
  },
  searchPlaceholder: {
    id: `${namespace}.searchPlaceholder`,
    description: 'Search placeholder',
    defaultMessage: 'Filter by orderable columns'
  },
  conditionalSignals: {
    id: `${namespace}.conditionalSignals`,
    description: 'Conditional Signals form',
    defaultMessage: 'Conditional Signals'
  },

  valueAvg: {
    id: `${namespace}.valueAvg`,
    description: 'Average value type',
    defaultMessage: 'Average'
  },
  valueMin: {
    id: `${namespace}.valueMin`,
    description: 'Minimum value type',
    defaultMessage: 'Minimum'
  },
  valueMax: {
    id: `${namespace}.valueMax`,
    description: 'Maximum value type',
    defaultMessage: 'Maximum'
  },
  deviceType: {
    id: `${namespace}.deviceType`,
    description: 'Device type table column header label',
    defaultMessage: 'Device type'
  },
  activeConfiguration: {
    id: `${namespace}.activeConfiguration`,
    description: 'Active configuration text',
    defaultMessage: 'Active configuration'
  },
  activeConfigurationVersion: {
    id: `${namespace}.activeConfigurationVersion`,
    description: 'Active configuration version text',
    defaultMessage: 'Active configuration version'
  },
  placeholder: {
    id: `${namespace}.placeholder`,
    description: 'Placeholder text',
    defaultMessage: 'Enter {column}'
  },
  noMachinesText: {
    id: `${namespace}.noMachinesText`,
    description: 'No machines text',
    defaultMessage: 'No machines available'
  },
  selectPlaceholder: {
    id: `${namespace}.selectPlaceholder`,
    description: 'Select placeholder text',
    defaultMessage: 'Select {column}'
  },
  machineModel: {
    id: `${namespace}.machineModel`,
    description: 'Machine model',
    defaultMessage: 'Model'
  },
  machineType: {
    id: `${namespace}.machineType`,
    description: 'Machine type',
    defaultMessage: 'Machine type'
  },
  machineBrand: {
    id: `${namespace}.machineBrand`,
    description: 'Machine brand',
    defaultMessage: 'Brand'
  },
  machineSerialNumber: {
    id: `${namespace}.machineSerialNumber`,
    description: 'Machine serial number',
    defaultMessage: 'Machine serial number'
  },
  lastActivity: {
    id: `${namespace}.lastActivity`,
    description: 'Last activity',
    defaultMessage: 'Last activity'
  },
  firmwareVersion: {
    id: `${namespace}.firmwareVersion`,
    description: 'Firmware version',
    defaultMessage: 'Firmware version'
  },
  deviceConfiguration: {
    id: `${namespace}.deviceConfiguration`,
    description: 'Device configuration',
    defaultMessage: 'Device configuration'
  },
  deviceConfigurationVersion: {
    id: `${namespace}.deviceConfigurationVersion`,
    description: 'Device configuration version',
    defaultMessage: 'Device configuration version'
  },
  selectColumns: {
    id: `${namespace}.selectColumns`,
    description: 'Select columns',
    defaultMessage: 'Select columns'
  },
  showHideColumns: {
    id: `${namespace}.showHideColumns`,
    description: 'Show/hide columns',
    defaultMessage: 'Show/hide columns'
  }
})

export default messages
