import React from 'react'
import PropTypes from 'prop-types'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { Link } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'

import CircularProgress from '@material-ui/core/CircularProgress'
import EditIcon from '@material-ui/icons/Edit'
import GetAppIcon from '@material-ui/icons/GetApp'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import SearchIcon from '@material-ui/icons/Search'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'

import messages from './messages'

import { logError, mapDynamicDevicesFilterObject } from 'utils/http'

import { utcTimeToBrowserLocal, utcTimeToBrowserLocalMilliseconds } from 'utils/timeFormat'

const styles = {
  dialog: {
    minWidth: 600
  },
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid #dadde9',
    fontSize: 14
  }
}

class ConfigurationsTable extends React.Component {
  constructor(props) {
    super(props)
    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    const { state } = props.location
    let defaultTableProps = { page: 1, sizePerPage: 10 }
    if (typeof state === 'object') defaultTableProps = { ...defaultTableProps, ...state }
    const { page, sizePerPage } = defaultTableProps

    this.state = {
      csNodes: [],
      count: 0,
      start: (page - 1) * sizePerPage,
      filter: {},

      tableOptions: {
        noDataText: <CircularProgress />,
        sizePerPage,
        page
      }
    }
  }

  componentDidMount = () => {
    const { groupId } = this.props

    if (groupId) {
      this.getCSNodes()
    }
  }

  componentDidUpdate = prevProps => {
    const { groupId } = this.props

    if (prevProps.groupId !== groupId && groupId) {
      this.setState(
        state => ({
          tableOptions: {
            ...state.tableOptions,
            noDataText: <CircularProgress />,
            page: 1
          },
          count: 0,
          start: 0,
          csNodes: []
        }),
        () => {
          this.getCSNodes()
        }
      )
    }
  }

  getCSNodes = () => {
    const { canReadDevices, getCSNodes, groupId } = this.props
    const { start, tableOptions, filter, sort } = this.state

    if (canReadDevices) {
      const deviceFields = { Device: ['id', 'name', 'eid', 'operating_time'] }
      const filterWithCS500DeviceType = { ...filter, device_type: { type: 'SelectFilter', value: 'CS500' } }
      const mappedFilters = mapDynamicDevicesFilterObject(filterWithCS500DeviceType, sort)

      getCSNodes(groupId, tableOptions.sizePerPage, start, deviceFields, mappedFilters)
        .then(response => {
          const { count, total, devices } = response.data
          if (count === 0) {
            this.setState(state => {
              const { noDataText, ...otherTableOptions } = state.tableOptions
              return {
                csNodes: [],
                count: 0,
                tableOptions: {
                  noDataText: this.formatMessage(messages.thereAreNoCS500),
                  ...otherTableOptions
                }
              }
            })
          } else {
            this.setState({
              csNodes: devices,
              count: total
            })
            if (devices.length === 0) {
              this.setState(state => {
                const { noDataText, ...otherTableOptions } = state.tableOptions
                return {
                  tableOptions: {
                    noDataText: this.formatMessage(messages.thereAreNoCS500),
                    ...otherTableOptions
                  }
                }
              })
            }
          }
        })
        .catch(response => {
          const { error } = { ...response }
          let noDataText = this.formatMessage(messages.errorRequestingData)
          if (error && error.response) {
            switch (error.response.status) {
              case 404:
                const errorReceived =
                  typeof error.response.data === 'string' ? error.response.data : error.response.data.message
                if (
                  errorReceived.includes('NO DEVICE IS ASSOCIATED TO THIS USER IN THIS GROUP') ||
                  errorReceived.includes('NO DEVICE EXISTS')
                ) {
                  noDataText = this.formatMessage(messages.thereAreNoCS500)
                }
                break
              default:
            }
          }
          this.setState(state => ({
            csNodes: [],
            tableOptions: {
              ...state.tableOptions,
              noDataText
            }
          }))
          logError(response)
        })
    } else {
      this.setState(state => {
        const { noDataText, ...otherTableOptions } = state.tableOptions
        return {
          csNodes: [],
          count: 0,
          tableOptions: {
            noDataText: this.formatMessage(messages.errorRequestingData),
            ...otherTableOptions
          }
        }
      })
    }
  }

  handlePageChange = (page, sizePerPage) => {
    this.setState(
      state => {
        const { page: previousPage, sizePerPage: previousSizePage, ...otherTableOptions } = state.tableOptions
        return {
          start: (page - 1) * sizePerPage,
          tableOptions: {
            page,
            sizePerPage,
            ...otherTableOptions
          },
          csNodes: []
        }
      },
      () => {
        this.getCSNodes()
      }
    )
  }

  handleSizePerPageList = sizePerPage => {
    this.setState(
      state => {
        const { sizePerPage: previousSizePerPage, ...otherTableOptions } = state.tableOptions
        return {
          tableOptions: { sizePerPage, ...otherTableOptions },
          csNodes: []
        }
      },
      () => {
        this.getCSNodes()
      }
    )
    this.setState({ csNodes: [] }, () => {
      this.getCSNodes()
    })
  }

  handleFilterChange = filterObj => {
    this.setState(
      state => ({
        filter: filterObj,
        csNodes: [],
        start: 0,
        tableOptions: {
          ...state.tableOptions,
          page: 1
        }
      }),
      () => {
        this.getCSNodes()
      }
    )
  }

  handleSortChange = (sortName, sortOrder) => {
    if (sortName === 'operating_time') {
      this.setState({ sort: {} })
    } else {
      this.setState(
        state => ({
          sort: {
            sortColumnKey: sortName,
            sortColumnModel: 'Device',
            sortColumnOrder: sortOrder.toUpperCase()
          },
          csNodes: [],
          start: 0,
          tableOptions: {
            ...state.tableOptions,
            page: 1
          }
        }),
        this.getCSNodes
      )
    }
  }

  getRowStyle = (cell, row) => {
    return {
      whiteSpace: 'normal',
      wordWrap: 'break-word',
      backgroundColor: 'white'
    }
  }

  showActions = () => (
    <TableHeaderColumn
      dataAlign='center'
      dataField='eid'
      dataFormat={this.formatActionsDetail}
      headerAlign='center'
      tdStyle={this.getRowStyle}
      width='150'
    >
      {this.formatMessage(messages.actions)}
    </TableHeaderColumn>
  )

  formatActionsDetail = (cell, row) => {
    const {
      canEditConfigurations,
      canReadConfigurations,
      classes,
      editConfigurationUrl,
      getDeviceNonVolatileConfiguration,
      viewConfigurationUrl
    } = this.props
    const { tableOptions } = this.state

    const { page, sizePerPage } = tableOptions
    return (
      <div>
        <Link
          className='button-link'
          style={{ display: canReadConfigurations ? '' : 'none' }}
          to={{ pathname: viewConfigurationUrl(cell), state: { page, sizePerPage } }}
        >
          <Tooltip classes={{ tooltip: classes.tooltip }} title={this.formatMessage(messages.viewConfiguration)}>
            <div style={{ padding: 0, display: '-webkit-inline-box' }}>
              <IconButton style={{ padding: 5 }}>
                <SearchIcon />
              </IconButton>
            </div>
          </Tooltip>
        </Link>
        <Link
          className='button-link'
          style={{ display: canEditConfigurations && canReadConfigurations ? '' : 'none' }}
          to={{ pathname: editConfigurationUrl(cell), state: { page, sizePerPage } }}
        >
          <Tooltip classes={{ tooltip: classes.tooltip }} title={this.formatMessage(messages.editConfiguration)}>
            <div style={{ padding: 0, display: '-webkit-inline-box' }}>
              <IconButton style={{ padding: '5px' }}>
                <EditIcon />
              </IconButton>
            </div>
          </Tooltip>
        </Link>
        {canReadConfigurations && (
          <Tooltip classes={{ tooltip: classes.tooltip }} title={this.formatMessage(messages.downloadConfiguration)}>
            <div style={{ padding: 0, display: '-webkit-inline-box' }}>
              <IconButton onClick={() => getDeviceNonVolatileConfiguration(row.eid)} style={{ padding: '5px' }}>
                <GetAppIcon />
              </IconButton>
            </div>
          </Tooltip>
        )}
      </div>
    )
  }

  remote(remoteObj) {
    return {
      ...remoteObj,
      pagination: true,
      sizePerPage: true,
      filter: true
    }
  }

  formatLastActivityTime = operating_time => {
    return utcTimeToBrowserLocal(operating_time.lastDateTime)
  }

  sortLastActivityTime = (a, b, order) => {
    if (order === 'asc') {
      return (
        utcTimeToBrowserLocalMilliseconds(a.operating_time.lastDateTime) -
        utcTimeToBrowserLocalMilliseconds(b.operating_time.lastDateTime)
      )
    } else {
      return (
        utcTimeToBrowserLocalMilliseconds(b.operating_time.lastDateTime) -
        utcTimeToBrowserLocalMilliseconds(a.operating_time.lastDateTime)
      )
    }
  }

  renderPaginationShowsTotal = formatMessage => (start, to, total) =>
    (
      <span>
        {`${formatMessage(messages.showingRows)} ${start} ${formatMessage(messages.to)} ${to} ${formatMessage(
          messages.of
        )} ${total}`}
      </span>
    )

  getTableOptions = () => {
    const { tableOptions } = this.state
    return {
      onSizePerPageList: this.handleSizePerPageList,
      sizePerPageList: [
        {
          text: '10',
          value: 10
        },
        {
          text: '20',
          value: 20
        },
        {
          text: '50',
          value: 50
        },
        {
          text: '100',
          value: 100
        }
      ],
      onPageChange: this.handlePageChange,
      ignoreSinglePage: false,
      pageStartIndex: 1,
      paginationSize: 5,
      prePage: this.formatMessage(messages.prePage),
      nextPage: this.formatMessage(messages.nextPage),
      firstPage: this.formatMessage(messages.firstPage),
      lastPage: this.formatMessage(messages.lastPage),
      paginationShowsTotal: this.renderPaginationShowsTotal(this.formatMessage),
      paginationPosition: 'bottom',
      hideSizePerPage: false,
      alwaysShowAllBtns: false,
      withFirstAndLast: true,

      onFilterChange: this.handleFilterChange,
      onSortChange: this.handleSortChange,
      ...tableOptions
    }
  }

  render() {
    const { count, csNodes } = this.state

    return (
      <div>
        <Paper style={{ borderRadius: 0 }}>
          <div className='table-with-pagination'>
            <BootstrapTable
              bordered={false}
              data={csNodes}
              exportCSV={false}
              fetchInfo={{ dataTotalSize: count }}
              options={this.getTableOptions()}
              pagination
              remote={this.remote}
            >
              <TableHeaderColumn dataField='id' isKey width='0'>
                {this.formatMessage(messages.locationID)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField='name'
                dataSort
                filter={{
                  type: 'TextFilter',
                  delay: 400,
                  placeholder: this.formatMessage(messages.placeholder, { column: this.formatMessage(messages.name) })
                }}
                tdStyle={this.getRowStyle}
                width='150'
              >
                {this.formatMessage(messages.name)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField='eid'
                dataSort
                filter={{
                  type: 'TextFilter',
                  delay: 400,
                  placeholder: this.formatMessage(messages.placeholder, { column: this.formatMessage(messages.eid) })
                }}
                tdStyle={this.getRowStyle}
                width='150'
              >
                {this.formatMessage(messages.eid)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField='operating_time'
                dataFormat={this.formatLastActivityTime}
                dataSort
                sortFunc={this.sortLastActivityTime}
                tdStyle={this.getRowStyle}
                width='100'
              >
                {this.formatMessage(messages.lastActivityTime)}
              </TableHeaderColumn>
              {this.showActions()}
            </BootstrapTable>
          </div>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
        </Paper>
      </div>
    )
  }
}

ConfigurationsTable.propTypes = {
  canEditConfigurations: PropTypes.bool.isRequired,
  canReadConfigurations: PropTypes.bool.isRequired,
  canReadDevices: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  editConfigurationUrl: PropTypes.func.isRequired,
  getCSNodes: PropTypes.func.isRequired,
  getDeviceNonVolatileConfiguration: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  viewConfigurationUrl: PropTypes.func.isRequired
}

export default withRouter(withStyles(styles)(injectIntl(ConfigurationsTable)))
