import React from 'react'
import PropTypes from 'prop-types'

import PageTitle from 'components/PageTitle'

const NotificationsTitle = ({ title = '', button = null }) => {
  return <PageTitle title={title} button={button} />
}

NotificationsTitle.propTypes = {
  button: PropTypes.any,
  title: PropTypes.string.isRequired
}

export default NotificationsTitle
