import * as R from './constants'

// DEPRECATED
// If you find yourself defining functions in this file, please note that with
// React Router v4 each router handles its own subroutes and that each
// constant should be inside the component it belongs to.
// Check components/settings to see an example which handles many subroutes.

// Ikerlan: new router version routes
const getProfileUrl = () => R.MY_PROFILE
const getNotificationsUrl = () => R.NOTIFICATIONS
const getMapUrl = () => R.MAP
const getNodesRoute = () => R.NODES
const getForgotPasswordRoute = () => R.FORGOT_PASSWORD

// Redefined with the new router
// const getNodesRoute = () => R.NODES

const getEditNodesRoute = nodeId => R.NODES_EDIT_NODE.replace(':nodeId', nodeId)

const getSafetyRoute = () => R.SAFETY

const getStatsRoute = () => R.STATS

const getLifecycleRoute = () => R.LIFECYCLE

const getActionsStateRoute = () => R.ACTIONSSTATE

const getDashboardsRoute = (nodeFamily, nodeId) =>
  R.NODES_DASHBOARDS.replace(':nodeFamily', nodeFamily).replace(':nodeId', nodeId)

const getGeotrackingRoute = () => R.GEOTRACKING

export {
  getProfileUrl,
  getNotificationsUrl,
  getMapUrl,
  getNodesRoute,
  getForgotPasswordRoute,
  getEditNodesRoute,
  getSafetyRoute,
  getStatsRoute,
  getLifecycleRoute,
  getActionsStateRoute,
  getDashboardsRoute,
  getGeotrackingRoute
}
