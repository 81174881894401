import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { utils, actions } from 'ducks/login'
import { actions as groupActions, utils as groupUtils } from 'ducks/groups'
import { actions as themeActions } from 'ducks/theme'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'

import ProfileLoader from './ProfileLoader'

import { withStyles } from '@material-ui/core/styles'

import { injectIntl } from 'react-intl'
import messages from './messages'

import { client, logError } from 'utils/http'

const styles = {
  dialog: {
    minWidth: 600
  }
}

class ProfileLoaderUpdater extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false
    }

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage
  }

  componentDidMount() {
    const { isPlus1ConnectAuthorized, isPlus1ConnectBeingAuthorized, userState, plus1ConnectLogin } = this.props
    if (!isPlus1ConnectAuthorized && !isPlus1ConnectBeingAuthorized && userState !== 'failed') {
      plus1ConnectLogin()
    }
  }

  componentDidUpdate(prevProps) {
    const { isPlus1ConnectAuthorized, userState, loadGroups, groupId, loadProfile } = this.props
    if (isPlus1ConnectAuthorized && !prevProps.isPlus1ConnectAuthorized && userState !== 'failed') {
      loadGroups()
    }
    if (prevProps.groupId !== groupId && groupId) {
      if (!prevProps.groupId) this.setTheme()
      loadProfile()
    }
    if (prevProps.userState !== 'toBeExpired' && userState === 'toBeExpired') {
      this.setState({ open: true })
    }
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  setTheme = () => {
    const { groupId, setTheme } = this.props
    client
      .getGroupTheme(groupId)
      .then(({ data }) =>
        data.file ? client.getGroupThemeConfigUrl(groupId, data.hashId) : Promise.reject('No theme config file')
      )
      .then(({ data: url }) => client.getGroupThemeConfig(url))
      .then(response => {
        setTheme(response.data)
      })
      .catch(response => {
        logError(response)
        setTheme({})
      })
  }

  render() {
    const { isProfileLoaded, isProfileBeingLoaded, userState, permissions, unauthorize, children, classes } = this.props
    const { open } = this.state
    return (
      <React.Fragment>
        <ProfileLoader
          isProfileBeingLoaded={isProfileBeingLoaded}
          isProfileLoaded={isProfileLoaded}
          permissions={permissions}
          unauthorize={unauthorize}
          userState={userState}
        >
          {children}
        </ProfileLoader>
        <Dialog classes={{ paper: classes.dialog }} disableBackdropClick onClose={this.handleClose} open={open}>
          <DialogTitle disableTypography style={{ fontSize: 20 }}>
            {this.formatMessage(messages.toExpire)}
          </DialogTitle>
          <DialogContent>
            <DialogContentText color='textPrimary' style={{ display: 'inline-flex', fontSize: 14 }}>
              {this.formatMessage(messages.timeToExpire)}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button className='primary-action-button' onClick={this.handleClose}>
              {this.formatMessage(messages.understand)}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  isProfileLoaded: utils.isProfileLoaded(state),
  isProfileBeingLoaded: utils.isProfileBeingLoaded(state),
  isPlus1ConnectAuthorized: utils.isPlus1ConnectAuthorized(state),
  isPlus1ConnectBeingAuthorized: utils.isPlus1ConnectBeingAuthorized(state),
  userState: utils.getUserState(state),
  groupId: groupUtils.getSelectedGroup(state).id,
  permissions: groupUtils.getUserPermissions(state)
})

const mapDispatchToProps = dispatch => ({
  loadProfile: () => dispatch(actions.loadProfile()),
  plus1ConnectLogin: () => dispatch(actions.plus1ConnectLogin()),
  unauthorize: () => dispatch(actions.unauthorize()),
  loadGroups: () => dispatch(groupActions.getGroups()),
  setTheme: theme => dispatch(themeActions.setTheme(theme))
})

ProfileLoaderUpdater.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  groupId: PropTypes.string,
  intl: PropTypes.object.isRequired,
  isPlus1ConnectAuthorized: PropTypes.bool.isRequired,
  isPlus1ConnectBeingAuthorized: PropTypes.bool.isRequired,
  isProfileBeingLoaded: PropTypes.bool.isRequired,
  isProfileLoaded: PropTypes.bool.isRequired,
  loadGroups: PropTypes.func.isRequired,
  loadProfile: PropTypes.func.isRequired,
  permissions: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  plus1ConnectLogin: PropTypes.func.isRequired,
  setTheme: PropTypes.func.isRequired,
  unauthorize: PropTypes.func.isRequired,
  userState: PropTypes.string.isRequired
}

ProfileLoaderUpdater.defaultProps = {
  groupId: ''
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(injectIntl(ProfileLoaderUpdater)))
