import { defineMessages } from 'react-intl'

const messages = defineMessages({
  newWidget: {
    id: 'AddWidget.newWidget',
    description: 'AddWidget new widget title',
    defaultMessage: 'NEW WIDGET'
  },
  widgetType: {
    id: 'AddWidget.widgetType',
    description: 'Widget type',
    defaultMessage: 'Widget Type'
  },
  box: {
    id: 'AddWidget.box',
    description: 'Widget box',
    defaultMessage: 'Box'
  },
  text: {
    id: 'AddWidget.text',
    description: 'Widget text',
    defaultMessage: 'Text'
  },
  image: {
    id: 'AddWidget.image',
    description: 'Widget image',
    defaultMessage: 'Image'
  },
  realtimevalue: {
    id: 'AddWidget.realtimevalue',
    description: 'Widget real time value',
    defaultMessage: 'Real Time Value'
  },
  value: {
    id: 'AddWidget.value',
    description: 'Widget value',
    defaultMessage: 'Value'
  },
  gauge: {
    id: 'AddWidget.gauge',
    description: 'Widget gauge',
    defaultMessage: 'Gauge'
  },
  columnchart: {
    id: 'AddWidget.columnchart',
    description: 'Widget Column chart',
    defaultMessage: 'Column chart'
  },
  linechart: {
    id: 'AddWidget.linechart',
    description: 'Widget Line chart',
    defaultMessage: 'Line chart'
  },
  line: {
    id: 'AddWidget.line',
    description: 'Widget Line',
    defaultMessage: 'Line'
  },
  link: {
    id: 'AddWidget.link',
    description: 'Widget Link',
    defaultMessage: 'Link'
  },
  historic: {
    id: 'AddWidget.historic',
    description: 'Widget Historic',
    defaultMessage: 'Historic'
  },
  map: {
    id: 'AddWidget.map',
    description: 'Widget Map',
    defaultMessage: 'Map'
  },
  video: {
    id: 'AddWidget.video',
    description: 'Widget Video',
    defaultMessage: 'Video'
  },
  weather: {
    id: 'AddWidget.weather',
    description: 'Widget Weather',
    defaultMessage: 'Weather'
  },
  embeddedweb: {
    id: 'AddWidget.embeddedweb',
    description: 'Widget embeddedweb',
    defaultMessage: 'Embedded web'
  },
  appearance: {
    id: 'AddWidget.appearance',
    description: 'Appearance widgets subheader',
    defaultMessage: 'Appearance widgets'
  },
  data: {
    id: 'AddWidget.data',
    description: 'Data widgets subheader',
    defaultMessage: 'Data widgets'
  },
  externalInfo: {
    id: 'AddWidget.externalInfo',
    description: 'External information widgets subheader',
    defaultMessage: 'External information widgets'
  }
})

export default messages
