import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Popper from '@material-ui/core/Popper'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import WarningIcon from '@material-ui/icons/Warning'

import messages from './messages'

const styles = () => ({
  popper: {
    maxWidth: 400,
    backgroundColor: 'white',
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
    borderRadius: 4,
    position: 'fixed !important',
    top: '-20px !important',
    left: '-20px !important',
    zIndex: 9999
  },
  dialogTitle: {
    backgroundColor: '#b60710',
    display: 'flex',
    borderRadius: 4
  },
  iconButton: {
    position: 'absolute',
    right: 3,
    top: 3,
    color: 'white',
    marginTop: 5
  },
  warningIcon: {
    marginRight: 20
  },
  dialogTitleDiv: {
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    fontWeight: 'normal'
  },
  dialogSubtitle: {
    color: '#B60710',
    fontSize: 16,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    marginBottom: 5
  },
  dialogContentRoot: {
    padding: 15
  }
})

class ObsoleteFirmwareWarningPopUp extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage
    this.state = {
      isOpen: true
    }
  }

  handleClose = () => {
    this.setState({ isOpen: false })
  }

  render() {
    const { isOpen } = this.state
    const { classes } = this.props

    return (
      <Popper anchorEl={document.getElementById('root')} className={classes.popper} open={isOpen} placement='left-end'>
        <DialogTitle classes={{ root: classes.dialogTitle }}>
          <div className={classes.dialogTitleDiv}>
            <WarningIcon classes={{ root: classes.warningIcon }} />
            {this.formatMessage(messages.importantNotification)}
            <IconButton classes={{ root: classes.iconButton }} onClick={this.handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent className={classes.dialogContentRoot}>
          <DialogContentText className={classes.dialogSubtitle}>
            {this.formatMessage(messages.CS100AndCS500FirmwareUpdateTitle)}
          </DialogContentText>
          <DialogContentText>{this.formatMessage(messages.CS100AndCS500FirmwareUpdateMessage)}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className='cancel-button' onClick={this.handleClose}>
            {this.formatMessage(messages.closeButton)}
          </Button>
        </DialogActions>
      </Popper>
    )
  }
}

ObsoleteFirmwareWarningPopUp.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired
}

export default withStyles(styles)(injectIntl(ObsoleteFirmwareWarningPopUp))
