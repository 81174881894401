import PropTypes from 'prop-types'
import React from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

import Alert from 'components/Alert'
import SelectColumnsButton from 'components/SelectColumnsButton'
import { devicesEndpointAdapter } from 'utils/adapters'
import { client, logError, mapDynamicDevicesFilterObject, getFiltersMatchingDevices } from 'utils/http'
import reactBootstrapTable from 'utils/reactBootstrapTable'
import { utcTimeToBrowserLocal } from 'utils/timeFormat'

import messages from './messages'
import { checkSelectedDevices } from '../utils'

class AssignDevicesToNotificationTable extends React.Component {
  constructor(props) {
    super(props)
    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage
    this.state = {
      unassignedDevices: [],
      assignedDevicesDetail: [],

      loadingUnassignedDevices: true,
      loadingDevicesAssignedDetail: true,

      filter: {},
      sort: {},

      alertMessages: false,
      alertMessagesType: '',
      alertMessagesTitle: '',
      alertMessagesText: [''],

      count: reactBootstrapTable.count,
      length: reactBootstrapTable.length,
      elementsPerPage: reactBootstrapTable.elementsPerPage,
      page: reactBootstrapTable.page,
      start: reactBootstrapTable.start,

      selectedDevices: [],
      selectedDevicesForNotificationRule: [],
      newListOfParametersOfDevicesForNotification: [],

      alertMessagesDevicesSelection: false,
      alertMessagesDevicesSelectionType: '',
      alertMessagesDevicesSelectionTitle: '',
      alertMessagesDevicesSelectionText: [''],

      visibleColumns: {
        name: true,
        machineModel: true,
        machineType: true,
        machineBrand: false,
        machineSerialNumber: true,
        deviceType: true,
        eid: false,
        lastActivity: false,
        firmwareVersion: false,
        deviceConfiguration: true,
        deviceConfigurationVersion: false
      }
    }
  }

  componentDidMount() {
    this.getUnassignedDevices()
    this.getAssignedDevicesDetails()
  }

  componentDidUpdate(prevProps) {
    const { notification } = this.props
    if (prevProps.notification !== notification) this.getUnassignedDevices()
  }

  //Get all available devices for the group to which the notification is associated
  //and desactivate devices that the notification has already associated
  getUnassignedDevices = () => {
    const { length, start, filter, sort } = this.state
    const { getGroupDevices, notification } = this.props

    const groupId = notification.groupId
    const deviceFields = {
      Device: [
        'id',
        'name',
        'eid',
        'creation_date',
        'device_type',
        'operating_time',
        'main_firmware_version',
        'created_at',
        'machine_model',
        'machine_type',
        'machine_brand',
        'machine_serial_number'
      ],
      Configuration: ['id', 'multi_functional_inputs', 'sensors_map', 'name', 'version_number']
    }
    const mappedFilters = mapDynamicDevicesFilterObject(filter, sort)
    const params = [groupId, length, start, deviceFields, mappedFilters]
    getGroupDevices(...params)
      .then(response => {
        const { devices = [], total = 0 } = response.data
        const mappedDevices = devicesEndpointAdapter(devices)
        this.getCs500Configuration(groupId, mappedDevices)
          .then(cs500Configurations => {
            const groupDevicesWithConfigurations = devices.map(device => {
              const newDevice = { ...device }
              if (device.device_type === 'CS500' && device.eid in cs500Configurations) {
                newDevice.Configuration = cs500Configurations[device.eid].parsedConfiguration.deviceConfiguration
              }
              return newDevice
            })

            const { selectedDevicesForNotificationRule } = this.state
            const filterMatchingDevices = getFiltersMatchingDevices(filter, selectedDevicesForNotificationRule)
            const selectedDeviceIds = filterMatchingDevices.map(device => device.id)

            this.setState(
              {
                unassignedDevices: groupDevicesWithConfigurations,
                count: total,
                loadingUnassignedDevices: false,
                selectedDevices: selectedDeviceIds,
                selectedDevicesForNotificationRule: filterMatchingDevices
              },
              () => this.saveDevicesAssignement()
            )
          })
          .catch(responseError => {
            this.setState({
              unassignedDevices: response.data.devices,
              count: response.data.total,

              loadingUnassignedDevices: false
            })
          })
      })
      .catch(response => {
        const errorResponse = { ...response }

        if (errorResponse && errorResponse.error && errorResponse.error.response) {
          const errorDetail = errorResponse.error.response
          let error404Checked = false
          switch (errorDetail.status) {
            case 400:
            case 401:
            case 403:
            case 406:
            case 500:
              this.setState({
                loadingUnassignedDevices: false,
                unassignedDevices: [],
                count: 0,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.error, {
                  number: errorDetail.status.toString()
                }),
                alertMessagesText: [
                  this.formatMessage(messages.errorRequestingMachines) +
                    ' (' +
                    this.formatMessage(messages['error' + errorDetail.status + 'Text']) +
                    ')'
                ]
              })
              break
            case 404:
              if (errorDetail.data !== undefined) {
                let errorReceived
                if (errorDetail.data.message !== undefined) {
                  errorReceived = errorDetail.data.message
                } else {
                  errorReceived = errorDetail.data
                }
                if (
                  errorReceived.includes('NO DEVICE EXISTS') ||
                  errorReceived.includes('NO DEVICE IS ASSOCIATED TO THIS USER IN THIS GROUP')
                ) {
                  error404Checked = true
                  this.setState({
                    loadingUnassignedDevices: false,
                    unassignedDevices: [],
                    count: 0
                  })
                } else if (errorReceived.includes('INVALID TOKEN')) {
                  error404Checked = true
                  this.setState({
                    loadingUnassignedDevices: false,
                    unassignedDevices: [],
                    count: 0
                  })
                }
              }
              if (!error404Checked) {
                this.setState({
                  loadingUnassignedDevices: false,
                  unassignedDevices: [],
                  count: 0,
                  alertMessages: true,
                  alertMessagesType: 'danger',
                  alertMessagesTitle: this.formatMessage(messages.error, {
                    number: '404'
                  }),
                  alertMessagesText: [
                    this.formatMessage(messages.errorRequestingMachines) +
                      ' (' +
                      this.formatMessage(messages.error404Text) +
                      ')'
                  ]
                })
              }
              break
            default:
              this.setState({
                loadingUnassignedDevices: false,
                unassignedDevices: [],
                count: 0,
                alertMessages: true,
                alertMessagesType: 'danger',
                alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
                alertMessagesText: [
                  this.formatMessage(messages.errorRequestingMachines) +
                    ' (' +
                    this.formatMessage(messages.errorUndefinedText) +
                    ')'
                ]
              })
              logError(errorResponse)
          }
        } else {
          this.setState({
            loadingUnassignedDevices: false,
            unassignedDevices: [],
            count: 0,
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
            alertMessagesText: [
              this.formatMessage(messages.errorRequestingMachines) +
                ' (' +
                this.formatMessage(messages.errorUndefinedText) +
                ')'
            ]
          })
          logError(errorResponse)
        }
      })
  }

  getCs500Configuration = (groupId, cs500Devices) => {
    const { fetchDeviceNonVolatileConfiguration } = this.props

    const cs500ConfigurationPromises = cs500Devices
      .map(device => {
        if (device.deviceType === 'CS500') {
          return {
            EID: device.EID,
            config: fetchDeviceNonVolatileConfiguration(groupId, device.EID).catch(error => {
              // If there's an error fetching the configuration we shouldn't break the whole chain. So that's why we simply return an empty object.
              return {}
            })
          }
        } else {
          return null
        }
      })
      .filter(Boolean)

    return Promise.all(cs500ConfigurationPromises.map(item => item.config)).then(values => {
      const cs500Configurations = values.reduce((acc, value) => ({ ...acc, ...value }), {})
      return cs500Configurations
    })
  }

  getAssignedDevicesDetails = () => {
    const { getDevicesDetail, notification } = this.props

    const devicesAssignedEids = notification.devices.map(deviceData => deviceData.eid)
    if (devicesAssignedEids.length > 0) {
      const groupId = notification.groupId
      getDevicesDetail(groupId, devicesAssignedEids)
        .then(response => {
          if (response.data.devices !== undefined && response.data.devices.length > 0) {
            const assignedDevices = response.data.devices.map(device => {
              const newDevice = { ...device }
              newDevice.deviceType = device.device_type
              return newDevice
            })

            this.getCs500Configuration(groupId, assignedDevices).then(cs500Configurations => {
              const groupDevicesWithConfigurations = assignedDevices.map(device => {
                const newDevice = { ...device }
                if (device.deviceType === 'CS500' && device.EID in cs500Configurations) {
                  newDevice.Configuration = cs500Configurations[device.EID].parsedConfiguration.deviceConfiguration
                }
                return newDevice
              })

              this.setState({
                assignedDevicesDetail: groupDevicesWithConfigurations,
                loadingDevicesAssignedDetail: false
              })
            })
          }
        })
        .catch(response => {
          const error = { ...response }

          if (error.response !== undefined && error.response.status !== undefined) {
            let errorMessagesTitle = ''
            let errorMessagesText = ''
            switch (error.response.status) {
              case 400: // Bad request
                errorMessagesTitle = this.formatMessage(messages.error, { message: 400 })
                errorMessagesText = [this.formatMessage(messages.error400Text)]
                break
              case 401: // Invalid credentials
                errorMessagesTitle = this.formatMessage(messages.error, { message: 401 })
                errorMessagesText = [error.response.message]
                break
              case 403: // Access denied
                errorMessagesTitle = this.formatMessage(messages.error, { message: 403 })
                errorMessagesText = [this.formatMessage(messages.error403Text)]
                break
              case 404: // API url not found
                errorMessagesTitle = this.formatMessage(messages.error, { message: 404 })
                errorMessagesText = [this.formatMessage(messages.error404Text)]
                break
              case 500: // Server errors
                errorMessagesTitle = this.formatMessage(messages.error, { message: 500 })
                errorMessagesText = [error.response.data.error_description]
                break
              default:
                errorMessagesTitle = this.formatMessage(messages.errorUndefinedTitle)
                errorMessagesText = [this.formatMessage(messages.errorUndefinedText)]
            }

            this.setState({
              assignedDevicesDetail: [],
              loadingDevicesAssignedDetail: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: errorMessagesTitle,
              alertMessagesText: errorMessagesText
            })
          } else {
            this.setState({
              assignedDevicesDetail: [],
              loadingDevicesAssignedDetail: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
              alertMessagesText: [this.formatMessage(messages.errorUndefinedText)]
            })
          }
        })
    } else {
      this.setState({
        assignedDevicesDetail: [],
        loadingDevicesAssignedDetail: false
      })
    }
  }

  handleClearSelectedDevices = () => {
    this.setState(
      {
        selectedDevices: [],
        selectedDevicesForNotificationRule: [],
        alertMessagesDevicesSelection: false,
        alertMessagesDevicesSelectionType: '',
        alertMessagesDevicesSelectionTitle: '',
        alertMessagesDevicesSelectionText: ['']
      },
      () => this.saveDevicesAssignement()
    )
  }

  handlePageChange = (page, sizePerPage) => {
    this.setState(
      {
        start: (page - 1) * sizePerPage,
        page,
        length: sizePerPage,
        unassignedDevices: [],
        loadingUnassignedDevices: true
      },
      () => {
        this.getUnassignedDevices()
      }
    )
  }

  handleSizePerPageList = sizePerPage => {
    this.setState(
      {
        length: sizePerPage,
        unassignedDevices: [],
        loadingUnassignedDevices: true
      },
      () => this.getUnassignedDevices()
    )
  }

  handleFilterChange = filterObj => {
    this.setState(
      {
        filter: filterObj,
        unassignedDevices: [],
        loadingUnassignedDevices: true
      },
      () => this.getUnassignedDevices()
    )
  }

  handleSortChange = (sortName, sortOrder) => {
    if (sortName.includes('.')) {
      this.setState({ sort: [] })
    } else {
      this.setState(
        {
          sort: {
            sortColumnKey: sortName,
            sortColumnModel: 'Device',
            sortColumnOrder: sortOrder.toUpperCase()
          },
          unassignedDevices: [],
          loadingUnassignedDevices: true
        },
        () => this.getUnassignedDevices()
      )
    }
  }

  onRowSelect = (row, isSelected, event, rowNumber) => {
    const { selectedDevices, selectedDevicesForNotificationRule } = this.state

    const element = row
    const elementId = row.id

    const currentSelectedDevices = selectedDevices.map(device => device)
    const currentSelectedDevicesForNotificationRule = selectedDevicesForNotificationRule.map(device => device)
    const indexOfDevice = selectedDevices.indexOf(elementId)

    if (isSelected) {
      if (indexOfDevice < 0) {
        currentSelectedDevices.push(elementId)
        currentSelectedDevicesForNotificationRule.push(element)
      }
    } else {
      if (indexOfDevice > -1) {
        currentSelectedDevices.splice(indexOfDevice, 1)
        currentSelectedDevicesForNotificationRule.splice(indexOfDevice, 1)
      }
    }

    this.setState(
      {
        selectedDevices: currentSelectedDevices,
        selectedDevicesForNotificationRule: currentSelectedDevicesForNotificationRule,
        alertMessagesDevicesSelection: false,
        alertMessagesDevicesSelectionType: '',
        alertMessagesDevicesSelectionTitle: '',
        alertMessagesDevicesSelectionText: ['']
      },
      () => this.saveDevicesAssignement()
    )
  }

  onSelectAll = (isSelected, rows) => {
    const { selectedDevices, selectedDevicesForNotificationRule } = this.state
    let indexOfDevice

    const currentSelectedDevices = selectedDevices.map(device => device)
    const currentSelectedDevicesForNotificationRule = selectedDevicesForNotificationRule.map(device => device)
    const devicesToProcess = rows.map(device => {
      return device
    })

    devicesToProcess.map(device => {
      indexOfDevice = currentSelectedDevices.indexOf(device.id)

      if (isSelected) {
        if (indexOfDevice < 0) {
          currentSelectedDevices.push(device.id)
          currentSelectedDevicesForNotificationRule.push(device)
        }
      } else {
        if (indexOfDevice > -1) {
          currentSelectedDevices.splice(indexOfDevice, 1)
          currentSelectedDevicesForNotificationRule.splice(indexOfDevice, 1)
        }
      }

      return device
    })

    this.setState(
      {
        selectedDevices: currentSelectedDevices,
        selectedDevicesForNotificationRule: currentSelectedDevicesForNotificationRule,
        alertMessagesDevicesSelection: false,
        alertMessagesDevicesSelectionType: '',
        alertMessagesDevicesSelectionTitle: '',
        alertMessagesDevicesSelectionText: ['']
      },
      () => this.saveDevicesAssignement()
    )
  }

  saveDevicesAssignement = () => {
    //Check that all the chosen devices have the same configuration file and the same
    // to the devices that have associated already

    const { assignedDevicesDetail, selectedDevicesForNotificationRule } = this.state
    const { notification } = this.props

    const devicesSelectedToAssign = devicesEndpointAdapter(selectedDevicesForNotificationRule)

    let deviceAlreadyAssignedWithDetail = []
    if (assignedDevicesDetail.length > 0) {
      deviceAlreadyAssignedWithDetail = assignedDevicesDetail[0]
    }
    const configurationOfDeviceAlreadyAssigned = deviceAlreadyAssignedWithDetail.Configuration
    deviceAlreadyAssignedWithDetail = {
      ...deviceAlreadyAssignedWithDetail,
      deviceConfiguration: configurationOfDeviceAlreadyAssigned
    }

    const devicesToCheck = [deviceAlreadyAssignedWithDetail]
    devicesSelectedToAssign.map(device => {
      devicesToCheck.push(device)
      return devicesToCheck
    })

    let devicesAssignedErrorText = ''
    let newListOfParametersOfDevicesForNotification = notification.devices

    const devicesSelectionError = checkSelectedDevices(devicesToCheck)
    if (devicesSelectionError === '') {
      //Get name, deviceType and EID of devices
      const devicesEIDs = devicesToCheck.map(device => ({
        eid: device.EID,
        deviceName: device.name,
        deviceType: device.deviceType
      }))
      newListOfParametersOfDevicesForNotification = devicesEIDs
    } else {
      if (devicesSelectionError === 'devicesWithDifferentConfigurationFile') {
        devicesAssignedErrorText = this.formatMessage(messages.machineSelectedWithDifferentConfigurationFile)
      } else if (devicesSelectionError === 'devicesWithoutConfigurationFile') {
        devicesAssignedErrorText = this.formatMessage(messages.machineSelectedWithoutConfigurationFile)
      } else if (devicesSelectionError === 'devicesWithConfigurationFileWithoutSignals') {
        devicesAssignedErrorText = this.formatMessage(messages.machineSelectedWithoutConfigurationFile)
      }
    }

    if (devicesAssignedErrorText !== '') {
      this.setState({
        newListOfParametersOfDevicesForNotification: [],
        alertMessagesDevicesSelection: true,
        alertMessagesDevicesSelectionType: 'danger',
        alertMessagesDevicesSelectionTitle: '',
        alertMessagesDevicesSelectionText: [devicesAssignedErrorText]
      })
    } else {
      this.setState({
        newListOfParametersOfDevicesForNotification,
        alertMessagesDevicesSelection: false,
        alertMessagesDevicesSelectionType: '',
        alertMessagesDevicesSelectionTitle: '',
        alertMessagesDevicesSelectionText: ['']
      })
    }
  }

  handleAssignDevices = () => {
    const { onNotificationAssignDevicesEdited, notification } = this.props
    const { newListOfParametersOfDevicesForNotification } = this.state
    const devicesToAssign = newListOfParametersOfDevicesForNotification
    client
      .assignDevicesToRuleInstance(notification.hashId, devicesToAssign)
      .then(response => {
        this.setState(
          {
            alertMessagesDevicesSelection: false,
            alertMessagesDevicesSelectionType: '',
            alertMessagesDevicesSelectionTitle: '',
            alertMessagesDevicesSelectionText: ['']
          },
          () => onNotificationAssignDevicesEdited()
        )
      })
      .catch(response => {
        const error = { ...response }
        let error401ToShow = ''
        let error500ToShow = ''
        switch (error.response.status) {
          case 400: // Bad request
            this.setState({
              alertMessagesDevicesSelection: true,
              alertMessagesDevicesSelectionType: 'danger',
              alertMessagesDevicesSelectionTitle: '',
              alertMessagesDevicesSelectionText: [this.formatMessage(messages.otherError400Text)]
            })
            break
          case 401: // Invalid credentials
            if (error.response.message !== undefined) {
              error401ToShow = error.response.message
            }
            this.setState({
              alertMessagesDevicesSelection: true,
              alertMessagesDevicesSelectionType: 'danger',
              alertMessagesDevicesSelectionTitle: '',
              alertMessagesDevicesSelectionText: [error401ToShow]
            })
            break
          case 403: // Access denied
            this.setState({
              alertMessagesDevicesSelection: true,
              alertMessagesDevicesSelectionType: 'danger',
              alertMessagesDevicesSelectionTitle: '',
              alertMessagesDevicesSelectionText: [this.formatMessage(messages.error403Text)]
            })
            break
          case 404: // API url not found
            this.setState({
              alertMessagesDevicesSelection: true,
              alertMessagesDevicesSelectionType: 'danger',
              alertMessagesDevicesSelectionTitle: '',
              alertMessagesDevicesSelectionText: [this.formatMessage(messages.otherError404Text)]
            })
            break
          case 406: // Not acceptable
            this.setState({
              alertMessagesDevicesSelection: true,
              alertMessagesDevicesSelectionType: 'danger',
              alertMessagesDevicesSelectionTitle: '',
              alertMessagesDevicesSelectionText: [this.formatMessage(messages.error406Text)]
            })
            break
          case 409: // Data integrity violation
            this.setState({
              alertMessagesDevicesSelection: true,
              alertMessagesDevicesSelectionType: 'danger',
              alertMessagesDevicesSelectionTitle: '',
              alertMessagesDevicesSelectionText: [this.formatMessage(messages.error409Text)]
            })
            break
          case 422: // Validation failed
            this.setState({
              alertMessagesDevicesSelection: true,
              alertMessagesDevicesSelectionType: 'danger',
              alertMessagesDevicesSelectionTitle: '',
              alertMessagesDevicesSelectionText: [this.formatMessage(messages.error422Text)]
            })
            break
          case 500: // Server errors
            error500ToShow = this.formatMessage(messages.error500ToSHow)
            if (
              error.response.message.data !== undefined &&
              error.response.message.data.error_description !== undefined
            ) {
              error500ToShow = error.response.data.error_description
            }
            this.setState({
              alertMessagesDevicesSelection: true,
              alertMessagesDevicesSelectionType: 'danger',
              alertMessagesDevicesSelectionTitle: '',
              alertMessagesDevicesSelectionText: [error500ToShow]
            })
            break
          default:
            this.setState({
              alertMessagesDevicesSelection: true,
              alertMessagesDevicesSelectionType: 'danger',
              alertMessagesDevicesSelectionTitle: '',
              alertMessagesDevicesSelectionText: [this.formatMessage(messages.errorUndefinedText)]
            })
            logError(response)
        }
      })
  }

  handleChangeColumnVisibility = columnId => {
    this.setState(state => {
      const updatedColumnVisibility = !state.visibleColumns[columnId]
      return {
        visibleColumns: {
          ...state.visibleColumns,
          [columnId]: updatedColumnVisibility
        }
      }
    })
  }

  renderTableError = () => {
    const { alertMessages, alertMessagesText, alertMessagesTitle, alertMessagesType } = this.state
    return (
      <Alert
        alertType={alertMessagesType}
        closeFunction={this.closeAlert}
        messageText={alertMessagesText}
        messageTitle={alertMessagesTitle}
        showAlert={alertMessages}
      />
    )
  }

  showDevicesTools = () => {
    const { selectedDevices, alertMessagesDevicesSelection, visibleColumns } = this.state

    return (
      <div className='container-fluid'>
        <Grid container style={{ marginTop: 14 }}>
          <Grid container item spacing={1} xs={7}>
            <Grid item>
              <Button disabled style={{ color: 'color: rgba(0, 0, 0, 0.87)' }}>
                {this.formatMessage(messages.selectedMachines)} ({selectedDevices.length})
              </Button>
            </Grid>
            <Grid item>
              <Button
                className='secondary-action-button'
                disabled={selectedDevices.length === 0}
                onClick={this.handleClearSelectedDevices}
              >
                {this.formatMessage(messages.clearSelection)}
              </Button>
            </Grid>
            <Grid item>
              <SelectColumnsButton
                columns={visibleColumns}
                onChangeColumnVisibility={this.handleChangeColumnVisibility}
              />
            </Grid>
          </Grid>
          <Grid container item justifyContent='flex-end' xs={5}>
            <Grid item>
              <Button
                className='primary-action-button'
                disabled={selectedDevices.length === 0 || alertMessagesDevicesSelection}
                onClick={this.handleAssignDevices}
              >
                {this.formatMessage(messages.assignMachines)}
              </Button>
            </Grid>
          </Grid>
          <Grid container item style={{ marginTop: 10, marginBottom: 10 }} xs={12}>
            {this.formatMessage(messages.machineConditionText)}
            <br />
            {this.formatMessage(messages.machineConditionTextNoSelectableMachines)}
          </Grid>
        </Grid>
      </div>
    )
  }

  formatLastActivity = operatingTime => {
    return utcTimeToBrowserLocal(operatingTime.lastDateTime)
  }

  formatMachineField = cell => {
    return cell ? cell : '-'
  }

  formatActiveConfigurationColumn = cell => {
    return cell?.name ? cell.name : '-'
  }

  formatActiveConfigurationVersionColumn = cell => {
    return cell?.version_number ? cell.version_number : '-'
  }

  renderPaginationShowsTotal = formatMessage => (start, to, total) =>
    (
      <span>
        {`${formatMessage(messages.showingMachines)} ${start} ${formatMessage(messages.to)} ${to} ${formatMessage(
          messages.of
        )} ${total}`}
      </span>
    )

  showDevices = () => {
    const {
      assignedDevicesDetail,
      count,
      elementsPerPage,
      length,
      page,
      selectedDevices,
      unassignedDevices,
      loadingDevicesAssignedDetail,
      loadingUnassignedDevices,
      visibleColumns
    } = this.state

    const loading = loadingDevicesAssignedDetail || loadingUnassignedDevices

    const noDataText = loading ? <CircularProgress /> : this.formatMessage(messages.noMachines)

    const tableOptions = {
      noDataText,

      onSizePerPageList: this.handleSizePerPageList,
      sizePerPageList: elementsPerPage,
      sizePerPage: length,
      page,

      onPageChange: this.handlePageChange,
      ignoreSinglePage: false,
      pageStartIndex: 1,
      paginationSize: 5,
      prePage: this.formatMessage(messages.prePage),
      nextPage: this.formatMessage(messages.nextPage),
      firstPage: this.formatMessage(messages.firstPage),
      lastPage: this.formatMessage(messages.lastPage),
      paginationShowsTotal: this.renderPaginationShowsTotal(this.formatMessage),
      paginationPosition: 'bottom',
      hideSizePerPage: false,
      alwaysShowAllBtns: false,
      withFirstAndLast: true,

      onFilterChange: this.handleFilterChange,
      onSortChange: this.handleSortChange
    }

    const currentAssignedDeviceIds = assignedDevicesDetail.map(device => device.id)

    const selectRowProp = {
      mode: 'checkbox',
      clickToSelect: false,
      onSelect: this.onRowSelect,
      onSelectAll: this.onSelectAll,
      bgColor: '#f5f5f5',
      selected: selectedDevices,
      unselectable: currentAssignedDeviceIds
    }

    const remote = remoteObj => ({
      ...remoteObj,
      search: false,
      pagination: true,
      sizePerPage: true,
      filter: true
    })

    const data = loading ? [] : unassignedDevices

    const deviceTypeFilterOptions = {
      CS100: 'CS100',
      CS500: 'CS500',
      CS10: 'CS10'
    }

    return (
      <div key='panel2' className='container-fluid'>
        <Paper>
          {this.showDevicesTools()}
          <div className='table-with-pagination'>
            <BootstrapTable
              bordered={false}
              condensed={false}
              data={data}
              exportCSV={false}
              fetchInfo={{ dataTotalSize: count }}
              hover
              keyField='id'
              multiColumnSearch={false}
              options={tableOptions}
              pagination
              remote={remote}
              search={false}
              searchPlaceholder={this.formatMessage(messages.searchPlaceholder)}
              selectRow={selectRowProp}
              striped={false}
            >
              <TableHeaderColumn dataField='id' hidden />
              <TableHeaderColumn
                dataField='name'
                dataSort
                filter={{
                  type: 'TextFilter',
                  delay: 400,
                  placeholder: `${this.formatMessage(messages.placeholder, {
                    column: this.formatMessage(messages.name).toLowerCase()
                  })}`
                }}
                hidden={!visibleColumns.name}
              >
                {this.formatMessage(messages.name)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField='machine_model'
                dataFormat={this.formatMachineField}
                dataSort
                filter={{
                  type: 'TextFilter',
                  delay: 400,
                  placeholder: `${this.formatMessage(messages.placeholder, {
                    column: this.formatMessage(messages.machineModel).toLowerCase()
                  })}`
                }}
                hidden={!visibleColumns.machineModel}
              >
                {this.formatMessage(messages.machineModel)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField='machine_type'
                dataFormat={this.formatMachineField}
                dataSort
                filter={{
                  type: 'TextFilter',
                  delay: 400,
                  placeholder: `${this.formatMessage(messages.placeholder, {
                    column: this.formatMessage(messages.machineType).toLowerCase()
                  })}`
                }}
                hidden={!visibleColumns.machineType}
              >
                {this.formatMessage(messages.machineType)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField='machine_brand'
                dataFormat={this.formatMachineField}
                dataSort
                filter={{
                  type: 'TextFilter',
                  delay: 400,
                  placeholder: `${this.formatMessage(messages.placeholder, {
                    column: this.formatMessage(messages.machineBrand).toLowerCase()
                  })}`
                }}
                hidden={!visibleColumns.machineBrand}
              >
                {this.formatMessage(messages.machineBrand)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField='machine_serial_number'
                dataFormat={this.formatMachineField}
                dataSort
                filter={{
                  type: 'TextFilter',
                  delay: 400,
                  placeholder: `${this.formatMessage(messages.placeholder, {
                    column: this.formatMessage(messages.machineSerialNumber).toLowerCase()
                  })}`
                }}
                hidden={!visibleColumns.machineSerialNumber}
              >
                {this.formatMessage(messages.machineSerialNumber)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField='device_type'
                dataSort
                filter={{
                  type: 'SelectFilter',
                  delay: 400,
                  options: deviceTypeFilterOptions,
                  placeholder: `${this.formatMessage(messages.placeholder, {
                    column: this.formatMessage(messages.deviceType).toLowerCase()
                  })}`
                }}
                hidden={!visibleColumns.deviceType}
              >
                {this.formatMessage(messages.deviceType)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField='eid'
                dataSort
                filter={{
                  type: 'TextFilter',
                  delay: 400,
                  placeholder: `${this.formatMessage(messages.placeholder, {
                    column: this.formatMessage(messages.eid).toLowerCase()
                  })}`
                }}
                hidden={!visibleColumns.eid}
              >
                {this.formatMessage(messages.eid)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField='operating_time'
                dataFormat={this.formatLastActivity}
                hidden={!visibleColumns.lastActivity}
              >
                {this.formatMessage(messages.lastActivity)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField='main_firmware_version'
                dataSort
                filter={{
                  type: 'TextFilter',
                  delay: 400,
                  placeholder: `${this.formatMessage(messages.placeholder, {
                    column: this.formatMessage(messages.firmwareVersion).toLowerCase()
                  })}`
                }}
                hidden={!visibleColumns.firmwareVersion}
              >
                {this.formatMessage(messages.firmwareVersion)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField='Configuration'
                dataFormat={this.formatActiveConfigurationColumn}
                filter={{
                  type: 'TextFilter',
                  delay: 400,
                  placeholder: `${this.formatMessage(messages.placeholder, {
                    column: this.formatMessage(messages.deviceConfiguration).toLowerCase()
                  })}`
                }}
                hidden={!visibleColumns.deviceConfiguration}
              >
                {this.formatMessage(messages.deviceConfiguration)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField='Configuration'
                dataFormat={this.formatActiveConfigurationVersionColumn}
                hidden={!visibleColumns.deviceConfigurationVersion}
              >
                {this.formatMessage(messages.deviceConfigurationVersion)}
              </TableHeaderColumn>
            </BootstrapTable>
          </div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
        </Paper>
      </div>
    )
  }

  render() {
    const {
      alertMessages,
      alertMessagesDevicesSelection,
      alertMessagesDevicesSelectionText,
      alertMessagesDevicesSelectionTitle,
      alertMessagesDevicesSelectionType
    } = this.state
    if (alertMessages) {
      return <div className='container-fluid'>{this.renderTableError()}</div>
    } else {
      return (
        <div>
          {alertMessagesDevicesSelection ? (
            <Alert
              alertType={alertMessagesDevicesSelectionType}
              closeFunction={this.closeAlertDevicesSelectionError}
              messageText={alertMessagesDevicesSelectionText}
              messageTitle={alertMessagesDevicesSelectionTitle}
              showAlert={alertMessagesDevicesSelection}
            />
          ) : 
            ''
          }
          {this.showDevices()}
        </div>
      )
    }
  }
}

AssignDevicesToNotificationTable.propTypes = {
  fetchDeviceNonVolatileConfiguration: PropTypes.func.isRequired,
  getDevicesDetail: PropTypes.func.isRequired,
  getGroupDevices: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  notification: PropTypes.object.isRequired,
  onNotificationAssignDevicesEdited: PropTypes.func.isRequired
}

export default injectIntl(AssignDevicesToNotificationTable)
