import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'

import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'

import { DEFAULT_GROUP_ID } from 'utils/constants'

import { getGroupEditUrl } from '../urls' // FIXME coupled
import messages from './messages'


const styles = {
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid #dadde9',
    fontSize: 14
  }
}

class TreeListGroups extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {}
  }

  handleClick = event => {
    const { deleteGroup, hashId } = this.props
    event.preventDefault()
    event.stopPropagation()
    deleteGroup(hashId)
  }

  renderLastRow = () => {
    const { canEditGroup, canReadDevices, groupName, userGroup, canDeleteGroup, hashId, classes } = this.props

    const isEditableGroup = canEditGroup && canReadDevices
    const canBeDeleted = groupName !== '' && groupName !== userGroup && canDeleteGroup
    return (
      <div className='col-xs-1 text-center' style={{ whiteSpace: 'nowrap' }}>
        <span>
          <Link
            className='button-link'
            style={{ visibility: isEditableGroup ? '' : 'hidden' }}
            to={getGroupEditUrl(hashId)}
          >
            <Tooltip classes={{ tooltip: classes.tooltip }} title={this.formatMessage(messages.editGroup)}>
              <div style={{ padding: 0, display: '-webkit-inline-box' }}>
                <IconButton style={{ padding: '5px', top: '-12px' }}>
                  <EditIcon />
                </IconButton>
              </div>
            </Tooltip>
          </Link>
          {canBeDeleted && (
            <Tooltip classes={{ tooltip: classes.tooltip }} title={this.formatMessage(messages.deleteGroup)}>
              <div style={{ padding: 0, display: '-webkit-inline-box' }}>
                <IconButton onClick={this.handleClick} style={{ padding: '5px', top: '-12px' }}>
                  <DeleteIcon />
                </IconButton>
              </div>
            </Tooltip>
          )}
        </span>
      </div>
    )
  }

  render() {
    const {
      createdDate,
      lastModifiedDate,
      canEditGroup,
      canReadDevices,
      groupName,
      canDeleteGroup,
      description,
      actionsTitle,
      className,
      hashId
    } = this.props
    let creationDate
    let lastModificationDate

    if (typeof createdDate === 'string') {
      creationDate = createdDate
    } else {
      creationDate = moment(createdDate).format('DD-MMM-YYYY')
    }
    if (typeof lastModifiedDate === 'string') {
      lastModificationDate = lastModifiedDate
    } else {
      lastModificationDate = moment(lastModifiedDate).format('DD-MMM-YYYY')
    }
    if (hashId === DEFAULT_GROUP_ID) {
      creationDate = ''
      lastModificationDate = ''
    }

    return (
      <div className={className}>
        <div className='row'>
          <div
            className={canEditGroup && canReadDevices || canDeleteGroup ? 'col-xs-8' : 'col-xs-9'}
            style={{ fontWeight: hashId === DEFAULT_GROUP_ID ? 'bold' : 'normal' }}
          >
            {description}
          </div>
          <div className='col-xs-3'>
            <div className='row'>
              <div className='col-xs-6 text-right'>{creationDate}</div>
              <div className='col-xs-6 text-right'>{lastModificationDate}</div>
            </div>
          </div>

          {(canEditGroup && canReadDevices || canDeleteGroup) &&
          groupName.toUpperCase() !== 'DANFOSS DEFAULT GROUP' &&
          actionsTitle === '' ? 
            this.renderLastRow()
            : (
              <div className='col-xs-1 text-center'>
                {(canEditGroup && canReadDevices || canDeleteGroup) && <span>{actionsTitle}</span>}
              </div>
            )}
          {/* eslint-enable */}
        </div>
      </div>
    )
  }
}

TreeListGroups.propTypes = {
  actionsTitle: PropTypes.string.isRequired,
  canDeleteGroup: PropTypes.bool.isRequired,
  canEditGroup: PropTypes.bool.isRequired,
  canReadDevices: PropTypes.bool.isRequired,
  className: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  createdDate: PropTypes.string.isRequired,
  deleteGroup: PropTypes.func,
  description: PropTypes.string.isRequired,
  groupName: PropTypes.string,
  hashId: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  lastModifiedDate: PropTypes.string.isRequired,
  userGroup: PropTypes.string
}

TreeListGroups.defaultProps = {
  deleteGroup: i => i,
  groupName: '',
  userGroup: ''
}

export default withStyles(styles)(injectIntl(TreeListGroups))
