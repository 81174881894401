import { defineMessages } from 'react-intl'

const namespace = 'SelectMachine'

const messages = defineMessages({
  showingMachines: {
    id: `${namespace}.showingMachines`,
    description: 'Edit group table total text showing rows',
    defaultMessage: 'Showing machines'
  },
  to: {
    id: `${namespace}.to`,
    description: 'Edit group table total text to',
    defaultMessage: 'to'
  },
  of: {
    id: `${namespace}.of`,
    description: 'Edit group table total text of',
    defaultMessage: 'of'
  },
  prePage: {
    id: `${namespace}.prePage`,
    description: 'Previous page button text',
    defaultMessage: 'Prev'
  },
  nextPage: {
    id: `${namespace}.nextPage`,
    description: 'Next page button text',
    defaultMessage: 'Next'
  },
  firstPage: {
    id: `${namespace}.firstPage`,
    description: 'First page button text',
    defaultMessage: 'First'
  },
  lastPage: {
    id: `${namespace}.lastPage`,
    description: 'Last page button text',
    defaultMessage: 'Last'
  },
  ID: {
    id: `${namespace}.ID`,
    description: 'ID table header column label',
    defaultMessage: 'ID'
  },
  name: {
    id: `${namespace}.name`,
    description: 'Name floating label text || Name table header column label',
    defaultMessage: 'Name'
  },
  EID: {
    id: `${namespace}.EID`,
    description: 'EID table header column label',
    defaultMessage: 'EID'
  },
  createdAt: {
    id: `${namespace}.createdAt`,
    description: 'Created at floating label text',
    defaultMessage: 'Created at'
  },
  actions: {
    id: `${namespace}.actions`,
    description: 'Actions table header column label',
    defaultMessage: 'Select'
  },
  noMachinesText: {
    id: `${namespace}.noMachinesText`,
    description: 'No data',
    defaultMessage: 'No machines available'
  },
  errorRequestingMachines: {
    id: `${namespace}.errorRequestingMachines`,
    description: 'Error requesting machines',
    defaultMessage: 'Error requesting machines'
  },
  select: {
    id: `${namespace}.select`,
    description: 'Select button label',
    defaultMessage: 'SELECT'
  },
  deviceType: {
    id: `${namespace}.deviceType`,
    description: 'Device type',
    defaultMessage: 'Device type'
  },
  placeholder: {
    id: `${namespace}.placeholder`,
    description: 'Placeholder text',
    defaultMessage: 'Enter {column}'
  },
  selectPlaceholder: {
    id: `${namespace}.selectPlaceholder`,
    description: 'Select placeholder text',
    defaultMessage: 'Select {column}'
  },
  machineModel: {
    id: `${namespace}.machineModel`,
    description: 'Machine model',
    defaultMessage: 'Model'
  },
  machineType: {
    id: `${namespace}.machineType`,
    description: 'Machine type',
    defaultMessage: 'Machine type'
  },
  machineBrand: {
    id: `${namespace}.machineBrand`,
    description: 'Machine brand',
    defaultMessage: 'Brand'
  },
  machineSerialNumber: {
    id: `${namespace}.machineSerialNumber`,
    description: 'Machine serial number',
    defaultMessage: 'Machine serial number'
  },
  lastActivity: {
    id: `${namespace}.lastActivity`,
    description: 'Last activity',
    defaultMessage: 'Last activity'
  },
  firmwareVersion: {
    id: `${namespace}.firmwareVersion`,
    description: 'Firmware version',
    defaultMessage: 'Firmware version'
  },
  deviceConfiguration: {
    id: `${namespace}.deviceConfiguration`,
    description: 'Device configuration',
    defaultMessage: 'Device configuration'
  },
  deviceConfigurationVersion: {
    id: `${namespace}.deviceConfigurationVersion`,
    description: 'Device configuration version',
    defaultMessage: 'Device configuration version'
  },
  selectColumns: {
    id: `${namespace}.selectColumns`,
    description: 'Select columns',
    defaultMessage: 'Select columns'
  },
  showHideColumns: {
    id: `${namespace}.showHideColumns`,
    description: 'Show/hide columns',
    defaultMessage: 'Show/hide columns'
  },
  eid: {
    id: `${namespace}.eid`,
    description: 'Eid',
    defaultMessage: 'Eid'
  }
})

export default messages
