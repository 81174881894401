import PropTypes from 'prop-types'
import React from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

import SelectColumnsButton from 'components/SelectColumnsButton'
import { client, logError, mapDynamicDevicesFilterObject } from 'utils/http'
import { utcTimeToBrowserLocal } from 'utils/timeFormat'

import * as c from './constants'
import messages from './messages'

class AssignableMachinesTable extends React.Component {
  constructor(props) {
    super(props)
    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      assignedDevices: [],
      count: 0,
      filter: {},
      isAssignedDevicesLoading: false,
      isUnassignedDevicesLoading: false,
      newSelectedDevicesList: [],
      selectedDevices: [],
      selectedDevicesForNotificationRule: [],
      sort: {},
      start: 0,
      tableOptions: {
        noDataText: <CircularProgress />,
        sizePerPage: 5,
        page: 1
      },
      unassignedDevices: [],

      visibleColumns: {
        name: true,
        machineModel: true,
        machineType: true,
        machineBrand: false,
        machineSerialNumber: true,
        deviceType: true,
        eid: false,
        lastActivity: false,
        firmwareVersion: false,
        deviceConfiguration: true,
        deviceConfigurationVersion: false
      }
    }
  }

  componentDidMount() {
    this.getUnassignedDevices()
    this.getAssignedDevices()
  }

  getUnassignedDevices = () => {
    const { getDynamicCSNodes, ruleInstance } = this.props
    const {
      start,
      tableOptions: { sizePerPage }
    } = this.state

    if (ruleInstance) {
      const { groupId } = ruleInstance

      this.setState(
        state => ({
          isUnassignedDevicesLoading: true,
          tableOptions: {
            ...state.tableOptions,
            noDataText: <CircularProgress />
          },
          unassignedDevices: []
        }),
        () => {
          const { filter, sort } = this.state
          const mappedFilters = mapDynamicDevicesFilterObject(filter, sort)
          const deviceFields = {
            Device: [
              'id',
              'name',
              'eid',
              'operating_time',
              'device_type',
              'main_firmware_version',
              'machine_model',
              'machine_type',
              'machine_brand',
              'machine_serial_number'
            ],
            Configuration: ['name', 'version_number']
          }

          getDynamicCSNodes(groupId, sizePerPage, start, deviceFields, mappedFilters)
            .then(response => {
              const data = response.data
              if (data.count === 0) {
                this.setState(state => {
                  const { noDataText, ...otherTableOptions } = state.tableOptions
                  return {
                    count: 0,
                    isUnassignedDevicesLoading: false,
                    tableOptions: {
                      noDataText: this.formatMessage(messages.noMachinesUnassigned),
                      ...otherTableOptions
                    },
                    unassignedDevices: []
                  }
                })
              } else {
                this.setState({
                  count: response.data.total,
                  isUnassignedDevicesLoading: false,
                  unassignedDevices: data.devices
                })
              }
            })
            .catch(response => {
              const error = response.error
              let noDataText = this.formatMessage(messages.errorRequestingData)

              if (error && error.response) {
                switch (error.response.status) {
                  case 404:
                    const errorReceived =
                      typeof error.response.data === 'string' ? error.response.data : error.response.data.message
                    if (
                      errorReceived.includes('NO DEVICE IS ASSOCIATED TO THIS USER IN THIS GROUP') ||
                      errorReceived.includes('NO DEVICE EXISTS')
                    ) {
                      noDataText = this.formatMessage(messages.thereAreCurrentlyNoMachines)
                    }
                    break
                  default:
                }
              }
              this.setState(state => ({
                isUnassignedDevicesLoading: false,
                tableOptions: {
                  ...state.tableOptions,
                  noDataText
                },
                unassignedDevices: []
              }))
              logError(response)
            })
        }
      )
    }
  }

  getAssignedDevices = () => {
    const { fetchCSNodesDetails, ruleInstance, setAlert } = this.props

    if (ruleInstance) {
      const { groupId, devices: devicesAssigned } = ruleInstance
      const devicesAssignedEids = devicesAssigned.map(device => device.eid)

      if (devicesAssignedEids.length > 0) {
        this.setState(
          state => ({
            assignedDevices: [],
            isAssignedDevicesLoading: true,
            tableOptions: {
              ...state.tableOptions,
              noDataText: <CircularProgress />
            }
          }),
          () => {
            fetchCSNodesDetails(groupId, devicesAssignedEids)
              .then(response => {
                this.setState({
                  assignedDevices: response.data.devices,
                  isAssignedDevicesLoading: false
                })
              })
              .catch(response => {
                const error = response.error

                const alertMessages = true
                const alertMessagesType = 'danger'
                let alertMessagesTitle = ''
                let alertMessagesText = ['']

                if (error.response !== undefined && error.response.status !== undefined) {
                  switch (error.response.status) {
                    case 400:
                      alertMessagesTitle = this.formatMessage(messages.error, { number: 400 })
                      alertMessagesText = [this.formatMessage(messages.error400Text)]
                      break
                    case 401:
                      alertMessagesTitle = this.formatMessage(messages.error, { number: 401 })
                      alertMessagesText = [error.response.message]
                      break
                    case 403:
                      alertMessagesTitle = this.formatMessage(messages.error, { number: 403 })
                      alertMessagesText = [this.formatMessage(messages.error403Text)]
                      break
                    case 404:
                      alertMessagesTitle = this.formatMessage(messages.error, { number: 404 })
                      alertMessagesText = [this.formatMessage(messages.error404Text)]
                      break
                    case 500:
                      alertMessagesTitle = this.formatMessage(messages.error, { number: 500 })
                      alertMessagesText = [error.response.data.error_description]
                      break
                    default:
                      alertMessagesTitle = this.formatMessage(messages.errorUndefinedTitle)
                      alertMessagesText = [this.formatMessage(messages.errorUndefinedText)]
                  }

                  this.setState(state => {
                    const { noDataText, ...otherTableOptions } = state.tableOptions
                    return {
                      assignedDevices: [],
                      isAssignedDevicesLoading: false,
                      tableOptions: {
                        noDataText: this.formatMessage(messages.noMachinesAssigned),
                        ...otherTableOptions
                      }
                    }
                  })
                  setAlert(alertMessages, alertMessagesType, alertMessagesTitle, alertMessagesText)
                } else {
                  this.setState(state => {
                    const { noDataText, ...otherTableOptions } = state.tableOptions
                    return {
                      assignedDevices: [],
                      isAssignedDevicesLoading: false,
                      tableOptions: {
                        noDataText: this.formatMessage(messages.noMachinesAssigned),
                        ...otherTableOptions
                      }
                    }
                  })
                  alertMessagesTitle = this.formatMessage(messages.errorUndefinedTitle)
                  alertMessagesText = [this.formatMessage(messages.errorUndefinedText)]
                  setAlert(alertMessages, alertMessagesType, alertMessagesTitle, alertMessagesText)
                }
              })
          }
        )
      }
    }
  }

  handleClearSelectedDevices = () => {
    this.setState({
      selectedDevices: [],
      selectedDevicesForNotificationRule: [],
      newSelectedDevicesList: []
    })
  }

  handleChangeColumnVisibility = columnId => {
    this.setState(state => {
      const updatedColumnVisibility = !state.visibleColumns[columnId]
      return {
        visibleColumns: {
          ...state.visibleColumns,
          [columnId]: updatedColumnVisibility
        }
      }
    })
  }

  formatLastActivity = operatingTime => {
    return utcTimeToBrowserLocal(operatingTime.lastDateTime)
  }

  formatMachineField = cell => {
    return cell ? cell : '-'
  }

  formatActiveConfigurationColumn = cell => {
    return cell?.name ? cell.name : '-'
  }

  formatActiveConfigurationVersionColumn = cell => {
    return cell?.version_number ? cell.version_number : '-'
  }

  onPageChange = (page, sizePerPage) => {
    this.setState(state => {
      const { page: previousPage, sizePerPage: previousSizePage, ...otherTableOptions } = state.tableOptions
      return {
        start: (page - 1) * sizePerPage,
        tableOptions: {
          page,
          sizePerPage,
          ...otherTableOptions
        }
      }
    }, this.getUnassignedDevices)
  }

  onSizePerPageList = sizePerPage => {
    this.setState(state => {
      const { sizePerPage: previousSizePerPage, ...otherTableOptions } = state.tableOptions
      return {
        tableOptions: { sizePerPage, ...otherTableOptions }
      }
    }, this.getUnassignedDevices)
  }

  getTableOptions = () => {
    const { tableOptions } = this.state
    const completeTableOptions = {
      ...tableOptions,
      alwaysShowAllBtns: false,
      firstPage: this.formatMessage(messages.firstPage),
      hideSizePerPage: false,
      ignoreSinglePage: false,
      lastPage: this.formatMessage(messages.lastPage),
      nextPage: this.formatMessage(messages.nextPage),
      onFilterChange: this.onFilterChange,
      onPageChange: this.onPageChange,
      onSizePerPageList: this.onSizePerPageList,
      onSortChange: this.onSortChange,
      pageStartIndex: 1,
      paginationPosition: 'bottom',
      paginationShowsTotal: this.renderPaginationShowsTotal(this.formatMessage),
      paginationSize: 5,
      prePage: this.formatMessage(messages.prePage),
      sizePerPageList: [
        {
          text: '5',
          value: 5
        },
        {
          text: '50',
          value: 50
        },
        {
          text: '100',
          value: 100
        },
        {
          text: '200',
          value: 200
        }
      ],
      withFirstAndLast: true
    }

    return completeTableOptions
  }

  onRowSelect = (row, isSelected) => {
    const { selectedDevices, selectedDevicesForNotificationRule } = this.state

    const element = row
    const elementId = row.id

    const currentSelectedDevices = selectedDevices.map(device => device)
    const currentSelectedDevicesForNotificationRule = selectedDevicesForNotificationRule.map(device => device)
    const indexOfDevice = selectedDevices.indexOf(elementId)

    if (isSelected) {
      if (indexOfDevice < 0) {
        currentSelectedDevices.push(elementId)
        currentSelectedDevicesForNotificationRule.push(element)
      }
    } else {
      if (indexOfDevice > -1) {
        currentSelectedDevices.splice(indexOfDevice, 1)
        currentSelectedDevicesForNotificationRule.splice(indexOfDevice, 1)
      }
    }

    this.setState({
      selectedDevices: currentSelectedDevices,
      newSelectedDevicesList: currentSelectedDevicesForNotificationRule.map(device => {
        return {
          eid: device.eid,
          deviceName: device.name,
          deviceType: device.device_type
        }
      }),
      selectedDevicesForNotificationRule: currentSelectedDevicesForNotificationRule
    })
  }

  onSelectAll = (isSelected, rows) => {
    const { selectedDevices, selectedDevicesForNotificationRule } = this.state
    let indexOfDevice

    const currentSelectedDevices = selectedDevices.map(device => device)
    const currentSelectedDevicesForNotificationRule = selectedDevicesForNotificationRule.map(device => device)

    const devicesToProcess = rows.map(device => {
      return device
    })

    devicesToProcess.map(device => {
      indexOfDevice = currentSelectedDevices.indexOf(device.id)

      if (isSelected) {
        if (indexOfDevice < 0) {
          currentSelectedDevices.push(device.id)
          currentSelectedDevicesForNotificationRule.push(device)
        }
      } else {
        if (indexOfDevice > -1) {
          currentSelectedDevices.splice(indexOfDevice, 1)
          currentSelectedDevicesForNotificationRule.splice(indexOfDevice, 1)
        }
      }

      return device
    })

    this.setState({
      selectedDevices: currentSelectedDevices,
      newSelectedDevicesList: currentSelectedDevicesForNotificationRule.map(device => {
        return {
          eid: device.eid,
          deviceName: device.name,
          deviceType: device.device_type
        }
      }),
      selectedDevicesForNotificationRule: currentSelectedDevicesForNotificationRule
    })
  }

  onFilterChange = filterObj => {
    this.setState(
      state => ({
        filter: filterObj,
        start: 0,
        tableOptions: {
          ...state.tableOptions,
          page: 1
        }
      }),
      () => {
        this.getUnassignedDevices()
      }
    )
  }

  onSortChange = (sortName, sortOrder) => {
    if (sortName.includes('.') || sortName === 'operating_time') {
      this.setState({
        sort: {}
      })
    } else {
      this.setState(
        {
          sort: {
            sortColumnKey: sortName,
            sortColumnModel: 'Device',
            sortColumnOrder: sortOrder.toUpperCase()
          }
        },
        this.getUnassignedDevices
      )
    }
  }

  handleAssignDevices = () => {
    const { ruleInstance, changeTableMode, fetchRuleInstance, setAlert } = this.props
    const { newSelectedDevicesList } = this.state
    const devicesToAssign = newSelectedDevicesList

    let alertMessages = false
    let alertMessagesType = ''
    let alertMessagesTitle = ''
    let alertMessagesText = ['']

    client
      .assignDevicesToRuleInstance(ruleInstance.hashId, devicesToAssign)
      .then(response => {
        alertMessages = true
        alertMessagesType = 'success'
        alertMessagesTitle = ''
        alertMessagesText = [this.formatMessage(messages.machinesAassignedCorrectly)]
        setAlert(alertMessages, alertMessagesType, alertMessagesTitle, alertMessagesText)

        fetchRuleInstance()
        changeTableMode(c.VIEW_ASSIGNED_MACHINES_MODE)
      })
      .catch(response => {
        const error = { ...response }
        let error401ToShow = ''
        let error500ToShow = ''

        alertMessages = true
        alertMessagesType = 'danger'

        switch (error.response.status) {
          case 400:
            alertMessagesTitle = this.formatMessage(messages.error, { number: 400 })
            alertMessagesText = [this.formatMessage(messages.error400Text)]
            break
          case 401:
            if (error.response.message !== undefined) {
              error401ToShow = error.response.message
            }
            alertMessagesTitle = this.formatMessage(messages.error, { number: 401 })
            alertMessagesText = [error401ToShow]
            break
          case 403:
            alertMessagesTitle = this.formatMessage(messages.error, { number: 403 })
            alertMessagesText = [this.formatMessage(messages.error403Text)]
            break
          case 404:
            alertMessagesTitle = this.formatMessage(messages.error, { number: 404 })
            alertMessagesText = [this.formatMessage(messages.error404Text)]
            break
          case 406:
            alertMessagesTitle = this.formatMessage(messages.error, { number: 406 })
            alertMessagesText = [this.formatMessage(messages.error406Text)]
            break
          case 409:
            alertMessagesTitle = this.formatMessage(messages.error, { number: 409 })
            alertMessagesText = [this.formatMessage(messages.error409Text)]
            break
          case 422:
            alertMessagesTitle = this.formatMessage(messages.error, { number: 422 })
            alertMessagesText = [this.formatMessage(messages.error422Text)]
            break
          case 500:
            error500ToShow = this.formatMessage(messages.error500ToSHow)
            if (
              error.response.message.data !== undefined &&
              error.response.message.data.error_description !== undefined
            ) {
              error500ToShow = error.response.data.error_description
            }
            alertMessagesTitle = this.formatMessage(messages.error, { number: 500 })
            alertMessagesText = [error500ToShow]
            break
          default:
            alertMessagesTitle = this.formatMessage(messages.errorUndefinedTitle)
            alertMessagesText = [this.formatMessage(messages.errorUndefinedText)]
            logError(response)
        }
        setAlert(alertMessages, alertMessagesType, alertMessagesTitle, alertMessagesText)
      })
  }

  renderPaginationShowsTotal = formatMessage => (start, to, total) =>
    (
      <span>
        {`${formatMessage(messages.showingRows)} ${start} ${formatMessage(messages.to)} ${to} ${formatMessage(
          messages.of
        )} ${total}`}
      </span>
    )

  render() {
    const { tableHeight } = this.props
    const {
      assignedDevices,
      count,
      isAssignedDevicesLoading,
      isUnassignedDevicesLoading,
      selectedDevices,
      unassignedDevices,
      visibleColumns
    } = this.state

    const isLoading = isAssignedDevicesLoading || isUnassignedDevicesLoading

    const currentAssignedDeviceIds = assignedDevices.map(device => device.id)

    const tableOptions = this.getTableOptions()

    const data = isLoading ? [] : unassignedDevices

    const selectRowProp = {
      mode: 'checkbox',
      clickToSelect: false,
      onSelect: this.onRowSelect,
      onSelectAll: this.onSelectAll,
      bgColor: '#f5f5f5',
      selected: selectedDevices,
      unselectable: currentAssignedDeviceIds
    }

    const deviceTypeFilterOptions = {
      CS100: 'CS100',
      CS500: 'CS500',
      CS10: 'CS10'
    }

    return (
      <div className='col-md-12' style={{ height: tableHeight, padding: 0 }}>
        <Paper style={{ height: '100%', borderRadius: 0 }}>
          <Grid container style={{ height: '100%' }} wrap='wrap'>
            <Grid container item spacing={2} style={{ paddingTop: 12, paddingLeft: 10 }} xs={12}>
              <Grid container item spacing={1} xs={9}>
                <Grid item>
                  <Button disabled>
                    {this.formatMessage(messages.selectedMachines)} ({selectedDevices.length})
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    className='secondary-action-button'
                    disabled={selectedDevices.length === 0}
                    onClick={this.handleClearSelectedDevices}
                  >
                    {this.formatMessage(messages.clearSelection)}
                  </Button>
                </Grid>
                <Grid item>
                  <SelectColumnsButton
                    columns={visibleColumns}
                    onChangeColumnVisibility={this.handleChangeColumnVisibility}
                  />
                </Grid>
                <Grid item>
                  <span>{this.formatMessage(messages.machineConditionTextNoSelectableMachines)}</span>
                </Grid>
              </Grid>
              <Grid alignContent='flex-start' container item justifyContent='flex-end' xs={3}>
                <Button
                  className='primary-action-button'
                  disabled={selectedDevices.length === 0}
                  onClick={this.handleAssignDevices}
                >
                  {this.formatMessage(messages.assignMachines)}
                </Button>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <div className='table-with-pagination' style={{ height: '85%', width: '100%' }}>
                <BootstrapTable
                  bodyStyle={{ height: '65%' }}
                  bordered={false}
                  condensed={false}
                  containerStyle={{ height: '100%' }}
                  data={data}
                  exportCSV={false}
                  fetchInfo={{ dataTotalSize: count }}
                  hover
                  keyField='id'
                  multiColumnSearch={false}
                  options={tableOptions}
                  pagination
                  remote={remoteObj => ({
                    ...remoteObj,
                    search: false,
                    pagination: true,
                    sizePerPage: true,
                    sort: false,
                    filter: true
                  })}
                  search={false}
                  searchPlaceholder={this.formatMessage(messages.searchPlaceholder)}
                  selectRow={selectRowProp}
                  striped={false}
                  tableStyle={{ height: '80%' }}
                >
                  <TableHeaderColumn dataField='id' hidden />
                  <TableHeaderColumn
                    dataField='name'
                    dataSort
                    filter={{
                      type: 'TextFilter',
                      delay: 400,
                      placeholder: `${this.formatMessage(messages.placeholder, {
                        column: this.formatMessage(messages.name).toLowerCase()
                      })}`
                    }}
                    hidden={!visibleColumns.name}
                  >
                    {this.formatMessage(messages.name)}
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField='machine_model'
                    dataFormat={this.formatMachineField}
                    dataSort
                    filter={{
                      type: 'TextFilter',
                      delay: 400,
                      placeholder: `${this.formatMessage(messages.placeholder, {
                        column: this.formatMessage(messages.machineModel).toLowerCase()
                      })}`
                    }}
                    hidden={!visibleColumns.machineModel}
                  >
                    {this.formatMessage(messages.machineModel)}
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField='machine_type'
                    dataFormat={this.formatMachineField}
                    dataSort
                    filter={{
                      type: 'TextFilter',
                      delay: 400,
                      placeholder: `${this.formatMessage(messages.placeholder, {
                        column: this.formatMessage(messages.machineType).toLowerCase()
                      })}`
                    }}
                    hidden={!visibleColumns.machineType}
                  >
                    {this.formatMessage(messages.machineType)}
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField='machine_brand'
                    dataFormat={this.formatMachineField}
                    dataSort
                    filter={{
                      type: 'TextFilter',
                      delay: 400,
                      placeholder: `${this.formatMessage(messages.placeholder, {
                        column: this.formatMessage(messages.machineBrand).toLowerCase()
                      })}`
                    }}
                    hidden={!visibleColumns.machineBrand}
                  >
                    {this.formatMessage(messages.machineBrand)}
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField='machine_serial_number'
                    dataFormat={this.formatMachineField}
                    dataSort
                    filter={{
                      type: 'TextFilter',
                      delay: 400,
                      placeholder: `${this.formatMessage(messages.placeholder, {
                        column: this.formatMessage(messages.machineSerialNumber).toLowerCase()
                      })}`
                    }}
                    hidden={!visibleColumns.machineSerialNumber}
                  >
                    {this.formatMessage(messages.machineSerialNumber)}
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField='device_type'
                    dataSort
                    filter={{
                      type: 'SelectFilter',
                      delay: 400,
                      options: deviceTypeFilterOptions,
                      placeholder: `${this.formatMessage(messages.placeholder, {
                        column: this.formatMessage(messages.deviceType).toLowerCase()
                      })}`
                    }}
                    hidden={!visibleColumns.deviceType}
                  >
                    {this.formatMessage(messages.deviceType)}
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField='eid'
                    dataSort
                    filter={{
                      type: 'TextFilter',
                      delay: 400,
                      placeholder: `${this.formatMessage(messages.placeholder, {
                        column: this.formatMessage(messages.eid).toLowerCase()
                      })}`
                    }}
                    hidden={!visibleColumns.eid}
                  >
                    {this.formatMessage(messages.eid)}
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField='operating_time'
                    dataFormat={this.formatLastActivity}
                    hidden={!visibleColumns.lastActivity}
                  >
                    {this.formatMessage(messages.lastActivity)}
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField='main_firmware_version'
                    dataSort
                    filter={{
                      type: 'TextFilter',
                      delay: 400,
                      placeholder: `${this.formatMessage(messages.placeholder, {
                        column: this.formatMessage(messages.firmwareVersion).toLowerCase()
                      })}`
                    }}
                    hidden={!visibleColumns.firmwareVersion}
                  >
                    {this.formatMessage(messages.firmwareVersion)}
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField='Configuration'
                    dataFormat={this.formatActiveConfigurationColumn}
                    filter={{
                      type: 'TextFilter',
                      delay: 400,
                      placeholder: `${this.formatMessage(messages.placeholder, {
                        column: this.formatMessage(messages.deviceConfiguration).toLowerCase()
                      })}`
                    }}
                    hidden={!visibleColumns.deviceConfiguration}
                  >
                    {this.formatMessage(messages.deviceConfiguration)}
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField='Configuration'
                    dataFormat={this.formatActiveConfigurationVersionColumn}
                    hidden={!visibleColumns.deviceConfigurationVersion}
                  >
                    {this.formatMessage(messages.deviceConfigurationVersion)}
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </div>
    )
  }
}

AssignableMachinesTable.propTypes = {
  changeTableMode: PropTypes.func.isRequired,
  fetchCSNodesDetails: PropTypes.func.isRequired,
  fetchRuleInstance: PropTypes.func.isRequired,
  getDynamicCSNodes: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  ruleInstance: PropTypes.object,
  setAlert: PropTypes.func.isRequired,
  tableHeight: PropTypes.string.isRequired
}

AssignableMachinesTable.defaultProps = {
  ruleInstance: null
}

export default injectIntl(AssignableMachinesTable)
