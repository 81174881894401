import { defineMessages } from 'react-intl'

const namespace = 'machineDetails'

const messages = defineMessages({
  error400Message: {
    id: `${namespace}.error400Message`,
    description: 'Error 400 message',
    defaultMessage: 'Bad request'
  },
  error403Message: {
    id: `${namespace}.error403Message`,
    description: 'Error 403 message',
    defaultMessage: 'Access denied'
  },
  error404Message: {
    id: `${namespace}.error404Message`,
    description: 'Error 404 message',
    defaultMessage: 'API url not found.'
  },
  error406Message: {
    id: `${namespace}.error406Message`,
    description: 'Error 406 message',
    defaultMessage: 'Not acceptable'
  },
  errorUndefinedTitle: {
    id: `${namespace}.errorUndefinedTitle`,
    description: 'Error undefined title',
    defaultMessage: 'Error undefined'
  },
  errorUndefinedMessage: {
    id: `${namespace}.errorUndefinedMessage`,
    description: 'Error undefined message',
    defaultMessage: 'No description available.'
  },
  error409Message: {
    id: `${namespace}.error409Message`,
    description: 'Error 409 message',
    defaultMessage: 'Data integrity violation: Configuration name it`s already in use'
  },
  error415Message: {
    id: `${namespace}.error415Message`,
    description: 'Error 415 message',
    defaultMessage: 'Unsupported media type'
  },
  error422Message: {
    id: `${namespace}.error422Message`,
    description: 'Error 422 message',
    defaultMessage: 'Validation failed'
  },
  error500Message: {
    id: `${namespace}.error500Message`,
    description: 'Error 500 message',
    defaultMessage: 'Validation failed'
  },
  error: {
    id: `${namespace}.error`,
    description: 'error text',
    defaultMessage: 'Error {number}'
  },
  error401Message: {
    id: `${namespace}.error401Message`,
    description: 'Error 401 message',
    defaultMessage: 'Invalid credentials'
  },
  return: {
    id: `${namespace}.return`,
    description: 'Return',
    defaultMessage: 'Return'
  },
  nodeInformation: {
    id: `${namespace}.nodeInformation`,
    description: 'Device information',
    defaultMessage: 'Device information'
  },
  activityInformation: {
    id: `${namespace}.activityInformation`,
    description: 'Activity information',
    defaultMessage: 'Activity information'
  },
  dataPlanInformation: {
    id: `${namespace}.dataPlanInformation`,
    description: 'Data plan information',
    defaultMessage: 'Data plan information'
  },
  signals: {
    id: `${namespace}.signals`,
    description: 'Signals',
    defaultMessage: 'Signals'
  },
  configuration: {
    id: `${namespace}.configuration`,
    description: 'Configuration',
    defaultMessage: 'Configuration'
  },
  lastMessageDate: {
    id: `${namespace}.lastMessageDate`,
    description: 'Last message date',
    defaultMessage: 'Last message date'
  },
  deviceFirmwareLabel: {
    id: `${namespace}.deviceFirmwareLabel`,
    description: 'Device firmware',
    defaultMessage: 'Device firmware'
  },
  radioFirmwareLabel: {
    id: `${namespace}.radioFirmwareLabel`,
    description: 'Radio firmware',
    defaultMessage: 'Radio firmware'
  },
  name: {
    id: `${namespace}.name`,
    description: 'Name',
    defaultMessage: 'Name'
  },
  value: {
    id: `${namespace}.value`,
    description: 'Value',
    defaultMessage: 'Value'
  },
  lastUpdated: {
    id: `${namespace}.lastUpdated`,
    description: 'Last updated',
    defaultMessage: 'Last updated'
  },
  firstActivityTime: {
    id: `${namespace}.firstActivityTime`,
    description: 'First activity time',
    defaultMessage: 'First activity time'
  },
  lastActivityTime: {
    id: `${namespace}.lastActivityTime`,
    description: 'Last activity time',
    defaultMessage: 'Last activity time'
  },
  totalActivityTime: {
    id: `${namespace}.totalActivityTime`,
    description: 'Total activity time',
    defaultMessage: 'Total activity time'
  },
  deviceStatus: {
    id: `${namespace}.deviceStatus`,
    description: 'Device status',
    defaultMessage: 'Device status'
  },
  // TODO: @nayestaran meter esto en i18n
  crcErrors: {
    id: `${namespace}.crcErrors`,
    description: 'CRC Errors',
    defaultMessage: 'CRC Errors'
  },
  category: {
    id: `${namespace}.category`,
    description: 'Category',
    defaultMessage: 'Category'
  },
  description: {
    id: `${namespace}.description`,
    description: 'Error Description',
    defaultMessage: 'Error Description'
  },
  date: {
    id: `${namespace}.date`,
    description: 'Date',
    defaultMessage: 'Date'
  },
  dm1: {
    id: `${namespace}.dm1`,
    description: 'DM1 Messages',
    defaultMessage: 'DM1 Messages'
  },
  spn: {
    id: `${namespace}.spn`,
    description: 'SPN',
    defaultMessage: 'SPN'
  },
  fmi: {
    id: `${namespace}.fmi`,
    description: 'FMI',
    defaultMessage: 'FMI'
  },
  address: {
    id: `${namespace}.address`,
    description: 'Address',
    defaultMessage: 'Address'
  },
  oc: {
    id: `${namespace}.oc`,
    description: 'OC',
    defaultMessage: 'OC'
  },
  noDM1MessagesAvailable: {
    id: `${namespace}.noDM1MessagesAvailable`,
    description: 'No DM1 messages available warning text',
    defaultMessage: 'No DM1 messages available'
  },
  noCRCMessagesAvailable: {
    id: `${namespace}.noCRCMessagesAvailable`,
    description: 'No CRC error messages available warning text',
    defaultMessage: 'No CRC error messages available'
  },
  prePage: {
    id: `${namespace}.prePage`,
    description: 'Node details previous page button text',
    defaultMessage: 'Prev'
  },
  nextPage: {
    id: `${namespace}.nextPage`,
    description: 'Node details next page button text',
    defaultMessage: 'Next'
  },
  firstPage: {
    id: `${namespace}.firstPage`,
    description: 'Node details first page button text',
    defaultMessage: 'First'
  },
  lastPage: {
    id: `${namespace}.lastPage`,
    description: 'Node details last page button text',
    defaultMessage: 'Last'
  },
  configurationOfAdvancedSignals: {
    id: `${namespace}.configurationOfAdvancedSignals`,
    description: 'Configuration of advanced signals',
    defaultMessage: 'Configuration of advanced signals'
  },
  configureYourSignals: {
    id: `${namespace}.configureYourSignals`,
    description: 'Configure your signals',
    defaultMessage: 'Configure your signals'
  },
  openConfiguration: {
    id: `${namespace}.openConfiguration`,
    description: 'Open configuration',
    defaultMessage: 'Open configuration'
  },
  firmware: {
    id: `${namespace}.firmware`,
    description: 'Firmware',
    defaultMessage: 'Firmware'
  },
  remoteFirmwareUpdateStatus: {
    id: `${namespace}.remoteFirmwareUpdateStatus`,
    description: 'Remote firmware update status',
    defaultMessage: 'Remote firmware update status'
  },
  requestedStep: {
    id: `${namespace}.requestedStep`,
    description: 'Requested',
    defaultMessage: 'Requested'
  },
  downloadingStep: {
    id: `${namespace}.downloadingStep`,
    description: 'Downloading',
    defaultMessage: 'Downloading'
  },
  manualRebootRequiredStep: {
    id: `${namespace}.manualRebootRequiredStep`,
    description: 'Manual reboot required',
    defaultMessage: 'Manual reboot required'
  },
  installingStep: {
    id: `${namespace}.installingStep`,
    description: 'Installing',
    defaultMessage: 'Installing'
  },
  completedStep: {
    id: `${namespace}.completedStep`,
    description: 'Completed',
    defaultMessage: 'Completed'
  },
  notAttempted: {
    id: `${namespace}.notAttempted`,
    description: 'Not attempted',
    defaultMessage: 'Not attempted'
  },
  failedStep: {
    id: `${namespace}.failedFirmware`,
    description: 'firmware update failed ',
    defaultMessage: 'Failed'
  },
  deviceTypeLabel: {
    id: `${namespace}.deviceTypeLabel`,
    description: 'Device type',
    defaultMessage: 'Device type'
  },
  machineInformation: {
    id: `${namespace}.machineInformation`,
    description: 'Machine information',
    defaultMessage: 'Machine information'
  },
  model: {
    id: `${namespace}.model`,
    description: 'Model',
    defaultMessage: 'Model'
  },
  machineType: {
    id: `${namespace}.machineType`,
    description: 'Machine type',
    defaultMessage: 'Machine type'
  },
  brand: {
    id: `${namespace}.brand`,
    description: 'Brand',
    defaultMessage: 'Brand'
  },
  machineSerialNumber: {
    id: `${namespace}.machineSerialNumber`,
    description: 'Machine serial number',
    defaultMessage: 'Machine serial number'
  },
  title: {
    id: `${namespace}.title`,
    description: 'Machine details',
    defaultMessage: 'Machine details'
  }
})

export default messages
