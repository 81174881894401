import React from 'react'

import { useAuth0 } from '../../../react-auth0-spa'

const SSOSignUpButton = (props) => {
  const { loginWithRedirect } = useAuth0()
  return (    
    <button className={props.classValue} onClick={() => {
      loginWithRedirect({signup:true})    
    }}
    >{props.buttonText}
    </button>
  )  
}

export default SSOSignUpButton