import * as c from './constants'

export const getOriginalConfiguration = (state, eid) => state[c.NAME][eid][c.ORIGINAL_CONFIGURATION]
export const getConfiguration = (state, eid) => state[c.NAME][eid][c.PARSED_CONFIGURATION]
export const getAllDevicesConfigurations = state => {
  const config = {}
  Object.entries(state[c.NAME]).forEach(([key, value]) => {
    config[key] = value[c.PARSED_CONFIGURATION]
  })

  return config
}
