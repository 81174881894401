import React from 'react'
import PropTypes from 'prop-types'

import Card from '@material-ui/core/Card'
import Button from '@material-ui/core/Button'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'

import { withStyles } from '@material-ui/core/styles'

import { useAuth0 } from 'react-auth0-spa'

import { injectIntl } from 'react-intl'
import messages from './messages'
import PortalLoading from 'components/PortalLoading'

const styles = {
  title: {
    fontSize: '35px'
  },
  subheader: {
    fontSize: '20px'
  }
}

const ProfileLoader = ({
  isProfileLoaded,
  isProfileBeingLoaded,
  userState,
  permissions,
  unauthorize,
  children,
  intl,
  classes
}) => {
  const { formatMessage } = intl
  const { logout } = useAuth0()

  if (userState === 'expired') {
    logout({ returnTo: process.env.REACT_APP_DIP_REDIRECT_LOGOUT_URI })
  }
  if (userState === 'failed') {
    return (
      <div style={{ position: 'absolute', top: 0, right: 0, left: 0, bottom: 0 }}>
        <span
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            width: 200,
            margin: '-10px 0 0 -100px',
            textAlign: 'center'
          }}
        />
        <div>
          <MuiThemeProvider>
            <Card>
              <CardHeader
                classes={{ title: classes.title, subheader: classes.subheader }}
                subheader={formatMessage(messages.subtitle)}
                title={formatMessage(messages.title)}
              />
              <CardContent style={{ textAlign: 'center' }}>
                <Button
                  className='primary-action-button'
                  onClick={() => {
                    unauthorize()
                    logout({ returnTo: process.env.REACT_APP_DIP_REDIRECT_LOGOUT_URI })
                  }}
                >
                  {formatMessage(messages.return)}
                </Button>
              </CardContent>
            </Card>
          </MuiThemeProvider>
        </div>
      </div>
    )
  } else if (userState === 'not verified') {
    return (
      <div style={{ position: 'absolute', top: 0, right: 0, left: 0, bottom: 0 }}>
        <span
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            width: 200,
            margin: '-10px 0 0 -100px',
            textAlign: 'center'
          }}
        />
        <div>
          <MuiThemeProvider>
            <Card>
              <CardHeader classes={{ title: classes.title }} title={formatMessage(messages.notVerified)} />
              <CardContent style={{ textAlign: 'center' }}>
                <Button
                  className='primary-action-button'
                  onClick={() => {
                    unauthorize()
                    logout({ returnTo: process.env.REACT_APP_DIP_REDIRECT_LOGOUT_URI })
                  }}
                >
                  {formatMessage(messages.return)}
                </Button>
              </CardContent>
            </Card>
          </MuiThemeProvider>
        </div>
      </div>
    )
  } else if (!isProfileLoaded || isProfileBeingLoaded || userState === 'loading' || permissions === '') {
    return <PortalLoading />
  }
  return children
}

ProfileLoader.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  isProfileBeingLoaded: PropTypes.bool.isRequired,
  isProfileLoaded: PropTypes.bool.isRequired,
  permissions: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  unauthorize: PropTypes.func.isRequired,
  userState: PropTypes.string.isRequired
}

export default withStyles(styles)(injectIntl(ProfileLoader))
