import React from 'react'

import { validationSchemaFromPC, transformValuesFromPCToAPI } from '../config'

import { getEstimatedDataConsumption } from '../DataConsumptionEstimation/variables'

import ReactUploadFile from 'utils/fileHandler/ReactUploadFile'

import Input from '@material-ui/core/Input'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import CloseIcon from '@material-ui/icons/Close'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import InputAdornment from '@material-ui/core/InputAdornment'
import CircularProgress from '@material-ui/core/CircularProgress'
import DialogContentText from '@material-ui/core/DialogContentText'

import { withStyles } from '@material-ui/core/styles'

import { injectIntl } from 'react-intl'
import messages from './messages'

import { logError } from 'utils/http'

const styles = {
  dialog: {
    minWidth: 600
  }
}

class ConfigurationImportFromPC extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      loaded: false,
      isValidFile: false,
      configFileToImport: null,
      loadedFileName: '',
      configurationName: '',
      savingConfiguration: false,
      saveConfigurationDisabled: true,

      rawConfig: null,
      hasAllowedSize: false,
      machineUsagePercentage: 25,
      headersStackOverhead: 1,
      dataConsumptionEstimation: 0,

      alertVisibility: 'hidden',
      alertMessageType: 'success',
      alertMessageTitle: '',
      alertMessageText: ''
    }
  }

  componentDidUpdate = prevProps => {
    if (prevProps.dialogOpen !== this.props.dialogOpen) {
      this.deleteData()
    }
  }

  closeAlert = () => {
    this.setState({
      alertVisibility: 'hidden',
      alertMessageTitle: '',
      alertMessageText: ''
    })
  }

  handleChangeConfigurationName = event => {
    const configurationName = event.target.value
    const saveConfigurationDisabled = event.target.value.trim().length === 0 || event.target.value.length === 0
    const isValidFile = this.state.isValidFile
    const hasAllowedSize = this.state.hasAllowedSize

    this.setState({
      configurationName,
      saveConfigurationDisabled: saveConfigurationDisabled || !isValidFile || !hasAllowedSize
    })
  }

  handleMachineUsagePercentageChange = percentage => {
    const Mu = percentage / 100
    const Hso = this.state.headersStackOverhead
    const config = this.state.rawConfig
    const estimatedDataConsumption = getEstimatedDataConsumption(config, Mu, Hso)

    const isValidFile = this.state.isValidFile
    const hasAllowedSize = estimatedDataConsumption <= 25 ? true : false
    const saveConfigurationDisabled = !isValidFile || !hasAllowedSize

    this.setState({
      machineUsagePercentage: percentage,
      dataConsumptionEstimation: estimatedDataConsumption,
      hasAllowedSize,
      saveConfigurationDisabled,
      alertVisibility: '',
      alertMessageType: saveConfigurationDisabled ? 'danger' : 'success',
      alertMessageTitle: saveConfigurationDisabled
        ? this.formatMessage(messages.invalidConfigFile)
        : this.formatMessage(messages.validConfigFile),
      alertMessageText: saveConfigurationDisabled ? this.formatMessage(messages.invalidConfigSizeMessage) : ''
    })
  }

  deleteData = () => {
    this.setState({
      loaded: false,
      isValidFile: false,
      configFileToImport: null,
      loadedFileName: '',
      configurationName: '',
      savingConfiguration: false,
      saveConfigurationDisabled: true,
      rawConfig: null,
      hasAllowedSize: false,
      machineUsagePercentage: 25,
      headersStackOverhead: 1,
      dataConsumptionEstimation: 0,
      alertVisibility: 'hidden',
      alertMessageType: 'success',
      alertMessageTitle: '',
      alertMessageText: ''
    })
  }

  isJSON = data => {
    try {
      JSON.parse(data)
    } catch (e) {
      return false
    }
    return true
  }

  saveConfiguration = () => {
    const configToSave = Object.assign(this.state.configFileToImport, {
      name: this.state.configurationName
    })
    this.setState({
      savingConfiguration: true
    })
    this.props
      .saveConfiguration(this.props.groupId, configToSave)
      .then(() => {
        this.deleteData()
        this.props.handleDialogClose()
        this.props.handleUpdateTable(true)
        this.props.changeAlertState({
          alertVisibility: '',
          alertMessageType: 'success',
          alertMessageText: this.formatMessage(messages.configurationCreatedSuccessfully)
        })
      })
      .catch(response => {
        const { error } = { ...response }
        if (error.response) {
          const message = error.response.data ? ': ' + error.response.data : ''
          switch (error.response.status) {
            case 400:
              this.deleteData()
              this.setState({
                alertVisibility: '',
                alertMessageType: 'danger',
                alertMessageTitle: this.formatMessage(messages.error, { number: '400' }),
                alertMessageText: this.formatMessage(messages.error400Message) + message
              })
              break
            case 401:
              this.deleteData()
              this.setState({
                alertVisibility: '',
                alertMessageType: 'danger',
                alertMessageTitle: this.formatMessage(messages.error, { number: '401' }),
                alertMessageText: this.formatMessage(messages.error401Message) + message
              })
              break
            case 403:
              this.deleteData()
              this.setState({
                alertVisibility: '',
                alertMessageType: 'danger',
                alertMessageTitle: this.formatMessage(messages.error, { number: '403' }),
                alertMessageText: this.formatMessage(messages.error403Message) + message
              })
              break
            case 404:
              this.deleteData()
              this.setState({
                alertVisibility: '',
                alertMessageType: 'danger',
                alertMessageTitle: this.formatMessage(messages.error, { number: '404' }),
                alertMessageText: this.formatMessage(messages.error404Message)
              })
              break
            case 406:
              this.deleteData()
              this.setState({
                alertVisibility: '',
                alertMessageType: 'danger',
                alertMessageTitle: this.formatMessage(messages.error, { number: '406' }),
                alertMessageText: this.formatMessage(messages.error406Message) + message
              })
              break
            case 409:
              this.deleteData()
              this.setState({
                alertVisibility: '',
                alertMessageType: 'danger',
                alertMessageTitle: this.formatMessage(messages.error, { number: '409' }),
                alertMessageText: this.formatMessage(messages.error409Message) + message
              })
              break
            case 415:
              this.deleteData()
              this.setState({
                alertVisibility: '',
                alertMessageType: 'danger',
                alertMessageTitle: this.formatMessage(messages.error, { number: '415' }),
                alertMessageText: this.formatMessage(messages.error415Message) + message
              })
              break
            case 422:
              this.deleteData()
              this.setState({
                alertVisibility: '',
                alertMessageType: 'danger',
                alertMessageTitle: this.formatMessage(messages.error, { number: '422' }),
                alertMessageText: this.formatMessage(messages.error422Message) + message
              })
              break
            case 500:
              this.deleteData()
              this.setState({
                alertVisibility: '',
                alertMessageType: 'danger',
                alertMessageTitle: this.formatMessage(messages.error, { number: '500' }),
                alertMessageText: this.formatMessage(messages.error500Message) + message
              })
              break
            default:
              this.deleteData()
              this.setState({
                alertVisibility: '',
                alertMessageType: 'danger',
                alertMessageTitle: this.formatMessage(messages.errorUndefinedTitle),
                alertMessageText: this.formatMessage(messages.errorUndefinedMessage) + message
              })
              logError(error)
          }
        } else {
          this.deleteData()
          this.setState({
            alertVisibility: '',
            alertMessageType: 'danger',
            alertMessageTitle: this.formatMessage(messages.errorUndefinedTitle),
            alertMessageText: this.formatMessage(messages.errorUndefinedMessage)
          })
          logError(error)
        }
      })
  }

  render() {
    const uploadButtonOptions = {
      baseUrl: process.env.REACT_APP_HTTP_API,
      dataType: 'json',
      multiple: false,
      numberLimit: 1,
      accept: '.json',
      fileFieldName: 'file',

      didChoose: files => {
        files = {} // eslint-disable-line no-param-reassign
      },
      beforeUpload: files => {
        if (typeof files === 'string') {
          return true
        }
        if (files[0].size > 0) {
          return true
        }
        return false
      },
      didLoad: pcFile => {
        const reader = new FileReader()
        reader.readAsText(pcFile)
        reader.onload = () => {
          if (this.isJSON(reader.result)) {
            const jsonFile = JSON.parse(reader.result)
            if (jsonFile.sensorsMap) jsonFile.sensorsMap = jsonFile.sensorsMap.filter(s => s.frequency !== 0)

            validationSchemaFromPC()
              .validate(jsonFile, { abortEarly: false })
              .then(content => {
                const configAdapted = transformValuesFromPCToAPI(content)

                const Mu = this.state.machineUsagePercentage / 100
                const Hso = this.state.headersStackOverhead
                const estimatedDataConsumption = getEstimatedDataConsumption(content, Mu, Hso)

                if (estimatedDataConsumption <= 25) {
                  this.setState({
                    loaded: true,
                    isValidFile: true,
                    hasAllowedSize: true,
                    rawConfig: content,
                    dataConsumptionEstimation: estimatedDataConsumption,
                    loadedFileName: pcFile.name,
                    configurationName: configAdapted.name,
                    configFileToImport: configAdapted,
                    saveConfigurationDisabled: false,
                    alertVisibility: '',
                    alertMessageType: 'success',
                    alertMessageTitle: this.formatMessage(messages.validConfigFile),
                    alertMessageText: ''
                  })
                } else {
                  this.setState({
                    loaded: true,
                    isValidFile: true,
                    hasAllowedSize: false,
                    rawConfig: content,
                    dataConsumptionEstimation: estimatedDataConsumption,
                    loadedFileName: pcFile.name,
                    configurationName: configAdapted.name,
                    configFileToImport: configAdapted,
                    saveConfigurationDisabled: true,
                    alertVisibility: '',
                    alertMessageType: 'danger',
                    alertMessageTitle: this.formatMessage(messages.invalidConfigFile),
                    alertMessageText: this.formatMessage(messages.invalidConfigSizeMessage)
                  })
                }
              })
              .catch(error => {
                this.setState({
                  loaded: true,
                  isValidFile: false,
                  hasAllowedSize: false,
                  loadedFileName: pcFile.name,
                  configurationName: '',
                  configFileToImport: null,
                  saveConfigurationDisabled: true,
                  alertVisibility: '',
                  alertMessageType: 'danger',
                  alertMessageTitle: this.formatMessage(messages.invalidConfigFile),
                  alertMessageText: error.errors ? error.errors[0] : ''
                })
              })
          } else {
            this.setState({
              loaded: true,
              isValidFile: false,
              hasAllowedSize: false,
              loadedFileName: pcFile.name,
              configurationName: '',
              configFileToImport: null,
              saveConfigurationDisabled: true,
              alertVisibility: '',
              alertMessageType: 'danger',
              alertMessageTitle: this.formatMessage(messages.invalidJsonFile),
              alertMessageText: this.formatMessage(messages.invalidJsonFileMessage)
            })
          }
        }
      }
    }

    const { intl, classes, dialogOpen, handleDialogClose } = this.props

    const {
      loaded,
      isValidFile,
      loadedFileName,
      configurationName,
      savingConfiguration,
      saveConfigurationDisabled,
      machineUsagePercentage,
      dataConsumptionEstimation,
      alertVisibility,
      alertMessageType,
      alertMessageTitle,
      alertMessageText
    } = this.state

    return (
      <Dialog open={dialogOpen} onClose={handleDialogClose} classes={{ paper: classes.dialog }}>
        <DialogContent style={{ padding: '12px 10px 10px 12px' }}>
          <DialogTitle style={{ padding: 12 }}>
            <IconButton
              style={{
                position: 'absolute',
                right: 3,
                top: 3,
                padding: 5
              }}
              onClick={handleDialogClose}
            >
              <CloseIcon />
            </IconButton>
            {this.formatMessage(messages.selectFileFromPC)}
          </DialogTitle>
          <div className="col-md-12" style={{ padding: '0 0 0 12px' }}>
            <ReactUploadFile
              style={{ padding: 0 }}
              intl={intl}
              options={uploadButtonOptions}
              chooseFileButton={
                <Button className="secondary-action-button">{this.formatMessage(messages.browse)}</Button>
              }
            />
          </div>
          {loaded && isValidFile && (
            <div className="col-md-12" style={{ padding: 12 }}>
              <DialogContentText color="textPrimary">{this.formatMessage(messages.machineUsage)}</DialogContentText>
              <FormControl style={{ paddingBottom: 12 }}>
                <Input
                  id="machineUsagePercentage"
                  type="number"
                  value={machineUsagePercentage}
                  placeholder="%"
                  inputProps={{ min: 0, max: 100 }}
                  endAdornment={<InputAdornment position="end">{' %'}</InputAdornment>}
                  onChange={event => {
                    this.handleMachineUsagePercentageChange(event.target.value)
                  }}
                />
              </FormControl>
              <DialogContentText color="textPrimary">
                {this.formatMessage(messages.megabytesPerMonth) + dataConsumptionEstimation + ' MB'}
              </DialogContentText>
            </div>
          )}
          <div className="col-md-12" style={{ padding: '12px 12px 0 12px' }}>
            <div className={alertVisibility}>
              <div className={'alert alert-' + alertMessageType + ' alert-dismissible animated fadeIn'}>
                <button onClick={this.closeAlert} className="close" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
                {alertMessageTitle !== '' && <h4 style={{ margin: 0 }}>{alertMessageTitle}</h4>}
                <span className="h5">{alertMessageText}</span>
              </div>
            </div>
          </div>
          {loaded && (
            <div className="col-md-12" style={{ padding: 12 }}>
              <DialogContentText color="textPrimary">{this.formatMessage(messages.loadedFile)}</DialogContentText>
              <DialogContentText color="textPrimary" style={{ paddingBottom: 12 }}>
                {loadedFileName}
              </DialogContentText>
              <TextField
                label={this.formatMessage(messages.saveConfigAs)}
                placeholder={this.formatMessage(messages.configurationName)}
                value={configurationName}
                onChange={this.handleChangeConfigurationName}
              />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            className="primary-action-button"
            onClick={this.saveConfiguration}
            disabled={saveConfigurationDisabled || savingConfiguration}
          >
            {savingConfiguration && <CircularProgress size={24} style={{ position: 'absolute' }} />}
            {this.formatMessage(messages.saveConfiguration)}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(injectIntl(ConfigurationImportFromPC))
