import { defineMessages } from 'react-intl'

const messages = defineMessages({
  title: {
    id: 'EditConfigurationCS500.title',
    description: 'Title text',
    defaultMessage: 'Edit configuration'
  },
  addCANMessage: {
    id: 'EditConfigurationCS500.addCANMessage',
    description: 'Add CAN message tooltip',
    defaultMessage: 'Add CAN message'
  },
  sendCANMessages: {
    id: 'EditConfigurationCS500.sendCANMessages',
    description: 'CAN messages paper title',
    defaultMessage: 'Send CAN messages'
  },
  deviceSettings: {
    id: 'EditConfigurationCS500.deviceSettings',
    description: 'Device settings card title',
    defaultMessage: 'Device settings'
  },
  nodeId0: {
    id: 'EditConfigurationCS500.nodeId0',
    description: 'CAN port 0 node ID input label',
    defaultMessage: 'CAN port 0 node ID'
  },
  nodeId1: {
    id: 'EditConfigurationCS500.nodeId1',
    description: 'CAN port 1 node ID input label',
    defaultMessage: 'CAN port 1 node ID'
  },
  baudRate0: {
    id: 'EditConfigurationCS500.baudRate0',
    description: 'CAN port 0 baud rate select label',
    defaultMessage: 'CAN port 0 baud rate (kbps)'
  },
  baudRate1: {
    id: 'EditConfigurationCS500.baudRate1',
    description: 'CAN port 1 baud rate select label',
    defaultMessage: 'CAN port 1 baud rate (kbps)'
  },
  sa0: {
    id: 'EditConfigurationCS500.sa0',
    description: 'CAN port 0 source address input label',
    defaultMessage: 'CAN port 0 source address'
  },
  sa1: {
    id: 'EditConfigurationCS500.sa1',
    description: 'CAN port 1 source address input label',
    defaultMessage: 'CAN port 1 source address'
  },
  loggingUploadPeriod0: {
    id: 'EditConfigurationCS500.loggingUploadPeriod0',
    description: 'Cloud buffer 0 upload period input label',
    defaultMessage: 'Cloud buffer 0 upload period (s)'
  },
  loggingUploadPeriod1: {
    id: 'EditConfigurationCS500.loggingUploadPeriod1',
    description: 'Cloud buffer 1 upload period input label',
    defaultMessage: 'Cloud buffer 1 upload period (s)'
  },
  buffer0explanation: {
    id: 'EditConfigurationCS500.buffer0explanation',
    description: 'Virtual signals',
    defaultMessage: 'Virtual signals'
  },
  buffer1explanation: {
    id: 'EditConfigurationCS500.buffer1explanation',
    description: 'DM1, Geofence, GNSS & Diagnostic data',
    defaultMessage: 'DM1, GNSS & Diagnostic data'
  },
  interlinkEnabled: {
    id: 'EditConfigurationCS500.interlinkEnabled',
    description: 'Enable PLUS+1 Interlink proxy connection',
    defaultMessage: 'Enable PLUS+1 Interlink proxy connection'
  },
  fotaUpdateEnabled: {
    id: 'EditConfigurationCS500.fotaUpdateEnabled',
    description: 'Enable FOTA update',
    defaultMessage: 'Enable FOTA update'
  },
  gnssConditions: {
    id: 'EditConfigurationCS500.gnssConditions',
    description: 'GNSS conditions card title',
    defaultMessage: 'GNSS conditions'
  },
  sendGNSSPosition: {
    id: 'EditConfigurationCS500.sendGNSSPosition',
    description: 'sendGNSSPosition checkbox label',
    defaultMessage: 'Send GNSS position'
  },
  positionLogTime: {
    id: 'EditConfigurationCS500.positionLogTime',
    description: 'positionLogTime input label',
    defaultMessage: 'Position log time (s)'
  },
  sendGNSSAltitude: {
    id: 'EditConfigurationCS500.sendGNSSAltitude',
    description: 'sendGNSSAltitude checkbox label',
    defaultMessage: 'Send GNSS altitude'
  },
  altitudeLogTime: {
    id: 'EditConfigurationCS500.altitudeLogTime',
    description: 'altitudeLogTime input label',
    defaultMessage: 'Altitude log time (s)'
  },
  sendGNSSSpeed: {
    id: 'EditConfigurationCS500.sendGNSSSpeed',
    description: 'sendGNSSSpeed checkbox label',
    defaultMessage: 'Send GNSS speed'
  },
  speedLogTime: {
    id: 'EditConfigurationCS500.speedLogTime',
    description: 'speedLogTime input label',
    defaultMessage: 'Speed log time (s)'
  },
  sendGNSSHeading: {
    id: 'EditConfigurationCS500.sendGNSSHeading',
    description: 'sendGNSSHeading checkbox label',
    defaultMessage: 'Send GNSS heading'
  },
  headingLogTime: {
    id: 'EditConfigurationCS500.headingLogTime',
    description: 'headingLogTime input label',
    defaultMessage: 'Heading log time (s)'
  },
  geofenceConditions: {
    id: 'EditConfigurationCS500.geofenceConditions',
    description: 'Geofence conditions card title',
    defaultMessage: 'Geofence conditions'
  },
  enableGeofence: {
    id: 'EditConfigurationCS500.enableGeofence',
    description: 'enableGeofence checkbox label',
    defaultMessage: 'Enable geofence'
  },
  latitude: {
    id: 'EditConfigurationCS500.latitude',
    description: 'Latitude / 0.0000001°',
    defaultMessage: 'Latitude / 0.0000001°'
  },
  longitude: {
    id: 'EditConfigurationCS500.longitude',
    description: 'Longitude / 0.0000001°',
    defaultMessage: 'Longitude / 0.0000001°'
  },
  radius: {
    id: 'EditConfigurationCS500.radius',
    description: 'radius',
    defaultMessage: 'Radius (m)'
  },
  geofenceLogToPortal: {
    id: 'EditConfigurationCS500.geofenceLogToPortal',
    description: 'Log to portal',
    defaultMessage: 'Log to portal'
  },
  geofenceSamplePeriod: {
    id: 'EditConfigurationCS500.geofenceSamplePeriod',
    description: 'Sample period',
    defaultMessage: 'Sample period (s)'
  },
  geofenceSendImmediately: {
    id: 'EditConfigurationCS500.geofenceSendImmediately',
    description: 'Send immediately',
    defaultMessage: 'Send immediately'
  },
  virtualSignals: {
    id: 'EditConfigurationCS500.canbus',
    description: 'Virtual signals paper title',
    defaultMessage: 'Virtual signals'
  },
  addSignal: {
    id: 'EditConfigurationCS500.addSignal',
    description: 'Add signal tooltip',
    defaultMessage: 'Add signal'
  },
  cancel: {
    id: 'EditConfigurationCS500.cancel',
    description: 'Cancel button label',
    defaultMessage: 'Cancel'
  },
  download: {
    id: 'EditConfigurationCS500.download',
    description: 'Download button label',
    defaultMessage: 'Download'
  },
  apply: {
    id: 'EditConfigurationCS500.apply',
    description: 'Apply button label',
    defaultMessage: 'Apply'
  },
  applying: {
    id: 'EditConfigurationCS500.applying',
    description: 'Applying configuration',
    defaultMessage: 'Applying configuration...'
  },
  configurationUpdate: {
    id: 'EditConfigurationCS500.configurationUpdate',
    description: 'Configuration update text',
    defaultMessage: 'Configuration update'
  },
  warningYouAreAboutToUpdateDeviceConfig: {
    id: 'EditConfigurationCS500.warningYouAreAboutToUpdateDeviceConfig',
    description: 'Warning message',
    defaultMessage: 'WARNING! You are about to update the following device\'s configuration:'
  },
  sameConfig: {
    id: 'EditConfigurationCS500.sameConfig',
    description: 'The configuration you are about to apply is already configured in the device.',
    defaultMessage: 'The configuration you are about to apply is already configured in the device.'
  },
  configurationSuccessfullyApplied: {
    id: 'EditConfigurationCS500.configurationSuccessfullyApplied',
    description: 'Configuration successfully applied.',
    defaultMessage: 'Configuration successfully applied.'
  },
  thereAreNoCS500: {
    id: 'EditConfigurationCS500.thereAreNoCS500',
    description: 'There are no CS500 devices message',
    defaultMessage: 'There are no CS500 devices to show.'
  },
  generalErrorDescriptionApply: {
    id: 'EditConfigurationCS500.generalErrorDescriptionApply',
    description: 'General error description',
    defaultMessage:
      'There are some errors in the configuration. Please, correct them before updating the configuration.'
  },
  generalErrorDescriptionDownload: {
    id: 'EditConfigurationCS500.generalErrorDescriptionDownload',
    description: 'General error description',
    defaultMessage:
      'There are some errors in the configuration. Please, correct them before downloading the configuration.'
  },
  error400Message: {
    id: 'EditConfigurationCS500.error400Message',
    description: 'Error 400 message',
    defaultMessage: 'Bad request'
  },
  error403Message: {
    id: 'EditConfigurationCS500.error403Message',
    description: 'Error 403 message',
    defaultMessage: 'Access denied'
  },
  error404Message: {
    id: 'EditConfigurationCS500.error404Message',
    description: 'Error 404 message',
    defaultMessage: 'API url not found.'
  },
  error406Message: {
    id: 'EditConfigurationCS500.error406Message',
    description: 'Error 406 message',
    defaultMessage: 'Not acceptable'
  },
  errorUndefinedTitle: {
    id: 'EditConfigurationCS500.errorUndefinedTitle',
    description: 'Error undefined title',
    defaultMessage: 'Error undefined'
  },
  errorUndefinedMessage: {
    id: 'EditConfigurationCS500.errorUndefinedMessage',
    description: 'Error undefined message',
    defaultMessage: 'No description available.'
  },
  error409Message: {
    id: 'EditConfigurationCS500.error409Message',
    description: 'Error 409 message',
    defaultMessage: 'Data integrity violation: Configuration name it\'s already in use'
  },
  error415Message: {
    id: 'EditConfigurationCS500.error415Message',
    description: 'Error 415 message',
    defaultMessage: 'Unsupported media type'
  },
  error422Message: {
    id: 'EditConfigurationCS500.error422Message',
    description: 'Error 422 message',
    defaultMessage: 'Validation failed'
  },
  error: {
    id: 'EditConfigurationCS500.error',
    description: 'error text',
    defaultMessage: 'Error {number}'
  },
  error401Message: {
    id: 'EditConfigurationCS500.error401Message',
    description: 'Error 401 message',
    defaultMessage: 'Invalid credentials'
  },
  error500Message: {
    id: 'EditConfigurationCS500.error500Message',
    description: 'Error 500 message',
    defaultMessage: 'Unexpected error'
  }
})

export default messages
