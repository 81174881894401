import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import AdvancedSignalsConfigDialog from 'components/AdvancedSignalsConfigDialog'
import PageTitle from 'components/PageTitle'

import messages from '../messages'
import MachinesTable from './MachinesTable'

class Machines extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { privileges, allPrivileges, getDynamicCSNodes, groupId, intl } = this.props

    return (
      <div className='content-container' id='content'>
        <div style={{ margin: '20px 25px 20px 20px' }}>
          <div className='container-fluid'>
            <PageTitle title={intl.formatMessage(messages.title)} />
          </div>
          <div className='row' id='cs' style={{ height: '100%', margin: '20px 0 0 0' }}>
            <div className='col-md-12'>
              <MachinesTable
                allPrivileges={allPrivileges}
                getDynamicCSNodes={getDynamicCSNodes}
                groupId={groupId}
                privileges={privileges}
              />
            </div>
          </div>
        </div>
        <AdvancedSignalsConfigDialog />
      </div>
    )
  }
}

Machines.propTypes = {
  allPrivileges: PropTypes.object.isRequired,
  getDynamicCSNodes: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  privileges: PropTypes.object.isRequired
}

export default injectIntl(Machines)
