import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Files from 'react-files'

import Grid from '@material-ui/core/Grid'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import Button from '@material-ui/core/Button'

import { injectIntl } from 'react-intl'
import messages from './messages'

import './dropzone.css'

class ImportDashboard extends Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      name: '',
      description: '',
      height: '',
      width: '',
      zoom: '',
      widgets: null,
      uploadedFileName: '',
      uploadError: false,
      uploadMessage: ''
    }
  }

  onFilesChange = files => {
    const fileReader = new FileReader()

    if (files[0]) {
      fileReader.readAsText(files[files.length - 1])
    }

    fileReader.onload = event => {
      const fileContent = JSON.parse(event.target.result)
      this.setState({
        name: fileContent.name,
        description: fileContent.description,
        height: fileContent.height,
        width: fileContent.width,
        zoom: fileContent.zoom,
        widgets: fileContent.widgets,
        uploadedFileName: files[files.length - 1].name,
        uploadError: false,
        uploadMessage: ''
      })
    }
  }

  onFilesError = (error, file) => {
    this.setState({
      uploadError: true,
      uploadMessage: error.code + ': ' + error.message
    })
  }

  importDashboardWidgets = () => {
    let maxZIndex = 0
    const widgets = Object.keys(this.state.widgets)

    widgets.map(widget => {
      if (this.state.widgets[widget].zIndex > maxZIndex) {
        maxZIndex = this.state.widgets[widget].zIndex
      }

      return true
    })
    this.props.importDashboard(maxZIndex, this.state.widgets)

    this.props.closeImportDashboardModal()
  }

  importDashboard = () => {
    const settings = {
      name: this.state.name,
      description: this.state.description,
      height: this.state.height,
      width: this.state.width,
      zoom: this.state.zoom
    }
    this.props.setDashboardSettings(settings)
    this.importDashboardWidgets()
    const dashboardData = { ...this.props.dashboardData, name: this.state.name }
    this.props.updateGroupDashboard(dashboardData)
  }

  render() {
    return (
      <Dialog
        TransitionComponent={this.transition}
        aria-describedby="alert-dialog-slide-description"
        aria-labelledby="alert-dialog-slide-title"
        fullWidth={true}
        keepMounted={true}
        maxWidth="md"
        onClose={this.props.closeImportDashboardModal}
        open={this.props.importDashboardModal}
        scroll="paper"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {this.formatMessage(messages.importDashboard)}
          <IconButton
            onClick={this.props.closeImportDashboardModal}
            style={{
              position: 'absolute',
              right: 3,
              top: 3
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ flexGrow: 1 }}>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12}>
              {this.state.uploadedFileName === '' ? (
                <Files
                  accepts={['.json']}
                  className="files-dropzone"
                  clickable={true}
                  dropActiveClassName="files-dropzone-active"
                  maxFiles={100}
                  minFileSize={0}
                  multiple={true}
                  // maxFileSize={10000000}
                  onChange={this.onFilesChange}
                  onError={this.onFilesError}
                >
                  {this.formatMessage(messages.dropFilesHereOrClickToUpload)}
                </Files>
              ) : 
                ''
              }
              <DialogContentText id="alert-dialog-slide-description">
                {this.state.uploadError ? (
                  <span style={{ color: 'red', display: 'block', paddingBottom: 10, paddingTop: 10 }}>
                    {this.state.uploadMessage}
                  </span>
                ) : 
                  ''
                }
                {this.state.uploadedFileName !== '' ? (
                  <span style={{ display: 'block', paddingBottom: 10, paddingTop: 10 }}>
                    <strong>{this.formatMessage(messages.uploadedFile)}</strong> {this.state.uploadedFileName}
                  </span>
                ) : 
                  ''
                }
                {this.state.uploadedFileName !== '' ? (
                  <span style={{ display: 'block', paddingBottom: 10, paddingTop: 10 }}>
                    <Button className="primary-action-button" onClick={this.importDashboard}>
                      {this.formatMessage(messages.importDashboardAction)}
                    </Button>
                  </span>
                ) : 
                  ''
                }
              </DialogContentText>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button className="cancel-button" onClick={this.props.closeImportDashboardModal}>
            {this.formatMessage(messages.cancel)}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

ImportDashboard.propTypes = {
  closeImportDashboardModal: PropTypes.func.isRequired,
  dashboardData: PropTypes.object.isRequired,
  importDashboardModal: PropTypes.bool.isRequired,
  setDashboardSettings: PropTypes.func.isRequired,
  updateGroupDashboard: PropTypes.func.isRequired
}

export default injectIntl(ImportDashboard)
