import { connect } from 'react-redux'

import { utils } from 'ducks/theme'

import * as c from 'utils/constants'
import { resolvePermissions } from 'utils/permissions'

import { createLoader } from 'components/LoadOnMount'
import Header from './Header'

const mapStateToProps = state => ({
  showSettingsDropdown: resolvePermissions(state, [c.USER_READ, c.GROUPS_READ, c.GROUPROLE_READ, c.RULE_READ]),
  canViewNotifications: resolvePermissions(state, c.NOTIFICATION_READ),
  appBarColor: utils.getAppBarColor(state),
  appFontColor: utils.getFontColor(state)
})

export default createLoader(connect(mapStateToProps)(Header))
