import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import DM1 from '../DM1'
import DeviceSettings from '../DeviceSettings'
import GNSSGeofenceConditions from '../GNSSGeofenceConditions'
import MFIO from '../MFIO'
import MachineStateDetermination from '../MachineStateDetermination'
import SendCANMessages from '../SendCANMessages'
import Signal from '../Signal'
import messages from './messages'

const ViewConfigurationForm = ({
  intl,
  nvConfiguration,
  sendCANMessageStatus1,
  sendCANMessageStatus2,
  sendCANMessageStatus3,
  sendCANMessageStatus4,
  onSetValuePulse
}) => {
  const { formatMessage } = intl

  return (
    <div className='container-fluid' style={{ marginTop: 20 }}>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <DeviceSettings
            baudRate0={nvConfiguration.baudRate0}
            baudRate0ErrorMessage={nvConfiguration.baudRate0ErrorMessage}
            baudRate1={nvConfiguration.baudRate1}
            baudRate1ErrorMessage={nvConfiguration.baudRate1ErrorMessage}
            // cellularPriority={nvConfiguration.cellularPriority}
            // cellularPriorityErrorMessage={nvConfiguration.cellularPriorityErrorMessage}
            fotaUpdateEnabled={nvConfiguration.fotaUpdateEnabled}
            interlinkEnabled={nvConfiguration.interlinkEnabled}
            isEditable={false}
            loggingUploadPeriod0={nvConfiguration.loggingUploadPeriod0}
            loggingUploadPeriod0ErrorMessage={nvConfiguration.loggingUploadPeriod0ErrorMessage}
            loggingUploadPeriod1={nvConfiguration.loggingUploadPeriod1}
            loggingUploadPeriod1ErrorMessage={nvConfiguration.loggingUploadPeriod1ErrorMessage}
            nodeId0={nvConfiguration.nodeId0}
            nodeId0ErrorMessage={nvConfiguration.nodeId0ErrorMessage}
            nodeId1={nvConfiguration.nodeId1}
            nodeId1ErrorMessage={nvConfiguration.nodeId1ErrorMessage}
            restartCellularModule={nvConfiguration.restartCellularModule}
            sa0={nvConfiguration.sa0}
            sa0ErrorMessage={nvConfiguration.sa0ErrorMessage}
            sa1={nvConfiguration.sa1}
            sa1ErrorMessage={nvConfiguration.sa1ErrorMessage}
            // view='viewConfig'
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <GNSSGeofenceConditions
            altitudeLogTime={nvConfiguration.altitudeLogTime}
            altitudeLogTimeErrorMessage={nvConfiguration.altitudeLogTimeErrorMessage}
            geofenceEnabled={nvConfiguration.geofenceEnabled}
            geofenceLatitude={nvConfiguration.geofenceLatitude}
            geofenceLatitudeErrorMessage={nvConfiguration.geofenceLatitudeErrorMessage}
            geofenceLogToPortal={nvConfiguration.geofenceLogToPortal}
            geofenceLongitude={nvConfiguration.geofenceLongitude}
            geofenceLongitudeErrorMessage={nvConfiguration.geofenceLongitudeErrorMessage}
            geofenceRadius={nvConfiguration.geofenceRadius}
            geofenceRadiusErrorMessage={nvConfiguration.geofenceRadiusErrorMessage}
            geofenceSamplePeriod={nvConfiguration.geofenceSamplePeriod}
            geofenceSamplePeriodErrorMessage={nvConfiguration.geofenceSamplePeriodErrorMessage}
            geofenceSendImmediately={nvConfiguration.geofenceSendImmediately}
            gnssAltitudeEnabled={nvConfiguration.gnssAltitudeEnabled}
            gnssHeadingEnabled={nvConfiguration.gnssHeadingEnabled}
            gnssPositionEnabled={nvConfiguration.gnssPositionEnabled}
            gnssSpeedEnabled={nvConfiguration.gnssSpeedEnabled}
            headingLogTime={nvConfiguration.headingLogTime}
            headingLogTimeErrorMessage={nvConfiguration.headingLogTimeErrorMessage}
            isEditable={false}
            positionLogTime={nvConfiguration.positionLogTime}
            positionLogTimeErrorMessage={nvConfiguration.positionLogTimeErrorMessage}
            speedLogTime={nvConfiguration.speedLogTime}
            speedLogTimeErrorMessage={nvConfiguration.speedLogTimeErrorMessage}
          />
        </Grid>
        <Grid item xs={12}>
          <MFIO
            bias0={nvConfiguration.bias0}
            bias0ErrorMessage={nvConfiguration.bias0ErrorMessage}
            bias1={nvConfiguration.bias1}
            bias1ErrorMessage={nvConfiguration.bias1ErrorMessage}
            bias2={nvConfiguration.bias2}
            bias2ErrorMessage={nvConfiguration.bias2ErrorMessage}
            bias3={nvConfiguration.bias3}
            bias3ErrorMessage={nvConfiguration.bias3ErrorMessage}
            digThreshHigh0={nvConfiguration.digThreshHigh0}
            digThreshHigh0ErrorMessage={nvConfiguration.digThreshHigh0ErrorMessage}
            digThreshHigh1={nvConfiguration.digThreshHigh1}
            digThreshHigh1ErrorMessage={nvConfiguration.digThreshHigh1ErrorMessage}
            digThreshHigh2={nvConfiguration.digThreshHigh2}
            digThreshHigh2ErrorMessage={nvConfiguration.digThreshHigh2ErrorMessage}
            digThreshHigh3={nvConfiguration.digThreshHigh3}
            digThreshHigh3ErrorMessage={nvConfiguration.digThreshHigh3ErrorMessage}
            digThreshLow0={nvConfiguration.digThreshLow0}
            digThreshLow0ErrorMessage={nvConfiguration.digThreshLow0ErrorMessage}
            digThreshLow1={nvConfiguration.digThreshLow1}
            digThreshLow1ErrorMessage={nvConfiguration.digThreshLow1ErrorMessage}
            digThreshLow2={nvConfiguration.digThreshLow2}
            digThreshLow2ErrorMessage={nvConfiguration.digThreshLow2ErrorMessage}
            digThreshLow3={nvConfiguration.digThreshLow3}
            digThreshLow3ErrorMessage={nvConfiguration.digThreshLow3ErrorMessage}
            inputRange0={nvConfiguration.inputRange0}
            inputRange0ErrorMessage={nvConfiguration.inputRange0ErrorMessage}
            inputRange1={nvConfiguration.inputRange1}
            inputRange1ErrorMessage={nvConfiguration.inputRange1ErrorMessage}
            inputRange2={nvConfiguration.inputRange2}
            inputRange2ErrorMessage={nvConfiguration.inputRange2ErrorMessage}
            inputRange3={nvConfiguration.inputRange3}
            inputRange3ErrorMessage={nvConfiguration.inputRange3ErrorMessage}
            inputSignal0={nvConfiguration.inputSignal0}
            inputSignal0ErrorMessage={nvConfiguration.inputSignal0ErrorMessage}
            inputSignal1={nvConfiguration.inputSignal1}
            inputSignal1ErrorMessage={nvConfiguration.inputSignal1ErrorMessage}
            inputSignal2={nvConfiguration.inputSignal2}
            inputSignal2ErrorMessage={nvConfiguration.inputSignal2ErrorMessage}
            inputSignal3={nvConfiguration.inputSignal3}
            inputSignal3ErrorMessage={nvConfiguration.inputSignal3ErrorMessage}
            isEditable={false}
          />
        </Grid>
        <Grid item xs={12}>
          <DM1
            dm1LogToPortal={nvConfiguration.dm1LogToPortal}
            dm1Port={nvConfiguration.dm1Port}
            dm1PortErrorMessage={nvConfiguration.dm1PortErrorMessage}
            dm1SA={nvConfiguration.dm1SA}
            dm1SAErrorMessage={nvConfiguration.dm1SAErrorMessage}
            dm1UseSA={nvConfiguration.dm1UseSA}
            expanded={nvConfiguration.dm1Expanded}
            isEditable={false}
          />
        </Grid>
        <Grid item xs={12}>
          <MachineStateDetermination
            byte0={nvConfiguration.byte0}
            byte0ErrorMessage={nvConfiguration.byte0ErrorMessage}
            byte1={nvConfiguration.byte1}
            byte1ErrorMessage={nvConfiguration.byte1ErrorMessage}
            byte2={nvConfiguration.byte2}
            byte2ErrorMessage={nvConfiguration.byte2ErrorMessage}
            byte3={nvConfiguration.byte3}
            byte3ErrorMessage={nvConfiguration.byte3ErrorMessage}
            byte4={nvConfiguration.byte4}
            byte4ErrorMessage={nvConfiguration.byte4ErrorMessage}
            byte5={nvConfiguration.byte5}
            byte5ErrorMessage={nvConfiguration.byte5ErrorMessage}
            byte6={nvConfiguration.byte6}
            byte6ErrorMessage={nvConfiguration.byte6ErrorMessage}
            byte7={nvConfiguration.byte7}
            byte7ErrorMessage={nvConfiguration.byte7ErrorMessage}
            canId={nvConfiguration.canId}
            canIdErrorMessage={nvConfiguration.canIdErrorMessage}
            canPort={nvConfiguration.canPort}
            canPortErrorMessage={nvConfiguration.canPortErrorMessage}
            digitalInputPin={nvConfiguration.digitalInputPin}
            digitalInputPinErrorMessage={nvConfiguration.digitalInputPinErrorMessage}
            enableCan={nvConfiguration.enableCan}
            enableDigitalInput={nvConfiguration.enableDigitalInput}
            enableServiceToolButton={nvConfiguration.enableServiceToolButton}
            expanded={nvConfiguration.machineStateDeterminationExpanded}
            forceFullAccessMode={nvConfiguration.forceFullAccessMode}
            isEditable={false}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper style={{ borderRadius: 0 }}>
            <Grid container style={{ justifyContent: 'space-between' }}>
              <Grid item>
                <Typography component='h3' style={{ margin: '14px' }} variant='h5'>
                  {formatMessage(messages.canMessages)}
                </Typography>
              </Grid>
            </Grid>
            <Grid>
              {nvConfiguration.sendCANMessages.map(sendCANMessage => {
                const getSendCANMessageStatus = canMessageId => {
                  switch (canMessageId) {
                    case 0:
                      return sendCANMessageStatus1
                    case 1:
                      return sendCANMessageStatus2
                    case 2:
                      return sendCANMessageStatus3
                    case 3:
                      return sendCANMessageStatus4
                  }
                }
                return (
                  <React.Fragment key={sendCANMessage.canMessageId}>
                    <Divider light />
                    <SendCANMessages
                      {...sendCANMessage}
                      isEditable={false}
                      noErrorInSendCANMessage
                      sendCANMessageStatus={getSendCANMessageStatus(sendCANMessage.canMessageId)}
                      setValuePulse={onSetValuePulse}
                    />
                  </React.Fragment>
                )
              })}
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper style={{ borderRadius: 0 }}>
            <Grid container style={{ justifyContent: 'space-between' }}>
              <Grid item>
                <Typography component='h3' style={{ margin: '14px' }} variant='h5'>
                  {formatMessage(messages.virtualSignals)}
                </Typography>
              </Grid>
            </Grid>
            <Grid>
              {nvConfiguration.signals.map(signal => {
                return (
                  <React.Fragment key={signal.signalId}>
                    <Divider light />
                    <Signal
                      inputSignal0={nvConfiguration.inputSignal0}
                      inputSignal1={nvConfiguration.inputSignal1}
                      inputSignal2={nvConfiguration.inputSignal2}
                      inputSignal3={nvConfiguration.inputSignal3}
                      isEditable={false}
                      {...signal}
                    />
                  </React.Fragment>
                )
              })}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

ViewConfigurationForm.propTypes = {
  configurationsUrl: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  nvConfiguration: PropTypes.object.isRequired,
  onSetValuePulse: PropTypes.func.isRequired,
  sendCANMessageStatus1: PropTypes.number.isRequired,
  sendCANMessageStatus2: PropTypes.number.isRequired,
  sendCANMessageStatus3: PropTypes.number.isRequired,
  sendCANMessageStatus4: PropTypes.number.isRequired
}

export default injectIntl(ViewConfigurationForm)
