import PropTypes from 'prop-types'
import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Callback from 'components/Callback'
import { utils } from 'ducks/login'
import { getUrlParameter } from 'routes'
import { LOGOUT, HOME, CALLBACK } from 'routes/constants'
import { getLastOpenURL, saveLastOpenURL } from 'utils/urlStorage'

import Home from '../home'
import { REDIRECTIONS, HOME_PATH, CALLBACK_PATH, getUnloggedRedirection } from '../url'

const LoginRouteHandler = ({ loginPath, location }) => {
  if (
    !getLastOpenURL() &&
    !location.pathname.includes(LOGOUT) &&
    !location.pathname.includes(HOME) &&
    !location.pathname.includes(CALLBACK) &&
    location.pathname !== '/'
  ) {
    saveLastOpenURL(location.pathname)
  }
  if (location.pathname.includes('register/signupcs10')) {
    utils.cacheRegisterPath(location.pathname)
    var eid = getUrlParameter(location.search, 'eid')
    var registerCode = getUrlParameter(location.search, 'regcode')
    var newParams = '?eid=' + encodeURIComponent(eid) + '&regcode=' + encodeURIComponent(registerCode)
    utils.cacheRegisterSearch(newParams)
  }

  let [, groupPath] = location.pathname.split('/')
  if (!groupPath || groupPath === 'logout') groupPath = loginPath

  return (
    <Switch>
      <Route component={Home} path={HOME_PATH} />
      <Route component={Callback} path={CALLBACK_PATH} />
      {REDIRECTIONS.map(({ from, to }) => (
        <Redirect key={from} from={from} to={to} />
      ))}
      <Redirect push to={getUnloggedRedirection(groupPath)} />
    </Switch>
  )
}

LoginRouteHandler.propTypes = {
  location: PropTypes.object.isRequired,
  loginPath: PropTypes.string.isRequired
}

export { LoginRouteHandler }
