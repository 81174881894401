import moment from 'moment'
//import { client, logError } from 'utils/http'

/* eslint-disable max-params */
const composeLocalQuery = (
  filter,
  filterCriticality,
  filterStatus,
  filterArchived,
  filterDates,
  secondaryGlobalQuery,
  page,
  pageLength
) => {
  let query = ''
  let filterQuery = ''
  let separator = ''
  let criticalityQuery = ''
  let criticalitySeparator = ''
  let statusQuery = ''
  let statusSeparator = ''

  if (secondaryGlobalQuery !== '') {
    filterQuery = secondaryGlobalQuery
    separator = ';'
  }

  /* FILTER BY DATES */
  if (filterDates.from && filterDates.from !== null && filterDates.to && filterDates.to !== null) {
    filterQuery =
      filterQuery +
      separator +
      '(createdDate>' +
      moment(filterDates.from).valueOf() +
      ');(createdDate<' +
      moment(filterDates.to).valueOf() +
      ')'
    separator = ';'
  }

  /* FILTER BY SEVERITY (CRITICALITY) */

  if (filterCriticality.high) {
    criticalityQuery = criticalityQuery + criticalitySeparator + 'criticality==high'
    criticalitySeparator = ','
  }
  if (filterCriticality.medium) {
    criticalityQuery = criticalityQuery + criticalitySeparator + 'criticality==medium'
    criticalitySeparator = ','
  }
  if (filterCriticality.low) {
    criticalityQuery = criticalityQuery + criticalitySeparator + 'criticality==low'
    criticalitySeparator = ','
  }

  if (criticalityQuery !== '') {
    filterQuery = filterQuery + separator + '(' + criticalityQuery + ')'
    separator = ';'
  }

  /* FILTER BY STATUS */
  if (filterStatus.open) {
    statusQuery = statusQuery + statusSeparator + 'status==open'
    statusSeparator = ','
  }
  if (filterStatus.closed) {
    statusQuery = statusQuery + statusSeparator + 'status==closed'
    statusSeparator = ','
  }
  if (filterStatus.acknowledged) {
    statusQuery = statusQuery + statusSeparator + 'status==acknowledged'
    statusSeparator = ','
  }

  if (statusQuery !== '') {
    filterQuery = filterQuery + separator + '(' + statusQuery + ')'
    separator = ';'
  }

  /* FILTER BY ARCHIVED */
  //Si selecciona "mostrar archivadas" mostramos todas, las archivadas y las no archivadas, por eso en el filtro
  //aparece "archived==false" y después otro "archived==XX" que puede ser true o false.
  if (filterArchived !== undefined) {
    filterQuery = filterQuery + separator + '(archived==false,archived==' + filterArchived + ')'
    separator = ';'
  }

  /* FILTER BY SOURCE  */
  if (filter.source !== undefined) {
    filterQuery = filterQuery + separator + '(source=="*' + filter.source.value + '*")'
    separator = ';'
  }

  /* FILTER BY RULE INSTANCE NAME */
  if (filter.ruleInstanceName !== undefined) {
    filterQuery = filterQuery + separator + '(ruleInstanceName=="*' + filter.ruleInstanceName.value + '*")'
  }

  /* FILTER BY NOTIFICATION BODY  */
  if (filter.notificationBody !== undefined) {
    filterQuery = filterQuery + separator + '(notificationBody=="*' + filter.notificationBody.value + '*")'
    separator = ';'
  }

  if (filterQuery !== '') {
    query = '&' + query + 'search=' + filterQuery
  }

  query = query + '&'

  query = query + 'page=' + (page - 1) + '&size=' + pageLength

  return query
}
/* eslint-enable */

export { composeLocalQuery }
