import _isEqual from 'lodash/isEqual'

import messages from './messages'

export const getDevice = (deviceEid, getDeviceByEid, groupId, setState) => {
  getDeviceByEid(deviceEid, groupId).then(response => {
    setState({
      deviceName: response.data.name
    })
  })
}

export const getConfiguration = (
  adapter,
  deviceEid,
  formatMessage,
  getDeviceNonVolatileConfiguration,
  groupId,
  setLocalConfiguration,
  setState
) => {
  getDeviceNonVolatileConfiguration(groupId, deviceEid)
    .then(response => {
      setState({ isConfigLoading: false })
      setLocalConfiguration(adapter.cs500ConfigToCs100Config(response.data))
    })
    .catch(response => {
      const { error } = { ...response }
      if (error && error.response) {
        const message = error.response.data && error.response.data.message ? ': ' + error.response.data.message : ''
        switch (error.response.status) {
          case 400:
            setState({
              isConfigLoading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: formatMessage(messages.error, {
                number: '400'
              }),
              alertMessagesText: [formatMessage(messages.error400Message) + message]
            })
            break
          case 401:
            setState({
              isConfigLoading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: formatMessage(messages.error, {
                number: '401'
              }),
              alertMessagesText: [formatMessage(messages.error401Message) + message]
            })
            break
          case 403:
            setState({
              isConfigLoading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: formatMessage(messages.error, {
                number: '403'
              }),
              alertMessagesText: [formatMessage(messages.error403Message) + message]
            })
            break
          case 404:
            setState({
              isConfigLoading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: formatMessage(messages.error, {
                number: '404'
              }),
              alertMessagesText: [formatMessage(messages.error404Message) + message]
            })
            break
          case 406:
            setState({
              isConfigLoading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: formatMessage(messages.error, {
                number: '406'
              }),
              alertMessagesText: [formatMessage(messages.error406Message) + message]
            })
            break
          case 422:
            setState({
              isConfigLoading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: formatMessage(messages.error, {
                number: '422'
              }),
              alertMessagesText: [formatMessage(messages.error422Message) + message]
            })
            break
          case 500:
            setState({
              isConfigLoading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: formatMessage(messages.error, {
                number: '500'
              }),
              alertMessagesText: [formatMessage(messages.error500Message) + message]
            })
            break
          default:
            setState({
              isConfigLoading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: formatMessage(messages.errorUndefinedTitle),
              alertMessagesText: [formatMessage(messages.errorUndefinedMessage)]
            })
        }
      } else {
        setState({
          isConfigLoading: false,
          alertMessages: true,
          alertMessagesType: 'danger',
          alertMessagesTitle: formatMessage(messages.errorUndefinedTitle),
          alertMessagesText: [formatMessage(messages.errorUndefinedMessage)]
        })
      }
    })
}

export const downloadConfiguration = (
  configState,
  deviceEid,
  deviceName,
  download,
  formatMessage,
  getSignalsWithAdaptedParameters,
  localConfigurationError,
  setState,
  transformValuesForAPI,
  transformVirtualParametersValuesForAPI,
  validationSchema
) => {
  // Modify multiplier and offset
  const adaptedSignals = getSignalsWithAdaptedParameters(configState.signals)
  const newConfigState = {
    ...configState,
    signals: adaptedSignals
  }

  validationSchema()
    .validate(newConfigState, {
      abortEarly: false,
      context: {
        loopTime: newConfigState.loopTime,
        signalsToLog: newConfigState.signals.length,
        // logPeriods: newConfigState.signals.map(s => parseInt(s.samplePeriod)),
        // triggerLogPeriods: newConfigState.signals.map(s => parseInt(s.samplePeriodTriggered)),
        configuredSignals: newConfigState.signals.map(s => s.signalId)
      }
    })
    .then(content => {
      const configAdapted = {
        parameters: transformValuesForAPI(content),
        virtualParameters: transformVirtualParametersValuesForAPI(content)
      }

      const fileName = deviceEid.replaceAll(':', '') + '-editing-CS500-config.json'
      const blob = new Blob([JSON.stringify(configAdapted, null, 2)], { type: 'application/octet-stream' })
      download(blob, fileName, 'application/octet-stream')
    })
    .catch(error => {
      localConfigurationError(error.inner)
      setState({
        isDialogOpen: true,
        isApplyConfigurationDialogButtonVisible: false,
        alertMessagesType: 'danger',
        alertMessagesTitle: deviceName + ' - ' + deviceEid,
        alertMessagesText: formatMessage(messages.generalErrorDescriptionDownload)
      })
    })
}

export const handleApplyConfigurationButtonClick = (
  configState,
  deviceEid,
  deviceName,
  formatMessage,
  getSignalsWithAdaptedParameters,
  localConfigurationError,
  setState,
  transformValuesForAPI,
  transformVirtualParametersValuesForAPI,
  validationSchema
) => {
  // Modify multiplier and offset
  const adaptedSignals = getSignalsWithAdaptedParameters(configState.signals)
  const newConfigState = {
    ...configState,
    signals: adaptedSignals
  }

  validationSchema()
    .validate(newConfigState, {
      abortEarly: false,
      context: {
        loopTime: newConfigState.loopTime,
        signalsToLog: newConfigState.signals.length,
        // logPeriods: newConfigState.signals.map(s => parseInt(s.samplePeriod)),
        // triggerLogPeriods: newConfigState.signals.map(s => parseInt(s.samplePeriodTriggered)),
        configuredSignals: newConfigState.signals.map(s => s.signalId)
      }
    })
    .then(content => {
      const configAdapted = {
        parameters: transformValuesForAPI(content),
        virtualParameters: transformVirtualParametersValuesForAPI(content)
      }
      const originalConfig = newConfigState.originalConfigs ? newConfigState.originalConfigs[deviceEid] : []
      const deltaConfig = getDeltaConfiguration(originalConfig, configAdapted)
      const isConfigToApplyEmpty = deltaConfig.parameters.length === 0 && deltaConfig.virtualParameters.length === 0

      setState({
        configToApply: {
          parameters: deltaConfig.parameters,
          virtualParameters: deltaConfig.virtualParameters
        },

        isDialogOpen: true,
        isApplyConfigurationDialogButtonVisible: isConfigToApplyEmpty ? false : true,
        alertMessagesType: isConfigToApplyEmpty ? 'info' : 'warning',
        alertMessagesTitle: isConfigToApplyEmpty
          ? deviceName + ' - ' + deviceEid
          : formatMessage(messages.warningYouAreAboutToUpdateDeviceConfig),
        alertMessagesText: isConfigToApplyEmpty ? formatMessage(messages.sameConfig) : deviceName + ' - ' + deviceEid
      })
    })
    .catch(error => {
      setState({
        isDialogOpen: true,
        isApplyConfigurationDialogButtonVisible: false,
        alertMessagesType: 'danger',
        alertMessagesTitle: deviceName + ' - ' + deviceEid,
        alertMessagesText: formatMessage(messages.generalErrorDescriptionApply)
      })
      localConfigurationError(error.inner)
    })
}

const getDeltaConfiguration = (originalConfig, adaptedConfig) => {
  const newParameters = adaptedConfig.parameters
  const originalParameters = originalConfig.parameters
  const newVirtualParameters = adaptedConfig.virtualParameters || []
  const originalVirtualParameters = originalConfig.virtualParameters || []

  const newSignals = newParameters.filter(newParameter => newParameter.id.includes('SignalSource_NV'))
  const originalSignals = originalParameters.filter(originalParameter =>
    originalParameter.id.includes('SignalSource_NV')
  )
  const newSendCANMessages = newParameters.filter(newParameter => newParameter.id.includes('SignalDest_NV'))
  const originalSendCANMessages = originalParameters.filter(originalParameter =>
    originalParameter.id.includes('SignalDest_NV')
  )

  const deltaConfig = {
    parameters: [],
    virtualParameters: []
  }

  newParameters.forEach(newParam => {
    const foundParameter = originalParameters.find(originalParam => originalParam.id === newParam.id)
    if (!foundParameter || foundParameter.value !== newParam.value) {
      deltaConfig.parameters.push(newParam)
    }
  })

  originalSignals.forEach(originalSignal => {
    const foundSignal = newSignals.find(newSignal => newSignal.id === originalSignal.id)
    if (!foundSignal && originalSignal.value !== 0) {
      deltaConfig.parameters.push({
        id: originalSignal.id,
        value: 0
      })
      deltaConfig.virtualParameters.push(
        {
          id: originalSignal.id,
          name: null
        },
        {
          id: originalSignal.id,
          unit: null
        }
      )
    }
  })

  originalSendCANMessages.forEach(originalSendCANMessage => {
    const foundSendCANMessage = newSendCANMessages.find(
      newSendCANMessage => newSendCANMessage.id === originalSendCANMessage.id
    )
    if (!foundSendCANMessage && originalSendCANMessage.value !== 0) {
      deltaConfig.parameters.push({
        id: originalSendCANMessage.id,
        value: 0
      })
    }
  })

  newVirtualParameters.forEach(newVirtualParam => {
    const foundVirtualParameter = originalVirtualParameters.find(
      originalVirtualParam => originalVirtualParam.id === newVirtualParam.id
    )
    if (!foundVirtualParameter || !_isEqual(foundVirtualParameter, newVirtualParam)) {
      deltaConfig.virtualParameters.push(newVirtualParam)
    }
  })

  return deltaConfig
}

export const applyConfiguration = (
  configToApply,
  deviceEid,
  deviceName,
  formatMessage,
  groupId,
  setDeviceNonVolatileConfiguration,
  setState
) => {
  setState({
    isApplyConfigurationDialogButtonVisible: false,
    isDialogOpen: true,
    alertMessagesType: 'warning',
    alertMessagesTitle: deviceName + ' - ' + deviceEid,
    alertMessagesText: formatMessage(messages.applying)
  })

  setDeviceNonVolatileConfiguration(groupId, deviceEid, configToApply)
    .then(() => {
      setState({
        isApplyConfigurationDialogButtonVisible: false,
        alertMessagesType: 'success',
        isDialogOpen: true,
        alertMessagesTitle: deviceName + ' - ' + deviceEid,
        alertMessagesText: formatMessage(messages.configurationSuccessfullyApplied)
      })
    })
    .catch(response => {
      const { error } = { ...response }

      const isApplyConfigurationDialogButtonVisible = false
      const isDialogOpen = true
      const alertMessagesType = 'danger'
      const alertMessagesTitle = deviceName + ' - ' + deviceEid

      if (error.response) {
        const message = error.response.data && error.response.data.message ? ': ' + error.response.data.message : ''

        switch (error.response.status) {
          case 400:
            setState({
              isApplyConfigurationDialogButtonVisible,
              isDialogOpen,
              alertMessagesType,
              alertMessagesTitle,
              alertMessagesText:
                formatMessage(messages.error, { number: '400' }) +
                ': ' +
                formatMessage(messages.error400Message) +
                message
            })
            break
          case 401:
            setState({
              isApplyConfigurationDialogButtonVisible,
              isDialogOpen,
              alertMessagesType,
              alertMessagesTitle,
              alertMessagesText:
                formatMessage(messages.error, { number: '401' }) +
                ': ' +
                formatMessage(messages.error401Message) +
                message
            })
            break
          case 403:
            setState({
              isApplyConfigurationDialogButtonVisible,
              isDialogOpen,
              alertMessagesType,
              alertMessagesTitle,
              alertMessagesText:
                formatMessage(messages.error, { number: '403' }) +
                ': ' +
                formatMessage(messages.error403Message) +
                message
            })
            break
          case 404:
            setState({
              isApplyConfigurationDialogButtonVisible,
              isDialogOpen,
              alertMessagesType,
              alertMessagesTitle,
              alertMessagesText:
                formatMessage(messages.error, { number: '404' }) +
                ': ' +
                formatMessage(messages.error404Message) +
                message
            })
            break
          case 406:
            setState({
              isApplyConfigurationDialogButtonVisible,
              isDialogOpen,
              alertMessagesType,
              alertMessagesTitle,
              alertMessagesText:
                formatMessage(messages.error, { number: '406' }) +
                ': ' +
                formatMessage(messages.error406Message) +
                message
            })
            break
          case 409:
            setState({
              isApplyConfigurationDialogButtonVisible,
              isDialogOpen,
              alertMessagesType,
              alertMessagesTitle,
              alertMessagesText:
                formatMessage(messages.error, { number: '409' }) +
                ': ' +
                formatMessage(messages.error409Message) +
                message
            })
            break
          case 415:
            setState({
              isApplyConfigurationDialogButtonVisible,
              isDialogOpen,
              alertMessagesType,
              alertMessagesTitle,
              alertMessagesText:
                formatMessage(messages.error, { number: '415' }) +
                ': ' +
                formatMessage(messages.error415Message) +
                message
            })
            break
          case 422:
            setState({
              isApplyConfigurationDialogButtonVisible,
              isDialogOpen,
              alertMessagesType,
              alertMessagesTitle,
              alertMessagesText:
                formatMessage(messages.error, { number: '422' }) +
                ': ' +
                formatMessage(messages.error422Message) +
                message
            })
            break
          case 500:
            setState({
              isApplyConfigurationDialogButtonVisible,
              isDialogOpen,
              alertMessagesType,
              alertMessagesTitle,
              alertMessagesText:
                formatMessage(messages.error, { number: '500' }) +
                ': ' +
                formatMessage(messages.error500Message) +
                message
            })
            break
          default:
            setState({
              isApplyConfigurationDialogButtonVisible,
              isDialogOpen,
              alertMessagesType,
              alertMessagesTitle,
              alertMessagesText: formatMessage(messages.errorUndefinedMessage) + message
            })
        }
      } else {
        setState({
          isApplyConfigurationDialogButtonVisible,
          isDialogOpen,
          alertMessagesType,
          alertMessagesTitle,
          alertMessagesText: formatMessage(messages.errorUndefinedMessage)
        })
      }
    })
}
