import React from 'react'
import PropTypes from 'prop-types'
import _isEqual from 'lodash/isEqual'

import AccessTimeIcon from '@material-ui/icons/AccessTime'
import Button from '@material-ui/core/Button'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CloseIcon from '@material-ui/icons/Close'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import ErrorIcon from '@material-ui/icons/Error'
import IconButton from '@material-ui/core/IconButton'
import InfoIcon from '@material-ui/icons/Info'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

import { getSignalsWithAdaptedParameters, validationSchema } from 'modules/configurationsCS500/config'

import messages from './messages'

import { adapter } from 'utils/cs500'
import ReactUploadFile from 'utils/fileHandler/ReactUploadFile'

const styles = {
  button: {
    padding: '16px 24px 16px 24px'
  },
  divider: {
    margin: '32px 24px 0 24px'
  },
  subtitle: {
    width: '100%',
    padding: '32px 24px 0 24px',
    fontSize: 16,
    color: '#5E5E5E',
    fontWeight: 'bold'
  },
  text: {
    width: '100%',
    padding: '0 24px 0 24px',
    fontSize: 16,
    color: '#5E5E5E'
  },
  title: {
    fontSize: 20,
    padding: '16px 24px 0 24px'
  },
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid #dadde9',
    fontSize: 14
  }
}

const MODE_BROWSE_FILE = 'BROWSE FILE'
const MODE_UPDATE_CONFIG = 'UPDATE CONFIG'

class CS500ConfigurationDialog extends React.Component {
  constructor(props) {
    super(props)
    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage

    this.state = {
      alertMessageText: '',
      alertMessageTitle: '',
      alertMessageType: 'success',
      alertVisibility: 'hidden',
      dialogMode: MODE_BROWSE_FILE,
      importedConfig: null,
      isApplyButtonDisabled: true,
      isConfigFileLoaded: false,
      loadedFileName: ''
    }
  }

  componentDidMount = () => {
    const { csNodes } = this.props
    const newState = { ...this.state }

    csNodes.forEach(device => {
      newState['configUpdateAlertType|' + device.EID] = 'warning'
      newState['configUpdateStatusText|' + device.EID] = 'Waiting...'
    })

    this.setState({
      ...newState
    })
  }

  isJSON = data => {
    try {
      JSON.parse(data)
    } catch (e) {
      return false
    }
    return true
  }

  handleConfigurationUpdate = () => {
    const { getDeviceNonVolatileConfiguration, groupId, setDeviceNonVolatileConfiguration, csNodes } = this.props
    const { importedConfig } = this.state

    csNodes.forEach(device => {
      const deviceEid = device.EID

      this.setState({
        ['configUpdateStatusText|' + device.EID]: 'Applying configuration...',
        ['configUpdateAlertType|' + device.EID]: 'warning'
      })

      getDeviceNonVolatileConfiguration(groupId, deviceEid)
        .then(response => {
          const originalConfig = response.data

          const deltaConfig = this.getDeltaConfiguration(originalConfig, importedConfig)
          const isConfigToApplyEmpty = deltaConfig.parameters.length === 0 && deltaConfig.virtualParameters.length === 0

          if (isConfigToApplyEmpty) {
            this.setState({
              ['configUpdateStatusText|' + device.EID]: this.formatMessage(messages.sameConfig),
              ['configUpdateAlertType|' + device.EID]: 'info'
            })
          } else {
            const configToApply = {
              parameters: deltaConfig.parameters,
              virtualParameters: deltaConfig.virtualParameters
            }

            setDeviceNonVolatileConfiguration(groupId, deviceEid, configToApply)
              .then(() => {
                this.setState({
                  ['configUpdateStatusText|' + device.EID]: this.formatMessage(
                    messages.nvConfigurationUpdatedSuccessfully
                  ),
                  ['configUpdateAlertType|' + device.EID]: 'success'
                })
              })
              .catch(err => {
                this.setState({
                  applyingConfiguration: false,
                  dialogOpen: false
                })
                const { error } = { ...err }
                if (error.response) {
                  const message = error.response.data?.messages ? ': ' + error.response.data.messages : ''
                  switch (error.response.status) {
                    case 400:
                      this.setState({
                        ['configUpdateStatusText|' + device.EID]:
                          this.formatMessage(messages.error, { number: '400' }) +
                          ': ' +
                          this.formatMessage(messages.error400Message) +
                          message,
                        ['configUpdateAlertType|' + device.EID]: 'danger'
                      })
                      break
                    case 401:
                      this.setState({
                        ['configUpdateStatusText|' + device.EID]:
                          this.formatMessage(messages.error, { number: '401' }) +
                          ': ' +
                          this.formatMessage(messages.error401Message) +
                          message,
                        ['configUpdateAlertType|' + device.EID]: 'danger'
                      })
                      break
                    case 403:
                      this.setState({
                        ['configUpdateStatusText|' + device.EID]:
                          this.formatMessage(messages.error, { number: '403' }) +
                          ': ' +
                          this.formatMessage(messages.error403Message) +
                          message,
                        ['configUpdateAlertType|' + device.EID]: 'danger'
                      })
                      break
                    case 404:
                      this.setState({
                        ['configUpdateStatusText|' + device.EID]:
                          this.formatMessage(messages.error, { number: '404' }) +
                          ': ' +
                          this.formatMessage(messages.error404Message) +
                          message,
                        ['configUpdateAlertType|' + device.EID]: 'danger'
                      })
                      break
                    case 406:
                      this.setState({
                        ['configUpdateStatusText|' + device.EID]:
                          this.formatMessage(messages.error, { number: '406' }) +
                          ': ' +
                          this.formatMessage(messages.error406Message) +
                          message,
                        ['configUpdateAlertType|' + device.EID]: 'danger'
                      })
                      break
                    case 409:
                      this.setState({
                        ['configUpdateStatusText|' + device.EID]:
                          this.formatMessage(messages.error, { number: '409' }) +
                          ': ' +
                          this.formatMessage(messages.error409Message) +
                          message,
                        ['configUpdateAlertType|' + device.EID]: 'danger'
                      })
                      break
                    case 415:
                      this.setState({
                        ['configUpdateStatusText|' + device.EID]:
                          this.formatMessage(messages.error, { number: '415' }) +
                          ': ' +
                          this.formatMessage(messages.error415Message) +
                          message,
                        ['configUpdateAlertType|' + device.EID]: 'danger'
                      })
                      break
                    case 422:
                      this.setState({
                        ['configUpdateStatusText|' + device.EID]:
                          this.formatMessage(messages.error, { number: '422' }) +
                          ': ' +
                          this.formatMessage(messages.error422Message) +
                          message,
                        ['configUpdateAlertType|' + device.EID]: 'danger'
                      })
                      break
                    case 500:
                      this.setState({
                        ['configUpdateStatusText|' + device.EID]:
                          this.formatMessage(messages.error, { number: '500' }) +
                          ': ' +
                          this.formatMessage(messages.error500Message) +
                          message,
                        ['configUpdateAlertType|' + device.EID]: 'danger'
                      })
                      break
                    default:
                      this.setState({
                        ['configUpdateStatusText|' + device.EID]:
                          this.formatMessage(messages.errorUndefinedTitle) +
                          ': ' +
                          this.formatMessage(messages.errorUndefinedMessage) +
                          message,
                        ['configUpdateAlertType|' + device.EID]: 'danger'
                      })
                  }
                } else {
                  this.setState({
                    ['configUpdateStatusText|' + device.EID]:
                      this.formatMessage(messages.errorUndefinedTitle) +
                      ': ' +
                      this.formatMessage(messages.errorUndefinedMessage),
                    ['configUpdateAlertType|' + device.EID]: 'danger'
                  })
                }
              })
          }
        })
        .catch(response => {
          const { error } = { ...response }

          const errorText = error?.response?.data?.message ? error.response.data.message : ''
          this.setState({
            ['configUpdateAlertType|' + device.EID]: 'danger',
            ['configUpdateStatusText|' + device.EID]: errorText
          })
        })
    })
  }

  getDeltaConfiguration = (originalConfig, adaptedConfig) => {
    const newParameters = adaptedConfig.parameters
    const originalParameters = originalConfig.parameters
    const newVirtualParameters = adaptedConfig.virtualParameters || []
    const originalVirtualParameters = originalConfig.virtualParameters || []

    const newSignals = newParameters.filter(newSignal => newSignal.id.includes('SignalSource_NV'))
    const originalSignals = originalParameters.filter(originalSignal => originalSignal.id.includes('SignalSource_NV'))
    const newSendCANMessages = newParameters.filter(newParameter => newParameter.id.includes('SignalDest_NV'))
    const originalSendCANMessages = originalParameters.filter(originalParameter =>
      originalParameter.id.includes('SignalDest_NV')
    )

    const deltaConfig = {
      parameters: [],
      virtualParameters: []
    }
    newParameters.forEach(newParam => {
      const foundParameter = originalParameters.find(originalParam => originalParam.id === newParam.id)
      if (!foundParameter || foundParameter.value !== newParam.value) {
        deltaConfig.parameters.push(newParam)
      }
    })

    originalSignals.forEach(originalSignal => {
      const foundSignal = newSignals.find(newSignal => newSignal.id === originalSignal.id)
      if (!foundSignal && originalSignal.value !== 0) {
        deltaConfig.parameters.push({
          id: originalSignal.id,
          value: 0
        })
        deltaConfig.virtualParameters.push(
          {
            id: originalSignal.id,
            name: null
          },
          {
            id: originalSignal.id,
            unit: null
          }
        )
      }
    })

    originalSendCANMessages.forEach(originalSendCANMessage => {
      const foundSendCANMessage = newSendCANMessages.find(
        newSendCANMessage => newSendCANMessage.id === originalSendCANMessage.id
      )
      if (!foundSendCANMessage && originalSendCANMessage.value !== 0) {
        deltaConfig.parameters.push({
          id: originalSendCANMessage.id,
          value: 0
        })
      }
    })

    newVirtualParameters.forEach(newVirtualParam => {
      const foundVirtualParameter = originalVirtualParameters.find(
        originalVirtualParam => originalVirtualParam.id === newVirtualParam.id
      )
      if (!foundVirtualParameter || !_isEqual(foundVirtualParameter, newVirtualParam)) {
        deltaConfig.virtualParameters.push(newVirtualParam)
      }
    })

    return deltaConfig
  }

  changeDialogMode = mode => {
    this.setState({
      dialogMode: mode
    })
  }

  showConfigUpdateStatus = device => {
    const state = { ...this.state }

    const alertMessageText = state['configUpdateStatusText|' + device.EID]
    const alertMessageTitle = device.name + ' - ' + device.EID
    const alertMessageType = state['configUpdateAlertType|' + device.EID]
    const alertVisibility = ''

    return (
      <div key={device.EID} className='col-md-12' style={{ padding: '0 24px 10px 24px' }}>
        <div className={alertVisibility}>
          <div
            className={'alert alert-' + alertMessageType + ' alert-dismissible animated fadeIn'}
            style={{ marginBottom: 0 }}
          >
            {alertMessageTitle !== '' && (
              <h4 style={{ display: 'flex', alignItems: 'flex-start', margin: 0 }}>
                {alertMessageType === 'success' && <CheckCircleIcon style={{ marginRight: 10 }} />}
                {alertMessageType === 'info' && <InfoIcon style={{ marginRight: 10 }} />}
                {alertMessageType === 'danger' && <ErrorIcon style={{ marginRight: 10 }} />}
                {alertMessageType === 'warning' && <AccessTimeIcon style={{ marginRight: 10 }} />}
                {alertMessageTitle}
              </h4>
            )}
            {alertMessageText !== '' && <h5 style={{ margin: '0 0 0 35px' }}>{alertMessageText}</h5>}
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { action, canApplyConfiguration, classes, csNodes, handleClose, intl } = this.props
    const {
      alertMessageText,
      alertMessageTitle,
      alertMessageType,
      alertVisibility,
      dialogMode,
      isApplyButtonDisabled,
      isConfigFileLoaded,
      loadedFileName
    } = this.state

    const uploadButtonOptions = {
      baseUrl: process.env.REACT_APP_HTTP_API,
      dataType: 'json',
      multiple: false,
      numberLimit: 1,
      accept: '.json',
      fileFieldName: 'file',

      beforeUpload: files => {
        if (typeof files === 'string') {
          return true
        }
        if (files[0].size > 0) {
          return true
        }
        return false
      },
      didLoad: pcFile => {
        const reader = new FileReader()
        reader.readAsText(pcFile)
        reader.onload = () => {
          if (this.isJSON(reader.result)) {
            const jsonFile = JSON.parse(reader.result)
            try {
              const adaptedNonVolatileConfiguration = adapter.cs500ConfigToCs100Config(jsonFile)
              // Modify multiplier and offset
              const adaptedSignals = getSignalsWithAdaptedParameters(adaptedNonVolatileConfiguration.signals)
              const fixedAndAdaptedNonVolatileConfiguration = {
                ...adaptedNonVolatileConfiguration,
                signals: adaptedSignals
              }

              validationSchema()
                .validate(fixedAndAdaptedNonVolatileConfiguration, {
                  abortEarly: false,
                  context: {
                    loopTime: fixedAndAdaptedNonVolatileConfiguration.loopTime,
                    signalsToLog: fixedAndAdaptedNonVolatileConfiguration.signals.length,
                    // logPeriods: fixedAndAdaptedNonVolatileConfiguration.signals.map(s => parseInt(s.samplePeriod)),
                    // triggerLogPeriods: fixedAndAdaptedNonVolatileConfiguration.signals.map(s =>
                    //   parseInt(s.samplePeriodTriggered)
                    // ),
                    configuredSignals: fixedAndAdaptedNonVolatileConfiguration.signals.map(s => s.signalId)
                  }
                })
                .then(() => {
                  this.setState({
                    isConfigFileLoaded: true,
                    loadedFileName: pcFile.name,
                    importedConfig: jsonFile,
                    isApplyButtonDisabled: false,
                    alertVisibility: '',
                    alertMessageType: 'success',
                    alertMessageTitle: this.formatMessage(messages.validConfigFile),
                    alertMessageText: ''
                  })
                })
                .catch(error => {
                  const errorText = []
                  let i = 0
                  error.inner.forEach(e => {
                    errorText[i] = '· ' + e.message
                    i++
                    errorText[i] = <br />
                    i++
                  })
                  this.setState({
                    isConfigFileLoaded: true,
                    loadedFileName: pcFile.name,
                    importedConfig: null,
                    isApplyButtonDisabled: true,
                    alertVisibility: '',
                    alertMessageType: 'danger',
                    alertMessageTitle: this.formatMessage(messages.invalidConfigFile),
                    alertMessageText: errorText
                  })
                })
            } catch (error) {
              this.setState({
                isConfigFileLoaded: true,
                loadedFileName: pcFile.name,
                importedConfig: null,
                isApplyButtonDisabled: true,
                alertVisibility: '',
                alertMessageType: 'danger',
                alertMessageTitle: this.formatMessage(messages.invalidConfigFile),
                alertMessageText: this.formatMessage(messages.invalidCS500config)
              })
            }
          } else {
            this.setState({
              isConfigFileLoaded: true,
              loadedFileName: pcFile.name,
              importedConfig: null,
              isApplyButtonDisabled: true,
              alertVisibility: '',
              alertMessageType: 'danger',
              alertMessageTitle: this.formatMessage(messages.invalidConfigFile),
              alertMessageText: this.formatMessage(messages.mustBeJson)
            })
          }
        }
      }
    }

    return (
      <React.Fragment>
        <DialogTitle classes={{ root: classes.title }}>
          {action.description}
          <IconButton
            onClick={handleClose}
            style={{
              position: 'absolute',
              right: 3,
              top: 3,
              padding: 5
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Typography style={styles.subtitle}>{this.formatMessage(messages.devicesToUpdate)}</Typography>
        {csNodes.map(device => (
          <Typography key={device.eid} style={styles.text}>
            {device.name + ' - ' + device.EID}
          </Typography>
        ))}
        <Divider style={styles.divider} />
        {dialogMode === MODE_BROWSE_FILE && (
          <React.Fragment>
            <Typography style={styles.subtitle}>{this.formatMessage(messages.selectFileFromPC)}</Typography>
            {alertVisibility === '' && (
              <div className='col-md-12' style={{ padding: '16px 24px 0 24px' }}>
                <div className={alertVisibility}>
                  <div
                    className={'alert alert-' + alertMessageType + ' alert-dismissible animated fadeIn'}
                    style={{ marginBottom: 0 }}
                  >
                    {alertMessageTitle !== '' && (
                      <h4
                        style={{
                          display: 'flex',
                          alignItems: alertMessageType === 'success' ? 'end' : 'flex-start',
                          margin: 0
                        }}
                      >
                        {alertMessageType === 'success' && <CheckCircleIcon style={{ marginRight: 10 }} />}
                        {alertMessageType === 'danger' && <ErrorIcon style={{ marginRight: 10 }} />}
                        {alertMessageTitle}
                      </h4>
                    )}
                    {alertMessageText !== '' && <h5 style={{ margin: '0 0 0 35px' }}>{alertMessageText}</h5>}
                  </div>
                </div>
              </div>
            )}
            <div className='col-md-12' style={styles.button}>
              <ReactUploadFile
                chooseFileButton={
                  <Button className='secondary-action-button'>{this.formatMessage(messages.browse)}</Button>
                }
                intl={intl}
                options={uploadButtonOptions}
              />
            </div>
            {isConfigFileLoaded && (
              <div style={styles.text}>
                <DialogContentText color='textPrimary'>{this.formatMessage(messages.loadedFile)}</DialogContentText>
                <DialogContentText color='textPrimary' style={{ paddingBottom: 12 }}>
                  {loadedFileName}
                </DialogContentText>
              </div>
            )}
            <DialogActions>
              <Tooltip
                classes={{ tooltip: classes.tooltip }}
                placement='top'
                title={!canApplyConfiguration ? this.formatMessage(messages.notAllowedToApplyConfiguration) : ''}
              >
                <div style={{ padding: 0, display: 'inline-flex' }}>
                  <Button
                    className='primary-action-button'
                    disabled={isApplyButtonDisabled || !canApplyConfiguration}
                    onClick={() => {
                      this.changeDialogMode(MODE_UPDATE_CONFIG)
                      this.handleConfigurationUpdate()
                    }}
                  >
                    {this.formatMessage(messages.apply)}
                  </Button>
                </div>
              </Tooltip>
            </DialogActions>
          </React.Fragment>
        )}
        {dialogMode === MODE_UPDATE_CONFIG && (
          <React.Fragment>
            <Typography style={styles.subtitle}>{this.formatMessage(messages.selectFile)}</Typography>
            <Typography style={styles.text}>{loadedFileName}</Typography>
            <Divider style={styles.divider} />
            <div style={{ paddingTop: 32, paddingBottom: 32 }}>
              {csNodes.map(device => this.showConfigUpdateStatus(device))}
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }
}

CS500ConfigurationDialog.propTypes = {
  action: PropTypes.object.isRequired,
  canApplyConfiguration: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  csNodes: PropTypes.array.isRequired,
  getDeviceNonVolatileConfiguration: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  setDeviceNonVolatileConfiguration: PropTypes.func.isRequired
}

export default withStyles(styles)(CS500ConfigurationDialog)
