import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Alert from 'components/Alert'
import Loading from 'components/Loading'
import PageTitle from 'components/PageTitle'
import { mapGroupProperties, mapToHierarchicalGroups } from 'components/groups/utils'
import { logError } from 'utils/http'

import AssignMachinesToGroupTable from './AssignMachinesToGroupTable'
import messages from './messages'

class AssignMachinesToGroup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      group: {},
      loading: true,
      alertMessages: false,
      alertMessagesType: '',
      alertMessagesTitle: '',
      alertMessagesText: [''],
      groups: [],
      groupsHierachy: []
    }

    const {
      intl: { formatMessage }
    } = props
    this.formatMessage = formatMessage
  }

  componentDidMount() {
    const { groupsLoaded } = this.props
    if (groupsLoaded) this.getGroup()
  }

  componentDidUpdate(prevProps) {
    const { groupsLoaded } = this.props
    if (!prevProps.groupsLoaded && groupsLoaded) this.getGroup()
  }

  getGroup = () => {
    const {
      getAllGroups,
      getGroup,
      groupId,
      intl: { locale }
    } = this.props

    getAllGroups()
      .then(response => {
        let groups = []
        let groupsHierachy = []
        let group = {}
        const currentGroup = getGroup(groupId)
        const responseGroups = response.data.group
        const { parent_id } = responseGroups.find(responseGroup => responseGroup.id === currentGroup.id)
        const danfossDefaultGroup = responseGroups.find(
          responseGroup => responseGroup.group_name.toUpperCase() === 'DANFOSS DEFAULT GROUP'
        )
        let parentGroups = []
        if (parent_id === danfossDefaultGroup.parent_id) parentGroups = [danfossDefaultGroup]
        if (parentGroups.length === 0) {
          parentGroups = responseGroups.filter(responseGroup => responseGroup.id === parent_id)
        }
        groups = mapGroupProperties(parentGroups)
        groupsHierachy = mapToHierarchicalGroups(parentGroups)
        group = { description: currentGroup.name }

        if (groupsHierachy.length === 0) {
          this.setState({
            group,
            groups,
            groupsHierachy,
            loading: false,
            alertMessages: true,
            alertMessagesType: 'danger',
            alertMessagesTitle: this.formatMessage(messages.errorNoParentGroupTitle),
            alertMessagesText: [this.formatMessage(messages.errorNoParentGroupMessage)]
          })
        } else {
          this.setState({
            group,
            groups,
            groupsHierachy,
            loading: false,
            alertMessages: false,
            alertMessagesType: '',
            alertMessagesTitle: '',
            alertMessagesText: ['']
          })
        }
      })
      .catch(response => {
        const error = { ...response }
        switch (error.response.status) {
          case 400: // Bad request
            this.setState({
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '400' }),
              alertMessagesText: [this.formatMessage(messages.error400Message)]
            })
            break
          case 401: // Invalid credentials
            const message = locale === 'en' ? error.response.message : this.formatMessage(messages.error401Message)
            this.setState({
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '401' }),
              alertMessagesText: [message]
            })
            break
          case 403: // Access denied
            this.setState({
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '403' }),
              alertMessagesText: [this.formatMessage(messages.error403Message)]
            })
            break
          case 404: // API url not found
            this.setState({
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '404' }),
              alertMessagesText: [this.formatMessage(messages.error404Message)]
            })
            break
          case 406: // Not acceptable
            this.setState({
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '406' }),
              alertMessagesText: [this.formatMessage(messages.error406Message)]
            })
            break
          case 500: // Unexpected error
            this.setState({
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.error, { number: '500' }),
              alertMessagesText: [this.formatMessage(messages.error500Message)]
            })
            break
          default:
            this.setState({
              loading: false,
              alertMessages: true,
              alertMessagesType: 'danger',
              alertMessagesTitle: this.formatMessage(messages.errorUndefinedTitle),
              alertMessagesText: [this.formatMessage(messages.errorUndefinedMessage)]
            })
            logError(response)
        }
      })
  }

  setAssignMachinesToGroupTitle = description => {
    if (description) {
      const title = 'assignMachinesToGroup'
      return this.formatMessage(messages[title]) + ` "${description}"`
    } else {
      return '...'
    }
  }

  closeAlert = () => {
    const { groupsHierachy } = this.state

    if (groupsHierachy.length === 0) {
      const { onCloseNoParentGroupErrorAlert } = this.props
      onCloseNoParentGroupErrorAlert()
    } else {
      this.setState({
        alertMessages: false,
        alertMessagesType: '',
        alertMessagesTitle: '',
        alertMessagesText: ['']
      })
    }
  }

  setAlert = alertParams => {
    this.setState(alertParams)
  }

  renderTableLoadingAndError = () => {
    const { alertMessages, alertMessagesText, alertMessagesTitle, alertMessagesType, loading } = this.state

    if (loading) {
      return <Loading />
    } else if (alertMessages) {
      return (
        <Alert
          alertType={alertMessagesType}
          closeFunction={this.closeAlert}
          messageText={alertMessagesText}
          messageTitle={alertMessagesTitle}
          showAlert={alertMessages}
        />
      )
    }
  }

  renderTablecontent = () => {
    const { addDevicesToGroup, getGroupDevices, groupId, onGroupEdited } = this.props
    const { alertMessages, groups, groupsHierachy, loading } = this.state

    if (loading || alertMessages) {
      return <div className='container-fluid'>{this.renderTableLoadingAndError()}</div>
    } else {
      return (
        <AssignMachinesToGroupTable
          addDevicesToGroup={addDevicesToGroup}
          getGroupDevices={getGroupDevices}
          groupId={groupId}
          groups={groups}
          groupsHierarchy={groupsHierachy}
          onGroupEdited={onGroupEdited}
          setAlert={this.setAlert}
        />
      )
    }
  }

  render() {
    const { groupEditUrl } = this.props
    const { group } = this.state

    return (
      <div className='content-container' id='content'>
        <div style={{ margin: '20px' }}>
          <div className='container-fluid'>
            <PageTitle back={groupEditUrl} title={this.setAssignMachinesToGroupTitle(group.description)} />
          </div>
          {this.renderTablecontent()}
        </div>
      </div>
    )
  }
}

AssignMachinesToGroup.propTypes = {
  addDevicesToGroup: PropTypes.func.isRequired,
  getAllGroups: PropTypes.func.isRequired,
  getGroup: PropTypes.func.isRequired,
  getGroupDevices: PropTypes.func.isRequired,
  groupEditUrl: PropTypes.string,
  groupId: PropTypes.string,
  groupsLoaded: PropTypes.bool.isRequired,
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired, locale: PropTypes.string.isRequired }).isRequired,
  onCloseNoParentGroupErrorAlert: PropTypes.func.isRequired,
  onGroupEdited: PropTypes.func.isRequired
}

AssignMachinesToGroup.defaultProps = {
  groupEditUrl: '',
  groupId: ''
}

export default injectIntl(AssignMachinesToGroup)
