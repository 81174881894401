import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import { utils as groupUtils, actions as groupActions } from 'ducks/groups'
import { utils, actions } from 'ducks/login'

import ProfileDropdown from './ProfileDropdown'

const mapStateToProps = (state, { history }) => ({
  user: utils.getLoggedUser(state),
  groups: groupUtils.getUserGroups(state),
  selectedGroup: groupUtils.getSelectedGroup(state),
  disabled:
    history.location.pathname.includes('/configurations/') &&
      !history.location.pathname.includes('/configurations/configuration') ||
    history.location.pathname.includes('/nodeDetails') ||
    history.location.pathname.includes('/dashboards') ||
    history.location.pathname.includes('/roles/') && !history.location.pathname.includes('/roles/role') ||
    history.location.pathname.includes('/users/') && !history.location.pathname.includes('/users/user') ||
    history.location.pathname.includes('/notificationsRulesSettings/') ||
    history.location.pathname.includes('/groups/') ||
    history.location.pathname.includes('/maintenances/')
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      unauthorize: actions.unauthorize,
      changeGroup: groupActions.changeGroup
    },
    dispatch
  )

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfileDropdown))
