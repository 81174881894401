import { defineMessages } from 'react-intl'

const messages = defineMessages({
  error: {
    id: 'MFIO.error',
    description: 'Error icon tooltip',
    defaultMessage: 'Error'
  },
  deleteSignal: {
    id: 'MFIO.deleteSignal',
    description: 'Delete signal icon tooltip',
    defaultMessage: 'Delete signal'
  },
  open: {
    id: 'MFIO.open',
    description: 'Open icon tooltip',
    defaultMessage: 'Open'
  },
  close: {
    id: 'MFIO.close',
    description: 'Close icon tooltip',
    defaultMessage: 'Close'
  },
  pinFunction: {
    id: 'MFIO.pinFunction',
    description: 'Pin function',
    defaultMessage: 'Pin function'
  },
  name: {
    id: 'MFIO.name',
    description: 'name',
    defaultMessage: 'Name'
  },
  logCycle: {
    id: 'MFIO.logCycle',
    description: 'Log cycle',
    defaultMessage: 'Time interval (s)'
  },
  inputSignal: {
    id: 'MFIO.inputSignal',
    description: 'Input signal',
    defaultMessage: 'Input signal'
  },
  inputRange: {
    id: 'MFIO.inputRange',
    description: 'Input range',
    defaultMessage: 'Input range'
  },
  bias: {
    id: 'MFIO.bias',
    description: 'Bias',
    defaultMessage: 'Bias'
  },
  digThreshHigh: {
    id: 'MFIO.digThreshHigh',
    description: 'Digital threshold high',
    defaultMessage: 'Digital threshold high (mV)'
  },
  digThreshLow: {
    id: 'MFIO.digThreshLow',
    description: 'Digital threshold low',
    defaultMessage: 'Digital threshold low (mV)'
  },
  unit: {
    id: 'MFIO.unit',
    description: 'Unit',
    defaultMessage: 'Unit'
  },
  offset: {
    id: 'MFIO.offset',
    description: 'Offset',
    defaultMessage: 'Offset'
  },
  multiplier: {
    id: 'MFIO.multiplier',
    description: 'Multiplier',
    defaultMessage: 'Multiplier'
  },
  decimals: {
    id: 'MFIO.decimals',
    description: 'Decimals',
    defaultMessage: 'Decimals'
  },
  defaultPowerUp: {
    id: 'MFIO.defaultPowerUp',
    description: 'Default power up',
    defaultMessage: 'Default power up'
  },
  powerManagementEnable: {
    id: 'MFIO.powerManagementEnable',
    description: 'Power management checkbox label',
    defaultMessage: 'Enable power management'
  },
  wakeUpFlagEnable: {
    id: 'MFIO.wakeUpFlagEnable',
    description: 'Power management checkbox label',
    defaultMessage: 'Enable cyclic wakeup'
  },
  cycleTimeDelay: {
    id: 'MFIO.cycleTimeDelay',
    description: 'Cycle time delay label',
    defaultMessage: 'Cycle time delay (s)'
  },
  shutdownDelay: {
    id: 'MFIO.shutdownDelay',
    description: 'Shutdown delay label',
    defaultMessage: 'Shutdown delay (s)'
  },
  typeOfValue: {
    id: 'MFIO.typeOfValue',
    description: 'Type of value',
    defaultMessage: 'Type of value'
  },
  lastValue: {
    id: 'MFIO.lastValue',
    description: 'Last value',
    defaultMessage: 'Last value'
  },
  averageValue: {
    id: 'MFIO.averageValue',
    description: 'Average value',
    defaultMessage: 'Average value'
  },
  minValue: {
    id: 'MFIO.minValue',
    description: 'Minimum value',
    defaultMessage: 'Minimum value'
  },
  maxValue: {
    id: 'MFIO.maxValue',
    description: 'Maximum value',
    defaultMessage: 'Maximum value'
  }
})

export default messages