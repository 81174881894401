import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import DoneIcon from '@material-ui/icons/Done'
import EditIcon from '@material-ui/icons/Edit'

import SelectColumnsButton from 'components/SelectColumnsButton'
import { mapDynamicDevicesFilterObject } from 'utils/http'
import client from 'utils/http/client'

import AvailableAdvancedSignalsAlert from './AvailableAdvancedSignalsAlert'
import {
  getAdaptedAdvancedSignalsData,
  getAdaptedDetailedDevicesData,
  getAdaptedDynamicDevicesData
} from '../../apiAdapters'
import ServerErrorAlert from '../../components/alerts/ServerErrorAlert'
import { DEVICE_TYPES, MAINTENANCE_CONFIGURATION_MODES } from '../../constants'
import messages from '../../messages'
import { paperStyles } from '../../styles'
import { getTableOptions } from '../../tableHelpers'

const styles = {
  ...paperStyles
}

class MachinesSettingsStep extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isDevicesLoading: false,
      allDevicesData: [],
      isAssignedDevicesLoading: false,
      assignedDevicesData: [],
      isSelectedDevicesModified: false,
      selectedDevicesEids: [],

      isAdvancedSignalsLoading: false,
      advancedSignalsData: [],

      isApiStepFetchError: false,

      currentPage: 1,
      currentPageSize: 10,
      totalSize: 0,
      filter: {},
      sort: {},

      visibleColumns: {
        name: true,
        machineModel: true,
        machineType: true,
        machineBrand: false,
        machineSerialNumber: true,
        deviceType: true,
        eid: false,
        lastActivity: false,
        firmwareVersion: false,
        deviceConfiguration: true,
        deviceConfigurationVersion: false
      }
    }

    this.tableRef = React.createRef()
  }

  componentDidMount() {
    this.fetchAssignedDevicesData()
      .then(this.fetchAdvancedSignalsData)
      .then(() => {
        const { assignedDevicesEids, onAvailableAdvancedSignalsChange } = this.props
        const availableAdvancedSignals = this.getAvailableAdvancedSignals(assignedDevicesEids)
        onAvailableAdvancedSignalsChange(availableAdvancedSignals)
      })
  }

  componentDidUpdate(prevProps) {
    const { isSubStep } = this.props

    if (isSubStep && prevProps.isSubStep !== isSubStep) {
      this.tableRef.current.cleanSort()
      this.setState({ currentPage: 1, totalSize: 0, filter: {}, sort: {} }, () =>
        this.fetchAllDevicesData().then(this.fetchAdvancedSignalsData)
      )
    }
    if (!isSubStep && prevProps.isSubStep !== isSubStep) {
      this.setState({ currentPage: 1, totalSize: 0, filter: {}, sort: {} }, this.fetchAssignedDevicesData)
    }
  }

  fetchAllDevicesData = () => {
    const { fetchDynamicCSNodes, groupId } = this.props
    const { currentPage, currentPageSize, filter, sort } = this.state

    const offset = (currentPage - 1) * currentPageSize

    const deviceFields = {
      Device: [
        'id',
        'eid',
        'name',
        'device_type',
        'operating_time',
        'main_firmware_version',
        'machine_model',
        'machine_type',
        'machine_brand',
        'machine_serial_number'
      ],
      Configuration: ['id', 'name', 'version_number']
    }

    let sortColumnKey
    switch (sort.columnKey) {
      case 'name':
        sortColumnKey = 'name'
        break
      case 'deviceType':
        sortColumnKey = 'device_type'
        break
      case 'eid':
        sortColumnKey = 'eid'
        break
      case 'machineModel':
        sortColumnKey = 'machine_model'
        break
      case 'machineType':
        sortColumnKey = 'machine_type'
        break
      case 'machineBrand':
        sortColumnKey = 'machine_brand'
        break
      case 'machineSerialNumber':
        sortColumnKey = 'machine_serial_number'
        break
      case 'firmwareVersion':
        sortColumnKey = 'main_firmware_version'
        break
    }

    const adaptedFilter = Object.entries(filter).reduce((acc, [key, value]) => {
      let newKey
      switch (key) {
        case 'name':
          newKey = 'name'
          break
        case 'deviceType':
          newKey = 'device_type'
          break
        case 'eid':
          newKey = 'eid'
          break
        case 'configurationName':
          newKey = 'Configuration.name'
          break
        case 'machineModel':
          newKey = 'machine_model'
          break
        case 'machineType':
          newKey = 'machine_type'
          break
        case 'machineBrand':
          newKey = 'machine_brand'
          break
        case 'machineSerialNumber':
          newKey = 'machine_serial_number'
          break
        case 'firmwareVersion':
          newKey = 'main_firmware_version'
          break
      }

      return {
        ...acc,
        [newKey]: value
      }
    }, {})

    const mappedFilters = mapDynamicDevicesFilterObject(adaptedFilter, {
      sortColumnKey,
      sortColumnOrder: sort.order?.toUpperCase(),
      sortColumnModel: 'Device'
    })

    this.setState({ isDevicesLoading: true, allDevicesData: [], isApiStepFetchError: false })
    return fetchDynamicCSNodes(groupId, currentPageSize, offset, deviceFields, mappedFilters)
      .then(response => {
        const allDevicesData = getAdaptedDynamicDevicesData(response.data.devices)
        const totalNumberOfDevices = response.data.total

        this.setState({ allDevicesData, totalSize: totalNumberOfDevices })
      })
      .catch(() => {
        this.setState({ isApiStepFetchError: true })
        return true
      })
      .finally((isApiStepFetchError = false) => {
        this.setState({ isDevicesLoading: false })
        return isApiStepFetchError
      })
  }

  fetchAssignedDevicesData = () => {
    const { fetchCSNodesDetails, groupId, assignedDevicesEids } = this.props

    if (assignedDevicesEids.length === 0) return Promise.resolve()

    this.setState({ isAssignedDevicesLoading: true, isApiStepFetchError: false })
    return fetchCSNodesDetails(groupId, assignedDevicesEids)
      .then(response => {
        const assignedDevicesData = getAdaptedDetailedDevicesData(response.data.devices)

        this.setState({ assignedDevicesData, currentPage: 1, totalSize: assignedDevicesData.length })
      })
      .catch(() => {
        this.setState({ isApiStepFetchError: true })
        return true
      })
      .finally((isApiStepFetchError = false) => {
        this.setState({ isAssignedDevicesLoading: false })
        return isApiStepFetchError
      })
  }

  fetchAdvancedSignalsData = (isApiStepFetchError = false) => {
    const { assignedDevicesEids, groupId, isSubStep } = this.props
    const { allDevicesData, selectedDevicesEids } = this.state

    if (isApiStepFetchError) return

    const devicesEids = isSubStep
      ? allDevicesData
        .map(device => device.eid)
        .concat(selectedDevicesEids)
        .filter((eid, index, self) => self.findIndex(item => item === eid) === index)
      : assignedDevicesEids

    if (devicesEids.length === 0) return Promise.resolve()

    this.setState({ isAdvancedSignalsLoading: true, isApiStepFetchError: false })

    return client
      .getMeasurements(groupId, devicesEids)
      .then(response => {
        const advancedSignalsData = getAdaptedAdvancedSignalsData(response.data).filter(
          (signal, index, self) => self.findIndex(item => item.hashId === signal.hashId) === index
        )

        this.setState({ advancedSignalsData })
      })
      .catch(() => {
        this.setState({ isApiStepFetchError: true })
      })
      .finally(() => {
        this.setState({ isAdvancedSignalsLoading: false })
      })
  }

  getTableOptions = () => {
    const { intl } = this.props
    const {
      currentPage,
      currentPageSize,
      filter,
      isAdvancedSignalsLoading,
      isAssignedDevicesLoading,
      isDevicesLoading,
      sort
    } = this.state

    return getTableOptions({
      intl,
      isLoading: isDevicesLoading || isAssignedDevicesLoading || isAdvancedSignalsLoading,
      page: currentPage,
      pageSize: currentPageSize,
      filter,

      sort: {
        sortName: sort.columnKey,
        sortOrder: sort.order
      },

      onPageChange: this.handlePageChange,
      onFilterChange: this.handleFilterChange,
      onSortChange: this.handleSortChange
    })
  }

  getAvailableAdvancedSignals = devicesEids => {
    const { advancedSignalConfigHashId, advancedSignalHashId, mode } = this.props
    const { advancedSignalsData } = this.state

    const currentDevicesAdvancedSignals = advancedSignalsData.filter(signal =>
      devicesEids.some(deviceEid => signal.devicesEids.includes(deviceEid))
    )

    let newAvailableAdvancedSignals = []

    if (mode === MAINTENANCE_CONFIGURATION_MODES.NEW_MAINTENANCE_CONFIGURATION) {
      newAvailableAdvancedSignals = devicesEids.reduce((advancedSignals, deviceEid, deviceIndex) => {
        const currentDeviceAdvancedSignals = currentDevicesAdvancedSignals.filter(signal =>
          signal.devicesEids.includes(deviceEid)
        )

        if (deviceIndex === 0) {
          return currentDeviceAdvancedSignals
        } else {
          const currentDeviceConfigHashIds = currentDeviceAdvancedSignals
            .map(signal => signal.configHashId)
            .filter((configHashId, index, self) => self.findIndex(item => item === configHashId) === index)
          return advancedSignals.filter(signal => currentDeviceConfigHashIds.includes(signal.configHashId))
        }
      }, [])
    } else if (mode === MAINTENANCE_CONFIGURATION_MODES.EDIT_MAINTENANCE_CONFIGURATION) {
      const currentAdvancedSignal = currentDevicesAdvancedSignals.find(signal => signal.hashId === advancedSignalHashId)

      if (currentAdvancedSignal) {
        const devicesAdvancedSingalsConfigHashIds = devicesEids.map(deviceEid => {
          const currentDeviceAdvancedSignals = currentDevicesAdvancedSignals.filter(signal =>
            signal.devicesEids.includes(deviceEid)
          )
          const currentDeviceConfigHashIds = currentDeviceAdvancedSignals
            .map(signal => signal.configHashId)
            .filter((configHashId, index, self) => self.findIndex(item => item === configHashId) === index)

          return currentDeviceConfigHashIds
        })

        newAvailableAdvancedSignals = devicesAdvancedSingalsConfigHashIds.every(advancedSingalsConfigHashIds =>
          advancedSingalsConfigHashIds.includes(advancedSignalConfigHashId)
        )
          ? [currentAdvancedSignal]
          : []
      } else {
        const replacementAdvancedSignal = currentDevicesAdvancedSignals.find(
          signal => signal.configHashId === advancedSignalConfigHashId
        )
        if (replacementAdvancedSignal) {
          newAvailableAdvancedSignals = [replacementAdvancedSignal]
        } else {
          newAvailableAdvancedSignals = []
        }
      }
    }

    return newAvailableAdvancedSignals
  }

  handleEditClick = () => {
    const { assignedDevicesEids, onSubStepEnterClick } = this.props

    this.setState({ selectedDevicesEids: [...assignedDevicesEids], isSelectedDevicesModified: false })
    onSubStepEnterClick()
  }

  handleClearSelectionClick = () => {
    const { onAvailableAdvancedSignalsChange } = this.props

    this.setState({ selectedDevicesEids: [], isSelectedDevicesModified: true })
    onAvailableAdvancedSignalsChange([])
  }

  handleOkClick = () => {
    const {
      advancedSignalConfigHashId,
      advancedSignalHashId,
      onAdvancedSignalChange,
      onAssignedDevicesEidsChange,
      onAvailableAdvancedSignalsChange,
      onSubStepExitClick
    } = this.props
    const { selectedDevicesEids } = this.state

    this.setState({ selectedDevicesEids: [] })
    onAssignedDevicesEidsChange([...selectedDevicesEids])

    const availableAdvancedSignals = this.getAvailableAdvancedSignals(selectedDevicesEids)
    onAvailableAdvancedSignalsChange(availableAdvancedSignals)

    const availableAdvancedSignalsIds = availableAdvancedSignals.map(signal => signal.hashId)
    if (!availableAdvancedSignalsIds.includes(advancedSignalHashId)) {
      const replacementAdvancedSignal = availableAdvancedSignals.find(
        signal => signal.configHashId === advancedSignalConfigHashId
      )
      if (replacementAdvancedSignal) {
        onAdvancedSignalChange(replacementAdvancedSignal)
      } else {
        onAdvancedSignalChange({})
      }
    }

    onSubStepExitClick()
  }

  handleCancelClick = () => {
    const { assignedDevicesEids, onAvailableAdvancedSignalsChange, onSubStepExitClick } = this.props

    this.setState({ selectedDevicesEids: [] })

    const availableAdvancedSignals = this.getAvailableAdvancedSignals(assignedDevicesEids)
    onAvailableAdvancedSignalsChange(availableAdvancedSignals)

    onSubStepExitClick()
  }

  handleRowSelect = (row, isSelected) => {
    const { onAvailableAdvancedSignalsChange } = this.props
    const { selectedDevicesEids } = this.state

    let newSelectedDevicesEids
    if (isSelected) {
      newSelectedDevicesEids = [...selectedDevicesEids, row.eid]
    } else {
      newSelectedDevicesEids = selectedDevicesEids.filter(eid => eid !== row.eid)
    }

    this.setState({ selectedDevicesEids: newSelectedDevicesEids, isSelectedDevicesModified: true })

    const availableAdvancedSignals = this.getAvailableAdvancedSignals(newSelectedDevicesEids)
    onAvailableAdvancedSignalsChange(availableAdvancedSignals)
  }

  handleAllRowsSelect = (isSelected, rows) => {
    const { onAvailableAdvancedSignalsChange } = this.props
    const { selectedDevicesEids } = this.state

    let newSelectedDevicesEids
    if (isSelected) {
      newSelectedDevicesEids = [...selectedDevicesEids, ...rows.map(row => row.eid)]
    } else {
      newSelectedDevicesEids = selectedDevicesEids.filter(eid => !rows.map(row => row.eid).includes(eid))
    }

    this.setState({ selectedDevicesEids: newSelectedDevicesEids, isSelectedDevicesModified: true })

    const availableAdvancedSignals = this.getAvailableAdvancedSignals(newSelectedDevicesEids)
    onAvailableAdvancedSignalsChange(availableAdvancedSignals)
  }

  handleSortChange = (columnKey, order) => {
    const { isSubStep } = this.props
    if (isSubStep) {
      this.setState({ sort: { columnKey, order } }, () =>
        this.fetchAllDevicesData().then(this.fetchAdvancedSignalsData)
      )
    }
  }

  handleFilterChange = filter => {
    const { isSubStep } = this.props
    if (isSubStep) {
      this.setState({ filter }, () => this.fetchAllDevicesData().then(this.fetchAdvancedSignalsData))
    }
  }

  handlePageChange = (page, pageSize) => {
    const { isSubStep } = this.props
    this.setState({ currentPage: page, currentPageSize: pageSize }, () => {
      if (isSubStep) {
        this.fetchAllDevicesData().then(this.fetchAdvancedSignalsData)
      }
    })
  }

  handleChangeColumnVisibility = columnId => {
    this.setState(state => {
      const updatedColumnVisibility = !state.visibleColumns[columnId]
      return {
        visibleColumns: {
          ...state.visibleColumns,
          [columnId]: updatedColumnVisibility
        }
      }
    })
  }

  formatMachineField = cell => {
    return cell ? cell : '-'
  }

  formatLastActivityColumn = cell => {
    return cell ? cell : '-'
  }

  formatActiveConfigurationColumn = cell => {
    return cell ? cell : '-'
  }

  formatActiveConfigurationVersionColumn = cell => {
    return cell ? cell : '-'
  }

  render() {
    const {
      availableAdvancedSignals,
      advancedSignalName,
      assignedDevicesEids,
      classes,
      mode,
      intl,
      isApiFetchError,
      isSubStep
    } = this.props
    const {
      allDevicesData,
      assignedDevicesData,
      isAdvancedSignalsLoading,
      isApiStepFetchError,
      isAssignedDevicesLoading,
      isDevicesLoading,
      isSelectedDevicesModified,
      selectedDevicesEids,
      totalSize,
      visibleColumns
    } = this.state

    const tableData = isSubStep ? allDevicesData : assignedDevicesData

    const tableOptions = this.getTableOptions()

    const isApiError = isApiFetchError || isApiStepFetchError
    const isOkButtonDisabled = selectedDevicesEids.length === 0 || availableAdvancedSignals.length === 0 || isApiError
    const isLoading = isDevicesLoading || isAssignedDevicesLoading || isAdvancedSignalsLoading

    return (
      <Grid item xs={12}>
        <Paper classes={{ root: classes.paperRoot }}>
          <Grid container spacing={3}>
            {isApiError && (
              <Grid item xs={12}>
                <ServerErrorAlert />
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography component='h2' variant='h5'>
                {intl.formatMessage(messages.machineSettings)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {intl.formatMessage(messages.compatibleMachinesClarification)}
              <br />
              {intl.formatMessage(messages.equivalentAdvancedSignalsClarification)}
            </Grid>
            <Grid item xs={12}>
              <Typography component='h3' variant='h6'>
                {intl.formatMessage(messages.assignedMachines)}
              </Typography>
            </Grid>
            <AvailableAdvancedSignalsAlert
              advancedSignalName={advancedSignalName}
              availableAdvancedSignals={availableAdvancedSignals}
              isSelectedDevicesModified={isSelectedDevicesModified}
              isSubStep={isSubStep}
              mode={mode}
              selectedDevicesEids={selectedDevicesEids}
            />
            <Grid container item xs={12}>
              <Grid container item spacing={1} xs={6}>
                {isSubStep && (
                  <Grid item>
                    <React.Fragment>
                      <Button disabled>
                        {intl.formatMessage(messages.selected)} ({selectedDevicesEids.length})
                      </Button>
                      <Button
                        className='secondary-action-button'
                        disabled={isApiError}
                        onClick={this.handleClearSelectionClick}
                      >
                        {intl.formatMessage(messages.clearSelection)}
                      </Button>
                    </React.Fragment>
                  </Grid>
                )}
                <Grid item>
                  <SelectColumnsButton
                    columns={visibleColumns}
                    onChangeColumnVisibility={this.handleChangeColumnVisibility}
                  />
                </Grid>
              </Grid>
              <Grid alignItems='center' container item justifyContent='flex-end' xs={6}>
                {isSubStep ? (
                  <React.Fragment>
                    <Button onClick={this.handleCancelClick}>{intl.formatMessage(messages.cancel)}</Button>
                    <Button
                      className='primary-action-button'
                      disabled={isOkButtonDisabled}
                      onClick={this.handleOkClick}
                      startIcon={<DoneIcon />}
                    >
                      {intl.formatMessage(messages.ok)}
                    </Button>
                  </React.Fragment>
                ) : (
                  <Button
                    className='primary-action-button'
                    disabled={isApiError}
                    onClick={this.handleEditClick}
                    startIcon={assignedDevicesEids.length === 0 ? <AddIcon /> : <EditIcon />}
                  >
                    {assignedDevicesEids.length === 0
                      ? intl.formatMessage(messages.assignMachines)
                      : intl.formatMessage(messages.edit)}
                  </Button>
                )}
              </Grid>
              <Grid item xs={12}>
                <div className='table-with-pagination' style={{ padding: 0 }}>
                  <BootstrapTable
                    ref={this.tableRef}
                    bordered={false}
                    data={isLoading ? [] : tableData}
                    fetchInfo={{ dataTotalSize: totalSize }}
                    hover
                    keyField='eid'
                    options={tableOptions}
                    pagination
                    remote={remoteObj => ({
                      ...remoteObj,
                      search: false,
                      pagination: true,
                      sizePerPage: true,
                      sort: true,
                      filter: true
                    })}
                    selectRow={{
                      ...isSubStep && {
                        mode: 'checkbox',
                        onSelect: this.handleRowSelect,
                        onSelectAll: this.handleAllRowsSelect,
                        bgColor: '#f5f5f5',
                        selected: selectedDevicesEids
                      }
                    }}
                  >
                    <TableHeaderColumn
                      dataField='name'
                      dataSort={isSubStep}
                      filter={{
                        ...isSubStep && {
                          type: 'TextFilter',
                          delay: 400,
                          placeholder: `${intl.formatMessage(messages.filterBy)} ${intl
                            .formatMessage(messages.name)
                            .toLowerCase()}`
                        }
                      }}
                      hidden={!visibleColumns.name}
                    >
                      {intl.formatMessage(messages.name)}
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField='machineModel'
                      dataFormat={this.formatMachineField}
                      dataSort={isSubStep}
                      filter={{
                        ...isSubStep && {
                          type: 'TextFilter',
                          delay: 400,
                          placeholder: `${intl.formatMessage(messages.filterBy)} ${intl
                            .formatMessage(messages.machineModel)
                            .toLowerCase()}`
                        }
                      }}
                      hidden={!visibleColumns.machineModel}
                    >
                      {intl.formatMessage(messages.machineModel)}
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField='machineType'
                      dataFormat={this.formatMachineField}
                      dataSort={isSubStep}
                      filter={{
                        ...isSubStep && {
                          type: 'TextFilter',
                          delay: 400,
                          placeholder: `${intl.formatMessage(messages.filterBy)} ${intl
                            .formatMessage(messages.machineType)
                            .toLowerCase()}`
                        }
                      }}
                      hidden={!visibleColumns.machineType}
                    >
                      {intl.formatMessage(messages.machineType)}
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField='machineBrand'
                      dataFormat={this.formatMachineField}
                      dataSort={isSubStep}
                      filter={{
                        ...isSubStep && {
                          type: 'TextFilter',
                          delay: 400,
                          placeholder: `${intl.formatMessage(messages.filterBy)} ${intl
                            .formatMessage(messages.machineBrand)
                            .toLowerCase()}`
                        }
                      }}
                      hidden={!visibleColumns.machineBrand}
                    >
                      {intl.formatMessage(messages.machineBrand)}
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField='machineSerialNumber'
                      dataFormat={this.formatMachineField}
                      dataSort={isSubStep}
                      filter={{
                        ...isSubStep && {
                          type: 'TextFilter',
                          delay: 400,
                          placeholder: `${intl.formatMessage(messages.filterBy)} ${intl
                            .formatMessage(messages.machineSerialNumber)
                            .toLowerCase()}`
                        }
                      }}
                      hidden={!visibleColumns.machineSerialNumber}
                    >
                      {intl.formatMessage(messages.machineSerialNumber)}
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField='deviceType'
                      dataSort={isSubStep}
                      filter={{
                        ...isSubStep && {
                          type: 'SelectFilter',
                          delay: 400,
                          options: DEVICE_TYPES,
                          placeholder: `${intl.formatMessage(messages.filterBy)} ${intl
                            .formatMessage(messages.deviceType)
                            .toLowerCase()}`
                        }
                      }}
                      hidden={!visibleColumns.deviceType}
                    >
                      {intl.formatMessage(messages.deviceType)}
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField='eid'
                      dataSort={isSubStep}
                      filter={{
                        ...isSubStep && {
                          type: 'TextFilter',
                          delay: 400,
                          placeholder: `${intl.formatMessage(messages.filterBy)} ${intl.formatMessage(messages.eid)}`
                        }
                      }}
                      hidden={!visibleColumns.eid}
                    >
                      {intl.formatMessage(messages.eid)}
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField='lastActivity'
                      dataFormat={this.formatLastActivityColumn}
                      hidden={!visibleColumns.lastActivity}
                    >
                      {intl.formatMessage(messages.lastActivity)}
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField='main_firmware_version'
                      dataSort={isSubStep}
                      filter={{
                        ...isSubStep && {
                          type: 'TextFilter',
                          delay: 400,
                          placeholder: `${intl.formatMessage(messages.filterBy)} ${intl.formatMessage(
                            messages.firmwareVersion
                          )}`
                        }
                      }}
                      hidden={!visibleColumns.firmwareVersion}
                    >
                      {intl.formatMessage(messages.firmwareVersion)}
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField='configurationName'
                      dataFormat={this.formatActiveConfigurationColumn}
                      filter={{
                        ...isSubStep && {
                          type: 'TextFilter',
                          delay: 400,
                          placeholder: `${intl.formatMessage(messages.filterBy)} ${intl
                            .formatMessage(messages.deviceConfiguration)
                            .toLowerCase()}`
                        }
                      }}
                      hidden={!visibleColumns.deviceConfiguration}
                    >
                      {intl.formatMessage(messages.deviceConfiguration)}
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField='configurationVersion'
                      dataFormat={this.formatActiveConfigurationVersionColumn}
                      hidden={!visibleColumns.deviceConfigurationVersion}
                    >
                      {intl.formatMessage(messages.deviceConfigurationVersion)}
                    </TableHeaderColumn>
                  </BootstrapTable>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    )
  }
}

MachinesSettingsStep.propTypes = {
  advancedSignalConfigHashId: PropTypes.string.isRequired,
  advancedSignalHashId: PropTypes.string.isRequired,
  advancedSignalName: PropTypes.string.isRequired,
  assignedDevicesEids: PropTypes.array.isRequired,
  availableAdvancedSignals: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  fetchCSNodesDetails: PropTypes.func.isRequired,
  fetchDynamicCSNodes: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  isApiFetchError: PropTypes.bool.isRequired,
  isSubStep: PropTypes.bool.isRequired,
  mode: PropTypes.oneOf([
    MAINTENANCE_CONFIGURATION_MODES.NEW_MAINTENANCE_CONFIGURATION,
    MAINTENANCE_CONFIGURATION_MODES.EDIT_MAINTENANCE_CONFIGURATION
  ]).isRequired,
  onAdvancedSignalChange: PropTypes.func.isRequired,
  onAssignedDevicesEidsChange: PropTypes.func.isRequired,
  onAvailableAdvancedSignalsChange: PropTypes.func.isRequired,
  onSubStepEnterClick: PropTypes.func.isRequired,
  onSubStepExitClick: PropTypes.func.isRequired
}

export default withStyles(styles)(injectIntl(MachinesSettingsStep))
