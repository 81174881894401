import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as map from 'ducks/map'
import { selectors as geotrackingSelectors } from 'ducks/geotracking'
import { createNodesLoader } from 'components/LoadNodesOnMount'
import Geotracking from './Geotracking'

const mapStateToProps = state => ({
  mergedAndOrderedGpsTrackings: geotrackingSelectors.getGPSTrackingsMergedAndOrdered(state)
})

const mapDispatchToProps = dispatchFunc =>
  bindActionCreators(
    {
      load: () => dispatch => {
        dispatch(map.actions.show())
      },
      unload: map.actions.hide
    },
    dispatchFunc
  )

export default connect(mapStateToProps, mapDispatchToProps)(createNodesLoader(Geotracking))
