import { defineMessages } from 'react-intl'

const messages = defineMessages({
  availableProtocols: {
    id: 'DbcFileImport.availableProtocols',
    description: 'Available protocols',
    defaultMessage: 'Available protocols:'
  },
  importDbc: {
    id: 'DbcFileImport.importDbc',
    description: 'Import DBC file',
    defaultMessage: 'Import DBC file'
  },
  selectDbcFileFromPC: {
    id: 'DbcFileImport.selectDbcFileFromPC',
    description: 'Select DBC file to import from PC:',
    defaultMessage: 'Select DBC file to import from PC:'
  },
  saveDbcFile: {
    id: 'DbcFileImport.saveDbcFile',
    description: 'Save DBC file',
    defaultMessage: 'Save DBC file'
  },
  cancel: {
    id: 'DbcFileImport.cancel',
    description: 'Cancel',
    defaultMessage: 'Cancel'
  },
  fileSuccessfullyLoaded: {
    id: 'DbcFileImport.fileSuccessfullyLoaded',
    description: 'DBC file successfully loaded',
    defaultMessage: 'DBC file successfully loaded.'
  },
  fileSuccessfullyUploaded: {
    id: 'DbcFileImport.fileSuccessfullyUploaded',
    description: 'DBC file processed and uploaded',
    defaultMessage: 'DBC file processed and uploaded.'
  },
  fileSuccessfullyDeleted: {
    id: 'DbcFileImport.fileSuccessfullyDeleted',
    description: 'DBC file deleted',
    defaultMessage: 'DBC file deleted.'
  },
  loadedFile: {
    id: 'DbcFileImport.loadedFile',
    description: 'Loaded file',
    defaultMessage: 'Loaded file:'
  },
  dbcFileName: {
    id: 'DbcFileImport.dbcFileName',
    description: 'DBC file name',
    defaultMessage: 'DBC file name'
  },
  saveDbcFileAs: {
    id: 'DbcFileImport.saveDbcFileAs',
    description: 'Save DBC file as',
    defaultMessage: 'Save DBC file as:'
  },
  thereAreNoAvailableProtocols: {
    id: 'DbcFileImport.thereAreNoAvailableProtocols',
    description: 'There are no available protocols',
    defaultMessage: 'There are no available protocols.'
  },
  invalidDbcFile: {
    id: 'DbcFileImport.invalidDbcFile',
    description: 'Invalid DBC file',
    defaultMessage: 'Invalid DBC file:'
  },
  emptyFile: {
    id: 'DbcFileImport.emptyFile',
    description: 'File cannot be empty',
    defaultMessage: 'File cannot be empty.'
  },
  noDbcFormat: {
    id: 'DbcFileImport.noDbcFormat',
    description: 'Select a file with \'.dbc\' extension',
    defaultMessage: 'Select a file with \'.dbc\' extension.'
  },
  addDbcFile: {
    id: 'DbcFileImport.addDbcFile',
    description: '+ Add DBC file',
    defaultMessage: '+ Add DBC file'
  },
  browse: {
    id: 'DbcFileImport.browse',
    description: 'Browse file',
    defaultMessage: 'Browse file'
  }
})

export default messages