import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import CancelIcon from '@material-ui/icons/Cancel'

import messages from './messages'

const styles = {
  image: {
    maxHeight: 50,
    maxWidth: '100%'
  },
  pictureLabel: {
    fontSize: 12,
    color: 'rgba(0, 0, 0, 0.5)',
    display: 'block'
  },
  browseButton: {
    color: '#FFFFFF',
    backgroundColor: '#9DA7AF',
    marginRight: 15,
    '&:hover': {
      backgroundColor: '#70767E'
    }
  }
}

class PictureEditor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.pictureInputRef = React.createRef()
  }

  handlePictureChange = event => {
    const { onChange } = this.props
    const [file = {}] = event.target.files
    const { name, type = '' } = file
    if (type.startsWith('image/')) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        const picture = reader.result
        onChange(picture, name)
      }
    }
  }

  handlePictureDelete = () => {
    const { onChange } = this.props
    this.pictureInputRef.current.value = null
    onChange('', '')
  }

  handlePictureBrowse = () => {
    this.pictureInputRef.current.click()
  }

  render() {
    const { picture, intl, label, name, classes } = this.props
    const { formatMessage } = intl
    const onlyField = picture === ''
    return (
      <Grid container>
        <Grid item xs={onlyField ? 12 : 9}>
          <input
            ref={this.pictureInputRef}
            accept='image/*'
            onChange={this.handlePictureChange}
            onClick={() => this.value = null}
            style={{ display: 'none' }}
            type='file'
          />
          <label className={classes.pictureLabel}>{label}</label>
          <Button classes={{ root: classes.browseButton }} onClick={this.handlePictureBrowse} size='small'>
            {formatMessage(messages.browseImage)}
          </Button>
          <label style={{ fontWeight: 'normal' }}>{name}</label>
        </Grid>
        {!onlyField && (
          <Grid item xs={3}>
            <img alt={formatMessage(messages.preview)} className={classes.image} src={picture} />
            <IconButton
              onClick={this.handlePictureDelete}
              style={{
                textAlign: 'right',
                position: 'absolute',
                zIndex: 1,
                width: '12px',
                height: '12px'
              }}
            >
              <CancelIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
    )
  }
}

PictureEditor.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  picture: PropTypes.string.isRequired
}

export default withStyles(styles)(injectIntl(PictureEditor))
