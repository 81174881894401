import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Chip from '@material-ui/core/Chip'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Step from '@material-ui/core/Step'
import StepConnector from '@material-ui/core/StepConnector'
import StepLabel from '@material-ui/core/StepLabel'
import Stepper from '@material-ui/core/Stepper'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

import { Map, Marker } from 'components/Map'
import { utcTimeToBrowserLocal } from 'utils/timeFormat'

import CrcErrorsTable from './CrcErrorsTable'
import DM1Table from './DM1Table'
import messages from './messages'

const styles = theme => ({
  dividerRoot: {
    marginLeft: -20,
    marginRight: -20
  },
  cardRoot: {
    height: '100%'
  },
  listItemRoot: {
    padding: 15
  },
  map: {
    width: '100%',
    minHeight: '400px'
  },
  cardSubheader: {
    fontSize: '1.8rem'
  },
  stepperRoot: {
    maxWidth: 650
  },
  stepLabel: {
    marginTop: '12px !important'
  },
  completedStepLabel: {
    fontWeight: 'normal !important'
  },
  completedStep: {
    color: '#009541 !important'
  },
  activeStep: {
    color: '#0077FF !important'
  },
  alternativeActiveStep: {
    color: '#E8912F !important'
  },
  line: {
    minWidth: '35x !important'
  },
  chipDiv: {
    marginTop: 16,
    marginLeft: 20
  },
  chipRoot: {
    height: 'fit-content',
    fontSize: '14px',
    marginRight: 10
  }
})

const defaultCenter = { latitude: 38.19394, longitude: 15.55256 }

const MachineDetails = ({ node, nodeLastLocation = {}, intl, classes, onOpenDialog, canConfigureAdvancedSignals }) => {
  const { formatMessage } = intl
  const { latitude, longitude } = nodeLastLocation
  const position = {
    latitude: latitude ? parseFloat(latitude) : undefined,
    longitude: longitude ? parseFloat(longitude) : undefined
  }

  const stepsCS500 = [
    formatMessage(messages.requestedStep),
    formatMessage(messages.downloadingStep),
    formatMessage(messages.manualRebootRequiredStep),
    formatMessage(messages.installingStep),
    formatMessage(messages.completedStep)
  ]

  const stepsCS100 = [
    formatMessage(messages.requestedStep),
    formatMessage(messages.downloadingStep),
    formatMessage(messages.installingStep),
    formatMessage(messages.completedStep)
  ]

  const steps = node.deviceType === 'CS100' ? stepsCS100 : stepsCS500

  const getStepper = activeStep => (
    <Stepper
      activeStep={activeStep}
      alternativeLabel
      classes={{ root: classes.stepperRoot }}
      connector={<StepConnector classes={{ root: classes.line }} />}
      style={{ paddingLeft: 0 }}
    >
      {steps.map((label, index) => (
        <Step key={label}>
          <StepLabel
            StepIconProps={{
              classes: {
                completed: classes.completedStep,
                active: index === 2 && node.deviceType !== 'CS100' ? classes.alternativeActiveStep : classes.activeStep
              }
            }}
            classes={{
              completed: classes.completedStepLabel,
              label: classes.stepLabel
            }}
          >
            {label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  )

  const getFirmwareStatus = device => {
    switch (device?.components?.mainFirmware?.status.toLowerCase()) {
      case 'disablingproxy':
      case 'updaterequested':
        return getStepper(0)
      case 'pending':
        return getStepper(0)
      case 'downloading':
        return getStepper(1)
      case 'installing':
        return getStepper(2)
      case 'provisioning':
      case 'rebooting':
        return getStepper(3)
      case 'active':
        const lastUpdate = device.components.mainFirmware.lastChangeTimestamp
        return (
          <div className={classes.chipDiv}>
            <Chip
              className={classes.chipRoot}
              label={formatMessage(messages.completedStep)}
              style={{ color: '#ffffff', backgroundColor: '#009541' }}
            />
            <span>{utcTimeToBrowserLocal(lastUpdate)}</span>
          </div>
        )
      case 'failed':
        const failedTime = device.components.mainFirmware.lastChangeTimestamp
        return (
          <div className={classes.chipDiv}>
            <Chip
              className={classes.chipRoot}
              label={formatMessage(messages.failedStep)}
              style={{ color: '#ffffff', backgroundColor: '#d9534f' }}
            />
            <span>{utcTimeToBrowserLocal(failedTime)}</span>
          </div>
        )
      default:
        return (
          <div className={classes.chipDiv}>
            <Chip
              className={classes.chipRoot}
              label={formatMessage(messages.notAttempted)}
              style={{ color: '#ffffff', backgroundColor: '#5e5e5e' }}
            />
          </div>
        )
    }
  }

  const firmwareStatus = getFirmwareStatus(node)
  const isFotaProcessVisible =
    node.deviceType === 'CS500' ||
    node.deviceType === 'CS100' &&
      node?.mainFirmwareVersion &&
      Math.round(node.mainFirmwareVersion.substr(0, 3)) >= 180

  return (
    <div className='container-fluid'>
      <Grid container spacing={2}>
        <Grid container item md={6} spacing={2} xs={12}>
          <Grid item xs={12}>
            <Card classes={{ root: classes.cardRoot }} style={{ borderRadius: 0 }}>
              <CardHeader title={formatMessage(messages.machineInformation)} />
              <CardContent>
                <Typography>
                  <label style={{ width: '25%', minWidth: '100px' }}>{formatMessage(messages.name)}</label>
                  {node.name}
                </Typography>
                <Typography>
                  <label style={{ width: '25%', minWidth: '100px' }}>{formatMessage(messages.model)}</label>
                  {node.machineModel || '-'}
                </Typography>
                <Typography>
                  <label style={{ width: '25%', minWidth: '100px' }}>{formatMessage(messages.machineType)}</label>
                  {node.machineType || '-'}
                </Typography>
                <Typography>
                  <label style={{ width: '25%', minWidth: '100px' }}>{formatMessage(messages.brand)}</label>
                  {node.machineBrand || '-'}
                </Typography>
                <Typography>
                  <label style={{ width: '25%', minWidth: '100px' }}>
                    {formatMessage(messages.machineSerialNumber)}
                  </label>
                  {node.machineSerialNumber || '-'}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card classes={{ root: classes.cardRoot }} style={{ borderRadius: 0 }}>
              <CardHeader title={formatMessage(messages.nodeInformation)} />
              <CardContent>
                <Typography>
                  <label style={{ width: '20%', minWidth: '100px' }}>EID</label>
                  {node.EID}
                </Typography>
                <Typography>
                  <label style={{ width: '20%', minWidth: '100px' }}>{formatMessage(messages.deviceTypeLabel)}</label>
                  {node.deviceType}
                </Typography>
                <Typography>
                  <label style={{ width: '20%', minWidth: '100px' }}>{formatMessage(messages.configuration)}</label>
                  {node.deviceConfiguration !== null
                    ? node.deviceConfiguration.name + ' v' + node.deviceConfiguration.versionNumber
                    : '--'}
                </Typography>
                <Typography>
                  <label style={{ width: '20%', minWidth: '100px' }}>{formatMessage(messages.lastMessageDate)}</label>
                  {node.lastActivityTime !== null ? utcTimeToBrowserLocal(node.lastActivityTime) : '--'}
                </Typography>
                <Typography>
                  <label style={{ width: '20%', minWidth: '100px' }}>{formatMessage(messages.deviceStatus)}</label>
                  {node.deviceStatus !== null ? node.deviceStatus : '--'}
                </Typography>
              </CardContent>
              <Divider />
              <CardHeader
                style={{ paddingBottom: 12 }}
                title={formatMessage(messages.firmware)}
                titleTypographyProps={{ classes: { root: classes.cardSubheader } }}
              />
              <CardContent>
                <Typography>
                  <label style={{ width: '20%', minWidth: '100px' }}>
                    {formatMessage(messages.radioFirmwareLabel)}
                  </label>
                  {node.cellFirmwareVersion !== null ? node.cellFirmwareVersion : '--'}
                </Typography>
                <Typography>
                  <label style={{ width: '20%', minWidth: '100px' }}>
                    {formatMessage(messages.deviceFirmwareLabel)}
                  </label>
                  {node.mainFirmwareVersion !== null ? node.mainFirmwareVersion : '--'}
                </Typography>
                {isFotaProcessVisible && (
                  <Typography>
                    <Grid container item xs={12}>
                      <Grid item sm={2} style={{ marginTop: 12 }} xs={12}>
                        <label>{formatMessage(messages.remoteFirmwareUpdateStatus)}</label>
                      </Grid>
                      <Grid item sm={10} xs={12}>
                        {firmwareStatus}
                      </Grid>
                    </Grid>
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card classes={{ root: classes.cardRoot }} style={{ borderRadius: 0 }}>
              <CardHeader title={formatMessage(messages.dataPlanInformation)} />
              <CardContent>
                <Typography>
                  <label style={{ width: '50px' }}>IMEI</label> {node.simCard.IMEI || '--'}
                </Typography>
                <Typography>
                  <label style={{ width: '50px' }}>ICCID</label> {node.simCard.ICCID || '--'}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          {node.deviceType !== 'CS10' && canConfigureAdvancedSignals && (
            <Grid item xs={12}>
              <Card classes={{ root: classes.cardRoot }} style={{ borderRadius: 0 }}>
                <CardHeader title={formatMessage(messages.configurationOfAdvancedSignals)} />
                <CardContent>
                  <Grid container>
                    <Grid item sm={4} xs={6}>
                      <Typography>{formatMessage(messages.configureYourSignals)}</Typography>
                    </Grid>
                    <Grid item sm={8} xs={6}>
                      <Button
                        className='secondary-action-button'
                        onClick={() => onOpenDialog(node.EID, node.deviceType, node.name)}
                      >
                        {formatMessage(messages.openConfiguration)}
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
        <Grid classes={{ item: classes.map }} item md={6} xs={12}>
          <Map
            bounds={undefined}
            center={{ latitude: position.latitude, longitude: position.longitude }}
            defaultCenter={{ latitude: defaultCenter.latitude, longitude: defaultCenter.longitude }}
            fitBounds={undefined}
            options={{
              scaleControl: false,
              mapTypeControl: true,
              panControl: true,
              zoomControl: true,
              streetViewControl: false
            }}
            sat
            zoom={position.latitude && position.longitude ? 7 : 0.7}
          >
            {latitude && longitude && <Marker key={node.id} label={node.name} name={node.id} position={position} />}
          </Map>
        </Grid>
        <Grid classes={{ item: classes.map }} item md={12} xs={12}>
          {node.deviceType === 'CS100' && (
            <Card classes={{ root: classes.cardRoot }} style={{ borderRadius: 0 }}>
              <CardHeader title={formatMessage(messages.crcErrors)} />
              <CardContent>
                <CrcErrorsTable nodeEID={node.EID} />
              </CardContent>
            </Card>
          )}
          {node.deviceType === 'CS500' && (
            <Card classes={{ root: classes.cardRoot }} style={{ borderRadius: 0 }}>
              <CardHeader title={formatMessage(messages.dm1)} />
              <CardContent>
                <DM1Table nodeEID={node.EID} />
              </CardContent>
            </Card>
          )}
        </Grid>
      </Grid>
    </div>
  )
}
MachineDetails.propTypes = {
  canConfigureAdvancedSignals: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
  nodeLastLocation: PropTypes.object,
  onOpenDialog: PropTypes.func.isRequired
}

MachineDetails.defaultProps = {
  nodeLastLocation: {}
}

export default withStyles(styles)(injectIntl(MachineDetails))
