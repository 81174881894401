import PropTypes from 'prop-types'
import React from 'react' // eslint-disable-line no-unused-vars
import { injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'

import Avatar from '@material-ui/core/Avatar'
import Divider from '@material-ui/core/Divider'
import Icon from '@material-ui/core/Icon'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined'
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined'

import DropdownMenu from 'components/DropdownMenu'
import { getSmsServiceConsentUrl } from 'modules/smsServiceConsent/urls'

import { useAuth0 } from '../../../../react-auth0-spa'
import messages from './messages'
import { mapToHierarchicalGroups } from './utils'

import './scrollbar.css'

const styles = {
  divider: {
    margin: '11px 0px'
  },
  menuHeader: {
    color: '#5E5D52',
    display: 'flex',
    padding: '11px 16px 6px',
    textTransform: 'uppercase'
  },
  menuItem: {
    color: '#5E5D52',
    paddingTop: '8px',
    paddingBottom: '8px'
  },
  menuItemIcon: {
    marginRight: 16
  }
}

const ProfileDropdown = ({
  classes,
  user,
  unauthorize,
  groups,
  selectedGroup,
  changeGroup,
  disabled,
  intl,
  fontColor,
  color,
  ...other
}) => {
  const { formatMessage } = intl

  const { logout } = useAuth0()

  if (!user) {
    return <span>{formatMessage(messages.loadingInfo)}</span>
  }

  const profilePicture = (
    <Avatar
      style={{
        backgroundColor: fontColor,
        color,
        fontWeight: 'bold',
        marginLeft: '5px',
        height: 28,
        width: 28,
        fontSize: 14
      }}
    >
      {user.firstName.substring(0, 1)}
    </Avatar>
  )

  const label = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div>{profilePicture}</div>
      <span className='hidden-xs' style={{ textAlign: 'start' }}>
        <span
          className='text-capitalize profile-name'
          style={{ marginLeft: '5px', paddingRight: '5px', color: fontColor }}
        >
          {user.firstName} {user.lastName + '\n'}
        </span>
        <div
          style={{
            color: fontColor,
            fontWeight: 'normal',
            fontSize: 12,
            textTransform: 'none',
            marginLeft: '5px',
            paddingLeft: '5px'
          }}
        >
          {selectedGroup.name}
        </div>
      </span>
    </div>
  )

  const handleLogout = () => {
    //Logout from SSO
    try {
      unauthorize()
      logout({ returnTo: process.env.REACT_APP_DIP_REDIRECT_LOGOUT_URI })
    } catch (error) {
      unauthorize()
    }
  }

  const hierarchicalGroups = mapToHierarchicalGroups(groups)

  const renderHierarchicalGroups = () =>
    hierarchicalGroups.map((group, index) => {
      const isCurrentGroup = group.id === selectedGroup.id
      const hasNesting = group.depth > 0
      const hasNestingExtraMargin = group.depth > 1
      const nestingExtraMargin = hasNestingExtraMargin ? (group.depth - 1) * 10 : 0
      const nestingTotalMargin = 4 + nestingExtraMargin

      return (
        <MenuItem
          key={index}
          classes={{ root: classes.menuItem }}
          disabled={disabled}
          onClick={() => {
            if (!isCurrentGroup) {
              changeGroup(group.id)
            }
          }}
          style={isCurrentGroup ? { fontWeight: 'bold' } : { paddingLeft: 56 }}
          value={group.id}
        >
          {isCurrentGroup && <CheckCircleOutlineIcon classes={{ root: classes.menuItemIcon }} />}
          {hasNesting && (
            <KeyboardArrowRightIcon style={{ fontSize: 18, marginLeft: nestingTotalMargin, marginRight: 3 }} />
          )}
          {group.name}
        </MenuItem>
      )
    })

  return (
    <div
      className='no-notifications-tab'
      style={{
        position: 'relative',
        height: 50
      }}
    >
      <DropdownMenu icon={label} {...other} name='user_button'>
        <a href={process.env.REACT_APP_DIP_REDIRECT_EDIT_PROFILE_URI} rel='noopener noreferrer' target='_blank'>
          <MenuItem classes={{ root: classes.menuItem }} name='my.danfoss-DIP-profile'>
            <AccountCircleOutlinedIcon classes={{ root: classes.menuItemIcon }} />
            {formatMessage(messages.myProfile)}
          </MenuItem>
        </a>
        <Link style={{ textDecoration: 'none' }} to={getSmsServiceConsentUrl()}>
          <MenuItem classes={{ root: classes.menuItem }} name='sms-service-consent'>
            <AssignmentOutlinedIcon classes={{ root: classes.menuItemIcon }} />
            {formatMessage(messages.smsServiceConsent)}
          </MenuItem>
        </Link>
        <Divider classes={{ root: classes.divider }} />
        {disabled && (
          <div
            className='alert alert-dismissible animated fadeIn alert-danger'
            style={{ borderRadius: 0, maxWidth: 'fit-content', textAlign: 'left', padding: 15 }}
          >
            <span className='h5'>
              <b>{formatMessage(messages.changeGroupAlert)}</b>
            </span>
          </div>
        )}
        <div className={classes.menuHeader}>{formatMessage(messages.groupSelection)}</div>
        <div className='customScroll'>{renderHierarchicalGroups()}</div>
        <Divider classes={{ root: classes.divider }} />
        <a href={process.env.REACT_APP_MARKETPLACE_LINK} rel='noopener noreferrer' target='_blank'>
          <MenuItem classes={{ root: classes.menuItem }} name='Market-Place'>
            <ShoppingCartOutlinedIcon classes={{ root: classes.menuItemIcon }} />
            {formatMessage(messages.marketPlace)}
          </MenuItem>
        </a>
        <MenuItem classes={{ root: classes.menuItem }} name='logout_button' onClick={handleLogout}>
          <Icon className='zmdi zmdi-sign-in' classes={{ root: classes.menuItemIcon }} />
          {formatMessage(messages.logout)}
        </MenuItem>
      </DropdownMenu>
    </div>
  )
}

ProfileDropdown.propTypes = {
  changeGroup: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  fontColor: PropTypes.string.isRequired,
  groups: PropTypes.array.isRequired,
  intl: PropTypes.object.isRequired,
  selectedGroup: PropTypes.object.isRequired,
  unauthorize: PropTypes.func.isRequired,
  user: PropTypes.object
}

ProfileDropdown.defaultProps = {
  user: null
}

export default withStyles(styles)(injectIntl(ProfileDropdown))
